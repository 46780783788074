import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

export const pointTipConfigsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPointTipConfigs: builder.query<
      {
        count: number;
        results: {
          id: number;
          payout_service: {
            id: number;
            max_amount: string;
            min_amount: string;
            name: string;
            type: number;
          };
          point: {
            id: number;
            name: string;
          };
          service: {
            id: number;
            max_amount: string;
            min_amount: string;
            name: string;
            type: number;
          };
          wallet: {
            available_balance: number;
            currency: string;
            id: number;
            name: string;
          };
        }[];
      },
      { point?: number }
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/point-tip-configs/?${query.toString()}`;
      },
    }),
  }),
});

export const { useGetPointTipConfigsQuery } = pointTipConfigsApi;
