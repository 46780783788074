import React, { useEffect, useState } from "react";

const checkIsNumber = (char: string) => !!(char && char >= "0" && char <= "9");

const applyMask = (mask: string, initValue: string) => {
  let value: string[] = [];

  if (typeof initValue === "string") {
    value = initValue.split("").splice(0, mask.length);
  }

  if (typeof initValue === "number") {
    value = String(initValue).split("").splice(0, mask.length);
  }

  for (let i = 0; i <= mask.length; i++) {
    if (!value[i]) {
      break;
    }

    if (mask[i] !== "0") {
      value.splice(i, 0, mask[i]);
    } else if (!checkIsNumber(value[i])) {
      value.splice(i, 1);
    }
  }

  return value.join("");
};

const unMask = (mask: string, initValue: string) => {
  const value: (string | null)[] = initValue.split("");

  for (let i = 0; i <= mask.length; i++) {
    if (!value[i]) {
      break;
    }

    if (mask[i] !== "0") {
      value.splice(i, 1, null);
    }
  }

  return value.filter((val) => val !== null).join("");
};

interface IMaskedInputWrapper {
  mask: string;
  value: string;
  children: React.ReactElement;
  onAccept?: (value: string) => void;
}

// Work only with mask based on numbers
export default function MaskedInputWrapper(
  props: IMaskedInputWrapper
): React.ReactElement {
  const [inputValue, setInputValue] = useState(props.value);

  const maskedValue = applyMask(props.mask, inputValue);

  useEffect(() => {
    if (maskedValue.length === props.mask.length) {
      if (props.onAccept) props.onAccept(unMask(props.mask, maskedValue));
    }
  }, [inputValue]);

  useEffect(() => {
    if (props.value !== inputValue) {
      setInputValue(props.value);
    }
  }, [props.value]);

  return React.cloneElement(props.children, {
    value: maskedValue,
    onInput: (val: string) => {
      setInputValue(val);
    },
  });
}
