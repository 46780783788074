import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { QRCodeSVG } from "qrcode.react";
import { toast } from "react-toastify";

import { Box } from "UI/Box";
import { Typography } from "UI/Typography";
import { ReactComponent as LinkIcon } from "utils/img/link.svg";
import { getColors } from "utils/getColors";
import { Button } from "UI/Button";

export const PayUrlInfo = ({
  payUrl,
  handleSave,
}: {
  payUrl: string;
  handleSave?: () => void;
}) => {
  const { t } = useTranslation();
  const { pageBackground } = getColors();

  const handleCopy = () => {
    navigator.clipboard.writeText(payUrl);
    toast.info(t("QR-код был сохранен в буфер обмена"));
  };

  return (
    <Box
      p={32}
      style={{
        background: "white",
        textAlign: "center",
      }}
    >
      <Box
        style={{
          height: "214px",
          width: "214px",
          margin: "auto",
        }}
      >
        <QRCodeSVG value={payUrl} size={214} />
      </Box>
      <Typography variant="h4" mb={22} mt={16}>
        {t("Платежная страница создана и готова к использованию")}
      </Typography>
      <Typography variant="subtitle2" style={{ color: "#838383" }} mb={54}>
        {t(
          "Ссылка и QR-код были успешно сгенерированы, вы можете сохранить данные без изменений и начать пользоваться страницей"
        )}
      </Typography>

      <Typography mb={12} variant="subtitle3" style={{ textAlign: "start" }}>
        {t("Ссылка на страницу")}
      </Typography>
      <Box
        flex
        nowrap
        alignItems="center"
        style={{ cursor: "pointer", background: pageBackground }}
        p={12}
        onClick={handleCopy}
      >
        <IconColor>
          <LinkIcon />
        </IconColor>
        <Typography>
          {payUrl.length > 40 ? `${payUrl.substring(0, 40)}...` : payUrl}
        </Typography>
      </Box>
      {handleSave ? (
        <Button variant="text" mt={16} fullwidth onClick={handleSave}>
          {t("Сохранить без изменений")}
        </Button>
      ) : (
        <></>
      )}
    </Box>
  );
};

const IconColor = styled.div`
  margin-right: 16px;
  svg {
    width: 16px !important;
    height: 16px !important;
    path {
      fill: #2c60b0 !important;
    }
  }
`;
