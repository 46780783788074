import { useFormik } from "formik";
import { isString } from "lodash";
import { useState } from "react";
import { SketchPicker } from "react-color";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import { useGetPointTipConfigsQuery } from "api/baseAPI/pointTipConfigs";
import { useGetServicesCommissionsQuery } from "api/baseAPI/services";
import { usePostTipUserMutation } from "api/baseAPI/tipUsers";
import { LinearProgress } from "components/LinearProgress";
import { Layout } from "components/layouts/Layout";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { ReactComponent as CameraIcon } from "utils/img/camera.svg";
import { ReactComponent as Info } from "utils/img/info.svg";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import ImagePlaceholder from "./assets/image-placeholder.svg";
import { FakePayForm } from "./parts/FakePayForm";

export const TipUserCreate = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные сервисы"), ""],
    [t("QR для чаевых"), "/services/tip-users"],
    [t("Создание менеджера"), ""],
  ];

  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>("");

  const navigate = useNavigate();

  const pm = useSelectedPointManager();

  const ptcQuery = useGetPointTipConfigsQuery({ point: pm?.point.id });

  const commissionQuery = useGetServicesCommissionsQuery(
    {
      serviceId: ptcQuery.data?.results[0]?.service?.id || 0, // zero is a hack
      pointId: pm?.point.id,
    },
    { skip: !ptcQuery.data?.results[0] }
  );

  const [createUser, createUserMeta] = usePostTipUserMutation();

  const form = useFormik({
    initialValues: {
      name: "",
      company_name: "",
      position: "",
      description: "",
      accent_color: "#000000",
      card_number: "",
      def_amount: "0",
      suggested_amounts: ["100", "200", "500"],
      avatar: "",
    },
    onSubmit: (values) => {
      createUser({
        ...values,
        suggested_amounts: values.suggested_amounts.map((a) => Number(a)),
        point: pm?.point_id,
        avatar: selectedFile,
      })
        .unwrap()
        .then((data) => {
          navigate(`/services/tip-users/${data.id}`);
        });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("Обязательное поле")),
      company_name: Yup.string().required(t("Обязательное поле")),
      position: Yup.string().required(t("Обязательное поле")),
      card_number: Yup.string().required(t("Обязательное поле")),
      suggested_amounts: Yup.array()
        .of(Yup.string())
        .test("unique", t("Допускаются только уникальные значения"), (value) =>
          value ? value.length === new Set(value)?.size : true
        ),
    }),
  });

  useTranslateFormErrors(form);

  useServerErrorsInForm(form.setFieldError, createUserMeta.error);

  const handleChangeFile = async (fileList: File[]) => {
    if (!fileList) return;
    const file = fileList[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result && isString(reader.result)) {
        setImageUrl(reader.result);
      } else {
        setImageUrl("");
      }
    };
    reader.readAsDataURL(file);
    setSelectedFile(file);
  };

  return (
    <Layout title={t("Транзакции")} breadCrumbs={breadCrumbs}>
      <>
        {commissionQuery.isFetching || ptcQuery.isFetching ? (
          <LinearProgress />
        ) : (
          <Box py={32} px={24}>
            <Grid container>
              <Grid item md={8}>
                <Typography variant="h5" mb={25}>
                  {t("Создание менеджера")}
                </Typography>
                <Grid container my={30} hSpace={20}>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    responsive={{ md: { mb: 0 }, sm: { mb: 24 } }}
                  >
                    <Dropzone
                      onDrop={handleChangeFile}
                      accept={{ "image/*": [] }}
                      maxSize={625000}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          style={{ height: "100%", minHeight: "300px" }}
                        >
                          <Box
                            style={{
                              backgroundImage: `url(${ImagePlaceholder})`,
                              backgroundColor: "white",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              cursor: "pointer",
                              height: "100%",
                              maxWidth: "100%",
                              position: "relative",
                              // marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            mb={24}
                          >
                            <input {...getInputProps()} />

                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                style={{ width: "100%", maxHeight: "100%" }}
                                alt=""
                              />
                            ) : (
                              <></>
                            )}
                            <Box
                              flex
                              p={8}
                              alignItems="center"
                              style={{
                                position: "absolute",
                                bottom: 0,
                                background: "rgba(0, 0, 0, 0.56)",
                                width: "calc(100% - 16px)",
                              }}
                            >
                              <CameraIcon />
                              <Typography
                                variant="buttonSmall"
                                pl={8}
                                style={{ color: "white" }}
                              >
                                {t("Загрузить фото").toLocaleUpperCase()}
                              </Typography>
                            </Box>
                          </Box>
                        </div>
                      )}
                    </Dropzone>
                    {form.errors.avatar ? (
                      <Box py={10} mb={25} style={{ color: "red" }}>
                        {t("Выберите фото")}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <Grid container hSpace={20} vSpace={20}>
                      <Grid item sm={12}>
                        <TextInput
                          label={t("Имя менеджера")}
                          placeholder={t("Введите имя")}
                          value={form.values.name}
                          onChange={(val) => form.setFieldValue("name", val)}
                          error={form.touched.name && Boolean(form.errors.name)}
                          helperText={form.touched.name && form.errors.name}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextInput
                          label={t("Название компании")}
                          placeholder={t("Введите название")}
                          value={form.values.company_name}
                          onChange={(val) =>
                            form.setFieldValue("company_name", val)
                          }
                          error={
                            form.touched.company_name &&
                            Boolean(form.errors.company_name)
                          }
                          helperText={
                            form.touched.company_name &&
                            form.errors.company_name
                          }
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextInput
                          label={t("Должность")}
                          placeholder={t("Введите значение")}
                          value={form.values.position}
                          onChange={(val) => {
                            form.setFieldValue("position", val);
                          }}
                          error={
                            form.touched.position &&
                            Boolean(form.errors.position)
                          }
                          helperText={
                            form.touched.position && form.errors.position
                          }
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextInput
                          label={t("Номер карты")}
                          placeholder={t("Введите значение")}
                          value={form.values.card_number}
                          onChange={(val) => {
                            form.setFieldValue(
                              "card_number",
                              val.replace(/[^0-9.]/g, "").slice(0, 16)
                            );
                          }}
                          error={
                            form.touched.card_number &&
                            Boolean(form.errors.card_number)
                          }
                          helperText={
                            form.touched.card_number && form.errors.card_number
                          }
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <StyledCommissionBlock>
                          <div>
                            <Info height={14} width={14} stroke="black" />
                          </div>
                          <div>
                            <div>{`${t("Комиссия")} 4Bill`}</div>
                            {commissionQuery.data?.point.percent} {"% + "}
                            {commissionQuery.data?.point.fix}{" "}
                            {ptcQuery.data?.results[0]?.wallet.currency}
                          </div>
                        </StyledCommissionBlock>
                      </Grid>
                      <Grid item sm={12}>
                        <TextInput
                          multiline
                          label={t("Описание")}
                          placeholder={t("Введите описание")}
                          value={form.values.description}
                          onChange={(val) => {
                            form.setFieldValue("description", val);
                          }}
                          error={
                            form.touched.description &&
                            Boolean(form.errors.description)
                          }
                          helperText={
                            form.touched.description && form.errors.description
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container hSpace={20} vSpace={20} my={30}>
                  <Grid item sm={12}>
                    <Typography variant="body">
                      {t("Настройки сумм")}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <TextInput
                      label={t("Валюта")}
                      placeholder={t("Введите значение")}
                      value={ptcQuery.data?.results[0]?.wallet?.currency || ""}
                      readOnly
                    />
                  </Grid>
                  <Grid item md={2} sm={4}>
                    <TextInput
                      label={t("Сумма")}
                      placeholder={t("Введите сумму")}
                      value={form.values.suggested_amounts[0]}
                      onChange={(val) => {
                        form.setFieldValue(
                          "suggested_amounts[0]",
                          val.replace(/[^0-9]/g, "")
                        );
                      }}
                      {...(form.values.suggested_amounts.filter(
                        (item) => item === form.values.suggested_amounts[0]
                      ).length > 1
                        ? {
                            error: !!form.errors.suggested_amounts,
                            helperText: form.errors.suggested_amounts as string,
                          }
                        : {})}
                    />
                  </Grid>
                  <Grid item md={2} sm={4}>
                    <TextInput
                      label={t("Сумма")}
                      placeholder={t("Введите сумму")}
                      value={form.values.suggested_amounts[1]}
                      onChange={(val) => {
                        form.setFieldValue(
                          "suggested_amounts[1]",
                          val.replace(/[^0-9]/g, "")
                        );
                      }}
                      {...(form.values.suggested_amounts.filter(
                        (item) => item === form.values.suggested_amounts[1]
                      ).length > 1
                        ? {
                            error: !!form.errors.suggested_amounts,
                            helperText: form.errors.suggested_amounts as string,
                          }
                        : {})}
                    />
                  </Grid>
                  <Grid item md={2} sm={4}>
                    <TextInput
                      label={t("Сумма")}
                      placeholder={t("Введите сумму")}
                      value={form.values.suggested_amounts[2]}
                      onChange={(val) => {
                        form.setFieldValue(
                          "suggested_amounts[2]",
                          val.replace(/[^0-9]/g, "")
                        );
                      }}
                      {...(form.values.suggested_amounts.filter(
                        (item) => item === form.values.suggested_amounts[2]
                      ).length > 1
                        ? {
                            error: !!form.errors.suggested_amounts,
                            helperText: form.errors.suggested_amounts as string,
                          }
                        : {})}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="body">
                      {t("Акцентный цвет")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <StyledColorBox
                      color={form.values.accent_color}
                      onClick={() => setShowPicker(true)}
                    />
                    {showPicker && (
                      <StyledPopover>
                        <StyledCover onClick={() => setShowPicker(false)} />
                        <SketchPicker
                          disableAlpha
                          color={form.values.accent_color}
                          onChange={(color) => {
                            form.setFieldValue("accent_color", color.hex);
                          }}
                        />
                      </StyledPopover>
                    )}
                  </Grid>
                </Grid>
                <Grid container hSpace={20} vSpace={10} my={30}>
                  <Grid item md={4} sm={12}>
                    <Link to="/services/tip-users">
                      <Button variant="outlined" fullwidth>
                        {t("Назад")}
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item md={4} sm={12} flex>
                    <Button
                      variant="contained"
                      fullwidth
                      onClick={() => setShowPreview(true)}
                    >
                      {t("Предпросмотр страницы")}
                    </Button>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <Button
                      variant="contained"
                      fullwidth
                      onClick={form.submitForm}
                    >
                      {t("Создать")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        <Modal
          open={showPreview}
          onClose={() => setShowPreview(false)}
          hideHeader
        >
          <FakePayForm
            name={form.values.name}
            company_name={form.values.company_name}
            position={form.values.position}
            suggestedAmounts={form.values.suggested_amounts}
            avatar={imageUrl}
            onClose={() => setShowPreview(false)}
          />
        </Modal>
      </>
    </Layout>
  );
};

const StyledColorBox = styled.div<{ color: string }>`
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  width: 40px;
  height: 26px;
  background-color: ${(props) => props.color};
  box-sizing: border-box;
  cursor: pointer;
`;
const StyledPopover = styled.div`
  position: fixed;
  z-index: 2;
  top: 50%;
`;
const StyledCover = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;
const StyledCommissionBlock = styled.div`
  background-color: #ddeee1;
  padding: 10px;
  display: flex;
  gap: 10px;
  font-size: 12px;
  height: 100%;
  box-sizing: border-box;
`;
