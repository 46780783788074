import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseTagData } from "@yaireo/tagify";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { FormikProps, useFormik } from "formik";
import { last, omit, without } from "lodash";
import styled from "styled-components";
import * as Yup from "yup";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { InvoiceFormValues } from "pages/checkoutInvoice/invoices/InvoiceCreate";
import { getColors } from "utils/getColors";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { hexToRGB } from "utils/mixColors";

export const AdditionalSettings = ({
  form,
}: {
  form: FormikProps<InvoiceFormValues>;
}) => {
  const { t, i18n } = useTranslation();
  const tagRef = useRef<Tagify<BaseTagData> | undefined>(undefined);
  const [addField, setAddField] = useState<boolean>(false);
  const [editKey, setEditKey] = useState("");

  const { accentColor } = getColors();

  const onChange = useCallback((e: any) => {
    if (last(e.detail.value || "") !== "@")
      form.setFieldValue("description_template", e.detail.value);
  }, []);

  useEffect(() => {
    if (tagRef.current) {
      tagRef.current.DOM.input.dataset.placeholder = t("Шаблон описания");
    }
  }, [i18n.language]);

  const formField = useFormik<{
    key: string;
    prefilledValue: string;
  }>({
    initialValues: {
      key: "",
      prefilledValue: "",
    },
    onSubmit: (values) => {
      if (!editKey) {
        form.setFieldValue("additional_fields", [
          ...form.values.additional_fields,
          values.key,
        ]);
      }
      form.setFieldValue("prefilled_fields", {
        ...form.values.prefilled_fields,
        [values.key]: values.prefilledValue || "",
      });
      formField.resetForm();
      setAddField(false);
      setEditKey("");
    },
    validationSchema: Yup.object().shape({
      key: Yup.string()
        .required(t("Обязательное поле"))
        .max(32, t("Максимум {{characters}} символов", { characters: 32 }))
        .test(
          "isEqual",
          t("Поле уже добавлено"),
          (value) =>
            !form.values.additional_fields.includes(value || "") || !!editKey
        ),
      prefilledValue: Yup.string().max(
        100,
        t("Максимум {{characters}} символов", { characters: 100 })
      ),
    }),
  });

  const handleEditField = (key: string) => {
    formField.setFieldValue("key", key);
    formField.setFieldValue(
      "prefilledValue",
      form.values.prefilled_fields?.[key] || ""
    );
    setEditKey(key);
    setAddField(true);
  };

  const handleDeleteField = (key: string) => {
    form.setFieldValue(
      "additional_fields",
      without(form.values.additional_fields, key)
    );
    form.setFieldValue(
      "prefilled_fields",
      omit(form.values.prefilled_fields, [key])
    );
    form.setFieldValue(
      "description_template",
      form.values.description_template.replace(
        `[[{"value":"${key}","prefix":"@"}]]`,
        ""
      )
    );
  };

  return (
    <>
      <Typography variant="h5" mt={12}>
        {t("Дополнительные настройки")}
      </Typography>
      <Grid container pt={12}>
        <Grid item sm={12} lg={6}>
          <StyledDescriptionWrapper accentcolor={accentColor}>
            <Tags
              value={form.values.description_template || ""}
              settings={{
                mode: "mix",
                pattern: /@/,
                enforceWhitelist: true,
                dropdown: {
                  enabled: 0,
                },
              }}
              tagifyRef={tagRef}
              placeholder={t("Шаблон назначения платежа")}
              onChange={onChange}
              onBlur={(e: any) => {
                if (!e.detail?.tagify?.state?.lastOriginalValueReported) {
                  form.setFieldValue("description_template", "");
                }
              }}
              onRemove={() => {
                if (!form.values.description_template) {
                  form.setFieldValue("description_template", "");
                }
              }}
              whitelist={form.values.additional_fields}
            />
          </StyledDescriptionWrapper>
        </Grid>
      </Grid>
      <Grid container mt={12} hSpace={24} vSpace={24} smHSpace={1}>
        <Grid item sm={12} lg={6}>
          <Table
            isLoading={false}
            tableCellTitles={[t("Ключ"), t("Предзаполненное значение")]}
            count={form.values.additional_fields?.length || 0}
            page={1}
            rowsPerPage={1}
            disabledPagination
            hideActionColumn
            setPage={() => {}}
            setRowsPerPage={() => {}}
          >
            {form.values.additional_fields?.length > 0 ? (
              form.values.additional_fields.map((key: string) => (
                <StyledRow key={key}>
                  <StyledCell px={4}>{key}</StyledCell>
                  <StyledCell px={4}>{`${form.values.prefilled_fields?.[
                    key
                  ]?.substring(0, 30)}${
                    (form.values.prefilled_fields?.[key]?.length || 0) > 30
                      ? "..."
                      : ""
                  }`}</StyledCell>
                  <StyledCell>
                    <Box flex justifyContent="flex-end" style={{ gap: 8 }}>
                      <StyledIconWrapper>
                        <EditIcon onClick={() => handleEditField(key)} />
                      </StyledIconWrapper>
                      <StyledIconWrapper>
                        <DeleteIcon onClick={() => handleDeleteField(key)} />
                      </StyledIconWrapper>
                    </Box>
                  </StyledCell>
                </StyledRow>
              ))
            ) : (
              <></>
            )}
          </Table>
        </Grid>
        <Grid item sm={12} lg={6}>
          {addField ? (
            <Grid container hSpace={12} vSpace={12} smHSpace={1}>
              <Grid item sm={12} lg={6}>
                <TextInput
                  size="small"
                  label={t("Ключ")}
                  placeholder={t("Введите ключ")}
                  disabled={!!editKey}
                  onChange={(value) => formField.setFieldValue("key", value)}
                  value={formField.values.key}
                  error={formField.touched.key && Boolean(formField.errors.key)}
                  helperText={formField.touched.key && formField.errors.key}
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  size="small"
                  label={t("Предзаполнить")}
                  placeholder={t("Введите значение")}
                  onChange={(value) =>
                    formField.setFieldValue("prefilledValue", value)
                  }
                  value={formField.values.prefilledValue}
                  error={
                    formField.touched.prefilledValue &&
                    Boolean(formField.errors.prefilledValue)
                  }
                  helperText={
                    formField.touched.prefilledValue &&
                    formField.errors.prefilledValue
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    formField.resetForm();
                    setAddField(false);
                    setEditKey("");
                  }}
                  fullwidth
                >
                  {t("Отмена")}
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  fullwidth
                  variant="contained"
                  onClick={() => formField.handleSubmit()}
                >
                  {t("Сохранить")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container hSpace={24} vSpace={24} smHSpace={1}>
              <Grid item sm={6}>
                <Button
                  fullwidth
                  variant="outlined"
                  onClick={() => setAddField(true)}
                  disabled={addField}
                >
                  {t("Добавить")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* ) : null} */}
    </>
  );
};
const StyledDescriptionWrapper = styled.div`
  max-width: 100%;
  word-break: break-word;
  .tagify {
    border: 1px solid #b9bfc8;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 5px 20px;
    min-height: 48px;
    width: 100%;
    color: #19202e;
    box-sizing: border-box;
    background: transparent;
    &:placeholder {
      color: #727272;
    }
    &:hover {
      border: 1px solid
        ${({ accentcolor }: { accentcolor: string }) =>
          hexToRGB(accentcolor, 0.4)};
    }
    &:focus {
      border: 1px solid ${({ accentcolor }) => accentcolor} !important;
    }
    .tagify__input {
      margin-bottom: -22px;
    }
  }
  tag {
    background: #eaeff7;
    height: 20px;
    padding: 4px;
    white-space: nowrap;
    color: #19202e;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    div {
      &::before {
        display: none;
      }
    }
  }
`;

const StyledIconWrapper = styled.div`
  cursor: pointer;
`;
