import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useGetPointPointLogDetailQuery } from "api/baseAPI/points";
import { IconCellOpen } from "components/table/IconCellOpen";
import { RowStatusOnOff } from "components/table/RowStatusOnOff";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { dateFormatter } from "utils/date";
import { DataItem } from "pages/invoicing/pages/parts/AdditionalComponents";

export const PointLogDetails = ({ rowKey }: { rowKey: number }) => {
  const { t } = useTranslation();

  const tableCellTitles = [t("Тип"), "", t("Создано"), t("Статус")];

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [openId, setOpenId] = useState<number>(0);
  const selectedPointManager = useSelectedPointManager();

  const { data, isFetching, isSuccess } = useGetPointPointLogDetailQuery({
    point: selectedPointManager.point?.id,
    key: rowKey,
  });

  const handleSetPage = (pageVal: number) => {
    setPage(pageVal);
  };

  const handleSetRowsPerPage = (rows: number) => {
    setPage(1);
    setPageSize(rows);
  };

  const handleOpen = (index: number) => {
    setOpenId(openId === index ? 0 : index);
  };

  return (
    <Table
      tableCellTitles={tableCellTitles}
      isLoading={isFetching}
      count={0}
      dataLength={isSuccess && data?.length ? data?.length : 0}
      page={Number(page)}
      rowsPerPage={pageSize}
      setPage={handleSetPage}
      setRowsPerPage={handleSetRowsPerPage}
      disabledPagination
    >
      {data?.map((row, index) => (
        <React.Fragment key={index}>
          <StyledRow>
            <StyledCell>
              {row.type
                ? `${row.type === 5 ? t("Инфо") : t("Ошибка")} (${row.type})`
                : ""}
            </StyledCell>
            <StyledCell>
              <IconCellOpen
                open={openId === index + 1}
                onClick={() => handleOpen(index + 1)}
              />
            </StyledCell>
            <StyledCell>
              {row.created_at
                ? dateFormatter.format(row.created_at?.split("+")?.[0])
                : ""}
            </StyledCell>
            <StyledCell>
              <RowStatusOnOff status={row.status === 1} />{" "}
            </StyledCell>
          </StyledRow>
          {openId === index + 1 && (
            <StyledRow>
              <td colSpan={4}>
                <Grid container p={16} hSpace={12} vSpace={12}>
                  <Grid item sm={12}>
                    <Typography variant="h6">
                      {t("Дополнительная информация")}
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <DataItem
                      text={t("Данные")}
                      content={<pre>{row.data}</pre>}
                    />
                  </Grid>
                </Grid>
              </td>
            </StyledRow>
          )}
        </React.Fragment>
      ))}
    </Table>
  );
};
