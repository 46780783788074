import { FormikProps } from "formik";
import { find } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { v4 } from "uuid";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Select } from "UI/Form/Select";
import { IconButton } from "UI/IconButton";
import { Typography } from "UI/Typography";
import {
  CustomizerCreatePreviewQuery,
  useGetThemeQuery,
  usePostCustomizerCreatePreviewMutation,
} from "api/baseAPI/customizer";
import { useGetPointsAvailablePaymentMethodsQuery } from "api/baseAPI/points";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { ReactComponent as ArrowsReloadIcon } from "utils/img/arrows-reload.svg";
import { RESPONSIVE_SIZES } from "utils/tools";
import { FormValues } from "./CustomizationRuleFormLogic";

export const paymentUrls: Record<string, string> = {
  openfinance: "public/openfinance",
  moneygram: "public/moneygram",
  p2p_am: "public/widget/p2p",
  cbu_cvu: "public/cbu",
  upi: "public/upi",
};

export const PayFormRulePreview = (props: {
  form: FormikProps<FormValues>;
  formFilled?: boolean | null;
  handleClose?: () => void;
  autoRefresh?: boolean;
}) => {
  const { t } = useTranslation();
  const { form, handleClose, autoRefresh, formFilled } = props;

  const selectedPointManager = useSelectedPointManager();

  const [createPreview] = usePostCustomizerCreatePreviewMutation();

  const [testUUID] = useState<string>(v4());
  const [isPreviewReady, setIsPreviewReady] = useState<boolean>(false);

  const [iframeKey, setIframeKey] = useState<number>(0);

  const previewHost =
    process.env.REACT_APP_PAY_FORMS_HOST ||
    `https://pay2${window.location.host.slice(
      window.location.host.indexOf("."),
      window.location.host.length
    )}`;

  const paymentMethodTypesQuery = useGetPointsAvailablePaymentMethodsQuery({
    point: selectedPointManager.point?.id,
  });

  const { data: lightThemeData, isUninitialized: isLightFetching } =
    useGetThemeQuery(
      { id: form.values.light || 0 },
      { skip: !form.values.light }
    );

  const { data: darkThemeData, isFetching: isDarkFetching } = useGetThemeQuery(
    { id: form.values.dark || 0 },
    { skip: !form.values.dark }
  );

  const requestData: CustomizerCreatePreviewQuery = {
    transaction_uuid: testUUID,
    data: {
      transaction: {
        id: 1234567890,
        status: 0,
        service_type: 2,
        amount: 250.0,
        currency: "UAH",
        initial_amount: 250.0,
        initial_currency: "UAH",
        is_fc: false,
        is_retry: false,
        failure_reason_code: null,
        locale: "uk",
        description: "Preview Transaction",
        api_forms_slug: "cards",
        payment_slug: form.values.payment_slug?.slug || "card_debit_hpp",
      },
      customization: {
        theme_switch: form.values.theme_switch,
        ap_gp_position: form.values.ap_gp_position,
        partners_logos: form.values.partners_logos,
        is_card_alias_enabled: false,
        is_ap_available: form.values.is_ap_available,
        is_gp_available: form.values.is_gp_available,
        oferta_style: form.values.oferta_style,
        locales: form.values.locales,
        oferta_url: "https://example.com",
        form_fields: [],
        light: null,
        dark: null,
        phone_number: null,
        company_name: null,
        messenger: null,
        email: null,
      },
      card_alias: [],
    },
  };

  if (form.values.phone_number && requestData.data.customization) {
    requestData.data.customization.phone_number = form.values.phone_number;
  }

  if (form.values.company_name && requestData.data.customization) {
    requestData.data.customization.company_name = form.values.company_name;
  }

  if (form.values.messenger && requestData.data.customization) {
    requestData.data.customization.messenger = form.values.messenger;
  }

  if (form.values.email && requestData.data.customization) {
    requestData.data.customization.email = form.values.email;
  }

  if (form.values.payment_slug && requestData.data.transaction) {
    requestData.data.transaction.payment_slug =
      form.values.payment_slug?.slug || "card_debit_hpp";
  }

  if (form.values.light && lightThemeData && requestData.data.customization) {
    requestData.data.customization.light = {
      accent_color: lightThemeData.accent_color,
      background_color: lightThemeData.background_color,
      logo: lightThemeData.logo as string,
      border_radius: lightThemeData.border_radius,
      panel_background_color_first: lightThemeData.panel_background_color_first,
      panel_background_color_second:
        lightThemeData.panel_background_color_second,
      inputs_background: lightThemeData.inputs_background,
      primary_text_font: lightThemeData.primary_text_font,
      primary_text_color: lightThemeData.primary_text_color,
      secondary_text_color: lightThemeData.secondary_text_color,
      button_text_color: lightThemeData.button_text_color,
      custom_styles: lightThemeData.custom_styles as string,
      ap_gp_style: lightThemeData.ap_gp_style,
      inputs_style: lightThemeData.inputs_style,
    };
  }

  if (form.values.dark && darkThemeData && requestData.data.customization) {
    requestData.data.customization.dark = {
      accent_color: darkThemeData.accent_color,
      background_color: darkThemeData.background_color,
      logo: darkThemeData.logo as string,
      border_radius: darkThemeData.border_radius,
      panel_background_color_first: darkThemeData.panel_background_color_first,
      panel_background_color_second:
        darkThemeData.panel_background_color_second,
      inputs_background: darkThemeData.inputs_background,
      primary_text_font: darkThemeData.primary_text_font,
      primary_text_color: darkThemeData.primary_text_color,
      secondary_text_color: darkThemeData.secondary_text_color,
      button_text_color: darkThemeData.button_text_color,
      custom_styles: darkThemeData.custom_styles as string,
      ap_gp_style: darkThemeData.ap_gp_style,
      inputs_style: darkThemeData.inputs_style,
    };
  }

  const handlePreview = (slug?: string) => {
    setIsPreviewReady(false);
    createPreview({
      ...requestData,
      data: {
        ...requestData.data,
        transaction: {
          ...requestData.data.transaction,
          payment_slug:
            slug || form.values.payment_slug?.slug || "card_debit_hpp",
        },
      },
    }).finally(() => {
      setIsPreviewReady(true);
      setIframeKey(iframeKey + 1);
    });
  };

  const [viewMode, setViewMode] = useState<"mobile" | "desktop">("desktop");

  useEffect(() => {
    if (
      formFilled !== null &&
      (isLightFetching === false || isDarkFetching === false)
    ) {
      createPreview(requestData).then(() => {
        setIsPreviewReady(true);
        setIframeKey(iframeKey + 1);
      });
    }
  }, [
    formFilled,
    lightThemeData,
    darkThemeData,
    isLightFetching,
    isDarkFetching,
  ]);

  return (
    <Box p={handleClose ? 24 : 0}>
      <Box flex alignItems="flex-end" justifyContent="space-between" mb={24}>
        <Box flex alignItems="center" style={{ minHeight: "50px" }}>
          <Typography variant="h5">{t("Просмотр правила")}</Typography>
        </Box>
        <StyledButtonSwitch
          firstTitle="Mobile"
          secondTitle="Desktop"
          value={viewMode  ===  "mobile"}
          onChange={() =>
            setViewMode(viewMode  ===  "mobile" ? "desktop" : "mobile")
          }
        />
        <Box style={{ width: "280px" }}>
          <Select
            value={
              form.values.payment_slug?.id
                ? String(form.values.payment_slug?.id)
                : ""
            }
            onChange={(value) => {
              const findEl = find(
                paymentMethodTypesQuery.data,
                (el) => el.id  ===  Number(value)
              );
              form.setFieldValue(
                "payment_slug",
                value
                  ? {
                      id: findEl?.id || Number(value),
                      slug: findEl?.slug || "",
                    }
                  : null
              );
              if (autoRefresh) handlePreview(findEl?.slug || "");
            }}
            label={t("Платежный метод")}
            placeholder={t("Выберите метод")}
            size="small"
            options={
              paymentMethodTypesQuery.data &&
              paymentMethodTypesQuery.data?.length > 0
                ? paymentMethodTypesQuery.data?.map((item) => ({
                    value: `${item.id}`,
                    label: item.slug,
                  }))
                : []
            }
            error={
              form.touched.payment_slug && Boolean(form.errors.payment_slug)
            }
            helperText={form.touched.payment_slug && form.errors.payment_slug}
          />
        </Box>
        {!autoRefresh && (
          <IconButton variant="outlined" onClick={handlePreview}>
            <StyledArrowsReloadIcon />
          </IconButton>
        )}
        {!!handleClose && (
          <StyledCloseButton variant="outlined" onClick={() => handleClose()}>
            {t("Закрыть")}
          </StyledCloseButton>
        )}
      </Box>
      {isPreviewReady && previewHost && (
        <Box
          style={{
            position: "sticky",
            justifyContent: "center",
            flex: 1,
            display: "flex",
          }}
        >
          <iframe
            key={iframeKey}
            title="iframe"
            src={`${previewHost}/${
              form.values.payment_slug?.slug &&
              paymentUrls?.[form.values.payment_slug?.slug]
                ? paymentUrls?.[form.values.payment_slug?.slug]
                : "public/cards"
            }/ua/${testUUID}`}
            style={{
              width: viewMode === "desktop" ? "100%" : "400px",
              minHeight: handleClose ? "500px" : "600px",
            }}
           />
        </Box>
      )}
      {!!handleClose && (
        <StyledMobileCloseButton
          mt={24}
          fullwidth
          variant="outlined"
          onClick={() => handleClose()}
        >
          {t("Закрыть")}
        </StyledMobileCloseButton>
      )}
    </Box>
  );
}

const StyledArrowsReloadIcon = styled(ArrowsReloadIcon)`
  display: flex;
  align-items: center;
  path {
    fill: #838383 !important;
  }
`;

const StyledButtonSwitch = styled(ButtonSwitch)`
  @media (${RESPONSIVE_SIZES.lg}) {
    display: none;
  }
`;

const StyledCloseButton = styled(Button)`
  @media (${RESPONSIVE_SIZES.lg}) {
    display: none;
  }
`;

const StyledMobileCloseButton = styled(Button)`
  @media (${RESPONSIVE_SIZES.overLg}) {
    display: none;
  }
`;
