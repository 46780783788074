import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import { useGetPointTipConfigsQuery } from "api/baseAPI/pointTipConfigs";
import {
  TipUsersQueryParams,
  useDeleteTipUserMutation,
  useGetTipUsersQuery,
  useGetTipUserStatisticsQuery,
  useUsePostTipUserMutationMutation,
} from "api/baseAPI/tipUsers";
import { LinearProgress } from "components/LinearProgress";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import { ReactComponent as LinkIcon } from "utils/img/link.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { IconCellOpen } from "components/table/IconCellOpen";
import { Grid } from "UI/Grid";
import { Box } from "UI/Box";
import { DataItem } from "pages/invoicing/pages/parts/AdditionalComponents";
import { useUrlQuery } from "utils/url";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as MakePayoutIcon } from "./assets/make-payout.svg";
import { ReactComponent as QrDownloadButton } from "./assets/qr-download-button.svg";
import { ReactComponent as Success } from "./assets/success.svg";
import { ReactComponent as Time } from "./assets/time.svg";
import { ReactComponent as TotalUsers } from "./assets/total-users.svg";
import { ReactComponent as Trend } from "./assets/trend.svg";
import { handleDownloadQr, QrDownloader } from "./parts/QrDownloader";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
};

const InfoPanel = () => {
  const { t } = useTranslation();

  const { data: statisticData, isFetching: isFetchingStatistic } =
    useGetTipUserStatisticsQuery();

  if (isFetchingStatistic)
    return (
      <Grid container>
        <Grid item md={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    );

  return (
    <Grid
      container
      responsive={{ lg: { direction: "row" }, sm: { direction: "column" } }}
    >
      <Grid item lg={6} xl={3}>
        <StyledPanelCell>
          <TotalUsers />
          <div>
            <Typography fontSize={14} fontWeight="bold">
              {t("Всего пользователей")}
            </Typography>
            <Typography>{statisticData?.quantity}</Typography>
          </div>
        </StyledPanelCell>
      </Grid>
      <Grid item lg={6} xl={3}>
        <StyledPanelCell>
          <Trend />
          <div>
            <Typography fontSize={14} fontWeight="bold">
              {t("Общая сумма оплаченных")}
            </Typography>
            <Typography>{statisticData?.total_balance}</Typography>
          </div>
        </StyledPanelCell>
      </Grid>
      <Grid item lg={6} xl={3}>
        <StyledPanelCell>
          <Success />
          <div>
            <Typography fontSize={14} fontWeight="bold">
              {t("Количество успешных")}
            </Typography>
            <Typography>{statisticData?.tips_quantity}</Typography>
          </div>
        </StyledPanelCell>
      </Grid>
      <Grid item lg={6} xl={3}>
        <StyledPanelCell>
          <StyledIconWrapper>
            <Time />
          </StyledIconWrapper>
          <div>
            <Typography fontSize={14} fontWeight="bold">
              {t("Активные")}
            </Typography>
            <Typography>{statisticData?.tips_amount}</Typography>
          </div>
        </StyledPanelCell>
      </Grid>
    </Grid>
  );
};

export const TipUsers = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные сервисы"), ""],
    [t("QR для чаевых"), ""],
  ];

  const tableCellTitles = [
    t("ID"),
    t("Имя менеджера"),
    t("Название компании"),
    t("Должность"),
    t("Номер карты"),
    t("Общая сумма"),
    t("Валюта"),
  ];

  const [openId, setOpenId] = useState<number | null>(null);

  const { queryParams, querySetters } =
    useUrlQuery<TipUsersQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  // const filters = useFilterMaker(
  //   initParameters,
  //   queryParams,
  //   (_) => {
  //     set_page("1");
  //   },
  //   () => {
  //     set_search(initParameters.search);
  //   }
  // );

  const { data, isFetching, refetch } = useGetTipUsersQuery({
    ...queryParams,
  });

  const pointTipConfigsQuery = useGetPointTipConfigsQuery({});

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const handleCopy = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.info(t("Url был сохранен в буфер обмена"));
  };

  const [deleteTipUser] = useDeleteTipUserMutation();

  const handleDelete = (id: number) => {
    deleteTipUser({ id }).then(() => {
      refetch();
    });
  };

  const navigate = useNavigate();

  const handleEdit = (id: number) => {
    navigate(`/services/tip-users/${id}`);
  };

  const [makePayout, makePayoutMeta] = useUsePostTipUserMutationMutation();

  useSuccessNotification([makePayoutMeta]);
  useErrorNotification([makePayoutMeta]);

  const [payoutItem, setPayoutItem] = useState<null | number>(null);

  const tipUser = data?.results.find((item) => item.id === payoutItem);

  const payoutForm = useFormik({
    initialValues: {
      amount: "",
      description: "",
    },
    onSubmit: (values) => {
      if (payoutItem === null) return;

      makePayout({
        id: payoutItem,
        amount: Number(values.amount),
        description: values.description,
      }).then(() => {
        setPayoutItem(null);
      });
    },
    validationSchema: yup.object().shape({
      amount: yup
        .number()
        .required(t("Обязательное поле"))
        .max(
          Number(tipUser?.balance),
          t("Максимальная сумма {{balance}}", {
            balance: tipUser?.balance || "",
          })
        ),
      description: yup
        .string()
        .required(t("Обязательное поле"))
        .max(100, t("Максимум 100 символов")),
    }),
  });

  useTranslateFormErrors(payoutForm);

  return (
    <Layout title={t("Транзакции")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
              createTitle={t("Добавить менеджера")}
              createPath="/services/tip-users/create"
            />
          }
          filtersRow={<InfoPanel />}
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data &&
            data.results.map((row) => (
              <React.Fragment key={row.id}>
                <StyledRow>
                  <StyledCell>
                    <Box flex alignItems="center">
                      <IconCellOpen
                        open={openId === row.id}
                        onClick={() =>
                          setOpenId(openId === row.id ? null : row.id)
                        }
                      />
                      <div>{row.id}</div>
                    </Box>
                  </StyledCell>
                  <StyledCell>{row.name}</StyledCell>
                  <StyledCell>{row.company_name}</StyledCell>
                  <StyledCell>{row.position}</StyledCell>
                  <StyledCell>{row.card_number}</StyledCell>
                  <StyledCell>{row.balance}</StyledCell>
                  <StyledCell>
                    {pointTipConfigsQuery.data?.results
                      ? pointTipConfigsQuery.data?.results.find(
                          (ptc) => ptc.point.id === row.point.id
                        )?.wallet?.currency
                      : null}
                  </StyledCell>
                  <StyledCell>
                    <TableRowActions
                      actions={[
                        <MenuItemAction
                          key={0}
                          onClick={() => setPayoutItem(row.id)}
                        >
                          <MakePayoutIcon /> {t("Выплатить")}
                        </MenuItemAction>,
                        <MenuItemAction
                          key={1}
                          onClick={() => handleEdit(row.id)}
                        >
                          <EditIcon /> {t("Редактировать")}
                        </MenuItemAction>,
                        <MenuItemAction
                          key={2}
                          onClick={() => handleDelete(row.id)}
                        >
                          <DeleteIcon /> {t("Удалить")}
                        </MenuItemAction>,
                      ]}
                    />
                  </StyledCell>
                </StyledRow>
                {openId === row.id && (
                  <StyledRow>
                    <td colSpan={8} style={{ paddingRight: "6px" }}>
                      <QrDownloader qrUrl={row.page_url} id={String(row.id)} />
                      <Grid container py={16} hSpace={16} vSpace={16}>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("Файл QR-кода")}
                            content={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDownloadQr(String(row.id))}
                              >
                                <QrDownloadButton />
                                {t("Скачать PNG")}
                              </div>
                            }
                          />
                        </Grid>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("Предлагаемые суммы")}
                            content={
                              <>
                                {row.suggested_amounts?.map((amount) => (
                                  <StyledSuggestedAmount key={amount}>
                                    {amount}
                                  </StyledSuggestedAmount>
                                ))}
                              </>
                            }
                          />
                        </Grid>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("Ссылка на QR-код")}
                            content={
                              <Box
                                flex
                                style={{ cursor: "pointer" }}
                                p={12}
                                onClick={() => handleCopy(row.page_url)}
                              >
                                <IconColor>
                                  <LinkIcon />
                                </IconColor>
                                <Typography>
                                  {row.page_url.length > 15
                                    ? `${row.page_url.substring(0, 15)}...`
                                    : row.page_url}
                                </Typography>
                              </Box>
                            }
                          />
                        </Grid>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("Цвет кнопки")}
                            content={
                              <StyledButtonColor
                                color={row.accent_color || "transparent"}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </td>
                  </StyledRow>
                )}
              </React.Fragment>
            ))}
        </Table>
      </Box>
      <Modal
        open={Boolean(payoutItem)}
        hideHeader
        onClose={() => setPayoutItem(null)}
      >
        <Box px={20} py={30}>
          <Typography variant="h4" fontSize={20} mb={24} fontWeight="bold">
            {t("Выплата чаевых")}
          </Typography>
          <Typography variant="body" fontSize={16} mb={12}>
            {t("Баланс менеджера")}:
          </Typography>
          <Typography variant="body" fontSize={32} mb={20}>
            {tipUser?.balance}{" "}
            <Typography
              variant="body"
              fontSize={20}
              style={{ display: "inline" }}
              fontWeight="bold"
            >
              {pointTipConfigsQuery.data?.results
                ? pointTipConfigsQuery.data?.results.find(
                    (ptc) => ptc.point.id === tipUser?.point.id
                  )?.wallet?.currency
                : null}
            </Typography>
          </Typography>
          <Box mb={30}>
            <TextInput
              placeholder={t("Сумма выплаты")}
              size="small"
              name="amount"
              value={payoutForm.values.amount}
              onChange={(val) =>
                payoutForm.setFieldValue("amount", val.replaceAll(/\D/g, ""))
              }
              error={
                payoutForm.touched.amount && Boolean(payoutForm.errors.amount)
              }
              helperText={payoutForm.errors.amount as string}
            />
          </Box>
          <Box mb={30}>
            <TextInput
              multiline
              placeholder={t("Описание")}
              size="small"
              name="amount"
              value={payoutForm.values.description}
              onChange={(val) => payoutForm.setFieldValue("description", val)}
              error={
                payoutForm.touched.description &&
                Boolean(payoutForm.errors.description)
              }
              helperText={payoutForm.errors.description as string}
            />
          </Box>
          <Grid container hSpace={5}>
            <Grid item md={6}>
              <Button
                fullwidth
                variant="outlined"
                onClick={() => setPayoutItem(null)}
              >
                {t("Отмена")}
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button fullwidth onClick={() => payoutForm.handleSubmit()}>
                {t("Выплатить")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Layout>
  );
};

const StyledSuggestedAmount = styled.div`
  display: inline-block;
  padding: 4px;
  background-color: #eaeff7;
  margin-right: 4px;
`;

const IconColor = styled.div`
  margin-right: 16px;

  svg {
    width: 16px !important;
    height: 16px !important;

    path {
      fill: #2c60b0 !important;
    }
  }
`;

const StyledButtonColor = styled.div<{ color: string }>`
  width: 40px;
  height: 30px;
  background-color: ${(props) => props.color};
`;

const StyledIconWrapper = styled.div`
  background-color: rgba(255, 153, 0, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPanelCell = styled.div`
  background-color: white;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 5px;
`;
