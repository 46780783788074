import { useFormik } from "formik";
import { isBoolean, map } from "lodash";
import { QRCodeSVG } from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Divider } from "UI/Divider";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  useGetOtpMutation,
  useGetUsersSelfQuery,
  useUpdateOtpMutation,
} from "api/baseAPI/user";
import { IconColor } from "components/table/TableRowActions";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { ReactComponent as CircleCheckIcon } from "utils/img/circle-check.svg";
import { ReactComponent as LockIcon } from "utils/img/lock.svg";
import { ReactComponent as ShieldCheckIcon } from "utils/img/shield-check.svg";
import { useErrorNotification } from "utils/notificationWrappers";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
};

export const ModalOtp = (props: Props): React.ReactElement => {
  const { onClose, onSuccess } = props;
  const { t } = useTranslation();
  const { pageBackground } = getColors();
  const [open, setOpen] = useState(false);

  const getUsersSelfQuery = useGetUsersSelfQuery();
  const [getOtp, getOtpMeta] = useGetOtpMutation();
  const [updateOtp, { isSuccess, isError, isLoading }] = useUpdateOtpMutation();

  const otpText = [
    t(
      "Для установки двухфакторной аутентификации используйте приложение “Google Authenticator“."
    ),
    t(
      "Откройте приложение и сканируйте код на экране вашего компьютера. Если вы используете смартфон, просто коснитесь QR-кода на экране."
    ),
    t(
      "После сканирования QR-кода введите код, который отображается в приложении, на странице и нажмите “Активировать“."
    ),
    t(
      "Двухфакторная аутентификация активирована. Теперь, кроме логина и пароля, вам потребуется вводить одноразовый код из приложения Google Authenticator каждый раз при входе."
    ),
    t(
      "Если вы вводите код правильно, но все равно появляется ошибка, перейдите в настройки приложения на своем смартфоне. Найдите опцию “Коррекция времени для кодов“ и выберите “Синхронизировать“. И попробуйте еще раз."
    ),
  ];

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (
      isBoolean(getUsersSelfQuery.data?.otp_enabled) &&
      !getUsersSelfQuery.data?.otp_enabled
    ) {
      getOtp();
    }
  }, [getUsersSelfQuery.data]);

  const form = useFormik({
    initialValues: {
      otp: "",
    },
    onSubmit: (values) => {
      updateOtp({ otp: values.otp });
    },
    validationSchema: Yup.object().shape({
      otp: Yup.string()
        .required(t("Обязательное поле"))
        .min(6, t("Значение этого поля должно состоять из 6 цифр")),
    }),
  });
  useErrorNotification([{ isError }]);

  useTranslateFormErrors(form);

  return (
    <>
      <Grid
        item
        sm={12}
        lg={4}
        xl={4.39}
        style={{
          background: "#ffffff",
        }}
        responsive={{ md: { mt: 0 }, sm: { mt: -71 } }}
      >
        <Box p={32}>
          <Typography variant="h5" mb={24}>
            {t("Двухфакторная аутентификация")}
          </Typography>
          {!getUsersSelfQuery?.data?.otp_enabled ? (
            <>
              <Typography variant="subtitle3" mb={12}>
                {t("Отсканируйте QR-code")}
              </Typography>
              {!!getOtpMeta?.data?.string && (
                <Box
                  flex
                  alignItems="center"
                  justifyContent="center"
                  p={32}
                  style={{
                    background: pageBackground,
                    margin: "auto",
                    marginBottom: 24,
                  }}
                >
                  <QRCodeSVG
                    value={getOtpMeta?.data?.string || ""}
                    size={150}
                  />
                </Box>
              )}
            </>
          ) : (
            <></>
          )}
          <TextInput
            size="small"
            label={t("Введите код OTP")}
            placeholder={t("Введите код")}
            onChange={(value) =>
              form.setFieldValue(
                "otp",
                value.replace(/[^0-9]/g, "").slice(0, 6)
              )
            }
            value={form.values.otp}
            error={form.touched.otp && Boolean(form.errors.otp)}
            helperText={form.touched.otp && form.errors.otp}
            iconStart={
              getUsersSelfQuery?.data?.otp_enabled ? (
                <IconColor color="rgba(62, 169, 89, 1)">
                  <ShieldCheckIcon />
                </IconColor>
              ) : (
                <LockIcon />
              )
            }
          />
          {getUsersSelfQuery?.data?.otp_enabled ? (
            <Box mt={24}>
              <TextInput
                size="small"
                placeholder={t("Аутентификация включена")}
                value=""
                readOnly
                iconStart={
                  <IconColor color="#3EA959">
                    <CircleCheckIcon />
                  </IconColor>
                }
              />
            </Box>
          ) : (
            <></>
          )}
          <Grid
            container
            flex
            alignItems="center"
            justifyContent="space-between"
            mt={24}
          >
            <Grid item>
              <Button
                mr={24}
                size="small"
                disabled={isLoading}
                variant="outlined"
                onClick={() => onClose()}
              >
                {t("Отмена")}
              </Button>
            </Grid>
            <Grid item sm="fill">
              <Button
                size="small"
                disabled={isLoading}
                fullwidth
                onClick={() => form.handleSubmit()}
              >
                {!getUsersSelfQuery?.data?.otp_enabled
                  ? t("Активировать")
                  : t("Деактивировать")}
              </Button>
            </Grid>
          </Grid>
          {!getUsersSelfQuery?.data?.otp_enabled ? (
            <Box mt={24}>
              <Divider direction="vertical" />
              <Box
                flex
                alignItems="center"
                justifyContent="space-between"
                style={{
                  cursor: "pointer",
                  ...(open ? { background: pageBackground } : {}),
                }}
                mt={16}
                px={32}
                py={16}
                mx={-32}
                onClick={() => setOpen(!open)}
              >
                <Typography variant="subtitle1">
                  {t("Как активировать")}
                </Typography>
                <StyledArrowUpDown open={open} />
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Grid>
      {open ? (
        <Grid
          item
          sm={12}
          lg={4}
          xl={4.39}
          style={{
            background: "#ffffff",
          }}
        >
          <Box p={30} pt={1}>
            {map(otpText, (text, i) => (
              <Box mt={16} key={i}>
                <Divider direction="vertical" />
                <Grid
                  container
                  flex
                  alignItems="center"
                  justifyContent="space-between"
                  mt={16}
                  responsive={{ md: { nowrap: true } }}
                >
                  <Grid item>
                    <Box
                      flex
                      alignItems="center"
                      justifyContent="center"
                      mr={16}
                      style={{
                        width: 40,
                        height: 40,
                        background: "rgba(200, 207, 220, 0.2)",
                      }}
                    >
                      {i + 1}
                    </Box>
                  </Grid>
                  <Grid item sm={10}>
                    <Typography variant="subtitle3">{text}</Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

const StyledArrowUpDown = styled(ArrowUpDown)`
  transform: rotate(270deg);
  @media (${RESPONSIVE_SIZES.sm}) {
    ${({ open }: { open: boolean }) =>
      open ? `transform: rotate(180deg);` : "transform: rotate(0deg);"};
  }
`;
