import styled from "styled-components";
import { ReactComponent as Arrow } from "./ArrowLeft.svg";

export const ArrowInSquare = () => (
    <StyledWrapper>
      <Arrow />
    </StyledWrapper>
  )

const StyledWrapper = styled.div`
  width: 44px;
  height: 44px;
  background-color: #eaeff7;
  display: flex;
  align-items: center;
  justify-content: center;
`;
