import { FormikProps } from "formik";
import { isString, last } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { SketchPicker } from "react-color";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { ReactComponent as CaretCircleUpIcon } from "utils/img/caret-circle-up.svg";
import { ReactComponent as CloseIcon } from "utils/img/close.svg";
import { ReactComponent as ImageIcon } from "utils/img/image.svg";
import { ReactComponent as UploadIcon } from "utils/img/upload.svg";
import { RESPONSIVE_SIZES } from "utils/tools";
import { BoxIcon } from "pages/invoicing/pages/parts/SecondStep";
import { FormValues } from "./ThemeFormLogic";

const InputSelectColor = ({
  name,
  label,
  form,
}: {
  form: FormikProps<FormValues>;
  name: keyof FormValues;
  label: string;
}) => {
  const { t } = useTranslation();
  const [openPicker, setOpenPicker] = useState(false);

  return (
    <>
      <TextInput
        name={name}
        label={label}
        placeholder={t("Выберите цвет")}
        value={String(form.values[name]) || ""}
        onChange={form.handleChange}
        size="small"
        onClick={() => setOpenPicker(true)}
        iconEnd={
          <ColorView
            style={{ backgroundColor: String(form.values[name]) || "" }}
          />
        }
      />
      {openPicker && (
        <StyledPopover>
          <StyledCover onClick={() => setOpenPicker(false)} />
          <SketchPicker
            disableAlpha
            color={String(form.values[name]) || ""}
            onChange={(color) => {
              form.setFieldValue(name, color.hex);
            }}
          />
        </StyledPopover>
      )}
    </>
  );
};

export const ThemeForm = (props: {
  submitButtonText: string;
  form: FormikProps<FormValues>;
  setSelectedFile: Dispatch<SetStateAction<File | string>>;
  selectedFile: File | string;
  setImageUrl: Dispatch<SetStateAction<string>>;
  imageUrl: string;
  themeID?: string;
  onCancel: () => void;
}) => {
  const {
    submitButtonText,
    form,
    selectedFile,
    setImageUrl,
    setSelectedFile,
    imageUrl,
    themeID,
    onCancel,
  } = props;
  const { t } = useTranslation();

  const { pageBackground } = getColors();

  const [openBasicSettings, setOpenBasicSettings] = useState(true);
  const [openTypography, setOpenTypography] = useState(true);
  const [openAdditionalSettings, setOpenAdditionalSettings] = useState(true);

  const handleChangeFile = async (fileList: File[]) => {
    if (!fileList) return;
    const file = fileList[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result && isString(reader.result)) {
        setImageUrl(reader.result);
      } else {
        setImageUrl("");
      }
    };
    reader.readAsDataURL(file);
    setSelectedFile(file);
  };

  const handleDeleteFile = () => {
    setSelectedFile("");
    setImageUrl("");
    form.setFieldValue("delete_logo", true);
  };

  return (
    <>
      <Box>
        <Typography variant="h5">{t("Параметры темы")}</Typography>
        {themeID && (
          <TextInput
            value={themeID}
            label="Lable"
            size="small"
            disabled
            mt={24}
          />
        )}
        <Box
          flex
          alignItems="center"
          mt={24}
          style={{ cursor: "pointer" }}
          onClick={() => setOpenBasicSettings(!openBasicSettings)}
        >
          <Typography variant="h5" mr={16}>
            {t("Общие настройки")}
          </Typography>
          <StyledCaretCircleUpIcon open={openBasicSettings} />
        </Box>
        {openBasicSettings ? (
          <Grid container pt={24} hSpace={24} vSpace={24}>
            <Grid item sm={12}>
              <Dropzone
                onDrop={handleChangeFile}
                accept={{ "image/*": [] }}
                maxSize={625000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <Box
                      p={16}
                      style={{
                        border: "1px dashed rgba(44, 96, 176, 0.4)",
                        background: "#FFFFFF",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      <Box
                        flex
                        alignItems="center"
                        justifyContent="center"
                        mb={12}
                      >
                        <BoxIcon background={pageBackground}>
                          <UploadIcon />
                        </BoxIcon>
                        <Typography variant="buttonSmall" textAlign="center">
                          {t(
                            "Нажмите или перетащите файлы для загрузки"
                          ).toLocaleUpperCase()}
                        </Typography>
                      </Box>
                      <Box flex justifyContent="center">
                        <Typography variant="subtitle3">
                          {t("Формат файла")}
                        </Typography>
                        <>&nbsp;</>
                        <Typography
                          variant="subtitle3"
                          style={{ color: "#838383" }}
                        >
                          {" png, jpg, gif "}
                        </Typography>
                      </Box>
                      <Box flex justifyContent="center">
                        <Typography variant="subtitle3">
                          {t("Размеры в px")}
                        </Typography>
                        <>&nbsp;</>
                        <Typography
                          variant="subtitle3"
                          style={{ color: "#838383" }}
                        >
                          250x100, 400x100, 250x150, 350x75
                        </Typography>
                      </Box>
                      <Box flex justifyContent="center">
                        <Typography variant="subtitle3">{t("Вес")}</Typography>
                        <>&nbsp;</>
                        <Typography
                          variant="subtitle3"
                          style={{ color: "#838383" }}
                        >
                          {`${t("не более")} 5mb`}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                )}
              </Dropzone>
              {selectedFile || imageUrl ? (
                <Grid item sm={12} mt={24}>
                  <Box
                    flex
                    justifyContent="space-between"
                    alignItems="center"
                    p={16}
                    style={{ border: "1px solid #C8CFDC" }}
                    nowrap
                  >
                    <Box flex alignItems="center" nowrap>
                      <Box
                        mr={8}
                        flex
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          border: "1px solid #C8CFDC",
                          width: 35,
                          height: 35,
                        }}
                      >
                        <ImageIcon />
                      </Box>
                      <Typography>
                        {(typeof selectedFile !== "string" &&
                          selectedFile?.name) ||
                          last(imageUrl.split("?")?.[0].split("/")) ||
                          ""}
                      </Typography>
                    </Box>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteFile()}
                    />
                  </Box>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item sm={12}>
              <TextInput
                size="small"
                label={t("Название")}
                placeholder={t("Введите название")}
                onChange={(value) => form.setFieldValue("name", value)}
                value={form.values.name}
                error={form.touched.name && Boolean(form.errors.name)}
                helperText={form.touched.name && form.errors.name}
              />
            </Grid>
            <Grid item sm={12}>
              <InputSelectColor
                label={t("Цвет фона")}
                name="background_color"
                form={form}
              />
            </Grid>
            <Grid item sm={12}>
              <InputSelectColor
                label={`${t("Цвет панели")} 1`}
                name="panel_background_color_first"
                form={form}
              />
            </Grid>
            <Grid item sm={12}>
              <InputSelectColor
                label={`${t("Цвет панели")} 2`}
                name="panel_background_color_second"
                form={form}
              />
            </Grid>
            <Grid item sm={12}>
              <InputSelectColor
                label={t("Акцентный цвет")}
                name="accent_color"
                form={form}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Box
          flex
          alignItems="center"
          mt={24}
          style={{ cursor: "pointer" }}
          onClick={() => setOpenTypography(!openTypography)}
        >
          <Typography variant="h5" mr={16}>
            {t("Типографика")}
          </Typography>
          <StyledCaretCircleUpIcon open={openTypography} />
        </Box>
        {openTypography ? (
          <Grid container pt={24} hSpace={24} vSpace={24}>
            <Grid item sm={12}>
              <Select
                value={form.values.primary_text_font}
                onChange={(value) => {
                  form.setFieldValue("primary_text_font", value);
                }}
                label={t("Шрифт")}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  { label: "Montserrat", value: "Montserrat" },
                  { label: "Open Sans", value: "Open Sans" },
                  { label: "Rubik", value: "Rubik" },
                  { label: "Oswald", value: "Oswald" },
                  { label: "Noto Sans", value: "Noto Sans" },
                  { label: "Inter", value: "Inter" },
                  { label: "Mulish", value: "Mulish" },
                  { label: "IBM Plex Sans", value: "IBM Plex Sans" },
                  { label: "Jost", value: "Jost" },
                ]}
                error={
                  form.touched.primary_text_font &&
                  Boolean(form.errors.primary_text_font)
                }
                helperText={
                  form.touched.primary_text_font &&
                  form.errors.primary_text_font
                }
              />
            </Grid>
            <Grid item sm={12}>
              <InputSelectColor
                label={t("Основной цвет текста")}
                name="primary_text_color"
                form={form}
              />
            </Grid>
            <Grid item sm={12}>
              <InputSelectColor
                label={t("Дополнительный цвет текста")}
                name="secondary_text_color"
                form={form}
              />
            </Grid>
            <Grid item sm={12}>
              <InputSelectColor
                label={t("Цвет текста кнопки")}
                name="button_text_color"
                form={form}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Box
          flex
          alignItems="center"
          mt={24}
          style={{ cursor: "pointer" }}
          onClick={() => setOpenAdditionalSettings(!openAdditionalSettings)}
        >
          <Typography variant="h5" mr={16}>
            {t("Дополнительные настройки")}
          </Typography>
          <StyledCaretCircleUpIcon open={openAdditionalSettings} />
        </Box>
        {openAdditionalSettings ? (
          <Grid container pt={24} hSpace={24} vSpace={24}>
            <Grid item sm={12}>
              <Select
                value={String(form.values.ap_gp_style) || ""}
                onChange={(value) => {
                  form.setFieldValue("ap_gp_style", Number(value));
                }}
                label={t("Стиль Apple Pay / Google Pay")}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  { label: t("Светлый"), value: "1" },
                  { label: t("Тёмный"), value: "2" },
                ]}
                error={
                  form.touched.ap_gp_style && Boolean(form.errors.ap_gp_style)
                }
                helperText={form.touched.ap_gp_style && form.errors.ap_gp_style}
              />
            </Grid>
            <Grid item sm={12}>
              <Select
                value={String(form.values.inputs_style) || ""}
                onChange={(value) => {
                  form.setFieldValue("inputs_style", Number(value));
                }}
                label={t("Стиль input-ов")}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  { label: "Filled", value: "1" },
                  { label: "Outlined", value: "2" },
                ]}
                error={
                  form.touched.inputs_style && Boolean(form.errors.inputs_style)
                }
                helperText={
                  form.touched.inputs_style && form.errors.inputs_style
                }
              />
            </Grid>
            <Grid item sm={12}>
              <InputSelectColor
                label={t("Фоновый цвет инпутов")}
                name="inputs_background"
                form={form}
              />
            </Grid>
            <Grid item sm={12}>
              <Select
                value={String(form.values.border_radius) || ""}
                onChange={(value) => {
                  form.setFieldValue("border_radius", Number(value));
                }}
                label={t("Скругление элементов")}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  ...Array(51)
                    .fill(null)
                    .map((_, i) => ({
                      label: `${i} px`,
                      value: `${i}`,
                    })),
                ]}
                error={
                  form.touched.border_radius &&
                  Boolean(form.errors.border_radius)
                }
                helperText={
                  form.touched.border_radius && form.errors.border_radius
                }
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
      <StyledGridButtons
        background={pageBackground}
        container
        hSpace={24}
        mt={24}
      >
        <Grid item sm={6}>
          <Button variant="outlined" fullwidth onClick={onCancel}>
            {t("Отмена")}
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            variant="contained"
            onClick={form.submitForm}
            fullwidth
            disabled={form.isSubmitting}
          >
            {submitButtonText}
          </Button>
        </Grid>
      </StyledGridButtons>
    </>
  );
}

const StyledCaretCircleUpIcon = styled(CaretCircleUpIcon)`
  ${({ open }: { open: boolean }) =>
    !open ? "transform: rotate(180deg)" : ""};
`;

const StyledGridButtons = styled(Grid)<{
  background: string;
}>`
  @media (${RESPONSIVE_SIZES.sm}) {
    position: fixed;
    bottom: 88px;
    width: 100vw;
    background: ${({ background }) => background};
    padding: 24px;
    margin: -24px;
    z-index: 105;
  }
`;
const StyledPopover = styled.div`
  position: fixed;
  z-index: 2;
  top: 50%;
`;
const StyledCover = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;
const ColorView = styled.div`
  width: 40px;
  height: 23px;
  cursor: default;
  border: 1px solid;
  padding: 1px 2px;
  margin-top: -3px;
`;
