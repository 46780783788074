import saveAs from "file-saver";
import * as htmlToImage from "html-to-image";
import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Typography } from "UI/Typography";
import { ReactComponent as QRPlaceholder } from "utils/img/qr-placeholder.svg";

export function handleDownloadQr(id: string) {
  const node = document.getElementById(id);

  if (node) {
    htmlToImage
      .toBlob(node, {
        backgroundColor: "#ffffff",
        style: {
          paddingTop: "100px",
          paddingBottom: "100px",
          display: "block",
        },
        width: 430,
        height: 600,
        canvasWidth: 400,
        canvasHeight: 600,
        pixelRatio: 2,
      })
      .then((blob) => {
        if (window.saveAs) {
          window.saveAs(blob as Blob, "qr.png");
        } else {
          saveAs.saveAs(blob as Blob, "qr.png");
        }
      })
      .catch((error) => {
        console.error("Oops, something went wrong!", error);
      });
  }
}

export const QrDownloader = ({ qrUrl, id }: { qrUrl: string; id?: string }) => {
  const { t } = useTranslation();
  return (
    <div id={id || qrUrl} style={{ display: "none" }}>
      <Typography variant="h5" mb={25} textAlign="center">
        {t("Сканируй меня!")}
      </Typography>
      <StyledQrPlaceholderWrapper>
        {qrUrl ? <QRCodeSVG size={214} value={qrUrl} /> : <QRPlaceholder />}
      </StyledQrPlaceholderWrapper>
      <Typography
        variant="body"
        fontSize={14}
        mt={25}
        mb={25}
        textAlign="center"
      >
        {t("Тут вы можете оставить чаевые!")}
      </Typography>
    </div>
  );
}

const StyledQrPlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
