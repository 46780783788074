import { useFormik } from "formik";
import { forEach, includes, keys } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Modal } from "UI/Modal";
import {
  useCreateCustomizationMutation,
  useCreatePageMutation,
  useDeletePageMutation,
  useUpdatePageMutation,
} from "api/baseAPI/invoicing";
import { StepperForm } from "components/StepperForm";
import { Layout } from "components/layouts/Layout";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { FirstStep } from "./parts/FirstStep";
import { ModalSuccess } from "./parts/ModalSuccess";
import { SecondStep } from "./parts/SecondStep";

export type PagesFormValues = {
  title: string;
  amount_currency: string;
  locale: string;
  available_locales: string[];
  fixed_amount: string;
  active_till: string;
  service: string;
  bp_service: string;
  min_amount: string;
  wallet: string;
  is_reusable: boolean;
  additional_fields: string[];
  preferred_amounts: string[];
  available_payment_methods: number[];
  customization: string;
  prefilled_fields: Record<string, string>;
  status: boolean;
  description_template: string;
  preferred_amounts_logic: 1 | 2;
  kind: 1 | 3;
};

export type CustomizationsFormValues = {
  logo: string;
  background_color: string;
  form_color: string;
  form_accent_color: string;
  font_color: string;
  button_color: string;
  font: string;
};

export const initData: PagesFormValues = {
  title: "",
  amount_currency: "",
  locale: "ua",
  available_locales: ["ua", "en", "ru"],
  fixed_amount: "",
  active_till: "",
  service: "",
  bp_service: "",
  min_amount: "",
  wallet: "",
  additional_fields: [],
  preferred_amounts: [],
  available_payment_methods: [],
  is_reusable: false,
  status: true,
  customization: "",
  prefilled_fields: {},
  description_template: "",
  preferred_amounts_logic: 1,
  kind: 1,
};
export const initDataCustomization: CustomizationsFormValues = {
  logo: "",
  background_color: "",
  form_color: "",
  form_accent_color: "",
  font_color: "",
  button_color: "",
  font: "",
};

export const phoneRegExps = [
  /^\+38(0\d{9})$/,
  /^\+77(\d{10})$/,
  /^\+55(\d{10})$/,
  /^\+54(\d{10})$/,
  /^\+91(\d{10})$/,
];

export const PageCrete = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные страницы"), ""],
    [t("Список страниц"), "/invoicing/pages"],
    [t("Создание платежной страницы"), ""],
  ];

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);

  const [showSuccess, setShowSuccess] = useState(false);
  const [pageId, setPageId] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>("");

  const selectedPointManager = useSelectedPointManager();

  const [createPage, { isSuccess, data, error, isLoading }] =
    useCreatePageMutation();
  const [
    updatePage,
    {
      isSuccess: isSuccessUpdate,
      data: dataUpdate,
      error: errorUpdate,
      isLoading: isLoadingUpdate,
    },
  ] = useUpdatePageMutation();
  const [
    createCustomization,
    {
      isSuccess: isSuccessCreateCustomization,
      data: dataCreateCustomization,
      error: errorCreateCustomization,
      isLoading: isLoadingCreateCustomization,
    },
  ] = useCreateCustomizationMutation();
  const [deletePage, deletePageMeta] = useDeletePageMutation();

  useEffect(() => {
    if (isSuccess && data?.id && !pageId) {
      setPageId(data?.uuid);
      setActiveStep(1);
    }
  }, [isSuccess, data, pageId]);

  useEffect(() => {
    if (isSuccessUpdate && pageId && activeStep === 0) {
      setActiveStep(1);
    }
  }, [isSuccessUpdate, pageId, activeStep]);

  useEffect(() => {
    if (isSuccessUpdate && pageId && activeStep === 1) {
      setShowSuccess(true);
    }
  }, [isSuccessUpdate, pageId, activeStep]);

  useEffect(() => {
    if (deletePageMeta.isSuccess) {
      navigate("/invoicing/pages");
    }
  }, [deletePageMeta.isSuccess]);

  const handleErrors = (dataErrors: string[]) => {
    if (
      includes(dataErrors, "Point does not have required service connected")
    ) {
      toast.error(t("К точке не подключена требуемая услуга"));
      return;
    }
    if (
      includes(dataErrors, "point and wallet have different account owners")
    ) {
      toast.error(t("У точки и кошелька разные владельцы счетов"));
      return;
    }
    if (includes(dataErrors?.[0], "has different currency from page")) {
      toast.error(t("Валюта кошелька отличается от валюты страницы"));
      return;
    }
    toast.error(t("Ошибка"));
  };

  useEffect(() => {
    if (
      errorUpdate &&
      "data" in errorUpdate &&
      "status" in errorUpdate &&
      errorUpdate.status === 400
    ) {
      const dataErrors = errorUpdate.data as string[];
      handleErrors(dataErrors);
    }
  }, [errorUpdate]);

  useEffect(() => {
    if (error && "data" in error && "status" in error && error.status === 400) {
      const dataErrors = error.data as string[];
      handleErrors(dataErrors);
    }
  }, [error]);

  const today = new Date();
  const createForm = useFormik<PagesFormValues>({
    initialValues: initData,
    onSubmit: (values) => {
      const dataForm = {
        point: String(selectedPointManager.point?.id),
        title: values.title,
        amount_currency: values.amount_currency,
        locale: values.locale,
        available_locales: values.available_locales,
        fixed_amount: values.fixed_amount || null,
        active_till: values.active_till || null,
        service: values.service,
        bp_service: values.bp_service || null,
        min_amount: values.min_amount || null,
        wallet: values.wallet,
        is_reusable: values.is_reusable,
        additional_fields: values.additional_fields,
        available_payment_methods: values.available_payment_methods,
        preferred_amounts: [...values.preferred_amounts]?.sort(
          (a: string, b: string) => Number(a) - Number(b)
        ),
        customization: values.customization || null,
        status: values.status ? 1 : 0,
        prefilled_fields: values.prefilled_fields,
        preferred_amounts_logic: values.preferred_amounts_logic,
        kind: values.kind,
        description_template: values.description_template
          ? values.description_template
              .replace(
                `[[{"value":"${t("ФИО")}","prefix":"@"}]]`,
                "{first_name} {last_name} {patronymic}"
              )
              .replace(
                `[[{"value":"${t("Дата рождения")}","prefix":"@"}]]`,
                "{birth_date}"
              )
              .replace(
                `[[{"value":"${t("Имя плательщика")}","prefix":"@"}]]`,
                "{name}"
              )
              .replace(
                `[[{"value":"${t(
                  "Номер телефона плательщика"
                )}","prefix":"@"}]]`,
                "{phone}"
              )
              .replace(
                `[[{"value":"${t("Email плательщика")}","prefix":"@"}]]`,
                "{email}"
              )
              .replace(
                `[[{"value":"${t("Комментарий")}","prefix":"@"}]]`,
                "{comment}"
              )
              .replace(
                `[[{"value":"${t("Страна")}","prefix":"@"}]]`,
                "{country}"
              )
              .replace(`[[{"value":"${t("Город")}","prefix":"@"}]]`, "{city}")
              .replace(
                `[[{"value":"${t("Адрес")}","prefix":"@"}]]`,
                "{address}"
              )
              .replace(
                `[[{"value":"${t("Почтовый индекс")}","prefix":"@"}]]`,
                "{zip_code}"
              )
          : "",
      };
      if (pageId) {
        updatePage({
          ...dataForm,
          id: pageId,
        });
        return;
      }
      createPage(dataForm);
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(t("Обязательное поле")),
      service: Yup.string().required(t("Обязательное поле")),
      wallet: Yup.string().required(t("Обязательное поле")),
      description_template: Yup.string().max(
        256,
        t("Максимум {{characters}} символов", { characters: 256 })
      ),
      active_till: Yup.date().min(
        today,
        t("Дата должна быть не ранее сегодняшнего дня")
      ),
      fixed_amount: Yup.string().when("min_amount", {
        is: (val: string) => val,
        then: Yup.string().test(
          "isValidByLuhn",
          t("Сумма должна быть не меньше минимальной"),
          (value: string | undefined): boolean => {
            if (!value) return true;
            return (Number(createForm.values.min_amount) || 0) <= Number(value);
          }
        ),
      }),
      preferred_amounts: Yup.array()
        .when("min_amount", {
          is: (val: string) => val,
          then: Yup.array().test(
            "isValidByLuhn",
            t("Сумма должна быть не меньше минимальной"),
            (value: (number | undefined)[] | undefined): boolean => {
              if (value?.length === 0) return true;
              const valid = value?.map(
                (num) =>
                  (Number(createForm.values.min_amount) || 0) <= Number(num)
              );
              return !valid?.includes(false);
            }
          ),
        })
        .test(
          "isValidLength",
          t("Должно быть не меньше 2 вариантов"),
          (value: (number | undefined)[] | undefined): boolean =>
            (value && value?.length === 0) ||
            (!!value && value?.length > 1 && value?.length < 4)
        ),
      prefilled_fields: Yup.object().shape({
        phone: Yup.string().test(
          "isValid",
          `${t("Некорректное значение")}. ${t("Пример")} +380123456789`,
          (value: string | undefined): boolean =>
            !value ||
            (!!value &&
              phoneRegExps.map((regExp) => regExp.test(value)).includes(true))
        ),
        email: Yup.string().email(t("Некорректное значение")),
      }),
    }),
  });

  useTranslateFormErrors(createForm);

  const customizationsForm = useFormik<CustomizationsFormValues>({
    initialValues: initDataCustomization,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("logo", selectedFile || "");
      formData.append("background_color", values.background_color);
      formData.append("form_color", values.form_color);
      formData.append("form_accent_color", values.form_accent_color);
      formData.append("font_color", values.font_color);
      formData.append("button_color", values.button_color);
      formData.append("font", values.font);
      createCustomization({ formData });
    },
  });

  const handleCloseForm = () => {
    if (pageId) {
      deletePage({ id: pageId });
    } else {
      navigate("/invoicing/pages");
    }
  };

  const handleSubmitForm = () => {
    if (pageId) {
      if (createForm.isValid) {
        customizationsForm.handleSubmit();
        return;
      }
      createForm.validateForm();
      forEach(keys(createForm.errors), (key) =>
        createForm.setFieldTouched(key, true)
      );
    } else {
      createForm.handleSubmit();
    }
  };

  useEffect(() => {
    if (isSuccessCreateCustomization && dataCreateCustomization?.id) {
      createForm.setFieldValue(
        "customization",
        dataCreateCustomization?.id || ""
      );
      setTimeout(() => createForm.handleSubmit());
    }
  }, [isSuccessCreateCustomization, dataCreateCustomization]);

  useServerErrorsInForm(createForm.setFieldError, error);
  useServerErrorsInForm(createForm.setFieldError, errorUpdate);
  useServerErrorsInForm(
    customizationsForm.setFieldError,
    errorCreateCustomization
  );

  const steps = [t("Конструктор"), t("Оформление")];
  return (
    <Layout title={t("Создание платежной страницы")} breadCrumbs={breadCrumbs}>
      <Box
        px={24}
        py={32}
        flex
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "calc(100vh - 124px)" }}
      >
        <Box style={{ width: "100%" }}>
          <StepperForm stepsLabels={steps} activeStep={activeStep} />
          {activeStep === 0 ? (
            <FirstStep form={createForm} />
          ) : (
            <SecondStep
              pageSubmit={() => createForm.handleSubmit()}
              formPage={createForm}
              form={customizationsForm}
              pay_url={dataUpdate?.pay_url || data?.pay_url || ""}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              customSubmit={() => handleSubmitForm()}
              setImageUrl={setImageUrl}
              imageUrl={imageUrl}
            />
          )}
        </Box>
        <Box flex mt={20}>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            variant="outlined"
            size="small"
            onClick={() =>
              activeStep === 0 ? handleCloseForm() : setActiveStep(0)
            }
          >
            {pageId ? t("Назад") : t("Отмена")}
          </Button>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            ml={24}
            variant="contained"
            size="small"
            disabled={
              isLoadingCreateCustomization || isLoading || isLoadingUpdate
            }
            onClick={handleSubmitForm}
          >
            {activeStep ? t("Сохранить") : t("Следующий шаг")}
          </Button>
        </Box>

        <Modal
          open={showSuccess}
          hideHeader
          width={370}
          onClose={() => setShowSuccess(false)}
        >
          <ModalSuccess
            create
            payUrl={dataUpdate?.pay_url || data?.pay_url || ""}
          />
        </Modal>
      </Box>
    </Layout>
  );
};
