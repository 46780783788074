import { FormikProps } from "formik";
import i18n from "i18next";
import {
  AdditionalPayoutsField,
  PayoutFieldType,
} from "api/baseAPI/payouts";
import { Checkbox } from "UI/Form/Checkbox";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { PayoutFormData } from "pages/payout/logic/payoutsCreateLogic";

interface AdditionalFieldsProps {
  form: FormikProps<PayoutFormData>;
  fields?: AdditionalPayoutsField[] | null;
  payoutId: string;
}

export const AdditionalFields = ({
  form,
  fields,
  payoutId,
}: AdditionalFieldsProps) => (
    <Grid container hSpace={10} vSpace={10}>
      {fields?.length ? (
        fields.map((field) => {
          switch (field.type) {
            case PayoutFieldType.STRING:
              return (
                <Grid item mt={7} key={field.gate_key} sm={12} md={4}>
                  <TextInput
                    name={`payouts.${payoutId}.fields.${field.gate_key}`}
                    size="small"
                    placeholder={
                      field.translations?.[`placeholder_${i18n.language}`]
                        ? field.translations?.[`placeholder_${i18n.language}`]
                        : field.placeholder
                    }
                    onBlur={(e) => {
                      form.handleBlur(e);
                      form.setFieldTouched(
                        `payouts.${payoutId}.fields.${field.gate_key}`,
                        true
                      );
                    }}
                    onChange={(value) => {
                      form.setFieldValue(
                        `payouts.${payoutId}.fields.${field.gate_key}`,
                        value
                      );
                    }}
                    value={
                      form.values.payouts?.[payoutId].fields?.[
                        field.gate_key
                      ] || ""
                    }
                    error={
                      // @ts-ignore
                      form.touched.payouts?.[payoutId]?.fields?.[
                        field.gate_key
                      ] &&
                      Boolean(
                        form.errors.payouts?.[payoutId]?.fields?.[
                          // @ts-ignore
                          field.gate_key
                        ]
                      )
                    }
                    helperText={
                      // @ts-ignore
                      form.touched.payouts?.[payoutId]?.fields?.[
                        field.gate_key
                      ] &&
                      // @ts-ignore
                      form.errors.payouts?.[payoutId]?.fields?.[field.gate_key]
                    }
                  />
                </Grid>
              );
            case PayoutFieldType.INTEGER:
              return (
                <Grid item mt={7} sm={12} md={4} key={field.gate_key}>
                  <TextInput
                    name={`payouts.${payoutId}.fields.${field.gate_key}`}
                    size="small"
                    placeholder={
                      field.translations?.[`placeholder_${i18n.language}`]
                        ? field.translations?.[`placeholder_${i18n.language}`]
                        : field.placeholder
                    }
                    onBlur={(e) => {
                      form.handleBlur(e);
                      form.setFieldTouched(
                        `payouts.${payoutId}.fields.${field.gate_key}`,
                        true
                      );
                    }}
                    onChange={(value) => {
                      form.setFieldValue(
                        `payouts.${payoutId}.fields.${field.gate_key}`,
                        value.replace(/[^0-9]/g, "")
                      );
                    }}
                    value={
                      form.values.payouts?.[payoutId].fields?.[
                        field.gate_key
                      ] || ""
                    }
                    error={
                      // @ts-ignore
                      form.touched.payouts?.[payoutId]?.fields?.[
                        field.gate_key
                      ] &&
                      Boolean(
                        form.errors.payouts?.[payoutId]?.fields?.[
                          // @ts-ignore
                          field.gate_key
                        ]
                      )
                    }
                    helperText={
                      // @ts-ignore
                      form.touched.payouts?.[payoutId]?.fields?.[
                        field.gate_key
                      ] &&
                      // @ts-ignore
                      form.errors.payouts?.[payoutId]?.fields?.[field.gate_key]
                    }
                  />
                </Grid>
              );
            case PayoutFieldType.FLOAT:
              return (
                <Grid item mt={7} sm={12} md={4} key={field.gate_key}>
                  <TextInput
                    name={`payouts.${payoutId}.fields.${field.gate_key}`}
                    size="small"
                    placeholder={
                      field.translations?.[`placeholder_${i18n.language}`]
                        ? field.translations?.[`placeholder_${i18n.language}`]
                        : field.placeholder
                    }
                    onBlur={(e) => {
                      form.handleBlur(e);
                      form.setFieldTouched(
                        `payouts.${payoutId}.fields.${field.gate_key}`,
                        true
                      );
                    }}
                    onChange={(value) => {
                      const trimValue = value
                        .replace(/[^0-9.]/g, "")
                        .slice(0, 20);
                      form.setFieldValue(
                        `payouts.${payoutId}.fields.${field.gate_key}`,
                        `${trimValue.split(".")[0] || ""}${
                          trimValue.includes(".")
                            ? `.${
                                (trimValue.split(".")[1] || "").length > 2
                                  ? trimValue.split(".")[1].slice(0, 2)
                                  : trimValue.split(".")[1]
                              }`
                            : ""
                        }`
                      );
                    }}
                    value={
                      form.values.payouts?.[payoutId].fields?.[
                        field.gate_key
                      ] || ""
                    }
                    error={
                      // @ts-ignore
                      form.touched.payouts?.[payoutId]?.fields?.[
                        field.gate_key
                      ] &&
                      Boolean(
                        form.errors.payouts?.[payoutId]?.fields?.[
                          // @ts-ignore
                          field.gate_key
                        ]
                      )
                    }
                    helperText={
                      // @ts-ignore
                      form.touched.payouts?.[payoutId]?.fields?.[
                        field.gate_key
                      ] &&
                      // @ts-ignore
                      form.errors.payouts?.[payoutId]?.fields?.[field.gate_key]
                    }
                  />
                </Grid>
              );
            case PayoutFieldType.BOOLEAN:
              return (
                <Grid item mt={7} sm={12} md={4} key={field.gate_key}>
                  <Checkbox
                    name={field.gate_key}
                    value={
                      !!form.values.payouts?.[payoutId].fields?.[field.gate_key]
                    }
                    onChange={(val) =>
                      form.setFieldValue(
                        `payouts.${payoutId}.fields.${field.gate_key}`,
                        val
                      )
                    }
                    label={
                      field.translations?.[`placeholder_${i18n.language}`]
                        ? field.translations?.[`placeholder_${i18n.language}`]
                        : field.placeholder
                    }
                  />
                </Grid>
              );
            default:
              return <></>;
          }
        })
      ) : (
        <></>
      )}
    </Grid>
  )
