import { ReactElement } from "react";
import * as yup from "yup";
import { ReactComponent as PhoneIcon } from "utils/img/phone.svg";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { PhoneInput } from "pages/payout/logic/forms/components/inputs/PhoneInput";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class PhonePayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "phone";

  kind: 2 = 2;

  name: string = "По номеру телефона";

  mobileName: string = "По номеру тел.";

  icon: ReactElement = (<PhoneIcon />);

  csvExample: string | null = "/file-samples/sample-phone.csv";

  xlsxExample: string | null = "/file-samples/sample-phone.xlsx";

  additional_payout_information = null;

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <PhoneInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .min(12, "Неправильный формат номера телефона!")
    .required("Введите номер телефона");

  get serviceId() {
    return (
      (this.pointManager?.service_config?.phone_payouts_available &&
        this.pointManager?.service_config?.default_phone_payout_service &&
        this.getService(
          this.pointManager?.service_config?.default_phone_payout_service
        )) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }
}
