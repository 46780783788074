import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentRefreshToken,
} from "reducers/authSlice";

export const useAuth = () => {
  const access_token = useSelector(selectCurrentToken);
  const refresh_token = useSelector(selectCurrentRefreshToken);

  return useMemo(
    () => ({ access_token, refresh_token }),
    [access_token, refresh_token]
  );
};
