export type AlignItems = {
  alignItems?: "flex-start" | "center" | "flex-end";
};

export function alignItemsProps(props: AlignItems) {
  return (({ alignItems }) => ({
    alignItems,
  }))(props);
}

export function alignItemsStyle(props: AlignItems) {
  return `
    ${props.alignItems ? `align-items: ${  props.alignItems  };` : ""}
  `;
}
