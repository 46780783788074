import React from "react";
import styled from "styled-components";


import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import { ReactComponent as WarningIcon } from "utils/img/warning.svg";

type Props = {
  onClose: () => void;
  title?: string;
};

export const ModalInfo = (props: Props): React.ReactElement => {
  const { onClose, title } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Modal
      open
      hideHeader
      height={250}
      width={400}
      onClose={() => onClose()}
    >
      <Box p={27} style={{ textAlign: "center" }}>
        <Box
          flex
          alignItems="center"
          justifyContent="center"
          style={{
            height: 84,
            width: 84,
            background: "#EAEFF7",
            margin: "auto",
          }}
        >
          <IconColor>
            <WarningIcon />
          </IconColor>
        </Box>
        <Typography variant="h4" mt={32}>
          {title || t("Запрос на выгрузку истории баланса!")}
        </Typography>
        <Typography
          variant="subtitle2"
          mt={8}
          mb={32}
          style={{ color: "#393939" }}
        >
          {t("Для получения файла перейдите в раздел отчетности")}
        </Typography>
        <Grid
          container
          flex
          alignItems="center"
          justifyContent="space-between"
          mt={24}
        >
          <Grid item>
            <Button
              mr={24}
              size="small"
              variant="outlined"
              onClick={() => onClose()}
            >
              {t("Отмена")}
            </Button>
          </Grid>
          <Grid item sm="fill">
            <Button
              size="small"
              fullwidth
              onClick={() => {
                navigate("/reports");
                onClose();
              }}
            >
              {t("Перейти в раздел")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

const IconColor = styled.div`
  svg {
    width: 36px !important;
    height: 36px !important;
    path {
      fill: rgba(44, 96, 176, 1) !important;
    }
  }
`;
