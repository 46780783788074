import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

type SettersKeys<Type> = {
  [Property in keyof Type as `set_${string & Property}`]: Type[Property];
};

export interface IUseUrlQueryResult<Type> {
  queryParams: Type;
  querySetters: Record<keyof SettersKeys<Type>, (value: string) => void>;
}

export const useUrlQuery = <T extends Record<keyof T, string>>(
  initParameters: T,
  disabledRedefine?: boolean
): IUseUrlQueryResult<T> => {
  const parameters = { ...initParameters };
  const navigate = useNavigate();

  // Берем параметры из url
  const currentQString = useLocation().search;
  const query = new URLSearchParams(currentQString);

  // Переопределяем дефолты
  if (!disabledRedefine) {
    (Object.keys(parameters) as Array<keyof T>).forEach((key: keyof T) => {
      const p = query.get(String(key));
      if (p) {
        parameters[key] = p as T[keyof T];
      }
    });
  }

  // Запоминаем параметры
  const [queryParams, setQueryParams] = useState(parameters);

  // Формируем новый url
  (Object.keys(parameters) as Array<keyof T>).forEach((key: keyof T) => {
    if (
      queryParams[key] !== "" &&
      queryParams[key] !== undefined &&
      queryParams[key] !== "undefined" &&
      queryParams[key] !== null &&
      queryParams[key] !== "null"
    ) {
      query.set(String(key), queryParams[key]);
    } else {
      query.delete(String(key));
    }
  });

  const nextQString = query.toString();

  // Сравниваем текущий с новым. Если изменился то переходим на новый
  useEffect(() => {
    if (currentQString !== `?${nextQString}`) {
      navigate({ search: nextQString }, { replace: true });
    }
  }, [currentQString, nextQString, navigate]);

  // Формируем набор сеттеров
  const querySetters = (Object.keys(queryParams) as Array<keyof T>).reduce(
    (acc: Record<keyof SettersKeys<T>, (value: string) => void>, key) => {
      acc[`set_${String(key)}` as keyof SettersKeys<T>] = (value: string) => {
        setQueryParams((prev: typeof queryParams) => ({
          ...prev,
          [key]: value,
        }));
      };

      return acc;
    },
    {} as Record<keyof SettersKeys<T>, (value: string) => void>
  );

  return { queryParams, querySetters };
};
