import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { DateSelector } from "components/RangeSelector/DateSelector";
import { useFilterMakerResult } from "utils/filterMaker";
import { Grid } from "UI/Grid";
import { TableFilter } from "components/table/TableFilter";

export const PointLogsFilters = ({
  filters,
}: {
  filters: useFilterMakerResult<{
    page: string;
    search: string;
    log_to: string;
    log_from: string;
  }>;
}) => {
  const { t } = useTranslation();
  const { values, setValue, errors } = filters;
  return (
    <TableFilter
      onSubmit={filters.handleSubmit}
      onReset={filters.handleReset}
      disabledReset={filters.disabledReset}
      disableSubmit={!!errors}
      widthBox="50%"
    >
      <Grid container mt={24} hSpace={24} vSpace={24}>
        <Grid item sm={12}>
          <DateSelector
            label={t("Период")}
            start={values.log_from ? DateTime.fromISO(values.log_from) : null}
            end={values.log_to ? DateTime.fromISO(values.log_to) : null}
            set_start={(val) => setValue("log_from", val)}
            set_end={(val) => setValue("log_to", val)}
          />
        </Grid>
      </Grid>
    </TableFilter>
  );
};
