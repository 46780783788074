import styled from "styled-components";
import { RESPONSIVE_SIZES } from "utils/tools";

type Direction = "horizontal" | "vertical";

interface DividerProps {
  width?: number;
  color?: string;
  direction?: Direction;
  smDirection?: Direction;
  mdDirection?: Direction;
  lgDirection?: Direction;
  xlDirection?: Direction;
  xxlDirection?: Direction;
}

export const Divider = ({
  width,
  color,
  direction,
  smDirection,
  mdDirection,
  lgDirection,
  xlDirection,
  xxlDirection,
}: DividerProps) => (
  <StyledDivider
    direction={direction}
    smDirection={smDirection}
    mdDirection={mdDirection}
    lgDirection={lgDirection}
    xlDirection={xlDirection}
    xxlDirection={xxlDirection}
    width={width || 1}
    color={color || "#ECF0F6"}
  />
);

const StyledDivider = styled.div`
  @media (${RESPONSIVE_SIZES.sm}) {
    ${(props: {
      direction?: Direction;
      smDirection?: Direction;
      mdDirection?: Direction;
      lgDirection?: Direction;
      xlDirection?: Direction;
      xxlDirection?: Direction;
      smHide?: boolean;
      mdHide?: boolean;
      lgHide?: boolean;
      xlHide?: boolean;
      xxlHide?: boolean;
      width: number;
      color: string;
    }) =>
      props.smDirection
        ? `width: 100%; height: ${props.width}px;`
        : `width: ${props.width}px; min-height: 100%;`}

    ${(props) => (props.smHide ? "display: none;" : "")}
  }
  @media (${RESPONSIVE_SIZES.overSm}) and (${RESPONSIVE_SIZES.md}) {
    ${(props) =>
      props.mdDirection
        ? `width: 100%; height: ${props.width}px;`
        : `width: ${props.width}px; min-height: 100%;`}

    ${(props) => (props.mdHide ? "display: none;" : "")}
  }
  @media (${RESPONSIVE_SIZES.overMd}) and (${RESPONSIVE_SIZES.lg}) {
    ${(props) =>
      props.lgDirection
        ? `width: 100%; height: ${props.width}px;`
        : `width: ${props.width}px; min-height: 100%;`}

    ${(props) => (props.lgHide ? "display: none;" : "")}
  }
  @media (${RESPONSIVE_SIZES.overLg}) and (${RESPONSIVE_SIZES.xl}) {
    ${(props) =>
      props.xlDirection
        ? `width: 100%; height: ${props.width}px;`
        : `width: ${props.width}px; min-height: 100%;`}

    ${(props) => (props.xlHide ? "display: none;" : "")}
  }
  @media (${RESPONSIVE_SIZES.overXl}) and (${RESPONSIVE_SIZES.xxl}) {
    ${(props) =>
      props.xxlDirection
        ? `width: 100%; height: ${props.width}px;`
        : `width: ${props.width}px; min-height: 100%;`}

    ${(props) => (props.xxlHide ? "display: none;" : "")}
  }
  @media (${RESPONSIVE_SIZES.overXxl}) {
    ${(props) =>
      props.xxlDirection
        ? `width: 100%; height: ${props.width}px;`
        : `width: ${props.width}px; min-height: 100%;`}

    ${(props) => (props.xxlHide ? "display: none;" : "")}
  }
  background-color: ${(props) => props.color};

  ${(props) =>
    props.direction === "vertical"
      ? `width: 100% !important; height: ${props.width}px !important; min-height: 1px !important;`
      : ""}
`;
