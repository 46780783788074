import React, { useEffect } from "react";
import styled from "styled-components";
import { isNumber } from "lodash";
import { ReactComponent as CloseIcon } from "utils/img/close.svg";
import { RESPONSIVE_SIZES } from "utils/tools";

export const Modal = ({
  open,
  children,
  title,
  width,
  height,
  maxHeight,
  hideHeader,
  disabledOverlay,
  isSmallTitle,
  onClose,
}: {
  open: boolean;
  children: React.ReactElement | React.ReactElement[] | string;
  title?: string;
  width?: number | string;
  height?: number;
  maxHeight?: string;
  hideHeader?: boolean;
  disabledOverlay?: boolean;
  isSmallTitle?: boolean;
  onClose: () => void;
}) => {
  useEffect(() => {
    window.addEventListener("keyup", handleEscape);
    return window.removeEventListener("keyup", handleEscape);
  }, []);

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    }
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [open]);

  if (!open) return null;

  return (
    <StyledModalWrapper>
      <StyledModalWindow
        width={width}
        height={height}
        maxheight={maxHeight || ""}
        disabledoverlay={disabledOverlay}
      >
        {!hideHeader && (
          <StyledModalHeader small={isSmallTitle}>
            <StyledModalHeaderTitle>{title}</StyledModalHeaderTitle>
            <StyledModalClose onClick={handleClose}>
              <CloseIcon />
            </StyledModalClose>
          </StyledModalHeader>
        )}
        {children}
      </StyledModalWindow>
      <StyledModalOverlay onClick={handleClose} />
    </StyledModalWrapper>
  );
}

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(25, 32, 46, 0.72);
`;

const StyledModalWindow = styled.div<{
  width?: number | string;
  height?: number;
  maxheight?: string;
  disabledoverlay?: boolean;
}>`
  position: fixed;
  width: ${({ width }) =>
    width ? `${width}${isNumber(width) ? "px" : ""}` : "400px"};
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  overflow-y: ${({ disabledoverlay }) =>
    disabledoverlay ? "visible" : "auto"};
  max-width: 100%;
  max-height: ${({ maxheight }) => (maxheight || "100vh")};
  background: white;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledModalHeader = styled.div<{ small?: boolean }>`
  height: ${({ small }) => (small ? "40px" : "80px")};
  display: flex;
  align-items: center;
  padding: 0 ${({ small }) => (small ? "15px" : "40px")};
  justify-content: space-between;
`;

const StyledModalHeaderTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #19202e;
  @media (${RESPONSIVE_SIZES.md}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const StyledModalClose = styled.div`
  padding: 8px;
  cursor: pointer;
`;
