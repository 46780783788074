import { isNumber, isString } from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { ReactComponent as CircleCheckIcon } from "utils/img/circle-check.svg";
import { ReactComponent as CircleCloseIcon } from "utils/img/circle-close.svg";
import { ReactComponent as CircleInfoIcon } from "utils/img/info-circle.svg";
import { ReactComponent as LinkIcon } from "utils/img/link.svg";
import { ReactComponent as CircleStopIcon } from "utils/img/stop-circle.svg";

export const ParamOnOff = ({ includes }: { includes: boolean }) => includes ? <StyledCircleCheckIcon /> : <StyledCircleCloseIcon />

export const DataItem = ({
  content,
  text,
}: {
  content: string | number | React.ReactElement;
  text: string;
}) => (
    <Box
      p={16}
      style={{
        border: "1px solid #c8cfdc",
        minHeight: 38,
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Typography pb={6} variant="subtitle3">
        {text}
      </Typography>
      {isString(content) || isNumber(content) ? (
        <Typography variant="inputText">{content}</Typography>
      ) : (
        content
      )}
    </Box>
  )

export const CopyCell = ({
  content,
  text,
}: {
  content: number | string;
  text?: string | number;
}) => {
  const { t } = useTranslation();

  const handleCopy = () => {
    navigator.clipboard.writeText(String(content));
    toast.info(t("Скопировано"));
  };

  return (
    <Box
      onClick={handleCopy}
      flex
      alignItems="center"
      style={{
        cursor: "pointer",
      }}
    >
      <IconColor color="#2C60B0">
        <LinkIcon />
      </IconColor>
      <>&nbsp;</>
      <Typography variant="subtitle3">{text || content}</Typography>
    </Box>
  );
}

export const StatusPageTransaction = ({ status }: { status: 1 | 0 | -1 }) => {
  const { t } = useTranslation();
  const { success, error, warning } = getColors();

  return (
    <Box flex alignItems="center" justifyContent="center" nowrap>
      <>
        {status  ===  1 && (
          <IconColor color={success}>
            <CircleCheckIcon />
          </IconColor>
        )}
        {status  ===  0 && (
          <IconColor color={error}>
            <CircleStopIcon />
          </IconColor>
        )}
        {status  ===  -1 && (
          <IconColor color={warning}>
            <CircleInfoIcon />
          </IconColor>
        )}
        &nbsp;
        {status  ===  1 && t("Успешная")}
        {status  ===  0 && t("Неуспешная")}
        {status  ===  -1 && t("В обработке")}
      </>
    </Box>
  );
}

const IconColor = styled.div`
  svg {
    width: 12px !important;
    height: 12px !important;

    path {
      fill: ${(props: { color: string }) => props.color} !important;
    }
  }
`;

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  path {
    fill: #3ea959 !important;
  }
`;

const StyledCircleCloseIcon = styled(CircleCloseIcon)`
  path {
    fill: #d00025 !important;
  }
`;
