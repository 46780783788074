import { useFormik } from "formik";
import { includes, isObject, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Button } from "UI/Button";
import { Divider } from "UI/Divider";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
// import { Typography } from "../../UI/Typography";
import { useLazyGetServicesCommissionsQuery } from "api/baseAPI/services";
import { useGetUsersMeСustomPermissionsQuery } from "api/baseAPI/user";
import {
  useCryptoTopUpMutation,
  useLazyGetWalletQuery,
} from "api/baseAPI/wallets";
import { Layout } from "components/layouts/Layout";
import {
  useSelectedPointManager,
  useSelectedWalletManager,
} from "hooks/useSelectedEntity";
import { setSelectedWallet } from "reducers/basicSlice";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import {
  handleChangeNumber,
  numberForInput,
  visibleNumber,
} from "utils/numbers";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { Select } from "UI/Form/Select";
import { Box } from "UI/Box";
import { WalletInfo } from "./parts/WalletInfo";

export const CryptoTopUp = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Пополнение"), ""],
    [t("Криптовалютой"), ""],
  ];

  const dispatch = useDispatch();
  const selectedPointManager = useSelectedPointManager();
  const selectedWallet = useSelectedWalletManager();

  const getСustomPermissions = useGetUsersMeСustomPermissionsQuery();
  const [getWalletData, walletData] = useLazyGetWalletQuery();

  useEffect(() => {
    if (selectedWallet?.id) {
      getWalletData({ id: selectedWallet.id });
    }
  }, [selectedWallet?.id]);

  const [isShowPayModal, setIsShowPayModal] = useState<boolean>(false);
  const [payUrl, setPayUrl] = useState<string | null>(null);

  const [commissions, setCommissions] = useState<{
    customer: {
      fix: number | null;
      max: number | null;
      min: number | null;
      percent: number | null;
    };
    point: {
      fix: number | null;
      max: number | null;
      min: number | null;
      percent: number | null;
    };
    provider: {
      fix: number | null;
      max: number | null;
      min: number | null;
      percent: number | null;
    };
  } | null>(null);

  const [getServiceCommissions, { isError: isErrorServiceCommissions }] =
    useLazyGetServicesCommissionsQuery();

  const [cryptoTopUp, cryptoTopUpMeta] = useCryptoTopUpMutation();

  useSuccessNotification([]);
  useErrorNotification([cryptoTopUpMeta]);

  const form = useFormik({
    initialValues: {
      amount: "",
      currency: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      if (walletData?.data?.currency) {
        cryptoTopUp({
          walletId: selectedWallet.id,
          point: selectedPointManager.point.id,
          currency: walletData?.data?.currency,
          amount: Number(values.amount),
          service:
            selectedPointManager.service_config?.wallet_crypto_top_up?.[
              values.currency
            ],
        })
          .unwrap()
          .then((data) => {
            setIsShowPayModal(true);
            const payUrlLink = data?.response?.response?.result?.pay_url;
            if (payUrlLink) {
              setPayUrl(payUrlLink);
            } else {
              setPayUrl(null);
            }
          })
          .finally(() => {
            setSubmitting(false);
            setIsShowPayModal(true);
          });
      }
    },
    validationSchema: yup.object().shape({
      amount: yup.number().required(t("Обязательное поле")),
      currency: yup.string().required(t("Обязательное поле")),
    }),
  });

  useEffect(() => {
    if (
      selectedPointManager.service_config?.wallet_crypto_top_up &&
      selectedPointManager.point_id &&
      form.values.currency &&
      selectedPointManager.service_config?.wallet_crypto_top_up?.[
        form.values.currency
      ]
    ) {
      getServiceCommissions({
        serviceId:
          selectedPointManager.service_config?.wallet_crypto_top_up?.[
            form.values.currency
          ],
        pointId: selectedPointManager.point_id,
      })
        .unwrap()
        .then((data) => setCommissions(data));
    }
  }, [
    selectedPointManager.service_config?.card_top_up,
    selectedPointManager.point_id,
    form.values.currency,
  ]);

  useTranslateFormErrors(form);

  const chargedCommission = useMemo(() => {
    if (commissions?.point && form.values.amount) {
      const pointPercent = commissions.point.percent || 0;
      const pointFix = commissions.point.fix || 0;

      const percent = (Number(form.values.amount) * -pointPercent) / 100;
      return (-pointFix + percent).toFixed(2);
    }
    return "0.00";
  }, [commissions, form.values.amount]);

  const handleSelectWallet = (item: { id: number; name: string }) => {
    dispatch(setSelectedWallet(item));
  };

  return (
    <Layout title={t("Пополнение")} breadCrumbs={breadCrumbs}>
      <Grid
        container
        style={{
          background: "#ffffff",
          padding: "24px",
        }}
        hSpace={24}
        vSpace={12}
      >
        <Grid item sm={12} lg={8}>
          <WalletInfo
            selectedWallet={selectedWallet}
            onChangeSelectedWallet={handleSelectWallet}
          />
        </Grid>
        <Grid item responsive={{ sm: { hidden: true }, lg: { hidden: false } }}>
          <Divider />
        </Grid>
        <Grid item sm={12} lg="fill">
          <Grid
            container
            vSpace={16}
            responsive={{ sm: { direction: "column" } }}
          >
            <Grid item sm="fill">
              <Box style={{ widows: "100%" }} mb={12}>
                <Select
                  value={form.values.currency}
                  onChange={(value) => form.setFieldValue("currency", value)}
                  label={t("Валюта")}
                  size="small"
                  options={
                    isObject(
                      selectedPointManager.service_config?.wallet_crypto_top_up
                    )
                      ? map(
                          Object.keys(
                            selectedPointManager.service_config
                              ?.wallet_crypto_top_up
                          ),
                          (currency) => ({ value: currency, label: currency })
                        )
                      : []
                  }
                  error={form.touched.currency && Boolean(form.errors.currency)}
                  helperText={form.touched.currency && form.errors.currency}
                />
              </Box>
              <TextInput
                type="text"
                label={t("Сумма")}
                value={numberForInput(form.values.amount)}
                onChange={(val) => {
                  form.setFieldValue(
                    "amount",
                    handleChangeNumber(val.replace(/[^0-9.]/g, ""))
                  );
                }}
                size="small"
                helperText={
                  form.touched.amount && form.errors.amount
                    ? form.errors.amount
                    : `${t("Комиссия")} ${
                        walletData?.data?.currency
                      } ${visibleNumber(chargedCommission)}`
                }
                error={form.touched.amount && Boolean(form.errors.amount)}
              />
              <Button
                mt={10}
                onClick={() => form.submitForm()}
                disabled={
                  form.isSubmitting ||
                  isErrorServiceCommissions ||
                  !includes(getСustomPermissions.data, "crypto_top_up")
                }
                fullwidth
              >
                {t("Пополнить")}
              </Button>
              <Modal
                title={t("Оплата")}
                open={isShowPayModal && payUrl !== null}
                height={650}
                width={500}
                onClose={() => setIsShowPayModal(false)}
                isSmallTitle
              >
                {payUrl ? (
                  <iframe
                    src={payUrl}
                    title="iframe"
                    width="100%"
                    height="630px"
                    style={{ border: "none" }}
                    allow="clipboard-read; clipboard-write"
                  />
                ) : (
                  <></>
                )}
              </Modal>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}
