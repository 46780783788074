import { DateTime } from "luxon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getColors } from "utils/getColors";
import { RESPONSIVE_SIZES } from "utils/tools";
import { TimeSelector } from "./TimeSelector";
import chevronDown from "./assets/chevron-down.svg";
import { ReactComponent as ChevronLeft } from "./assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "./assets/chevron-right.svg";
import { StyledCalendarDay } from "./Calendar";

export const CalendarInput = ({
  date,
  onDateSet,
  onTimeSet,
  oldDate,
}: {
  date: DateTime | null;
  onDateSet: (date: DateTime) => void;
  onTimeSet: (date: DateTime) => void;
  oldDate: boolean;
}): React.ReactElement => {
  const { t } = useTranslation();

  const MONTHS: { [key: number]: string } = {
    1: t("Январь"),
    2: t("Февраль"),
    3: t("Март"),
    4: t("Апрель"),
    5: t("Май"),
    6: t("Июнь"),
    7: t("Июль"),
    8: t("Август"),
    9: t("Сентябрь"),
    10: t("Октябрь"),
    11: t("Ноябрь"),
    12: t("Декабрь"),
  };

  const WEEK_DAYS: { [key: string]: string } = {
    1: t("пн"),
    2: t("вт"),
    3: t("ср"),
    4: t("чт"),
    5: t("пт"),
    6: t("сб"),
    7: t("вс"),
  };
  const { accentColor, pageBackground } = getColors();
  const currentDate = DateTime.local();
  const currentYear = currentDate.year;
  const currentMonth = currentDate.month;
  const currentDay = currentDate.day;

  const YEARS_RANGE = oldDate ? 100 : 10;
  const YEAR_NOW = oldDate ? currentDate.year - 15 : currentDate.year;

  let initYear = currentDate.year;
  let initMonth = currentDate.month;

  if (date) {
    initYear = date.year;
    initMonth = date.month;
  }

  const [displayedYear, setDisplayedYear] = useState(initYear);
  const [displayedMonth, setDisplayedMonth] = useState<number>(initMonth);

  const {daysInMonth} = DateTime.local(displayedYear, displayedMonth);
  const firstDayNumber = DateTime.local(displayedYear, displayedMonth).weekday;

  // Handlers
  const selectDayHandler = (day: number) => {
    onDateSet(DateTime.local(displayedYear, displayedMonth, day));
  };

  const increeseMonthHandler = () => {
    let next = displayedMonth + 1;
    if (displayedMonth === 12) {
      setDisplayedYear(displayedYear + 1);
      next = 1;
    }
    setDisplayedMonth(next);
  };

  const decreeseMontHandler = () => {
    let next: keyof typeof MONTHS = displayedMonth - 1;
    if (displayedMonth === 1) {
      setDisplayedYear(displayedYear - 1);
      next = 12;
    }
    setDisplayedMonth(next);
  };

  return (
    <StyledCalendarWrapper>
      <StyledSelectWrapper>
        <StyledMonthNovigator onClick={decreeseMontHandler}>
          <ChevronLeft />
        </StyledMonthNovigator>
        <StyledSelector
          value={displayedMonth}
          onChange={(e) => setDisplayedMonth(Number(e.target.value))}
        >
          {Object.keys(MONTHS).map((monthNumber) => (
            <option key={monthNumber} value={monthNumber}>
              {MONTHS[Number(monthNumber)]}
            </option>
          ))}
        </StyledSelector>
        <StyledSelector
          value={displayedYear}
          onChange={(e) => setDisplayedYear(Number(e.target.value))}
        >
          {Array(YEARS_RANGE)
            .fill(null)
            .map((_, i) => (
                <option key={i} value={YEAR_NOW - i}>
                  {YEAR_NOW - i}
                </option>
              ))}
        </StyledSelector>
        <StyledMonthNovigator onClick={increeseMonthHandler}>
          <ChevronRight />
        </StyledMonthNovigator>
      </StyledSelectWrapper>

      {/* Line of days names */}
      <StyledWeekNamesWrapper>
        {Array(7)
          .fill(null)
          .map((_, i) => (
            <div key={i}>{WEEK_DAYS[String(i + 1)]}</div>
          ))}
      </StyledWeekNamesWrapper>

      {/* Days in months */}
      <StyledDaysWrapper>
        {Array(firstDayNumber - 1)
          .fill(null)
          .map((_, i) => (
            <div key={i + 100} />
          ))}
        {Array(daysInMonth)
          .fill(null)
          .map((_, i) => {
            const selected =
              !!(date &&
              displayedYear === date.year &&
              displayedMonth === date.month &&
              date.day  ===  i + 1);

            const current =
              !!(displayedYear === currentYear &&
              displayedMonth === currentMonth &&
              currentDay  ===  i + 1);

            const inRange = false;
            const disabled = false;

            return (
              <StyledCalendarDay
                key={i}
                selected={selected}
                current={current}
                inRange={inRange}
                onClick={() => selectDayHandler(i + 1)}
                disabled={disabled}
                background={pageBackground}
                rangeBackground={accentColor}
              >
                {i + 1}
              </StyledCalendarDay>
            );
          })}
      </StyledDaysWrapper>

      <TimeSelector
        type="start"
        rangeStart={date}
        rangeEnd={date ? date.endOf("day") : date}
        onChange={onTimeSet}
      />
    </StyledCalendarWrapper>
  );
};

const StyledCalendarWrapper = styled.div`
  padding: 2px;
  margin: 0;
  @media (${RESPONSIVE_SIZES.sm}) {
    margin: 20px 0;
  }
`;

const StyledMonthNovigator = styled.button`
  padding: 2px 5px;
  border: none;
  background-color: white;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const StyledSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSelector = styled.select`
  background: #ffffff;
  border: 1px solid #e8ebf1;
  box-sizing: border-box;
  padding: 5px 25px 5px 10px;
  appearance: none;
  background-image: url(${chevronDown});
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) center;
  &:focus {
    outline: none;
  }
`;

const StyledWeekNamesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 29px);
  color: rgba(0, 0, 0, 0.5);
  font-weight: 300;
  border-top: 1px solid (0, 0, 0, 0.5);
  margin: 5px 0 0;
  border-bottom: 1px solid (0, 0, 0, 0.5);
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
  @media (${RESPONSIVE_SIZES.sm}) {
    grid-template-columns: repeat(7, 34px);
  }
`;

const StyledDaysWrapper = styled.div`
  display: grid;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  row-gap: 0;
  grid-template-columns: repeat(7, 29px);
  padding: 5px 0;
  @media (${RESPONSIVE_SIZES.sm}) {
    grid-template-columns: repeat(7, 34px);
  }
`;
