/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable react/no-unused-prop-types */
import { useTranslation } from "react-i18next";

export const Helpcrunch = (props: { organization: string; appId: string }) => {
  const { i18n } = useTranslation();

  // @ts-ignore
  window.helpcrunchSettings = props;

  (function (w, d) {
    // @ts-ignore
    const hS = w.helpcrunchSettings;
    const widgetSrc = `https://${  hS.organization  }.widget.helpcrunch.com/`;
    // @ts-ignore
    w.HelpCrunch = function () {
      // @ts-ignore
      w.HelpCrunch.q.push(arguments);
    };
    // @ts-ignore
    w.HelpCrunch.q = [];
    function r() {
      if (d.querySelector(`script[src="${  widgetSrc  }"`)) {
        return;
      }
      const s = d.createElement("script");
      s.async = true;
      s.type = "text/javascript";
      s.src = widgetSrc;
      d.body.appendChild(s);
    }
    if (d.readyState  ===  "complete" || hS.loadImmediately) {
      r();
      // @ts-ignore
    } else if (w.attachEvent) {
      // @ts-ignore
      w.attachEvent("onload", r);
    } else {
      w.addEventListener("load", r, false);
    }
  })(window, document);
  // @ts-ignore
  HelpCrunch("setPhraseList", i18n.language  ===  "pt" ? "en" : i18n.language);
  return <></>;
};
