import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export function useInfoNotification(
  dataProvider: { isSuccess: boolean }[],
  message?: string
) {
  const { t } = useTranslation();

  useEffect(() => {
    if (
      Array.isArray(dataProvider) &&
      dataProvider.some((item) => item.isSuccess)
    ) {
      toast.info(message || t("Успех"));
    }
  }, [dataProvider.some((item) => item.isSuccess)]);
}

export function useSuccessNotification(
  dataProvider: { isSuccess: boolean }[],
  message?: string
) {
  const { t } = useTranslation();

  useEffect(() => {
    if (
      Array.isArray(dataProvider) &&
      dataProvider.some((item) => item.isSuccess)
    ) {
      toast.success(message || t("Успех"));
    }
  }, [dataProvider.some((item) => item.isSuccess)]);
}

export function useErrorNotification(
  dataProvider: { isError: boolean }[],
  message?: string
) {
  const { t } = useTranslation();

  useEffect(() => {
    if (
      Array.isArray(dataProvider) &&
      dataProvider.some((item) => item.isError)
    ) {
      toast.error(message || t("Ошибка"));
    }
  }, [dataProvider.some((item) => item.isError)]);
}
