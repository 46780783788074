import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { PageTemplatesQueryParams } from "api/baseAPI/invoicing";
import { DateSelector } from "components/RangeSelector/DateSelector";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { Select } from "UI/Form/Select";
import { Grid } from "UI/Grid";
import { IUseUrlQueryResult } from "utils/url";

export const PageTemplatesFiltersRow = ({
  queryParams,
  querySetters,
}: IUseUrlQueryResult<PageTemplatesQueryParams>) => {
  const { t } = useTranslation();

  const selectedPointManager = useSelectedPointManager();

  return (
    <Grid
      container
      my={24}
      style={{
        justifyContent: "space-between",
      }}
      responsive={{
        sm: { direction: "column-reverse" },
        md: { direction: "row" },
      }}
      vSpace={12}
    >
      <Grid item sm={12} md={5} xxl={3}>
        <Select
          value={queryParams.wallet}
          onChange={(value) => querySetters.set_wallet(value)}
          label={t("Кошелек")}
          placeholder={t("Выберите")}
          size="small"
          options={[
            { value: "", label: t("Все кошельки") },
            ...(selectedPointManager?.available_wallets?.length > 0
              ? selectedPointManager.available_wallets.map((item) => ({
                  value: `${item.id}`,
                  label: `${item.id} | ${item.name}`,
                }))
              : []),
          ]}
        />
      </Grid>
      <Grid item sm={12} md={6} xxl={5}>
        <DateSelector
          label={t("Период")}
          start={
            queryParams.created_at__gte
              ? DateTime.fromISO(queryParams.created_at__gte)
              : null
          }
          end={
            queryParams.created_at__lte
              ? DateTime.fromISO(queryParams.created_at__lte)
              : null
          }
          set_start={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__gte(val);
          }}
          set_end={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__lte(val);
          }}
          right
        />
      </Grid>
    </Grid>
  );
};
