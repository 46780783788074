import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { includes } from "lodash";
import * as Yup from "yup";
import {
  KIND_CHECKOUT,
  useGetInvoiceQuery,
  usePatchInvoiceMutation,
} from "api/baseAPI/checkoutInvoiceInvoices";
import { Layout } from "components/layouts/Layout";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Modal } from "UI/Modal";
import { useErrorNotification } from "utils/notificationWrappers";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import {
  InvoiceFormValues,
  initData,
  replaceOut,
  replaceIn,
} from "./InvoiceCreate";
import { FirstStep } from "./parts/FirstStep";
import { ModalSuccess } from "./parts/ModalSuccess";

export const InvoiceEdit = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Инвойсинг"), ""],
    [t("Список инвойсов"), "/invoice/invoices"],
    [t("Редактирование инвойса"), ""],
  ];

  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState(false);

  const { uuid = "" } = useParams<{ uuid: string }>();

  const selectedPointManager = useSelectedPointManager();

  const invoice = useGetInvoiceQuery({ uuid });

  const [
    updateInvoice,
    {
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
      isLoading: isLoadingUpdate,
      isError,
    },
  ] = usePatchInvoiceMutation();

  useEffect(() => {
    if (isSuccessUpdate) {
      setShowSuccess(true);
    }
  }, [isSuccessUpdate]);

  const handleErrors = (dataErrors: string[]) => {
    if (
      includes(dataErrors, "Point does not have required service connected")
    ) {
      toast.error(t("К точке не подключена требуемая услуга"));
      return;
    }
    if (
      includes(dataErrors, "point and wallet have different account owners")
    ) {
      toast.error(t("У точки и кошелька разные владельцы счетов"));
      return;
    }
    if (includes(dataErrors?.[0], "has different currency from page")) {
      toast.error(t("Валюта кошелька отличается от валюты страницы"));
      return;
    }
    toast.error(t("Ошибка"));
  };

  useEffect(() => {
    if (
      errorUpdate &&
      "data" in errorUpdate &&
      "status" in errorUpdate &&
      errorUpdate.status === 400
    ) {
      const dataErrors = errorUpdate.data as string[];
      handleErrors(dataErrors);
    }
  }, [errorUpdate]);

  const today = new Date();
  const updateForm = useFormik<InvoiceFormValues>({
    initialValues: initData,
    onSubmit: (values) => {
      const dataForm = {
        point_id: String(selectedPointManager.point?.id),
        account_id: String(selectedPointManager.user.account_id),

        title: values.title,
        active_till: values.active_till || null,
        locale: values.locale,
        kind: values.kind,

        wallet_id: values.wallet || null,
        service_mapping: values.service_mapping,
        external_id: values.external_id,
        ...(!values.is_reusable && {
          external_customer_id: values.external_customer_id || null,
        }),

        is_reusable: values.is_reusable,
        amount_currency: values.amount_currency || null,
        fixed_amount: values.fixed_amount || null,
        min_amount: values.min_amount || null,
        default_amount: values.default_amount || null,
        preferred_amounts: [...values.preferred_amounts]?.sort(
          (a: string, b: string) => Number(a) - Number(b)
        ),
        preferred_amounts_logic: values.preferred_amounts_logic,

        additional_fields: values.additional_fields,
        prefilled_fields: values.prefilled_fields,
        description_template: values.description_template
          ? replaceOut(values.description_template, values.additional_fields)
          : "",

        success_amount: 0,
        fail_amount: 0,
        fail_count: 0,
        success_count: 0,
      };
      updateInvoice({
        ...dataForm,
        uuid,
      })
        .unwrap()
        .then(() => setShowSuccess(true))
        .catch((e) => console.warn(e));
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required(t("Обязательное поле"))
        .max(128, t("Максимум {{characters}} символов", { characters: 128 })),
      external_id: Yup.string().required(t("Обязательное поле")),
      external_customer_id: Yup.string().max(
        100,
        t("Максимум {{characters}} символов", { characters: 100 })
      ),
      wallet: Yup.string().required(t("Обязательное поле")),
      active_till: Yup.date().min(
        today,
        t("Дата должна быть не ранее сегодняшнего дня")
      ),
      fixed_amount: Yup.string().when("min_amount", {
        is: (val: string) => val,
        then: Yup.string().test(
          "isValidByLuhn",
          t("Сумма должна быть не меньше минимальной"),
          (value: string | undefined): boolean => {
            if (!value) return true;
            return (Number(updateForm.values.min_amount) || 0) <= Number(value);
          }
        ),
      }),
      preferred_amounts: Yup.array()
        .when("min_amount", {
          is: (val: string) => val,
          then: Yup.array().test(
            "isValidByLuhn",
            t("Сумма должна быть не меньше минимальной"),
            (value: (number | undefined)[] | undefined): boolean => {
              if (value?.length === 0) return true;
              const valid = value?.map(
                (num) =>
                  (Number(updateForm.values.min_amount) || 0) <= Number(num)
              );
              return !valid?.includes(false);
            }
          ),
        })
        .test(
          "isValidLength",
          t("Должно быть не меньше 2 вариантов"),
          (value: (number | undefined)[] | undefined): boolean =>
            (value && value?.length === 0) ||
            (!!value && value?.length > 1 && value?.length < 4)
        ),
    }),
  });

  useTranslateFormErrors(updateForm);

  useEffect(() => {
    if (invoice.isSuccess && invoice.data?.uuid) {
      updateForm.setFieldValue("title", invoice.data?.title || "");
      updateForm.setFieldValue("kind", invoice.data?.kind || KIND_CHECKOUT);
      updateForm.setFieldValue("active_till", invoice.data?.active_till || "");
      updateForm.setFieldValue(
        "locale",
        invoice.data?.locale ? invoice.data?.locale?.toLocaleLowerCase() : ""
      );

      updateForm.setFieldValue(
        "wallet",
        invoice.data?.wallet_id ? String(invoice.data?.wallet_id) : ""
      );
      updateForm.setFieldValue(
        "service_mapping",
        invoice.data?.service_mapping || {}
      );
      updateForm.setFieldValue("external_id", invoice.data?.external_id || "");
      updateForm.setFieldValue(
        "external_customer_id",
        invoice.data?.external_customer_id || ""
      );

      updateForm.setFieldValue(
        "is_reusable",
        invoice.data?.is_reusable || false
      );
      updateForm.setFieldValue(
        "fixed_amount",
        invoice.data?.fixed_amount || ""
      );
      updateForm.setFieldValue(
        "amount_currency",
        invoice.data?.amount_currency || ""
      );
      updateForm.setFieldValue("min_amount", invoice.data?.min_amount || "");
      updateForm.setFieldValue(
        "default_amount",
        invoice.data?.default_amount || ""
      );
      updateForm.setFieldValue(
        "preferred_amounts",
        invoice.data?.preferred_amounts || []
      );
      updateForm.setFieldValue(
        "preferred_amounts_logic",
        invoice.data?.preferred_amounts_logic || 1
      );

      updateForm.setFieldValue(
        "additional_fields",
        invoice.data?.additional_fields || []
      );
      updateForm.setFieldValue(
        "prefilled_fields",
        invoice.data?.prefilled_fields || {}
      );
      updateForm.setFieldValue(
        "description_template",
        invoice.data?.description_template
          ? replaceIn(
              invoice.data?.description_template,
              invoice.data?.additional_fields || []
            )
          : ""
      );
    }
  }, [invoice]);

  const handleCloseForm = () => {
    navigate("/invoice/invoices");
  };

  useErrorNotification([{ isError }]);
  useServerErrorsInForm(updateForm.setFieldError, errorUpdate);

  return (
    <Layout title={t("Редактирование инвойса")} breadCrumbs={breadCrumbs}>
      <Box
        px={24}
        py={32}
        flex
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "calc(100vh - 124px)" }}
      >
        <FirstStep form={updateForm} />
        <Box flex mt={20}>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            variant="outlined"
            size="small"
            onClick={handleCloseForm}
          >
            {t("Назад")}
          </Button>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            ml={24}
            variant="contained"
            size="small"
            disabled={isLoadingUpdate}
            onClick={() => updateForm.handleSubmit()}
          >
            {t("Сохранить")}
          </Button>
        </Box>

        <Modal
          open={showSuccess}
          hideHeader
          width={370}
          onClose={() => setShowSuccess(false)}
        >
          <ModalSuccess />
        </Modal>
      </Box>
    </Layout>
  );
};
