import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { find, isEmpty, last, map } from "lodash";
import { Checkbox } from "UI/Form/Checkbox";
import ButtonFileUploader from "components/FileUploader";
import { useErrorNotification } from "utils/notificationWrappers";
import { IconColor } from "components/table/TableRowActions";
import { Button } from "UI/Button";
import { useAuth } from "hooks/useAuth";
import { removeAuth } from "reducers/authSlice";
import { ReactComponent as SquareCheckIcon } from "utils/img/square-check.svg";
import { ReactComponent as FileBlankIcon } from "utils/img/file-blank.svg";
import { Footer } from "components/layouts/Footer";
import { Box } from "UI/Box";
import { Typography } from "UI/Typography";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useGetDocumentPackagesQuery } from "api/baseAPI/helpers";
import { Divider } from "UI/Divider";
import {
  useCreateUserDocumentMutation,
  useGetUserDocumentsQuery,
  useLogoutMutation,
} from "api/baseAPI/user";

export const DocumentPackages = ({
  registrationForm,
  businessArea,
  user,
}: {
  registrationForm: number;
  businessArea: number;
  user: number;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const auth = useAuth();
  const [logout] = useLogoutMutation();

  const getUserDocuments = useGetUserDocumentsQuery({
    user,
  });
  const getDocumentPackages = useGetDocumentPackagesQuery({
    business_area: businessArea,
    registration_form: registrationForm,
  });

  const handleLogout = () => {
    const token = auth.access_token;
    dispatch(removeAuth());
    window.localStorage.removeItem("point_manager");
    window.localStorage.removeItem("selected_wallet");
    logout({ token }).unwrap();
    navigate("/login");
  };

  const DocumentItem = ({
    doc,
  }: {
    doc: { id: number; name: string; description: string };
  }) => {
    const [createUserDocument, { isError }] = useCreateUserDocumentMutation();

    const getUploadFile = find(
      getUserDocuments.data?.results,
      (item) => item.document.id === doc.id
    );
    const fileName = last(getUploadFile?.file?.split("/")) || "";

    const handleFile = (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user", String(user));
      formData.append("document", String(doc.id));
      createUserDocument({ formData });
    };

    useErrorNotification([{ isError }]);

    return (
      <Box p={16} mb={12} style={{ border: "1px solid #C8CFDC" }}>
        <Checkbox label={doc.name} value={!!getUploadFile?.is_approved} />
        <Typography variant="subtitle3" mt={8}>
          {doc.description}
        </Typography>
        {!getUploadFile?.is_approved ? (
          <Box flex mt={8} justifyContent="space-between" alignItems="center">
            <ButtonFileUploader
              title={t("Загрузить")}
              handleFile={handleFile}
            />
            {getUploadFile?.file ? (
              <Box flex alignItems="center">
                <Typography>
                  {fileName.length > 20
                    ? `${fileName.substring(0, 20)}...`
                    : fileName}
                </Typography>
                <Box
                  mr={8}
                  flex
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    background: "#EAEFF7",
                    width: 35,
                    height: 35,
                  }}
                >
                  <IconColor color="#838383">
                    <FileBlankIcon />
                  </IconColor>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box flex justifyContent="flex-end" mb={8} p={8}>
        <Button size="small" variant="outlined" onClick={handleLogout}>
          {t("Выйти из аккаунта")}
        </Button>
      </Box>
      <StyledPageWrapper>
        <Box p={24} style={{ textAlign: "center", maxWidth: "440px" }}>
          <Box
            flex
            alignItems="center"
            justifyContent="center"
            style={{
              height: 84,
              width: 84,
              background: "rgba(62, 169, 89, 0.1)",
              margin: "auto",
            }}
          >
            <SquareCheckIcon />
          </Box>
          <Typography variant="h4" mt={24} mb={8}>
            {t("Информация про Вас заполнена успешно!")}
          </Typography>
          <Typography variant="subtitle2" mb={16} style={{ color: "#838383" }}>
            {t(
              "В ближайшее время с Вами свяжется менеджер для дальнейшей регистрации"
            )}
          </Typography>
          <Divider direction="vertical" />
          <Box mt={24} style={{ textAlign: "start" }}>
            <Typography variant="h5" mb={24}>
              {t("Подготовьте следующие документы")}
            </Typography>
            {!isEmpty(getDocumentPackages.data?.results?.[0]?.required_docs) ? (
              <>
                {map(
                  getDocumentPackages.data?.results?.[0]?.required_docs,
                  (item) => (
                    <DocumentItem key={item.id} doc={item} />
                  )
                )}
              </>
            ) : (
              <></>
            )}
            {!isEmpty(getDocumentPackages.data?.results?.[0]?.optional_docs) ? (
              <>
                {map(
                  getDocumentPackages.data?.results?.[0]?.optional_docs,
                  (item) => (
                    <DocumentItem key={item.id} doc={item} />
                  )
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </StyledPageWrapper>
      <Footer />
    </>
  );
};

const StyledPageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 288px);
  background: white;
  @media (${RESPONSIVE_SIZES.sm}) {
    min-height: calc(100vh - 269px);
  }
`;
