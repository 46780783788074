import { ReactElement } from "react";
import * as yup from "yup";
import { ReactComponent as WhiteBitIcon } from "utils/img/whitebit.svg";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { EmailPayInput } from "pages/payout/logic/forms/components/inputs/EmailPayInput";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class WhiteBitPayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "whitebit";

  kind: 6 = 6;

  name: string = "WhiteBit";

  mobileName: string = this.name;

  icon: ReactElement = (<WhiteBitIcon />);

  csvExample: string | null = "/file-samples/sample-whitebit.csv";

  xlsxExample: string | null = "/file-samples/sample-whitebit.xlsx";

  get serviceId() {
    return (
      (this.pointManager?.service_config?.whitebit_create_code &&
        this.getService(
          this.pointManager?.service_config?.whitebit_create_code
        )) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <EmailPayInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .email("Неправильный формат email")
    .required("Введите email");
}
