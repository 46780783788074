import { useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import i18n from "locale";

export enum AP_GP_POSITION {
  UP = 1,
  DOWN = 2,
  NO = 3,
}

export type FormValues = {
  name: string;
  light: string;
  dark: string;
  theme_switch: boolean;
  is_ap_available: boolean;
  is_gp_available: boolean;
  ap_gp_position: AP_GP_POSITION;
  company_name: string;
  messenger: string;
  phone_number: string;
  email: string;
  partners_logos: string[];
  locales: string[];
  is_active: boolean;
  oferta_style: 0 | 1 | 2;
  available_payment_methods: { id: number | string; slug: string }[];
  payment_slug: { id: number; slug: string } | null;
};

export function useCustomizationRuleFormLogic(params: {
  onSubmit: (
    values: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
  validationSchema?: any;
}) {
  const { t } = useTranslation();

  const form = useFormik<FormValues>({
    initialValues: {
      name: "",
      light: "",
      dark: "",
      theme_switch: false,
      is_ap_available: false,
      is_gp_available: false,
      ap_gp_position: AP_GP_POSITION.UP,
      company_name: "",
      messenger: "",
      phone_number: "",
      email: "",
      partners_logos: [],
      locales: ["en", "uk", "ru", "pt"],
      is_active: false,
      oferta_style: 0,
      available_payment_methods: [],
      payment_slug: null,
    },
    onSubmit: (values, { setSubmitting }) => {
      params.onSubmit(values, setSubmitting);
    },
    validationSchema:
      params.validationSchema ||
      yup.object().shape({
        name: yup
          .string()
          .max(64, t("Максимум {{characters}} символов", { characters: 64 }))
          .required(t("Обязательное поле")),
        light: yup.string().required(t("Обязательное поле")),
        dark: yup.string().required(t("Обязательное поле")),
        company_name: yup
          .string()
          .nullable()
          .max(30, t("Максимум {{characters}} символов", { characters: 30 })),
        messenger: yup
          .string()
          .nullable()
          .max(128, t("Максимум {{characters}} символов", { characters: 128 })),
        phone_number: yup
          .string()
          .nullable()
          .max(32, t("Максимум {{characters}} символов", { characters: 32 })),
        email: yup
          .string()
          .email()
          .nullable()
          .max(128, t("Максимум {{characters}} символов", { characters: 128 })),
        partners_logos: yup.array().min(1, t("Обязательное поле")),
      }),
  });

  useEffect(() => {
    if (i18n.language) {
      form.setErrors({});
    }
  }, [i18n.language]);

  return { form };
}
