import FileSaver from "file-saver";
import { isEmpty, map } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { Alert } from "UI/Alert";
import { Box } from "UI/Box";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { TextInput } from "UI/Form/TextInput";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import {
  AttachmentItem,
  MessageItem,
  TransactionFraud,
  useLazyGetChatAttachmentsByIdsQuery,
  useLazyGetChatAttachmentsQuery,
  useLazyGetChatMessagesQuery,
  useStartDisputeQuery,
  useUploadChatAttachmentMutation,
} from "api/baseAPI/transactionFraud";
import { useGetUsersSelfQuery } from "api/baseAPI/user";
import { LinearProgress } from "components/LinearProgress";
import { IconColor } from "components/table/TableRowActions";
import { getColors } from "utils/getColors";
import { ReactComponent as ChatIcon } from "utils/img/chat.svg";
import { ReactComponent as CloseIcon } from "utils/img/close.svg";
import { ReactComponent as Download } from "utils/img/download.svg";
import { ReactComponent as FileIcon } from "utils/img/file-menu.svg";
import { ReactComponent as PaperPlane } from "utils/img/paper-plane.svg";
import { ReactComponent as Paperclip } from "utils/img/paperclip.svg";
import { ReactComponent as Warning } from "utils/img/warning.svg";

interface Props {
  transactionFraud: TransactionFraud;
  onClose: () => void;
}

export const Chat = ({ transactionFraud, onClose }: Props) => {
  const { t } = useTranslation();

  const hiddenFileInput = React.createRef<HTMLInputElement>();
  const observer = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState<string>("");
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [activeTab, setActiveTab] = useState("1");
  const [pageChat, setPageChat] = useState(1);
  const [loadingSend, setLoadingSend] = useState(false);
  const [isNextPageChat, setIsNextPageChat] = useState(true);
  const [chatMessages, setChatMessages] = useState<MessageItem[]>([]);
  const [pageFiles, setPageFiles] = useState(1);
  const [isNextPageFiles, setIsNextPageFiles] = useState(true);
  const [chatFiles, setChatFiles] = useState<AttachmentItem[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [receivedMessage, setReceivedMessage] = useState<MessageItem | null>(
    null
  );

  const startDispute = useStartDisputeQuery({ id: transactionFraud.id });
  const [getChatMessages, getChatMessagesMeta] = useLazyGetChatMessagesQuery();
  const [getChatAttachments, getChatAttachmentsMeta] =
    useLazyGetChatAttachmentsQuery();
  const [getChatAttachmentsByIds, getChatAttachmentsByIdsMeta] =
    useLazyGetChatAttachmentsByIdsQuery();
  const [uploadChatAttachment] = useUploadChatAttachmentMutation();
  const getUsersSelfQuery = useGetUsersSelfQuery();
  const { accentColor, pageBackground } = getColors();

  useEffect(() => {
    if (
      startDispute?.data?.chat?.uid &&
      pageChat &&
      activeTab === "1" &&
      isNextPageChat
    ) {
      getChatMessages({ uid: startDispute?.data?.chat?.uid, page: pageChat });
      setIsNextPageChat(false);
    }
  }, [startDispute?.data?.chat?.uid, pageChat]);

  useEffect(() => {
    if (getChatMessagesMeta.isSuccess && getChatMessagesMeta?.data?.results) {
      const messages = [
        ...(getChatMessagesMeta?.data?.results || []),
      ].reverse();
      if (pageChat === 1) {
        setChatMessages(messages);
      } else {
        setChatMessages([...messages, ...chatMessages]);
      }
      if (getChatMessagesMeta?.data?.next) {
        setIsNextPageChat(true);
      } else {
        setIsNextPageChat(false);
      }
    }
  }, [getChatMessagesMeta.isSuccess]);

  useEffect(() => {
    if (
      startDispute?.data?.chat?.uid &&
      pageFiles &&
      activeTab === "2" &&
      isNextPageFiles
    ) {
      getChatAttachments({
        chat: startDispute?.data?.chat?.uid,
        page: pageFiles,
      });
      setIsNextPageFiles(false);
    }
  }, [startDispute?.data?.chat?.uid, pageFiles, activeTab]);

  useEffect(() => {
    if (
      getChatAttachmentsMeta.isSuccess &&
      getChatAttachmentsMeta?.data?.results
    ) {
      const files = [...(getChatAttachmentsMeta?.data?.results || [])];
      if (pageChat === 1) {
        setChatFiles(files);
      } else {
        setChatFiles([...chatFiles, ...files]);
      }
      if (getChatAttachmentsMeta?.data?.next) {
        setIsNextPageFiles(true);
      } else {
        setIsNextPageFiles(false);
      }
    }
  }, [getChatAttachmentsMeta.isSuccess]);

  useEffect(() => {
    if (
      observer.current &&
      !getChatMessagesMeta.isLoading &&
      activeTab === "1"
    ) {
      if (pageChat === 1) {
        observer.current.scrollTop = observer.current?.scrollHeight || 0;
      } else {
        observer.current.scrollTop =
          Math.round(observer.current.scrollHeight / pageChat) || 0;
      }
    }
  }, [
    observer.current?.scrollHeight,
    getChatMessagesMeta.isLoading,
    activeTab,
    isNextPageChat,
  ]);

  useEffect(() => {
    if (observer.current && activeTab === "2") {
      observer.current.scrollTop = 0;
    }
  }, [observer.current, activeTab]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        observer.current?.scrollTop === 0 &&
        activeTab === "1" &&
        isNextPageChat
      ) {
        setPageChat((prev) => prev + 1);
      }
      if (
        observer.current &&
        activeTab === "2" &&
        isNextPageFiles &&
        (observer.current.scrollTop + observer.current.clientHeight >=
          observer.current?.scrollHeight ||
          !observer.current.scrollHeight)
      ) {
        setPageFiles((prev) => prev + 1);
      }
    };
    if (observer.current) {
      observer.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      observer.current?.removeEventListener("scroll", handleScroll);
    };
  }, [observer.current, isNextPageFiles, isNextPageChat, activeTab]);

  function myTimer() {
    if (startDispute?.data?.uid) {
      if (ws) ws.close();
      setWs(
        new WebSocket(
          `${process.env.REACT_APP_CHAT_URL}/ws/antifraud/${startDispute?.data?.uid}/`
        )
      );
    }
  }

  useEffect(() => {
    if (startDispute?.data?.uid) {
      setWs(
        new WebSocket(
          `${process.env.REACT_APP_CHAT_URL}/ws/antifraud/${startDispute?.data?.uid}/`
        )
      );
    }
    const wsInterval = setInterval(myTimer, 600000);
    return () => {
      clearInterval(wsInterval);
    };
  }, [startDispute?.data?.uid]);

  if (ws) {
    ws.onmessage = (e) => {
      const newMessage = JSON.parse(e.data)?.message;
      if (newMessage) {
        if (newMessage.attachments?.length) {
          getChatAttachmentsByIds({
            chat: startDispute?.data?.chat?.uid || "",
            id__in: newMessage.attachments.join(","),
          });
          setReceivedMessage(newMessage);
        } else {
          setChatMessages([
            ...chatMessages,
            {
              content: newMessage.content,
              sender_id: newMessage.sender_id,
              created_at: new Date().toISOString(),
              sender_type: newMessage.sender_type,
              id: newMessage.uuid,
              attachments: [],
            },
          ]);
          if (observer.current && activeTab === "1") {
            observer.current.scrollTop = observer.current?.scrollHeight || 0;
          }
        }
      }
    };
    ws.onclose = (e) => {
      console.error(e, "Chat socket closed unexpectedly");
      onClose();

      if (e.code !== 1000) {
        toast.error(t("Ошибка подключения"));
      }
    };
  }

  useEffect(() => {
    if (observer.current && activeTab === "1") {
      observer.current.scrollTop = observer.current?.scrollHeight || 0;
    }
  }, [chatMessages]);

  useEffect(() => {
    if (
      getChatAttachmentsByIdsMeta.isSuccess &&
      getChatAttachmentsByIdsMeta?.data?.results &&
      receivedMessage
    ) {
      setChatMessages([
        ...chatMessages,
        {
          content: receivedMessage.content,
          sender_id: receivedMessage.sender_id,
          created_at: new Date().toISOString(),
          sender_type: receivedMessage.sender_type,
          id: uuidv4(),
          attachments: getChatAttachmentsByIdsMeta?.data?.results || [],
        },
      ]);
      setReceivedMessage(null);
      setPageFiles(1);
      setIsNextPageFiles(true);
      if (observer.current && activeTab === "1") {
        observer.current.scrollTop = observer.current?.scrollHeight || 0;
      }
    }
  }, [getChatAttachmentsByIdsMeta.isSuccess]);

  const handleSendMessage = async () => {
    if (!message) return;
    setLoadingSend(true);

    let messageAttachments: number[] = [];
    if (selectedFiles.length && startDispute?.data?.chat?.uid) {
      try {
        const promises = selectedFiles.map((item) => {
          const formData = new FormData();
          formData.append("file", item);
          formData.append("chat", startDispute?.data?.chat?.uid || "");
          formData.append("uploaded_by", "0");
          return uploadChatAttachment({ formData }).unwrap();
        });
        const results: AttachmentItem[] = await Promise.all(promises);
        messageAttachments = [...map(results, "id")];
      } catch (e) {
        console.error(e);
        setLoadingSend(false);
      }
    }
    if (ws) {
      ws.send(
        JSON.stringify({
          message: {
            content: message,
            sender_type: 0,
            uuid: uuidv4(),
            attachments: messageAttachments,
          },
        })
      );
    }
    setMessage("");
    setSelectedFiles([]);
    setLoadingSend(false);
  };

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = e.target.files;
    if (!fileList) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSelectedFiles([...selectedFiles, ...fileList].slice(0, 10));
  };

  const handleDeleteFile = (index: number) => {
    const duplicateFiles = [...selectedFiles];
    if (index !== -1) {
      duplicateFiles.splice(index, 1);
    }
    setSelectedFiles(duplicateFiles);
  };

  const saveAttachment = (file: string) => {
    FileSaver.saveAs(file, file.split("_").slice(-1)[0]);
  };

  return (
    <Modal
      open
      height={250}
      width={550}
      maxHeight="80vh"
      onClose={() => {
        onClose();
        if (ws) ws.close();
      }}
      title={`${t("Чат диспута")} | ${transactionFraud.id}`}
    >
      <>
        <Box>
          <Box p={24} pt={1}>
            <ButtonSwitch
              firstTitle={t("Чат")}
              secondTitle={t("Файлы")}
              value={activeTab === "1"}
              onChange={(val) => {
                if (val) {
                  setActiveTab("1");
                } else {
                  setIsNextPageFiles(true);
                  setPageFiles(1);
                  setActiveTab("2");
                }
              }}
            />
          </Box>
        </Box>
        <div
          style={{
            padding: 24,
            paddingBottom: 8,
            overflow: "auto",
            height: "100%",
            maxHeight: "calc(80vh - 300px)",
            background: pageBackground,
          }}
          ref={observer}
        >
          {activeTab === "1" ? (
            <>
              {getChatMessagesMeta.isLoading || startDispute.isFetching ? (
                <Box mb={12}>
                  <LinearProgress />
                </Box>
              ) : !isEmpty(chatMessages) ? (
                <>
                  {map(chatMessages, (item) => (
                    <Box key={item.id} pb={16}>
                      <Box
                        mb={12}
                        flex
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box flex alignItems="center">
                          {item.sender_type === 0 ? (
                            <StyledUserLogo
                              src={getUsersSelfQuery.data?.avatar || ""}
                            />
                          ) : (
                            <Box
                              flex
                              alignItems="center"
                              justifyContent="center"
                              style={{
                                height: 40,
                                width: 40,
                                background: "#FFFFFF",
                                margin: "auto",
                              }}
                            >
                              <ChatIcon />
                            </Box>
                          )}
                          <Typography ml={8}>
                            {item.sender_type === 0
                              ? `${getUsersSelfQuery.data?.first_name || ""} ${
                                  getUsersSelfQuery.data?.last_name || ""
                                } (${t("Вы")})`
                              : t("Техподдержка")}
                          </Typography>
                        </Box>
                        <Typography
                          variant="subtitle3"
                          style={{ color: "#838383" }}
                        >
                          {new Date(item.created_at).toLocaleTimeString()} -{" "}
                          {new Date(item.created_at).toLocaleDateString()}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          overflowWrap: "break-word",
                        }}
                      >
                        <Typography variant="subtitle2" mb={8}>
                          {item.content}
                        </Typography>
                        {item.attachments.length > 0 &&
                          item.attachments.map((attachment) => (
                            <Box
                              flex
                              alignItems="flex-start"
                              mt={4}
                              key={attachment.id}
                              style={{ cursor: "pointer", gap: 8 }}
                              onClick={() => saveAttachment(attachment.file)}
                            >
                              <IconColor color="#838383">
                                <FileIcon />
                              </IconColor>
                              <Typography>
                                {attachment.file.split("_").slice(-1)[0]
                                  .length > 20
                                  ? `${attachment.file
                                      .split("_")
                                      .slice(-1)[0]
                                      .slice(0, 8)}****${attachment.file
                                      .split("_")
                                      .slice(-1)[0]
                                      .slice(-8)}`
                                  : attachment.file.split("_").slice(-1)[0]}
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <Alert color="green" mt={-12}>
                  <Box flex alignItems="center">
                    <Warning style={{ marginRight: "5px" }} />
                    {t("Сообщений пока нет")}
                  </Box>
                </Alert>
              )}
            </>
          ) : (
            <>
              {getChatAttachmentsMeta.isLoading ? (
                <LinearProgress />
              ) : !isEmpty(chatFiles) ? (
                <Box
                  p={24}
                  style={{
                    background: pageBackground,
                  }}
                >
                  {map(chatFiles, (item, i) => (
                    <Box
                      key={i}
                      flex
                      nowrap
                      justifyContent="space-between"
                      alignItems="center"
                      pb={16}
                      style={{
                        ...(i + 1 < chatFiles.length
                          ? {
                              borderBottom: "1px solid #C8CFDC",
                              marginBottom: 16,
                            }
                          : {}),
                        gap: 16,
                      }}
                    >
                      <Box flex nowrap alignItems="center">
                        <Box
                          flex
                          alignItems="center"
                          justifyContent="center"
                          style={{
                            height: 40,
                            width: 40,
                            background: "#FFFFFF",
                            margin: "auto",
                          }}
                        >
                          <IconColor color={accentColor}>
                            <FileIcon />
                          </IconColor>
                        </Box>
                        <Box ml={8}>
                          <Typography mb={8}>
                            {item?.file?.split("_").slice(-1)[0]}
                          </Typography>
                          <Typography
                            variant="subtitle3"
                            style={{ color: "#838383" }}
                          >
                            {new Date(item.created_at).toLocaleTimeString()} -{" "}
                            {new Date(item.created_at).toLocaleDateString()}
                          </Typography>
                        </Box>
                      </Box>
                      <IconColor
                        color={accentColor}
                        style={{ cursor: "pointer" }}
                        onClick={() => saveAttachment(item?.file)}
                      >
                        <Download />
                      </IconColor>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Alert color="green" mt={-12}>
                  <Box flex alignItems="center">
                    <Warning style={{ marginRight: "5px" }} />
                    {t("Файлов пока нет")}
                  </Box>
                </Alert>
              )}
            </>
          )}
        </div>
        {activeTab === "1" ? (
          <Box p={24}>
            <Box>
              <TextInput
                placeholder={t("Начните вводить текст")}
                size="small"
                // multiline
                value={message}
                disabled={
                  getChatMessagesMeta.isLoading || startDispute.isFetching
                }
                onChange={(val) => setMessage(val)}
                inputProps={{
                  onKeyDown: (e: KeyboardEvent) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  },
                }}
                iconStart={
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      hiddenFileInput.current && hiddenFileInput.current.click()
                    }
                  >
                    <input
                      multiple
                      type="file"
                      ref={hiddenFileInput}
                      hidden
                      onChange={handleUploadFile}
                    />
                    <Paperclip />
                  </Box>
                }
                iconEnd={
                  <StyledIconButton
                    color={accentColor}
                    disabled={
                      loadingSend ||
                      !message ||
                      getChatMessagesMeta.isLoading ||
                      startDispute.isFetching
                    }
                    onClick={() => handleSendMessage()}
                  >
                    <PaperPlane />
                  </StyledIconButton>
                }
              />
              {!isEmpty(selectedFiles) && (
                <Box flex mt={12} style={{ gap: 12 }}>
                  {map(selectedFiles, (file, i) => (
                    <Box
                      key={i}
                      p={4}
                      flex
                      alignItems="flex-start"
                      style={{
                        gap: 4,
                        background: pageBackground,
                        border: "1px solid #C8CFDC",
                      }}
                    >
                      <IconColor color={accentColor}>
                        <FileIcon />
                      </IconColor>
                      <Typography variant="subtitle2">
                        {file?.name?.length > 20
                          ? `${file?.name?.slice(0, 8)}****${file?.name?.slice(
                              -8
                            )}`
                          : file?.name}
                      </Typography>
                      <IconColor
                        color="#838383"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteFile(i)}
                      >
                        <CloseIcon />
                      </IconColor>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </>
    </Modal>
  );
};

const StyledUserLogo = styled.img`
  width: 40px;
  height: 40px;
`;

const StyledIconButton = styled.div`
  cursor: pointer;
  svg path {
    fill: ${({ disabled, color }: { disabled: boolean; color: string }) =>
      disabled ? "#C8CFDC" : color} !important;
  }
`;
