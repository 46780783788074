import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  PageTransactionsQueryParams,
  useGetPagesQuery,
} from "api/baseAPI/invoicing";
import { TableFilter } from "components/table/TableFilter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { useFilterMakerResult } from "utils/filterMaker";
import { handleChangeNumber, numberForInput } from "utils/numbers";

export const PageTransactionsFilters = ({
  filters,
  disabledPage,
}: {
  filters: useFilterMakerResult<PageTransactionsQueryParams>;
  disabledPage?: boolean;
}) => {
  const { t } = useTranslation();
  const { values, setValue, errors } = filters;
  const selectedPointManager = useSelectedPointManager();

  const pages = useGetPagesQuery({
    page: "1",
    page_size: "100",
    search: "",
    status: "",
    created_at__lte: "",
    created_at__gte: "",
    title__icontains: "",
    uuid__icontains: "",
    wallet: "",
    service: "",
    success_amount__gte: "",
    success_amount__lte: "",
    point: `${selectedPointManager?.point_id || ""}`,
  });

  const getPages = useMemo(() => {
    const { data } = pages;
    if (data && data?.results?.length) {
      const services = Array.from(
        new Set(
          data?.results?.map((item) => ({
            value: String(item?.id),
            label: `${item.title} | ${item.id}`,
          }))
        )
      );
      return services;
    }
    return [];
  }, [pages]);

  return (
    <TableFilter
      onSubmit={filters.handleSubmit}
      onReset={filters.handleReset}
      disabledReset={filters.disabledReset}
      disableSubmit={!!errors}
      widthBox="40%"
    >
      <Grid container mt={12} hSpace={24} vSpace={24} smHSpace={8}>
        {!disabledPage ? (
          <Grid item sm={12}>
            <Select
              value={values.page_id}
              onChange={(value) => setValue("page_id", value)}
              label={t("Страница")}
              placeholder={t("Выберите")}
              size="small"
              options={getPages}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item sm={6}>
          <TextInput
            size="small"
            fullwidth
            label={t("Сумма от")}
            placeholder={t("Предварительно заполнить")}
            value={numberForInput(values.amount__gte)}
            onChange={(value) =>
              setValue(
                "amount__gte",
                handleChangeNumber(value.replace(/[^0-9.]/g, ""))
              )
            }
          />
        </Grid>
        <Grid item sm={6}>
          <TextInput
            size="small"
            fullwidth
            label={t("Сумма до")}
            placeholder={t("Предварительно заполнить")}
            value={numberForInput(values.amount__lte)}
            onChange={(value) =>
              setValue(
                "amount__lte",
                handleChangeNumber(value.replace(/[^0-9.]/g, ""))
              )
            }
          />
        </Grid>
        <Grid item sm={12}>
          <TextInput
            size="small"
            label={t("Описание")}
            placeholder={t("Предварительно заполнить")}
            value={values.description}
            onChange={(value) => setValue("description", value)}
          />
        </Grid>
      </Grid>
    </TableFilter>
  );
}
