import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

export type TipUsersQueryParams = {
  page: string;
  page_size: string;
  search: string;
};
export const tipUsersApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getTipUsers: builder.query<
      {
        count: number;
        next: string | null;
        previous: string | null;
        results: [
          {
            id: number;
            name: string;
            company_name: string;
            position: string;
            description: string | null;
            accent_color: string | null;
            page_id: string;
            page_url: string;
            card_number: string;
            total_tips_count: number;
            balance: string;
            total_tips: string;
            def_amount: string;
            suggested_amounts: string[] | null;
            avatar: string;
            is_active: boolean;
            point: {
              id: number;
              name: string;
            };
          }
        ];
      },
      TipUsersQueryParams
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/tip-users/?${query.toString()}`;
      },
    }),
    getTipUser: builder.query<
      {
        id: number;
        name: string;
        description: string | null;
        accent_color: string | null;
        company_name: string;
        position: string;
        page_id: string;
        page_url: string;
        card_number: string;
        total_tips_count: number;
        balance: string;
        total_tips: string;
        def_amount: string;
        suggested_amounts: string[] | null;
        avatar: string | null;
        is_active: boolean;
        point: {
          id: number;
          name: string;
        };
      },
      { id: string }
    >({
      query: ({ id }) => `/tip-users/${id}/`,
    }),
    postTipUser: builder.mutation<
      { id: number },
      {
        name: string;
        company_name: string;
        position: string;
        description?: string;
        accent_color?: string;
        card_number: string;
        def_amount: string;
        suggested_amounts?: number[];
        avatar?: File;
        point: number;
      }
    >({
      query: (data) => {
        const formData = new FormData();

        (Object.keys(data) as Array<keyof typeof data>).forEach((key) => {
          if (key === "suggested_amounts") {
            (data[key] as Array<number>).forEach((amount: number) => {
              formData.append(key, amount.toString());
            });
          } else if (key  ===  "avatar") {
            formData.append(key, data[key] as Blob);
          } else {
            formData.append(key, data[key] as string);
          }
        });

        return {
          url: "/tip-users/",
          method: "POST",
          body: formData,
        };
      },
    }),
    patchTipUser: builder.mutation<
      { id: number },
      {
        id: string;
        name: string;
        company_name: string;
        position: string;
        description?: string;
        accent_color?: string;
        card_number: string;
        def_amount: string;
        suggested_amounts?: number[];
        avatar?: File | string | null;
        point: number | null;
      }
    >({
      query: ({ id, ...data }) => {
        const formData = new FormData();

        (Object.keys(data) as Array<keyof typeof data>).forEach((key) => {
          if (key === "suggested_amounts") {
            (data[key] as Array<number>).forEach((amount: number) => {
              formData.append(key, amount.toString());
            });
          } else if (key  ===  "avatar") {
            formData.append(key, data[key] as Blob);
          } else {
            formData.append(key, data[key] as string);
          }
        });

        return {
          url: `/tip-users/${id}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    deleteTipUser: builder.mutation<void, { id: number }>({
      query: (data) => ({
          url: `/tip-users/${data.id}/`,
          method: "DELETE",
        }),
    }),
    getTipUserStatistics: builder.query<
      {
        quantity: number;
        total_balance: number;
        tips_amount: number;
        tips_quantity: number;
      },
      void
    >({
      query: () => ({
          url: `/tip-users/statistic/`,
          method: "GET",
        }),
    }),
    usePostTipUserMutation: builder.mutation<
      void,
      { id: number; amount: number; description: string }
    >({
      query: ({ id, ...data }) => ({
          url: `/tip-users/${id}/payout/`,
          method: "POST",
          body: data,
        }),
    }),
  }),
});

export const {
  useGetTipUsersQuery,
  useLazyGetTipUserQuery,
  usePostTipUserMutation,
  useDeleteTipUserMutation,
  usePatchTipUserMutation,
  useGetTipUserStatisticsQuery,
  useUsePostTipUserMutationMutation,
} = tipUsersApi;
