import { KeyboardEvent, ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { IconButton } from "UI/IconButton";
import { ReactComponent as ArrowsReloadIcon } from "utils/img/arrows-reload.svg";
import { ReactComponent as SearchIcon } from "utils/img/search.svg";

interface Props {
  createTitle?: string;
  createPath?: string;
  createAction?: () => void;
  search: string;
  setSearch: (search: string) => void;
  hideSearch?: boolean;
  clearSearch?: boolean;
  placeholder?: string;
  refetch?: () => void;
  filters?: ReactElement;
  customButton?: ReactElement;
}

export const FilterBar = ({
  createTitle,
  createPath,
  createAction,
  search,
  setSearch,
  hideSearch,
  clearSearch,
  placeholder,
  refetch,
  filters,
  customButton,
}: Props) => {
  const { t } = useTranslation();
  const [searchWord, setSearchWord] = useState<string>(search);

  useEffect(() => {
    if (clearSearch) {
      setSearchWord("");
    }
  }, [clearSearch]);

  useEffect(() => {
    if (!search) {
      setSearchWord("");
    }
  }, [search]);

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key  ===  "Enter") {
      e.preventDefault();
      setSearch(searchWord);
    }
  };

  return (
    <Grid
      container
      style={{
        background: "#ffffff",
      }}
      p={16}
    >
      <Grid item sm={12} md="fill">
        <StyledFlex>
          {refetch && (
            <IconButton mr={16} variant="outlined" onClick={refetch}>
              <StyledArrowsReloadIcon />
            </IconButton>
          )}
          {filters}
          {hideSearch !== true && (
            <StyledWrapperSearch>
              <form style={{ width: "100%" }}>
                <TextInput
                  placeholder={placeholder || t("Поиск")}
                  size="small"
                  iconStart={<StyledSearchIcon />}
                  value={searchWord}
                  onChange={(val) => setSearchWord(val)}
                  onBlur={() => !searchWord && setSearch(searchWord)}
                  inputProps={{
                    onKeyDown: handleEnter,
                  }}
                />
              </form>
            </StyledWrapperSearch>
          )}
        </StyledFlex>
      </Grid>
      {(createPath || createAction) && createTitle ? (
        <Grid
          item
          sm={12}
          xl={3.2}
          responsive={{ xl: { pl: 20, pt: 0 }, sm: { pl: 0, pt: 20 } }}
        >
          <>
            {createPath && (
              <Link to={createPath}>
                <Button variant="contained" fullwidth>
                  {createTitle}
                </Button>
              </Link>
            )}
            {createAction && (
              <Button variant="contained" onClick={createAction} fullwidth>
                {createTitle}
              </Button>
            )}
          </>
        </Grid>
      ) : (
        <></>
      )}
      {customButton || <></>}
    </Grid>
  );
}

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledWrapperSearch = styled.div`
  width: -webkit-fill-available;
`;

const StyledArrowsReloadIcon = styled(ArrowsReloadIcon)`
  display: flex;
  align-items: center;
  path {
    fill: #838383 !important;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  path {
    fill: #838383 !important;
  }
`;
