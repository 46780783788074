import { useMemo, useRef, useState } from "react";

import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowLeftIcon } from "utils/img/arrow-left.svg";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { RESPONSIVE_SIZES } from "utils/tools";

const DOTS = "...";

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

interface Props {
  count: number;
  rowsPerPage: number;
  page: number;
  siblingCount?: number;
  dataLength?: number;
  disabledSelectedRow?: boolean;
  setPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
}

export const TablePagination = ({
  count,
  rowsPerPage,
  page,
  dataLength,
  siblingCount = 1,
  disabledSelectedRow,
  setPage,
  setRowsPerPage,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const rowsRef = useRef<HTMLDivElement>(null);

  const { accentColor } = getColors();

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(count / rowsPerPage);

    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 1;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [];
  }, [count, rowsPerPage, siblingCount, page]);

  if (count === 0 && !dataLength && page <= 1) {
    return null;
  }

  const onNext = () => {
    setPage(page + 1);
  };

  const onPrevious = () => {
    setPage(page - 1);
  };

  const lastPage = paginationRange?.[paginationRange.length - 1];

  return (
    <StyledWrapper>
      <StyledUl>
        <StyledLi
          background={accentColor}
          disabled={page === 1}
          onClick={onPrevious}
        >
          <ArrowLeftIcon />
        </StyledLi>
        {paginationRange?.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <StyledLi background={accentColor} key={index} dots>
                ...
              </StyledLi>
            );
          }

          return (
            <StyledLi
              background={accentColor}
              key={index}
              selected={pageNumber === page}
              onClick={() => setPage(Number(pageNumber))}
            >
              {pageNumber}
            </StyledLi>
          );
        })}
        {(!!dataLength || (!count && page > 1)) && page}
        <StyledLi
          background={accentColor}
          disabled={
            page === lastPage ||
            dataLength === 0 ||
            (!!dataLength && dataLength < rowsPerPage)
          }
          onClick={onNext}
        >
          <ArrowRightIcon />
        </StyledLi>
      </StyledUl>
      {!disabledSelectedRow && (
        <StyledWrapperRows ref={rowsRef} onClick={() => setIsOpen(!isOpen)}>
          <Typography variant="subtitle2">{`${t(
            "Строк на странице"
          )}: ${rowsPerPage}`}</Typography>
          <StyledArrowUpDown open={isOpen} />
          {isOpen && (
            <>
              <StyledModalOverlay onClick={() => setIsOpen(!isOpen)} />
              <StyledMenu>
                {[10, 25, 50, 100].map((rows) => (
                  <StyledMenuItem
                    key={rows}
                    onClick={() => setRowsPerPage(rows)}
                    selected={rows === rowsPerPage}
                  >
                    {rows}
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            </>
          )}
        </StyledWrapperRows>
      )}
    </StyledWrapper>
  );
};
const StyledModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
`;

const StyledWrapperRows = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  position: relative;
  @media (${RESPONSIVE_SIZES.lg}) {
    margin-top: 20px;
    margin-left: 0;
  }
`;

const StyledArrowUpDown = styled(ArrowUpDown)`
  padding-left: 16px;
  ${(props: { open: boolean }) =>
    props.open
      ? `
          padding-right: 16px;
          padding-left: 0;
          transform: rotate(180deg);
        `
      : ""};
`;
const StyledMenu = styled.div`
  position: absolute;
  padding: 6px 0;
  color: #000000;
  right: 0;
  top: -100px;
  background-color: white;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.18);
  border: 1px solid #ebebeb;
  z-index: 100;
  @media (${RESPONSIVE_SIZES.lg}) {
    width: 100%;
    padding: 6px 33px;
    left: -33px;
  }
`;
const StyledMenuItem = styled.div`
  width: 20px;
  padding: 6px 24px;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  opacity: ${(props: { selected: boolean }) => (props.selected ? "0.5" : "1")};
  @media (${RESPONSIVE_SIZES.lg}) {
    width: 100%;
    padding: 6px 0;
  }
`;
const StyledUl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type LiProps = {
  background: string;
  selected?: boolean;
  dots?: boolean;
  disabled?: boolean;
};
const StyledLi = styled.div<LiProps>`
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  margin: auto 10px;
  color: #19202e;
  align-items: center;
  letter-spacing: 0.01071em;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    border: 1px solid ${(props) => props.background};
  }

  ${(props) =>
    props.selected
      ? `
          background-color: ${props.background};
          color: #ffffff;
        `
      : ""};

  ${(props) =>
    props.disabled
      ? `
          pointer-events: none;
          &:hover {
            cursor: default;
          }
        `
      : ""};

  ${(props) =>
    props.dots
      ? `
          &:hover {
            cursor: default;
            border: 1px solid transparent;
          }
        `
      : ""};
`;

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  @media (${RESPONSIVE_SIZES.lg}) {
    flex-direction: column;
  }
`;

const ArrowRightIcon = styled(ArrowLeftIcon)`
  transform: rotate(180deg);
`;
