import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import React, { useState } from "react";

import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  PageTransactionsQueryParams,
  useGetPageTransactionsQuery,
} from "api/baseAPI/invoicing";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { dateFormatter } from "utils/date";
import { useFilterMaker } from "utils/filterMaker";
import { visibleNumber } from "utils/numbers";
import { useUrlQuery } from "utils/url";
import {
  CopyCell,
  DataItem,
  StatusPageTransaction,
} from "pages/invoicing/pages/parts/AdditionalComponents";
import { ReactComponent as ExternalLink } from "utils/img/external-link.svg";
import { PageTransactionsFilters } from "./parts/PageTransactionsFilters";
import { PageTransactionsFiltersRow } from "./parts/PageTransactionsFiltersRow";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
  page__account__in: "",
  page_id__in: "",
  id: "",
  external_id: "",
  external_uuid: "",
  created_at__gte: "",
  created_at__lte: "",
  amount__gte: "",
  amount__lte: "",
  status: "",
  page_id: "",
  description: "",
};

export const Transactions = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные страницы"), ""],
    [t("Транзакции"), ""],
  ];

  const tableCellTitles = [
    t("ID"),
    t("Страница"),
    t("ID транзакции"),
    t("UUID Транзакции"),
    t("Сумма"),
    t("Время"),
    t("Статус"),
  ];

  const [openId, setOpenId] = useState("");
  const selectedPointManager = useSelectedPointManager();

  const { queryParams, querySetters } =
    useUrlQuery<PageTransactionsQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_created_at__gte(values.created_at__gte || "");
      querySetters.set_created_at__lte(values.created_at__lte || "");
      querySetters.set_amount__gte(values.amount__gte || "");
      querySetters.set_amount__lte(values.amount__lte || "");
      querySetters.set_status(values.status || "");
      querySetters.set_id(values.id || "");
      querySetters.set_page_id(values.page_id || "");
      querySetters.set_description(values.description || "");
    },
    () => {
      set_search(initParameters.search);
      querySetters.set_status(initParameters.status);
      querySetters.set_created_at__lte(initParameters.created_at__lte);
      querySetters.set_created_at__gte(initParameters.created_at__gte);
    }
  );

  const { data, isFetching, refetch } = useGetPageTransactionsQuery({
    ...queryParams,
    page__point: `${selectedPointManager?.point_id || ""}`,
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  return (
    <Layout title={t("Транзакции")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
              filters={<PageTransactionsFilters filters={filters} />}
            />
          }
          filtersRow={
            <PageTransactionsFiltersRow
              queryParams={queryParams}
              querySetters={querySetters}
            />
          }
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data &&
            data.results.map((row) => (
              <React.Fragment key={row.id}>
                <StyledRow>
                  <StyledCell>
                    <Box flex alignItems="center" nowrap>
                      <IconCellOpen
                        open={openId === row.id}
                        onClick={() => setOpenId(openId ? "" : row.id)}
                      />
                      <div>{row.id}</div>
                    </Box>
                  </StyledCell>
                  <StyledCell>
                    {row.page.id && (
                      <StyledLink to={`/invoicing/pages/${row.page.uuid}`}>
                        <StyledIcon>
                          <ExternalLink />
                        </StyledIcon>
                        <Typography variant="subtitle2">
                          {`${row.page?.id} | ${
                            row.page?.title?.length > 10
                              ? `${row.page?.title.substring(0, 10)}...`
                              : row.page?.title
                          }`}
                        </Typography>
                      </StyledLink>
                    )}
                  </StyledCell>
                  <StyledCell>{row.external_id || ""}</StyledCell>
                  <StyledCell>{row.external_uuid || ""}</StyledCell>
                  <StyledCell>
                    {row.amount ? visibleNumber(row.amount) : ""}
                  </StyledCell>
                  <StyledCell>
                    {dateFormatter.format(row.created_at)}
                  </StyledCell>
                  <StyledCell>
                    <StatusPageTransaction status={row.status} />
                  </StyledCell>
                </StyledRow>
                {openId === row.id && (
                  <StyledRow>
                    <td colSpan={7}>
                      <Grid container pl={8} p={16} hSpace={16} vSpace={16}>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("Полное имя")}
                            content={
                              row?.customer_full_name ? (
                                <CopyCell content={row?.customer_full_name} />
                              ) : (
                                ""
                              )
                            }
                          />
                        </Grid>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("Номер телефона")}
                            content={
                              row?.customer_phone_number ? (
                                <CopyCell
                                  content={row?.customer_phone_number}
                                />
                              ) : (
                                ""
                              )
                            }
                          />
                        </Grid>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("Почта")}
                            content={
                              row?.customer_email ? (
                                <CopyCell content={row?.customer_email} />
                              ) : (
                                ""
                              )
                            }
                          />
                        </Grid>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("Комментарий")}
                            content={row.description}
                          />
                        </Grid>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("ФИО")}
                            content={
                              row?.customer_first_name ? (
                                <CopyCell
                                  content={`${row?.customer_first_name} ${row?.customer_last_name} ${row?.customer_patronymic}`}
                                />
                              ) : (
                                ""
                              )
                            }
                          />
                        </Grid>
                        <Grid sm={3} item>
                          <DataItem
                            text={t("Дата рождения")}
                            content={
                              row?.customer_birth_date ? (
                                <CopyCell content={row?.customer_birth_date} />
                              ) : (
                                ""
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </td>
                  </StyledRow>
                )}
              </React.Fragment>
            ))}
        </Table>
      </Box>
    </Layout>
  );
};

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledIcon = styled.div`
  padding: 4px;
  cursor: pointer;
  margin-right: 12px;
  display: flex;
  align-items: center;
  svg path {
    fill: #838383 !important;
  }
`;
