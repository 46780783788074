import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  useGetGatewayCredentialsMutation,
  useGetInvoicingCredentialsMutation,
} from "api/baseAPI/apiCredentials";
import { Layout } from "components/layouts/Layout";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { ReactComponent as ArrowLeftIcon } from "utils/img/arrow-left.svg";
import { ReactComponent as LinkIcon } from "utils/img/link.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";

const host = window.location.host.split(".");
host[0] = "docs";

export const ApiCredentials = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Управление"), ""],
    [t("Реквизиты"), ""],
  ];

  const selectedPointManager = useSelectedPointManager();
  const [getGatewayCredentials, getGatewayCredentialsMeta] =
    useGetGatewayCredentialsMutation();
  const [getInvoicingCredentials, getInvoicingCredentialsMeta] =
    useGetInvoicingCredentialsMutation();

  useEffect(() => {
    if (getGatewayCredentialsMeta.data?.api_token) {
      navigator.clipboard.writeText(getGatewayCredentialsMeta.data?.api_token);
    }
  }, [getGatewayCredentialsMeta.data]);

  useEffect(() => {
    if (
      getInvoicingCredentialsMeta.isSuccess &&
      !isEmpty(getInvoicingCredentialsMeta.data)
    ) {
      navigator.clipboard.writeText(
        JSON.stringify(getInvoicingCredentialsMeta.data)
      );
    }
  }, [getInvoicingCredentialsMeta]);

  useSuccessNotification(
    [getGatewayCredentialsMeta],
    t("Токен скопирован в буфер обмена")
  );
  useSuccessNotification(
    [getInvoicingCredentialsMeta],
    t("Реквизиты скопированы в буфер обмена")
  );
  useErrorNotification([getGatewayCredentialsMeta]);
  useErrorNotification([getInvoicingCredentialsMeta]);

  return (
    <Layout title={t("Реквизиты")} breadCrumbs={breadCrumbs}>
      <Grid container p={24} hSpace={24} vSpace={24}>
        <Grid item sm={12} xl={6}>
          <Box p={24} style={{ background: "white" }}>
            <Typography variant="h3" mb={12}>
              {t("Платежное API")}
            </Typography>
            <Typography variant="subtitle1">
              {t("Реквизиты, необходимые для интеграции с платежным API")}
            </Typography>
            <Box
              flex
              responsive={{
                xl: { mt: 32, direction: "row" },
                sm: { mt: 16, direction: "column-reverse" },
              }}
            >
              <Button
                mt={8}
                variant="outlined"
                iconEnd={<LinkIcon />}
                disabled={getGatewayCredentialsMeta.isLoading}
                onClick={() =>
                  getGatewayCredentials({
                    point: selectedPointManager.point?.id || 0,
                  })
                }
              >
                {t("Получить токен")}
              </Button>
              <a
                rel="noreferrer"
                href={`https://${host.join(".")}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  mt={8}
                  variant="text"
                  iconEnd={
                    <ArrowLeftIcon style={{ transform: "rotate(180deg)" }} />
                  }
                  responsive={{
                    xl: { ml: 12 },
                    sm: { mt: 0 },
                  }}
                >
                  {t("Смотреть документацию")}
                </Button>
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} xl={6}>
          <Box p={24} style={{ background: "white" }}>
            <Typography variant="h3" mb={12}>
              {t("API инвойсинга")}
            </Typography>
            <Typography variant="subtitle1">
              {t("Реквизиты, необходимые для интеграции с API инвойсинга")}
            </Typography>
            <Box
              flex
              responsive={{
                xl: { mt: 32, direction: "row" },
                sm: { mt: 16, direction: "column-reverse" },
              }}
            >
              <Button
                mt={8}
                variant="outlined"
                iconEnd={<LinkIcon />}
                disabled={getInvoicingCredentialsMeta.isLoading}
                onClick={() => getInvoicingCredentials()}
              >
                {t("Получить реквизиты")}
              </Button>
              <a
                rel="noreferrer"
                href={`https://${host.join(".")}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  mt={8}
                  variant="text"
                  iconEnd={
                    <ArrowLeftIcon style={{ transform: "rotate(180deg)" }} />
                  }
                  responsive={{
                    xl: { ml: 12 },
                    sm: { mt: 0 },
                  }}
                >
                  {t("Смотреть документацию")}
                </Button>
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};
