/* eslint-disable no-param-reassign */
import { saveAs } from "file-saver";
import { includes, reduce } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as XLSX from "xlsx";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import { useGetUsersMePayoutsPermissionsQuery } from "api/baseAPI/user";
import { useWalletInfoManager } from "hooks/useSelectedEntity";
import { getColors } from "utils/getColors";
import { ReactComponent as DownloadIcon } from "utils/img/download.svg";
import { ReactComponent as InfoIcon } from "utils/img/info.svg";
import { ReactComponent as PercentIcon } from "utils/img/percent.svg";
import { ReactComponent as TwoWayArrowsIcon } from "utils/img/two-way-arrows.svg";
import { visibleNumber } from "utils/numbers";
import { useCurrencyIcon } from "utils/useStyle";
import { IPayoutMethod } from "pages/payout/logic/payoutMethods";
import {
  PayoutData,
  usePayoutForm,
} from "pages/payout/logic/payoutsCreateLogic";

interface PayoutInfoPanelProps {
  selectedPayoutMethod: IPayoutMethod;
  payouts: Record<string, PayoutData>;
  isSubmitting: boolean;
}

export const PayoutInfoPanel = ({
  selectedPayoutMethod,
  payouts,
  isSubmitting,
}: PayoutInfoPanelProps) => {
  const { pageBackground } = getColors();
  const { t, i18n } = useTranslation();

  const [showConfirm, setShowConfirm] = useState(false);

  const getPayoutsPermissionsQuery = useGetUsersMePayoutsPermissionsQuery();
  const walletInfo = useWalletInfoManager();
  const currencyIcon = useCurrencyIcon(walletInfo?.currency || "UAH");

  const payoutAmount = () =>
    Object.entries(payouts).reduce((acc, [, v]) => {
      acc += Number(v.amount);
      return acc;
    }, 0);

  const [hack, setHack] = useState(0);

  const handleChangeDelay = (val: string) => {
    selectedPayoutMethod?.notifyChangeDelay(
      Number(val.replace(/[^0-9.]/g, "").substring(0, 4))
    );
    setHack(hack + 1);
  };

  const { additionalFields, isAdditionalFieldsLoading } =
    usePayoutForm(selectedPayoutMethod);

  const exportToExcel = (type: "csv" | "xlsx") => {
    const data = selectedPayoutMethod?.jsonExample?.map((el) => ({
      ...reduce(
        el,
        (acc, value, key) => ({
          ...acc,
          // @ts-ignore
          [t(key)]: type === "csv" ? `${value}` : value,
        }),
        {}
      ),
      ...(additionalFields?.length
        ? reduce(
            additionalFields,
            (acc, field) => ({
              ...acc,
              [field?.translations?.[`name_${i18n.language}`] || field?.name]:
                "",
            }),
            {}
          )
        : {}),
    }));

    const filename = `${selectedPayoutMethod?.filenameExample || "sample"}-${
      i18n.language
    }.${type}`;
    // @ts-ignore
    const worksheet = XLSX.utils.json_to_sheet(data);
    if (type === "csv") {
      const csv = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      saveAs(blob, filename);
      return;
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, filename);
  };

  return (
    <>
      <Typography variant="h6">{t("Сумма выплат")}</Typography>
      <StyledTypography variant="h3">
        <>
          {currencyIcon}
          {visibleNumber(payoutAmount())}
        </>
      </StyledTypography>
      <StyledDivider />
      <StyledWrapperCommission>
        <StyledWrapperIcon background="rgba(238, 48, 35, 0.1)">
          <PercentIcon />
        </StyledWrapperIcon>
        <div>
          <StyledTypographyGrey variant="inputText">
            {t("Общая комиссия")}
          </StyledTypographyGrey>
          <StyledTypographyAmount variant="inputText">
            <>
              {currencyIcon}
              {visibleNumber(
                Math.abs(
                  Object.keys(
                    selectedPayoutMethod?.getCommissions(payouts) || 0
                  ).reduce((acc, key) => {
                    acc +=
                      selectedPayoutMethod?.getCommissions(payouts)[key]
                        .point || 0;
                    return acc;
                  }, 0)
                )
              )}
            </>
          </StyledTypographyAmount>
        </div>
      </StyledWrapperCommission>
      <StyledWrapperCommission>
        <StyledWrapperIcon background="rgba(64, 137, 247, 0.1)">
          <TwoWayArrowsIcon />
        </StyledWrapperIcon>
        <div>
          <StyledTypographyGrey variant="inputText">
            {t("Количество платежей")}
          </StyledTypographyGrey>
          <StyledTypographyAmount variant="inputText">
            <>{Object.entries(payouts).length}</>
          </StyledTypographyAmount>
        </div>
      </StyledWrapperCommission>
      <StyledPaper background={pageBackground}>
        <StyledTypographyCenter variant="h6">
          {t("Финальная сумма выплат")}
        </StyledTypographyCenter>
        <StyledDividerDashed />
        <StyledTypographyGrey variant="inputText">
          {t("Сумма выплат с учетом комиссии")}
        </StyledTypographyGrey>
        <StyledTypographyAmount variant="inputText">
          <>
            {currencyIcon}
            {visibleNumber(
              Number(
                Object.entries(payouts).reduce((acc, [, v]) => {
                  acc += Number(v.amount);
                  return acc;
                }, 0)
              ) +
                Number(
                  Object.keys(
                    selectedPayoutMethod?.getCommissions(payouts) || {}
                  ).reduce((acc, key) => {
                    acc +=
                      selectedPayoutMethod?.getCommissions(payouts)[key]
                        .point || 0;
                    return acc;
                  }, 0)
                )
            )}
          </>
        </StyledTypographyAmount>
        <StyledDivider my={16} color="#B9BFC8" />
        <StyledTypographyGrey variant="inputText">
          {t("Сумма получателей с учетом комиссии")}
        </StyledTypographyGrey>
        <StyledTypographyAmount variant="inputText">
          <>
            {currencyIcon}
            {visibleNumber(
              Number(
                Object.entries(payouts).reduce((acc, [, v]) => {
                  acc += Number(v.amount);
                  return acc;
                }, 0)
              ) +
                Number(
                  Object.keys(
                    selectedPayoutMethod?.getCommissions(payouts) || {}
                  ).reduce((acc, key) => {
                    acc +=
                      selectedPayoutMethod?.getCommissions(payouts)[key]
                        .customer || 0;
                    return acc;
                  }, 0)
                )
            )}
          </>
        </StyledTypographyAmount>
        <StyledDownloadButtons>
          <div>
            {selectedPayoutMethod?.jsonExample ? (
              <Button
                variant="text"
                size="small"
                iconStart={<DownloadIcon />}
                onClick={() => exportToExcel("csv")}
                px={5}
                disabled={isAdditionalFieldsLoading}
                isLoading={isAdditionalFieldsLoading}
              >
                {`${t("Пример")} CSV`}
              </Button>
            ) : (
              <a
                href={
                  selectedPayoutMethod?.csvExample
                    ? selectedPayoutMethod?.csvExample?.replace(
                        ".csv",
                        `-${i18n.language}.csv`
                      )
                    : ""
                }
              >
                <Button
                  variant="text"
                  size="small"
                  iconStart={<DownloadIcon />}
                  onClick={() => console.warn("Start download csv")}
                  px={5}
                >
                  {`${t("Пример")} CSV`}
                </Button>
              </a>
            )}
          </div>
          <StyledDividerVertical />
          <div>
            {selectedPayoutMethod?.jsonExample ? (
              <Button
                variant="text"
                size="small"
                iconStart={<DownloadIcon />}
                onClick={() => exportToExcel("xlsx")}
                px={5}
                disabled={isAdditionalFieldsLoading}
                isLoading={isAdditionalFieldsLoading}
              >
                {`${t("Пример")} XLS`}
              </Button>
            ) : (
              <a
                href={
                  selectedPayoutMethod?.xlsxExample
                    ? selectedPayoutMethod?.xlsxExample?.replace(
                        ".xlsx",
                        `-${i18n.language}.xlsx`
                      )
                    : ""
                }
              >
                <Button
                  variant="text"
                  size="small"
                  iconStart={<DownloadIcon />}
                  onClick={() => console.warn("Start download xlsx")}
                  px={5}
                >
                  {`${t("Пример")} XLS`}
                </Button>
              </a>
            )}
          </div>
        </StyledDownloadButtons>
      </StyledPaper>

      {selectedPayoutMethod?.pointManager?.service_config
        ?.payouts_custom_delay_interface && (
        <TextInput
          size="small"
          mt={30}
          placeholder={t("Задержка между выплатами")}
          value={
            (selectedPayoutMethod?.values?.delay &&
              String(selectedPayoutMethod?.values?.delay)) ||
            ""
          }
          onChange={(val) => handleChangeDelay(val)}
          error={Boolean(selectedPayoutMethod?.errors?.delay)}
          helperText={selectedPayoutMethod?.errors?.delay || ""}
        />
      )}

      <Button
        variant="outlined"
        type="button"
        fullwidth
        my={15}
        onClick={() => {
          selectedPayoutMethod?.notifyFormSaveToTemplate(true);
        }}
      >
        {t("Сохранить выплаты в шаблоны")}
      </Button>

      <Button
        type="button"
        fullwidth
        onClick={() => {
          selectedPayoutMethod?.handleValidate().then((res) => {
            if (res) {
              setShowConfirm(true);
            }
          });
        }}
        isLoading={isSubmitting}
        disabled={
          !includes(getPayoutsPermissionsQuery.data, "add_registry") ||
          isSubmitting
        }
      >
        {t("Отправить платеж")}
      </Button>

      <Modal
        hideHeader
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        height={270}
      >
        <Box p={20}>
          <Grid container>
            <Grid item sm={12}>
              <Box justifyContent="center" flex pb={25}>
                <StyledInfoIconWrapper>
                  <InfoIcon height={40} width={40} />
                </StyledInfoIconWrapper>
              </Box>
            </Grid>

            <Grid item sm={12} style={{ textAlign: "center" }} my={25}>
              <Typography variant="h4">
                {t("Подтвердите отправку выплат")}
              </Typography>
            </Grid>

            <Grid item sm={12}>
              <Grid container justifyContent="space-around">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => setShowConfirm(false)}
                  >
                    {t("Отменить")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowConfirm(false);
                      selectedPayoutMethod?.handleSubmit();
                    }}
                  >
                    {t("Подтвердить")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

const StyledTypography = styled(Typography)`
  margin-top: 16px;
  display: flex;

  & div {
    margin-left: 0;
  }
`;
const StyledDivider = styled.hr<{ my?: number; color?: string }>`
  margin: ${({ my }) => my || 24}px 0;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: ${({ color }) => color || "#ecf0f6"};
`;
const StyledWrapperCommission = styled.div`
  display: flex;
  margin-bottom: 24px;
`;
const StyledWrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  background: ${({ background }: { background: string }) => background};
`;
const StyledTypographyCenter = styled(Typography)`
  text-align: center;
`;
const StyledTypographyGrey = styled(Typography)`
  color: #374258;
`;
const StyledTypographyAmount = styled(Typography)`
  margin-top: 6px;
  display: flex;
  color: #000000;

  & div {
    margin-left: 0;
  }
`;
const StyledDividerDashed = styled.div`
  width: 100%;
  margin: 24px 0;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent 0,
    transparent 15px,
    rgba(185, 191, 200, 0.4) 15px,
    rgba(185, 191, 200, 0.4) 30px
  );
  background-size: 30px;
  background-repeat: repeat-x;
`;
const StyledPaper = styled.div<{ background: string }>`
  padding: 24px 24px 0;
  background-color: ${({ background }) => background};
  position: relative;

  &:before {
    position: absolute;
    border-radius: 50%;
    top: 46px;
    left: -20px;
    content: "";
    width: 40px;
    height: 40px;
    z-index: 10;
    background: white;
  }

  &:after {
    position: absolute;
    border-radius: 50%;
    top: 46px;
    right: -20px;
    content: "";
    width: 40px;
    height: 40px;
    z-index: 10;
    background: white;
  }
`;
const StyledDividerVertical = styled.hr`
  margin: 0;
  flex-shrink: 0;
  border-width: 0px thin 0px 0px;
  border-style: solid;
  border-color: #b9bfc8;
  height: auto;
  align-self: stretch;
`;
const StyledDownloadButtons = styled.div`
  margin-top: 24px;
  border-top: 1px solid #b9bfc8;
  height: 64px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledInfoIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  width: 84px;
  background-color: #eaeff7;
`;
