import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "api/baseQuery";

export const baseSplitApi = createApi({
  reducerPath: "baseSplitApi",
  keepUnusedDataFor: 0,
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [
    "AccountTariffs",
    "ContactWallets",
    "Wallet",
    "User",
    "UserInfos",
    "UserDocuments",
    "TransactionFrauds",
    "TransactionFraud",
    "Reports",
    "ReportRequests",
    "PointManagers",
    "PointManager",
    "PointData",
    "PointTheme",
    "PayoutTemplates",
    "PayoutTemplate",
    "PayoutTemplateElements",
    "Payouts",
    "PayoutRegistries",
    "Transactions",
    "Pages",
    "Page",
    "Customizations",
    "Customization",
    "PageTemplates",
    "PageTemplate",
    "SocInvoiceDatas",
    "SocInvoiceData",
    "CustomizationRules",
    "CustomizationRule",
    "Themes",
    "Theme",
    "CheckoutInvoices",
    "CheckoutInvoice",
    "CheckoutInvoiceTemplates",
    "CheckoutInvoiceTemplate",
  ],
});
