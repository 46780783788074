import { useEffect } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export function useServerErrorsInForm(
  setFieldError: (field: string, value: string | undefined) => void,
  error: FetchBaseQueryError | SerializedError | undefined
) {
  useEffect(() => {
    if (error && "data" in error) {
      const data = error.data as Record<string, string>;
      (Object.keys(data) as string[]).forEach((fieldName) => {
        setFieldError(fieldName, data[fieldName]);
      });
    }
  }, [error]);
}
