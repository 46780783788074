import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

export interface Page {
  id: number;
  uuid: string;
  name: string;
  title: string;
  service: { id: number; name: string };
  bp_service: { id: number; name: string };
  point: { id: number; name: string };
  wallet: { id: number; name: string };
  account: number;
  status: 1 | 0;
  amount_currency: string;
  created_at: string;
  updated_at: string;
  active_till: string;
  min_amount: string;
  fixed_amount: string;
  locale: string;
  available_locales: string[];
  customization: {
    id: string;
    title: string;
    background_color: string;
    form_color: string;
    form_accent_color: string;
    font_color: string;
    font: string;
    button_color: string;
    logo: string;
  };
  additional_fields: string[];
  preferred_amounts: string[];
  available_payment_methods: number[];
  prefilled_fields: Record<string, string>;
  pay_url: string;
  fail_amount: string;
  fail_count: number;
  pending_count: number;
  success_amount: string;
  success_count: number;
  is_reusable: boolean;
  callback_url?: string;
  description_template: string;
  preferred_amounts_logic: 1 | 2;
  kind: 1 | 3;
}
export type PagesQueryParams = {
  page: string;
  page_size: string;
  search: string;
  status: string;
  created_at__lte: string;
  created_at__gte: string;
  title__icontains: string;
  uuid__icontains: string;
  wallet: string;
  service: string;
  success_amount__gte: string;
  success_amount__lte: string;
};
export type PageTemplatesQueryParams = {
  page: string;
  page_size: string;
  search: string;
  created_at__lte: string;
  created_at__gte: string;
  title__icontains: string;
  id: string;
  wallet: string;
  service: string;
  fixed_amount__gte: string;
  fixed_amount__lte: string;
};
export type PageTransactionsQueryParams = {
  page: string;
  page_size: string;
  search: string;
  id: string;
  created_at__gte: string;
  created_at__lte: string;
  amount__gte: string;
  amount__lte: string;
  status: string;
  page_id: string;
  page_id__in: string;
  description: string;
};

export const invoicingApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPages: builder.query<
      { count: number; results: Page[] },
      PagesQueryParams & { point: string }
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/invoicing/pages/?${query.toString()}`;
      },
      providesTags: ["Pages"],
    }),
    getPage: builder.query<Page, { uuid: string }>({
      query: ({ uuid }) => `/invoicing/pages/${uuid}/`,
      providesTags: ["Page"],
    }),
    createPage: builder.mutation<Page, CreatePage>({
      query: (data) => ({
        url: "/invoicing/pages/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Pages", "Page"],
    }),
    updatePage: builder.mutation<Page, Partial<CreatePage> & { id: string }>({
      query: ({ id, ...data }) => ({
        url: `/invoicing/pages/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Pages", "Page"],
    }),
    setStatusPage: builder.mutation<void, { id: string; status: number }>({
      query: ({ id, status }) => ({
        url: `/invoicing/pages/${id}/`,
        method: "PATCH",
        body: { status },
      }),
      invalidatesTags: ["Pages", "Page"],
    }),
    deletePage: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/invoicing/pages/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Pages", "Page"],
    }),

    getCustomizations: builder.query<
      { count: number; results: Customization[] },
      { page_size: string; is_public: string }
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/invoicing/page_customizations/?${query.toString()}`;
      },
      providesTags: ["Customizations"],
    }),
    createCustomization: builder.mutation<
      Customization,
      { formData: FormData }
    >({
      query: ({ formData }) => ({
        url: "/invoicing/page_customizations/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Customizations", "Customization"],
    }),
    updateCustomization: builder.mutation<
      Customization,
      { formData: FormData; id: string }
    >({
      query: ({ id, formData }) => ({
        url: `/invoicing/page_customizations/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["Customizations", "Customization"],
    }),

    getPageStatsByUuid: builder.query<
      {
        fail_amount: string;
        fail_count: number;
        pending_count: number;
        success_amount: string;
        success_count: number;
      },
      { uuid: string }
    >({
      query: ({ uuid }) => `/invoicing/pages/${uuid}/stats/`,
    }),

    getPageTemplates: builder.query<
      { count: number; results: PageTemplate[] },
      PageTemplatesQueryParams & { point: string }
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/invoicing/page-templates/?${query.toString()}`;
      },
      providesTags: ["PageTemplates"],
    }),
    getPageTemplate: builder.query<PageTemplate, { uuid: string }>({
      query: ({ uuid }) => `/invoicing/page-templates/${uuid}/`,
      providesTags: ["PageTemplate"],
    }),
    createPageTemplate: builder.mutation<PageTemplate, CreatePageTemplate>({
      query: (data) => ({
        url: "/invoicing/page-templates/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PageTemplates", "PageTemplate"],
    }),
    updatePageTemplate: builder.mutation<
      PageTemplate,
      Partial<CreatePageTemplate> & { id: string }
    >({
      query: ({ id, ...data }) => ({
        url: `/invoicing/page-templates/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["PageTemplates", "PageTemplate"],
    }),
    deletePageTemplate: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/invoicing/page-templates/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["PageTemplates", "PageTemplate"],
    }),

    getPageTransactions: builder.query<
      {
        count: number;
        results: {
          amount: string;
          created_at: string;
          external_id: string;
          external_uuid: string;
          customer_email: string;
          customer_full_name: string;
          customer_phone_number: string;
          customer_first_name: string;
          customer_last_name: string;
          customer_patronymic: string;
          customer_birth_date: string;
          description: string;
          id: string;
          page: { uuid: string; id: number; title: string };
          status: 1 | 0 | -1;
          uuid: string;
        }[];
      },
      PageTransactionsQueryParams & { page__point: string }
    >({
      query: (params) => {
        const query = getQuery(params, ["page_id"], params.search?.length > 12);
        return `/invoicing/page-transactions/?${query.toString()}`;
      },
    }),

    getAvailablePaymentMethods: builder.query<number[], { id: number }>({
      query: ({ id }) => `/invoicing/services/${id}/available-payment-methods/`,
    }),
  }),
});

export const {
  useGetPagesQuery,
  useGetPageQuery,
  useCreatePageMutation,
  useUpdatePageMutation,
  useDeletePageMutation,
  useSetStatusPageMutation,
  useGetPageStatsByUuidQuery,
  useLazyGetPageStatsByUuidQuery,

  useGetCustomizationsQuery,
  useCreateCustomizationMutation,
  useUpdateCustomizationMutation,

  useGetPageTemplatesQuery,
  useGetPageTemplateQuery,
  useCreatePageTemplateMutation,
  useUpdatePageTemplateMutation,
  useDeletePageTemplateMutation,

  useGetPageTransactionsQuery,

  useLazyGetAvailablePaymentMethodsQuery,
} = invoicingApi;

type CreatePage = {
  title: string;
  amount_currency: string;
  locale: string;
  available_locales: string[];
  fixed_amount: string | null;
  active_till: string | null;
  callback_url?: string;
  service: string;
  bp_service: string | null;
  min_amount: string | null;
  wallet: string;
  point: string;
  is_reusable: boolean;
  additional_fields: string[];
  preferred_amounts: string[];
  available_payment_methods: number[];
  prefilled_fields: Record<string, string> | null;
  customization: string | null;
  status: number;
  description_template: string;
  preferred_amounts_logic: 1 | 2;
  kind: 1 | 2 | 3;
};
type Customization = {
  id: number;
  title: string;
  background_color: string;
  form_color: string;
  form_accent_color: string;
  font_color: string;
  font: string;
  button_color: string;
};
type CreatePageTemplate = {
  title: string;
  amount_currency: string;
  point: string;
  locale: string;
  available_locales: string[];
  fixed_amount: string | null;
  service: string;
  bp_service: string | null;
  min_amount: string | null;
  wallet: string;
  is_reusable: boolean;
  additional_fields: string[];
  preferred_amounts: string[];
  available_payment_methods: number[];
  prefilled_fields: Record<string, string> | null;
  customization: string | null;
  status: number;
  description_template: string;
  preferred_amounts_logic: 1 | 2;
  kind: 1 | 3;
};
export type PageTemplate = {
  title: string;
  amount_currency: string;
  locale: string;
  available_locales: string[];
  point: string;
  fixed_amount: string;
  service: {
    id: string;
    name: string;
  };
  bp_service: {
    id: string;
    name: string;
  };
  min_amount: string;
  wallet: {
    id: string;
    name: string;
  };
  is_reusable: boolean;
  additional_fields: string[];
  customization: {
    id: string;
    title: string;
    background_color: string;
    form_color: string;
    form_accent_color: string;
    font_color: string;
    font: string;
    button_color: string;
    logo: string;
  };
  account: string;
  created_at: string;
  fail_amount: string;
  fail_count: string;
  id: number;
  status: 1 | 0;
  success_amount: string;
  success_count: string;
  updated_at: string;
  uuid: string;
  pay_url: string;
  preferred_amounts: string[];
  available_payment_methods: number[];
  prefilled_fields: Record<string, string>;
  description_template: string;
  preferred_amounts_logic: 1 | 2;
  kind: 1 | 3;
};
