import { find, isString } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LinearProgress } from "components/LinearProgress";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { hexToRGB } from "utils/mixColors";
import { Margin, marginProps, marginStyle } from "UI/utility/maring";
import { Checkbox } from "./Checkbox";
import { StyledLabelInput } from "./TextInput";

type MultiSelectProps = {
  className?: string;
  placeholder?: string;
  name?: string;
  value?: string[];
  isLoading?: boolean;
  inputValue?: string;
  onChangeInputValue?: (val: string) => void;
  onChange?: (val: string[]) => void;
  label?: string;
  error?: boolean | string;
  helperText?: string | boolean;
  disabled?: boolean;
  disabledChange?: boolean;
  size?: "small" | "default";
  options: { value: string; label: string }[];
} & Margin;

export const MultiSelect = ({
  className,
  placeholder,
  name,
  value,
  onChange,
  onChangeInputValue,
  label,
  error,
  helperText,
  disabled,
  size,
  options,
  isLoading,
  disabledChange,
  inputValue,
  ...otherProps
}: MultiSelectProps) => {
  const { t } = useTranslation();
  const [focus, setFocus] = useState<boolean>(false);
  const [showOptionList, setShowOptionList] = useState<boolean>(false);
  const selectOptionListRef = useRef<HTMLDivElement>(null);
  const { pageBackground, accentColor } = getColors();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        selectOptionListRef.current &&
        !selectOptionListRef.current.contains(event.target as Node)
      ) {
        setShowOptionList(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selectOptionListRef]);

  const handleSelectOption = (selectValue: string) => {
    if (disabledChange) return;
    if (value?.includes(selectValue)) {
      if (onChange)
        onChange(value.filter((existsValue) => existsValue !== selectValue));
    } else if (value) {
      if (onChange) onChange([...value, selectValue]);
    }
    if (onChangeInputValue) onChangeInputValue("");
  };

  return (
    <StyledSelectWrapper
      className={className}
      disabled={disabled}
      ref={selectOptionListRef}
      {...marginProps(otherProps)}
    >
      <StyledFieldset
        disabled={disabled}
        error={!!error}
        bordercolor={accentColor}
        size={size}
        focus={focus}
      >
        <StyledLabelInput empty={!label} size={size}>
          {label || ""}
        </StyledLabelInput>
        <StyledInput
          placeholder={placeholder}
          name={name}
          value={
            focus && isString(inputValue)
              ? inputValue
              : value?.length === 1
              ? `${
                  find(options, { value: value?.[0] })?.label ||
                  value?.length ||
                  ""
                }`
              : value?.length || ""
          }
          onChange={(e) => {
            if (onChangeInputValue) onChangeInputValue(e.target.value);
          }}
          readOnly={!isString(inputValue)}
          small={size === "small"}
          onClick={() => setShowOptionList(!showOptionList)}
          onMouseDown={() => {
            setFocus(true);
            if (onChangeInputValue) onChangeInputValue("");
          }}
          onBlur={() => setFocus(false)}
        />
      </StyledFieldset>
      <StyledArrowUpDown
        small={size === "small" ? 1 : 0}
        open={showOptionList}
        onClick={() => !disabled && setShowOptionList(!showOptionList)}
      />
      {helperText ? (
        <StyledHelperText error={!!error}>{helperText}</StyledHelperText>
      ) : null}
      {isLoading ? (
        <StyledWrapperModal
          isHelperText={!!helperText}
          showOptionList={showOptionList}
        >
          <LinearProgress />
        </StyledWrapperModal>
      ) : (
        <StyledWrapperModal
          isHelperText={!!helperText}
          showOptionList={showOptionList}
        >
          {options.length !== 0 ? (
            <>
              {options.map((option, index) => (
                <StyledOption
                  key={index}
                  background={pageBackground}
                  // onClick={() => handleSelectOption(option.value)}
                  active={!!value?.includes(option.value)}
                >
                  <Checkbox
                    py={12}
                    px={8}
                    value={!!value?.includes(option.value)}
                    onChange={() => handleSelectOption(option.value)}
                    label={option.label}
                  />
                </StyledOption>
              ))}
            </>
          ) : (
            t("Нет данных для отображения")
          )}
        </StyledWrapperModal>
      )}
    </StyledSelectWrapper>
  );
};

const StyledFieldset = styled.fieldset<{
  error?: boolean;
  bordercolor: string;
  focus?: boolean;
  size?: string;
}>`
  border: none;
  border: 1px solid ${(props) => (props.error ? "#D00025" : "#B9BFC8")};
  padding: 0;
  margin-top: -8px;
  padding-left: 5px;
  height: 63.5px;
  &:hover {
    border: 1px solid
      ${(props) => (props.error ? "#D00025" : hexToRGB(props.bordercolor, 0.4))};
    legend {
      color: ${(props) => hexToRGB(props.bordercolor, 0.4)};
    }
  }
  ${(props) =>
    props.focus
      ? `
          border: 1px solid
            ${props.error ? "#D00025" : props.bordercolor} !important;
          legend {
            color: ${props.bordercolor} !important;
          }
        `
      : ""};
  ${(props) => (props.size === "small" ? "height: 54px;" : "")};
  &:disabled {
    opacity: 0.5;
    border: 1px solid #c8cfdc;
    legend {
      color: #c8cfdc;
    }
  }
`;
const StyledArrowUpDown = styled(ArrowUpDown)`
  position: absolute;
  path {
    fill: #393939 !important;
  }
  top: 27px;
  right: 20px;
  ${(props) => (props.small ? `top: 22px;` : "")};
  ${(props: { open: boolean; small: number }) =>
    props.open ? `transform: rotate(180deg);` : ""};
`;
const StyledOption = styled.div`
  padding: 0px 24px;
  margin-right: -24px;
  margin-left: -24px;
  cursor: pointer;
  border-bottom: 1px solid
    ${(props: { background: string; active: boolean }) =>
      props.active ? props.background : "white"};
  ${(props) => (props.active ? `background: ${props.background};` : "")}
  &:hover {
    background: ${(props) => hexToRGB(props.background, 0.4)};
    border-bottom: 1px solid ${(props) => hexToRGB(props.background, 0.4)};
  }
`;
const StyledWrapperModal = styled.div`
  display: ${(props: { showOptionList: boolean; isHelperText?: boolean }) =>
    props.showOptionList ? "flex" : "none"};
  flex-direction: column;
  position: absolute;
  padding: 16px 24px;
  width: -webkit-fill-available;
  color: #000000;
  top: calc(100% - ${(props) => (props.isHelperText ? "19" : "0")}px);
  background-color: white;
  z-index: 160;
  background: #ffffff;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.18);
  max-height: 250px;
  overflow-y: auto;
`;

const StyledSelectWrapper = styled.div<{ disabled?: boolean } & Margin>`
  ${(props) => [marginStyle].map((fn) => fn(props)).join("")}
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

const StyledInput = styled.input<{
  small?: boolean;
}>`
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 20px;
  padding-left: 15px;
  background: transparent;
  ${(props) =>
    props.small
      ? `
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          padding: 15px;
          padding-left: 10px;
        `
      : ""};
  width: 100%;
  padding-top: 6.5px;
  padding-right: 35px;
  color: #19202e;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  &:placeholder {
    color: #727272;
  }
`;

const StyledHelperText = styled.div`
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: { error: boolean }) =>
    props.error ? "#D00025" : "#727272"};
`;
