import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

type SliceState = {
  access_token: string;
  refresh_token: string;
  style: {
    name: string;
    logo: string;
    is_fc: boolean;
    guest_dashboard_available: boolean;
    description: string;
    receipt_endpoint: string;
    helpcrunch_organization: string;
    helpcrunch_app_id: string;
    accent_color: string;
    background_color: string;
    background_image: string;
    font: string;
    soc_registration_available: boolean;
  };
};

const initialState = {
  access_token: window.localStorage.getItem("access_token_cabinet"),
  refresh_token: window.localStorage.getItem("refresh_token"),
  style: {
    name: "",
    logo: "",
    is_fc: false,
    guest_dashboard_available: false,
    description: "",
    receipt_endpoint: "",
    accent_color: "",
    background_color: "",
    background_image: "",
    font: "",
    soc_registration_available: false,
  },
} as SliceState;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (
      state: SliceState,
      action: PayloadAction<Pick<SliceState, "access_token" | "refresh_token">>
    ) => {
      window.localStorage.setItem(
        "access_token_cabinet",
        action.payload.access_token
      );
      window.localStorage.setItem(
        "refresh_token",
        action.payload.refresh_token
      );
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    removeAuth: (state: SliceState) => {
      state.access_token = "";
      state.refresh_token = "";
      window.localStorage.removeItem("access_token_cabinet");
      window.localStorage.removeItem("refresh_token");
    },
    setStyle: (
      state: SliceState,
      action: PayloadAction<SliceState["style"]>
    ) => {
      state.style = { ...action.payload };
    },
    removeStyle: (state: SliceState) => {
      state.style = { ...initialState.style };
    },
  },
});

export const { setAuth, removeAuth, setStyle, removeStyle } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentRefreshToken = (state: RootState) =>
  state.auth.refresh_token;
export const selectCurrentToken = (state: RootState) => state.auth.access_token;
export const selectCurrentStyle = (state: RootState) => state.auth.style;
