import { useFormik } from "formik";
import "quill-paste-smart";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  useLazyGetPointThemeQuery,
  useResetThemeMutation,
  useUpdateThemeMutation,
} from "api/baseAPI/points";
import { LinearProgress } from "components/LinearProgress";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import i18n from "locale";
import { getColors } from "utils/getColors";
import { ReactComponent as ReloadIcon } from "utils/img/arrows-reload.svg";
import { ReactComponent as CaretCircleUpIcon } from "utils/img/caret-circle-up.svg";
import { ReactComponent as UploadIcon } from "utils/img/upload.svg";
import { hexToRGB } from "utils/mixColors";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { BoxIcon } from "pages/invoicing/pages/parts/SecondStep";
import defaultCss from "./defaultCss";
import defaultHtml from "./defaultHtml";

export const ThemeSettings = () => {
  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState(true);
  const [loadingUpload, setloadingUpload] = useState(false);

  const { pageBackground, accentColor } = getColors();
  const selectedPointManager = useSelectedPointManager();
  const [getPointTheme, getPointThemeMeta] = useLazyGetPointThemeQuery();
  const [resetTheme, resetThemeMeta] = useResetThemeMutation();
  const [updateTheme, updateThemeMeta] = useUpdateThemeMutation();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (i18n.language) {
      setReload(true);
      setTimeout(() => setReload(false), 0);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (selectedPointManager.point?.id) {
      getPointTheme({
        point: selectedPointManager.point?.id,
      });
    }
  }, [selectedPointManager.point?.id]);

  const form = useFormik<{
    styles: string;
  }>({
    initialValues: {
      styles: "",
    },
    onSubmit: (values) => {
      updateTheme({
        id: selectedPointManager.point?.id,
        styles: values.styles,
      });
    },
    validationSchema: Yup.object().shape({
      styles: Yup.string().required(t("Обязательное поле")),
    }),
  });

  useTranslateFormErrors(form);

  const handleReset = () => {
    resetTheme({
      id: selectedPointManager.point?.id,
    });
  };

  const handleResetForm = () => {
    if (getPointThemeMeta.isSuccess && getPointThemeMeta?.data?.styles) {
      form.setFieldValue("styles", getPointThemeMeta?.data?.styles);
    }
  };

  const handleUploadFile = async (fileList: File[]) => {
    if (!fileList) return;
    try {
      setloadingUpload(true);
      const file = fileList[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        form.setFieldValue("styles", reader.result);
      };
      reader.readAsText(file);
    } catch (e) {
      toast.error(t("Ошибка"));
    } finally {
      setloadingUpload(false);
    }
  };

  useEffect(() => {
    if (getPointThemeMeta.isSuccess && getPointThemeMeta?.data?.styles) {
      form.setFieldValue("styles", getPointThemeMeta?.data?.styles);
    }
  }, [getPointThemeMeta]);

  useSuccessNotification([resetThemeMeta]);
  useSuccessNotification([updateThemeMeta]);
  useErrorNotification([resetThemeMeta]);
  useErrorNotification([updateThemeMeta]);

  return (
    <>
      <Grid item sm={12}>
        <Box
          flex
          nowrap
          alignItems="center"
          style={{ cursor: "pointer", width: "fit-content" }}
          onClick={() => setOpenForm(!openForm)}
        >
          <Typography variant="h5" mr={16}>
            {t("Настройки стиля платежной страницы")}
          </Typography>
          <StyledCaretCircleUpIcon open={openForm} />
        </Box>
      </Grid>
      {openForm ? (
        <Grid item sm={12}>
          <Grid container hSpace={16}>
            <Grid item sm={12} xl={5.9}>
              <StyledInput
                bordercolor={accentColor}
                iserror={!!(form.touched.styles && form.errors.styles)}
              >
                <Grid container>
                  <Grid item sm={12}>
                    {!reload && (
                      <ReactQuill
                        theme="bubble"
                        id="styles"
                        placeholder={t("Введите текст")}
                        value={form.values.styles}
                        onChange={(value) =>
                          form.setFieldValue(
                            "styles",
                            value === "<p><br></p>" ? "" : value
                          )
                        }
                      />
                    )}
                    {!!(form.touched.styles && form.errors.styles) && (
                      <StyledHelperText iserror={!!form.errors.styles}>
                        {form.errors.styles}
                      </StyledHelperText>
                    )}
                  </Grid>
                </Grid>
              </StyledInput>
              <Typography mt={24} mb={12}>
                {t("Загрузка стиля файлом")}
              </Typography>
              <Dropzone
                onDrop={handleUploadFile}
                accept={{
                  "text/html": [".html", ".txt", ".css"],
                }}
                maxSize={5000000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <Box
                      p={16}
                      style={{
                        border: "1px dashed rgba(44, 96, 176, 0.4)",
                        background: "#FFFFFF",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      <Box
                        flex
                        alignItems="center"
                        justifyContent="center"
                        mb={12}
                      >
                        <BoxIcon background={pageBackground}>
                          <UploadIcon />
                        </BoxIcon>
                        <Typography variant="buttonSmall">
                          {t(
                            "Нажмите или перетащите файл для загрузки"
                          ).toLocaleUpperCase()}
                        </Typography>
                      </Box>
                      <Box flex justifyContent="center">
                        <Typography variant="subtitle3">
                          {t("Формат файла")}
                        </Typography>
                        <>&nbsp;</>
                        <Typography
                          variant="subtitle3"
                          style={{ color: "#838383" }}
                        >
                          txt, css, html
                        </Typography>
                      </Box>
                      <Box flex justifyContent="center">
                        <Typography variant="subtitle3">{t("Вес")}</Typography>
                        <>&nbsp;</>
                        <Typography
                          variant="subtitle3"
                          style={{ color: "#838383" }}
                        >
                          {`${t("не более")} 5mb`}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                )}
              </Dropzone>
              {loadingUpload ? (
                <Box my={10}>
                  <LinearProgress />
                </Box>
              ) : (
                <></>
              )}
              <Box
                flex
                justifyContent="space-between"
                mt={40}
                style={{ gap: 10 }}
                responsive={{ sm: { hidden: true }, lg: { hidden: false } }}
              >
                <Button
                  variant="outlined"
                  disabled={
                    getPointThemeMeta.isFetching ||
                    resetThemeMeta.isLoading ||
                    updateThemeMeta.isLoading ||
                    form.values.styles === getPointThemeMeta?.data?.styles
                  }
                  onClick={() => handleResetForm()}
                >
                  {t("Отменить")}
                </Button>
                <Button
                  variant="text"
                  iconStart={<ReloadIcon />}
                  px={12}
                  disabled={
                    getPointThemeMeta.isFetching ||
                    resetThemeMeta.isLoading ||
                    updateThemeMeta.isLoading
                  }
                  onClick={() => handleReset()}
                >
                  {t("Вернуть стиль по умолчанию")}
                </Button>
                <Button
                  disabled={
                    getPointThemeMeta.isFetching ||
                    resetThemeMeta.isLoading ||
                    updateThemeMeta.isLoading
                  }
                  variant="contained"
                  onClick={() => form.handleSubmit()}
                >
                  {t("Сохранить стиль")}
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              sm={12}
              xl={6}
              responsive={{ sm: { mt: 28 }, lg: { mt: 0 } }}
              style={{ background: "white" }}
            >
              <StyledFormContainer
                styles={
                  form.values.styles
                    ? form.values.styles
                        .replaceAll("<p>", "")
                        .replaceAll("</p>", "")
                    : defaultCss
                }
                dangerouslySetInnerHTML={{ __html: defaultHtml }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              mt={40}
              responsive={{ sm: { hidden: false }, lg: { hidden: true } }}
            >
              <Box flex justifyContent="space-between">
                <Button
                  variant="outlined"
                  disabled={
                    getPointThemeMeta.isFetching ||
                    resetThemeMeta.isLoading ||
                    updateThemeMeta.isLoading ||
                    form.values.styles === getPointThemeMeta?.data?.styles
                  }
                  onClick={() => handleResetForm()}
                >
                  {t("Отменить")}
                </Button>
                <Button
                  disabled={
                    getPointThemeMeta.isFetching ||
                    resetThemeMeta.isLoading ||
                    updateThemeMeta.isLoading
                  }
                  variant="contained"
                  onClick={() => form.handleSubmit()}
                >
                  {t("Сохранить стиль")}
                </Button>
              </Box>
              <Button
                variant="text"
                iconStart={<ReloadIcon />}
                fullwidth
                mt={12}
                disabled={
                  getPointThemeMeta.isFetching ||
                  resetThemeMeta.isLoading ||
                  updateThemeMeta.isLoading
                }
                onClick={() => handleReset()}
              >
                {t("Вернуть стиль по умолчанию")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

const StyledCaretCircleUpIcon = styled(CaretCircleUpIcon)`
  ${(props: { open: boolean }) =>
    !props.open ? "transform: rotate(180deg)" : ""};
`;

const StyledFormContainer = styled.div`
  max-width: calc(100vw - 48px);
  ${(props: { styles: string }) => props.styles};
`;

const StyledHelperText = styled.div`
  font-weight: 400;
  margin-top: 4px;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: { iserror: boolean }) =>
    props.iserror ? "#D00025" : "#727272"};
`;

const StyledInput = styled.div`
  .ql-editor {
    max-width: 750px;
    min-height: 400px;
    max-height: 400px;
    border: 1px solid
      ${(props: { iserror: boolean; bordercolor: string }) =>
        props.iserror ? "#D00025" : "#b9bfc8"};
    &:hover {
      border: 1px solid ${(props) => hexToRGB(props.bordercolor, 0.4)};
    }
    &:focus {
      border: 1px solid ${(props) => props.bordercolor} !important;
    }
    ::before {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .ql-tooltip {
    height: 0 !important;
    display: none;
  }
`;
