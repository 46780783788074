import styled from "styled-components";
import { getColors } from "utils/getColors";

interface SwitchProps {
  small?: boolean;
  value: boolean;
  onChange: (values: boolean) => void;
}

export const Switch = ({ small, value, onChange }: SwitchProps) => {
  const { accentColor } = getColors();

  return (
    <StyledWrapper
      checked={value}
      onClick={() => onChange(!value)}
      accentcolor={accentColor}
      small={!!small}
    >
      <StyledPoint small={!!small} checked={value} />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<{
  small: boolean;
  checked: boolean;
  accentcolor: string;
}>`
  width: ${(props) => (props.small ? "52px" : "60px")};
  height: ${(props) => (props.small ? "24px" : "32px")};
  border-radius: 40px;
  position: relative;
  cursor: pointer;
  background: ${(props) => (props.checked ? props.accentcolor : "#d0d0d0")};
`;

const StyledPoint = styled.div<{ small: boolean; checked: boolean }>`
  width: ${(props) => (props.small ? "18px" : "24px")};
  height: ${(props) => (props.small ? "18px" : "24px")};
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 50%;
  left: ${(props) => (props.checked ? "31px" : "4px")};
  transform: translate(0, -50%);
  transition: 0.15s;
`;
