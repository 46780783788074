import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { Modal } from "UI/Modal";
import { ReactComponent as LinkIcon } from "utils/img/link.svg";
import { ReactComponent as SquareCheckIcon } from "utils/img/square-check.svg";

type Props = {
  password: string;
  onClose: () => void;
};

export const ModalSuccessNewPassword = (props: Props): React.ReactElement => {
  const { password, onClose } = props;
  const { t } = useTranslation();
  const { pageBackground } = getColors();

  const handleCopy = () => {
    navigator.clipboard.writeText(password);
    toast.info(t("Пароль был сохранен в буфер обмена"));
  };

  return (
    <Modal open hideHeader width={370} height={200} onClose={onClose}>
      <Box p={27} style={{ textAlign: "center" }}>
        <Box
          flex
          alignItems="center"
          justifyContent="center"
          style={{
            height: 84,
            width: 84,
            background: "rgba(62, 169, 89, 0.1)",
            margin: "auto",
          }}
        >
          <SquareCheckIcon />
        </Box>
        <Typography variant="h4" mt={24}>
          {t("Установлен новый пароль")}
        </Typography>
        <Typography
          variant="subtitle2"
          mt={12}
          mb={12}
          style={{ color: "#838383" }}
        >
          {t("Рекомендуем изменить его после входа в кабинет")}
        </Typography>

        <Box
          flex
          alignItems="center"
          style={{ cursor: "pointer", background: pageBackground }}
          p={12}
          onClick={handleCopy}
        >
          <IconColor>
            <LinkIcon />
          </IconColor>
          <Typography>{password}</Typography>
        </Box>
        <Button mt={24} size="small" fullwidth onClick={onClose}>
          {t("Закрыть")}
        </Button>
      </Box>
    </Modal>
  );
}

const IconColor = styled.div`
  margin-right: 16px;
  svg {
    width: 16px !important;
    height: 16px !important;
    path {
      fill: #2c60b0 !important;
    }
  }
`;
