import React, { ChangeEvent } from "react";

interface IProps {
  handleFiles: (files: FileList | null) => void;
  children: React.ReactElement;
  fileTypes?: string | string[];
  multipleFiles?: boolean;
  disabled?: boolean;
  elementId?: string;
}

export const FileReader = (props: IProps): React.ReactElement => {
  const {
    handleFiles,
    children,
    fileTypes = "image/*",
    multipleFiles = false,
    disabled = false,
    elementId,
  } = props;

  let fileInput: HTMLInputElement | null = null;

  const setFileInput = (element: HTMLInputElement | null) => {
    fileInput = element;
  };

  const clickInput = () => {
    if (fileInput) {
      const element = fileInput;
      element.value = "";
      element.click();
    }
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    handleFiles(e.target.files);
  };

  const hideInput = {
    width: "0px",
    height: "0px",
    opacity: "0",
  };

  const wrapper = {
    display: "flex",
  };

  const optionalAttributes: { [key: string]: string } = {};

  if (elementId) {
    optionalAttributes.id = elementId;
  }

  return (
    <div className="react-file-reader" style={wrapper}>
      <input
        type="file"
        onChange={handleUpload}
        accept={Array.isArray(fileTypes) ? fileTypes.join(",") : fileTypes}
        className="react-file-reader-input"
        ref={setFileInput}
        multiple={multipleFiles}
        style={hideInput}
        disabled={disabled}
        {...optionalAttributes}
      />

      <div
        className="react-file-reader-button"
        style={{ width: "100%" }}
        onClick={clickInput}
      >
        {children}
      </div>
    </div>
  );
}
