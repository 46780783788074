import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps, useFormik } from "formik";
import { find, isEmpty, keys, map, omit, toPairs } from "lodash";
import styled from "styled-components";
import * as Yup from "yup";
import {
  useGetPointServiceAvailableMethodsQuery,
  useGetPointServicesQuery,
  usePaymentMethodNames,
} from "api/baseAPI/pointServices";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { Button } from "UI/Button";
import { Select } from "UI/Form/Select";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { InvoiceFormValues } from "pages/checkoutInvoice/invoices/InvoiceCreate";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { useSelectedPointManager } from "hooks/useSelectedEntity";

export const ServiceMapping = ({
  form,
}: {
  form: FormikProps<InvoiceFormValues>;
}) => {
  const { t } = useTranslation();
  const [addSetting, setAddSetting] = useState<boolean>(false);

  const selectedPointManager = useSelectedPointManager();

  const pointServices = useGetPointServicesQuery({
    page: "1",
    page_size: "100",
    search: "",
    point: selectedPointManager.point?.id,
  });

  const paymentMethods = useGetPointServiceAvailableMethodsQuery({
    page: "1",
    page_size: "100",
    search: "",
    point: `${selectedPointManager?.point_id || ""}`,
  });

  const paymentMethodNames = usePaymentMethodNames();

  const getServices = useMemo(() => {
    const { data } = pointServices;
    if (data && data?.results?.length) {
      const services = Array.from(
        new Set(
          data?.results?.map((item) => ({
            value: String(item.service?.id),
            label: `${item.service?.id} | ${item.service?.name}`,
          }))
        )
      );
      return services;
    }
    return [];
  }, [pointServices?.data]);

  const getPaymentMethods = useMemo(() => {
    const { data } = paymentMethods;
    if (data && data?.length) {
      const elements = Array.from(
        new Set(
          data?.map((slug) => ({
            value: slug,
            label: paymentMethodNames?.[slug] || slug,
          }))
        )
      );
      return elements;
    }
    return [];
  }, [paymentMethods]);

  const formConfig = useFormik<{
    method: string;
    service: string;
  }>({
    initialValues: {
      method: "",
      service: "",
    },
    onSubmit: (values) => {
      form.setFieldValue("service_mapping", {
        ...form.values.service_mapping,
        ...(values.method && values.service
          ? {
              [values.method]: Number(values.service),
            }
          : {}),
      });
      formConfig.resetForm();
      setAddSetting(false);
    },
    validationSchema: Yup.object().shape({
      method: Yup.string()
        .test(
          "uniq method",
          t("Данный метод уже был выбран"),
          (value) =>
            !(value && keys(form.values.service_mapping).includes(value))
        )
        .required(t("Обязательное поле")),
      service: Yup.string().required(t("Обязательное поле")),
    }),
  });

  return (
    <Grid item sm={12}>
      <Typography variant="h5">{t("Услуги")}</Typography>
      <Grid container mt={8} hSpace={24} vSpace={24} smHSpace={1}>
        <Grid item sm={12} lg={6}>
          <Table
            isLoading={false}
            tableCellTitles={[t("Платежный метод"), t("Услуга")]}
            count={keys(form.values.service_mapping)?.length || 0}
            page={1}
            rowsPerPage={1}
            disabledPagination
            setPage={() => {}}
            setRowsPerPage={() => {}}
          >
            {!isEmpty(form.values.service_mapping) ? (
              map(toPairs(form.values.service_mapping), ([slug, service]) => (
                <StyledRow key={slug}>
                  <StyledCell>{paymentMethodNames?.[slug] || slug}</StyledCell>
                  <StyledCell style={{ padding: "11px 8px" }}>
                    {`${(
                      find(getServices, { value: String(service) })?.label ||
                      String(service)
                    )?.substring(0, 40)}${
                      (find(getServices, { value: String(service) })?.label?.length ||
                        0) > 40
                        ? "..."
                        : ""
                    }`}
                  </StyledCell>
                  <StyledCell style={{ padding: "11px 8px" }}>
                    <StyledIconWrapper>
                      <DeleteIcon
                        onClick={() =>
                          form.setFieldValue(
                            "service_mapping",
                            omit(form.values.service_mapping, [slug])
                          )
                        }
                      />
                    </StyledIconWrapper>
                  </StyledCell>
                </StyledRow>
              ))
            ) : (
              <></>
            )}
          </Table>
        </Grid>
        <Grid item sm={12} lg={6}>
          {addSetting ? (
            <Grid container hSpace={12} vSpace={12} smHSpace={1}>
              <Grid item sm={12} lg={6}>
                <Select
                  value={formConfig.values.method}
                  onChange={(value) => {
                    formConfig.setFieldValue("method", value);
                  }}
                  label={t("Платежный метод")}
                  placeholder={t("Выберите")}
                  size="small"
                  options={getPaymentMethods}
                  error={
                    formConfig.touched.method &&
                    Boolean(formConfig.errors.method)
                  }
                  helperText={
                    formConfig.touched.method && formConfig.errors.method
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <Select
                  value={formConfig.values.service}
                  onChange={(value) => {
                    formConfig.setFieldValue("service", value);
                  }}
                  label={t("Услуга")}
                  placeholder={t("Выберите услугу")}
                  size="small"
                  options={getServices}
                  error={
                    formConfig.touched.service &&
                    Boolean(formConfig.errors.service)
                  }
                  helperText={
                    formConfig.touched.service && formConfig.errors.service
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    formConfig.resetForm();
                    setAddSetting(false);
                  }}
                  fullwidth
                >
                  {t("Отмена")}
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  fullwidth
                  variant="contained"
                  onClick={() => formConfig.handleSubmit()}
                >
                  {t("Сохранить")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container hSpace={24} vSpace={24} smHSpace={1}>
              <Grid item sm={6}>
                <Button
                  fullwidth
                  variant="outlined"
                  onClick={() => setAddSetting(true)}
                  disabled={addSetting}
                >
                  {t("Добавить услугу")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const StyledIconWrapper = styled.div`
  cursor: pointer;
`;
