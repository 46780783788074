import { DateTime } from "luxon";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getColors } from "utils/getColors";
import { RESPONSIVE_SIZES } from "utils/tools";
import { CalendarInput } from "./CalendarInput";

export const DateInputSelector = ({
  date,
  onChange,
  children,
  oldDate,
}: {
  date: DateTime | null;
  onChange: (date: DateTime | null) => void;
  children: React.ReactNode;
  oldDate: boolean;
}): React.ReactElement => {
  const { t } = useTranslation();
  const { accentColor } = getColors();

  const [showPopUp, setPopUp] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setPopUp(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  const [start, setStart] = useState<DateTime | null>(null);

  useEffect(() => {
    setStart(date || null);
  }, [date]);

  // Handlers
  const handleToglePopUp = (e: SyntheticEvent) => {
    e.stopPropagation();
    setPopUp(!showPopUp);
  };

  const handleSelectStart = (dateTime: DateTime) => {
    setStart(dateTime.startOf("day"));
  };

  const handleSelectTimeStart = (dateTime: DateTime) => {
    setStart(dateTime);
  };

  const doneHandler = () => {
    setPopUp(false);
    onChange(start);
  };

  return (
    <StyledRangeSelectorWrapper>
      {showPopUp && (
        <StyledRangeSelectorPopUp ref={ref}>
          <StyledMainWrapper>
            <StyledMain>
              <StyledCalendarsWrapper>
                <CalendarInput
                  date={start}
                  onDateSet={handleSelectStart}
                  onTimeSet={handleSelectTimeStart}
                  oldDate={oldDate}
                />
              </StyledCalendarsWrapper>
              <StyledActionPanel>
                <StyledActionPanelButton
                  background={accentColor}
                  onClick={doneHandler}
                >
                  {t("Применить")}
                </StyledActionPanelButton>
              </StyledActionPanel>
            </StyledMain>
          </StyledMainWrapper>
        </StyledRangeSelectorPopUp>
      )}
      <StyledChildWrapper onClick={handleToglePopUp}>
        {children}
      </StyledChildWrapper>
    </StyledRangeSelectorWrapper>
  );
};

const StyledRangeSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media (${RESPONSIVE_SIZES.sm}) {
    flex: 1;
  }
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (${RESPONSIVE_SIZES.sm}) {
    flex-direction: column;
  }
`;

const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCalendarsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (${RESPONSIVE_SIZES.sm}) {
    flex-direction: column;
  }
`;

const StyledRangeSelectorPopUp = styled.div`
  position: absolute;
  top: 51px;
  left: 50%;
  background: white;
  padding: 16px;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%);
  display: flex;
  z-index: 102;
  @media (${RESPONSIVE_SIZES.sm}) {
    max-width: 100%;
    min-width: 240px;
  }
`;

const StyledChildWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  justify-content: space-around;
`;

const StyledActionPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  @media (${RESPONSIVE_SIZES.sm}) {
    margin-top: -20px;
  }
`;

const StyledActionPanelButton = styled.button`
  background: ${({ background }: { background: string }) => background};
  margin-top: -30px;
  color: #ffffff;
  border: none;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
`;
