import { read, utils } from "xlsx";

export default (file: File): Promise<string[][]> =>
  new Promise((resolve, reject) => {
    if (file.size === 0) {
      reject("Invalid file type");
    }

    const fileType = /[.]/.exec(file.name)
      ? /[^.]+$/.exec(file.name)
      : undefined;

    const reader = new FileReader();

    if (fileType && fileType[0] === "csv") {
      reader.onload = (onloadEvent) => {
        const data = onloadEvent.target?.result;

        if (data === "") {
          return reject("Invalid file type");
        }

        const workbook = read(data, { type: "binary", raw: true });

        const sheetName = workbook.SheetNames[0];

        const worksheet = workbook.Sheets[sheetName];

        const dataArray = utils.sheet_to_json<string[]>(worksheet, {
          header: 1,
        });
        if (dataArray.length > 0) dataArray.shift();
        resolve(dataArray);
      };

      reader.onerror = (error) => reject(`error reading file: ${error}`);

      reader.readAsText(file);
    } else if (fileType && fileType[0] === "xlsx") {
      reader.onload = (onloadEvent) => {
        const data = onloadEvent.target?.result;

        if (data === "") {
          return reject("Invalid file type");
        }

        const workbook = read(data, { type: "binary" });

        const sheetName = workbook.SheetNames[0];

        const worksheet = workbook.Sheets[sheetName];

        const dataArray = utils.sheet_to_json<string[]>(worksheet, {
          header: 1,
        });
        if (dataArray.length > 0) dataArray.shift();

        resolve(dataArray);
      };

      reader.onerror = (error) => reject(`error reading file: ${error}`);

      reader.readAsBinaryString(file);
    } else {
      reject("Invalid file type");
    }
  });
