import { find, includes, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import {
  useGetCustomizerDepartmentsQuery,
  useLazyGetCustomizationRuleQuery,
  useUpdateCustomizationRuleMutation,
} from "api/baseAPI/customizer";
import { useGetPointsAvailablePaymentMethodsQuery } from "api/baseAPI/points";
import { Layout } from "components/layouts/Layout";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { CustomizationRuleForm } from "./CustomizationRuleForm";
import { useCustomizationRuleFormLogic } from "./CustomizationRuleFormLogic";
import { PayFormRulePreview } from "./PayFormRulePreview";

export const CustomizationRuleEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ruleID = "" } = useParams<{ ruleID: string }>();

  const breadCrumbs = [
    [t("Управление"), ""],
    [t("Настройки формы"), "/admin/customizer"],
    [t("Редактирование правила отображения"), ""],
  ];
  const selectedPointManager = useSelectedPointManager();

  const [updateRule, updateRuleMeta] = useUpdateCustomizationRuleMutation();
  const paymentMethodTypesQuery = useGetPointsAvailablePaymentMethodsQuery({
    point: selectedPointManager.point?.id,
  });
  const getCustomizerDepartments = useGetCustomizerDepartmentsQuery({});

  const { form } = useCustomizationRuleFormLogic({
    onSubmit: (values, setSubmitting) => {
      updateRule({
        id: Number(ruleID),
        name: values.name,
        light: values.light ? Number(values.light) : null,
        dark: values.dark ? Number(values.dark) : null,
        theme_switch: values.theme_switch,
        is_ap_available: values.is_ap_available,
        is_gp_available: values.is_gp_available,
        ap_gp_position: values.ap_gp_position,
        company_name: values.company_name,
        messenger: values.messenger,
        phone_number: values.phone_number,
        email: values.email,
        partners_logos: values.partners_logos,
        is_active: values.is_active,
        oferta_style: values.oferta_style,
        locales: values.locales,
        available_payment_methods:
          values.available_payment_methods?.map((el) => el.slug) || [],
        extra_params: {},
        is_card_alias_enabled: false,
        point_id: selectedPointManager.point?.id,
        department:
          find(
            getCustomizerDepartments.data?.results,
            (el) => el?.gate_id  ===  selectedPointManager?.point?.department
          )?.id || 0,
      })
        .unwrap()
        .then(() => {
          navigate("/admin/customizer");
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useErrorNotification([updateRuleMeta]);
  useSuccessNotification([updateRuleMeta]);

  useServerErrorsInForm(form.setFieldError, updateRuleMeta.error);

  const [getRule, getRuleMeta] = useLazyGetCustomizationRuleQuery();

  const [formFilled, setFormFilled] = useState<null | boolean>(null);

  useEffect(() => {
    getRule({ id: ruleID })
      .unwrap()
      .then((data) => {
        form.setValues({
          name: data.name,
          light: data.light?.id ? String(data.dark?.id) : "",
          dark: data.dark?.id ? String(data.dark?.id) : "",
          theme_switch: data.theme_switch,
          is_gp_available: data.is_gp_available,
          is_ap_available: data.is_ap_available,
          ap_gp_position: data.ap_gp_position,
          company_name: data.company_name,
          messenger: data.messenger,
          phone_number: data.phone_number,
          email: data.email,
          partners_logos: data.partners_logos,
          is_active: data.is_active,
          oferta_style: data.oferta_style,
          available_payment_methods: [],
          payment_slug: null,
          locales: data.locales,
        });
        setFormFilled(true);
      });
  }, [ruleID]);

  useEffect(() => {
    if (
      paymentMethodTypesQuery.isSuccess &&
      paymentMethodTypesQuery.data?.length > 0 &&
      getRuleMeta.isSuccess &&
      !isEmpty(getRuleMeta.data?.available_payment_methods)
    ) {
      form.setFieldValue(
        "available_payment_methods",
        paymentMethodTypesQuery.data?.filter((el) =>
          includes(getRuleMeta.data?.available_payment_methods, el.slug)
        )
      );
    }
  }, [paymentMethodTypesQuery, getRuleMeta]);

  useEffect(() => {
    if (
      paymentMethodTypesQuery.isSuccess &&
      paymentMethodTypesQuery.data?.length > 0 &&
      formFilled
    ) {
      const findCardMethod = paymentMethodTypesQuery.data.find(
        (el) => el.slug  ===  "card_debit_hpp"
      );
      form.setFieldValue(
        "payment_slug",
        findCardMethod?.id
          ? { id: findCardMethod.id, slug: findCardMethod.slug }
          : null
      );
    }
  }, [paymentMethodTypesQuery, formFilled]);

  return (
    <Layout
      title={t("Редактирование правила отображения")}
      breadCrumbs={breadCrumbs}
    >
      <Box
        px={24}
        py={32}
        flex
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "calc(100vh - 124px)" }}
      >
        <Grid
          container
          hSpace={24}
          vSpace={24}
          style={{ position: "relative" }}
        >
          <Grid item sm={12} lg={3}>
            <CustomizationRuleForm
              submitButtonText={t("Сохранить")}
              form={form}
              ruleID={ruleID}
              onCancel={() => navigate("/admin/customizer")}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={9}
            responsive={{ md: { mb: 0 }, sm: { mb: 80 } }}
          >
            <PayFormRulePreview form={form} formFilled={formFilled} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
