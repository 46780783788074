/* eslint-disable max-classes-per-file */
export interface IPayoutCategory {
  name: string;
  slug: string;
}

export class Fiat implements IPayoutCategory {
  name: string = "Фиат";

  slug: string = "fiat";
}

export class Crypto implements IPayoutCategory {
  name: string = "Крипто";

  slug: string = "crypto";
}

export class UPI implements IPayoutCategory {
  name: string = "UPI";

  slug: string = "upi";
}
