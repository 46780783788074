import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import styled from "styled-components";
import { Invoice } from "api/baseAPI/checkoutInvoiceInvoices";
import { Box } from "UI/Box";
import { Typography } from "UI/Typography";
import { visibleNumber } from "utils/numbers";
import { getCurrencyIcon } from "utils/useStyle";

const COLORS = ["#303F9F", "#E31B0C", "rgba(0, 0, 0, 0.14)"];

export const InvoiceStats = ({ invoice }: { invoice: Invoice }) => {
  const { t } = useTranslation();

  const getOptions = useMemo(() => {
    if (!isEmpty(invoice)) {
      return [
        { name: t("Успешные"), count: invoice?.success_count || 0 },
        { name: t("Неуспешная"), count: invoice?.fail_count || 0 },
        ...(!invoice?.success_count && !invoice?.fail_count
          ? [{ name: "", count: 1 }]
          : []),
      ];
    }
    return [];
  }, [invoice]);

  const getPercent = useMemo(() => {
    if (!isEmpty(invoice)) {
      const amount = invoice
        ? Number(invoice.success_count || 0) + Number(invoice.fail_count || 0)
        : 0;
      return [
        (amount
          ? (Number(invoice?.success_count || 0) / amount) * 100
          : 0
        ).toFixed(1),
        (amount
          ? (Number(invoice?.fail_count || 0) / amount) * 100
          : 0
        ).toFixed(1),
      ];
    }
    return [];
  }, [invoice]);

  return (
    <Box
      p={16}
      style={{
        border: "1px solid #c8cfdc",
        height: "auto",
      }}
    >
      <Typography mb={12} variant="h6">
        {t("Статистика транзакций")}
      </Typography>
      <Typography pb={6} variant="subtitle3">
        {t("Общая сумма успешных платежей")}
      </Typography>
      <Typography variant="inputText" style={{ display: "flex" }}>
        <>
          {getCurrencyIcon(invoice.amount_currency, "0")}
          {visibleNumber(invoice?.success_amount || 0)}
        </>
      </Typography>
      <Box flex alignItems="center" style={{ flexWrap: "wrap" }}>
        <Box mr={30} my={10} style={{ width: 210 }}>
          <Box flex justifyContent="space-between" mb={16}>
            <Box flex>
              <StyledPoint color="#399818" />
              <Typography variant="subtitle3">{t("Успешные")}</Typography>
            </Box>
            <Typography variant="subtitle3">{`${
              getPercent[0] || 0
            }%`}</Typography>
          </Box>
          <Box flex justifyContent="space-between">
            <Box flex>
              <StyledPoint color="#DC0C0C" />
              <Typography variant="subtitle3">{t("Неуспешные")}</Typography>
            </Box>
            <Typography variant="subtitle3">{`${
              getPercent[1] || 0
            }%`}</Typography>
          </Box>
        </Box>
        <PieChart width={145} height={145}>
          <Pie
            data={getOptions}
            innerRadius={45}
            outerRadius={68}
            dataKey="count"
            nameKey="name"
          >
            {getOptions?.map((entry, i) => (
              <Cell key={`cell-${i}`} fill={COLORS[i]} />
            ))}
          </Pie>
          {getOptions.length < 4 && <Tooltip />}
        </PieChart>
      </Box>
    </Box>
  );
};
const StyledPoint = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${(props) => props.color};
`;
