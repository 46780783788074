import { useFormik } from "formik";
import { find, includes, isEmpty, sum } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useCreatePageMutation } from "api/baseAPI/invoicing";
import {
  ProductItem,
  useCreateInvoicingProductMutation,
  useCreateSocInvoiceDataMutation,
} from "api/baseAPI/socInvoice";
import { Layout } from "components/layouts/Layout";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { ReactComponent as CaretCircleUpIcon } from "utils/img/caret-circle-up.svg";
import { ReactComponent as PlusOutlinedIcon } from "utils/img/plus-outlined.svg";
import { useErrorNotification } from "utils/notificationWrappers";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { ProductForm } from "./parts/ProductForm";
import { ProductsTable } from "./parts/ProductsTable";

export type SocInvoiceDataFormValues = {
  sender_full_name: string;
  sender_phone_number: string;
  sender_email: string;
  receiver_full_name: string;
  receiver_phone_number: string;
  receiver_email: string;

  title: string;
  wallet: string;
  amount_currency: string;

  products: ProductItem[];
};

export const initData: SocInvoiceDataFormValues = {
  sender_full_name: "",
  sender_phone_number: "",
  sender_email: "",
  receiver_full_name: "",
  receiver_phone_number: "",
  receiver_email: "",

  title: "",
  wallet: "",
  amount_currency: "",

  products: [],
};

export const SocInvoiceDataCreate = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные сервисы"), ""],
    [t("Выставление счета"), "/services/soc-invoice-data"],
    [t("Создание платежа"), ""],
  ];

  const navigate = useNavigate();

  const [openModalProduct, setOpenModalProduct] = useState(false);
  const [openReceiverInfo, setOpenReceiverInfo] = useState(true);
  const [openSenderInfo, setOpenSenderInfo] = useState(true);
  const [openProducts, setOpenProducts] = useState(true);
  const [currency, setCurrency] = useState("");

  const selectedPointManager = useSelectedPointManager();

  const [createPage, createPageMeta] = useCreatePageMutation();
  const [createProduct, createProductMeta] =
    useCreateInvoicingProductMutation();
  const [createSocInvoiceData, { error, isLoading }] =
    useCreateSocInvoiceDataMutation();

  const handleErrors = (dataErrors: string[]) => {
    if (
      includes(dataErrors, "Point does not have required service connected")
    ) {
      toast.error(t("К точке не подключена требуемая услуга"));
      return;
    }
    if (
      includes(dataErrors, "point and wallet have different account owners")
    ) {
      toast.error(t("У точки и кошелька разные владельцы счетов"));
      return;
    }
    if (includes(dataErrors?.[0], "has different currency from page")) {
      toast.error(t("Валюта кошелька отличается от валюты страницы"));
      return;
    }
    toast.error(t("Ошибка"));
  };

  useEffect(() => {
    if (error && "data" in error && "status" in error && error.status  ===  400) {
      const dataErrors = error.data as string[];
      handleErrors(dataErrors);
    }
  }, [error]);

  const form = useFormik<SocInvoiceDataFormValues>({
    initialValues: initData,
    onSubmit: (values, { setSubmitting }) => {
      createPage({
        title: values.title,
        amount_currency: values.amount_currency,
        locale: "ua",
        available_locales: ["ua", "en", "ru"],
        fixed_amount: String(
          sum(
            values.products.map(
              (product) => Number(product.cost) * Number(product?.quantity)
            )
          ).toFixed(2)
        ),
        active_till: null,
        service: String(
          selectedPointManager.service_config?.invoicing_service[
            values.amount_currency
          ]
        ),
        bp_service: null,
        min_amount: null,
        wallet: values.wallet,
        point: String(selectedPointManager.point?.id),
        is_reusable: false,
        additional_fields: [],
        preferred_amounts: [],
        available_payment_methods: [],
        prefilled_fields: {},
        customization: null,
        status: 1,
        description_template: "",
        preferred_amounts_logic: 1,
        kind: 2,
      })
        .unwrap()
        .then((page) => {
          const promises = [];
          if (values.products?.length > 0) {
            promises.push(
              values.products.map((item) => createProduct({
                  ...item,
                  page: page.id,
                }).unwrap())
            );
          }
          promises.push(
            createSocInvoiceData({
              page: page.id,
              sender_full_name: values.sender_full_name,
              sender_phone_number: values.sender_phone_number,
              sender_email: values.sender_email,
              receiver_full_name: values.receiver_full_name,
              receiver_phone_number: values.receiver_phone_number,
              receiver_email: values.receiver_email,
            })
              .unwrap()
              .then()
          );

          Promise.all(promises).finally(() => {
            setSubmitting(false);
            navigate("/services/soc-invoice-data");
          });
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(t("Обязательное поле")),
      sender_full_name: Yup.string().required(t("Обязательное поле")),
      sender_phone_number: Yup.string().required(t("Обязательное поле")),
      sender_email: Yup.string()
        .email(t("Неправильный формат"))
        .required(t("Обязательное поле")),
      receiver_full_name: Yup.string().required(t("Обязательное поле")),
      receiver_phone_number: Yup.string().required(t("Обязательное поле")),
      receiver_email: Yup.string()
        .email(t("Неправильный формат"))
        .required(t("Обязательное поле")),

      wallet: Yup.string()
        .test(
          "isValid",
          t("Для валюты {{currency}} инвойсинг не настроен", {
            currency,
          }),
          (value: string | undefined): boolean => (
              !value ||
              !currency ||
              !!selectedPointManager.service_config?.invoicing_service?.[
                currency
              ]
            )
        )
        .required(t("Обязательное поле")),
    }),
  });

  useTranslateFormErrors(form);

  useEffect(() => {
    if (!isEmpty(form.touched) && !isEmpty(form.errors)) {
      setOpenSenderInfo(true);
      setOpenReceiverInfo(true);
    }
  }, [form.touched, form.errors]);

  const handleCloseForm = () => {
    navigate("/services/soc-invoice-data");
  };

  useErrorNotification([{ isError: createPageMeta.isError }]);
  useServerErrorsInForm(form.setFieldError, error);
  useServerErrorsInForm(form.setFieldError, createPageMeta.error);

  return (
    <Layout title={t("Создание платежа")} breadCrumbs={breadCrumbs}>
      <Box
        px={24}
        py={32}
        flex
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "calc(100vh - 124px)" }}
      >
        <Box>
          <Grid container pb={24}>
            <Grid item sm={12} md={4.8}>
              <TextInput
                size="small"
                label={t("Назначение")}
                placeholder={t("Введите значение")}
                onChange={(value) =>
                  form.setFieldValue("title", value.slice(0, 100))
                }
                value={form.values.title}
                error={form.touched.title && Boolean(form.errors.title)}
                helperText={form.touched.title && form.errors.title}
              />
            </Grid>
          </Grid>
          <>
            <Box
              flex
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenSenderInfo(!openSenderInfo)}
            >
              <Typography variant="h5" mr={16}>
                {t("Информация отправителя")}
              </Typography>
              <StyledCaretCircleUpIcon open={openSenderInfo} />
            </Box>
            {openSenderInfo ? (
              <Grid container pt={24} hSpace={24} vSpace={24} smHSpace={1}>
                <Grid item sm={12} md={5}>
                  <TextInput
                    size="small"
                    label={t("ФИО / название компании")}
                    placeholder={t("Введите название")}
                    onChange={(value) =>
                      form.setFieldValue(
                        "sender_full_name",
                        value.slice(0, 254)
                      )
                    }
                    value={form.values.sender_full_name}
                    error={
                      form.touched.sender_full_name &&
                      Boolean(form.errors.sender_full_name)
                    }
                    helperText={
                      form.touched.sender_full_name &&
                      form.errors.sender_full_name
                    }
                  />
                </Grid>
                <Grid item sm={12} md={3.5}>
                  <TextInput
                    size="small"
                    label="Email"
                    placeholder={t("Введите email")}
                    onChange={(value) =>
                      form.setFieldValue("sender_email", value.slice(0, 254))
                    }
                    value={form.values.sender_email}
                    error={
                      form.touched.sender_email &&
                      Boolean(form.errors.sender_email)
                    }
                    helperText={
                      form.touched.sender_email && form.errors.sender_email
                    }
                  />
                </Grid>
                <Grid item sm={12} md={3.5}>
                  <TextInput
                    size="small"
                    label={t("Телефон")}
                    placeholder={t("Введите телефон")}
                    onChange={(value) =>
                      form.setFieldValue(
                        "sender_phone_number",
                        value.replace(/[^0-9+]/g, "").slice(0, 16)
                      )
                    }
                    value={form.values.sender_phone_number}
                    error={
                      form.touched.sender_phone_number &&
                      Boolean(form.errors.sender_phone_number)
                    }
                    helperText={
                      form.touched.sender_phone_number &&
                      form.errors.sender_phone_number
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
            <Box
              flex
              alignItems="center"
              mt={32}
              style={{ cursor: "pointer" }}
              onClick={() => setOpenReceiverInfo(!openReceiverInfo)}
            >
              <Typography variant="h5" mr={16}>
                {t("Информация получателя")}
              </Typography>
              <StyledCaretCircleUpIcon open={openReceiverInfo} />
            </Box>
            {openReceiverInfo ? (
              <Grid container pt={24} hSpace={24} vSpace={24} smHSpace={1}>
                <Grid item sm={12} md={4.2}>
                  <TextInput
                    size="small"
                    label={t("ФИО / название компании")}
                    placeholder={t("Введите название")}
                    onChange={(value) =>
                      form.setFieldValue(
                        "receiver_full_name",
                        value.slice(0, 254)
                      )
                    }
                    value={form.values.receiver_full_name}
                    error={
                      form.touched.receiver_full_name &&
                      Boolean(form.errors.receiver_full_name)
                    }
                    helperText={
                      form.touched.receiver_full_name &&
                      form.errors.receiver_full_name
                    }
                  />
                </Grid>
                <Grid item sm={12} md={2.6}>
                  <Select
                    value={form.values.wallet}
                    onChange={(value) => {
                      form.setFieldValue("wallet", value);
                      const findWallet = find(
                        selectedPointManager?.available_wallets,
                        {
                          id: Number(value),
                        }
                      );
                      form.setFieldValue(
                        "amount_currency",
                        findWallet?.currency || "UAH"
                      );
                      setCurrency(findWallet?.currency || "UAH");
                    }}
                    label={t("Кошелек получателя")}
                    placeholder={t("Выберите кошелек")}
                    size="small"
                    options={
                      selectedPointManager?.available_wallets?.length > 0
                        ? selectedPointManager?.available_wallets?.map(
                            (item) => ({
                              value: `${item.id}`,
                              label: `${item.id} | ${item.name}`,
                            })
                          )
                        : []
                    }
                    error={form.touched.wallet && Boolean(form.errors.wallet)}
                    helperText={form.touched.wallet && form.errors.wallet}
                  />
                </Grid>
                <Grid item sm={12} md={2.6}>
                  <TextInput
                    size="small"
                    label="Email"
                    placeholder={t("Введите email")}
                    onChange={(value) =>
                      form.setFieldValue("receiver_email", value.slice(0, 254))
                    }
                    value={form.values.receiver_email}
                    error={
                      form.touched.receiver_email &&
                      Boolean(form.errors.receiver_email)
                    }
                    helperText={
                      form.touched.receiver_email && form.errors.receiver_email
                    }
                  />
                </Grid>
                <Grid item sm={12} md={2.6}>
                  <TextInput
                    size="small"
                    label={t("Телефон")}
                    placeholder={t("Введите телефон")}
                    onChange={(value) =>
                      form.setFieldValue(
                        "receiver_phone_number",
                        value.replace(/[^0-9+]/g, "").slice(0, 16)
                      )
                    }
                    value={form.values.receiver_phone_number}
                    error={
                      form.touched.receiver_phone_number &&
                      Boolean(form.errors.receiver_phone_number)
                    }
                    helperText={
                      form.touched.receiver_phone_number &&
                      form.errors.receiver_phone_number
                    }
                  />
                </Grid>
              </Grid>
            ) : null}

            <Box
              flex
              alignItems="center"
              mt={32}
              style={{ cursor: "pointer" }}
              onClick={() => setOpenProducts(!openProducts)}
            >
              <Typography variant="h5" mr={16}>
                {t("Добавление товара")}
              </Typography>
              <StyledCaretCircleUpIcon open={openProducts} />
            </Box>
            {openProducts ? (
              <>
                <Box pb={16} pt={24}>
                  <ProductsTable isForm products={form.values.products} />
                </Box>
                <Button
                  fullwidth
                  variant="outlined"
                  size="small"
                  disabled={!form.values.wallet}
                  iconStart={<PlusOutlinedIcon />}
                  onClick={() => setOpenModalProduct(true)}
                >
                  {t("Добавить товар")}
                </Button>
              </>
            ) : null}
            {openModalProduct ? (
              <ProductForm
                formSocInvoiceData={form}
                onClose={() => setOpenModalProduct(false)}
              />
            ) : null}
          </>
        </Box>
        <Box flex mt={20}>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            variant="outlined"
            size="small"
            disabled={
              isLoading ||
              createPageMeta.isLoading ||
              createProductMeta.isLoading ||
              form.isSubmitting
            }
            onClick={() => handleCloseForm()}
          >
            {t("Назад")}
          </Button>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            ml={24}
            variant="contained"
            size="small"
            disabled={
              isLoading ||
              createPageMeta.isLoading ||
              createProductMeta.isLoading ||
              form.isSubmitting
            }
            onClick={form.handleSubmit}
          >
            {t("Создать")}
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}

const StyledCaretCircleUpIcon = styled(CaretCircleUpIcon)`
  ${(props: { open: boolean }) =>
    !props.open ? "transform: rotate(180deg)" : ""};
`;
