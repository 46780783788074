import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { PagesQueryParams } from "api/baseAPI/invoicing";
import { useLazyGetPointServicesQuery } from "api/baseAPI/pointServices";
import { TableFilter } from "components/table/TableFilter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useFilterMakerResult } from "utils/filterMaker";
import { handleChangeNumber, numberForInput } from "utils/numbers";

export const PagesFilters = ({
  error,
  filters,
}: {
  filters: useFilterMakerResult<PagesQueryParams>;
  error: FetchBaseQueryError | SerializedError | undefined;
}) => {
  const { t } = useTranslation();
  const { values, setValue, errors } = filters;
  const [errorState, setError] = useState<Record<string, string>>({});

  useEffect(() => {
    if (error && "data" in error) {
      const data = error.data as Record<string, string>;
      setError(data);
    } else {
      setError({});
    }
  }, [error]);

  const selectedPointManager = useSelectedPointManager();

  const [pointServices, pointServicesMeta] = useLazyGetPointServicesQuery();

  useEffect(() => {
    if (selectedPointManager.point?.id) {
      pointServices({
        page: "1",
        page_size: "100",
        search: "",
        point: selectedPointManager.point?.id,
      });
    }
  }, [selectedPointManager.point?.id]);

  const getServices = useMemo(() => {
    const { data } = pointServicesMeta;
    if (data?.results?.length) {
      const services = Array.from(
        new Set(
          data?.results?.map((item) => ({
            value: String(item.service?.id),
            label: `${item.service?.id} | ${item.service?.name}`,
          }))
        )
      );
      return services;
    }
    return [];
  }, [pointServicesMeta?.data]);

  return (
    <TableFilter
      onSubmit={filters.handleSubmit}
      onReset={filters.handleReset}
      disabledReset={filters.disabledReset}
      disableSubmit={!!errors}
      widthBox="60%"
    >
      <Grid container mt={12} hSpace={24} vSpace={24} smHSpace={8}>
        <Grid item sm={12} md={6}>
          <TextInput
            size="small"
            label={t("UIID ссылки")}
            placeholder={t("Предварительно заполнить")}
            value={values.uuid__icontains}
            onChange={(value) => setValue("uuid__icontains", value)}
            error={Boolean(errorState?.uuid__icontains)}
            helperText={errorState?.uuid__icontains}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput
            size="small"
            label={t("Название")}
            placeholder={t("Предварительно заполнить")}
            value={values.title__icontains}
            onChange={(value) => setValue("title__icontains", value)}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Select
            value={values.wallet}
            onChange={(value) => setValue("wallet", value)}
            label={t("Кошелек")}
            placeholder={t("Выберите")}
            size="small"
            options={
              selectedPointManager?.available_wallets?.length > 0
                ? selectedPointManager?.available_wallets?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.id} | ${item.name}`,
                  }))
                : []
            }
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Select
            value={values.service}
            onChange={(value) => setValue("service", value)}
            label={t("Услуга")}
            placeholder={t("Выберите")}
            size="small"
            options={getServices}
          />
        </Grid>
        <Grid item sm={6}>
          <TextInput
            size="small"
            label={t("Сумма от")}
            placeholder={t("Предварительно заполнить")}
            value={numberForInput(values.success_amount__gte)}
            onChange={(value) =>
              setValue(
                "success_amount__gte",
                handleChangeNumber(value.replace(/[^0-9.]/g, ""))
              )
            }
          />
        </Grid>
        <Grid item sm={6}>
          <TextInput
            size="small"
            label={t("Сумма до")}
            placeholder={t("Предварительно заполнить")}
            value={numberForInput(values.success_amount__lte)}
            onChange={(value) =>
              setValue(
                "success_amount__lte",
                handleChangeNumber(value.replace(/[^0-9.]/g, ""))
              )
            }
          />
        </Grid>
      </Grid>
    </TableFilter>
  );
}
