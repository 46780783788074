import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { ReactComponent as Apple } from "pages/services/tip-users/assets/ApplePay.svg";
import { ReactComponent as Google } from "pages/services/tip-users/assets/GPay.svg";

import { ReactComponent as InfoIcon } from "pages/services/tip-users/assets/info.svg";
import { ReactComponent as MasterCardIcon } from "pages/services/tip-users/assets/mastercard.svg";
import { ReactComponent as VisaIcon } from "pages/services/tip-users/assets/visa.svg";

export const FakePayForm = ({
  name,
  company_name,
  position,
  suggestedAmounts,
  avatar,
  onClose,
}: {
  name: string;
  company_name: string;
  position: string;
  suggestedAmounts: string[];
  avatar: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box p={25}>
      <Typography variant="h4" mb={10}>
        {t("Предварительный просмотр")}
      </Typography>
      <Grid
        container
        style={{ backgroundColor: "#F6F7F8", borderRadius: "30px" }}
        p={15}
        vSpace={15}
      >
        <Grid item md={12}>
          <Grid container>
            <Grid item>
              <StyledAvatarPreview src={avatar} alt="" />
            </Grid>
            <Grid item pl={15}>
              <StyledPosition>{position}</StyledPosition>
              <StyledName>{name}</StyledName>
              <StyledCompanyName>{company_name}</StyledCompanyName>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <StyledAmountInput value="200" readOnly />
        </Grid>
        <Grid item md={12}>
          <StyledSuggestedAmounts>
            <StyledButton bg="transparent" color="black">
              {suggestedAmounts[0]}
            </StyledButton>
            <StyledButton bg="black" color="white">
              {suggestedAmounts[1]}
            </StyledButton>
            <StyledButton bg="transparent" color="black">
              {suggestedAmounts[2]}
            </StyledButton>
          </StyledSuggestedAmounts>
        </Grid>
        <Grid item md={12}>
          <StyledPayServicesButtons>
            <Google />
            <Apple />
          </StyledPayServicesButtons>
        </Grid>
        <Grid item md={12}>
          <Box p={10} style={{ backgroundColor: "white", borderRadius: "5px" }}>
            <Grid container vSpace={15} hSpace={15}>
              <Grid item md={12}>
                <Typography variant="body">{t("Оплата картой")}</Typography>
              </Grid>
              <Grid item md={12}>
                <StyledInput value={t("Введите номер карты")} readOnly />
              </Grid>
              <Grid item md={8}>
                <StyledInput value={t("Срок действия")} readOnly />
              </Grid>
              <Grid item md={4}>
                <StyledInput value="CVV" readOnly />
                <StyledInfoIconWrapper>
                  <InfoIcon />
                </StyledInfoIconWrapper>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={12}>
          <StyledButton bg="black" color="white">
            {t("ОПЛАТИТЬ")}
          </StyledButton>
        </Grid>
        <Grid item md={12} alignItems="center">
          <StyledPayServicesLogo>
            <MasterCardIcon height="25px" width="auto" />{" "}
            <VisaIcon height="20px" width="auto" />
          </StyledPayServicesLogo>
        </Grid>
      </Grid>

      <Button variant="outlined" fullwidth mt={20} onClick={() => onClose()}>
        {t("Назад")}
      </Button>
    </Box>
  );
}
const StyledInfoIconWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
`;
const StyledButton = styled.div<{ bg: string; color: string }>`
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  padding: 15px;
  border-radius: 5px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  border: 1px solid #d9d9d9;
`;
const StyledInput = styled.input`
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
`;
const StyledAmountInput = styled.input`
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  font-size: 30px;
  background-color: transparent;
`;
const StyledAvatarPreview = styled.img`
  width: 100px;
  min-height: 50px;
  border-radius: 20px;
`;
const StyledSuggestedAmounts = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 0;
  justify-content: space-between;
`;
const StyledPayServicesButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
const StyledPayServicesLogo = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 60%;
  margin: 0 auto;
  align-items: center;
`;
const StyledPosition = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
`;
const StyledName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  margin-bottom: 10px;
`;
const StyledCompanyName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
`;
