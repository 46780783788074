export const dateFormatter = {
  format: (dateString: string | null): string => {
    if (dateString === null) {
      return "";
    }

    const dateTimeFormat = new Intl.DateTimeFormat("uk-UA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });

    const date = new Date(dateString);

    return dateTimeFormat.format(date);
  },
};
