import { baseSplitApi } from "./index";

type CustomizationRuleQueryParams = {
  page: string;
  page_size: string;
  search: string;
  point_id: number;
};

export type BaseCustomizationRule = {
  name: string;
  department: number | null;
  point_id: number | null;
  theme_switch: boolean;
  ap_gp_position: 1 | 2 | 3;
  is_card_alias_enabled: boolean;
  company_name: string;
  messenger: string;
  phone_number: string;
  email: string;
  partners_logos: string[];
  is_active: boolean;
  oferta_style: 0 | 1 | 2;
  is_ap_available: boolean;
  is_gp_available: boolean;
  available_payment_methods: string[];
  extra_params: any;
  locales: string[];
};

export type CreateCustomizationRule = BaseCustomizationRule & {
  light: number | null;
  dark: number | null;
};

export type CustomizationRule = BaseCustomizationRule & {
  light: { id: number; name: string } | null;
  dark: { id: number; name: string } | null;
  department: { id: number; name: string } | null;
};

type ThemeQueryParams = {
  page: string;
  page_size: string;
  search?: string;
  point_id: number;
};

export type CreateTheme = {
  name: string;
  point_id: number | null;
  logo?: string | File | undefined | null;
  background_color: string;
  panel_background_color_first: string;
  panel_background_color_second: string;
  accent_color: string;
  primary_text_font: string;
  primary_text_color: string;
  secondary_text_color: string;
  button_text_color: string;
  ap_gp_style: 1 | 2;
  inputs_style: 1 | 2;
  inputs_background: string;
  border_radius: number;
  custom_styles?: string | File | undefined | null;
  delete_logo: boolean;
};
export type Theme = {
  accent_color: string;
  background_color: string;
  logo?: string | null;
  border_radius: number;
  panel_background_color_first: string;
  panel_background_color_second: string;
  inputs_background: string;
  primary_text_font: string;
  primary_text_color: string;
  secondary_text_color: string;
  button_text_color: string;
  custom_styles?: string;
  ap_gp_style: number;
  inputs_style: number;
};

export type Customization = {
  theme_switch: boolean;
  company_name?: string | null;
  messenger?: string | null;
  email?: string | null;
  phone_number?: string | null;
  ap_gp_position: number;
  partners_logos: string[];
  is_card_alias_enabled: boolean;
  is_ap_available: boolean;
  is_gp_available: boolean;
  oferta_style: number;
  oferta_url: string;
  form_fields: [];
  light?: Theme | null;
  dark?: Theme | null;
  locales?: string[];
};

export type CustomizerCreatePreviewQuery = {
  transaction_uuid: string;
  data: {
    transaction: {
      amount?: number;
      status: number;
      id: number;
      service_type: number;
      currency: string;
      initial_amount: number;
      initial_currency: string;
      is_fc: boolean;
      is_retry: boolean;
      failure_reason_code: null;
      locale: string;
      description: string;
      api_forms_slug: string;
      payment_slug: string;
    };
    customization?: Customization;
    card_alias: [];
  };
};

export type CustomizerCreatePreviewResponse = {
  id: number;
  uuid: string;
  status: number;
  service_type: number;
  amount: number;
  currency: string;
  initial_amount: number;
  initial_currency: string;
  is_fc: boolean;
  is_retry: boolean;
  failure_reason_code: null | number;
  locale: string;
  description: string;
  payment: {
    apple: {
      merchantId: string;
      domain: string;
      displayName: string;
    };
    google: {
      merchantId: string;
      gateway: string;
      gatewayMerchantId: string;
      merchantName: string;
    };
    card: {
      card_number: string;
      expire_year: string;
      expire_month: string;
    };
  };
  api_forms_slug: string;
  point: {
    success_url: null;
    fail_url: null;
    cancel_url: null;
    return_url: null;
    verification_url: null;
  };
  finmon: {
    required_fields: string[];
    optional_fields: string[];
  };
  customization: {
    theme_switch: boolean;
    company_name: string;
    messenger: string;
    email: string;
    phone_number: string;
    ap_gp_position: number;
    partners_logos: string[];
    is_card_alias_enabled: boolean;
    is_ap_available: boolean;
    is_gp_available: boolean;
    oferta_style: number;
    oferta_url: null | string;
    light: {
      accent_color: string;
      background_color: string;
      logo: string;
      border_radius: number;
      panel_background_color_first: string;
      panel_background_color_second: string;
      inputs_background: string;
      primary_text_font: string;
      primary_text_color: string;
      secondary_text_color: string;
      button_text_color: string;
      custom_styles: null | string;
      ap_gp_style: number;
      inputs_style: number;
    };
    dark: {
      accent_color: string;
      background_color: string;
      logo: string;
      border_radius: number;
      panel_background_color_first: string;
      panel_background_color_second: string;
      inputs_background: string;
      primary_text_font: string;
      primary_text_color: string;
      secondary_text_color: string;
      button_text_color: string;
      custom_styles: null | string;
      ap_gp_style: number;
      inputs_style: number;
    };
    form_fields: [
      {
        goes_to: number;
        type: number;
        name: string;
        gate_key: string;
        regex: null | string;
        placeholder: null | string;
        required: boolean;
        is_active: boolean;
        translations: {
          name_en: string;
          name_ru: string;
          name_uk: string;
        };
      }
    ];
  };
  card_alias: [
    {
      card_mask: string;
      expire_year: string;
      expire_month: string;
      card_alias: string;
    }
  ];
};

export const customizerApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCustomizationRules: builder.query<
      {
        count: number;
        results: {
          id: number;
          name: string;
          point_id: number | null;
          mirror_id: null | number;
          light: { id: number; name: string } | null;
          dark: { id: number; name: string } | null;
          updated_at: string;
          is_active: boolean;
        }[];
      },
      CustomizationRuleQueryParams
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/customizer/customization-rules/?${query.toString()}`;
      },
      providesTags: ["CustomizationRules"],
    }),
    getCustomizationRule: builder.query<
      CustomizationRule & { id: number },
      { id: number | string }
    >({
      query: ({ id }) => `/customizer/customization-rules/${id}/`,
      providesTags: ["CustomizationRule"],
    }),
    createCustomizationRule: builder.mutation<void, CreateCustomizationRule>({
      query: (data) => ({
        url: "/customizer/customization-rules/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CustomizationRules", "CustomizationRule"],
    }),
    updateCustomizationRule: builder.mutation<
      void,
      Partial<CreateCustomizationRule> & { id: number }
    >({
      query: ({ id, ...data }) => ({
        url: `/customizer/customization-rules/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["CustomizationRules", "CustomizationRule"],
    }),
    deleteCustomizationRule: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/customizer/customization-rules/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["CustomizationRules", "CustomizationRule"],
    }),

    getThemes: builder.query<
      {
        count: number;
        results: {
          id: number;
          mirror_id: null | number;
          name: string;
          point_id: number | null;
          updated_at: string;
        }[];
      },
      ThemeQueryParams
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/customizer/themes/?${query.toString()}`;
      },
      providesTags: ["Themes"],
    }),
    getTheme: builder.query<
      CreateTheme & { id: number },
      { id: number | string }
    >({
      query: ({ id }) => `/customizer/themes/${id}/`,
      providesTags: ["Theme"],
    }),
    createTheme: builder.mutation<void, CreateTheme>({
      query: (data) => {
        const formData = new FormData();
        (Object.keys(data) as Array<keyof CreateTheme>).forEach((key) => {
          if (key === "logo") {
            if (data[key] !== "") {
              formData.append(key, data[key] as Blob);
            }
          } else if (data[key] !== null) {
            formData.append(key, String(data[key]));
          } else {
            formData.append(key, "");
          }
        });
        return {
          url: "/customizer/themes/",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Themes", "Theme"],
    }),
    updateTheme: builder.mutation<void, Partial<CreateTheme> & { id: number }>({
      query: ({ id, ...data }) => {
        const formData = new FormData();
        (Object.keys(data) as Array<keyof CreateTheme>).forEach((key) => {
          if (key === "logo") {
            if (typeof data[key] === "object") {
              formData.append(key, data[key] as Blob);
            }
            if (data.delete_logo) {
              formData.append(key, "");
            }
          } else if (data[key] === null) {
            formData.append(key, "");
          } else {
            formData.append(key, data[key] as string);
          }
        });
        return {
          url: `/customizer/themes/${id}/`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Themes", "Theme"],
    }),
    deleteTheme: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/customizer/themes/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Themes", "Theme"],
    }),

    postCustomizerCreatePreview: builder.mutation<
      CustomizerCreatePreviewResponse,
      CustomizerCreatePreviewQuery
    >({
      query: (data) => ({
        url: `/customizer/create-preview/`,
        method: "POST",
        body: data,
      }),
    }),

    getCustomizerDepartments: builder.query<
      {
        count: number;
        results: {
          id: number;
          gate_id: number;
          name: string;
        }[];
      },
      {
        search?: string;
      }
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/customizer/departments/?${query.toString()}`;
      },
    }),
  }),
});

export const {
  useGetCustomizationRulesQuery,
  useLazyGetCustomizationRuleQuery,
  useCreateCustomizationRuleMutation,
  useUpdateCustomizationRuleMutation,
  useDeleteCustomizationRuleMutation,
  useGetThemesQuery,
  useGetThemeQuery,
  useLazyGetThemeQuery,
  useCreateThemeMutation,
  useUpdateThemeMutation,
  useDeleteThemeMutation,

  usePostCustomizerCreatePreviewMutation,

  useGetCustomizerDepartmentsQuery,
} = customizerApi;
