import { FormikProps } from "formik";
import { find } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { v4 } from "uuid";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Select } from "UI/Form/Select";
import { IconButton } from "UI/IconButton";
import { Typography } from "UI/Typography";
import {
  CustomizerCreatePreviewQuery,
  usePostCustomizerCreatePreviewMutation,
} from "api/baseAPI/customizer";
import { useGetPointsAvailablePaymentMethodsQuery } from "api/baseAPI/points";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { ReactComponent as ArrowsReloadIcon } from "utils/img/arrows-reload.svg";
import { RESPONSIVE_SIZES } from "utils/tools";
import { paymentUrls } from "pages/admin/customizer/parts/customizationRule/PayFormRulePreview";
import { FormValues } from "./ThemeFormLogic";

export const PayFormThemePreview = (props: {
  form: FormikProps<FormValues>;
  logo?: string;
  formFilled?: boolean | null;
  handleClose?: () => void;
  autoRefresh?: boolean;
}) => {
  const { t } = useTranslation();

  const { form, handleClose, autoRefresh, logo, formFilled } = props;

  const selectedPointManager = useSelectedPointManager();

  const [createPreview] = usePostCustomizerCreatePreviewMutation();

  const [testUUID] = useState<string>(v4());
  const [isPreviewReady, setIsPreviewReady] = useState<boolean>(false);

  const paymentMethodTypesQuery = useGetPointsAvailablePaymentMethodsQuery({
    point: selectedPointManager.point?.id,
  });

  const previewHost =
    process.env.REACT_APP_PAY_FORMS_HOST ||
    `https://pay2${window.location.host.slice(
      window.location.host.indexOf("."),
      window.location.host.length
    )}`;

  const previewData: CustomizerCreatePreviewQuery = {
    transaction_uuid: testUUID,
    data: {
      transaction: {
        id: 1234567890,
        status: 0,
        service_type: 2,
        amount: 250.0,
        currency: "UAH",
        initial_amount: 250.0,
        initial_currency: "UAH",
        is_fc: false,
        is_retry: false,
        failure_reason_code: null,
        locale: "uk",
        description: "Preview Transaction",
        api_forms_slug: "cards",
        payment_slug: form.values.payment_slug?.slug || "card_debit_hpp",
      },
      customization: {
        theme_switch: false,
        company_name: "Preview Company",
        messenger: "https://t.me/4bill",
        email: "test@example.com",
        phone_number: "380866297449",
        ap_gp_position: 1,
        partners_logos: [
          "/static-public/partners/mc-secure.svg",
          "/static-public/partners/visa-verify.svg",
          "/static-public/partners/pci-dss.svg",
        ],
        is_card_alias_enabled: false,
        is_ap_available: true,
        is_gp_available: true,
        oferta_style: 0,
        oferta_url: "https://example.com",
        form_fields: [],
        light: {
          accent_color: form.values.accent_color,
          background_color: form.values.background_color,
          logo: logo || null,
          border_radius: form.values.border_radius,
          panel_background_color_first:
            form.values.panel_background_color_first,
          panel_background_color_second:
            form.values.panel_background_color_second,
          inputs_background: form.values.inputs_background,
          primary_text_font: form.values.primary_text_font,
          primary_text_color: form.values.primary_text_color,
          secondary_text_color: form.values.secondary_text_color,
          button_text_color: form.values.button_text_color,
          // custom_styles: null,
          ap_gp_style: form.values.ap_gp_style,
          inputs_style: form.values.inputs_style,
        },
        dark: null,
      },
      card_alias: [],
    },
  };

  useEffect(() => {
    if (formFilled !== null) {
      setIsPreviewReady(false);
      createPreview(previewData).finally(() => {
        setIsPreviewReady(true);
      });
    }
  }, [formFilled]);

  const [iframeKey, setIframeKey] = useState<number>(0);

  const handlePreview = (slug?: string) => {
    setIsPreviewReady(false);
    createPreview({
      ...previewData,
      data: {
        ...previewData.data,
        transaction: {
          ...previewData.data.transaction,
          payment_slug:
            slug || form.values.payment_slug?.slug || "card_debit_hpp",
        },
      },
    }).finally(() => {
      setIsPreviewReady(true);
      setIframeKey(iframeKey + 1);
    });
  };

  const [viewMode, setViewMode] = useState<"mobile" | "desktop">("desktop");

  return (
    <Box p={handleClose ? 24 : 0}>
      <Box flex alignItems="flex-end" justifyContent="space-between" mb={24}>
        <Box flex alignItems="center" style={{ minHeight: "50px" }}>
          <Typography variant="h5">{t("Просмотр темы")}</Typography>
        </Box>
        <StyledButtonSwitch
          firstTitle="Mobile"
          secondTitle="Desktop"
          value={viewMode  ===  "mobile"}
          onChange={() =>
            setViewMode(viewMode  ===  "mobile" ? "desktop" : "mobile")
          }
        />
        <Box style={{ width: "280px" }}>
          <Select
            value={
              form.values.payment_slug?.id
                ? String(form.values.payment_slug?.id)
                : ""
            }
            onChange={(value) => {
              const findEl = find(
                paymentMethodTypesQuery.data,
                (el) => el.id  ===  Number(value)
              );
              form.setFieldValue(
                "payment_slug",
                value
                  ? {
                      id: findEl?.id || Number(value),
                      slug: findEl?.slug || "",
                    }
                  : null
              );
              if (autoRefresh) handlePreview(findEl?.slug || "");
            }}
            label={t("Платежный метод")}
            placeholder={t("Выберите метод")}
            size="small"
            options={
              paymentMethodTypesQuery.data &&
              paymentMethodTypesQuery.data?.length > 0
                ? paymentMethodTypesQuery.data?.map((item) => ({
                    value: `${item.id}`,
                    label: item.slug,
                  }))
                : []
            }
            error={
              form.touched.payment_slug && Boolean(form.errors.payment_slug)
            }
            helperText={form.touched.payment_slug && form.errors.payment_slug}
          />
        </Box>
        {!autoRefresh && (
          <IconButton variant="outlined" onClick={handlePreview}>
            <StyledArrowsReloadIcon />
          </IconButton>
        )}
        {!!handleClose && (
          <StyledCloseButton variant="outlined" onClick={() => handleClose()}>
            {t("Закрыть")}
          </StyledCloseButton>
        )}
      </Box>
      {isPreviewReady && previewHost && (
        <Box
          style={{
            position: "sticky",
            justifyContent: "center",
            flex: 1,
            display: "flex",
          }}
        >
          <iframe
            key={iframeKey}
            title="iframe"
            src={`${previewHost}/${
              form.values.payment_slug?.slug &&
              paymentUrls?.[form.values.payment_slug?.slug]
                ? paymentUrls?.[form.values.payment_slug?.slug]
                : "public/cards"
            }/ua/${testUUID}`}
            style={{
              width: viewMode === "desktop" ? "100%" : "400px",
              minHeight: handleClose ? "500px" : "600px",
            }}
           />
        </Box>
      )}
      {!!handleClose && (
        <StyledMobileCloseButton
          mt={24}
          fullwidth
          variant="outlined"
          onClick={() => handleClose()}
        >
          {t("Закрыть")}
        </StyledMobileCloseButton>
      )}
    </Box>
  );
}

const StyledArrowsReloadIcon = styled(ArrowsReloadIcon)`
  display: flex;
  align-items: center;
  path {
    fill: #838383 !important;
  }
`;

const StyledButtonSwitch = styled(ButtonSwitch)`
  @media (${RESPONSIVE_SIZES.lg}) {
    display: none;
  }
`;

const StyledCloseButton = styled(Button)`
  @media (${RESPONSIVE_SIZES.lg}) {
    display: none;
  }
`;

const StyledMobileCloseButton = styled(Button)`
  @media (${RESPONSIVE_SIZES.overLg}) {
    display: none;
  }
`;
