import React from "react";

interface CalendarDayProps {
  className?: string;
  disabled: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export const CalendarDay = ({
  className,
  disabled,
  onClick,
  children,
}: CalendarDayProps): React.ReactElement => (
  <div className={className} onClick={() => !disabled && onClick()}>
    {children}
  </div>
);
