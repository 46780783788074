import { ReactElement } from "react";
import * as yup from "yup";
import { UPIPayoutForm } from "pages/payout/logic/forms/UPIPayoutForm";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class UPIPayoutMethod extends BasePayoutMethod implements IPayoutMethod {
  slug: string = "upi";

  kind: 9 = 9;

  name: string = "UPI";

  mobileName: string = this.name;

  icon: ReactElement = (<></>);

  csvExample: string | null = "/file-samples/sample-upi.csv";

  xlsxExample: string | null = "/file-samples/sample-upi.xlsx";

  get serviceId() {
    return (
      (this.pointManager?.service_config?.upi_payouts &&
        this.getService(this.pointManager?.service_config?.upi_payouts)) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  form: (payoutMethod: IPayoutMethod) => ReactElement = (payoutMethod) => (
    <UPIPayoutForm payoutMethod={payoutMethod} />
  );

  customerAccountInput() {
    return <></>;
  }

  customerAccountValidationSchema = yup
    .string()
    .required("Укажите счет получателя");
}
