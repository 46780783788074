import { ReactElement } from "react";
import * as yup from "yup";
import { PIXPayoutForm } from "pages/payout/logic/forms/PIXPayoutForm";
import { ReactComponent as PixIcon } from "utils/img/pix.svg";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class PixPayoutMethod extends BasePayoutMethod implements IPayoutMethod {
  slug: string = "pix";

  kind: 7 = 7;

  name: string = "PIX";

  mobileName: string = this.name;

  icon: ReactElement = (<PixIcon />);

  csvExample: string | null = "/file-samples/sample-pix.csv";

  xlsxExample: string | null = "/file-samples/sample-pix.xlsx";

  get serviceId() {
    return (
      (this.pointManager?.service_config?.pix_payouts &&
        this.getService(this.pointManager?.service_config?.pix_payouts)) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = () => <></>;

  customerAccountValidationSchema = yup.string().required("Введите email");

  form: (payoutMethod: IPayoutMethod) => ReactElement = (payoutMethod) => (
    <PIXPayoutForm payoutMethod={payoutMethod} />
  );
}
