import styled from "styled-components";
import { getColors } from "utils/getColors";
import { Padding, paddingProps, paddingStyle } from "UI/utility/padding";
import { Margin, marginProps, marginStyle } from "UI/utility/maring";
import {
  getResponsiveProps,
  getResponsiveStyle,
  Responsive,
} from "UI/utility/responsive";
import { hiddenStyle, Hiden } from "UI/utility/hiden";

type ResponsiveLinearProgressProps = Padding & Margin & Hiden;

type LinearProgressProps = {} & Padding &
  Margin &
  Responsive<ResponsiveLinearProgressProps>;

export const LinearProgress = (props: LinearProgressProps) => {
  const { accentColor } = getColors();

  return (
    <StyledWrapper
      {...paddingProps(props)}
      {...marginProps(props)}
      {...getResponsiveProps(props)}
    >
      <StyledProgressBar>
        <StyledProgressBarValue accentcolor={accentColor} />
      </StyledProgressBar>
    </StyledWrapper>
  );
};

// Styles
type StyledButtonProps = {} & Padding &
  Margin &
  Hiden &
  Responsive<ResponsiveLinearProgressProps>;

const StyledWrapper = styled.div<StyledButtonProps>`
  width: 100%;
  margin: auto;

  ${(props) =>
    [
      paddingStyle,
      marginStyle,
      getResponsiveStyle<ResponsiveLinearProgressProps>([
        paddingStyle,
        marginStyle,
        hiddenStyle,
      ]),
    ]
      .map((fn) => fn(props))
      .join("")}
`;

const StyledProgressBar = styled.div`
  height: 4px;
  width: 100%;
  overflow: hidden;
  background-color: #c8cfdc;
`;

const StyledProgressBarValue = styled.div`
  width: 100%;
  height: 100%;
  transform-origin: 0% 50%;
  background-color: ${(props: { accentcolor: string }) => props.accentcolor};
  animation: indeterminateAnimation 1s infinite linear;
  @keyframes indeterminateAnimation {
    0% {
      transform: translateX(0) scaleX(0);
    }
    40% {
      transform: translateX(0) scaleX(0.4);
    }
    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }
`;
