import { useTranslation } from "react-i18next";
import { TextInput } from "UI/Form/TextInput";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";

export const EmailPayInput = (props: CustomerAccountInputProps) => {
  const { t } = useTranslation();
  const { id, form } = props;
  return (
    <TextInput
      onChange={(value) => {
        form.setFieldError(`payouts[${id}].customer_account`, "");
        form.setFieldValue(`payouts[${id}].customer_account`, value);
      }}
      placeholder={t("Счет получателя")}
      name="customer_account"
      value={form.values.payouts[id].customer_account}
      onBlur={(e) => {
        form.handleBlur(e);
        form.setFieldTouched(`payouts[${id}].customer_account`, true);
      }}
      error={
        !!(form.touched.payouts?.[id]?.customer_account &&
        form.errors.payouts?.[id]?.customer_account)
      }
      helperText={
        t<any>(
          form.touched.payouts?.[id]?.customer_account &&
            form.errors.payouts?.[id]?.customer_account &&
            form.errors.payouts?.[id]?.customer_account
        ) || ""
      }
      size="small"
    />
  );
}
