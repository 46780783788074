import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { RESPONSIVE_SIZES } from "utils/tools";

interface Props {
  stepsLabels: string[];
  activeStep: number;
}

export const StepperForm = ({ stepsLabels, activeStep }: Props) => {
  const { t } = useTranslation();
  const { accentColor } = getColors();

  return (
    <Grid
      container
      p={16}
      mb={40}
      flex
      justifyContent="space-between"
      alignItems="center"
      style={{
        backgroundColor: "white",
      }}
    >
      {stepsLabels.map((label, i) => {
        const isLast = i + 1  ===  stepsLabels.length;
        const background = activeStep >= i ? accentColor : "#C8CFDC";
        return (
          <Wrapper active={activeStep  ===  i} key={label}>
            <Grid item flex>
              <Box
                p={3}
                mr={12}
                style={{ border: "1px solid rgba(44, 96, 176, 0.4)" }}
              >
                <Box
                  flex
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    width: 34,
                    color: "white",
                    height: 34,
                    background,
                  }}
                >
                  {String(i + 1)}
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: background,
                  }}
                >
                  {`${t("Шаг")} ${i + 1}/${
                    stepsLabels.length
                  }`.toLocaleUpperCase()}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: activeStep >= i ? "black" : "#C8CFDC",
                  }}
                  mt={6}
                >
                  {label}
                </Typography>
              </Box>
            </Grid>
            {!isLast ? (
              <Grid item sm="fill" mx={24}>
                <StepperDivider
                  first={activeStep >= i}
                  second={activeStep >= i + 1}
                  background={accentColor}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Wrapper>
        );
      })}
    </Grid>
  );
}
const Wrapper = styled.div`
  display: contents;
  @media (${RESPONSIVE_SIZES.sm}) {
    display: ${(props: { active: boolean }) =>
      props.active ? "block" : "none"};
  }
`;
const StepperDivider = styled.div`
  width: 100%;
  height: 4px;
  background: linear-gradient(
    to right,
    ${(props: { background: string; first: boolean; second: boolean }) =>
      `${props.first ? props.background : "#C8CFDC"} 50%, ${
        props.second ? props.background : "#C8CFDC"
      } 50%`}
  );
  @media (${RESPONSIVE_SIZES.sm}) {
    display: none !important;
  }
`;
