import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

type PointLogQueryParams = {
  page: string;
  search: string;
  point: number;
  log_to: string;
  log_from: string;
};
type TransactionPointLogList = {
  created_at: string;
  data: string;
  datetime: string;
  file: string;
  log_time: number;
  point_id: number;
  processing_status: number | null;
  provider_id: number | null;
  status: number | null;
  transaction_id: number;
  type: 5 | 10;
};

export const pointsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPointsPointLog: builder.query<
      {
        datetime: string;
        key: number;
        log_count: number;
        min_created_at: string;
        point_id: number;
        transaction_id: number;
      }[],
      PointLogQueryParams
    >({
      query: ({ point, ...params }) => {
        const query = getQuery(params);
        return `/points/${point}/point-log/?${query.toString()}`;
      },
    }),
    getPointPointLogDetail: builder.query<
      TransactionPointLogList[],
      { point: number; key: number }
    >({
      query: ({ point, ...params }) => {
        const query = getQuery(params);
        return `/points/${point}/point-log-detail/?${query.toString()}`;
      },
    }),
    getPointData: builder.query<
      { results: { id: number; value: string }[] },
      { point: number; key: string }
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/point-data/?${query.toString()}`;
      },
      providesTags: ["PointData"],
    }),
    updateСallbackUrl: builder.mutation<
      void,
      {
        id: number;
        url: string;
      }
    >({
      query: ({ id, ...data }) => ({
        url: `/points/${id}/update-callback-url/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PointData"],
    }),
    resetСallbackUrl: builder.mutation<
      void,
      {
        id: number;
      }
    >({
      query: ({ id }) => ({
        url: `/points/${id}/reset-callback-url/`,
        method: "POST",
      }),
      invalidatesTags: ["PointData"],
    }),
    getPointTheme: builder.query<
      { id: number; styles: string },
      { point: number }
    >({
      query: ({ point }) => `/points/${point}/get-theme/`,
      providesTags: ["PointTheme"],
    }),
    updateTheme: builder.mutation<
      void,
      {
        id: number;
        styles: string;
      }
    >({
      query: ({ id, ...data }) => ({
        url: `/points/${id}/update-theme/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PointTheme"],
    }),
    resetTheme: builder.mutation<
      void,
      {
        id: number;
      }
    >({
      query: ({ id, ...data }) => ({
        url: `/points/${id}/reset-theme/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PointTheme"],
    }),

    getPointsAvailablePaymentMethods: builder.query<
      {
        id: number;
        slug: string;
      }[],
      {
        point: number;
      }
    >({
      query: ({ point }) => `/points/${point}/available-payment-methods/`,
    }),
  }),
});

export const {
  useGetPointsPointLogQuery,
  useGetPointPointLogDetailQuery,
  useResetСallbackUrlMutation,
  useUpdateСallbackUrlMutation,
  useLazyGetPointDataQuery,
  useResetThemeMutation,
  useUpdateThemeMutation,
  useLazyGetPointThemeQuery,
  useGetPointsAvailablePaymentMethodsQuery,
} = pointsApi;
