import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import {
  useLazyGetPointDataQuery,
  useResetСallbackUrlMutation,
  useUpdateСallbackUrlMutation,
} from "api/baseAPI/points";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { getColors } from "utils/getColors";
import { ReactComponent as CaretCircleUpIcon } from "utils/img/caret-circle-up.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";

export const CallbackUrlSettings = () => {
  const { t } = useTranslation();

  const [openCallback, setOpenCallback] = useState(true);
  const [openCallbackModal, setOpenCallbackModal] = useState(false);
  const [openFirstParagraphInModal, setFirstParagraphInModal] = useState(true);
  const [openSecondParagraphInModal, setSecondParagraphInModal] =
    useState(true);

  const { pageBackground } = getColors();
  const selectedPointManager = useSelectedPointManager();
  const [getPointData, getPointDataMeta] = useLazyGetPointDataQuery();
  const [resetСallbackUrl, resetСallbackUrlMeta] =
    useResetСallbackUrlMutation();
  const [updateСallbackUrl, updateСallbackUrlMeta] =
    useUpdateСallbackUrlMutation();

  useEffect(() => {
    if (selectedPointManager.point?.id) {
      getPointData({
        point: selectedPointManager.point?.id,
        key: "callback_url",
      });
    }
  }, [selectedPointManager.point?.id]);

  const form = useFormik<{
    url: string;
  }>({
    initialValues: {
      url: "",
    },
    onSubmit: (values) => {
      if (
        values.url &&
        values.url !== getPointDataMeta?.data?.results?.[0]?.value
      ) {
        updateСallbackUrl({
          id: selectedPointManager.point?.id,
          url: values.url,
        });
        return;
      }
      if (getPointDataMeta?.data?.results?.[0]?.value && !values.url) {
        resetСallbackUrl({ id: selectedPointManager.point?.id });
      }
    },
  });

  useEffect(() => {
    if (
      !getPointDataMeta.isFetching &&
      getPointDataMeta.isSuccess &&
      !isEmpty(getPointDataMeta.data?.results) &&
      getPointDataMeta?.data?.results?.[0]?.value
    ) {
      form.setFieldValue("url", getPointDataMeta?.data?.results?.[0]?.value);
    }
  }, [getPointDataMeta]);

  useSuccessNotification([resetСallbackUrlMeta]);
  useSuccessNotification([updateСallbackUrlMeta]);
  useErrorNotification([resetСallbackUrlMeta]);
  useErrorNotification([updateСallbackUrlMeta]);

  return (
    <>
      <Grid item sm={12}>
        <Box
          flex
          alignItems="center"
          style={{ cursor: "pointer", width: "fit-content" }}
          onClick={() => setOpenCallback(!openCallback)}
        >
          <Typography variant="h5" mr={16}>
            {t("Настройки Callback url")}
          </Typography>
          <StyledCaretCircleUpIcon open={openCallback} />
        </Box>
      </Grid>

      {openCallback ? (
        <>
          <Grid item sm={12} lg={4}>
            <TextInput
              size="small"
              label="URL"
              placeholder={t("Введите URL")}
              onChange={(value) => form.setFieldValue("url", value)}
              value={form.values.url}
              error={form.touched.url && Boolean(form.errors.url)}
              helperText={form.touched.url && form.errors.url}
            />
          </Grid>
          <Grid
            item
            sm={12}
            responsive={{ sm: { hidden: false }, lg: { hidden: true } }}
          >
            <Button
              responsive={{ sm: { hidden: false }, lg: { hidden: true } }}
              variant="text"
              fullwidth
              onClick={() => setOpenCallbackModal(true)}
              px={24}
            >
              {t("Инструкция формирование callback_url")}
            </Button>
          </Grid>
          <Grid item sm={12} lg={2}>
            <Button
              mt={2}
              fullwidth
              disabled={
                getPointDataMeta.isFetching ||
                resetСallbackUrlMeta.isLoading ||
                updateСallbackUrlMeta.isLoading ||
                form.values.url ===
                  (getPointDataMeta?.data?.results?.[0]?.value || "")
              }
              variant="outlined"
              onClick={() => form.handleSubmit()}
            >
              {t("Сохранить")}
            </Button>
          </Grid>
          <Grid
            item
            sm={12}
            responsive={{ sm: { hidden: true }, lg: { hidden: false } }}
          >
            <Button
              responsive={{ sm: { hidden: true }, lg: { hidden: false } }}
              variant="text"
              onClick={() => setOpenCallbackModal(true)}
            >
              {t("Инструкция формирование callback_url")}
            </Button>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {openCallbackModal ? (
        <Modal
          open
          hideHeader
          // height={250}
          width={650}
          onClose={() => setOpenCallbackModal(false)}
          disabledOverlay
        >
          <Box p={24}>
            <Box
              flex
              alignItems="center"
              style={{ cursor: "pointer", width: "fit-content" }}
              mb={16}
              onClick={() =>
                setFirstParagraphInModal(!openFirstParagraphInModal)
              }
            >
              <Typography variant="h5" mr={16}>
                {t("Инструкция формирование callback_url")}
              </Typography>
              <StyledCaretCircleUpIcon open={openFirstParagraphInModal} />
            </Box>
            {openFirstParagraphInModal ? (
              <>
                <Typography variant="subtitle1" mb={16}>
                  {t(
                    "Чтобы понимать, к какой транзакции относится пришедший коллбек, в callback_url необходимо включать какой-то уникальный идентификатор транзакции, по которому мерчант всегда сможет ее идентифицировать."
                  )}
                </Typography>
                <Box mb={16} p={10} style={{ background: pageBackground }}>
                  <Typography
                    variant="subtitle2"
                    style={{ wordBreak: "break-word" }}
                  >
                    {t("Например")}
                    <br />
                    https://merchant_server_domain/callback/123456789
                    <br />
                    https://merchant_server_domain/callback?transaction=123456789
                  </Typography>
                </Box>
                <Typography variant="subtitle1" mb={16}>
                  {t(
                    "Кроме того, есть возможность передавать callback_url в виде шаблона с подстановками."
                  )}
                </Typography>
                <Box mb={16} p={10} style={{ background: pageBackground }}>
                  <Typography
                    variant="subtitle2"
                    style={{ wordBreak: "break-word" }}
                  >
                    {t("Например")}
                    <br />
                    {`https://merchant_server_domain/callback/{external_transaction_id}`}
                    <br />
                    {`https://merchant_server_domain/callback/{external_transaction_id}?customer={external_customer_id}`}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" mb={16}>
                  {t(
                    "Перед отправкой коллбека в URL будут подставлены актуальные значения из текущей транзакции."
                  )}
                </Typography>
              </>
            ) : (
              <></>
            )}
            <Box
              flex
              alignItems="center"
              style={{ cursor: "pointer", width: "fit-content" }}
              mb={16}
              mt={16}
              onClick={() =>
                setSecondParagraphInModal(!openSecondParagraphInModal)
              }
            >
              <Typography variant="h5" mr={16}>
                {t("Перечень доступных подстановок для callback_url")}
              </Typography>
              <StyledCaretCircleUpIcon open={openSecondParagraphInModal} />
            </Box>
            {openSecondParagraphInModal ? (
              <>
                <Typography variant="h6" mb={24}>
                  {t(
                    "{transaction_id} - Идентификатор транзакции в платежном шлюзе"
                  )}
                </Typography>
                <Typography variant="h6" mb={24}>
                  {t(
                    "{external_transaction_id} - Идентификатор транзакции в системе мерчанта"
                  )}
                </Typography>
                <Typography variant="h6" mb={24}>
                  {t(
                    "{external_order_id} - Идентификатор заказа в системе мерчанта"
                  )}
                </Typography>
                <Typography variant="h6" mb={24}>
                  {t(
                    "{external_customer_id} - Идентификатор клиента в системе мерчанта"
                  )}
                </Typography>
                <Typography variant="subtitle1" mb={24}>
                  {t(
                    "Если какое-то из значений для подстановки отсутствует в транзакции, на его месте будет None."
                  )}
                </Typography>
              </>
            ) : (
              <></>
            )}
            <Button
              size="small"
              variant="outlined"
              onClick={() => setOpenCallbackModal(false)}
            >
              {t("Закрыть")}
            </Button>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

const StyledCaretCircleUpIcon = styled(CaretCircleUpIcon)`
  ${(props: { open: boolean }) =>
    !props.open ? "transform: rotate(180deg)" : ""};
`;
