import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import {
  Transaction,
  useCancelTransactionMutation,
} from "api/baseAPI/transactions";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";

type Props = {
  transaction: Transaction;
  onClose: () => void;
};

export const PartCancelForm = (props: Props): React.ReactElement => {
  const { onClose, transaction } = props;
  const { t } = useTranslation();

  const [cancelTransaction, cancelTransactionMeta] =
    useCancelTransactionMutation();

  useEffect(() => {
    if (cancelTransactionMeta.isSuccess) {
      onClose();
    }
  }, [cancelTransactionMeta.isSuccess]);

  const form = useFormik({
    initialValues: {
      amount: "",
    },
    onSubmit: (values) => {
      cancelTransaction({
        amount: Number(values.amount),
        id: transaction.id,
      });
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .transform((value, origin) => origin  ===  "" ? null : value)
        .nullable()
        .max(
          Number(transaction.amount) - Number(transaction.refunded_amount || 0),
          `${t("Сумма не может быть больше")} ${(
            transaction.amount - Number(transaction.refunded_amount || 0)
          ).toFixed(2)}`
        )
        .positive(t("Введите положительное число"))
        .required(t("Введите сумму"))
        .typeError(t("Неправильный формат")),
    }),
  });

  useErrorNotification([cancelTransactionMeta]);
  useSuccessNotification([cancelTransactionMeta]);
  useTranslateFormErrors(form);

  return (
    <Modal
      open
      hideHeader
      height={250}
      width={380}
      onClose={() => !cancelTransactionMeta.isLoading && onClose()}
    >
      <Box p={27}>
        <Typography variant="h5" mb={8} style={{ textAlign: "center" }}>
          {t("Введите сумму для частичного возврата")}
        </Typography>
        <Typography variant="subtitle2" mb={32} style={{ textAlign: "center" }}>
          {t("Сумма не должна превышать сумму транзакции")}
        </Typography>
        <TextInput
          label={t("Сумма")}
          placeholder={t("Введите сумму возврата")}
          value={form.values.amount}
          onChange={(value) =>
            form.setFieldValue("amount", value.replace(/[^0-9.]/g, ""))
          }
          error={form.touched.amount && Boolean(form.errors.amount)}
          helperText={form.touched.amount && form.errors.amount}
          size="small"
        />
        <Box flex alignItems="center" justifyContent="space-between" mt={24}>
          <Button
            mr={16}
            size="small"
            disabled={cancelTransactionMeta.isLoading}
            variant="outlined"
            onClick={() => onClose()}
          >
            {t("Отмена")}
          </Button>
          <Box style={{ maxWidth: "55%", width: "100%" }}>
            <Button
              size="small"
              fullwidth
              disabled={cancelTransactionMeta.isLoading}
              onClick={() => form.handleSubmit()}
            >
              {t("Подтвердить")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
