import { useTranslation } from "react-i18next";
import { Layout } from "components/layouts/Layout";
import { CustomizationRules } from "./parts/customizationRule/CustomizationRules";
import { Themes } from "./parts/theme/Themes";

export const CustomizePage = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Управление"), ""],
    [t("Настройки формы"), ""],
  ];

  return (
    <Layout title={t("Настройки формы")} breadCrumbs={breadCrumbs}>
      <Themes />
      <CustomizationRules />
    </Layout>
  );
};
