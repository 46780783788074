import { baseSplitApi } from "./index";

export const w2wApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    calculate: builder.mutation<
      {
        result: {
          commission_amount: number;
          commission_currency: string;
        };
        success: boolean;
        message: string;
      },
      {
        sender_wallet_id: number;
        receiver_wallet_id: number;
        sender_amount: number;
      }
    >({
      query: (data) => ({
        url: `/w2w/calculate/`,
        method: "post",
        body: data,
      }),
    }),
    transfer: builder.mutation<
      {
        error: { code: number; title: string };
        response: {
          id: number;
          is_test: boolean;
          status: number;
          status_description: string;
        };
        success: boolean;
      },
      {
        sender_wallet_id: number;
        receiver_wallet_id: number;
        sender_amount: number;
        commission_on_sender: boolean;
      }
    >({
      query: (data) => ({
        url: `/w2w/transfer/`,
        method: "post",
        body: data,
      }),
    }),
  }),
});

export const { useCalculateMutation, useTransferMutation } = w2wApi;
