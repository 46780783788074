import { ReactNode } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { ItemWithoutChild } from "./ItemWithoutChild";

export const MenuItem = ({
  onClick,
  toggleMenu,
  isActive,
  isMenuCollapsed,
  title,
  isOpened,
  icon,
  sidebarHoverBackground,
  childMenus,
}: {
  onClick: () => void;
  toggleMenu: () => void;
  isActive: boolean;
  isMenuCollapsed: boolean;
  title: string;
  isOpened: boolean;
  icon: ReactNode;
  sidebarHoverBackground: string;
  childMenus: {
    to: string;
    title: string;
    isAvailable: boolean;
  }[];
}) => (
    <>
      <StyledListItemCollapsed
        onClick={onClick}
        backgroundhover={sidebarHoverBackground}
        active={isActive}
      >
        <StyledFlex>
          <StyledMenuItemIconWrapper>{icon}</StyledMenuItemIconWrapper>
          {!isMenuCollapsed && title}
        </StyledFlex>
        <StyledArrowUpDown open={isOpened} />
      </StyledListItemCollapsed>
      {isOpened ? (
        <StyledListChildren onClick={() => toggleMenu()}>
          {childMenus.map((child, index) =>
            child.isAvailable ? (
              <ItemWithoutChild
                key={index}
                isMenuCollapsed={isMenuCollapsed}
                to={child.to}
                title={child.title}
                isChild
              />
            ) : null
          )}
        </StyledListChildren>
      ) : null}
    </>
  )

const StyledListItemCollapsed = styled.div<{
  active?: boolean;
  backgroundhover: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  height: 52px;
  cursor: pointer;
  color: #ffffff;
  ${(props) =>
    props.active && !!props.backgroundhover
      ? `background: ${props.backgroundhover};`
      : ""};
  &:hover {
    background: ${(props) => props.backgroundhover};
  }
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMenuItemIconWrapper = styled.div`
  margin-right: 10px;
  svg {
    width: 18px !important;
  }
`;

const StyledArrowUpDown = styled(ArrowUpDown)`
  padding-left: 16px;
  path {
    fill: #ffffff !important;
  }
  ${(props: { open: boolean }) =>
    props.open
      ? `
          padding-right: 16px;
          padding-left: 0;
          transform: rotate(180deg);
        `
      : ""};
`;

const StyledListChildren = styled.div`
  margin-top: 10px;
`;
