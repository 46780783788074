import { useEffect, useState } from "react";
import { Modal } from "UI/Modal";
import { useLazyGetThemeQuery } from "api/baseAPI/customizer";
import { useGetPointsAvailablePaymentMethodsQuery } from "api/baseAPI/points";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { PayFormThemePreview } from "./PayFormThemePreview";
import { useThemeFormLogic } from "./ThemeFormLogic";

export const ModalThemePreview = ({
  themeID,
  onClose,
}: {
  themeID: number;
  onClose: () => void;
}) => {
  const [formFilled, setFormFilled] = useState<null | boolean>(null);

  const selectedPointManager = useSelectedPointManager();
  const paymentMethodTypesQuery = useGetPointsAvailablePaymentMethodsQuery({
    point: selectedPointManager.point?.id,
  });
  const [getTheme] = useLazyGetThemeQuery();
  const { form, setImageUrl, imageUrl } = useThemeFormLogic({
    onSubmit: () => {},
  });

  useEffect(() => {
    getTheme({ id: themeID })
      .unwrap()
      .then((data) => {
        form.setValues({
          name: data.name,
          background_color: data.background_color,
          panel_background_color_first: data.panel_background_color_first,
          panel_background_color_second: data.panel_background_color_second,
          accent_color: data.accent_color,
          primary_text_font: data.primary_text_font,
          primary_text_color: data.primary_text_color,
          secondary_text_color: data.secondary_text_color,
          button_text_color: data.button_text_color,
          ap_gp_style: data.ap_gp_style,
          inputs_style: data.inputs_style,
          inputs_background: data.inputs_background,
          border_radius: data.border_radius,
          delete_logo: false,
          payment_slug: null,
        });
        setImageUrl(typeof data.logo === "string" ? data.logo : "");
        setFormFilled(true);
      });
  }, [themeID]);

  useEffect(() => {
    if (
      paymentMethodTypesQuery.isSuccess &&
      paymentMethodTypesQuery.data?.length > 0
    ) {
      const findCardMethod = paymentMethodTypesQuery.data.find(
        (el) => el.slug === "card_debit_hpp"
      );
      form.setFieldValue(
        "payment_slug",
        findCardMethod?.id
          ? { id: findCardMethod.id, slug: findCardMethod.slug }
          : null
      );
    }
  }, [paymentMethodTypesQuery]);

  return (
    <Modal hideHeader open height={620} width={1060} onClose={onClose}>
      <PayFormThemePreview
        form={form}
        logo={imageUrl}
        formFilled={formFilled}
        handleClose={onClose}
        autoRefresh
      />
    </Modal>
  );
};
