import { useFormik } from "formik";
import { isString } from "lodash";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import { useGetPointTipConfigsQuery } from "api/baseAPI/pointTipConfigs";
import { useGetServicesCommissionsQuery } from "api/baseAPI/services";
import {
  useLazyGetTipUserQuery,
  usePatchTipUserMutation,
} from "api/baseAPI/tipUsers";
import { LinearProgress } from "components/LinearProgress";
import { Layout } from "components/layouts/Layout";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { ReactComponent as CameraIcon } from "utils/img/camera.svg";
import { ReactComponent as Info } from "utils/img/info.svg";
import { ReactComponent as LinkIcon } from "utils/img/link.svg";
import { ReactComponent as QRPlaceholder } from "utils/img/qr-placeholder.svg";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import ImagePlaceholder from "./assets/image-placeholder.svg";
import { ReactComponent as SuccessModal } from "./assets/success-modal.svg";
import { FakePayForm } from "./parts/FakePayForm";
import { QrDownloader, handleDownloadQr } from "./parts/QrDownloader";

export const TipUserEdit = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные сервисы"), ""],
    [t("QR для чаевых"), "/services/tip-users"],
    [t("Редактирование менеджера"), ""],
  ];

  const { id } = useParams<{ id: string }>();

  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [qrUrl, setQrUrl] = useState<string>("");

  const navigate = useNavigate();

  const pm = useSelectedPointManager();

  const ptcQuery = useGetPointTipConfigsQuery({ point: pm?.point.id });

  const commissionQuery = useGetServicesCommissionsQuery(
    {
      serviceId: ptcQuery.data?.results[0].service.id || 0, // zero is a hack
      pointId: pm?.point.id,
    },
    { skip: !ptcQuery.data?.results[0] }
  );

  const [updateUser, updateUserMeta] = usePatchTipUserMutation();

  const form = useFormik({
    initialValues: {
      name: "",
      company_name: "",
      position: "",
      description: "",
      accent_color: "#000000",
      card_number: "",
      def_amount: "0",
      suggested_amounts: ["100", "200", "500"],
      avatar: "",
    },
    onSubmit: (values) => {
      if (id && pm.point_id) {
        const data: {
          id: string;
          name: string;
          company_name: string;
          position: string;
          description?: string;
          accent_color?: string;
          card_number: string;
          def_amount: string;
          suggested_amounts?: number[];
          avatar?: File | string | null;
          point: number;
        } = {
          id,
          name: values.name,
          company_name: values.company_name,
          position: values.position,
          description: values.description,
          accent_color: values.accent_color,
          card_number: values.card_number,
          def_amount: values.def_amount,
          suggested_amounts: values.suggested_amounts.map((a) => Number(a)),
          point: pm.point_id,
          avatar: "",
        };

        if (selectedFile) {
          data.avatar = selectedFile;
        }

        updateUser(data)
          .unwrap()
          .then(() => {
            setShowSuccessModal(true);
          });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("Обязательное поле")),
      company_name: Yup.string().required(t("Обязательное поле")),
      position: Yup.string().required(t("Обязательное поле")),
      card_number: Yup.string().required(t("Обязательное поле")),
      suggested_amounts: Yup.array()
        .of(Yup.string())
        .test("unique", t("Допускаются только уникальные значения"), (value) =>
          value ? value.length === new Set(value)?.size : true
        ),
    }),
  });

  useTranslateFormErrors(form);

  useServerErrorsInForm(form.setFieldError, updateUserMeta.error);

  const [getTipUser] = useLazyGetTipUserQuery();

  useEffect(() => {
    if (id) {
      getTipUser({ id })
        .unwrap()
        .then((data) => {
          form.setFieldValue("name", data.name, false);
          form.setFieldValue("company_name", data.company_name || "", false);
          form.setFieldValue("position", data.position || "", false);
          form.setFieldValue("description", data.description || "", false);
          form.setFieldValue("accent_color", data.accent_color || "", false);
          form.setFieldValue("card_number", data.card_number, false);
          form.setFieldValue("def_amount", data.def_amount || "", false);
          form.setFieldValue(
            "suggested_amounts",
            data.suggested_amounts,
            false
          );

          if (data.avatar) {
            setImageUrl(data.avatar);
          }

          setQrUrl(data.page_url);
        });
    }
  }, [id]);

  const handleChangeFile = async (fileList: File[]) => {
    if (!fileList) return;
    const file = fileList[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result && isString(reader.result)) {
        setImageUrl(reader.result);
      } else {
        setImageUrl("");
      }
    };
    reader.readAsDataURL(file);
    setSelectedFile(file);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(t("Скопировано в буфер обмена"));
  };

  return (
    <Layout title={t("Транзакции")} breadCrumbs={breadCrumbs}>
      <>
        {commissionQuery.isFetching || ptcQuery.isFetching ? (
          <LinearProgress />
        ) : (
          <Box py={32} px={24}>
            <Grid container hSpace={20}>
              <Grid item md={8}>
                <Typography variant="h5" mb={25}>
                  {t("Редактирование менеджера")}
                </Typography>
                <Grid container my={30} hSpace={20}>
                  <Grid
                    item
                    md={6}
                    sm={12}
                    responsive={{ md: { mb: 0 }, sm: { mb: 24 } }}
                  >
                    <Dropzone
                      onDrop={handleChangeFile}
                      accept={{ "image/*": [] }}
                      maxSize={625000}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          style={{ height: "100%", minHeight: "300px" }}
                        >
                          <Box
                            style={{
                              backgroundImage: `url(${ImagePlaceholder})`,
                              backgroundColor: "white",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              cursor: "pointer",
                              height: "100%",
                              maxWidth: "100%",
                              position: "relative",
                              // marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            mb={24}
                          >
                            <input {...getInputProps()} />

                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                style={{ width: "100%", maxHeight: "100%" }}
                                alt=""
                              />
                            ) : (
                              <></>
                            )}
                            <Box
                              flex
                              p={8}
                              alignItems="center"
                              style={{
                                position: "absolute",
                                bottom: 0,
                                background: "rgba(0, 0, 0, 0.56)",
                                width: "calc(100% - 16px)",
                              }}
                            >
                              <CameraIcon />
                              <Typography
                                variant="buttonSmall"
                                pl={8}
                                style={{ color: "white" }}
                              >
                                {t("Загрузить фото").toLocaleUpperCase()}
                              </Typography>
                            </Box>
                          </Box>
                        </div>
                      )}
                    </Dropzone>
                    {form.errors.avatar ? (
                      <Box py={10} mb={25} style={{ color: "red" }}>
                        {t("Выберите фото")}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <Grid container hSpace={20} vSpace={20}>
                      <Grid item sm={12}>
                        <TextInput
                          label={t("Имя менеджера")}
                          placeholder={t("Введите имя")}
                          value={form.values.name}
                          onChange={(val) => form.setFieldValue("name", val)}
                          error={form.errors.name}
                          helperText={String(form.errors.name)}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextInput
                          label={t("Название компании")}
                          placeholder={t("Введите название")}
                          value={form.values.company_name}
                          onChange={(val) =>
                            form.setFieldValue("company_name", val)
                          }
                          error={form.errors.company_name}
                          helperText={form.errors.company_name}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextInput
                          label={t("Должность")}
                          placeholder={t("Введите значение")}
                          value={form.values.position}
                          onChange={(val) => {
                            form.setFieldValue("position", val);
                          }}
                          error={form.errors.position}
                          helperText={form.errors.position}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <TextInput
                          label={t("Номер карты")}
                          placeholder={t("Введите значение")}
                          value={form.values.card_number}
                          onChange={(val) => {
                            form.setFieldValue(
                              "card_number",
                              val.replace(/[^0-9.]/g, "").slice(0, 16)
                            );
                          }}
                          error={form.errors.card_number}
                          helperText={form.errors.card_number}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <StyledCommissionBlock>
                          <div>
                            <Info height={14} width={14} stroke="black" />
                          </div>
                          <div>
                            <div>{`${t("Комиссия")} 4Bill`}</div>
                            {commissionQuery.data?.point.percent} {"% + "}
                            {commissionQuery.data?.point.fix}{" "}
                            {ptcQuery.data?.results[0]?.wallet.currency}
                          </div>
                        </StyledCommissionBlock>
                      </Grid>
                      <Grid item sm={12}>
                        <TextInput
                          multiline
                          label={t("Описание")}
                          placeholder={t("Введите описание")}
                          value={form.values.description}
                          onChange={(val) => {
                            form.setFieldValue("description", val);
                          }}
                          error={form.errors.description}
                          helperText={form.errors.description}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container hSpace={20} vSpace={20} my={30}>
                  <Grid item sm={12}>
                    <Typography variant="body">
                      {t("Настройки сумм")}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <TextInput
                      label={t("Валюта")}
                      placeholder={t("Введите значение")}
                      value={ptcQuery.data?.results[0]?.wallet.currency}
                      readOnly
                    />
                  </Grid>
                  <Grid item md={2} sm={4}>
                    <TextInput
                      label={t("Сумма")}
                      placeholder={t("Введите сумму")}
                      value={form.values.suggested_amounts[0]}
                      onChange={(val) => {
                        form.setFieldValue(
                          "suggested_amounts[0]",
                          val.replace(/[^0-9]/g, "")
                        );
                      }}
                      {...(form.values.suggested_amounts.filter(
                        (item) => item === form.values.suggested_amounts[0]
                      ).length > 1
                        ? {
                            error: !!form.errors.suggested_amounts,
                            helperText: form.errors.suggested_amounts as string,
                          }
                        : {})}
                    />
                  </Grid>
                  <Grid item md={2} sm={4}>
                    <TextInput
                      label={t("Сумма")}
                      placeholder={t("Введите сумму")}
                      value={form.values.suggested_amounts[1]}
                      onChange={(val) => {
                        form.setFieldValue(
                          "suggested_amounts[1]",
                          val.replace(/[^0-9]/g, "")
                        );
                      }}
                      {...(form.values.suggested_amounts.filter(
                        (item) => item === form.values.suggested_amounts[1]
                      ).length > 1
                        ? {
                            error: !!form.errors.suggested_amounts,
                            helperText: form.errors.suggested_amounts as string,
                          }
                        : {})}
                    />
                  </Grid>
                  <Grid item md={2} sm={4}>
                    <TextInput
                      label={t("Сумма")}
                      placeholder={t("Введите сумму")}
                      value={form.values.suggested_amounts[2]}
                      onChange={(val) => {
                        form.setFieldValue(
                          "suggested_amounts[2]",
                          val.replace(/[^0-9]/g, "")
                        );
                      }}
                      {...(form.values.suggested_amounts.filter(
                        (item) => item === form.values.suggested_amounts[2]
                      ).length > 1
                        ? {
                            error: !!form.errors.suggested_amounts,
                            helperText: form.errors.suggested_amounts as string,
                          }
                        : {})}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="body">
                      {t("Акцентный цвет")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <StyledColorBox
                      color={form.values.accent_color}
                      onClick={() => setShowPicker(true)}
                    />
                    {showPicker && (
                      <StyledPopover>
                        <StyledCover onClick={() => setShowPicker(false)} />
                        <SketchPicker
                          disableAlpha
                          color={form.values.accent_color}
                          onChange={(color) => {
                            form.setFieldValue("accent_color", color.hex);
                          }}
                        />
                      </StyledPopover>
                    )}
                  </Grid>
                </Grid>
                <Grid container hSpace={20} vSpace={10} my={30}>
                  <Grid item md={4} sm={12}>
                    <Link to="/services/tip-users">
                      <Button variant="outlined" fullwidth>
                        {t("Назад")}
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item md={4} sm={12} flex>
                    <Button
                      variant="contained"
                      fullwidth
                      onClick={() => setShowPreview(true)}
                    >
                      {t("Предпросмотр страницы")}
                    </Button>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <Button
                      variant="contained"
                      fullwidth
                      onClick={form.submitForm}
                    >
                      {t("Обновить")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Box style={{ backgroundColor: "#fff" }} p={20}>
                  <Grid container vSpace={20}>
                    <Grid item sm={12}>
                      <div>
                        <Typography variant="h5" mb={25} textAlign="center">
                          {t("Сканируй меня!")}
                        </Typography>
                        <StyledQrPlaceholderWrapper>
                          {qrUrl ? (
                            <QRCodeSVG size={214} value={qrUrl} />
                          ) : (
                            <QRPlaceholder />
                          )}
                        </StyledQrPlaceholderWrapper>
                        <Typography
                          variant="body"
                          fontSize={14}
                          mt={25}
                          mb={25}
                          textAlign="center"
                        >
                          {t("Тут вы можете оставить чаевые!")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item sm={12}>
                      <Typography variant="body" fontSize={12}>
                        {t("Ссылка на QR-код")}
                      </Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <TextInput
                        iconStart={<LinkIcon />}
                        value={qrUrl}
                        readOnly
                        onClick={() => handleCopy(qrUrl)}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Button
                        variant="outlined"
                        fullwidth
                        onClick={() => handleDownloadQr(String(id))}
                      >
                        {t("Сгенерировать QR-код")}
                      </Button>
                      {qrUrl && <QrDownloader qrUrl={qrUrl} id={String(id)} />}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Modal
          open={showPreview}
          onClose={() => setShowPreview(false)}
          hideHeader
        >
          <FakePayForm
            name={form.values.name}
            company_name={form.values.company_name}
            position={form.values.position}
            suggestedAmounts={form.values.suggested_amounts}
            avatar={imageUrl}
            onClose={() => setShowPreview(false)}
          />
        </Modal>
        <Modal
          open={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          hideHeader
          height={290}
        >
          <Box p={20}>
            <Grid container vSpace={20}>
              <Grid item sm={12}>
                <Box flex justifyContent="center">
                  <SuccessModal style={{ margin: "0 auto" }} />
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Typography
                  fontSize={20}
                  fontWeight="bold"
                  mb={15}
                  textAlign="center"
                >
                  {t("Менеджер обновлен успешно!")}
                </Typography>
                <Typography fontSize={16} textAlign="center">
                  {t("Вы можете его просмотреть в разделе Выставление счета")}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Button
                  variant="contained"
                  fullwidth
                  onClick={() => navigate("/services/tip-users")}
                >
                  {t("Перейти к выставлению счетов")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
    </Layout>
  );
};

const StyledColorBox = styled.div<{ color: string }>`
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  width: 40px;
  height: 26px;
  background-color: ${(props) => props.color};
  box-sizing: border-box;
  cursor: pointer;
`;

const StyledQrPlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPopover = styled.div`
  position: fixed;
  z-index: 2;
  top: 50%;
`;

const StyledCover = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

const StyledCommissionBlock = styled.div`
  background-color: #ddeee1;
  padding: 10px;
  display: flex;
  gap: 10px;
  font-size: 12px;
  height: 100%;
  box-sizing: border-box;
`;
