import FileSaver from "file-saver";
import { includes } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { privatApi } from "api/axios";
import {
  PayoutRegistriesQueryParams,
  REGISTRY_STATUS,
  useGetPayoutRegistriesQuery,
  useRegistryRetryFailedMutation,
  useRegistrySyncStatusMutation,
  useSaveAsTemplateMutation,
} from "api/baseAPI/payouts";
import { useGetUsersMePayoutsPermissionsQuery } from "api/baseAPI/user";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { dateFormatter } from "utils/date";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { visibleNumber } from "utils/numbers";
import { useUrlQuery } from "utils/url";
import { ReactComponent as RepeatNotSuccess } from "./icons/repeat-not-success.svg";
import { ReactComponent as SaveAsCsv } from "./icons/save-as-csv.svg";
import { ReactComponent as SaveAsTemplate } from "./icons/save-as-template.svg";
import { ReactComponent as StatusSuccess } from "./icons/status-success.svg";
import { ReactComponent as SyncStatus } from "./icons/sync-status.svg";

export const useRegistryStatuses = (): Record<
  keyof typeof REGISTRY_STATUS,
  string
> => {
  const { t } = useTranslation();
  return {
    0: t("Создан"),
    1: t("В обработке"),
    2: t("Обработан"),
    3: t("Ошибка"),
  };
};

const initParameters = {
  page: "1",
  page_size: "10",
  point_id: "",
};

export const RegistryList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openedRow, setOpenedRow] = useState<number | null>(null);
  const GET_REGISTRY_STATUS = useRegistryStatuses();

  const selectedPointManager = useSelectedPointManager();
  const getPayoutsPermissionsQuery = useGetUsersMePayoutsPermissionsQuery();

  const { queryParams, querySetters } =
    useUrlQuery<PayoutRegistriesQueryParams>(initParameters);

  const { page, page_size } = queryParams;

  const { set_page, set_page_size } = querySetters;

  const { data, isFetching } = useGetPayoutRegistriesQuery({
    ...queryParams,
    point_id: String(selectedPointManager.point?.id),
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const [registryRetryFailed, registryRetryFailedMeta] =
    useRegistryRetryFailedMutation();
  const [registrySyncStatus, registrySyncStatusMeta] =
    useRegistrySyncStatusMutation();
  const [saveAsTemplate, saveAsTemplateMeta] = useSaveAsTemplateMutation();

  useSuccessNotification([registrySyncStatusMeta]);
  useSuccessNotification([registryRetryFailedMeta]);
  useErrorNotification([saveAsTemplateMeta]);
  useErrorNotification([registryRetryFailedMeta]);
  useErrorNotification([registrySyncStatusMeta]);

  useEffect(() => {
    if (saveAsTemplateMeta.isSuccess && saveAsTemplateMeta.data.id) {
      navigate({
        pathname: "/payout-templates",
        search: `?template_id=${saveAsTemplateMeta.data.id}`,
      });
    }
  }, [saveAsTemplateMeta]);

  const fetchExport = async (id: number) => privatApi<void>(
      `/retriever/v0/payouts/export-to-file/`,
      "POST",
      {
        filters: {
          registry_id: id,
        },
        export_type: 2,
      },
      {
        responseType: "blob",
      }
    );

  const handleDownload = async (id: number) => {
    try {
      const response = await fetchExport(id);

      if (response.status  ===  204) {
        return;
      }
      let filename = "file.csv";
      try {
        const reg = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          response?.headers?.["content-disposition"] || ""
        );
        if (reg) {
          filename = reg[1].replace(/['"]/g, "");
        }
      } catch (e) {
        console.error("Error", e);
      }

      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        FileSaver.saveAs(response.data, filename);
      } catch (e) {
        console.error("Error", e);
        toast.error(t("Ошибка"));
      }
    } catch (err) {
      toast.error(t("Ошибка"));
      throw err;
    }
  };

  return (
    <Table
      tableCellTitles={[
        t("ID"),
        t("Выплаты"),
        t("Успешных"),
        t("Неуспешных"),
        t("Время"),
        t("Статус"),
      ]}
      count={data ? data.count : 0}
      rowsPerPage={Number(page_size)}
      page={Number(page)}
      setPage={handleSetPage}
      setRowsPerPage={handleSetRowsPerPage}
      isLoading={isFetching}
    >
      {data?.results.map((item) => (
        <React.Fragment key={item.id}>
          <StyledRow>
            <StyledCell>
              <Box flex alignItems="center" nowrap>
                <IconCellOpen
                  open={openedRow === item.id}
                  onClick={() =>
                    openedRow === item.id
                      ? setOpenedRow(null)
                      : setOpenedRow(item.id)
                  }
                />
                {item.id}
              </Box>
            </StyledCell>
            <StyledCell>{item.number_of_payouts}</StyledCell>
            <StyledCell>{item.number_of_successful_payouts}</StyledCell>
            <StyledCell>{item.number_of_failed_payouts}</StyledCell>
            <StyledCell>{dateFormatter.format(item.created_at)}</StyledCell>
            <StyledCell>
              {item.status === 1 && <StatusSuccess />}{" "}
              {GET_REGISTRY_STATUS[item.status]}
            </StyledCell>
            <StyledCell key="actions" align="right">
              <TableRowActions
                long
                actions={[
                  ...(includes(
                    getPayoutsPermissionsQuery.data,
                    "add_payouttemplate"
                  )
                    ? [
                        <MenuItemAction
                          key={0}
                          onClick={() =>
                            saveAsTemplate({
                              id: item.id,
                              point_manager_id: selectedPointManager.id,
                            })
                          }
                        >
                          <SaveAsTemplate />
                          {t("Сохранить как шаблон")}
                        </MenuItemAction>,
                      ]
                    : []),
                  <MenuItemAction
                    key={1}
                    onClick={() => handleDownload(item.id)}
                  >
                    <SaveAsCsv />
                    {t("Сохранить как CSV")}
                  </MenuItemAction>,
                  <MenuItemAction
                    key={4}
                    onClick={() => registrySyncStatus({ id: item.id })}
                  >
                    <SyncStatus />
                    {t("Синхронизировать статус")}
                  </MenuItemAction>,
                  ...(!!item.number_of_failed_payouts &&
                  item.status !== 1 &&
                  includes(
                    getPayoutsPermissionsQuery.data,
                    "change_payouttemplate"
                  )
                    ? [
                        <MenuItemAction
                          key={3}
                          onClick={() => registryRetryFailed({ id: item.id })}
                        >
                          <RepeatNotSuccess />
                          {t("Повторить повторно неуспешные")}
                        </MenuItemAction>,
                      ]
                    : []),
                ]}
              />
            </StyledCell>
          </StyledRow>
          {openedRow === item.id && (
            <StyledRow>
              <StyledCell colSpan={7}>
                <Grid container hSpace={16} vSpace={16}>
                  <Grid item sm={12} md={4}>
                    <Box style={{ border: "1px solid #C8CFDC" }} p={16}>
                      <Typography variant="body" mb={12}>
                        {t("ID Первичного реестра")}
                      </Typography>
                      <Typography variant="body">{item.id}</Typography>
                    </Box>
                  </Grid>

                  <Grid item sm={12} md={4}>
                    <Box style={{ border: "1px solid #C8CFDC" }} p={16}>
                      <Typography variant="body" mb={12}>
                        {t("Сумма успешных транзакций")}
                      </Typography>
                      <Typography variant="body">
                        {visibleNumber(item.amount_of_successful_payouts)}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item sm={12} md={4}>
                    <Box style={{ border: "1px solid #C8CFDC" }} p={16}>
                      <Typography variant="body" mb={12}>
                        {t("Сумма неуспешных транзакций")}
                      </Typography>
                      <Typography variant="body">
                        {visibleNumber(item.amount_of_failed_payouts)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </StyledCell>
            </StyledRow>
          )}
        </React.Fragment>
      ))}
    </Table>
  );
}
