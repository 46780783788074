import React from "react";
import { useTranslation } from "react-i18next";
import { useGetTransactionServiceFieldsViewQuery } from "api/baseAPI/transactions";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { getColors } from "utils/getColors";

type Props = {
  id: number;
};

export const TransactionServiceFields = ({ id }: Props): React.ReactElement => {
  const { t } = useTranslation();

  const { pageBackground } = getColors();

  const tableCellTitles = [t("Поле транзакции"), t("Значение")];

  const { data, isFetching } = useGetTransactionServiceFieldsViewQuery({ id });

  return (
    <Table
      tableCellTitles={tableCellTitles}
      isLoading={isFetching}
      count={data ? data.length : 0}
      page={1}
      rowsPerPage={3}
      disabledPagination
      maxHeight={200}
    >
      {data?.map((row) => (
        <StyledRow key={row.id} background={pageBackground}>
          <StyledCell>{row.field_slug}</StyledCell>
          <StyledCell>{row.field_value}</StyledCell>
        </StyledRow>
      ))}
    </Table>
  );
};
