import { FormikProps } from "formik";
import { find, isEmpty, isString, last } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { SketchPicker } from "react-color";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import { useGetCustomizationsQuery } from "api/baseAPI/invoicing";
import { LinearProgress } from "components/LinearProgress";
import { getColors } from "utils/getColors";
import { ReactComponent as CloseIcon } from "utils/img/close.svg";
import { ReactComponent as ImageIcon } from "utils/img/image.svg";
import { ReactComponent as UploadIcon } from "utils/img/upload.svg";
import {
  CustomizationsFormValues,
  PagesFormValues,
} from "pages/invoicing/pages/PageCrete";
import { ModalPagePreview } from "./ModalPagePreview";
import { PayUrlInfo } from "./PayUrlInfo";

type Props = {
  customSubmit: () => void;
  pageSubmit: () => void;
  formPage: FormikProps<PagesFormValues>;
  form: FormikProps<CustomizationsFormValues>;
  pay_url: string;
  setSelectedFile: Dispatch<SetStateAction<File | undefined>>;
  selectedFile: File | undefined;
  setImageUrl: Dispatch<SetStateAction<string>>;
  imageUrl: string;
  isTemplate?: boolean;
};

export const SecondStep = (props: Props) => {
  const {
    pageSubmit,
    form,
    formPage,
    pay_url,
    selectedFile,
    setImageUrl,
    setSelectedFile,
    imageUrl,
    isTemplate,
  } = props;
  const { t } = useTranslation();
  const [showWrapper, setShowWrapper] = useState<boolean>(false);
  const [openPicker, setOpenPicker] = useState<
    keyof CustomizationsFormValues | ""
  >("");

  const { pageBackground } = getColors();

  const customizationsQuery = useGetCustomizationsQuery({
    page_size: "500",
    is_public: "true",
  });

  const handleChangeFile = async (fileList: File[]) => {
    if (!fileList) return;
    const file = fileList[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result && isString(reader.result)) {
        setImageUrl(reader.result);
      } else {
        setImageUrl("");
      }
    };
    reader.readAsDataURL(file);
    setSelectedFile(file);
  };

  const handleDeleteFile = () => {
    setSelectedFile(undefined);
    setImageUrl("");
    form.setFieldValue("logo", "");
  };

  const inputSelectColor = (
    name: keyof CustomizationsFormValues,
    label: string
  ) => (
    <TextInput
      name={name}
      label={label}
      placeholder={t("Выберите цвет")}
      value={form.values[name]}
      onChange={form.handleChange}
      size="small"
      onClick={() => setOpenPicker(name)}
      iconEnd={<ColorView style={{ backgroundColor: form.values[name] }} />}
    />
  );

  const handleChangeStyles = (value: string) => {
    if (value && !isEmpty(customizationsQuery?.data?.results)) {
      const findElement = find(customizationsQuery?.data?.results, {
        id: value,
      });
      form.setFieldValue(
        "background_color", // @ts-ignore
        findElement?.background_color || ""
      );
      form.setFieldValue(
        "button_color", // @ts-ignore
        findElement?.button_color || ""
      ); // @ts-ignore
      form.setFieldValue("font", findElement?.font || ""); // @ts-ignore
      form.setFieldValue("font_color", findElement?.font_color || ""); // @ts-ignore
      form.setFieldValue(
        "form_accent_color", // @ts-ignore
        findElement?.form_accent_color || ""
      ); // @ts-ignore
      form.setFieldValue("form_color", findElement?.form_color || "");
    }
  };

  return (
    <>
      {((!pay_url && !isTemplate) || customizationsQuery.isLoading) && (
        <Box mb={10}>
          <LinearProgress />
        </Box>
      )}
      {(pay_url || isTemplate) && !customizationsQuery.isLoading && (
        <Grid container pt={24} hSpace={24} smHSpace={1}>
          <Grid item sm={12} md={8}>
            <Typography variant="h5" mb={24}>
              {t("Создать свой стиль страницы")}
            </Typography>
            <Typography mb={12}>{t("Загрузка логотипа")}</Typography>
            <Grid container hSpace={24} vSpace={24} smHSpace={1}>
              <Grid item sm={12}>
                <Dropzone
                  onDrop={handleChangeFile}
                  accept={{ "image/*": [] }}
                  maxSize={625000}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <Box
                        p={16}
                        style={{
                          border: "1px dashed rgba(44, 96, 176, 0.4)",
                          background: "#FFFFFF",
                          cursor: "pointer",
                        }}
                      >
                        <input {...getInputProps()} />
                        <Box
                          flex
                          alignItems="center"
                          justifyContent="center"
                          mb={12}
                        >
                          <BoxIcon background={pageBackground}>
                            <UploadIcon />
                          </BoxIcon>
                          <Typography variant="buttonSmall" textAlign="center">
                            {t(
                              "Нажмите или перетащите файлы для загрузки"
                            ).toLocaleUpperCase()}
                          </Typography>
                        </Box>
                        <Box flex justifyContent="center">
                          <Typography variant="subtitle3">
                            {t("Формат файла")}
                          </Typography>
                          <>&nbsp;</>
                          <Typography
                            variant="subtitle3"
                            style={{ color: "#838383" }}
                          >
                            {" png, jpg, gif "}
                          </Typography>
                        </Box>
                        <Box flex justifyContent="center">
                          <Typography variant="subtitle3">
                            {t("Размеры в px")}
                          </Typography>
                          <>&nbsp;</>
                          <Typography
                            variant="subtitle3"
                            style={{ color: "#838383" }}
                          >
                            250x100, 400x100, 250x150, 350x75
                          </Typography>
                        </Box>
                        <Box flex justifyContent="center">
                          <Typography variant="subtitle3">
                            {t("Вес")}
                          </Typography>
                          <>&nbsp;</>
                          <Typography
                            variant="subtitle3"
                            style={{ color: "#838383" }}
                          >
                            {`${t("не более")} 5mb`}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  )}
                </Dropzone>
              </Grid>
              {selectedFile || imageUrl ? (
                <Grid item sm={12}>
                  <Box
                    flex
                    justifyContent="space-between"
                    alignItems="center"
                    p={16}
                    style={{ border: "1px solid #C8CFDC" }}
                  >
                    <Box flex alignItems="center">
                      <Box
                        mr={8}
                        flex
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          border: "1px solid #C8CFDC",
                          width: 35,
                          height: 35,
                        }}
                      >
                        <ImageIcon />
                      </Box>
                      <Typography>
                        {selectedFile?.name ||
                          last(imageUrl.split("?")?.[0].split("/")) ||
                          ""}
                      </Typography>
                    </Box>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteFile()}
                    />
                  </Box>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item sm={12} md={6}>
                {inputSelectColor("background_color", t("Цвет фона страницы"))}
              </Grid>
              <Grid item sm={12} md={6}>
                {inputSelectColor("form_color", t("Цвет фона формы"))}
              </Grid>
              <Grid item sm={12} md={6}>
                <Select
                  value={form.values.font}
                  onChange={(value) => form.setFieldValue("font", value)}
                  label={t("Шрифт")}
                  placeholder={t("Выберите шрифт")}
                  size="small"
                  options={[
                    { value: "Montserrat", label: "Montserrat" },
                    { value: "Open Sans", label: "Open Sans" },
                    { value: "Rubik", label: "Rubik" },
                    { value: "Oswald", label: "Oswald" },
                    { value: "Noto Sans", label: "Noto Sans" },
                    { value: "Inter", label: "Inter" },
                    { value: "Mulish", label: "Mulish" },
                    { value: "IBM Plex Sans", label: "IBM Plex Sans" },
                    { value: "Jost", label: "Jost" },
                  ]}
                  error={form.touched.font && Boolean(form.errors.font)}
                  helperText={form.touched.font && form.errors.font}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                {inputSelectColor("font_color", t("Цвет шрифта"))}
                {openPicker && (
                  <StyledPopover>
                    <StyledCover onClick={() => setOpenPicker("")} />
                    <SketchPicker
                      disableAlpha
                      color={form.values[openPicker]}
                      onChange={(color) => {
                        form.setFieldValue(openPicker, color.hex);
                      }}
                    />
                  </StyledPopover>
                )}
              </Grid>
              <Grid item sm={12} md={6}>
                {inputSelectColor("button_color", t("Цвет кнопки"))}
              </Grid>
              <Grid item sm={12} md={6}>
                {inputSelectColor("form_accent_color", t("Цвет обводки"))}
              </Grid>
              <Grid item sm={12} md={6}>
                <Select
                  value=""
                  onChange={(value) => handleChangeStyles(value)}
                  placeholder={t("Список стандартных стилей")}
                  size="small"
                  options={
                    customizationsQuery?.data?.results?.map((item) => ({
                      value: String(item.id),
                      label: item.title,
                    })) || []
                  }
                />
              </Grid>
              <Grid item sm={12} md={6} flex>
                <Button
                  variant="outlined"
                  fullwidth
                  onClick={() => setShowWrapper(true)}
                >
                  {t("Предпросмотр страницы")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {!isTemplate ? (
            <Grid item sm={12} md={4}>
              <PayUrlInfo payUrl={pay_url} handleSave={() => pageSubmit()} />
            </Grid>
          ) : (
            <></>
          )}

          <Modal
            open={showWrapper}
            hideHeader
            width={400}
            onClose={() => setShowWrapper(false)}
          >
            <ModalPagePreview
              formPage={formPage}
              form={form}
              onClose={() => setShowWrapper(false)}
              imageUrl={imageUrl}
            />
          </Modal>
        </Grid>
      )}
    </>
  );
};
const StyledCover = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;
const StyledPopover = styled.div`
  position: fixed;
  z-index: 2;
  top: 50%;
`;
export const BoxIcon = styled.div`
  background: ${({ background }: { background: string }) => background};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  svg {
    width: 8px !important;
    height: 12px !important;
    path {
      fill: #2c60b0 !important;
    }
  }
`;
const ColorView = styled.div`
  width: 40px;
  height: 23px;
  cursor: default;
  border: 1px solid;
  padding: 1px 2px;
  margin-top: -3px;
`;
