import { Route, Routes } from "react-router-dom";

import { Pages } from "./pages/Pages";
import { PageCrete } from "./pages/PageCrete";
import { Page } from "./pages/Page";
import { PageEdit } from "./pages/PageEdit";
import { Transactions } from "./transactions/Transactions";
import { Templates } from "./templates/Templates";
import { PageTemplateCrete } from "./templates/PageTemplateCrete";
import { PageTemplateEdit } from "./templates/PageTemplateEdit";

const Router = () => (
  <Routes>
    <Route path="pages" element={<Pages />} />
    <Route path="pages/create" element={<PageCrete />} />
    <Route path="pages/:pageId/edit" element={<PageEdit />} />
    <Route path="pages/:pageId" element={<Page />} />

    <Route path="page-transactions" element={<Transactions />} />

    <Route path="page-templates" element={<Templates />} />
    <Route path="page-templates/create" element={<PageTemplateCrete />} />
    <Route path="page-templates/:pageId/edit" element={<PageTemplateEdit />} />
  </Routes>
);

export default Router;
