export type JustifyContent = {
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around";
};

export function justifyContentProps(props: JustifyContent) {
  return (({ justifyContent }) => ({
    justifyContent,
  }))(props);
}

export function justifyContentStyle(props: JustifyContent) {
  return `
    ${
      props.justifyContent
        ? `justify-content: ${  props.justifyContent  };`
        : ""
    }
  `;
}
