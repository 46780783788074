import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as PlusOutlinedIcon } from "utils/img/plus-outlined.svg";
import { ReactComponent as UploadIcon } from "utils/img/upload.svg";
import { AddPayoutToTemplateForm } from "pages/payout/parts/AddPayoutToTemplateForm";
import { FileReader } from "pages/payout/parts/FileReader";
import { ReactComponent as SplitPayoutsModalInfo } from "pages/payout/parts/icons/SplitPayoutsModalInfo.svg";
import { IPayoutMethod } from "pages/payout/logic/payoutMethods";
import { PayoutData } from "pages/payout/logic/payoutsCreateLogic";

export const PayoutFormWrapper = ({
  payout,
  payoutsData,
  children,
  resetForm,
  setNeedSplitPayouts,
  setShowAddToTemplateModal,
  showAddToTemplateModal,
  needSplitPayouts,
  handleSplitPayouts,
  handleUpload,
  isSplitting,
  addRow,
}: {
  payout: IPayoutMethod;
  payoutsData: Record<string, PayoutData>;
  children: ReactElement;
  resetForm: () => void;
  showAddToTemplateModal: boolean;
  setShowAddToTemplateModal: (value: boolean) => void;
  needSplitPayouts: boolean;
  setNeedSplitPayouts: (value: boolean) => void;
  handleSplitPayouts: () => void;
  isSplitting: boolean;
  addRow: () => void;
  handleUpload: (files: FileList | null) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {children}

      <Grid container justifyContent="space-between" vSpace={24}>
        <Grid item sm={12} lg={5.9} xxl="content">
          <Button fullwidth onClick={addRow} iconStart={<PlusOutlinedIcon />}>
            {t("Добавить платеж")}
          </Button>
        </Grid>
        <Grid item sm={12} lg={5.9} xxl="content" style={{ maxWidth: "100%" }}>
          <FileReader
            multipleFiles={false}
            fileTypes={[".csv", ".xlsx"]}
            handleFiles={(files) => handleUpload(files)}
          >
            <Button
              fullwidth
              onClick={() => console.warn("Start upload file")}
              iconStart={<UploadIcon />}
            >
              {t("Загрузить реестр")}
            </Button>
          </FileReader>
        </Grid>
        <Grid item sm={12} lg={5.9} xxl="content">
          <Button
            fullwidth
            variant="outlined"
            onClick={resetForm}
            disabled={Object.entries(payoutsData).length < 1}
            iconStart={<DeleteIcon />}
          >
            {t("Очистить все")}
          </Button>
        </Grid>
      </Grid>

      <Modal
        open={needSplitPayouts}
        hideHeader
        width={490}
        height={330}
        onClose={() => setNeedSplitPayouts(false)}
      >
        <Grid container p={24}>
          <Grid item sm="fill" mb={24} flex justifyContent="center">
            <SplitPayoutsModalInfo />
          </Grid>
          <Grid item sm={12} mb={8}>
            <Typography
              variant="body"
              fontSize={20}
              fontWeight="bold"
              textAlign="center"
            >
              {t("Платежи будут разбиты на составляющие")}
            </Typography>
          </Grid>
          <Grid item sm={12} mb={40}>
            <Typography
              variant="body"
              fontSize={16}
              fontWeight="normal"
              textAlign="center"
            >
              {t(
                "Чтобы оплатить эту сумму, платеж нужно разделить на несколько платежей"
              )}
            </Typography>
          </Grid>
          <Grid item sm="fill">
            <Box flex justifyContent="space-around">
              <Button
                variant="outlined"
                onClick={() => setNeedSplitPayouts(false)}
              >
                {t("Отмена")}
              </Button>
              <Button
                variant="contained"
                onClick={() => handleSplitPayouts()}
                isLoading={isSplitting}
              >
                {t("Подтвердить")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Modal>

      {payout.pointManager ? (
        <Modal
          open={showAddToTemplateModal}
          hideHeader
          width={490}
          height={330}
          maxHeight="80%"
          onClose={() => setShowAddToTemplateModal(false)}
        >
          <AddPayoutToTemplateForm
            payoutType={payout.kind}
            pointManagerId={payout.pointManager?.id}
            payouts={payoutsData}
            currency={payout.currency || ""}
            onCancel={() => {
              setShowAddToTemplateModal(false);
            }}
            configKey={payout?.configKey || ""}
          />
        </Modal>
      ) : null}
    </>
  );
}
