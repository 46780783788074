import { AnyAction, combineReducers, Reducer } from "@reduxjs/toolkit";
import { baseSplitApi } from "api/baseAPI";
import auth from "./authSlice";
import basic from "./basicSlice";

const combinedReducer = combineReducers({
  auth,
  basic,
  [baseSplitApi.reducerPath]: baseSplitApi.reducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "auth/removeAuth") {
    // eslint-disable-next-line no-param-reassign
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof combinedReducer>;
