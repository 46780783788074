import { includes, isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  useDeletePointManagerMutation,
  useGetPointManagersQuery,
} from "api/baseAPI/pointManagers";
import {
  useGetUsersMePointManagerPermissionsQuery,
  useGetUsersMeUserPermissionsQuery,
  useGetUsersSelfQuery,
  useLazyGetUsersNewPasswordQuery,
} from "api/baseAPI/user";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  IconColor,
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowsReloadIcon } from "utils/img/arrows-reload.svg";
import { ReactComponent as CircleCheckIcon } from "utils/img/circle-check.svg";
import { ReactComponent as CircleCloseIcon } from "utils/img/circle-close.svg";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { ModalSuccessNewPassword } from "./parts/ModalSuccessNewPassword";
import { PointManagerForm } from "./parts/PointManagerForm";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
};

export const PointManagers = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Управление"), ""],
    [t("Доступы"), ""],
  ];

  const tableCellTitles = [
    t("ID"),
    t("Пользователь"),
    t("Админ"),
    t("Уровень доступа"),
  ];

  const [openId, setOpenId] = useState<number>(0);
  const [openModalNewPassword, setOpenModalNewPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editId, setEditId] = useState<number>(0);
  const selectedPointManager = useSelectedPointManager();
  const getPointManagerPermissions =
    useGetUsersMePointManagerPermissionsQuery();
  const getUserPermissions = useGetUsersMeUserPermissionsQuery();
  const getUsersSelfQuery = useGetUsersSelfQuery();
  const [getNewPassword, getNewPasswordMeta] =
    useLazyGetUsersNewPasswordQuery();

  const { pageBackground } = getColors();

  const { queryParams, querySetters } = useUrlQuery(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const { data, isFetching, refetch } = useGetPointManagersQuery({
    ...queryParams,
    point_id: selectedPointManager.point?.id,
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const handleOpen = (id: number) => {
    setOpenId(openId === id ? 0 : id);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditId(0);
  };

  const [deletePointManager, deletePointManagerMeta] =
    useDeletePointManagerMutation();

  const handleDeletePointManager = (id: number) => {
    deletePointManager({ id });
  };

  useSuccessNotification([deletePointManagerMeta]);
  useErrorNotification([deletePointManagerMeta]);

  useEffect(() => {
    if (getNewPasswordMeta?.isSuccess && getNewPasswordMeta?.data?.password) {
      setOpenModalNewPassword(true);
    }
  }, [getNewPasswordMeta]);

  return (
    <Layout title={t("Доступы")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              createTitle={t("Выдать доступ")}
              {...(includes(getPointManagerPermissions.data, "add")
                ? {
                    createAction: () => setOpenModal(true),
                  }
                : {})}
              refetch={refetch}
            />
          }
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data && data.results.length > 0 ? (
            data.results.map((row) => (
              <React.Fragment key={row.id}>
                <StyledRow>
                  <StyledCell>
                    <Box flex alignItems="center" nowrap>
                      <IconCellOpen
                        open={openId === row.id}
                        onClick={() => handleOpen(row.id)}
                      />
                      <>{row.id}</>
                    </Box>
                  </StyledCell>
                  <StyledCell>{row.user?.username}</StyledCell>
                  <StyledCell>
                    <IconColor color={row.is_admin ? "#3ea959" : "#d00025"}>
                      {row.is_admin ? <CircleCheckIcon /> : <CircleCloseIcon />}
                    </IconColor>
                  </StyledCell>
                  <StyledCell>{row.access_level?.name}</StyledCell>
                  <StyledCell key="actions" align="right">
                    <TableRowActions
                      actions={[
                        ...(includes(getPointManagerPermissions.data, "change")
                          ? [
                              <MenuItemAction
                                key={2}
                                onClick={() => {
                                  setOpenModal(true);
                                  setEditId(row.id);
                                }}
                              >
                                <EditIcon /> &nbsp;{t("Редактировать")}
                              </MenuItemAction>,
                            ]
                          : []),
                        ...(includes(getPointManagerPermissions.data, "delete")
                          ? [
                              <MenuItemAction
                                key={3}
                                onClick={() => handleDeletePointManager(row.id)}
                              >
                                <DeleteIcon /> &nbsp;{t("Удалить")}
                              </MenuItemAction>,
                            ]
                          : []),
                        ...(includes(
                          getUserPermissions.data,
                          "reset_password"
                        ) &&
                        row?.user?.account_id &&
                        row.user.account_id ===
                          getUsersSelfQuery?.data?.account?.id
                          ? [
                              <MenuItemAction
                                key={3}
                                onClick={() => getNewPassword(row.user.id)}
                              >
                                <ArrowsReloadIcon /> &nbsp;
                                {t("Восстановить пароль")}
                              </MenuItemAction>,
                            ]
                          : []),
                      ]}
                    />
                  </StyledCell>
                </StyledRow>
                {openId === row.id && (
                  <StyledRow>
                    <td colSpan={5}>
                      <Grid container p={16} hSpace={24}>
                        <Grid item sm={6}>
                          <Typography variant="subtitle3" mb={8}>
                            {t("Доступные пункты меню")}
                          </Typography>
                          {!isEmpty(row.available_components) ? (
                            <Box style={{ maxHeight: 210, overflowY: "auto" }}>
                              {map(row.available_components || [], (item) => (
                                <Box
                                  px={8}
                                  py={12}
                                  mb={4}
                                  key={item.id}
                                  style={{ background: pageBackground }}
                                >
                                  <Typography variant="subtitle3">
                                    {item.display_name}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="subtitle3" mb={8}>
                            {t("Доступные кошельки")}
                          </Typography>
                          {!isEmpty(row.available_wallets) ? (
                            <Box style={{ maxHeight: 210, overflowY: "auto" }}>
                              {map(row.available_wallets || [], (item) => (
                                <Box
                                  px={8}
                                  py={12}
                                  mb={4}
                                  key={item.id}
                                  style={{ background: pageBackground }}
                                >
                                  <Typography variant="subtitle3">
                                    {`${item.id} | ${item.name}`}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </td>
                  </StyledRow>
                )}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </Table>
        {openModal ? (
          <PointManagerForm onClose={handleCloseModal} editId={editId} />
        ) : (
          <></>
        )}
        {openModalNewPassword ? (
          <ModalSuccessNewPassword
            onClose={() => setOpenModalNewPassword(false)}
            password={getNewPasswordMeta?.data?.password || ""}
          />
        ) : (
          <></>
        )}
      </Box>
    </Layout>
  );
};
