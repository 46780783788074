export const mixColors = (
  backgroundColor: string,
  primaryColor: string,
  weight: number
) => {
  function d2h(d: number) {
    return d.toString(16);
  } // convert a decimal value to hex
  function h2d(h: string) {
    return parseInt(h, 16);
  } // convert a hex value to decimal

  const resultWeight = typeof weight !== "undefined" ? weight : 50; // set the weight to 50%, if that argument is omitted

  let color = "#";

  for (let i = 0; i <= 5; i += 2) {
    // loop through each of the 3 hex pairs—red, green, and blue
    const v1 = h2d(backgroundColor.replace("#", "").substr(i, 2)); // extract the current pairs
    const v2 = h2d(primaryColor.replace("#", "").substr(i, 2));
    let val = d2h(Math.floor(v2 + (v1 - v2) * (resultWeight / 100.0)));

    while (val.length < 2) {
      val = `0${  val}`;
    } // prepend a '0' if val results in a single digit
    color += val; // concatenate val to our new color string
  }

  return color; // PROFIT!
};

export function hexToRGB(hex: string, alpha?: number) {
  const rgb = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16));
  return `rgba(${rgb?.[0]},${rgb?.[1]},${rgb?.[2]},${alpha || 1})`;
}
