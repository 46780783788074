import { includes } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Typography } from "UI/Typography";
import {
  useDeleteThemeMutation,
  useGetThemesQuery,
} from "api/baseAPI/customizer";
import { useGetUsersMeCustomizationThemePermissionsQuery } from "api/baseAPI/user";
import { FilterBar } from "components/table/FilterBar";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { dateFormatter } from "utils/date";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { ReactComponent as EyeIcon } from "utils/img/eye-full.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { ModalThemePreview } from "./ModalThemePreview";

export const Themes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableCellTitles = [t("ID"), t("Название темы"), t("Обновлено")];

  const [page, setPage] = useState<string>("1");
  const [pageSize, setPageSize] = useState<string>("10");
  const [search, setSearch] = useState<string>("");
  const [previewId, setPreviewId] = useState<number | null>(null);
  const selectedPointManager = useSelectedPointManager();
  const getThemePermissions = useGetUsersMeCustomizationThemePermissionsQuery();

  const { data, isFetching, refetch } = useGetThemesQuery({
    page,
    page_size: pageSize,
    search,
    point_id: selectedPointManager.point?.id,
  });

  const handleSetPage = (pageVal: number) => {
    setPage(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    setPage("1");
    setPageSize(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    setPage("1");
    setSearch(searchVal);
  };

  const [deleteTheme, deleteThemeMeta] = useDeleteThemeMutation();

  const handleDeleteTheme = (id: number) => {
    deleteTheme({ id });
  };

  useSuccessNotification([deleteThemeMeta]);
  useErrorNotification([deleteThemeMeta]);

  return (
    <Box py={32} px={24}>
      {previewId && (
        <ModalThemePreview
          onClose={() => setPreviewId(null)}
          themeID={previewId}
        />
      )}
      <Typography variant="h5" mb={16}>
        {t("Темы")}
      </Typography>
      <Table
        tableCellTitles={tableCellTitles}
        isLoading={isFetching}
        filterBar={
          <FilterBar
            search={search}
            setSearch={handleSetSearch}
            createTitle={t("Добавить тему")}
            {...(includes(getThemePermissions.data, "add")
              ? {
                  createPath: "/admin/customizer/themes/create",
                }
              : {})}
            refetch={refetch}
          />
        }
        count={data ? data.count : 0}
        page={Number(page)}
        rowsPerPage={Number(pageSize)}
        setPage={handleSetPage}
        setRowsPerPage={handleSetRowsPerPage}
      >
        {data?.results.map((row) => (
          <StyledRow key={row.id}>
            <StyledCell>{row.id}</StyledCell>
            <StyledCell>{row.name}</StyledCell>
            <StyledCell>{dateFormatter.format(row.updated_at)}</StyledCell>
            <StyledCell key="actions" align="right">
              <Box
                flex
                alignItems="center"
                justifyContent="flex-end"
                nowrap
                mr={20}
              >
                <StyledEyeIcon onClick={() => setPreviewId(row.id)} />
                <TableRowActions
                  actions={[
                    ...(includes(getThemePermissions.data, "change")
                      ? [
                          <MenuItemAction
                            key={2}
                            onClick={() =>
                              navigate(
                                `/admin/customizer/themes/${row.id}/edit`
                              )
                            }
                          >
                            <EditIcon style={{ width: "22px" }} /> &nbsp;
                            {t("Редактировать")}
                          </MenuItemAction>,
                        ]
                      : []),
                    ...(includes(getThemePermissions.data, "delete")
                      ? [
                          <MenuItemAction
                            key={3}
                            onClick={() => handleDeleteTheme(row.id)}
                          >
                            <DeleteIcon style={{ width: "22px" }} /> &nbsp;
                            {t("Удалить")}
                          </MenuItemAction>,
                        ]
                      : []),
                    ...(includes(getThemePermissions.data, "view")
                      ? [
                          <MenuItemAction
                            key={3}
                            onClick={() => setPreviewId(row.id)}
                          >
                            <EyeIcon style={{ width: "22px" }} /> &nbsp;
                            {t("Просмотр темы")}
                          </MenuItemAction>,
                        ]
                      : []),
                  ]}
                />
              </Box>
            </StyledCell>
          </StyledRow>
        ))}
      </Table>
    </Box>
  );
};

const StyledEyeIcon = styled(EyeIcon)`
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
`;
