import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PointManagerItem } from "api/baseAPI/pointManagers";
import { Banner } from "api/baseAPI/user";
import { Wallet } from "api/baseAPI/wallets";
import { RootState } from "./index";

type SliceState = {
  selectedPointManager: PointManagerItem;
  selectedWallet: { id: number; name: string };
  walletInfo: Omit<Wallet, "account">;
  banner: Banner | null;
};

const initialState = {
  selectedPointManager: {},
  selectedWallet: {},
  walletInfo: {},
  banner: {},
} as SliceState;

const basicSlice = createSlice({
  name: "basic",
  initialState,
  reducers: {
    setSelectedPointManager: (
      state: SliceState,
      action: PayloadAction<SliceState["selectedPointManager"]>
    ) => {
      window.localStorage.setItem(
        "point_manager",
        JSON.stringify(action.payload)
      );
      state.selectedPointManager = { ...action.payload };
    },
    setSelectedWallet: (
      state: SliceState,
      action: PayloadAction<SliceState["selectedWallet"]>
    ) => {
      window.localStorage.setItem(
        "selected_wallet",
        JSON.stringify(action.payload)
      );
      state.selectedWallet = { ...action.payload };
    },
    setWalletInfo: (
      state: SliceState,
      action: PayloadAction<SliceState["walletInfo"]>
    ) => {
      state.walletInfo = { ...action.payload };
    },
    setBanner: (
      state: SliceState,
      action: PayloadAction<SliceState["banner"]>
    ) => {
      state.banner = action.payload?.id ? { ...action.payload } : null;
    },
  },
});

export const {
  setSelectedPointManager,
  setSelectedWallet,
  setWalletInfo,
  setBanner,
} = basicSlice.actions;

export default basicSlice.reducer;

export const selectCurrentSelectedPointManager = (state: RootState) =>
  state.basic.selectedPointManager;
export const selectCurrentSelectedWalletManager = (state: RootState) =>
  state.basic.selectedWallet;
export const selectCurrentWalletInfoManager = (state: RootState) =>
  state.basic.walletInfo;
export const selectCurrentBanner = (state: RootState) => state.basic.banner;
