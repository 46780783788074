import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { getTimezone } from "utils/getTimezone";
import { RESPONSIVE_SIZES } from "utils/tools";
import { CalendarDay } from "./CalendarDay";
import { ReactComponent as ChevronLeft } from "./assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "./assets/chevron-right.svg";

export const CalendarMonth = ({
  rangeStart,
  rangeEnd,
  onDateSet,
  decreeseYearHandler,
  increeseYearHandler,
  displayedYear,
}: {
  rangeStart: DateTime | null;
  rangeEnd: DateTime | null;
  onDateSet: (date: DateTime) => void;
  decreeseYearHandler?: () => void;
  increeseYearHandler?: () => void;
  displayedYear: number;
}): React.ReactElement => {
  const { t } = useTranslation();

  const MONTHS: { [key: number]: string } = {
    1: t("Январь"),
    2: t("Февраль"),
    3: t("Март"),
    4: t("Апрель"),
    5: t("Май"),
    6: t("Июнь"),
    7: t("Июль"),
    8: t("Август"),
    9: t("Сентябрь"),
    10: t("Октябрь"),
    11: t("Ноябрь"),
    12: t("Декабрь"),
  };

  const { accentColor, pageBackground } = getColors();
  const timezone = getTimezone();
  const currentDate = DateTime.local().setZone(timezone);
  const currentYear = currentDate.year;
  const currentMonth = currentDate.month;

  // Handlers
  const selectMonthHandler = (month: number) => {
    onDateSet(
      DateTime.local(displayedYear, month, {
        zone: timezone,
      })
    );
  };

  return (
    <StyledCalendarWrapper>
      <StyledSelectWrapper>
        <StyledMonthNovigator
          onClick={() => decreeseYearHandler && decreeseYearHandler()}
        >
          <ChevronLeft />
        </StyledMonthNovigator>
        <Typography>{displayedYear}</Typography>
        <StyledMonthNovigator
          onClick={() => increeseYearHandler && increeseYearHandler()}
        >
          <ChevronRight />
        </StyledMonthNovigator>
      </StyledSelectWrapper>

      <StyledDaysWrapper>
        {Array(12)
          .fill(null)
          .map((_, i) => {
            const selected =
              !!((rangeEnd &&
                displayedYear === rangeEnd.year &&
                i + 1 === rangeEnd.month) ||
              (rangeStart &&
                displayedYear === rangeStart.year &&
                i + 1 === rangeStart.month));

            const current =
              !!(displayedYear === currentYear && i + 1 === currentMonth);

            let inRange = false;

            const month = DateTime.local(displayedYear, i + 1, {
              zone: timezone,
            });

            if (rangeStart && rangeEnd) {
              if (rangeStart <= month && month <= rangeEnd) {
                inRange = true;
              }
            }

            let disabled = false;
            if (month > currentDate) {
              disabled = true;
            }

            return (
              <StyledCalendarDay
                key={i}
                selected={selected}
                current={current}
                inRange={inRange}
                onClick={() => selectMonthHandler(i + 1)}
                disabled={disabled}
                background={pageBackground}
                rangeBackground={accentColor}
              >
                {MONTHS[i + 1]}
              </StyledCalendarDay>
            );
          })}
      </StyledDaysWrapper>
    </StyledCalendarWrapper>
  );
};

const StyledCalendarWrapper = styled.div`
  padding: 0 50px;
  margin: 0;
  @media (${RESPONSIVE_SIZES.sm}) {
    margin: 20px 0;
    padding: 0 30px;
  }
`;

const StyledMonthNovigator = styled.button`
  padding: 2px 5px;
  border: none;
  background-color: white;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDaysWrapper = styled.div`
  display: grid;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  row-gap: 0;
  grid-template-columns: repeat(2, 130px);
  padding: 5px 0;
`;

export const StyledCalendarDay = styled(CalendarDay)<{
  selected: boolean;
  current: boolean;
  inRange: boolean;
  disabled: boolean;
  background: string;
  rangeBackground: string;
}>`
  position: relative;
  // width: 25px;
  height: 25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => (props.selected ? "white" : "#000000")};
  background-color: ${(props) =>
    props.selected ? props.rangeBackground : "transparent"};
  border: 1px solid #ffffff;
  text-decoration: ${(props) =>
    props.disabled
      ? "line-through"
      : (propsValue) => (propsValue.current ? "underline" : "none")};
  &:hover {
    background-color: transparent;
    color: #000000;
    border: 1px solid ${(props) => props.rangeBackground};
  }
  ${(props) =>
    props.inRange && !props.selected
      ? `background-color: ${props.background}`
      : ""};
`;
