import { useTranslation } from "react-i18next";
import { baseSplitApi } from "./index";

interface PointService {
  id: number;
  status: number;
  updated_at: string;
  created_at: string;
  commission: {
    customer: { fix: number; max: number; min: number; percent: number };
    point: { fix: number; max: number; min: number; percent: number };
  };
  currency: { id: number; name: string };
  point: { id: number; name: string };
  service: {
    id: number;
    name: string;
    type: keyof typeof SERVICE_TYPE;
    max_amount: string;
    min_amount: string;
  };
}

type PointServiceQueryParams = {
  page: string;
  page_size: string;
  search: string;
  point: number | string;
};

export const SERVICE_TYPE = {
  0: "Перевод с кошелька на кошелек",
  1: "Оплата услуги",
  2: "Входящая",
  3: "P2P",
};

export const usePaymentMethodNames = (): Record<string, string> => {
  const { t } = useTranslation();
  return {
    card_debit_hpp: t("Банковская карта"),
    binance_pay_qr: "Binance Pay",
    pix: "Pix",
    upi: "Upi",
    cbu_cvu: "CBU",
    h2h: "H2H",
    spei: "Spei",
  };
};

export const pointServicesApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPointServices: builder.query<
      { count: number; results: PointService[] },
      PointServiceQueryParams
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/point-services/?${query.toString()}`;
      },
    }),
    getPointServiceAvailableMethods: builder.query<
      string [],
      PointServiceQueryParams
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/point-services/available-methods/?${query.toString()}`;
      },
    }),
  }),
});

export const {
  useGetPointServicesQuery,
  useLazyGetPointServicesQuery,
  useGetPointServiceAvailableMethodsQuery,
} = pointServicesApi;
