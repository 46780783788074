import React from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ReactComponent as SquareCheckIcon } from "utils/img/square-check.svg";

import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";

import { ReactComponent as Chain } from "utils/img/chain.svg";

type Props = {
  title: string;
  buttonText: string;
  handleClose?: () => void;
  handleClick: () => void;
  transId: string;
};

export const SuccessModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { title, buttonText, handleClick, handleClose, transId } = props;

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(t("Скопировано в буфер обмена"));
  };

  return (
    <Modal
      open
      hideHeader
      width={370}
      onClose={() => handleClose && handleClose()}
    >
      <Box p={27} style={{ textAlign: "center" }}>
        <Box
          flex
          alignItems="center"
          justifyContent="center"
          style={{
            height: 84,
            width: 84,
            background: "rgba(62, 169, 89, 0.1)",
            margin: "auto",
          }}
        >
          <SquareCheckIcon />
        </Box>
        <Typography variant="h4" mt={24}>
          {title}
        </Typography>
        <Box mt={25}>
          <Typography
            variant="body"
            textAlign="left"
            fontSize={15}
            fontWeight="bold"
          >
            {t("ID Транзакции")}
          </Typography>
        </Box>
        <TextInput
          value={transId}
          size="small"
          mt={15}
          readOnly
          iconStart={<Chain onClick={() => handleCopy(transId)} />}
        />
        <Button mt={24} size="small" fullwidth onClick={() => handleClick()}>
          {buttonText}
        </Button>
      </Box>
    </Modal>
  );
};
