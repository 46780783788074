import { useFormik } from "formik";
import { forEach, includes, isEmpty, keys } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Modal } from "UI/Modal";
import {
  useCreateCustomizationMutation,
  useGetPageQuery,
  useUpdateCustomizationMutation,
  useUpdatePageMutation,
} from "api/baseAPI/invoicing";
import { Layout } from "components/layouts/Layout";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useStyle } from "hooks/useStyle";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import {
  CustomizationsFormValues,
  PagesFormValues,
  initData,
  initDataCustomization,
  phoneRegExps,
} from "./PageCrete";
import { FirstStep } from "./parts/FirstStep";
import { ModalSuccess } from "./parts/ModalSuccess";
import { SecondStep } from "./parts/SecondStep";

export const PageEdit = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные страницы"), ""],
    [t("Список страниц"), "/invoicing/pages"],
    [t("Редактирование платежной страницы"), ""],
  ];

  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>("");

  const { pageId } = useParams<{ pageId: string }>();

  const selectedPointManager = useSelectedPointManager();
  const style = useStyle();

  const getPage = useGetPageQuery({ uuid: pageId || "" });

  const [
    updatePage,
    {
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
      isLoading: isLoadingUpdate,
    },
  ] = useUpdatePageMutation();
  const [createCustomization, { isSuccess, data, error, isLoading }] =
    useCreateCustomizationMutation();
  const [
    updateCustomization,
    {
      isSuccess: isSuccessUpdateCustomization,
      data: dataUpdateCustomization,
      error: errorUpdateCustomization,
      isLoading: isLoadingUpdateCustomization,
    },
  ] = useUpdateCustomizationMutation();

  useEffect(() => {
    if (isSuccessUpdate) {
      setShowSuccess(true);
    }
  }, [isSuccessUpdate]);

  const handleErrors = (dataErrors: string[]) => {
    if (
      includes(dataErrors, "Point does not have required service connected")
    ) {
      toast.error(t("К точке не подключена требуемая услуга"));
      return;
    }
    if (
      includes(dataErrors, "point and wallet have different account owners")
    ) {
      toast.error(t("У точки и кошелька разные владельцы счетов"));
      return;
    }
    if (includes(dataErrors?.[0], "has different currency from page")) {
      toast.error(t("Валюта кошелька отличается от валюты страницы"));
      return;
    }
    toast.error(t("Ошибка"));
  };

  useEffect(() => {
    if (
      errorUpdate &&
      "data" in errorUpdate &&
      "status" in errorUpdate &&
      errorUpdate.status === 400
    ) {
      const dataErrors = errorUpdate.data as string[];
      handleErrors(dataErrors);
    }
  }, [errorUpdate]);

  const today = new Date();
  const updateForm = useFormik<PagesFormValues>({
    initialValues: initData,
    onSubmit: (values) => {
      const dataForm = {
        point: String(selectedPointManager.point?.id),
        title: values.title,
        amount_currency: values.amount_currency,
        locale: values.locale,
        available_locales: values.available_locales,
        fixed_amount: values.fixed_amount || null,
        active_till: values.active_till || null,
        service: values.service,
        bp_service: values.bp_service || null,
        min_amount: values.min_amount || null,
        wallet: values.wallet,
        is_reusable: values.is_reusable,
        additional_fields: values.additional_fields,
        available_payment_methods: values.available_payment_methods,
        preferred_amounts: [...values.preferred_amounts]?.sort(
          (a: string, b: string) => Number(a) - Number(b)
        ),
        customization: values.customization || null,
        status: values.status ? 1 : 0,
        prefilled_fields: values.prefilled_fields,
        preferred_amounts_logic: values.preferred_amounts_logic,
        kind: values.kind,
        description_template: values.description_template
          ? values.description_template
              .replace(
                `[[{"value":"${t("ФИО")}","prefix":"@"}]]`,
                "{first_name} {last_name} {patronymic}"
              )
              .replace(
                `[[{"value":"${t("Дата рождения")}","prefix":"@"}]]`,
                "{birth_date}"
              )
              .replace(
                `[[{"value":"${t("Имя плательщика")}","prefix":"@"}]]`,
                "{name}"
              )
              .replace(
                `[[{"value":"${t(
                  "Номер телефона плательщика"
                )}","prefix":"@"}]]`,
                "{phone}"
              )
              .replace(
                `[[{"value":"${t("Email плательщика")}","prefix":"@"}]]`,
                "{email}"
              )
              .replace(
                `[[{"value":"${t("Комментарий")}","prefix":"@"}]]`,
                "{comment}"
              )
              .replace(
                `[[{"value":"${t("Страна")}","prefix":"@"}]]`,
                "{country}"
              )
              .replace(`[[{"value":"${t("Город")}","prefix":"@"}]]`, "{city}")
              .replace(
                `[[{"value":"${t("Адрес")}","prefix":"@"}]]`,
                "{address}"
              )
              .replace(
                `[[{"value":"${t("Почтовый индекс")}","prefix":"@"}]]`,
                "{zip_code}"
              )
          : "",
      };
      updatePage({
        ...dataForm,
        id: pageId || "",
      });
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(t("Обязательное поле")),
      service: Yup.string().required(t("Обязательное поле")),
      wallet: Yup.string().required(t("Обязательное поле")),
      active_till: Yup.date().min(
        today,
        t("Дата должна быть не ранее сегодняшнего дня")
      ),
      description_template: Yup.string().max(
        256,
        t("Максимум {{characters}} символов", { characters: 256 })
      ),
      fixed_amount: Yup.string().when("min_amount", {
        is: (val: string) => val,
        then: Yup.string().test(
          "isValidByLuhn",
          t("Сумма должна быть не меньше минимальной"),
          (value: string | undefined): boolean => {
            if (!value) return true;
            return (Number(updateForm.values.min_amount) || 0) <= Number(value);
          }
        ),
      }),
      preferred_amounts: Yup.array()
        .when("min_amount", {
          is: (val: string) => val,
          then: Yup.array().test(
            "isValidByLuhn",
            t("Сумма должна быть не меньше минимальной"),
            (value: (number | undefined)[] | undefined): boolean => {
              if (value?.length === 0) return true;
              const valid = value?.map(
                (num) =>
                  (Number(updateForm.values.min_amount) || 0) <= Number(num)
              );
              return !valid?.includes(false);
            }
          ),
        })
        .test(
          "isValidLength",
          t("Должно быть не меньше 2 вариантов"),
          (value: (number | undefined)[] | undefined): boolean =>
            (value && value?.length === 0) ||
            (!!value && value?.length > 1 && value?.length < 4)
        ),
      prefilled_fields: Yup.object().shape({
        phone: Yup.string().test(
          "isValid",
          `${t("Некорректное значение")}. ${t("Пример")} +380123456789`,
          (value: string | undefined): boolean =>
            !value ||
            (!!value &&
              phoneRegExps.map((regExp) => regExp.test(value)).includes(true))
        ),
        email: Yup.string().email(t("Некорректное значение")),
      }),
    }),
  });

  useTranslateFormErrors(updateForm);

  const customizationsForm = useFormik<CustomizationsFormValues>({
    initialValues: initDataCustomization,
    onSubmit: (values) => {
      const formData = new FormData();
      if (selectedFile || (getPage.data?.customization?.logo && !imageUrl))
        formData.append("logo", selectedFile || "");
      formData.append("background_color", values.background_color);
      formData.append("form_color", values.form_color);
      formData.append("form_accent_color", values.form_accent_color);
      formData.append("font_color", values.font_color);
      formData.append("button_color", values.button_color);
      formData.append("font", values.font);
      if (updateForm.values.customization) {
        updateCustomization({ formData, id: updateForm.values.customization });
        return;
      }
      createCustomization({ formData });
    },
  });

  useEffect(() => {
    if (getPage.isSuccess && getPage.data?.id) {
      updateForm.setFieldValue("title", getPage.data?.title || "");
      updateForm.setFieldValue(
        "amount_currency",
        getPage.data?.amount_currency || ""
      );
      updateForm.setFieldValue(
        "locale",
        getPage.data?.locale ? getPage.data?.locale.toLocaleLowerCase() : ""
      );
      updateForm.setFieldValue(
        "available_locales",
        getPage.data?.available_locales || []
      );
      updateForm.setFieldValue(
        "fixed_amount",
        getPage.data?.fixed_amount || ""
      );
      updateForm.setFieldValue("active_till", getPage.data?.active_till || "");
      updateForm.setFieldValue(
        "service",
        getPage.data?.service?.id ? String(getPage.data?.service?.id) : ""
      );
      updateForm.setFieldValue(
        "bp_service",
        getPage.data?.bp_service?.id ? String(getPage.data?.bp_service?.id) : ""
      );
      updateForm.setFieldValue(
        "wallet",
        getPage.data?.wallet?.id ? String(getPage.data?.wallet?.id) : ""
      );
      updateForm.setFieldValue("min_amount", getPage.data?.min_amount || "");
      updateForm.setFieldValue(
        "additional_fields",
        getPage.data?.additional_fields || []
      );
      updateForm.setFieldValue(
        "preferred_amounts",
        getPage.data?.preferred_amounts || []
      );
      updateForm.setFieldValue(
        "available_payment_methods",
        getPage.data?.available_payment_methods || []
      );
      updateForm.setFieldValue(
        "prefilled_fields",
        getPage.data?.prefilled_fields || {}
      );
      updateForm.setFieldValue(
        "is_reusable",
        getPage.data?.is_reusable || false
      );
      updateForm.setFieldValue(
        "customization",
        getPage.data?.customization?.id || ""
      );
      updateForm.setFieldValue("status", getPage.data?.status === 1);
      updateForm.setFieldValue("status", getPage.data?.status === 1);
      updateForm.setFieldValue(
        "preferred_amounts_logic",
        getPage.data?.preferred_amounts_logic || 1
      );
      updateForm.setFieldValue(
        "description_template",
        getPage.data?.description_template
          ? getPage.data?.description_template
              .replace(
                "{first_name} {last_name} {patronymic}",
                `[[{"value":"${t("ФИО")}","prefix":"@"}]]`
              )
              .replace(
                "{birth_date}",
                `[[{"value":"${t("Дата рождения")}","prefix":"@"}]]`
              )
              .replace(
                "{name}",
                `${
                  !style.is_fc
                    ? `[[{"value":"${t("Имя плательщика")}","prefix":"@"}]]`
                    : ""
                }`
              )
              .replace(
                "{phone}",
                `[[{"value":"${t(
                  "Номер телефона плательщика"
                )}","prefix":"@"}]]`
              )
              .replace(
                "{email}",
                `[[{"value":"${t("Email плательщика")}","prefix":"@"}]]`
              )
              .replace(
                "{comment}",
                `[[{"value":"${t("Комментарий")}","prefix":"@"}]]`
              )
              .replace(
                "{country}",
                `[[{"value":"${t("Страна")}","prefix":"@"}]]`
              )
              .replace("{city}", `[[{"value":"${t("Город")}","prefix":"@"}]]`)
              .replace(
                "{address}",
                `[[{"value":"${t("Адрес")}","prefix":"@"}]]`
              )
              .replace(
                "{zip_code}",
                `[[{"value":"${t("Почтовый индекс")}","prefix":"@"}]]`
              )
          : ""
      );
      if (!isEmpty(getPage.data?.customization)) {
        customizationsForm.setFieldValue(
          "background_color",
          getPage.data?.customization?.background_color || ""
        );
        customizationsForm.setFieldValue(
          "button_color",
          getPage.data?.customization?.button_color || ""
        );
        customizationsForm.setFieldValue(
          "font",
          getPage.data?.customization?.font || ""
        );
        customizationsForm.setFieldValue(
          "font_color",
          getPage.data?.customization?.font_color || ""
        );
        customizationsForm.setFieldValue(
          "form_accent_color",
          getPage.data?.customization?.form_accent_color || ""
        );
        customizationsForm.setFieldValue(
          "form_color",
          getPage.data?.customization?.form_color || ""
        );
        if (getPage.data?.customization?.logo) {
          setImageUrl(
            `${
              includes(getPage.data?.customization?.logo, "https")
                ? ""
                : process.env.REACT_APP_CARNET_IMG_HOST
            }${getPage.data?.customization?.logo}`
          );
        }
      }
    }
  }, [getPage.data]);

  const handleCloseForm = () => {
    navigate("/invoicing/pages");
  };

  const handleSubmitForm = () => {
    if (updateForm.isValid) {
      customizationsForm.handleSubmit();
      return;
    }
    updateForm.validateForm();
    forEach(keys(updateForm.errors), (key) =>
      updateForm.setFieldTouched(key, true)
    );
  };

  useEffect(() => {
    if (
      (isSuccess || isSuccessUpdateCustomization) &&
      (data?.id || dataUpdateCustomization?.id)
    ) {
      updateForm.setFieldValue(
        "customization",
        dataUpdateCustomization?.id || data?.id || ""
      );
      setTimeout(() => updateForm.handleSubmit());
    }
  }, [isSuccess, data, isSuccessUpdateCustomization, dataUpdateCustomization]);

  useServerErrorsInForm(updateForm.setFieldError, errorUpdate);
  useServerErrorsInForm(customizationsForm.setFieldError, error);
  useServerErrorsInForm(
    customizationsForm.setFieldError,
    errorUpdateCustomization
  );

  return (
    <Layout
      title={t("Редактирование платежной страницы")}
      breadCrumbs={breadCrumbs}
    >
      <Box
        px={24}
        py={32}
        flex
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "calc(100vh - 124px)" }}
      >
        <Box style={{ width: "100%" }}>
          <FirstStep form={updateForm} />
          <SecondStep
            pageSubmit={() => updateForm.handleSubmit()}
            formPage={updateForm}
            form={customizationsForm}
            pay_url={getPage.data?.pay_url || ""}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            customSubmit={() => handleSubmitForm()}
            setImageUrl={setImageUrl}
            imageUrl={imageUrl}
          />
        </Box>
        <Box flex mt={20}>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            variant="outlined"
            size="small"
            onClick={handleCloseForm}
          >
            {t("Назад")}
          </Button>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            ml={24}
            variant="contained"
            size="small"
            disabled={
              isLoadingUpdateCustomization || isLoading || isLoadingUpdate
            }
            onClick={handleSubmitForm}
          >
            {t("Сохранить")}
          </Button>
        </Box>

        <Modal
          open={showSuccess}
          hideHeader
          width={370}
          onClose={() => setShowSuccess(false)}
        >
          <ModalSuccess payUrl={getPage.data?.pay_url || ""} />
        </Modal>
      </Box>
    </Layout>
  );
};
