import { Route, Routes } from "react-router-dom";
import { ApiCredentials } from "./apiCredentials/ApiCredentials";
import { CustomizePage } from "./customizer/CustomizePage";
import { CustomizationRuleCreate } from "./customizer/parts/customizationRule/CustomizationRuleCreate";
import { CustomizationRuleEdit } from "./customizer/parts/customizationRule/CustomizationRuleEdit";
import { ThemeCreate } from "./customizer/parts/theme/ThemeCreate";
import { ThemeEdit } from "./customizer/parts/theme/ThemeEdit";
import { PointLogs } from "./pointLogs/PointLogs";
import { PointManagers } from "./pointManagers/PointManagers";
import { PointSettings } from "./pointSettings/PointSettings";
import { Tariffs } from "./tariffs/Tariffs";

const Router = () => (
  <Routes>
    <Route path="/tariffs" element={<Tariffs />} />
    <Route path="/point-managers" element={<PointManagers />} />
    <Route path="/api-credentials" element={<ApiCredentials />} />
    <Route path="/point-settings" element={<PointSettings />} />
    <Route path="/point-logs" element={<PointLogs />} />
    <Route path="/customizer" element={<CustomizePage />} />
    <Route
      path="/customizer/customization-rule/create"
      element={<CustomizationRuleCreate />}
    />
    <Route
      path="/customizer/customization-rule/:ruleID/edit"
      element={<CustomizationRuleEdit />}
    />
    <Route path="/customizer/themes/create" element={<ThemeCreate />} />
    <Route path="/customizer/themes/:themeID/edit" element={<ThemeEdit />} />
  </Routes>
);

export default Router;
