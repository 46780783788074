import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getColors } from "utils/getColors";
import { Typography } from "UI/Typography";

export const Loader = (): React.ReactElement => {
  const { t } = useTranslation();
  const { accentColor } = getColors();

  return (
    <StyledLoaderWrapperAll>
      <StyledLoaderWrapper>
        <StyledLoader color={accentColor} />
        <StyledAudioLoaderWrapper background={accentColor}>
          <StyledAudioLoader>
            <div />
            <div />
            <div />
          </StyledAudioLoader>
        </StyledAudioLoaderWrapper>
        <Typography
          mt={16}
          variant="subtitle1"
          style={{ maxWidth: 270, color: "white", textAlign: "center" }}
        >
          {t("Проверяем загруженный реестр")}
        </Typography>
        <Typography
          mt={8}
          variant="subtitle2"
          style={{ maxWidth: 270, color: "white", textAlign: "center" }}
        >
          {t("Это займет несколько секунд")}
        </Typography>
      </StyledLoaderWrapper>
    </StyledLoaderWrapperAll>
  );
}

const StyledLoaderWrapperAll = styled.div`
  ${(props) =>
    !props.theme.custom_styles
      ? `
        z-index: 1001;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(25, 32, 46, 0.72);
      `
      : ""}
`;

const StyledAudioLoader = styled.div`
  ${(props) =>
    !props.theme.custom_styles
      ? `
          height: 36px;
          align-items: flex-end;
          display: flex;
          div {
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: white;
          }
          div:nth-last-child(1) {
            animation: loadingA 1.5s 1s infinite;
          }
          div:nth-last-child(2) {
            margin-left: 4px;
            margin-right: 4px;
            animation: loadingA 1.5s 0.5s infinite;
          }
          div:nth-last-child(3) {
            animation: loadingA 1.5s 0s infinite;
          }
          @keyframes loadingA {
            0 {
              height: 12px;
            }
            50% {
              height: 36px;
            }
            100% {
              height: 12px;
            }
          }
        `
      : ""}
`;

const StyledLoaderWrapper = styled.div`
  ${(props) =>
    !props.theme.custom_styles
      ? `
          position: fixed;
          display: flex;
          flex-direction: column;
          align-items: center;
          top: calc(50% - 135px);
          left: calc(50% - 135px);
      `
      : ""}
`;

const StyledLoader = styled.div<{ color: string }>`
  ${(props) =>
    !props.theme.custom_styles
      ? `
          border: 4px solid white;
          border-top: 4px solid #ffffff;
          border-radius: 50%;
          width: 156px;
          height: 156px;
          ${
            props.color
              ? `
                border-top: 4px solid ${props.color};
              `
              : ""
          }
          animation: spin 1s linear infinite;
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `
      : ""}
`;
const StyledAudioLoaderWrapper = styled.div<{
  background: string;
}>`
  ${(props) =>
    !props.theme.custom_styles
      ? `
          position: absolute;
          top: 15px;
          width: 134px;
          height: 134px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${props.background || "#ffffff"};
        `
      : ""}
`;
