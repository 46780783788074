import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box } from "UI/Box";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Grid } from "UI/Grid";
import { Layout } from "components/layouts/Layout";
import { PayoutsList } from "./parts/PayoutsList";
import { RegistryList } from "./parts/RegistryList";

export const PayoutHistory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dataVariant, setDataVariant] = useState<"registries" | "payouts">(
    "registries"
  );

  const breadCrumbs = [
    [t("Выплаты"), ""],
    [t("История"), ""],
  ];

  return (
    <Layout title={t("История")} breadCrumbs={breadCrumbs}>
      <Box p={24}>
        <Grid container>
          <Grid item sm={12} md={4}>
            <ButtonSwitch
              mb={24}
              firstTitle={t("Просмотр реестров")}
              secondTitle={t("Просмотр выплат")}
              value={dataVariant === "registries"}
              onChange={(val) => {
                if (val) {
                  setDataVariant("registries");
                } else {
                  setDataVariant("payouts");
                }
                navigate({ search: "" });
              }}
            />
          </Grid>
        </Grid>
        <Box>
          {dataVariant === "registries" ? <RegistryList /> : <></>}
          {dataVariant === "payouts" ? <PayoutsList /> : <></>}
        </Box>
      </Box>
    </Layout>
  );
};
