import { baseSplitApi } from "./index";

export const helpersApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getBusinessAreas: builder.query<
      { count: number; results: { id: number; name: string }[] },
      void
    >({
      query: () => {
        const query = new URLSearchParams();
        return `/business-areas/?${query.toString()}`;
      },
    }),
    getRegistrationForms: builder.query<
      { count: number; results: { id: number; name: string }[] },
      void
    >({
      query: () => {
        const query = new URLSearchParams();
        return `/registration-forms/?${query.toString()}`;
      },
    }),
    getDocumentPackages: builder.query<
      {
        count: number;
        results: {
          id: number;
          required_docs: { id: number; name: string; description: string }[];
          optional_docs: { id: number; name: string; description: string }[];
        }[];
      },
      { business_area: number; registration_form: number }
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/document-packages/?${query.toString()}`;
      },
    }),
  }),
});

export const {
  useGetBusinessAreasQuery,
  useGetRegistrationFormsQuery,
  useGetDocumentPackagesQuery,
} = helpersApi;
