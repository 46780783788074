import styled from "styled-components";
import { getColors } from "utils/getColors";
import { hexToRGB } from "utils/mixColors";
import { RESPONSIVE_SIZES } from "utils/tools";
import { marginProps, Margin, marginStyle } from "./utility/maring";
import { Padding, paddingProps, paddingStyle } from "./utility/padding";
import {
  getResponsiveProps,
  getResponsiveStyle,
  Responsive,
} from "./utility/responsive";

type ResponsiveGridProps = Padding & Margin;

type ButtonSwitchProps = {
  firstTitle: string;
  secondTitle: string;
  value: boolean;
  onChange: (val: boolean) => void;
  className?: string;
} & Padding &
  Margin &
  Responsive<ResponsiveGridProps>;

export const ButtonSwitch = ({
  firstTitle,
  secondTitle,
  value,
  onChange,
  className,
  ...otherProps
}: ButtonSwitchProps) => {
  const { accentColor } = getColors();

  return (
    <StyledWrapperSwitch
      border={accentColor}
      {...paddingProps(otherProps)}
      {...marginProps(otherProps)}
      {...getResponsiveProps(otherProps)}
      className={className}
    >
      <StyledSwich
        active={value}
        onClick={() => onChange(true)}
        background={accentColor}
      >
        {firstTitle}
      </StyledSwich>
      <StyledSwich
        active={!value}
        onClick={() => onChange(false)}
        background={accentColor}
      >
        {secondTitle}
      </StyledSwich>
    </StyledWrapperSwitch>
  );
};
const StyledSwich = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 38px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #19202e;
  cursor: pointer;
  transition: left 0.25s;
  ${(props: { active: boolean; background: string }) =>
    props.active
      ? `
          color: #ffffff;
          background: ${props.background};
        `
      : ""};
  @media (${RESPONSIVE_SIZES.lg}) {
    width: 100%;
  }
`;

const StyledWrapperSwitch = styled.div<{ border: string } & Padding & Margin>`
  border: 1px solid #b9bfc8;
  display: flex;
  align-items: center;
  padding: 4px;
  &:hover {
    border: 1px solid
      ${(props: { border: string }) => hexToRGB(props.border, 0.4)};
  }
  ${(props) =>
    [
      paddingStyle,
      marginStyle,
      getResponsiveStyle<ResponsiveGridProps>([paddingStyle, marginStyle]),
    ]
      .map((fn) => fn(props))
      .join("")}
  @media (${RESPONSIVE_SIZES.lg}) {
    width: calc(100% - 10px);
  }
`;
