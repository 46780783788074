import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import {
  TRANSACTION_FRAUD_STATUSES,
  TRANSACTION_FRAUD_TYPES,
  TransactionFraudQueryParams,
} from "api/baseAPI/transactionFraud";
import { TableFilter } from "components/table/TableFilter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useFilterMakerResult } from "utils/filterMaker";
import { handleChangeNumber, numberForInput } from "utils/numbers";

export const TransactionFraudsFilters = ({
  filters,
  error,
}: {
  filters: useFilterMakerResult<TransactionFraudQueryParams>;
  error: FetchBaseQueryError | SerializedError | undefined;
}) => {
  const { t } = useTranslation();
  const { values, setValue, errors } = filters;
  const [errorFilter, setError] = useState<Record<string, string>>({});

  useEffect(() => {
    if (error && "data" in error) {
      const data = error.data as Record<string, string>;
      setError(data);
    } else {
      setError({});
    }
  }, [error]);

  const selectedPointManager = useSelectedPointManager();

  return (
    <TableFilter
      onSubmit={filters.handleSubmit}
      onReset={filters.handleReset}
      disabledReset={filters.disabledReset}
      disableSubmit={!!errors}
      widthBox="60%"
    >
      <Grid container mt={24} vSpace={24} hSpace={24}>
        <Grid item sm={12} lg={6}>
          <TextInput
            size="small"
            label={t("ID Транзакции")}
            placeholder={t("Введите значение")}
            value={values.transaction}
            onChange={(value) =>
              setValue("transaction", value.replace(/[^0-9]/g, ""))
            }
            error={Boolean(errorFilter?.transaction_id)}
            helperText={errorFilter?.transaction_id}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <TextInput
            size="small"
            label={t("ID Диспута")}
            placeholder={t("Введите значение")}
            value={values.id}
            onChange={(value) => setValue("id", value.replace(/[^0-9]/g, ""))}
            error={Boolean(errorFilter?.id)}
            helperText={errorFilter?.id}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <Select
            value={values.type}
            onChange={(value) => setValue("type", value)}
            label={t("Причина дипута")}
            placeholder={t("Выберите")}
            size="small"
            options={[
              { value: "", label: t("Все") },
              ...(
                Object.keys(TRANSACTION_FRAUD_TYPES) as Array<
                  keyof typeof TRANSACTION_FRAUD_TYPES
                >
              ).map((key) => ({
                label: TRANSACTION_FRAUD_TYPES[key],
                value: String(key),
              })),
            ]}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <Select
            value={values.status}
            onChange={(value) => setValue("status", value)}
            label={t("Статус")}
            placeholder={t("Выберите")}
            size="small"
            options={[
              { value: "", label: t("Все статусы") },
              ...(
                Object.keys(TRANSACTION_FRAUD_STATUSES) as Array<
                  keyof typeof TRANSACTION_FRAUD_STATUSES
                >
              ).map((key) => ({
                label: TRANSACTION_FRAUD_STATUSES[key],
                value: String(key),
              })),
            ]}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <TextInput
            size="small"
            label={t("Сумма от")}
            placeholder={t("Введите значение")}
            value={numberForInput(values.transaction__amount__gte)}
            onChange={(value) =>
              setValue(
                "transaction__amount__gte",
                handleChangeNumber(value.replace(/[^0-9.]/g, ""))
              )
            }
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <TextInput
            size="small"
            label={t("Сумма до")}
            placeholder={t("Введите значение")}
            value={numberForInput(values.transaction__amount__lte)}
            onChange={(value) =>
              setValue(
                "transaction__amount__lte",
                handleChangeNumber(value.replace(/[^0-9.]/g, ""))
              )
            }
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <Select
            label={t("Валюта")}
            placeholder={t("Выберите валюту")}
            size="small"
            options={
              selectedPointManager?.payout_wallets?.length > 0
                ? uniq(
                    selectedPointManager?.payout_wallets?.map(
                      (el) => el.currency
                    )
                  ).map((item) => ({
                    value: item,
                    label: item,
                  }))
                : []
            }
            value={values.transaction__amount_currency}
            onChange={(value) =>
              setValue("transaction__amount_currency", value)
            }
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <Select
            value={values.ps}
            onChange={(value) => setValue("ps", value)}
            label={t("Платежная система")}
            placeholder={t("Выберите")}
            size="small"
            options={[
              { value: "", label: "Все" },
              { value: "visa", label: "VISA" },
              { value: "mc", label: "Mastercard" },
              { value: "mir", label: "Mir" },
              { value: "prostir", label: "Prostir" },
              { value: "maestro", label: "Maestro" },
            ]}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <TextInput
            size="small"
            label={t("Детали транзакции")}
            placeholder={t("Введите значение")}
            value={values.transaction__description__icontains}
            onChange={(value) =>
              setValue("transaction__description__icontains", value)
            }
          />
        </Grid>
      </Grid>
    </TableFilter>
  );
}
