import { getQuery } from "utils/helpers";
import {
  CreateInvoice,
  Invoice,
  InvoicesQueryParams,
} from "./checkoutInvoiceInvoices";
import { baseSplitApi } from "./index";

type CreateInvoiceTemplate = Omit<CreateInvoice, "active_till" | "external_id">;

export type InvoiceTemplate = Omit<
  Invoice,
  "active_till" | "external_id" | "pay_url"
>;

type InvoiceTemplatesRequest = {
  pagination: { total: number };
  results: InvoiceTemplate[];
};

const checkoutInvoiceTemplatesApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getInvoiceTemplates: builder.query<
      InvoiceTemplatesRequest,
      InvoicesQueryParams
    >({
      query: (params) => {
        if (params.uuid)
          return `/checkout/invoices/invoice-template/${params.uuid}/`;
        const query = getQuery(params);
        return `/checkout/invoices/invoice-template/list/?${query.toString()}`;
      },
      transformResponse: (response: InvoiceTemplatesRequest | Invoice) => {
        if (response && "pagination" in response) return response;
        return {
          pagination: { total: response?.uuid ? 1 : 0 },
          results: response?.uuid ? [response] : [],
        };
      },
      providesTags: ["CheckoutInvoiceTemplates"],
    }),
    getInvoiceTemplate: builder.query<InvoiceTemplate, { uuid: string }>({
      query: ({ uuid }) => `/checkout/invoices/invoice-template/${uuid}/`,
      providesTags: ["CheckoutInvoiceTemplate"],
    }),
    postInvoiceTemplate: builder.mutation<
      InvoiceTemplate,
      CreateInvoiceTemplate
    >({
      query: (data) => ({
        url: "/checkout/invoices/invoice-template/create/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CheckoutInvoiceTemplates", "CheckoutInvoiceTemplate"],
    }),
    patchInvoiceTemplate: builder.mutation<
      InvoiceTemplate,
      Partial<CreateInvoiceTemplate> & { uuid: string }
    >({
      query: ({ uuid, ...data }) => ({
        url: `/checkout/invoices/invoice-template/${uuid}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["CheckoutInvoiceTemplates", "CheckoutInvoiceTemplate"],
    }),
    deleteInvoiceTemplate: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/checkout/invoices/invoice-template/${uuid}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["CheckoutInvoiceTemplates", "CheckoutInvoiceTemplate"],
    }),
  }),
});

export const {
  useGetInvoiceTemplatesQuery,
  useGetInvoiceTemplateQuery,
  usePostInvoiceTemplateMutation,
  usePatchInvoiceTemplateMutation,
  useDeleteInvoiceTemplateMutation,
} = checkoutInvoiceTemplatesApi;
