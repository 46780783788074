import { useState } from "react";

export function useMenuState(): [boolean, (_newMenuState: boolean) => void] {
  const [menuState, setMenuState] = useState<boolean>(false);

  const savedMenuState = localStorage.getItem("menuState");

  if (savedMenuState  ===  "open" && !menuState) {
    setMenuState(true);
  }

  const setNewMenuState = (newMenuState: boolean) => {
    setMenuState(newMenuState);
    if (newMenuState) {
      localStorage.setItem("menuState", "open");
    } else {
      localStorage.setItem("menuState", "close");
    }
  };

  return [menuState, setNewMenuState];
}
