import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

export type Reports = {
  uuid: string;
  name: string;
  status: number;
  file: string;
  created_at: string;
  updated_at: string;
  filters: {
    model: string;
    created_at__gte: string;
    created_at__lte: string;
    changed_at__gte: string;
    changed_at__lte: string;
    account_wallet: string;
    amount__gt: string;
    parent__isnull: string;
    account_wallet_id__in: string[];
    account_wallet_id: string;
    fields: string[];
    history_status__in: string[];
    status__in: string[];
    status: string;
    service_type: string;
  };
};

type ReportRequest = {
  id: number;
  frequency: number;
  frequency_unit: string;
  status: number;
  periodic: boolean;
  receive_email: string;
  created_at: string;
  updated_at: string;
  export_type: number;
  filters: {
    status__in: string;
    status: string;
    account_wallet_id__in: string[];
    account_wallet_id: string;
    service_type: string;
    fields: string[];
  };
};

type ReportRequestResponse = {
  id: number;
  response: string;
  created_at: string;
  files: { id: number; name: string; file: string }[];
};

export type ReportsQueryParams = {
  page: string;
  page_size: string;
  search: string;
  account_id: string;
};

export type ReportRequestsQueryParams = {
  page: string;
  page_size: string;
  search: string;
};

export const reportsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getReports: builder.query<
      { count: number; results: Reports[] },
      ReportsQueryParams
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/retriever/v0/reports/?${query.toString()}`;
      },
      providesTags: ["Reports"],
    }),
    createReport: builder.mutation<
      { uuid: string },
      {
        account_id: number;
        export_type?: number;
        filters: Record<string, string | number>;
      }
    >({
      query: (data) => ({
        url: "/retriever/v0/reports/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Reports"],
    }),
    getReport: builder.query<{ file: string }, { uuid: string }>({
      query: ({ uuid }) => `/retriever/v0/reports/${uuid}/`,
    }),
    formReport: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/retriever/v0/reports/${uuid}/form/`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["Reports"],
    }),
    deleteReport: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/retriever/v0/reports/${uuid}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Reports"],
    }),
    getReportRequests: builder.query<
      { count: number; results: ReportRequest[] },
      ReportRequestsQueryParams
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/report-requests/?${query.toString()}`;
      },
      providesTags: ["ReportRequests"],
    }),
    createReportRequest: builder.mutation<
      { uuid: string },
      {
        account_id: number;
        export_type: number;
        description: string;
        frequency_unit: string | null;
        frequency: string | null;
        user: number;
        receive_email: string;
        periodic: boolean;
        filters: Record<string, string | number>;
      }
    >({
      query: (data) => ({
        url: "/report-requests/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ReportRequests"],
    }),
    getReportRequestResponses: builder.query<
      { count: number; results: ReportRequestResponse[] },
      { request: number; page: string; page_size: string }
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/report-request-responses/?${query.toString()}`;
      },
    }),
  }),
});

export const {
  useGetReportsQuery,
  useLazyGetReportQuery,
  useCreateReportMutation,
  useDeleteReportMutation,
  useFormReportMutation,

  useGetReportRequestsQuery,
  useCreateReportRequestMutation,
  useGetReportRequestResponsesQuery,
} = reportsApi;
