import { useStyle } from "hooks/useStyle";
import { mixColors } from "./mixColors";

export function getColors() {
  const style = useStyle();

  const success = "#3EA959";
  const error = "#D00025";
  const warning = "#FF9900";

  const backgroundColor = style.background_color || "#2C60B0";
  const accentColor = style.accent_color || "#2C60B0";

  const sidebarBackground = mixColors(backgroundColor, "#161616", 15);
  const sidebarHoverBackground = mixColors(backgroundColor, "#2B2B2B", 22);
  const pageBackground = mixColors(backgroundColor, "#ffffff", 10);
  const buttonActive = mixColors(accentColor, "#000000", 82);

  return {
    sidebarBackground,
    sidebarHoverBackground,
    pageBackground,
    backgroundColor,
    buttonActive,
    accentColor,
    success,
    error,
    warning,
  };
}
