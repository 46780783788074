import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { Typography } from "UI/Typography";
import { ReactComponent as CircleCheckIcon } from "utils/img/circle-check.svg";
import { ReactComponent as CircleCloseIcon } from "utils/img/circle-close.svg";

interface Props {
  status: boolean;
}

export const RowStatusOnOff = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      {status ? <StyledCircleCheckIcon /> : <StyledCircleCloseIcon />}
      <Typography variant="subtitle2">
        {status ? t("Вкл") : t("Выкл")}
      </Typography>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  margin-right: 10px;
  path {
    fill: #3ea959 !important;
  }
`;

const StyledCircleCloseIcon = styled(CircleCloseIcon)`
  margin-right: 10px;
  path {
    fill: #d00025 !important;
  }
`;
