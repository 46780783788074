import axios, { AxiosPromise } from "axios";
import { PointManagerItem } from "./baseAPI/pointManagers";
import { apiHost } from "./baseQuery";

const API_ROOT = apiHost;

const api = axios.create({
  baseURL: API_ROOT,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "Sec-Fetch-Mode": "no-cors",
    exposedHeaders: "Content-Disposition",
  },
});

api.interceptors.request.use((config) => {
  config.headers = {
    "Sec-Fetch-Mode": "no-cors",
    exposedHeaders: "Content-Disposition",
    ...config.headers,
  };

  const access_token = window.localStorage.getItem("access_token_cabinet");

  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`;
  }

  return config;
});

export const privatApi = <T = never>(
  url: string,
  method: "GET" | "POST" | "DELETE" | "PUT",
  data?: Record<string, unknown> | string,
  config?: Record<string, unknown>
): AxiosPromise<T> => {
  const pointManagerStore = window.localStorage.getItem("point_manager");
  const pointManager: PointManagerItem = pointManagerStore
    ? { ...JSON.parse(pointManagerStore) }
    : {};
  return api({
    url,
    method,
    headers: {
      ...(pointManager?.id
        ? {
            "Point-Manager": String(pointManager?.id),
          }
        : {}),
    },
    data: data ?? null,
    ...config,
    timeout: 600000,
  });
};

export default api;
