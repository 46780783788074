import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { PayoutsQueryParams } from "api/baseAPI/payouts";
import { DateSelector } from "components/RangeSelector/DateSelector";
import { TableFilter } from "components/table/TableFilter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useFilterMakerResult } from "utils/filterMaker";
import { handleChangeNumber, numberForInput } from "utils/numbers";

export const PayoutsFilters = ({
  error,
  filters,
}: {
  filters: useFilterMakerResult<PayoutsQueryParams>;
  error: FetchBaseQueryError | SerializedError | undefined;
}) => {
  const { t } = useTranslation();
  const { values, setValue, errors } = filters;
  const [errorFilter, setError] = useState<Record<string, string>>({});

  useEffect(() => {
    if (error && "data" in error) {
      const data = error.data as Record<string, string>;
      setError(data);
    } else {
      setError({});
    }
  }, [error]);

  const selectedPointManager = useSelectedPointManager();

  return (
    <TableFilter
      onSubmit={filters.handleSubmit}
      onReset={filters.handleReset}
      disabledReset={filters.disabledReset}
      disableSubmit={!!errors}
      widthBox="50%"
    >
      <Grid container mt={24} hSpace={24} vSpace={24}>
        <Grid item sm={12} md={6}>
          <Select
            value={values.registry__account_wallet_id}
            onChange={(value) => setValue("registry__account_wallet_id", value)}
            placeholder={t("Выберите")}
            size="small"
            options={[
              { value: "", label: t("Все кошельки") },
              ...(selectedPointManager?.payout_wallets?.length > 0
                ? selectedPointManager.payout_wallets.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.id} | ${item.name}`,
                  }))
                : []),
            ]}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput
            placeholder={t("Реестр")}
            value={values.registry}
            onChange={(value) =>
              setValue("registry", value.replaceAll("  ", " "))
            }
            size="small"
            error={Boolean(errorFilter?.registry)}
            helperText={errorFilter?.registry ? t("Неправильный реестр") : ""}
          />
        </Grid>
        <Grid item sm={12}>
          <DateSelector
            label={t("Период")}
            start={
              values.created_at__gte
                ? DateTime.fromISO(values.created_at__gte)
                : null
            }
            end={
              values.created_at__lte
                ? DateTime.fromISO(values.created_at__lte)
                : null
            }
            set_start={(val) => setValue("created_at__gte", val)}
            set_end={(val) => setValue("created_at__lte", val)}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput
            size="small"
            placeholder={t("Сумма от")}
            value={numberForInput(values.amount__gte)}
            onChange={(value) =>
              setValue(
                "amount__gte",
                handleChangeNumber(value.replace(/[^0-9.]/g, "").slice(0, 20))
              )
            }
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput
            size="small"
            placeholder={t("Сумма до")}
            value={numberForInput(values.amount__lte)}
            onChange={(value) =>
              setValue(
                "amount__lte",
                handleChangeNumber(value.replace(/[^0-9.]/g, "").slice(0, 20))
              )
            }
          />
        </Grid>
        <Grid item sm={12}>
          <TextInput
            size="small"
            placeholder={t("Получатель")}
            value={values.customer_account__icontains}
            onChange={(value) =>
              setValue(
                "customer_account__icontains",
                value.replaceAll("  ", " ")
              )
            }
          />
        </Grid>
      </Grid>
    </TableFilter>
  );
};
