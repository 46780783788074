import React from "react";
import styled from "styled-components";
import {
  AlignItems,
  alignItemsProps,
  alignItemsStyle,
} from "./utility/alignItems";
import { Flex, flexProps, flexStyle } from "./utility/flex";
import {
  FlexDirection,
  flexDirectionProps,
  flexDirectionStyle,
} from "./utility/flexDirection";
import {
  flexContainerSizeStyle,
  flexItemSizeStyle,
  FlexSize,
  getFlexSizeProps,
  getFlexSpaceProps,
} from "./utility/flexSize";
import { hiddenProps, hiddenStyle, Hiden } from "./utility/hiden";
import {
  JustifyContent,
  justifyContentProps,
  justifyContentStyle,
} from "./utility/justifyContent";
import { Margin, marginProps, marginStyle } from "./utility/maring";
import { NoWrap, noWrapProps, noWrapStyle } from "./utility/nowrap";
import { Padding, paddingProps, paddingStyle } from "./utility/padding";
import {
  getResponsiveProps,
  getResponsiveStyle,
  Responsive,
} from "./utility/responsive";

type ResponsiveGridProps = Padding &
  Margin &
  FlexDirection &
  Hiden &
  NoWrap &
  JustifyContent &
  AlignItems;

type BaseGridNode = {
  children: React.ReactNode;
  style?: React.CSSProperties | Record<string, React.CSSProperties>;
  className?: string;
} & NoWrap &
  Flex &
  FlexSize &
  FlexDirection &
  Hiden &
  Padding &
  Margin &
  JustifyContent &
  AlignItems &
  Responsive<ResponsiveGridProps>;

type GridContainer = {
  container: boolean;
  item?: boolean;
} & BaseGridNode;

type GridItem = {
  item: boolean;
  container?: boolean;
} & BaseGridNode;

type GridNode = GridContainer | GridItem;

export const Grid = ({
  children,
  style,
  className,
  container,
  item,
  ...otherProps
}: GridNode) => {
  if (container) {
    return (
      <StyledContainerWrapper
        className={className}
        style={style}
        {...noWrapProps(otherProps)}
        {...getFlexSizeProps(otherProps)}
        {...getFlexSpaceProps(otherProps)}
        {...flexDirectionProps(otherProps)}
        {...hiddenProps(otherProps)}
        {...paddingProps(otherProps)}
        {...marginProps(otherProps)}
        {...justifyContentProps(otherProps)}
        {...alignItemsProps(otherProps)}
        {...getResponsiveProps(otherProps)}
      >
        {Array.isArray(children)
          ? children.map(
              (el, index) =>
                !!el &&
                React.cloneElement(el, {
                  ...el.props,
                  key: index,
                  ...getFlexSpaceProps(otherProps),
                })
            )
          : children}
      </StyledContainerWrapper>
    );
  }

  if (item) {
    return (
      <StyledWrapper
        {...getFlexSizeProps(otherProps)}
        {...getFlexSpaceProps(otherProps)}
        {...flexDirectionProps(otherProps)}
        {...hiddenProps(otherProps)}
        {...paddingProps(otherProps)}
        {...marginProps(otherProps)}
        {...justifyContentProps(otherProps)}
        {...alignItemsProps(otherProps)}
        {...flexProps(otherProps)}
        {...getResponsiveProps(otherProps)}
        style={style}
        className={className}
      >
        {children}
      </StyledWrapper>
    );
  }

  return null;
};

const StyledContainerWrapper = styled.div<
  NoWrap & FlexSize & FlexDirection & Padding & Margin
>`
  ${(props) =>
    [
      noWrapStyle,
      flexContainerSizeStyle,
      flexDirectionStyle,
      paddingStyle,
      marginStyle,
      flexStyle,
      justifyContentStyle,
      alignItemsStyle,
      getResponsiveStyle<ResponsiveGridProps>([
        paddingStyle,
        marginStyle,
        flexDirectionStyle,
        hiddenStyle,
        noWrapStyle,
        justifyContentStyle,
        alignItemsStyle,
      ]),
    ]
      .map((fn) => fn(props))
      .join("")}
`;

const StyledWrapper = styled.div<
  FlexSize & Hiden & Padding & Margin & Responsive<Padding>
>`
  ${(props) =>
    [
      paddingStyle,
      marginStyle,
      flexDirectionStyle,
      hiddenStyle,
      flexItemSizeStyle,
      flexStyle,
      alignItemsStyle,
      justifyContentStyle,
      getResponsiveStyle<ResponsiveGridProps>([
        paddingStyle,
        marginStyle,
        flexDirectionStyle,
        hiddenStyle,
        noWrapStyle,
        justifyContentStyle,
        alignItemsStyle,
      ]),
    ]
      .map((fn) => fn(props))
      .join("")}
`;
