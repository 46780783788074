import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const WalletCard = ({
  walletName,
  currency,
}: {
  walletName: string;
  currency: string;
}) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledWalletName>{walletName}</StyledWalletName>
      <StyledCurrency>
        <StyledCurrencyTitle>{t("Валюта")}</StyledCurrencyTitle>
        {currency}
      </StyledCurrency>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #cacdd2;
  min-width: 320px;
`;

const StyledWalletName = styled.div`
  padding: 18px;
  background-color: #eaeff7;
  font-size: 14px;
  border-bottom: 1px solid #cacdd2;
  height: 54px;
  box-sizing: border-box;
`;

const StyledCurrencyTitle = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
`;

const StyledCurrency = styled.div`
  padding: 18px;
  font-size: 24px;
`;
