import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { find, isEmpty, map } from "lodash";
import {
  KIND_CHECKOUT,
  useGetInvoiceQuery,
} from "api/baseAPI/checkoutInvoiceInvoices";
import { Layout } from "components/layouts/Layout";
import { TabList } from "components/TabList";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  DataItem,
  ParamOnOff,
} from "pages/invoicing/pages/parts/AdditionalComponents";
import { dateFormatter } from "utils/date";
import { visibleNumber } from "utils/numbers";
import { getCurrencyIcon } from "utils/useStyle";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { InvoiceStats } from "./parts/InvoiceStats";

export const Invoice = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Инвойсинг"), ""],
    [t("Список инвойсов"), "/invoice/invoices"],
    [t("Инвойс"), ""],
  ];

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("1");

  const { uuid = "" } = useParams<{ uuid: string }>();

  const invoice = useGetInvoiceQuery({ uuid });
  const selectedPointManager = useSelectedPointManager();

  const handleChangeActiveTab = (newValue: string) => {
    navigate({ search: "" });
    setActiveTab(newValue);
  };

  const getWallet = useMemo(() => {
    if (invoice?.data?.wallet_id) {
      const wallet = find(selectedPointManager?.available_wallets, {
        id: Number(invoice?.data?.wallet_id),
      });
      return wallet ? `${wallet?.id} | ${wallet.name}` : "";
    }
    return "";
  }, [invoice?.data?.wallet_id, selectedPointManager?.available_wallets]);

  return (
    <Layout title={t("Инвойс")} breadCrumbs={breadCrumbs}>
      <Box p={24} style={{ background: "white" }}>
        <Typography variant="h5">{t("Общие настройки")}</Typography>
        <Grid container pt={24} hSpace={24} vSpace={24}>
          <Grid item sm={12} md={3}>
            <DataItem text="UUID" content={invoice?.data?.uuid || ""} />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Описание")}
              content={invoice?.data?.title || ""}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Тип")}
              content={invoice?.data?.kind === KIND_CHECKOUT ? t("Чекаут") : ""}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Срок действия")}
              content={
                <Typography
                  variant="inputText"
                  style={{
                    color:
                      invoice?.data?.active_till &&
                      new Date(invoice?.data?.active_till) < new Date()
                        ? "red"
                        : "black",
                  }}
                >
                  {dateFormatter.format(invoice?.data?.active_till || null)}
                </Typography>
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem text={t("Язык")} content={invoice?.data?.locale || ""} />
          </Grid>
        </Grid>
        <Typography mt={24} variant="h5">
          {t("Платежные данные")}
        </Typography>
        <Grid container pt={24} hSpace={24} vSpace={24}>
          <Grid item sm={12} md={3}>
            <DataItem text={t("Кошелек")} content={getWallet} />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Услуги")}
              content={
                invoice?.data?.service_mapping
                  ? JSON.stringify(invoice?.data?.service_mapping, null, 2)
                  : ""
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Внешний ID")}
              content={invoice?.data?.external_id || ""}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("ID Клиента")}
              content={invoice?.data?.external_customer_id || ""}
            />
          </Grid>
        </Grid>
        <Typography mt={24} variant="h5">
          {t("Настройки оплаты")}
        </Typography>
        <Grid container pt={24} hSpace={24} vSpace={24}>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Сумма")}
              content={
                invoice?.data?.fixed_amount
                  ? visibleNumber(invoice?.data?.fixed_amount)
                  : ""
              }
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Валюта")}
              content={invoice?.data?.amount_currency || ""}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Минимальная сумма")}
              content={
                invoice?.data?.min_amount
                  ? visibleNumber(invoice?.data?.min_amount)
                  : ""
              }
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Дефолтная сумма")}
              content={
                invoice?.data?.default_amount
                  ? visibleNumber(invoice?.data?.default_amount)
                  : ""
              }
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Предлагаемые суммы")}
              content={
                !isEmpty(invoice?.data?.preferred_amounts) ? (
                  <Box flex alignItems="center">
                    {map(invoice?.data?.preferred_amounts, (item) => (
                      <Box
                        p={4}
                        mr={2}
                        mb={-6}
                        style={{ background: "#EAEFF7" }}
                        key={item}
                      >
                        <Typography
                          variant="subtitle3"
                          style={{ display: "flex" }}
                        >
                          <>
                            {getCurrencyIcon(
                              invoice?.data?.amount_currency || ""
                            )}
                            {visibleNumber(item)}
                          </>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <></>
                )
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Повторные оплаты")}
              content={<ParamOnOff includes={!!invoice?.data?.is_reusable} />}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Время создания")}
              content={dateFormatter.format(invoice?.data?.created_at || null)}
            />
          </Grid>
        </Grid>
        <Typography mt={24} variant="h5">
          {t("Дополнительные настройки")}
        </Typography>
        <Grid container pt={24} hSpace={24} vSpace={24}>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Шаблон описания")}
              content={invoice?.data?.description_template || ""}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Дополнительные поля")}
              content={invoice?.data?.additional_fields?.join(", ") || ""}
            />
          </Grid>
        </Grid>
      </Box>
      <Box py={32} px={24}>
        <TabList
          value={activeTab}
          onChange={handleChangeActiveTab}
          items={[{ label: t("Основная информация"), value: "1" }]}
        />
        <Box mt={24}>
          <>
            {activeTab === "1" && (
              <Grid container pl={24} hSpace={24}>
                <Grid item sm={12} md={6}>
                  {!!invoice.data?.uuid && (
                    <InvoiceStats invoice={invoice.data} />
                  )}
                </Grid>
              </Grid>
            )}
          </>
        </Box>
      </Box>
    </Layout>
  );
};
