import { includes, reduce, replace } from "lodash";
import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

export type TransactionsQueryParams = {
  page: string;
  page_size: string;
  search: string;
  account_wallet: string;
  status__in: string;
  service__type: string;
  created_at__gte: string;
  created_at__lte: string;
  part_date__gte: string;
  part_date__lte: string;
  id: string;
  service__in: string;
  description__icontains: string;
  external_transaction_id: string;
  external_customer_id: string;
  parent__isnull: string;
  card_number: string;
};
export interface Transaction {
  id: number;
  service: { id: number; name: string };
  amount: number;
  created_at: string;
  status: keyof typeof TRANSACTION_STATUSES;
  card_number: string | null;
  card_ps: string | null;
  refunded_amount: number;
  point_currency: string;
  provider_amount: number;
  provider_currency: string;
  amount_currency: string;
  external_transaction_id: number | null;
  external_customer_id: string | null;
  description: string;
  service_type: number;
  uuid: string;
  failure_reason_description: string;
  method: { id: number; slug: string } | null;
  total_commission: number;
}

export const TRANSACTION_STATUSES = {
  "-1": "Зарезервированный ID",
  0: "Новая",
  1: "Успешная",
  2: "Неуcпешная",
  3: "Отменена",
  4: "Реверс",
  5: "Истёк срок",
  6: "Авторизация успешна",
  10: "В обработке",
  30: "В процессе отмены",
  40: "В процессе реверса",
  50: "Перепроведён",
  100: "Внутренняя ошибка",
  200: "Создание неуспешно",
};

type TransactionRefund = {
  status: string;
  amount_to_refund: string;
  id: number;
  created_at: string;
};

type TransactionServiceFields = {
  id: number;
  field_slug: string;
  field_value: string;
};

export const transactionsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getTransactions: builder.query<
      { count: number; results: Transaction[] },
      TransactionsQueryParams
    >({
      query: (params) => {
        const reduceParams = reduce(
          params,
          (acc, value, key) => ({
            ...acc,
            ...(includes(key, "__in") && value && !includes(value, ",")
              ? {
                  [replace(key, "__in", "")]: value,
                }
              : {
                  [key]: value,
                }),
          }),
          {}
        );
        const query = getQuery(reduceParams);
        return `/retriever/v0/transactions/?${query.toString()}`;
      },
      providesTags: ["Transactions"],
    }),
    getTransaction: builder.query<Transaction, { id: number }>({
      query: ({ id }) => ({
        url: `/retriever/v0/transactions/${id}/`,
        method: "GET",
      }),
    }),
    getTransactionServiceFields: builder.query<
      { id: number; field_slug: string; field_value: string }[],
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/retriever/v0/transactions/${id}/service-fields/`,
        method: "GET",
      }),
    }),
    cancelTransaction: builder.mutation<
      { refund_id: number },
      { id: number; amount?: number }
    >({
      query: ({ id, amount }) => ({
        url: `/transactions/${id}/cancel/`,
        method: "POST",
        ...(amount
          ? {
              body: { amount },
            }
          : {}),
      }),
      invalidatesTags: ["Transactions"],
    }),
    resendTransactionCallBack: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/transactions/${id}/resend-callback/`,
        method: "POST",
      }),
      invalidatesTags: ["Transactions"],
    }),
    getTransactionServiceFieldsView: builder.query<
      TransactionServiceFields[],
      { id: number }
    >({
      query: ({ id }) =>
        `/retriever/v0/transactions/${id}/service-fields-view/`,
    }),

    getTransactionRefunds: builder.query<
      { count: number; results: TransactionRefund[] },
      { page: string; page_size?: string; transaction: number }
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/retriever/v0/transaction-refunds/?${query.toString()}`;
      },
      providesTags: ["Transactions"],
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useLazyGetTransactionQuery,
  useGetTransactionRefundsQuery,
  useCancelTransactionMutation,
  useResendTransactionCallBackMutation,
  useLazyGetTransactionServiceFieldsQuery,
  useGetTransactionServiceFieldsQuery,
  useGetTransactionServiceFieldsViewQuery,
} = transactionsApi;
