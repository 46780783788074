import { useTranslation } from "react-i18next";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Layout } from "components/layouts/Layout";
import { CallbackUrlSettings } from "./parts/CallbackUrlSettings";
import { ThemeSettings } from "./parts/ThemeSettings";

export const PointSettings = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Управление"), ""],
    [t("Настройки точки"), ""],
  ];

  return (
    <Layout title={t("Настройки точки")} breadCrumbs={breadCrumbs}>
      <Box>
        <Grid container p={24} hSpace={24} vSpace={24}>
          <CallbackUrlSettings />
          <ThemeSettings />
        </Grid>
      </Box>
    </Layout>
  );
};
