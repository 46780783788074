import { baseSplitApi } from "./index";

export type CommissionResponse = {
  customer: {
    fix: number | null;
    max: number | null;
    min: number | null;
    percent: number | null;
  };
  point: {
    fix: number | null;
    max: number | null;
    min: number | null;
    percent: number | null;
  };
  provider: {
    fix: number | null;
    max: number | null;
    min: number | null;
    percent: number | null;
  };
  min_amount: number | null;
  max_amount: number | null;
};
export const servicesApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getServicesCommissions: builder.query<
      CommissionResponse,
      {
        serviceId: number;
        pointId: number;
      }
    >({
      query: ({ serviceId, pointId }) => ({
        url: `/services/${serviceId}/get-commissions/?point=${pointId}`,
      }),
    }),
    getAllServicesCommissions: builder.query<
      any,
      {
        serviceId: number;
        pointId: number;
      }[]
    >({
      queryFn: async (values, api, extraOptions, bQ) => {
        const promises = values.map(({ serviceId, pointId }) => bQ({
            url: `/services/${serviceId}/get-commissions/?point=${pointId}`,
          }));
        return Promise.all(promises).then((results) => ({
            data: results.map((el) => ({
              data: el.data,
              // @ts-ignore
              key: el.meta?.request?.url?.split("v0")?.[1] || "",
            })),
          }));
      },
    }),
  }),
});

export const {
  useGetServicesCommissionsQuery,
  useLazyGetServicesCommissionsQuery,
  useLazyGetAllServicesCommissionsQuery,
} = servicesApi;
