import { useTranslation } from "react-i18next";
import { TextInput } from "UI/Form/TextInput";
import MaskedInputWrapper from "utils/MaskedInputWrapper";
import { ReactComponent as CardIcon } from "pages/payout/parts/icons/card.svg";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";

export const CardInput = (props: CustomerAccountInputProps) => {
  const { t } = useTranslation();
  const { id, form } = props;
  return (
    <MaskedInputWrapper
      mask="0000 0000 0000 0000"
      value={form.values.payouts[id].customer_account}
    >
      <TextInput
        placeholder={t("Номер карты")}
        value={form.values.payouts[id].customer_account}
        onBlur={(e) => {
          form.handleBlur(e);
          form.setFieldTouched(`payouts[${id}].customer_account`, true);
        }}
        name="customer_account"
        error={
          !!(form.touched.payouts?.[id]?.customer_account &&
          form.errors.payouts?.[id]?.customer_account)
        }
        helperText={
          t<any>(
            form.touched.payouts?.[id]?.customer_account &&
              form.errors.payouts?.[id]?.customer_account &&
              form.errors.payouts?.[id]?.customer_account
          ) || ""
        }
        onChange={(value) => {
          form.setFieldValue(
            `payouts[${id}].customer_account`,
            value.substring(0, 19)
          );
        }}
        iconStart={<CardIcon />}
        size="small"
      />
    </MaskedInputWrapper>
  );
}
