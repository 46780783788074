import { every } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Grid } from "UI/Grid";
import { LinearProgress } from "components/LinearProgress";
import { getColors } from "utils/getColors";
import { hexToRGB } from "utils/mixColors";
import { RESPONSIVE_SIZES } from "utils/tools";
import { IPayoutMethod } from "pages/payout/logic/payoutMethods";
import { IPayoutCategory } from "pages/payout/logic/payoutsCategory";

export const PayoutTypeSelect = ({
  loading,
  payoutMethods,
  selectedPayoutMethod,
  onChange,
}: {
  loading: boolean;
  payoutMethods: IPayoutMethod[];
  selectedPayoutMethod: IPayoutMethod | null;
  onChange: (payout: IPayoutMethod | null) => void;
}) => {
  const { accentColor } = getColors();
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] =
    useState<IPayoutCategory | null>(null);

  useEffect(() => {
    if (selectedPayoutMethod) {
      setSelectedCategory(selectedPayoutMethod.category);
    }
  }, [selectedPayoutMethod]);

  const handleTogglePayoutType = (payout: IPayoutMethod) => {
    if (payout.isActive) {
      onChange(payout);
    }
  };

  const categories = payoutMethods.reduce((acc, payout) => {
    if (payout.category?.slug) {
      acc[payout.category.slug] = payout.category;
    }
    return acc;
  }, {} as Record<string, IPayoutCategory>);

  const handleSelectCategory = (slug: string) => {
    if (
      !every(
        payoutMethods.filter((p) => p.category?.slug === slug),
        (p) => !p.isActive
      )
    ) {
      setSelectedCategory(categories[slug]);
    }
  };

  useEffect(() => {
    if (selectedPayoutMethod?.category?.slug !== selectedCategory?.slug) {
      const payoutMethod = payoutMethods
        .filter((payout) => payout.category === selectedCategory)
        .filter((method) => method.isActive)[0];
      if (payoutMethod) {
        handleTogglePayoutType(payoutMethod);
      }
    }
  }, [selectedCategory]);

  if (
    loading ||
    !payoutMethods.map((pm) => pm.isLoaded).every((isLoaded) => isLoaded)
  ) {
    return <LinearProgress />;
  }

  return (
    <Grid container hSpace={15} vSpace={24}>
      <Grid item>
        <StyledWrapperSwitch border={accentColor}>
          {Object.keys(categories).map((slug) => (
            <StyledSwich
              key={slug}
              active={slug === selectedCategory?.slug}
              onClick={() => handleSelectCategory(slug)}
              background={accentColor}
              disabled={every(
                payoutMethods.filter((p) => p.category?.slug === slug),
                (p) => !p.isActive
              )}
            >
              <div>{t<any>(categories[slug].name)}</div>
            </StyledSwich>
          ))}
        </StyledWrapperSwitch>
      </Grid>
      {selectedCategory !== null &&
      payoutMethods.filter((p) => p.category === selectedCategory).length >
        1 ? (
        <Grid
          item
          sm={12}
          md="content"
          style={{ position: "relative", height: "48px" }}
        >
          <StyledWrapperSwitch border={accentColor} mobile>
            {payoutMethods
              .filter((p) => p.category === selectedCategory)
              .map((payout) => (
                <StyledSwich
                  key={payout.kind}
                  active={payout.kind === selectedPayoutMethod?.kind}
                  onClick={() => handleTogglePayoutType(payout)}
                  background={accentColor}
                  disabled={payout.isActive === false}
                >
                  {payout.icon}
                  <StyledText>{t<any>(payout.name)}</StyledText>
                  <StyledMobileText>
                    {t<any>(payout.mobileName)}
                  </StyledMobileText>
                </StyledSwich>
              ))}
          </StyledWrapperSwitch>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

const StyledMobileText = styled.div`
  display: none;
  @media (${RESPONSIVE_SIZES.sm}) {
    white-space: nowrap;
    display: block;
  }
`;

const StyledText = styled.div`
  display: block;
  white-space: nowrap;
  @media (${RESPONSIVE_SIZES.sm}) {
    display: none;
  }
`;

const StyledWrapperSwitch = styled.div`
  border: 1px solid #b9bfc8;
  display: flex;
  align-items: center;
  padding: 4px;

  @media (${RESPONSIVE_SIZES.sm}) {
    ${(props: { border: string; mobile?: boolean }) =>
      props?.mobile
        ? `
            position: absolute;
            overflow-x: scroll;
            max-width: -moz-available;
            max-width: -webkit-fill-available;
            max-width: fill-available;

            &::-webkit-scrollbar {
              height: 2px;
            }

            &::-webkit-scrollbar-track {
              background: ${hexToRGB(props.border, 0.4)};
            }

            &::-webkit-scrollbar-thumb {
              background: #19202e;
            }
          `
        : ""};
  }

  &:hover {
    border: 1px solid ${(props) => hexToRGB(props.border, 0.4)};
  }
`;

const StyledSwich = styled.div<{
  active: boolean;
  background: string;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #19202e;
  cursor: pointer;
  transition: left 0.25s;
  padding: 0 16px;
  width: calc(50% - 32px);

  svg {
    padding-right: 8px;
    max-height: 14px;
    max-width: 14px;
  }

  svg:not(#exmo) {
    path {
      fill: #19202e !important;
    }
  }

  @media (${RESPONSIVE_SIZES.sm}) {
    // width: 100%;
    // box-sizing: border-box;
  }

  ${(props) =>
    props.active
      ? `
          color: #ffffff;
          background: ${props.background};
          svg:not(#exmo) path {
            fill: #FFFFFF !important;
          }
        `
      : ""};

  ${(props) =>
    props.disabled
      ? `
          opacity: 0.5;
          cursor: not-allowed;
        `
      : ""};
`;
