import { RESPONSIVE_SIZES } from "utils/tools";

export type Responsive<T> = {
  responsive?: {
    sm?: T;
    md?: T;
    lg?: T;
    xl?: T;
    xxl?: T;
  };
};

export function getResponsiveProps<T>(props: Responsive<T>) {
  return (({ responsive }) => ({
    responsive,
  }))(props);
}

export function getResponsiveStyle<T>(
  getStyleFns: Array<(props: T) => string>
) {
  return (props: Responsive<T>) => {
    const sm = { ...props.responsive?.sm } as T;
    const md = { ...props.responsive?.sm, ...props.responsive?.md } as T;
    const lg = {
      ...props.responsive?.sm,
      ...props.responsive?.md,
      ...props.responsive?.lg,
    } as T;
    const xl = {
      ...props.responsive?.sm,
      ...props.responsive?.md,
      ...props.responsive?.lg,
      ...props.responsive?.xl,
    } as T;
    const xxl = {
      ...props.responsive?.sm,
      ...props.responsive?.md,
      ...props.responsive?.lg,
      ...props.responsive?.xl,
      ...props.responsive?.xxl,
    } as T;

    return `
      @media (${RESPONSIVE_SIZES.sm}) {
        ${getStyleFns.map((fn) => (sm ? fn(sm) : "")).join("")}
      }
      @media (${RESPONSIVE_SIZES.overSm}) and (${RESPONSIVE_SIZES.md}) {
        ${getStyleFns.map((fn) => (md ? fn(md) : "")).join("")}
      }
      @media (${RESPONSIVE_SIZES.overMd}) and (${RESPONSIVE_SIZES.lg}) {
        ${getStyleFns.map((fn) => (lg ? fn(lg) : "")).join("")}
      }
      @media (${RESPONSIVE_SIZES.overLg}) and (${RESPONSIVE_SIZES.xl}) {
        ${getStyleFns.map((fn) => (xl ? fn(xl) : "")).join("")}
      }
      @media (${RESPONSIVE_SIZES.overXl}) {
        ${getStyleFns.map((fn) => (xxl ? fn(xxl) : "")).join("")}
      }
    `;
  };
}
