import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import i18n from "locale";

export type FormValues = {
  name: string;
  background_color: string;
  panel_background_color_first: string;
  panel_background_color_second: string;
  accent_color: string;
  primary_text_font: string;
  primary_text_color: string;
  secondary_text_color: string;
  button_text_color: string;
  ap_gp_style: 1 | 2;
  inputs_style: 1 | 2;
  inputs_background: string;
  border_radius: number;
  delete_logo: boolean;
  payment_slug: { id: number; slug: string } | null;
};

export function useThemeFormLogic(params: {
  onSubmit: (
    values: FormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
  validationSchema?: any;
}) {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState<File | string>("");
  const [imageUrl, setImageUrl] = useState<string>("");

  const form = useFormik<FormValues>({
    initialValues: {
      name: "",
      background_color: "#E6E6E6",
      panel_background_color_first: "#F5F5F5",
      panel_background_color_second: "#FFFFFF",
      accent_color: "#00B53F",
      primary_text_font: "Inter",
      primary_text_color: "#000000",
      secondary_text_color: "#8E8E8E",
      button_text_color: "#FFFFFF",
      ap_gp_style: 2,
      inputs_style: 1,
      inputs_background: "#FFFFFF",
      border_radius: 0,
      delete_logo: false,
      payment_slug: null,
    },
    onSubmit: (values, { setSubmitting }) => {
      params.onSubmit(values, setSubmitting);
    },
    validationSchema:
      params.validationSchema ||
      yup.object().shape({
        accent_color: yup.string().required(t("Обязательное поле")),
        button_text_color: yup.string().required(t("Обязательное поле")),
        inputs_background: yup.string().required(t("Обязательное поле")),
        name: yup.string().required(t("Обязательное поле")),
        panel_background_color_first: yup
          .string()
          .required(t("Обязательное поле")),
        panel_background_color_second: yup
          .string()
          .required(t("Обязательное поле")),
        primary_text_color: yup.string().required(t("Обязательное поле")),
        primary_text_font: yup.string().required(t("Обязательное поле")),
        secondary_text_color: yup.string().required(t("Обязательное поле")),
      }),
  });

  useEffect(() => {
    if (i18n.language) {
      form.setErrors({});
    }
  }, [i18n.language]);

  return {
    form,
    selectedFile,
    setSelectedFile,
    imageUrl,
    setImageUrl,
  };
}
