import { baseSplitApi } from "./index";

export const currencyRatesApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCurrencyRates: builder.query<
      {
        count: number;
        results: {
          id: number;
          created_at: string;
          rate: number;
          updated_at: string;
        }[];
      },
      void
    >({
      query: () => `/currency-rates/`,
    }),
    getCurrencyRate: builder.query<
      { id: number; rate: string },
      { id: string }
    >({
      query: ({ id }) => `/currency-rates/${id}/`,
    }),
  }),
});

export const { useGetCurrencyRatesQuery, useLazyGetCurrencyRateQuery } =
  currencyRatesApi;
