import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InvoiceTransactionsQueryParams } from "api/baseAPI/checkoutInvoiceTransactions";
import {
  useGetPointServiceAvailableMethodsQuery,
  usePaymentMethodNames,
} from "api/baseAPI/pointServices";
import { TableFilter } from "components/table/TableFilter";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { useFilterMakerResult } from "utils/filterMaker";
import { useSelectedPointManager } from "hooks/useSelectedEntity";

export const TransactionsFilters = ({
  filters,
}: {
  filters: useFilterMakerResult<InvoiceTransactionsQueryParams>;
}) => {
  const { t } = useTranslation();
  const { values, setValue, errors } = filters;
  const selectedPointManager = useSelectedPointManager();

  const paymentMethods = useGetPointServiceAvailableMethodsQuery({
    page: "1",
    page_size: "100",
    search: "",
    point: `${selectedPointManager?.point_id || ""}`,
  });

  const paymentMethodNames = usePaymentMethodNames();

  const getPaymentMethods = useMemo(() => {
    const { data } = paymentMethods;
    if (data && data?.length) {
      const elements = Array.from(
        new Set(
          data?.map((slug) => ({
            value: slug,
            label: paymentMethodNames?.[slug] || slug,
          }))
        )
      );
      return elements;
    }
    return [];
  }, [paymentMethods]);

  return (
    <TableFilter
      onSubmit={filters.handleSubmit}
      onReset={filters.handleReset}
      disabledReset={filters.disabledReset}
      disableSubmit={!!errors}
      widthBox="50%"
    >
      <Grid container mt={12} hSpace={24} vSpace={24} smHSpace={8}>
        <Grid item sm={12}>
          <TextInput
            size="small"
            fullwidth
            label={t("Инвойс")}
            placeholder={t("Предварительно заполнить")}
            value={values.invoice_uuid}
            onChange={(value) => setValue("invoice_uuid", value)}
          />
        </Grid>
        <Grid item sm={12}>
          <Select
            value={values.payment_method_slug}
            onChange={(value) => setValue("payment_method_slug", value)}
            label={t("Платежный метод")}
            placeholder={t("Выберите")}
            size="small"
            options={getPaymentMethods}
          />
        </Grid>
      </Grid>
    </TableFilter>
  );
};
