import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { Alert } from "UI/Alert";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useGetContactWalletsQuery } from "api/baseAPI/contactWallets";
import { ReactComponent as Warning } from "utils/img/warning.svg";
import { useCurrencyIcon } from "utils/useStyle";
import { TransferFormValues } from "pages/w2w/W2w";

export const ReceiverByWallet = ({
  currency,
  amountRate,
  rate,
  form,
}: {
  currency: { from: string | null; to: string | null };
  amountRate: number | string;
  rate: null | number;
  form: FormikProps<TransferFormValues>;
}) => {
  const { t } = useTranslation();
  const fromCurrencyIcon = useCurrencyIcon(currency.from || "");
  const toCurrencyIcon = useCurrencyIcon(currency.to || "");
  const { data: contactWalletsData } = useGetContactWalletsQuery();
  return (
    <Grid container mx={24} style={{ background: "white" }} p={16}>
      <Grid item sm={12} p={12}>
        <Typography variant="body" fontWeight="bold">
          {t("Получатель")}
        </Typography>
      </Grid>
      <Grid item sm="fill" p={12}>
        <Grid container hSpace={24}>
          <Grid item sm={12} md={5}>
            <Select
              options={
                contactWalletsData?.results.map((item) => ({
                  value: String(item.account_wallet.id),
                  label: item.name,
                })) || []
              }
              placeholder={t("Выберите кошелек")}
              value={form.values.receiverWalletId}
              onChange={(v) => form.setFieldValue("receiverWalletId", v)}
              error={form.errors.receiverWalletId}
              helperText={form.errors.receiverWalletId}
              mb={24}
            />
            {amountRate && rate ? (
              <Alert color="green" mb={24}>
                <Grid container hSpace={10}>
                  <Grid item>
                    <Warning />
                  </Grid>
                  <Grid item>
                    {t("Сумма зачисления")} <br />
                    {fromCurrencyIcon}&nbsp;{amountRate}
                    {rate !== 1 ? (
                      <>
                        &nbsp;{t("по курсу")} {toCurrencyIcon}&nbsp;{rate}
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Alert>
            ) : null}
          </Grid>
          <Grid item sm="fill">
            <TextInput
              multiline
              fullwidth
              placeholder={t("Комментарий")}
              value={form.values.comment}
              onChange={(v) => form.setFieldValue("comment", v)}
              error={form.errors.comment}
              helperText={form.errors.comment}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
