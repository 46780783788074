import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Select } from "UI/Form/Select";
import { Grid } from "UI/Grid";
import { IUseUrlQueryResult } from "utils/url";
import { AccountWalletTransactionsQueryParams } from "api/baseAPI/accountWalletTransactions";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { DateSelector } from "components/RangeSelector/DateSelector";

export const AccountWalletTransactionsFiltersRow = ({
  queryParams,
  querySetters,
}: IUseUrlQueryResult<AccountWalletTransactionsQueryParams>) => {
  const { t } = useTranslation();

  const selectedPointManager = useSelectedPointManager();

  return (
    <Grid
      container
      my={24}
      justifyContent="space-between"
      vSpace={20}
      hSpace={20}
      smHSpace={1}
      mdHSpace={1}
    >
      <Grid item sm={12} lg={6} xl={3} xxl={4}>
        <Select
          value={queryParams.account_wallet}
          onChange={(value) => {
            querySetters.set_page("1");
            querySetters.set_account_wallet(value);
          }}
          label={t("Кошелек")}
          placeholder={t("Выберите кошелек")}
          size="small"
          options={
            selectedPointManager?.available_wallets?.length > 0
              ? selectedPointManager?.available_wallets?.map(
                  (item: { id: number; name: string }) => ({
                    value: `${item.id}`,
                    label: `${item.id} | ${item.name}`,
                  })
                )
              : []
          }
        />
      </Grid>
      <Grid item sm={12} lg={6} xl={3} xxl={4}>
        <Select
          value={queryParams.amount__gt}
          onChange={(val) => {
            querySetters.set_page("1");
            querySetters.set_amount__gt(val);
          }}
          label={t("Направление")}
          size="small"
          options={[
            { value: "", label: t("Все") },
            { value: "0", label: t("Зачислено") },
            { value: "1", label: t("Списано") },
          ]}
        />
      </Grid>
      <Grid item sm={12} xl={6} xxl={4}>
        <DateSelector
          label={t("Период")}
          start={
            queryParams.created_at__gte
              ? DateTime.fromISO(queryParams.created_at__gte)
              : null
          }
          end={
            queryParams.created_at__lte
              ? DateTime.fromISO(queryParams.created_at__lte)
              : null
          }
          set_start={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__gte(val);
          }}
          set_end={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__lte(val);
          }}
          right
        />
      </Grid>
    </Grid>
  );
};
