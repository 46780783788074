import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  useGetUsersSelfQuery,
  useLazyGetUserInfosQuery,
} from "api/baseAPI/user";
import { DocumentPackages } from "./parts/DocumentPackages";
import { UserInfosForm } from "./parts/UserInfosForm";

export const Onboarding = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [editId, setEditId] = useState(0);
  const [step, setStep] = useState(0);
  const getUsersSelfQuery = useGetUsersSelfQuery();
  const [getUserInfos, getUserInfosMeta] = useLazyGetUserInfosQuery();

  useEffect(() => {
    if (getUsersSelfQuery.isSuccess && getUsersSelfQuery.data.is_approved) {
      navigate("/");
    }
  }, [getUsersSelfQuery]);

  useEffect(() => {
    document.title = "Onboarding";
  });

  useEffect(() => {
    if (getUserInfosMeta?.isSuccess && !step) {
      if (isEmpty(getUserInfosMeta.data.results)) {
        setEditId(0);
      }
      if (getUserInfosMeta.data.results?.[0]?.id) {
        setEditId(getUserInfosMeta.data.results?.[0]?.id);
      }
      setOpenModal(true);
      setStep(1);
    }
  }, [getUserInfosMeta, step]);

  useEffect(() => {
    if (getUsersSelfQuery?.data?.id) {
      getUserInfos({ user: getUsersSelfQuery?.data?.id });
    }
  }, [getUsersSelfQuery]);

  return (
    <>
      <StyledPageWrapper>
        {openModal ? (
          <UserInfosForm
            editId={editId}
            user={getUsersSelfQuery?.data?.id || 0}
            onSuccess={() => {
              setOpenModal(false);
              setStep(2);
            }}
          />
        ) : (
          <></>
        )}
        {step === 2 ? (
          <DocumentPackages
            businessArea={
              getUserInfosMeta.data?.results?.[0]?.business_area?.id || 0
            }
            registrationForm={
              getUserInfosMeta.data?.results?.[0]?.registration_form?.id || 0
            }
            user={getUsersSelfQuery?.data?.id || 0}
          />
        ) : (
          <></>
        )}
      </StyledPageWrapper>
    </>
  );
};

const StyledPageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: white;
`;
