import { find } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import {
  useCreateCustomizationRuleMutation,
  useGetCustomizerDepartmentsQuery,
} from "api/baseAPI/customizer";
import { useGetPointsAvailablePaymentMethodsQuery } from "api/baseAPI/points";
import { Layout } from "components/layouts/Layout";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { CustomizationRuleForm } from "./CustomizationRuleForm";
import { useCustomizationRuleFormLogic } from "./CustomizationRuleFormLogic";
import { PayFormRulePreview } from "./PayFormRulePreview";

export const CustomizationRuleCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadCrumbs = [
    [t("Управление"), ""],
    [t("Настройки формы"), "/admin/customizer"],
    [t("Создание правила отображения"), ""],
  ];

  const selectedPointManager = useSelectedPointManager();

  const [createRule, createRuleMeta] = useCreateCustomizationRuleMutation();
  const paymentMethodTypesQuery = useGetPointsAvailablePaymentMethodsQuery({
    point: selectedPointManager.point?.id,
  });
  const getCustomizerDepartments = useGetCustomizerDepartmentsQuery({});

  const { form } = useCustomizationRuleFormLogic({
    onSubmit: (values, setSubmitting) => {
      createRule({
        name: values.name,
        light: values.light ? Number(values.light) : null,
        dark: values.dark ? Number(values.dark) : null,
        theme_switch: values.theme_switch,
        is_ap_available: values.is_ap_available,
        is_gp_available: values.is_gp_available,
        ap_gp_position: values.ap_gp_position,
        company_name: values.company_name,
        messenger: values.messenger,
        phone_number: values.phone_number,
        email: values.email,
        partners_logos: values.partners_logos,
        is_active: values.is_active,
        oferta_style: values.oferta_style,
        locales: values.locales,
        available_payment_methods:
          values.available_payment_methods?.map((el) => el.slug) || [],
        extra_params: {},
        is_card_alias_enabled: false,
        point_id: selectedPointManager.point?.id,
        department:
          find(
            getCustomizerDepartments.data?.results,
            (el) => el?.gate_id  ===  selectedPointManager?.point?.department
          )?.id || 0,
      })
        .then(() => {
          navigate("/admin/customizer");
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (
      paymentMethodTypesQuery.isSuccess &&
      paymentMethodTypesQuery.data?.length > 0
    ) {
      const findCardMethod = paymentMethodTypesQuery.data.find(
        (el) => el.slug  ===  "card_debit_hpp"
      );
      form.setFieldValue(
        "payment_slug",
        findCardMethod?.id
          ? { id: findCardMethod.id, slug: findCardMethod.slug }
          : null
      );
    }
  }, [paymentMethodTypesQuery]);

  useErrorNotification([createRuleMeta]);
  useSuccessNotification([createRuleMeta]);

  useServerErrorsInForm(form.setFieldError, createRuleMeta.error);

  return (
    <Layout title={t("Создание правила отображения")} breadCrumbs={breadCrumbs}>
      <Box
        px={24}
        py={32}
        flex
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "calc(100vh - 124px)" }}
      >
        <Grid
          container
          hSpace={24}
          vSpace={24}
          style={{ position: "relative" }}
        >
          <Grid item sm={12} lg={3}>
            <CustomizationRuleForm
              submitButtonText={t("Добавить")}
              form={form}
              onCancel={() => navigate("/admin/customizer")}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={9}
            responsive={{ md: { mb: 0 }, sm: { mb: 80 } }}
          >
            <PayFormRulePreview form={form} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
