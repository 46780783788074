import { getQuery } from "utils/helpers";
import { SERVICE_TYPE } from "./pointServices";
import { baseSplitApi } from "./index";

export type AccountTariffsQueryParams = {
  page: string;
  page_size: string;
  search: string;
  point_id: number;
};
export type AccountTariffs = {
  id: number;
  status: number;
  name: string;
  updated_at: string;
  created_at: string;
  penalty: string;
  settlement: string;
  max_amount: string;
  min_amount: string;
  card_types: string[];
  mcc_codes: string[];
  geo_allowed: string[];
  geo_prohibited: string[];
  commission: {
    customer: { fix: number; max: number; min: number; percent: number };
    point: { fix: number; max: number; min: number; percent: number };
  };
  currency: string;
  point_id: number;
  service: {
    id: number;
    name: string;
    type: keyof typeof SERVICE_TYPE;
    max_amount: string;
    min_amount: string;
  };
};

export const accountTariffsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAccountTariffs: builder.query<
      { count: number; results: AccountTariffs[] },
      AccountTariffsQueryParams
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/account-tariffs/?${query.toString()}`;
      },
    }),
  }),
});

export const { useGetAccountTariffsQuery } = accountTariffsApi;
