import { getQuery } from "utils/helpers";
import { TRANSACTION_STATUSES } from "./transactions";
import { baseSplitApi } from "./index";

type InvoiceTransaction = {
  amount: string;
  amount_currency: string;
  created_at: string;
  external_id: string;
  invoice_uuid: string;
  description: string;
  page: { uuid: string; id: number; title: string };
  status: keyof typeof TRANSACTION_STATUSES;
  uuid: string;
  payment_method_slug: string;
  fields: Record<string, string>;
  failure_reason_code: number;
};
export type InvoiceTransactionsQueryParams = {
  offset: string;
  limit: string;
  order_by: string;
  page: string;
  page_size: string;
  search: string;
  invoice_uuid: string;
  created_at__gte: string;
  created_at__lte: string;
  payment_method_slug: string;
  invoice__point_id: string;
};

export const checkoutInvoiceTransactionsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getInvoiceTransactions: builder.query<
      {
        results: InvoiceTransaction[];
      },
      InvoiceTransactionsQueryParams
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/checkout/invoices/invoice-transaction/list/?${query.toString()}`;
      },
    }),
    getInvoiceTransaction: builder.query<InvoiceTransaction, { id: string }>({
      query: ({ id }) => `/checkout/invoices/invoice-transaction/${id}/`,
    }),
  }),
});

export const { useGetInvoiceTransactionQuery, useGetInvoiceTransactionsQuery } =
  checkoutInvoiceTransactionsApi;
