import { baseSplitApi } from "./index";

export const contactWalletsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getContactWallets: builder.query<
      {
        count: number;
        results: {
          id: number;
          name: string;
          point_manager: number;
          currency: string;
          account_wallet: {
            id: number;
            name: string;
            currency: string;
          };
        }[];
      },
      void
    >({
      query: () => `/contact-wallets/`,
      providesTags: ["ContactWallets"],
    }),
    getContactWallet: builder.query<
      { id: number; name: string },
      { id: number }
    >({
      query: ({ id }) => `/contact-wallets/${id}/`,
    }),
    createContactWallet: builder.mutation<
      {
        account_wallet_id: number;
        currency: string;
        name: string;
        point_manager: number;
      },
      {
        account_wallet_id: number;
        currency: string;
        name: string;
        point_manager: number;
      }
    >({
      query: (data) => ({
        url: `/contact-wallets/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ContactWallets"],
    }),
  }),
});

export const {
  useGetContactWalletsQuery,
  useGetContactWalletQuery,
  useCreateContactWalletMutation,
} = contactWalletsApi;
