import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { InvoiceTransactionsQueryParams } from "api/baseAPI/checkoutInvoiceTransactions";
import { DateSelector } from "components/RangeSelector/DateSelector";
import { Grid } from "UI/Grid";
import { IUseUrlQueryResult } from "utils/url";

export const TransactionsFiltersRow = ({
  queryParams,
  querySetters,
}: IUseUrlQueryResult<InvoiceTransactionsQueryParams>) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      my={24}
      style={{
        justifyContent: "space-between",
      }}
      responsive={{
        sm: { direction: "column-reverse" },
        md: { direction: "row" },
      }}
      vSpace={12}
    >
      <Grid item sm={12} md={6} xxl={5}>
        <DateSelector
          label={t("Период")}
          start={
            queryParams.created_at__gte
              ? DateTime.fromISO(queryParams.created_at__gte)
              : null
          }
          end={
            queryParams.created_at__lte
              ? DateTime.fromISO(queryParams.created_at__lte)
              : null
          }
          set_start={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__gte(val);
          }}
          set_end={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__lte(val);
          }}
        />
      </Grid>
    </Grid>
  );
};
