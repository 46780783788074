import { useTranslation } from "react-i18next";
import { TextInput } from "UI/Form/TextInput";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";

export const PhoneInput = (props: CustomerAccountInputProps) => {
  const { t } = useTranslation();
  const { id, form } = props;
  return (
    <TextInput
      // eslint-disable-next-line no-octal-escape
      mask="+38\0000000000"
      onAccept={(value) => {
        form.setFieldValue(`payouts[${id}].customer_account`, String(value));
      }}
      name="customer_account"
      placeholder={t("Номер телефона")}
      value={form.values.payouts[id].customer_account}
      onBlur={(e) => {
        form.handleBlur(e);
        form.setFieldTouched(`payouts[${id}].customer_account`, true);
      }}
      error={
        !!(form.touched.payouts?.[id]?.customer_account &&
        form.errors.payouts?.[id]?.customer_account)
      }
      helperText={
        t<any>(
          form.touched.payouts?.[id]?.customer_account &&
            form.errors.payouts?.[id]?.customer_account &&
            form.errors.payouts?.[id]?.customer_account
        ) || ""
      }
      size="small"
    />
  );
}
