import { includes } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Divider } from "UI/Divider";
import { Typography } from "UI/Typography";
import { TransactionFraud } from "api/baseAPI/transactionFraud";
import { useCancelTransactionMutation } from "api/baseAPI/transactions";
import {
  useGetUsersMeAgoraPermissionsQuery,
  useGetUsersMeRetrieverPermissionsQuery,
  useGetUsersMeTransactionFraudInfoPermissionsQuery,
} from "api/baseAPI/user";
import { IconCellOpen } from "components/table/IconCellOpen";
import { ReactComponent as ReloadIcon } from "utils/img/arrows-reload.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useMenuState } from "utils/useMenuState";

type Props = {
  onOpenChat: () => void;
  onCancel: () => void;
  checkedList: TransactionFraud[];
};

export const ModalActions = (props: Props): React.ReactElement => {
  const { onOpenChat, onCancel, checkedList } = props;
  const [openActions, setOpenActions] = useState(false);
  const { t } = useTranslation();
  const [isMenuCollapsed] = useMenuState();
  const [cancelTransaction, cancelTransactionMeta] =
    useCancelTransactionMutation();

  const getTransactionFraudInfoPermissionsQuery =
    useGetUsersMeTransactionFraudInfoPermissionsQuery();
  const getRetrieverPermissionsQuery = useGetUsersMeRetrieverPermissionsQuery();
  const getAgoraPermissionsQuery = useGetUsersMeAgoraPermissionsQuery();

  useSuccessNotification([cancelTransactionMeta]);
  useErrorNotification([cancelTransactionMeta]);

  useEffect(() => {
    if (cancelTransactionMeta.isSuccess) onCancel();
  }, [cancelTransactionMeta]);

  return (
    <StyledWraper open={isMenuCollapsed}>
      <Box flex style={{ gap: 24 }}>
        <Box responsive={{ sm: { hidden: true }, lg: { hidden: false } }}>
          <Button
            variant="outlined"
            disabled={cancelTransactionMeta.isLoading}
            onClick={() => onCancel()}
          >
            {t("Отмена")}
          </Button>
        </Box>
        <Box responsive={{ sm: { hidden: true }, lg: { hidden: false } }}>
          <Divider color="rgba(200, 207, 220, 0.7)" />
        </Box>
        <StyledBox>
          <Box flex alignItems="center" nowrap>
            <Box
              flex
              alignItems="center"
              justifyContent="center"
              style={{
                height: 48,
                width: 48,
                background: "#3EA959",
                margin: "auto",
              }}
            >
              <Typography variant="h3" style={{ color: "#FFFFFF" }}>
                {checkedList.length}
              </Typography>
            </Box>
            <Box ml={16}>
              <Typography mb={8} variant="h6" style={{ color: "#FFFFFF" }}>
                {t("Транзакций выбрано")}
              </Typography>
              <Typography variant="subtitle2" style={{ color: "#FFFFFF" }}>
                {t("Выберите действие для транзакций")}
              </Typography>
            </Box>
          </Box>
          <Box responsive={{ sm: { hidden: false }, lg: { hidden: true } }}>
            <IconCellOpen
              open={openActions}
              onClick={() => setOpenActions(!openActions)}
            />
          </Box>
        </StyledBox>
      </Box>
      {openActions ? (
        <Box responsive={{ sm: { hidden: false }, lg: { hidden: true } }}>
          <Divider direction="vertical" color="rgba(200, 207, 220, 0.7)" />
        </Box>
      ) : (
        <></>
      )}
      <Box
        flex
        style={{ gap: 24 }}
        responsive={{ sm: { hidden: true }, lg: { hidden: false } }}
      >
        <Button
          variant="text"
          iconStart={<ReloadIcon />}
          disabled={
            cancelTransactionMeta.isLoading ||
            !includes(getRetrieverPermissionsQuery.data, "change_transaction")
          }
          onClick={() =>
            cancelTransaction({ id: checkedList[0].transaction.id })
          }
        >
          {t("Согласие на возврат")}
        </Button>
        <Button
          disabled={
            cancelTransactionMeta.isLoading ||
            !includes(getAgoraPermissionsQuery.data, "change_chat") ||
            !includes(getTransactionFraudInfoPermissionsQuery.data, "change")
          }
          onClick={() => onOpenChat()}
        >
          {t("Диспут")}
        </Button>
      </Box>
      {openActions ? (
        <Box
          flex
          justifyContent="space-between"
          style={{ gap: 4, width: "100%" }}
          responsive={{ sm: { hidden: false }, lg: { hidden: true } }}
        >
          <StyledButton
            variant="outlined"
            size="small"
            disabled={cancelTransactionMeta.isLoading}
            onClick={() => onCancel()}
          >
            {t("Отмена")}
          </StyledButton>
          <StyledButton
            variant="text"
            size="small"
            iconStart={<ReloadIcon />}
            disabled={
              cancelTransactionMeta.isLoading ||
              !includes(getRetrieverPermissionsQuery.data, "change_transaction")
            }
            onClick={() =>
              cancelTransaction({ id: checkedList[0].transaction.id })
            }
          >
            {t("Согласие на возврат")}
          </StyledButton>
          <StyledButton
            size="small"
            disabled={
              cancelTransactionMeta.isLoading ||
              !includes(getAgoraPermissionsQuery.data, "change_chat") ||
              !includes(getTransactionFraudInfoPermissionsQuery.data, "change")
            }
            onClick={() => onOpenChat()}
          >
            {t("Диспут")}
          </StyledButton>
        </Box>
      ) : (
        <></>
      )}
    </StyledWraper>
  );
}

const StyledWraper = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  gap: 16px;
  right: 24px;
  bottom: 24px;
  background: #19202e;
  box-shadow: 0px 14px 14px rgba(25, 32, 46, 0.2);
  width: ${(props: { open: boolean }) =>
    props.open ? "calc(100% - 188px)" : "calc(100% - 352px)"};
  @media (${RESPONSIVE_SIZES.lg}) {
    flex-direction: column;
  }
  @media (${RESPONSIVE_SIZES.sm}) {
    width: calc(100% - 48px);
    flex-direction: column;
    right: 0;
    bottom: 65px;
  }
`;
const StyledButton = styled(Button)`
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const StyledBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (${RESPONSIVE_SIZES.lg}) {
    width: 100%;
  }
`;
