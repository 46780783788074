import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useGetTransactionServiceFieldsViewQuery } from "api/baseAPI/transactions";
import { Grid } from "UI/Grid";
import { getColors } from "utils/getColors";
import { TransactionRefunds } from "./TransactionRefunds";
import { TransactionServiceFields } from "./TransactionServiceFields";

type Props = {
  id: number;
  refundedAmount: number;
};

export const TransactionRowInfo = ({
  id,
  refundedAmount,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [switchValue, setSwitchValue] = useState(true);

  const { accentColor } = getColors();

  const { data } = useGetTransactionServiceFieldsViewQuery({ id });

  if (!refundedAmount  && !data?.length) return <></>;

  return (
    <Grid item sm={9}>
      <StyledBox>
        {!!data?.length && (
          <StyledSwitch
            active={switchValue || !refundedAmount}
            $accentColor={accentColor}
            onClick={() => setSwitchValue(true)}
          >
            {t("Дополнительная информация")}
          </StyledSwitch>
        )}
        {!!refundedAmount && (
          <StyledSwitch
            active={!switchValue || !data?.length}
            $accentColor={accentColor}
            onClick={() => setSwitchValue(false)}
          >
            {t("Список возвратов")}
          </StyledSwitch>
        )}
      </StyledBox>
      {(switchValue || !refundedAmount) && <TransactionServiceFields id={id} />}
      {(!switchValue || !data?.length) && <TransactionRefunds id={id} />}
    </Grid>
  );
};

const StyledBox = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSwitch = styled.div<{ $accentColor: string; active: boolean }>`
  width: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${(props) => (props.active ? props.$accentColor : "#19202e")};
  padding-bottom: 8px;
  border-bottom: 1px solid
    ${(props) => (props.active ? props.$accentColor : "#c8cfdc")};
`;
