import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  useChangePasswordMutation,
  useGetUsersSelfQuery,
} from "api/baseAPI/user";
import { IconColor } from "components/table/TableRowActions";
import { ReactComponent as EyeIcon } from "utils/img/eye.svg";
import { ReactComponent as HideEyeIcon } from "utils/img/hide-eye.svg";
import { ReactComponent as LockIcon } from "utils/img/lock.svg";
import { ReactComponent as ShieldCheckIcon } from "utils/img/shield-check.svg";
import { useErrorNotification } from "utils/notificationWrappers";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
};

export const ModalChangePassword = (props: Props): React.ReactElement => {
  const { onClose, onSuccess } = props;
  const { t } = useTranslation();

  const getUsersSelfQuery = useGetUsersSelfQuery();
  const [changePassword, { isSuccess, isError, isLoading }] =
    useChangePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  const form = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newConfirmPassword: "",
      otp: "",
      showOldPassword: false,
      showNewPassword: false,
      showNewConfirmPassword: false,
    },
    onSubmit: (values) => {
      changePassword({
        old_password: values.oldPassword,
        new_password: values.newPassword,
        otp: values.otp,
      });
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string()
        .when("newPassword", {
          is: (val: string) => (!!(val && val.length > 0)),
          then: Yup.string().notOneOf(
            [Yup.ref("newPassword")],
            t("Пароли не должны совпадать")
          ),
        })
        .min(8, t("Длина пароля не меньше 8 символов"))
        .required(t("Обязательное поле")),
      newPassword: Yup.string()
        .min(8, t("Длина пароля не меньше 8 символов"))
        .required(t("Обязательное поле")),
      newConfirmPassword: Yup.string()
        .when("newPassword", {
          is: (val: string) => (!!(val && val.length > 0)),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            t("Пароли должны совпадать")
          ),
        })
        .min(8, t("Длина пароля не меньше 8 символов"))
        .required(t("Обязательное поле")),
      otp: Yup.string().when("oldPassword", {
        is: () => !!getUsersSelfQuery.data?.otp_enabled,
        then: Yup.string()
          .required(t("Обязательное поле"))
          .min(6, t("Значение этого поля должно состоять из 6 цифр")),
      }),
    }),
  });
  useErrorNotification([{ isError }]);

  useTranslateFormErrors(form);

  return (
    <Grid
      item
      sm={12}
      lg={6}
      xl={4}
      style={{
        background: "#ffffff",
      }}
      responsive={{ md: { mt: 0 }, sm: { mt: -71 } }}
    >
      <Box p={32}>
        <Typography variant="h5" mb={24}>
          {t("Смена пароля")}
        </Typography>
        <TextInput
          size="small"
          type={form.values.showOldPassword ? "text" : "password"}
          label={t("Введите старый пароль")}
          placeholder={t("Введите пароль")}
          onChange={(value) => form.setFieldValue("oldPassword", value)}
          value={form.values.oldPassword}
          error={form.touched.oldPassword && Boolean(form.errors.oldPassword)}
          helperText={form.touched.oldPassword && form.errors.oldPassword}
          iconEnd={
            <Icon
              onClick={() =>
                form.setFieldValue(
                  "showOldPassword",
                  !form.values.showOldPassword
                )
              }
            >
              {form.values.showOldPassword ? <EyeIcon /> : <HideEyeIcon />}
            </Icon>
          }
        />
        <Box mt={24}>
          <TextInput
            size="small"
            type={form.values.showNewPassword ? "text" : "password"}
            label={t("Введите новый пароль")}
            placeholder={t("Введите пароль")}
            onChange={(value) => form.setFieldValue("newPassword", value)}
            value={form.values.newPassword}
            error={form.touched.newPassword && Boolean(form.errors.newPassword)}
            helperText={form.touched.newPassword && form.errors.newPassword}
            iconEnd={
              <Icon
                onClick={() =>
                  form.setFieldValue(
                    "showNewPassword",
                    !form.values.showNewPassword
                  )
                }
              >
                {form.values.showNewPassword ? <EyeIcon /> : <HideEyeIcon />}
              </Icon>
            }
          />
        </Box>
        <Box mt={24}>
          <TextInput
            size="small"
            type={form.values.showNewConfirmPassword ? "text" : "password"}
            label={t("Повторите новый пароль")}
            placeholder={t("Введите пароль")}
            onChange={(value) =>
              form.setFieldValue("newConfirmPassword", value)
            }
            value={form.values.newConfirmPassword}
            error={
              form.touched.newConfirmPassword &&
              Boolean(form.errors.newConfirmPassword)
            }
            helperText={
              form.touched.newConfirmPassword && form.errors.newConfirmPassword
            }
            iconEnd={
              <Icon
                onClick={() =>
                  form.setFieldValue(
                    "showNewConfirmPassword",
                    !form.values.showNewConfirmPassword
                  )
                }
              >
                {form.values.showNewConfirmPassword ? (
                  <EyeIcon />
                ) : (
                  <HideEyeIcon />
                )}
              </Icon>
            }
          />
        </Box>
        {getUsersSelfQuery?.data?.otp_enabled ? (
          <Box mt={24}>
            <TextInput
              size="small"
              label={t("Введите код OTP")}
              placeholder={t("Введите код")}
              onChange={(value) =>
                form.setFieldValue(
                  "otp",
                  value.replace(/[^0-9]/g, "").slice(0, 6)
                )
              }
              value={form.values.otp}
              error={form.touched.otp && Boolean(form.errors.otp)}
              helperText={form.touched.otp && form.errors.otp}
              iconStart={
                getUsersSelfQuery?.data?.otp_enabled ? (
                  <IconColor color="rgba(62, 169, 89, 1)">
                    <ShieldCheckIcon />
                  </IconColor>
                ) : (
                  <LockIcon />
                )
              }
            />
          </Box>
        ) : (
          <></>
        )}
        <Grid
          container
          flex
          alignItems="center"
          justifyContent="space-between"
          mt={24}
          vSpace={12}
        >
          <Grid item>
            <Button
              mr={24}
              size="small"
              disabled={isLoading}
              variant="outlined"
              onClick={() => onClose()}
            >
              {t("Отмена")}
            </Button>
          </Grid>
          <Grid item sm="fill">
            <Button
              size="small"
              disabled={isLoading}
              fullwidth
              onClick={() => form.handleSubmit()}
            >
              {t("Сменить пароль")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
const Icon = styled.div`
  cursor: pointer;
  svg path {
    fill: #393939 !important;
  }
`;
