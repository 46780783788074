import { find, isEmpty, map } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { getColors } from "utils/getColors";
import { ReactComponent as ReplenishmentIcon } from "utils/img/Replenishment.svg";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { ReactComponent as CardIcon } from "utils/img/card.svg";
import { ReactComponent as DocumentationMenuIcon } from "utils/img/documentation-menu-icon.svg";
import { ReactComponent as FileMenuIcon } from "utils/img/file-menu.svg";
import { ReactComponent as MainComponentIcon } from "utils/img/main-component.svg";
import { ReactComponent as ReportMenuIcon } from "utils/img/report-menu-icon.svg";
import { ReactComponent as ServicesIcon } from "utils/img/services.svg";
import { ReactComponent as SliderIcon } from "utils/img/slider.svg";
import { ReactComponent as SwatchesPaletteIcon } from "utils/img/swatches-palette.svg";
import { ReactComponent as WalletIcon } from "utils/img/wallet.svg";
import { RESPONSIVE_SIZES } from "utils/tools";
import { MenuItem } from "./MenuItem";

export const menuLink: Record<string, string> = {
  report: "/",
  payout_create: "/payout-create",
  payout_history: "/payout-history",
  payout_templates: "/payout-templates",
  card_top_up: "/card-top-up",
  crypto_top_up: "/crypto-top-up",
  invoice_top_up: "/invoice-top-up",
  binance_pay_top_up: "/binance-pay-top-up",
  tariffs: "/admin/tariffs",
  point_managers: "/admin/point-managers",
  api_credentials: "/admin/api-credentials",
  point_log: "/admin/point-logs",
  point_settings: "/admin/point-settings",
  customizer: "/admin/customizer",
  invoicing_page_templates: "/invoicing/page-templates",
  invoicing_page_transactions: "/invoicing/page-transactions",
  invoicing_pages: "/invoicing/pages",
  invoice_invoices: "/invoice/invoices",
  invoice_templates: "/invoice/templates",
  invoice_transactions: "/invoice/transactions",
  services_tip_users: "/services/tip-users",
  soc_invoice: "/services/soc-invoice-data",
  w2w: "/w2w",
  reports: "/reports",
  account_wallet_transactions: "/account-wallet-transactions",
  transactions: "/transactions",
  report_requests: "/report-requests",
  transaction_fraud: "/transaction-fraud",
};

const host = window.location.host.split(".");
host[0] = "docs";

export const Menu = ({
  isMenuCollapsed,
  toggleMenu,
}: {
  isMenuCollapsed: boolean;
  toggleMenu: () => void;
}): React.ReactElement => {
  const { t } = useTranslation();

  const cabinetOpenedMenu = window.localStorage.getItem("cabinetOpenedMenu");
  const [openedMenu, setOpenedMenu] = useState<string | null>(
    cabinetOpenedMenu
  );
  const { sidebarHoverBackground } = getColors();
  const selectedPointManager = useSelectedPointManager();

  useEffect(() => {
    if (isMenuCollapsed) {
      setOpenedMenu(null);
      window.localStorage.removeItem("cabinetOpenedMenu");
    }
  }, [isMenuCollapsed]);

  const handleClickToOpenMenu = (openedMenuItem: string) => {
    if (openedMenu  ===  openedMenuItem) {
      window.localStorage.removeItem("cabinetOpenedMenu");
    } else {
      window.localStorage.setItem("cabinetOpenedMenu", openedMenuItem);
    }
    setOpenedMenu((prev) => {
      if (prev  ===  openedMenuItem) {
        return "";
      }
      return openedMenuItem;
    });
  };

  const handleClickItemWithoutChild = () => {
    toggleMenu();
    setOpenedMenu(null);
    window.localStorage.removeItem("cabinetOpenedMenu");
  };

  const parentComponents = useMemo(() => (
      selectedPointManager?.available_components
        ?.filter((el) => !el.parent)
        ?.sort((el, elNext) => el.listing_order - elNext.listing_order)
        ?.map((item) => item?.name?.toLocaleLowerCase()) || []
    ), [selectedPointManager]);

  const childrenComponents: Record<string, string[]> = useMemo(() => (
      selectedPointManager?.available_components
        ?.filter((el) => el.parent)
        ?.sort((el, elNext) => el.listing_order - elNext.listing_order)
        ?.reduce((acc, item) => {
          const parent = find(selectedPointManager?.available_components, {
            id: item.parent,
          });
          const parentName = parent?.name?.toLocaleLowerCase() || item.parent;
          return {
            ...acc,
            [parentName]: [
              // @ts-ignore
              ...(acc?.[parentName] || []),
              item?.name?.toLocaleLowerCase(),
            ],
          };
        }, {}) || {}
    ), [selectedPointManager]);

  const menuIcons: Record<string, JSX.Element> = {
    report: <ReportMenuIcon />,
    payouts: <CardIcon />,
    replenishment: <ReplenishmentIcon />,
    admin: <SliderIcon />,
    invoicing: <SwatchesPaletteIcon />,
    services: <ServicesIcon />,
    invoice: <ServicesIcon />,
    wallets: <WalletIcon />,
    reporting: <FileMenuIcon />,
    transaction_fraud: <MainComponentIcon />,
  };

  const menuTitles: Record<string, string> = {
    report: t("Итоговый отчет"),
    payouts: t("Выплаты"),
    payout_create: t("Создать"),
    payout_history: t("История"),
    payout_templates: t("Шаблоны"),
    replenishment: t("Пополнение"),
    card_top_up: t("Картой"),
    crypto_top_up: t("Криптовалютой"),
    binance_pay_top_up: t("Binance Pay"),
    invoice_top_up: t("Платежная страница"),
    admin: t("Управление"),
    tariffs: t("Тарифы"),
    point_managers: t("Доступы"),
    api_credentials: t("Реквизиты"),
    point_settings: t("Настройки точки"),
    point_log: t("Лог API запросов"),
    customizer: t("Настройки формы"),
    invoicing: t("Платежные страницы"),
    invoicing_page_templates: t("Шаблоны"),
    invoicing_page_transactions: t("Транзакции"),
    invoicing_pages: t("Список страниц"),
    invoice: t("Инвойсинг"),
    invoice_invoices: t("Инвойсы"),
    invoice_templates: t("Шаблоны"),
    invoice_transactions: t("Транзакции"),
    services: t("Платежные сервисы"),
    services_tip_users: t("QR для чаевых"),
    soc_invoice: t("Выставление счета"),
    wallets: t("Кошельки"),
    w2w: t("Перевод между кошельками"),
    reporting: t("Отчетность"),
    reports: t("Отчеты"),
    account_wallet_transactions: t("История баланса"),
    transactions: t("Транзакции"),
    report_requests: t("Запросы"),
    transaction_fraud: t("Чарджбеки"),
  };

  return (
    <StyledWrapperMenu>
      <div>
        <>
          {map(parentComponents, (parent: string) => {
            if (!isEmpty(childrenComponents?.[parent])) {
              return (
                <MenuItem
                  key={parent}
                  isOpened={openedMenu  ===  parent}
                  isActive={openedMenu  ===  parent}
                  onClick={() => handleClickToOpenMenu(parent)}
                  toggleMenu={() => toggleMenu()}
                  title={menuTitles[parent]}
                  icon={menuIcons[parent] || <DocumentationMenuIcon />}
                  childMenus={map(childrenComponents?.[parent], (child) => ({
                    to: menuLink[child] || "/",
                    title: menuTitles[child] || "",
                    isAvailable: true,
                  }))}
                  isMenuCollapsed={isMenuCollapsed}
                  sidebarHoverBackground={sidebarHoverBackground}
                />
              );
            } 
              return (
                <ItemWithoutChild
                  key={parent}
                  icon={menuIcons[parent] || <DocumentationMenuIcon />}
                  to={menuLink[parent] || "/"}
                  title={menuTitles[parent] || ""}
                  isMenuCollapsed={isMenuCollapsed}
                  toggleMenu={handleClickItemWithoutChild}
                />
              );
            
          })}
        </>
      </div>
      <div>
        <StyledDrawer />

        <StyledListItemCollapsed
          onClick={() => handleClickToOpenMenu("documentation")}
          backgroundhover={sidebarHoverBackground}
          active={openedMenu  ===  "documentation"}
        >
          <StyledFlex>
            <StyledMenuItemIconWrapper>
              <DocumentationMenuIcon />
            </StyledMenuItemIconWrapper>
            {!isMenuCollapsed && t("Документация")}
          </StyledFlex>
          <StyledArrowUpDown open={openedMenu  ===  "documentation"} />
        </StyledListItemCollapsed>
        {openedMenu  ===  "documentation" && (
          <StyledListChildren>
            <StyledMenuItemA
              href={`https://${host.join(".")}`}
              target="_blank"
              backgroundhover={sidebarHoverBackground}
            >
              <StyledFakeIcon />
              {t("Документация")}
            </StyledMenuItemA>
          </StyledListChildren>
        )}
      </div>
    </StyledWrapperMenu>
  );
}

const ItemWithoutChild = ({
  to,
  title,
  icon,
  isMenuCollapsed,
  isChild,
  toggleMenu,
}: {
  to: string;
  title: string;
  icon?: React.ReactElement;
  isMenuCollapsed: boolean;
  isChild?: boolean;
  toggleMenu: () => void;
}) => {
  const { sidebarHoverBackground } = getColors();
  return (
    <StyledMenuItemLink
      onClick={() => toggleMenu()}
      to={to}
      backgroundhover={sidebarHoverBackground}
      small={`${!!isChild}`}
    >
      {icon ? (
        <StyledMenuItemIconWrapper>{icon}</StyledMenuItemIconWrapper>
      ) : (
        <StyledFakeIcon />
      )}
      {!isMenuCollapsed && title}
    </StyledMenuItemLink>
  );
}

const StyledWrapperMenu = styled.div`
  height: calc(100vh - 132px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  @media (${RESPONSIVE_SIZES.md}) {
    height: calc(100vh - 182px);
  }
`;

const StyledListItemCollapsed = styled.div<{
  active?: boolean;
  backgroundhover: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  height: 52px;
  cursor: pointer;
  color: #ffffff;
  ${(props) =>
    props.active && !!props.backgroundhover
      ? `background: ${props.backgroundhover};`
      : ""};

  &:hover {
    background: ${(props) => props.backgroundhover};
  }
`;
const StyledListChildren = styled.div`
  margin-top: 10px;
`;
const StyledFlex = styled.div`
  display: flex;
`;
const StyledDrawer = styled.div`
  border-top: 1px solid #2b3647;
`;
const StyledFakeIcon = styled.div`
  margin-right: 30px;
`;

const StyledMenuItemA = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 18px;
  height: 29px;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;

  &:hover {
    background: ${(props: { backgroundhover: string }) =>
      props.backgroundhover};
  }
`;

const StyledMenuItemLink = styled(Link)<{
  active?: boolean;
  backgroundhover: string;
  small?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 18px;
  height: ${(props) => (props.small  ===  "true" ? "29px" : "52px")};
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;

  ${(props: { active?: boolean }) => (props.active ? `opacity: 0.4;` : "")}
  &:hover {
    background: ${(props) => props.backgroundhover};
  }
`;
const StyledMenuItemIconWrapper = styled.div`
  margin-right: 10px;

  svg {
    width: 18px !important;

    path {
      fill: #ffffff !important;
    }
  }
`;
const StyledArrowUpDown = styled(ArrowUpDown)`
  padding-left: 16px;

  path {
    fill: #ffffff !important;
  }

  ${(props: { open: boolean }) =>
    props.open
      ? `
          padding-right: 16px;
          padding-left: 0;
          transform: rotate(180deg);
        `
      : ""};
`;
