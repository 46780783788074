export const visibleNumber = (
  value: number | string,
  charsAfterPoint?: number
) =>
  value
    ? `${(String(value).split(".")[0] || "").replace(
        /(?=(\d{3})+$)\B/g,
        " "
      )}.${
        Number(value || 0)
          .toFixed(charsAfterPoint || 2)
          .split(".")[1]
      }`
    : (0).toFixed(charsAfterPoint || 2);

export const numberForInput = (value: string) =>
  value
    ? `${(String(value).split(".")[0] || "").replace(/(?=(\d{3})+$)\B/g, " ")}${
        value && String(value)?.includes(".") ? `.${value.split(".")[1]}` : ""
      }`
    : "";

export const handleChangeNumber = (value: string) => {
  const trimValue = value.replace(" ", "");

  return `${trimValue.split(".")[0] || ""}${
    trimValue.includes(".")
      ? `.${
          (trimValue.split(".")[1] || "").length > 2
            ? trimValue.split(".")[1].slice(0, 2)
            : trimValue.split(".")[1]
        }`
      : ""
  }`;
};
