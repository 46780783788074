import { Action, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { ThunkAction } from "redux-thunk";
import rootReducer, { RootState } from "./reducers";

import { baseSplitApi } from "./api/baseAPI";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([baseSplitApi.middleware]),
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
setupListeners(store.dispatch);
