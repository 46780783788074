import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { PointManagerItem } from "api/baseAPI/pointManagers";
import {
  selectCurrentBanner,
  selectCurrentSelectedPointManager,
  selectCurrentSelectedWalletManager,
  selectCurrentWalletInfoManager,
} from "reducers/basicSlice";

export const useSelectedPointManager = () => {
  const pointManagerStore = window.localStorage.getItem("point_manager");
  const pointManager: PointManagerItem = pointManagerStore
    ? { ...JSON.parse(pointManagerStore) }
    : {};
  const selectedPointManager = useSelector(selectCurrentSelectedPointManager);

  return useMemo(
    () =>
      !isEmpty(pointManager)
        ? { ...pointManager }
        : { ...selectedPointManager },
    [selectedPointManager]
  );
};
export const useSelectedWalletManager = () => {
  const walletManagerStore = window.localStorage.getItem("selected_wallet");
  const walletManager: { id: number; name: string } = walletManagerStore
    ? { ...JSON.parse(walletManagerStore) }
    : {};
  const selectedWalletManager = useSelector(selectCurrentSelectedWalletManager);

  return useMemo(
    () =>
      !isEmpty(walletManager)
        ? { ...walletManager }
        : { ...selectedWalletManager },
    [selectedWalletManager]
  );
};
export const useWalletInfoManager = () => {
  const walletInfo = useSelector(selectCurrentWalletInfoManager);

  return useMemo(() => ({ ...walletInfo }), [walletInfo]);
};
export const useBanner = () => {
  const banner = useSelector(selectCurrentBanner);

  return useMemo(() => ({ ...banner }), [banner]);
};
