import React, { Component, ErrorInfo, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: { children: React.ReactElement }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <StyledErrorMessageWrapper className="p-10">
          <StyledErrorHeader>Oops! Something went wrong</StyledErrorHeader>
          <div>Please reload the page or contact technical support</div>
        </StyledErrorMessageWrapper>
      );
    }

    return children;
  }
}

const StyledErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #000;
`;

const StyledErrorHeader = styled.h1`
  font-size: 32px;
  font-weight: 300;
  color: red;
  font-style: italic;
`;
