import React from "react";
import styled from "styled-components";
import { Margin, marginProps, marginStyle } from "./utility/maring";
import { Padding, paddingProps, paddingStyle } from "./utility/padding";
import {
  getResponsiveProps,
  getResponsiveStyle,
  Responsive,
} from "./utility/responsive";
import { Text, textProps, textStyle } from "./utility/text";

type Variants =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "subtitle3"
  | "inputText"
  | "body"
  | "buttonSmall";

type TypographyProps = {
  children: React.ReactNode;
  variant?: Variants;
  className?: string;
  style?: React.CSSProperties;
} & Padding &
  Margin &
  Text &
  Responsive<Margin & Padding>;

export const Typography = ({
  children,
  className,
  variant,
  style,
  ...otherProps
}: TypographyProps) => (
  <StyledTypography
    className={className}
    style={style}
    variant={variant}
    {...paddingProps(otherProps)}
    {...marginProps(otherProps)}
    {...textProps(otherProps)}
    {...getResponsiveProps(otherProps)}
  >
    {children}
  </StyledTypography>
);

const StyledTypography = styled.div<
  {
    variant?: Variants;
  } & Padding &
    Margin &
    Text &
    Responsive<Margin & Padding>
>`
  font-weight: 500;
  font-size: 14px;
  color: #19202e;

  ${(props) =>
    [
      marginStyle,
      paddingStyle,
      textStyle,
      getResponsiveStyle<Padding & Margin>([paddingStyle, marginStyle]),
    ]
      .map((fn) => fn(props))
      .join("")}

  ${(props) =>
    props.variant === "h1"
      ? "font-size: 16px; line-height: 19px; font-weight: 500;"
      : ""};
  ${(props) =>
    props.variant === "h3" ? "font-size: 24px; line-height: 29px;" : ""};
  ${(props) =>
    props.variant === "h4" ? "font-size: 20px; line-height: 24px;" : ""};
  ${(props) =>
    props.variant === "h5" ? "font-size: 18px; line-height: 22px;" : ""};
  ${(props) =>
    props.variant === "h6" ? "font-size: 16px; line-height: 19px;" : ""};
  ${(props) =>
    props.variant === "subtitle1"
      ? "font-weight: 400; font-size: 16px; line-height: 19px;"
      : ""};
  ${(props) =>
    props.variant === "subtitle2"
      ? "font-weight: 400; font-size: 14px; line-height: 17px;"
      : ""};
  ${(props) =>
    props.variant === "subtitle3"
      ? "font-weight: 400; font-size: 12px; line-height: 15px;"
      : ""};
  ${(props) =>
    props.variant === "inputText" ? "font-size: 14px; line-height: 17px;" : ""};
  ${(props) =>
    props.variant === "buttonSmall"
      ? "font-weight: 600; font-size: 10px; line-height: 24px;"
      : ""};
`;
