import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { ReactComponent as LinkIcon } from "utils/img/link.svg";
import { ReactComponent as SquareCheckIcon } from "utils/img/square-check.svg";

type Props = {
  create?: boolean;
  isTemplate?: boolean;
  payUrl: string;
};

export const ModalSuccess = (props: Props): React.ReactElement => {
  const { create, payUrl, isTemplate } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pageBackground } = getColors();

  const handleCopy = () => {
    navigator.clipboard.writeText(payUrl);
    toast.info(t("QR-код был сохранен в буфер обмена"));
  };

  return (
    <Box p={27} style={{ textAlign: "center" }}>
      <Box
        flex
        alignItems="center"
        justifyContent="center"
        style={{
          height: 84,
          width: 84,
          background: "rgba(62, 169, 89, 0.1)",
          margin: "auto",
        }}
      >
        <SquareCheckIcon />
      </Box>
      {!isTemplate ? (
        <>
          <Typography variant="h4" mt={24}>
            {create
              ? t("Платежная страница успешно создана")
              : t("Платежная страница успешно обновлена")}
          </Typography>
          <Typography
            variant="subtitle2"
            mt={12}
            mb={12}
            style={{ color: "#838383" }}
          >
            {t("Ссылка сгенерирована и готова к использованию")}
          </Typography>

          <Typography
            mb={12}
            variant="subtitle3"
            style={{ textAlign: "start" }}
          >
            {t("Ссылка на страницу")}
          </Typography>
          <Box
            flex
            style={{ cursor: "pointer", background: pageBackground }}
            p={12}
            onClick={handleCopy}
          >
            <IconColor>
              <LinkIcon />
            </IconColor>
            <Typography>
              {payUrl.length > 30 ? `${payUrl.substring(0, 30)}...` : payUrl}
            </Typography>
          </Box>
        </>
      ) : (
        <Typography variant="h4" mt={24}>
          {create ? t("Шаблон успешно создан!") : t("Шаблон успешно обновлен!")}
        </Typography>
      )}
      <Button
        mt={24}
        size="small"
        fullwidth
        onClick={() =>
          navigate(
            !isTemplate ? "/invoicing/pages" : "/invoicing/page-templates"
          )
        }
      >
        {!isTemplate ? t("К платежным страницам") : t("К шаблонам")}
      </Button>
    </Box>
  );
}

const IconColor = styled.div`
  margin-right: 16px;
  svg {
    width: 16px !important;
    height: 16px !important;
    path {
      fill: #2c60b0 !important;
    }
  }
`;
