import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { includes } from "lodash";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import {
  InvoicesQueryParams,
  KIND_CHECKOUT,
  usePostInvoiceMutation,
} from "api/baseAPI/checkoutInvoiceInvoices";
import {
  InvoiceTemplate,
  useDeleteInvoiceTemplateMutation,
  useGetInvoiceTemplatesQuery,
} from "api/baseAPI/checkoutInvoiceTemplates";
import { useGetUsersMeInvoicePermissionsQuery } from "api/baseAPI/user";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  IconColor,
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { Box } from "UI/Box";
import { InvoicesFilters } from "pages/checkoutInvoice/invoices/parts/InvoicesFilters";
import { CopyCell } from "pages/invoicing/pages/parts/AdditionalComponents";
import { dateFormatter } from "utils/date";
import { useFilterMaker } from "utils/filterMaker";
import { getTimezone } from "utils/getTimezone";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { ReactComponent as PlusIcon } from "utils/img/plus-outlined.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { useSelectedPointManager } from "hooks/useSelectedEntity";

const initParameters = {
  offset: "",
  limit: "",
  order_by: "",
  page: "1",
  page_size: "10",
  search: "",
  uuid: "",
  title__ilike: "",
  wallet_id: "",
  point_id: "",
};

export const Templates = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadCrumbs = [
    [t("Инвойсинг"), ""],
    [t("Шаблоны"), ""],
  ];

  const tableCellTitles = ["UUID", t("Описание"), t("Тип"), t("Создано")];

  const timezone = getTimezone();
  const selectedPointManager = useSelectedPointManager();
  const getPermissions = useGetUsersMeInvoicePermissionsQuery();

  const { queryParams, querySetters } =
    useUrlQuery<InvoicesQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_uuid(values.uuid);
      querySetters.set_title__ilike(values.title__ilike);
      querySetters.set_wallet_id(values.wallet_id);
    },
    () => set_search(initParameters.search)
  );

  const { data, isFetching, isSuccess, refetch, error } =
    useGetInvoiceTemplatesQuery({
      ...queryParams,
      limit: page_size || "",
      offset: String((Number(page) - 1) * Number(page_size) || 0),
      order_by: "-created_at",
      point_id: `${selectedPointManager?.point_id || ""}`,
      page: "",
      page_size: "",
    });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };
  const [createInvoice, createInvoiceMeta] = usePostInvoiceMutation();

  const [deletePageTemplate, deletePageTemplateMeta] =
    useDeleteInvoiceTemplateMutation();

  const handleDeletePageTemplate = (uuid: string) => {
    deletePageTemplate({ uuid });
  };

  const handleCreateInvoice = (row: InvoiceTemplate) => {
    createInvoice({
      title: row.title,
      active_till: DateTime.local()
        .setZone(timezone)
        .plus({ day: 3 })
        .plus({ hour: 1 })
        .startOf("hour")
        .toISO(),
      locale: row.locale,
      kind: row.kind,

      point_id: String(selectedPointManager.point?.id),
      account_id: String(selectedPointManager.user.account_id),
      wallet_id: String(row.wallet_id || ""),
      service_mapping: row.service_mapping,
      external_id: uuidv4(),

      is_reusable: row.is_reusable,
      amount_currency: row.amount_currency || null,
      fixed_amount: row.fixed_amount || null,
      min_amount: row.min_amount || null,
      default_amount: row.default_amount || null,
      preferred_amounts: row.preferred_amounts,
      preferred_amounts_logic: row.preferred_amounts_logic,

      additional_fields: row.additional_fields,
      prefilled_fields: row.prefilled_fields,
      description_template: row.description_template,

      success_amount: 0,
      fail_amount: 0,
      fail_count: 0,
      success_count: 0,
    });
  };

  useEffect(() => {
    if (createInvoiceMeta.isSuccess && createInvoiceMeta?.data?.uuid) {
      navigate(`/invoice/invoices/${createInvoiceMeta?.data?.uuid}/edit`);
    }
  }, [createInvoiceMeta.isSuccess, createInvoiceMeta.data]);

  useSuccessNotification([deletePageTemplateMeta]);
  useErrorNotification([deletePageTemplateMeta, createInvoiceMeta]);

  return (
    <Layout title={t("Шаблоны")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              createTitle={t("Создать шаблон")}
              {...(includes(getPermissions.data, "add_invoicetemplate")
                ? {
                    createPath: "/invoice/templates/create",
                  }
                : {})}
              refetch={refetch}
              filters={<InvoicesFilters filters={filters} error={error} />}
              hideSearch
            />
          }
          count={isSuccess ? data?.pagination?.total || 0 : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {isSuccess && data && data.results.length > 0 ? (
            data.results.map((row) => (
              <React.Fragment key={row.uuid}>
                <StyledRow>
                  <StyledCell>
                    {row.uuid && <CopyCell content={row.uuid} />}
                  </StyledCell>
                  <StyledCell>
                    {row.title?.length > 50
                      ? `${row.title.substring(0, 20)}...${row.title.substring(
                          row.title.length - 20
                        )}`
                      : row.title}
                  </StyledCell>
                  <StyledCell>
                    {row.kind === KIND_CHECKOUT && t("Чекаут")}
                  </StyledCell>
                  <StyledCell>
                    {dateFormatter.format(row.created_at)}
                  </StyledCell>
                  <StyledCell key="actions" align="right">
                    <TableRowActions
                      actions={[
                        ...(includes(getPermissions.data, "add_invoice")
                          ? [
                              <MenuItemAction
                                key={1}
                                onClick={() => handleCreateInvoice(row)}
                              >
                                <IconColor color="#838383">
                                  <PlusIcon />
                                </IconColor>
                                &nbsp;{t("Создать инвойс")}
                              </MenuItemAction>,
                            ]
                          : []),
                        ...(includes(
                          getPermissions.data,
                          "change_invoicetemplate"
                        )
                          ? [
                              <MenuItemAction
                                key={2}
                                onClick={() =>
                                  navigate(
                                    `/invoice/templates/${row.uuid}/edit`
                                  )
                                }
                              >
                                <EditIcon /> &nbsp;{t("Редактировать")}
                              </MenuItemAction>,
                            ]
                          : []),
                        ...(includes(
                          getPermissions.data,
                          "delete_invoicetemplate"
                        )
                          ? [
                              <MenuItemAction
                                key={3}
                                onClick={() =>
                                  handleDeletePageTemplate(row.uuid)
                                }
                              >
                                <DeleteIcon /> &nbsp;{t("Удалить")}
                              </MenuItemAction>,
                            ]
                          : []),
                      ]}
                    />
                  </StyledCell>
                </StyledRow>
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </Table>
      </Box>
    </Layout>
  );
};
