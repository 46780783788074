import { useFormik } from "formik";
import { isBoolean } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/LoginInput";
import {
  useLazyGetUsersSelfQuery,
  useLoginMutation,
  useRegisterMutation,
} from "api/baseAPI/user";
import { LeftBlockLoginPage } from "components/LeftBlockLoginPage";
import { useAuth } from "hooks/useAuth";
import { setAuth } from "reducers/authSlice";
import getSettingStyles from "utils/getSettingStyles";
import { ReactComponent as EyeIcon } from "utils/img/eye.svg";
import { ReactComponent as HideEyeIcon } from "utils/img/hide-eye.svg";
import { ReactComponent as InputErrorIcon } from "utils/img/input-error-icon.svg";
import { ReactComponent as InputSuccessIcon } from "utils/img/input-success-icon.svg";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { RESPONSIVE_SIZES } from "utils/tools";

export const Signup = () => {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useAuth();

  const [login, { isLoading }] = useLoginMutation();
  const [getUsersSelf, getUsersSelfMeta] = useLazyGetUsersSelfQuery();
  const [register, { isLoading: isLoadingRegister, error: errorRegister }] =
    useRegisterMutation();

  getSettingStyles();

  useEffect(() => {
    document.title = "ЛК | Sign up";
  });

  const {
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    validateField,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
      confirm_password: "",
      show_password: false,
      show_confirm_password: false,
    },
    onSubmit: async (v) => {
      setError("");
      try {
        await register({
          username: v.username,
          email: v.username,
          password: v.password,
        }).unwrap();
        const data = await login({
          username: v.username,
          password: v.password,
        }).unwrap();
        dispatch(
          setAuth({
            access_token: data.access_token,
            refresh_token: data.refresh_token,
          })
        );
        getUsersSelf();
      } catch (err: any) {
        if (String(err?.status).startsWith("5")) {
          setError("Oops! Something went wrong");
        }
      }
    },

    validationSchema: Yup.object().shape({
      username: Yup.string().required("Username/email is required"),
      password: Yup.string()
        .min(8, "Password should be minimun of 8 characters")
        .required("Password is required"),
      confirm_password: Yup.string()
        .when("password", {
          is: (val: string) => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Error! Passwords do not match"
          ),
        })
        .min(8, "Password should be minimun of 8 characters")
        .required("Password is required"),
    }),
  });

  useServerErrorsInForm(setFieldError, errorRegister);

  useEffect(() => {
    if (auth.access_token && getUsersSelfMeta.isSuccess) {
      if (
        isBoolean(getUsersSelfMeta.data.is_approved) &&
        !getUsersSelfMeta.data.is_approved
      ) {
        navigate("/onboarding");
      } else {
        navigate("/");
      }
    }
  }, [getUsersSelfMeta, auth.access_token]);

  return (
    <StyledLayout>
      <LeftBlockLoginPage />
      <StyledRightColumn>
        <StyledWraperForm>
          <StyledFakeHeader />
          <div>
            <StyledTitleForm>Sign up</StyledTitleForm>
            <StyledSubtitleForm>
              To register, fill in all the fields
            </StyledSubtitleForm>
            <StyledGrid mt={48}>
              <TextInput
                placeholder="Enter username or email address"
                name="username"
                value={values.username}
                onChange={(value) => {
                  setFieldValue("username", value);
                  if (!touched.username) setFieldTouched("username", true);
                  setTimeout(() => validateField("username"));
                }}
                error={(touched.username && !!errors.username) || !!error}
                helperText={
                  touched.username && errors.username ? errors.username : ""
                }
                {...(!errors.username && !!values.username && !error
                  ? {
                      icon: <InputSuccessIcon />,
                    }
                  : {})}
                {...(!!errors.username || !!error
                  ? {
                      icon: <InputErrorIcon />,
                    }
                  : {})}
              />
            </StyledGrid>
            <StyledGrid mt={16}>
              <TextInput
                placeholder="Enter password"
                type={values.show_password ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={(value) => {
                  setFieldValue("password", value.slice(0, 30));
                  if (!touched.password) setFieldTouched("password", true);
                  setTimeout(() => validateField("password"));
                }}
                error={(touched.password && !!errors.password) || !!error}
                helperText={
                  touched.password && errors.password
                    ? errors.password
                    : error || ""
                }
                icon={
                  <StyledWrapperIcons>
                    <StyledWrapperFirstIcon
                      onClick={() =>
                        setFieldValue("show_password", !values.show_password)
                      }
                    >
                      {values.show_password ? <EyeIcon /> : <HideEyeIcon />}
                    </StyledWrapperFirstIcon>

                    {!errors.password && !!values.password && !error ? (
                      <StyledWrapperLastIcon>
                        <InputSuccessIcon />
                      </StyledWrapperLastIcon>
                    ) : null}
                    {!!errors.password || !!error ? (
                      <StyledWrapperLastIcon>
                        <InputErrorIcon />
                      </StyledWrapperLastIcon>
                    ) : null}
                  </StyledWrapperIcons>
                }
              />
            </StyledGrid>
            <StyledGrid mt={16}>
              <TextInput
                placeholder="Repeat password"
                type={values.show_confirm_password ? "text" : "password"}
                name="confirm_password"
                value={values.confirm_password}
                onChange={(value) => {
                  setFieldValue("confirm_password", value.slice(0, 30));
                  if (!touched.confirm_password) {
                    setFieldTouched("confirm_password", true);
                  }
                  setTimeout(() => validateField("confirm_password"));
                }}
                error={
                  (touched.confirm_password && !!errors.confirm_password) ||
                  !!error
                }
                helperText={
                  touched.confirm_password && errors.confirm_password
                    ? errors.confirm_password
                    : error || ""
                }
                icon={
                  <StyledWrapperIcons>
                    <StyledWrapperFirstIcon
                      onClick={() =>
                        setFieldValue(
                          "show_confirm_password",
                          !values.show_confirm_password
                        )
                      }
                    >
                      {values.show_confirm_password ? (
                        <EyeIcon />
                      ) : (
                        <HideEyeIcon />
                      )}
                    </StyledWrapperFirstIcon>

                    {!errors.confirm_password &&
                    !!values.confirm_password &&
                    !error ? (
                      <StyledWrapperLastIcon>
                        <InputSuccessIcon />
                      </StyledWrapperLastIcon>
                    ) : null}
                    {!!errors.confirm_password || !!error ? (
                      <StyledWrapperLastIcon>
                        <InputErrorIcon />
                      </StyledWrapperLastIcon>
                    ) : null}
                  </StyledWrapperIcons>
                }
              />
            </StyledGrid>
            <StyledGrid mt={40}>
              <StyledButton
                onClick={() => handleSubmit()}
                disabled={isLoading || isLoadingRegister || !isValid}
                fullwidth
                disabledUpperCase
              >
                Sign up
              </StyledButton>
            </StyledGrid>
          </div>
          <StyledWrapperLink>
            Already have an account? &nbsp;
            <StyledLink to="/login">Sign in</StyledLink>
          </StyledWrapperLink>
        </StyledWraperForm>
      </StyledRightColumn>
    </StyledLayout>
  );
};

// Styles
const StyledButton = styled(Button)`
  font-size: 20px;
  padding: 20px;
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 16px;
    line-height: 19px;
    padding: 15px;
  }
`;
const StyledLink = styled(Link)`
  color: black;
`;
const StyledLayout = styled.div`
  display: flex;
  min-height: 100vh;
`;
const StyledRightColumn = styled.div`
  width: calc(50% + 55px);
  padding-left: 120px;
  padding-right: 120px;
  display: flex;
  align-items: center;
  position: relative;
  @media (${RESPONSIVE_SIZES.lg}) {
    width: 100%;
    padding: 24px;
  }
`;
const StyledWraperForm = styled.div`
  width: 100%;
  margin: 0 128px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (${RESPONSIVE_SIZES.xxl}) {
    margin: 0;
  }
`;
const StyledTitleForm = styled.div`
  font-weight: 600;
  font-size: 48px;
  line-height: 32px;
  color: #000000;
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 32px;
  }
`;
const StyledSubtitleForm = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-top: 24px;
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 14px;
    line-height: 20px;
  }
`;
const StyledGrid = styled.div`
  width: 100%;
  margin-top: ${(props: { mt?: number }) => props.mt || 0}px;
`;
const StyledWrapperFirstIcon = styled.div`
  cursor: pointer;
`;
const StyledWrapperLastIcon = styled.div`
  margin-left: 20px;
`;
const StyledWrapperIcons = styled.div`
  display: flex;
`;
const StyledWrapperLink = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  color: #727272;
  margin-bottom: 40px;
  margin-top: 12px;
  @media (${RESPONSIVE_SIZES.lg}) {
    margin-bottom: 24px;
  }
`;
const StyledFakeHeader = styled.div`
  font-size: 16px;
`;
