import { useFormik } from "formik";
import { find, isEmpty, isString } from "lodash";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useGetUsersSelfQuery, useUpdateUserMutation } from "api/baseAPI/user";
import { timeZones } from "components/RangeSelector/TimeService";
import { ReactComponent as CameraIcon } from "utils/img/camera.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
};

export const ModalChangeProfile = (props: Props): React.ReactElement => {
  const { onClose, onSuccess } = props;
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string>("");

  const getUsersSelfQuery = useGetUsersSelfQuery();
  const [updateUser, { isSuccess, isError, isLoading }] =
    useUpdateUserMutation();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  const form = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      timezone: "",
    },
    onSubmit: (values) => {
      const formData = new FormData();
      if (selectedFile) formData.append("avatar", selectedFile || "");
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("timezone", values.timezone);
      updateUser({ formData, id: Number(getUsersSelfQuery.data?.id) });
    },
    validationSchema: Yup.object().shape({}),
  });

  useEffect(() => {
    if (getUsersSelfQuery.isSuccess && !isEmpty(getUsersSelfQuery.data)) {
      form.setFieldValue(
        "first_name",
        getUsersSelfQuery.data?.first_name || ""
      );
      form.setFieldValue("last_name", getUsersSelfQuery.data?.last_name || "");
      setImageUrl(getUsersSelfQuery.data?.avatar || "");
      if (
        getUsersSelfQuery.data?.timezone &&
        find(timeZones, { value: getUsersSelfQuery.data?.timezone })
      ) {
        form.setFieldValue("timezone", getUsersSelfQuery.data?.timezone || "");
      }
    }
  }, [getUsersSelfQuery]);

  const handleChangeFile = async (fileList: File[]) => {
    if (!fileList) return;
    const file = fileList[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result && isString(reader.result)) {
        setImageUrl(reader.result);
      } else {
        setImageUrl("");
      }
    };
    reader.readAsDataURL(file);
    setSelectedFile(file);
  };

  useErrorNotification([{ isError }]);
  useSuccessNotification([{ isSuccess }]);

  return (
    <Box p={8} pt={1}>
      <Typography variant="h5" mb={20}>
        {t("Редактирование профиля")}
      </Typography>
      <Dropzone
        onDrop={handleChangeFile}
        accept={{ "image/*": [] }}
        maxSize={625000}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <Box
              style={{
                background: "#F5F5F5",
                cursor: "pointer",
                minHeight: 200,
                maxWidth: 300,
                maxHeight: 300,
                position: "relative",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
              }}
              mb={24}
            >
              <input {...getInputProps()} />

              {imageUrl ? (
                <img
                  src={imageUrl}
                  style={{ width: "auto", maxHeight: "300px" }}
                  alt=""
                />
              ) : (
                <></>
              )}
              <Box
                flex
                p={8}
                alignItems="center"
                style={{
                  position: "absolute",
                  bottom: 0,
                  background: "rgba(0, 0, 0, 0.56)",
                  width: "calc(100% - 16px)",
                }}
              >
                <CameraIcon />
                <Typography
                  variant="buttonSmall"
                  pl={8}
                  style={{ color: "white" }}
                >
                  {t("Загрузить фото").toLocaleUpperCase()}
                </Typography>
              </Box>
            </Box>
          </div>
        )}
      </Dropzone>
      <TextInput
        size="small"
        label={t("Имя")}
        placeholder={t("Введите имя")}
        onChange={(value) =>
          form.setFieldValue("first_name", value.slice(0, 16))
        }
        value={form.values.first_name}
        error={form.touched.first_name && Boolean(form.errors.first_name)}
        helperText={form.touched.first_name && form.errors.first_name}
      />
      <Box mt={24}>
        <TextInput
          size="small"
          label={t("Фамилия")}
          placeholder={t("Введите фамилию")}
          onChange={(value) =>
            form.setFieldValue("last_name", value.slice(0, 20))
          }
          value={form.values.last_name}
          error={form.touched.last_name && Boolean(form.errors.last_name)}
          helperText={form.touched.last_name && form.errors.last_name}
        />
      </Box>
      <Box mt={24}>
        <Select
          size="small"
          label={t("Часовой пояс")}
          placeholder={t("Введите часовой пояс")}
          onChange={(value) => form.setFieldValue("timezone", value)}
          value={form.values.timezone}
          options={timeZones}
          error={form.touched.timezone && Boolean(form.errors.timezone)}
          helperText={form.touched.timezone && form.errors.timezone}
        />
      </Box>

      <Grid
        container
        flex
        alignItems="center"
        justifyContent="space-between"
        mt={24}
        vSpace={12}
      >
        <Grid item lg={12}>
          <Button
            size="small"
            disabled={isLoading}
            variant="outlined"
            fullwidth
            onClick={() => onClose()}
          >
            {t("Отмена")}
          </Button>
        </Grid>
        <Grid item lg={12}>
          <Button
            size="small"
            disabled={isLoading}
            fullwidth
            onClick={() => form.handleSubmit()}
          >
            {t("Сохранить")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
