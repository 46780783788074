import { FormikProps } from "formik";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import WebFont from "webfontloader";
import { Button } from "UI/Button";
import { CustomizationsFormValues, PagesFormValues } from "pages/invoicing/pages/PageCrete";
import Logo from "./img/Logo.svg";
import applePayIcon from "./img/a-pay.svg";
import editIcon from "./img/edit.svg";
import googlePayIcon from "./img/g-pay.svg";
import infoIcon from "./img/info.svg";
import masterCardIcon from "./img/master-card.svg";
import visaIcon from "./img/visa.svg";

const SET_AMOUNT = 2;
const KIND_TIP = 3;

const uaLocale = {
  "Сумма оплаты": "Сума оплати",
  "Сумма не меньше указанной:": "Сума не менша від зазначеної:",
  Сумма: "Сума",
  Комиссия: "Комісія",
  "Оплата картой": "Оплата карткою",
  "Введите номер карты": "Введіть номер картки",
  "Срок действия": "Термін дії",
  "Дополнительные поля": "Додаткові поля",
  ФИО: "ПІБ",
  "Номер телефона": "Номер телефону",
  "Введите почту": "Введіть пошту",
  Комментарий: "Коментар",
  ОПЛАТИТЬ: "ОПЛАТИТИ",
  "Фиксированная сумма оплаты": "Фіксована сума оплати",
  Фамилия: "Прізвище",
  Имя: "Імʼя",
  Отчество: "По батькові",
  "Дата рождения": "Дата народження",
  Страна: "Країна",
  Город: "Місто",
  Адрес: "Адреса",
  "Почтовый индекс": "Поштовий індекс",
};

const enLocale = {
  "Сумма оплаты": "Payment amount",
  "Сумма не меньше указанной:": "Amount not less than:",
  Сумма: "Amount",
  Комиссия: "Fee",
  "Оплата картой": "Payment by card",
  "Введите номер карты": "Enter card number",
  "Срок действия": "Valid till",
  "Дополнительные поля": "Additional fields",
  ФИО: "Full name",
  "Номер телефона": "Phone number",
  "Введите почту": "Email",
  Комментарий: "Comment",
  ОПЛАТИТЬ: "PAY",
  "Фиксированная сумма оплаты": "Fixed payment amount",
  Фамилия: "Last name",
  Имя: "First name",
  Отчество: "Patronymic",
  "Дата рождения": "Date of Birth",
  Страна: "Country",
  Город: "City",
  Адрес: "Address",
  "Почтовый индекс": "Zip Code",
};

const FormInput = ({
  placeholder,
  bordercolor,
  font,
}: {
  placeholder: string;
  bordercolor: string;
  font?: string;
}) => (
    <StyledWrapperInput>
      <StyledInput
        type="text"
        value=""
        placeholder={placeholder}
        bordercolor={bordercolor}
        font={font}
        readOnly
      />
    </StyledWrapperInput>
  )

type Props = {
  onClose: () => void;
  formPage: FormikProps<PagesFormValues>;
  form: FormikProps<CustomizationsFormValues>;
  imageUrl: string;
};

export const ModalPagePreview = (props: Props): React.ReactElement => {
  const { formPage, form, onClose, imageUrl } = props;
  const { t } = useTranslation();

  const currencyIcons = {
    USD: <StyledP>&#36;</StyledP>,
    EUR: <StyledP>&#8364;</StyledP>,
    RUB: <StyledP>&#8381;</StyledP>,
    UAH: <StyledP>&#8372;</StyledP>,
    INR: <StyledP>&#8377;</StyledP>,
    KZT: <StyledP>&#8376;</StyledP>,
    AZN: <StyledP>&#8380;</StyledP>,
    KGS: <StyledP>&#13199;</StyledP>,
    TJS: <StyledP>&#84;&#74;&#83;</StyledP>,
    TMT: <StyledP>&#109;</StyledP>,
    PLN: <StyledP>&#122;&#322;</StyledP>,
    TRY: <StyledP>&#8356;</StyledP>,
    CLP: <StyledP>&#36;</StyledP>,
    MXN: <StyledP>&#36;</StyledP>,
    CZK: <StyledP>&#75;&#269;</StyledP>,
    HUF: <StyledP>&#70;&#116;</StyledP>,
    RON: <StyledP>&#108;&#101;&#105;</StyledP>,
  };

  useEffect(() => {
    if (form.values?.font) {
      WebFont.load({ google: { families: [form.values?.font] } });
    }
  }, [form.values?.font]);

  const locale = useMemo(
    () => (key: keyof typeof uaLocale) => {
      if (formPage.values.locale  ===  "ua" && uaLocale[key])
        return uaLocale[key];
      if (formPage.values.locale  ===  "en" && enLocale[key])
        return enLocale[key];
      return key;
    },
    [formPage.values.locale]
  );

  const titleFields = {
    name: locale("ФИО"),
    phone: locale("Номер телефона"),
    email: locale("Введите почту"),
    comment: locale("Комментарий"),
    last_name: locale("Фамилия"),
    first_name: locale("Имя"),
    patronymic: locale("Отчество"),
    birth_date: locale("Дата рождения"),
    country: locale("Страна"),
    city: locale("Город"),
    address: locale("Адрес"),
    zip_code: locale("Почтовый индекс"),
  };

  return (
    <div style={{ padding: 16 }}>
      <StyledTitleModal>
        {t("Предпросмотр платежной страницы")}
      </StyledTitleModal>

      <StyledLayout font={form.values.font}>
        <StyledWrapper background={form.values.background_color}>
          <StyledRowFlexLogo alignstart={formPage?.values?.kind  ===  KIND_TIP}>
            <StyledLogoFlex>
              <StyledLogo
                src={imageUrl || Logo}
                userlogo={formPage?.values?.kind  ===  KIND_TIP}
                alt="Logo"
              />
              {formPage?.values?.kind  ===  KIND_TIP ? (
                <StyledAlignStart>
                  <StyledPosition>
                    {formPage?.values?.title?.split(";")?.[0]}
                  </StyledPosition>
                  <StyledFirstName>
                    {formPage?.values?.title?.split(";")?.[1]}
                  </StyledFirstName>
                  <StyledLastName>
                    {formPage?.values?.title?.split(";")?.[2]}
                  </StyledLastName>
                </StyledAlignStart>
              ) : null}
            </StyledLogoFlex>
          </StyledRowFlexLogo>
          <StyledTitleAmount color={form.values.font_color}>
            {formPage.values.fixed_amount
              ? locale("Фиксированная сумма оплаты")
              : locale("Сумма оплаты")}
            {!formPage.values.fixed_amount && <img src={editIcon} alt="Edit" />}
          </StyledTitleAmount>
          <StyledWrapperAmountInput bordercolor={form.values.form_accent_color}>
            <StyledAmountInput
              type="number"
              value={
                formPage.values.fixed_amount ? formPage.values.fixed_amount : ""
              }
              name="amount"
              placeholder={locale("Сумма")}
              readOnly
              font={form.values.font}
            />
            <StyledAmountCommission>
              {locale("Комиссия")} <br />
              <div style={{ marginTop: "8px" }}>
                {`120.00 ${formPage.values.amount_currency || "UAH"}`}
              </div>
            </StyledAmountCommission>
          </StyledWrapperAmountInput>
          {!formPage.values.fixed_amount &&
            formPage.values.preferred_amounts?.length > 0 && (
              <StyledWrapperPreferred
                onEdge={formPage.values.preferred_amounts?.length  ===  2}
              >
                {[...formPage.values.preferred_amounts]
                  ?.sort((a: string, b: string) => Number(a) - Number(b))
                  ?.map((ammount, i) => (
                    <StyledPreferredAmount key={i}>
                      {`${
                        formPage?.values?.preferred_amounts_logic  ===  SET_AMOUNT
                          ? ""
                          : "+"
                      }${
                        (ammount?.split(".")[1] || "")  ===  "00"
                          ? ammount.slice(0, ammount.indexOf("."))
                          : Number(ammount).toFixed(2)
                      }`}
                      {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        currencyIcons?.[formPage.values.amount_currency] || ""
                      }
                    </StyledPreferredAmount>
                  ))}
              </StyledWrapperPreferred>
            )}
          {formPage?.values?.title && formPage?.values?.kind !== KIND_TIP ? (
            <StyledWrapperInfo background={form?.values?.form_color}>
              <StyledTitle>{formPage?.values?.title}</StyledTitle>
            </StyledWrapperInfo>
          ) : null}
          <StyledWrapperImg>
            <img
              src={googlePayIcon}
              alt="googlePay"
              style={{ marginRight: "4px" }}
            />
            <img
              src={applePayIcon}
              alt="applePay"
              style={{ marginLeft: "4px" }}
            />
          </StyledWrapperImg>
          <StyledWrapperInfo
            background={form.values.form_color}
            style={{ marginTop: "15px" }}
          >
            <StyledSubTitle color={form.values.font_color}>
              {locale("Оплата картой")}
            </StyledSubTitle>
            <StyledRowItem>
              <FormInput
                placeholder={locale("Введите номер карты")}
                bordercolor={form.values.form_accent_color}
                font={form.values.font}
              />
            </StyledRowItem>
            <StyledRowFlex>
              <StyledRowItem style={{ width: "62%" }}>
                <FormInput
                  placeholder={locale("Срок действия")}
                  bordercolor={form.values.form_accent_color}
                  font={form.values.font}
                />
              </StyledRowItem>
              <StyledRowItem style={{ width: "33%" }}>
                <StyledInfoIcon src={infoIcon} />
                <FormInput
                  placeholder="CVV"
                  bordercolor={form.values.form_accent_color}
                  font={form.values.font}
                />
              </StyledRowItem>
            </StyledRowFlex>
          </StyledWrapperInfo>
          {formPage.values.additional_fields?.length > 0 && (
            <StyledWrapperInfo background={form.values.form_color}>
              <StyledSubTitle color={form.values.font_color}>
                {locale("Дополнительные поля")}
              </StyledSubTitle>
              {formPage.values.additional_fields.map((item) => (
                <StyledRowItem key={item}>
                  <FormInput
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    placeholder={titleFields?.[item]}
                    bordercolor={form.values.form_accent_color}
                    font={form.values.font}
                  />
                </StyledRowItem>
              ))}
            </StyledWrapperInfo>
          )}
          <StyledButton
            background={form.values.button_color}
            font={form.values.font}
          >
            {locale("ОПЛАТИТЬ")}
          </StyledButton>
          <StyledWrapperImg>
            <img
              src={masterCardIcon}
              alt="Master-Card"
              style={{ marginRight: "20px" }}
            />
            <img src={visaIcon} alt="Visa" style={{ marginLeft: "20px" }} />
          </StyledWrapperImg>
        </StyledWrapper>
      </StyledLayout>
      <Button
        mt={24}
        size="small"
        fullwidth
        variant="outlined"
        onClick={onClose}
      >
        {t("Назад")}
      </Button>
    </div>
  );
}
const StyledP = styled.div`
  margin-left: 5px;
`;
const StyledWrapperPreferred = styled.div`
  display: flex;
  justify-content: ${(props: { onEdge: boolean }) =>
    props.onEdge ? "space-evenly" : "space-between"};
  align-items: center;
  margin-bottom: 24px;
`;
const StyledPreferredAmount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  min-width: 72px;
  min-height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #000000;
  border-radius: 6px;
`;

const StyledRowFlexLogo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${(props: { alignstart: boolean }) =>
    props.alignstart ? "flex-start" : "center"};
  margin-bottom: 24px;
`;

const StyledTitleModal = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 24px;
  color: #000000;
`;

const StyledWrapperAmountInput = styled.div`
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid
    ${(props: { bordercolor: string }) => props.bordercolor ?? "#D9D9D9"};
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  background: #f6f7f8;
`;
const StyledAmountInput = styled.input`
  max-width: 50%;
  border: none;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  background: #f6f7f8;
  font-family: ${(props: { font: string }) => props.font ?? "Montserrat"};
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.5);
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
const StyledAmountCommission = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #5c5c5c;
`;
const StyledInfoIcon = styled.img`
  position: absolute;
  top: 8px;
  right: 6px;
  z-index: 100;
  cursor: pointer;
  padding: 10px;
`;
const StyledRowFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  font-family: ${(props: { background: string; font: string }) =>
    props.font ?? "Montserrat"};
  background: ${(props: { background: string }) =>
    props.background ?? "#181818"};
  border-radius: 6px;
  padding: 20px;
  width: 100%;
  border: none;
  cursor: pointer;
`;
const StyledRowItem = styled.div`
  margin-top: 16px;
  position: relative;
`;
const StyledSubTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: start;
  color: ${(props: { color: string }) => props.color ?? "#000000"};
`;
const StyledWrapperImg = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;
const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5c5c5c;
`;
const StyledWrapperInfo = styled.div`
  border-radius: 6px;
  background: ${(props: { background: string }) =>
    props.background ?? "#FFFFFF"};
  padding: 16px;
  margin-bottom: 16px;
`;
const StyledTitleAmount = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${(props: { color: string }) => props.color ?? "#000000"};
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;
const StyledLogo = styled.img`
  max-width: 100%;
  ${(props: { userlogo: boolean }) =>
    props.userlogo
      ? `
    max-width: 72px;
    max-height: 72px;
    height: 72px;
    border-radius: 6px;
    margin-right: 12px;
  `
      : ""}
`;
const StyledLayout = styled.div`
  font-family: ${(props: { font: string }) => props.font ?? "Montserrat"};
`;

const StyledWrapper = styled.div`
  padding: 20px 10px;
  margin: auto;
  text-align: center;
  position: relative;
  border: 5px solid;
  border-radius: 30px;
  background: ${(props: { background: string }) =>
    props.background ?? "#F6F7F8"};
`;

const StyledWrapperInput = styled.div`
  display: block;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  max-width: 100%;
  border-radius: 6px;
  border: 1px solid
    ${(props: { bordercolor: string }) => props.bordercolor ?? "#D9D9D9"};
  padding: 16px;
  font-family: ${(props: { bordercolor: string; font?: string }) =>
    props.font ?? "Montserrat"};
  background-position: 20px center;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 20px center;
  font-size: 14px;
  line-height: 17px;
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.5);
  }
`;
const StyledLogoFlex = styled.div`
  display: flex;
`;

const StyledAlignStart = styled.div`
  text-align: start;
`;

const StyledPosition = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-top: 8px;
`;

const StyledFirstName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 4px;
`;

const StyledLastName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
  margin-top: 8px;
`;
