import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "UI/Form/MultiSelect";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { useLazyGetPointServicesQuery } from "api/baseAPI/pointServices";
import { TransactionsQueryParams } from "api/baseAPI/transactions";
import { TableFilter } from "components/table/TableFilter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useFilterMakerResult } from "utils/filterMaker";

export const TransactionsFilters = ({
  error,
  filters,
}: {
  filters: useFilterMakerResult<TransactionsQueryParams>;
  error: FetchBaseQueryError | SerializedError | undefined;
}) => {
  const { t } = useTranslation();
  const { values, setValue, errors } = filters;
  const [errorFilter, setError] = useState<Record<string, string>>({});
  const [searchService, setSearchService] = useState("");

  useEffect(() => {
    if (error && "data" in error) {
      const data = error.data as Record<string, string>;
      setError(data);
    } else {
      setError({});
    }
  }, [error]);

  const selectedPointManager = useSelectedPointManager();

  const [pointServices, pointServicesMeta] = useLazyGetPointServicesQuery();

  useEffect(() => {
    if (selectedPointManager.point?.id) {
      pointServices({
        page: "1",
        page_size: "100",
        search: "",
        point: selectedPointManager.point?.id,
        [searchService && Number(searchService) ? "service" : "search"]:
          searchService,
      });
    }
  }, [selectedPointManager.point?.id, searchService]);

  const getServices = useMemo(() => {
    const { data } = pointServicesMeta;
    if (data && data?.results?.length) {
      const services = Array.from(
        new Set(
          data?.results?.map((item) => ({
            value: String(item.service?.id),
            label: `${item.service?.id} | ${item.service?.name}`,
          }))
        )
      );
      return services;
    }
    return [];
  }, [pointServicesMeta?.data]);

  return (
    <TableFilter
      onSubmit={filters.handleSubmit}
      onReset={filters.handleReset}
      disabledReset={filters.disabledReset}
      disableSubmit={!!errors}
      widthBox="45%"
    >
      <Grid container mt={24} vSpace={24}>
        <Grid item sm={12}>
          <MultiSelect
            inputValue={searchService}
            onChangeInputValue={(val) => setSearchService(val)}
            isLoading={pointServicesMeta.isFetching}
            value={values.service__in ? values.service__in.split(",") : []}
            onChange={(value) => setValue("service__in", value.join(","))}
            label={t("Услуга")}
            placeholder={t("Выберите")}
            size="small"
            options={getServices}
          />
        </Grid>
        <Grid item sm={12}>
          <TextInput
            size="small"
            label={t("ID Транзакции")}
            placeholder={t("Введите значение")}
            value={values.id}
            onChange={(value) => setValue("id", value)}
            error={Boolean(errorFilter?.id)}
            helperText={errorFilter?.id}
          />
        </Grid>
        <Grid item sm={12}>
          <TextInput
            size="small"
            label={t("Назначение")}
            placeholder={t("Введите значение")}
            value={values.description__icontains}
            onChange={(value) => setValue("description__icontains", value)}
          />
        </Grid>
        <Grid item sm={12}>
          <TextInput
            size="small"
            label={t("Номер карты/телефона получателя")}
            placeholder={t("Введите значение")}
            value={values.card_number}
            onChange={(value) =>
              setValue(
                "card_number",
                value.replace(/[^0-9+]/g, "").slice(0, 16)
              )
            }
          />
        </Grid>
        <Grid item sm={12}>
          <TextInput
            size="small"
            label={t("Внешний ID клиента")}
            placeholder={t("Введите значение")}
            value={values.external_customer_id}
            onChange={(value) => setValue("external_customer_id", value)}
          />
        </Grid>
        <Grid item sm={12}>
          <TextInput
            size="small"
            label={t("Внешний ID транзакции")}
            placeholder={t("Введите значение")}
            value={values.external_transaction_id}
            onChange={(value) => setValue("external_transaction_id", value)}
          />
        </Grid>
      </Grid>
    </TableFilter>
  );
}
