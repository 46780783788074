import { ReactElement } from "react";
import * as yup from "yup";
import { ReactComponent as BinanceIcon } from "utils/img/binance.svg";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { EmailPayInput } from "pages/payout/logic/forms/components/inputs/EmailPayInput";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class BinancePayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "binance";

  kind: 4 = 4;

  name: string = "Binance";

  mobileName: string = this.name;

  icon: ReactElement = (<BinanceIcon />);

  csvExample: string | null = "/file-samples/sample-binance.csv";

  xlsxExample: string | null = "/file-samples/sample-binance.xlsx";

  get serviceId() {
    return (
      (this.pointManager?.service_config?.binance_create_code &&
        this.getService(
          this.pointManager?.service_config?.binance_create_code
        )) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <EmailPayInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .email("Неправильный формат email")
    .required("Введите email");
}
