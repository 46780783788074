import { useFormik } from "formik";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import {
  useGetBusinessAreasQuery,
  useGetRegistrationFormsQuery,
} from "api/baseAPI/helpers";
import {
  useCreateUserInfoMutation,
  useLazyGetUserInfoQuery,
  useLogoutMutation,
  useUpdateUserInfoMutation,
} from "api/baseAPI/user";
import { useAuth } from "hooks/useAuth";
import { removeAuth } from "reducers/authSlice";
import { useErrorNotification } from "utils/notificationWrappers";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";

type Props = {
  editId: number;
  user: number;
  onSuccess: () => void;
};

export const UserInfosForm = (props: Props): React.ReactElement => {
  const { onSuccess, user, editId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const auth = useAuth();
  const [logout] = useLogoutMutation();

  const getBusinessAreas = useGetBusinessAreasQuery();
  const getRegistrationForms = useGetRegistrationFormsQuery();

  const [getUserInfo, getUserInfoMeta] = useLazyGetUserInfoQuery();
  const [createUserInfos, { isSuccess, isError, isLoading }] =
    useCreateUserInfoMutation();
  const [
    updateUserInfo,
    {
      isSuccess: isSuccessUpload,
      isError: isErrorUpload,
      isLoading: isLoadingUpload,
    },
  ] = useUpdateUserInfoMutation();

  useEffect(() => {
    if (editId) {
      getUserInfo({ id: editId });
    }
  }, [editId]);

  useEffect(() => {
    if (isSuccess || isSuccessUpload) {
      onSuccess();
    }
  }, [isSuccess, isSuccessUpload]);

  const form = useFormik({
    initialValues: {
      business_name: "",
      tax_number: "",
      registration_form: "",
      business_area: "",
    },
    onSubmit: (values) => {
      const formData = {
        registration_form: values.registration_form
          ? Number(values.registration_form)
          : "",
        business_area: values.business_area ? Number(values.business_area) : "",
        business_name: values.business_name,
        tax_number: values.tax_number,
        user,
      };
      if (editId) {
        updateUserInfo({
          id: editId,
          ...formData,
        });
        return;
      }
      createUserInfos(formData);
    },
    validationSchema: Yup.object().shape({
      business_name: Yup.string().required(t("Обязательное поле")),
      business_area: Yup.string().required(t("Обязательное поле")),
    }),
  });

  useTranslateFormErrors(form);

  const getRegistrationForm = useMemo(() => {
    const values = getRegistrationForms.data?.results;
    return [
      {
        id: values?.[0]?.id ? String(values?.[0]?.id) : "1",
        name: values?.[0]?.name || "ФОП",
      },
      {
        id: values?.[1]?.id ? String(values?.[1]?.id) : "2",
        name: values?.[1]?.name || "Юр. лицо",
      },
    ];
  }, [getRegistrationForms]);

  useEffect(() => {
    if (!editId && getRegistrationForms.data?.results?.[0]?.id) {
      form.setFieldValue(
        "registration_form",
        String(getRegistrationForms.data?.results?.[0]?.id)
      );
    }
  }, [editId, isSuccessUpload]);

  useEffect(() => {
    if (getUserInfoMeta.isSuccess && !isEmpty(getUserInfoMeta.data)) {
      form.setFieldValue(
        "registration_form",
        getUserInfoMeta.data?.registration_form?.id
          ? String(getUserInfoMeta.data?.registration_form?.id)
          : ""
      );
      form.setFieldValue(
        "business_area",
        getUserInfoMeta.data?.business_area?.id
          ? String(getUserInfoMeta.data?.business_area?.id)
          : ""
      );
      form.setFieldValue(
        "business_name",
        getUserInfoMeta.data?.business_name || ""
      );
      form.setFieldValue("tax_number", getUserInfoMeta.data?.tax_number || "");
    }
  }, [getUserInfoMeta]);

  const handleLogout = () => {
    const token = auth.access_token;
    dispatch(removeAuth());
    window.localStorage.removeItem("point_manager");
    window.localStorage.removeItem("selected_wallet");
    logout({ token }).unwrap();
    navigate("/login");
  };

  useErrorNotification([{ isError }]);
  useErrorNotification([{ isError: isErrorUpload }]);

  return (
    <Modal open hideHeader height={250} width={410} onClose={() => {}}>
      <Box p={32}>
        <Typography variant="h5" mb={24}>
          {t("Немного информации о Вас")}
        </Typography>
        <TextInput
          size="small"
          label={t("Название бизнеса")}
          placeholder={t("Введите название")}
          onChange={(value) => form.setFieldValue("business_name", value)}
          value={form.values.business_name}
          error={
            form.touched.business_name && Boolean(form.errors.business_name)
          }
          helperText={form.touched.business_name && form.errors.business_name}
        />
        <Typography variant="h6" mt={24} mb={12}>
          {t("Форма регистрации")}
        </Typography>
        <Box mb={24}>
          <ButtonSwitch
            firstTitle={getRegistrationForm[0].name}
            secondTitle={getRegistrationForm[1].name}
            value={form.values.registration_form  ===  getRegistrationForm[0].id}
            onChange={() =>
              form.setFieldValue(
                "registration_form",
                form.values.registration_form  ===  getRegistrationForm[0].id
                  ? getRegistrationForm[1].id
                  : getRegistrationForm[0].id
              )
            }
          />
        </Box>
        <Box mb={24}>
          <Select
            value={form.values.business_area}
            onChange={(value) => form.setFieldValue("business_area", value)}
            label={t("Направление")}
            size="small"
            error={
              form.touched.business_area && Boolean(form.errors.business_area)
            }
            helperText={form.touched.business_area && form.errors.business_area}
            options={
              !isEmpty(getBusinessAreas?.data?.results)
                ? getBusinessAreas?.data?.results?.map((item) => ({
                    value: `${item.id}`,
                    label: item.name,
                  })) || []
                : []
            }
          />
        </Box>
        <TextInput
          size="small"
          label={t("ИНН")}
          placeholder={t("Введите значение")}
          onChange={(value) => form.setFieldValue("tax_number", value)}
          value={form.values.tax_number}
          error={form.touched.tax_number && Boolean(form.errors.tax_number)}
          helperText={form.touched.tax_number && form.errors.tax_number}
        />
        <Box flex alignItems="center" justifyContent="space-between" mt={24}>
          <Button
            size="small"
            variant="outlined"
            disabled={isLoading || isLoadingUpload}
            onClick={handleLogout}
          >
            {t("Выйти из аккаунта")}
          </Button>
          <Button
            size="small"
            disabled={isLoading || isLoadingUpload}
            onClick={() => form.handleSubmit()}
          >
            {t("Подтвердить")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
