import zones from "tzdata";
import { DateTime } from "luxon";

export const timeZones = Object.entries(zones.zones)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // .filter(([_, v]) => Array.isArray(v))
  .filter(([zoneName]) => DateTime.local().setZone(zoneName).isValid)
  .map(([zoneName]) => ({
      label: `${zoneName} ${String(
        DateTime.local().setZone(zoneName).toFormat("ZZ")
      )}`,
      value: zoneName.replace("Kyiv", "Kiev"),
    }))
  .sort((a, b) => {
    if (a.label?.split(" ")?.[1] < b.label?.split(" ")?.[1]) {
      return -1;
    }
    if (a.label?.split(" ")?.[1] > b.label?.split(" ")?.[1]) {
      return 1;
    }
    return 0;
  });
