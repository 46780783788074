import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

export interface IModalContext {
  open: (body: React.ReactElement | string, width?: number | null) => void;
  close: () => void;
}

export const ModalContext = React.createContext<IModalContext | null>(null);

export const ModalProvider = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [body, setBody] = useState<React.ReactElement | string>("");
  const [width, setWidth] = useState<number>(500);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isOpen]);

  const value = useMemo(
    () => ({
      open: (
        bodyValue: React.ReactElement | string,
        widthValue?: number | null
      ) => {
        setIsOpen(true);
        setBody(bodyValue);
        if (widthValue) {
          setWidth(widthValue);
        }
      },
      close: () => {
        setIsOpen(false);
        setBody("");
        setWidth(500);
      },
    }),
    []
  );

  const handleToggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ModalContext.Provider value={value}>
      {isOpen && (
        <StyledModalRoot>
          <StyledModalOverlay onClick={handleToggleIsOpen} />
          <StyledModalWrapper width={width}>
            <StyledModalWindow>
              <div>{body}</div>
            </StyledModalWindow>
          </StyledModalWrapper>
        </StyledModalRoot>
      )}
      {children}
    </ModalContext.Provider>
  );
};

const StyledModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;

const StyledModalWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(calc(100vh / 8));
  top: 0%;
  z-index: 200;
  width: ${(props: { width: number }) => props.width}px;
  max-width: calc(100% - 20px);
  padding-bottom: 50px;
  padding-top: 50px;
`;
const StyledModalWindow = styled.div`
  background: white;
  padding: 32px;
  box-sizing: border-box;
`;

const StyledModalRoot = styled.div`
  overflow-x: hidden;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  width: 100%;
  overflow-y: scroll;
  display: flex;
`;
