import { FormikErrors } from "formik";
import { isNumber } from "lodash";
import { ReactElement } from "react";
import { CommissionResponse } from "api/baseAPI/services";
import { Wallet } from "api/baseAPI/wallets";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { PayoutForm } from "pages/payout/logic/forms/PayoutForm";
import { IPayoutCategory } from "pages/payout/logic/payoutsCategory";
import {
  Commissions,
  PayoutData,
  PayoutFormData,
  getCustomerCommission,
  getPointCommission,
} from "pages/payout/logic/payoutsCreateLogic";
import { IPayoutMethod } from "./index";

export type Subscriber = {
  setPayouts: (payouts: Record<string, PayoutData>) => void;
  setSaveToTemplate: (save: boolean) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setDelay: (delay: number) => void;
  setFormErrors: (errors: FormikErrors<PayoutFormData>) => void;
};

export class BasePayoutMethod {
  isLoaded: boolean = false;

  isActive: boolean = false;

  commissionsData: CommissionResponse | null = null;

  subscribers: Record<string, Subscriber> = {};

  wallet: Wallet | null = null;

  currency: string | null = null;

  pointManager: ReturnType<typeof useSelectedPointManager> | null = null;

  category: IPayoutCategory | null = null;

  errors: FormikErrors<PayoutFormData> | null = null;

  values: PayoutFormData | null = null;

  additional_payout_information: any = null;

  jsonExample: Record<string, string | number>[] | null = null;

  filenameExample: string = "";

  csvExample: string | null = null;

  xlsxExample: string | null = null;

  constructor(category: IPayoutCategory) {
    this.category = category;
  }

  addSubscriber(key: string, subscriber: Subscriber) {
    this.subscribers[key] = subscriber;
  }

  notifyFormUpdate(payouts: Record<string, PayoutData>) {
    Object.values(this.subscribers).forEach((s) => s.setPayouts(payouts));
  }

  notifyFormSaveToTemplate(isSave: boolean) {
    Object.values(this.subscribers).forEach((s) => s.setSaveToTemplate(isSave));
  }

  notifyIsSubmiting(isSubmiting: boolean) {
    Object.values(this.subscribers).forEach((s) =>
      s.setIsSubmitting(isSubmiting)
    );
  }

  notifyChangeDelay(delay: number) {
    Object.values(this.subscribers).forEach((s) => s.setDelay(delay));
  }

  notifyChangeErrors(errors: FormikErrors<PayoutFormData>) {
    Object.values(this.subscribers).forEach((s) => s.setFormErrors(errors));
  }

  handleSubmit() {
    console.warn("Not implemented");
  }

  handleValidate() {
    return new Promise((resolve: (res: boolean) => void) => {
      resolve(false);
    });
  }

  resetForm() {
    console.warn("Not implemented");
  }

  handleSaveToTemplate() {
    console.warn("Not implemented");
  }

  getCommissions(payouts: Record<string, PayoutData>): Commissions {
    const customer_fix = this.commissionsData?.customer?.fix || null;
    const customer_percent = this.commissionsData?.customer?.percent || null;
    const point_fix = this.commissionsData?.point?.fix || null;
    const point_percent = this.commissionsData?.point?.percent || null;

    return Object.entries(payouts).reduce((acc, [id, payout]) => {
      acc[id] = {
        point: getPointCommission(
          Number(payout.amount),
          point_fix,
          point_percent
        ),
        customer: getCustomerCommission(
          Number(payout.amount),
          customer_fix,
          customer_percent
        ),
      };
      return acc;
    }, {} as Commissions);
  }

  getService(service: number | Record<string, number>): number {
    return isNumber(service)
      ? service
      : this.currency && service?.[this.currency]
      ? service?.[this.currency]
      : 0;
  }

  form: (payoutMethod: IPayoutMethod) => ReactElement = (payoutMethod) => (
    <PayoutForm payoutMethod={payoutMethod} />
  );
}
