export type Padding = {
  p?: number;
  px?: number;
  py?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
};

export function paddingProps(props: Padding) {
  return (({ p, py, px, pt, pr, pb, pl }) => ({
    p,
    py,
    px,
    pt,
    pr,
    pb,
    pl,
  }))(props);
}

export function paddingStyle(props: Padding) {
  return `
    ${props.p ? `padding:${  props.p  }px;` : ""}
    ${
      props.px
        ? `padding-left:${  props.px  }px; padding-right:${  props.px  }px;`
        : ""
    }
    ${
      props.py
        ? `padding-top:${  props.py  }px; padding-bottom:${  props.py  }px;`
        : ""
    }
    ${props.pt ? `padding-top:${  props.pt  }px;` : ""}
    ${props.pr ? `padding-right:${  props.pr  }px;` : ""}
    ${props.pb ? `padding-bottom:${  props.pb  }px;` : ""}
    ${props.pl ? `padding-left:${  props.pl  }px;` : ""}
  `;
}
