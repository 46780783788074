import { useFormik } from "formik";
import {
  filter,
  find,
  includes,
  isArray,
  isEmpty,
  map,
  reduce,
  replace,
} from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Divider } from "UI/Divider";
import { MultiSelect } from "UI/Form/MultiSelect";
import { Select } from "UI/Form/Select";
import { Switch } from "UI/Form/Switch";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  useCreateReportRequestMutation,
  useGetReportRequestsQuery,
} from "api/baseAPI/reports";
import {
  useGetUsersMeReportRequestPermissionsQuery,
  useGetUsersSelfQuery,
} from "api/baseAPI/user";
import {
  useLazyGetWalletQuery,
  useTransactionStatuses,
} from "api/baseAPI/wallets";
import { LinearProgress } from "components/LinearProgress";
import { DateSelector } from "components/RangeSelector/DateSelector";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  useSelectedPointManager,
  useSelectedWalletManager,
} from "hooks/useSelectedEntity";
import { dateFormatter } from "utils/date";
import { getColors } from "utils/getColors";
import { getTimezone } from "utils/getTimezone";
import { ReactComponent as CircleCheckIcon } from "utils/img/circle-check.svg";
import { ReactComponent as CircleCloseIcon } from "utils/img/circle-close.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { ReportRequestResponses } from "./parts/ReportRequestResponses";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
};

const BoxColor = ({ children }: { children: React.ReactElement }) => {
  const { pageBackground } = getColors();
  return (
    <Box flex mt={4} px={8} py={12} style={{ background: pageBackground }}>
      {children}
    </Box>
  );
};

export const ReportRequests = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Отчетность"), ""],
    [t("Запросы"), ""],
  ];

  const tableCellTitles = [
    t("ID"),
    t("Дата запроса отчета"),
    t("Периодичность"),
    t("Формат"),
    t("Статус"),
  ];

  const [openId, setOpenId] = useState<number>(0);
  const selectedPointManager = useSelectedPointManager();
  const getUsersSelfQuery = useGetUsersSelfQuery();
  const selectedWallet = useSelectedWalletManager();
  const { pageBackground } = getColors();
  const timezone = getTimezone();
  const getReportRequestPermissionsQuery =
    useGetUsersMeReportRequestPermissionsQuery();

  const [getWallet, getWalletMeta] = useLazyGetWalletQuery();
  const [createReportRequest, createReportRequestMeta] =
    useCreateReportRequestMutation();

  useEffect(() => {
    if (selectedWallet?.id) {
      getWallet({ id: selectedWallet?.id });
    }
  }, [selectedWallet]);

  const TRANSACTION_REPORT_STATUS: { [key: number]: string } = {
    0: t("Новый"),
    1: t("Принят в работу"),
    2: t("Уточнения требований"),
    3: t("Отменен"),
    4: t("Успешно обработан"),
  };

  const fieldsOptions = [
    { value: "id", label: "ID" },
    { value: "service", label: t("Сервис") },
    { value: "point_price", label: t("Сумма мерчанта") },
    { value: "point_currency", label: t("Валюта мерчанта") },
    { value: "provider_amount", label: t("Сумма клиента") },
    { value: "provider_currency", label: t("Валюта клиента") },
    { value: "amount", label: t("Сумма") },
    { value: "amount_currency", label: t("Валюта суммы") },
    { value: "status", label: t("Статус") },
    { value: "failure_reason_code", label: t("Ошибка") },
    { value: "external_transaction_id", label: t("Внешний ID") },
    { value: "external_customer_id", label: t("Внешний клиент") },
    { value: "description", label: t("Примечания") },
    { value: "uuid", label: t("Уникальный ID") },
    { value: "account", label: t("ID аккаунта") },
    { value: "created_at", label: t("Дата") },
    { value: "card_number", label: t("Аккаунт") },
    { value: "card_ps", label: t("ПС") },
    {
      value: "failure_reason",
      label: t("Описание ошибки"),
    },
  ];

  const { queryParams, querySetters } = useUrlQuery<{
    page: string;
    page_size: string;
    search: string;
  }>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const { data, isFetching, refetch } = useGetReportRequestsQuery({
    ...queryParams,
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  useSuccessNotification([createReportRequestMeta]);
  useErrorNotification([createReportRequestMeta]);

  const form = useFormik({
    initialValues: {
      created_at__gte: DateTime.local()
        .setZone(timezone)
        .minus({ day: 3 })
        .startOf("day")
        .toISO(),
      created_at__lte: DateTime.local().setZone(timezone).toISO(),
      account_wallet_id__in: !isEmpty(selectedPointManager?.available_wallets)
        ? String(selectedPointManager?.available_wallets?.[0]?.id)
        : "",
      fields: [],
      status__in: "",
      service_type: "",
      export_type: "1",
      receive_email: "",
      description: "",
      periodic: false,
      frequency_unit: "",
      frequency: "",
    },
    onSubmit: (values) => {
      const filters = reduce(
        {
          created_at__gte: values.created_at__gte,
          created_at__lte: values.created_at__lte,
          status__in: values.status__in,
          service_type: values.service_type,
          fields: !isEmpty(values.fields)
            ? map(
                filter(fieldsOptions, (item) =>
                  includes(values.fields, item.value)
                ),
                "value"
              )
            : map(fieldsOptions, "value"),
          account_wallet_id__in: includes(values.account_wallet_id__in, ",")
            ? values.account_wallet_id__in.split(",")
            : values.account_wallet_id__in,
          export_type: "",
          model: "Transaction",
        },
        (acc, value, key) => ({
          ...acc,
          ...(!isEmpty(value)
            ? {
                ...(includes(key, "__in") &&
                value &&
                !includes(value, ",") &&
                !isArray(value)
                  ? {
                      [replace(key, "__in", "")]: value,
                    }
                  : {
                      [key]: value,
                    }),
              }
            : {}),
        }),
        {}
      );

      createReportRequest({
        account_id: getWalletMeta?.data?.account?.id || 0,
        export_type: Number(values.export_type),
        receive_email: values.receive_email,
        description: values.description,
        periodic: values.periodic,
        frequency_unit: values.frequency_unit || null,
        frequency: values.frequency || null,
        user: getUsersSelfQuery.data?.id || 0,
        filters,
      });
    },
    validationSchema: Yup.object().shape({
      receive_email: Yup.string()
        .email(t("Неправильный формат"))
        .required(t("Обязательное поле")),
    }),
  });

  useTranslateFormErrors(form);

  const handleOpen = (id: number) => {
    setOpenId(openId === id ? 0 : id);
  };

  const TRANSACTION_STATUSES = useTransactionStatuses();

  return (
    <Layout title={t("Запросы")} breadCrumbs={breadCrumbs}>
      <Box p={24} style={{ background: "white" }}>
        <Typography variant="h6" mb={16}>
          {t("Введите данные для формирования отчета")}
        </Typography>
        <Grid container hSpace={24} vSpace={24}>
          <Grid item sm={12} lg={6} xl={4}>
            <Select
              value={form.values.service_type}
              onChange={(val) => form.setFieldValue("service_type", val)}
              label={t("Тип транзакций")}
              size="small"
              options={[
                { value: "", label: t("Все типы") },
                { value: "0", label: t("Внутренние") },
                { value: "1", label: t("Выплаты") },
                { value: "2", label: t("Эквайринг") },
                { value: "3", label: "p2p" },
              ]}
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <MultiSelect
              value={
                form.values.status__in ? form.values.status__in.split(",") : []
              }
              onChange={(val) =>
                form.setFieldValue("status__in", val.join(","))
              }
              label={t("Статус")}
              placeholder={t("Все статусы")}
              size="small"
              options={[
                ...Object.entries(TRANSACTION_STATUSES).map(([k, v]) => ({
                  label: v,
                  value: k,
                })),
              ]}
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <MultiSelect
              value={
                form.values.account_wallet_id__in
                  ? form.values.account_wallet_id__in.split(",")
                  : []
              }
              onChange={(val) =>
                form.setFieldValue("account_wallet_id__in", val.join(","))
              }
              label={t("Кошельки")}
              placeholder={t("Выберите кошелек")}
              size="small"
              options={
                selectedPointManager?.available_wallets?.length > 0
                  ? selectedPointManager?.available_wallets?.map(
                      (item: { id: number; name: string }) => ({
                        value: `${item.id}`,
                        label: `${item.id} | ${item.name}`,
                      })
                    )
                  : []
              }
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <MultiSelect
              value={form.values.fields}
              onChange={(val) => form.setFieldValue("fields", val)}
              label={t("Выбор полей для загрузки отчетов")}
              placeholder={t("Все поля")}
              size="small"
              options={fieldsOptions}
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={6} xxl={4}>
            <DateSelector
              label={t("Период")}
              start={
                form.values.created_at__gte
                  ? DateTime.fromISO(form.values.created_at__gte)
                  : null
              }
              end={
                form.values.created_at__lte
                  ? DateTime.fromISO(form.values.created_at__lte)
                  : null
              }
              set_start={(val) => form.setFieldValue("created_at__gte", val)}
              set_end={(val) => form.setFieldValue("created_at__lte", val)}
              disabledClear
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <TextInput
              size="small"
              label={t("Почта получателя")}
              placeholder={t("Введите почту")}
              onChange={(value) => form.setFieldValue("receive_email", value)}
              value={form.values.receive_email}
              error={
                form.touched.receive_email && Boolean(form.errors.receive_email)
              }
              helperText={
                form.touched.receive_email && form.errors.receive_email
              }
            />
          </Grid>

          <Grid item sm={12}>
            <Divider direction="vertical" />
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <Box
              p={12}
              style={{
                background: pageBackground,
              }}
            >
              <Typography variant="subtitle2">
                {t("Периодический отчет")}
              </Typography>
              <Box
                flex
                alignItems="center"
                justifyContent="space-between"
                pt={8}
              >
                <Typography variant="subtitle3" mb={8}>
                  {t("Отчет будет приходить с заданной периодичностью")}
                </Typography>
                <Switch
                  value={form?.values.periodic}
                  onChange={(checked) => {
                    form?.setFieldValue("periodic", checked);
                    if (!checked) {
                      form?.setFieldValue("frequency", "");
                      form?.setFieldValue("frequency_unit", "");
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <Select
              value={form.values.frequency_unit}
              onChange={(val) => form.setFieldValue("frequency_unit", val)}
              label={t("Периодичность")}
              disabled={!form.values.periodic}
              size="small"
              options={[
                ...(!form.values.periodic ? [{ value: "", label: "" }] : []),
                { value: "day", label: t("День") },
                { value: "week", label: t("Неделя") },
                { value: "month", label: t("Месяц") },
              ]}
            />
            {form.values.frequency_unit ? (
              <TextInput
                mt={20}
                size="small"
                label={
                  form.values.frequency_unit === "day"
                    ? t("Дней")
                    : form.values.frequency_unit === "week"
                    ? t("Недель")
                    : t("Месяцев")
                }
                onChange={(value) =>
                  form.setFieldValue(
                    "frequency",
                    value.replace(/[^0-9]/g, "").slice(0, 3)
                  )
                }
                value={form.values.frequency}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <TextInput
              size="small"
              label={t("Комментарий")}
              placeholder={t("Введите комментарий")}
              onChange={(value) => form.setFieldValue("description", value)}
              value={form.values.description}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>

        <Grid
          container
          mt={24}
          style={{
            justifyContent: "space-between",
          }}
          hSpace={24}
          responsive={{
            sm: { direction: "column" },
            lg: { direction: "row" },
          }}
        >
          <Grid item>
            <Box
              flex
              alignItems="center"
              nowrap
              responsive={{
                sm: { direction: "column" },
                lg: { direction: "row" },
              }}
            >
              <Typography variant="h6">{t("Формат файла отчета")}</Typography>
              <ButtonSwitch
                responsive={{
                  lg: { mb: 0, mt: 0, ml: 16 },
                  sm: { mb: 16, mt: 16, ml: 0 },
                }}
                firstTitle="XLS"
                secondTitle="CSV"
                value={form.values.export_type === "1"}
                onChange={() =>
                  form.setFieldValue(
                    "export_type",
                    form.values.export_type === "2" ? "1" : "2"
                  )
                }
              />
            </Box>
          </Grid>
          <Grid item sm={12} lg={4}>
            <Button
              fullwidth
              onClick={() => form.handleSubmit()}
              disabled={
                createReportRequestMeta.isLoading ||
                !includes(getReportRequestPermissionsQuery.data, "add")
              }
            >
              {t("Запросить отчет")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {createReportRequestMeta.isLoading ? <LinearProgress /> : <></>}
      <Box p={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
            />
          }
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          hideActionColumn
        >
          {data && data.results.length > 0 ? (
            data.results.map((row) => (
              <React.Fragment key={row.id}>
                <StyledRow>
                  <StyledCell>
                    <Box flex alignItems="center" nowrap>
                      <IconCellOpen
                        open={openId === row.id}
                        onClick={() => handleOpen(row.id)}
                      />
                      {row.id}
                    </Box>
                  </StyledCell>
                  <StyledCell>
                    {dateFormatter.format(row.created_at)}
                  </StyledCell>
                  <StyledCell>
                    {row.frequency_unit
                      ? `${row.frequency} ${t(
                          row.frequency_unit === "day"
                            ? "День"
                            : row.frequency_unit === "week"
                            ? "Неделя"
                            : "Месяц"
                        )}`
                      : ""}
                  </StyledCell>
                  <StyledCell>
                    {row.export_type === 1 ? "XLS" : "CSV"}
                  </StyledCell>
                  <StyledCell>
                    {TRANSACTION_REPORT_STATUS[Number(row.status)]}
                  </StyledCell>
                </StyledRow>
                {openId === row.id && (
                  <StyledRow background="#ffffff">
                    <td colSpan={11}>
                      <Box p={20} pl={12}>
                        <Grid container hSpace={20} vSpace={20} mb={16}>
                          <Grid item sm={2.4}>
                            <Typography variant="subtitle3" mb={8}>
                              {t("Периодический отчет")}
                            </Typography>
                            <BoxColor>
                              <>
                                {row.periodic ? (
                                  <StyledCircleCheckIcon />
                                ) : (
                                  <StyledCircleCloseIcon />
                                )}
                                <Typography variant="subtitle2">
                                  {row.periodic ? t("Да") : t("Нет")}
                                </Typography>
                              </>
                            </BoxColor>
                          </Grid>
                          <Grid item sm={2.4}>
                            <Typography variant="subtitle3" mb={8}>
                              {t("Статусы")}
                            </Typography>
                            {row.filters?.status || row.filters?.status__in ? (
                              map(
                                (
                                  row.filters?.status ||
                                  row.filters?.status__in ||
                                  ""
                                ).split(","),
                                (id: keyof typeof TRANSACTION_STATUSES) => (
                                  <BoxColor key={id}>
                                    <Typography variant="subtitle2">
                                      {TRANSACTION_STATUSES[id]}
                                    </Typography>
                                  </BoxColor>
                                )
                              )
                            ) : (
                              <BoxColor>
                                <Typography variant="subtitle2">
                                  {t("Все статусы")}
                                </Typography>
                              </BoxColor>
                            )}
                          </Grid>
                          <Grid item sm={2.4}>
                            <Typography variant="subtitle3" mb={8}>
                              {t("Кошельки")}
                            </Typography>
                            {!isEmpty(row.filters?.account_wallet_id__in) ||
                            row.filters?.account_wallet_id ? (
                              map(
                                !isEmpty(row.filters?.account_wallet_id__in)
                                  ? row.filters?.account_wallet_id__in
                                  : [row.filters?.account_wallet_id],
                                (id) => {
                                  const wallet = find(
                                    selectedPointManager?.available_wallets,
                                    { id: Number(id) }
                                  );
                                  return (
                                    <BoxColor key={id}>
                                      <Typography variant="subtitle2">
                                        {wallet
                                          ? `${wallet.id} | ${wallet.name}`
                                          : id}
                                      </Typography>
                                    </BoxColor>
                                  );
                                }
                              )
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item sm={2.4}>
                            <Typography variant="subtitle3" mb={8}>
                              {t("Тип транзакций")}
                            </Typography>
                            <BoxColor>
                              <Typography variant="subtitle2">
                                {row.filters.service_type === "3" && "p2p"}
                                {row.filters.service_type === "2" &&
                                  t("Эквайринг")}
                                {row.filters.service_type === "1" &&
                                  t("Выплаты")}
                                {row.filters.service_type === "0" &&
                                  t("Внутренние")}
                                {!row.filters.service_type && t("Все типы")}
                              </Typography>
                            </BoxColor>
                          </Grid>
                          <Grid item sm={2.4}>
                            <Typography variant="subtitle3" mb={8}>
                              {t("Поля загрузки отчета")}
                            </Typography>
                            {!isEmpty(row.filters?.fields) ? (
                              <Box
                                style={{ maxHeight: 300, overflowY: "auto" }}
                              >
                                {map(row.filters?.fields, (key) => {
                                  const field = find(fieldsOptions, {
                                    value: key,
                                  });
                                  return (
                                    <BoxColor key={key}>
                                      <Typography variant="subtitle2">
                                        {field?.label || key}
                                      </Typography>
                                    </BoxColor>
                                  );
                                })}
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                        <ReportRequestResponses id={row.id} />
                      </Box>
                    </td>
                  </StyledRow>
                )}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </Table>
      </Box>
    </Layout>
  );
};

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  margin-right: 10px;
  path {
    fill: #3ea959 !important;
  }
`;

const StyledCircleCloseIcon = styled(CircleCloseIcon)`
  margin-right: 10px;
  path {
    fill: #d00025 !important;
  }
`;
