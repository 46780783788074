import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import { RootState } from "reducers";
import { removeAuth, setAuth } from "reducers/authSlice";
import { PointManagerItem } from "./baseAPI/pointManagers";

export const apiHost = window.location.host.includes("localhost")
  ? process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "/v0"
  : `https://${process.env.REACT_APP_API_SUBDOMAIN}${window.location.host.slice(
      window.location.host.indexOf("."),
      window.location.host.length
    )}/v0`;

export const baseQueryWithOutToken = fetchBaseQuery({
  baseUrl: apiHost,
});

export const baseQuery = fetchBaseQuery({
  baseUrl: apiHost,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.access_token;
    const lang = localStorage.getItem("lang");
    const pointManagerState = (getState() as RootState).basic
      .selectedPointManager;
    const pointManagerStore = window.localStorage.getItem("point_manager");
    const pointManager: PointManagerItem = pointManagerStore
      ? { ...JSON.parse(pointManagerStore) }
      : {};
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      headers.set("Accept-Language", lang ?? "uk");
      if (pointManager?.id || pointManagerState?.id)
        headers.set(
          "Point-Manager",
          `${pointManager?.id || pointManagerState?.id}`
        );
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    result.error.status  ===  401 &&
    window.localStorage.getItem("refresh_token")
  ) {
    const refreshResult = await baseQueryWithOutToken(
      {
        url: "/o/token/",
        method: "POST",
        body: {
          refresh_token: window.localStorage.getItem("refresh_token"),
          client_id: String(process.env.REACT_APP_API_AUTH_CLIENT_ID),
          grant_type: "refresh_token",
        },
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      // eslint-disable-next-lines @typescript-eslint/no-unused-vars
      // @ts-ignore
      api.dispatch(setAuth(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(removeAuth());
      window.localStorage.removeItem("point_manager");
      window.localStorage.removeItem("selected_wallet");
    }
  }
  return result;
};
