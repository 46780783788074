import { Route, Routes } from "react-router-dom";
import { TipUsers } from "./tip-users/TipUsers";
import { TipUserCreate } from "./tip-users/TipUserCreate";
import { TipUserEdit } from "./tip-users/TipUserEdit";

import { SocInvoiceDatas } from "./soc-invoce-data/SocInvoiceDatas";
import { SocInvoiceDataCreate } from "./soc-invoce-data/SocInvoiceDataCreate";

const Router = () => (
    <Routes>
      <Route path="tip-users" element={<TipUsers />} />
      <Route path="tip-users/create" element={<TipUserCreate />} />
      <Route path="tip-users/:id" element={<TipUserEdit />} />

      <Route path="soc-invoice-data" element={<SocInvoiceDatas />} />
      <Route
        path="soc-invoice-data/create"
        element={<SocInvoiceDataCreate />}
      />
    </Routes>
  )

export default Router;
