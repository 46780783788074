export const RESPONSIVE_SIZES = {
  sm: "max-width: 576px",
  md: "max-width: 768px",
  lg: "max-width: 992px",
  xl: "max-width: 1200px",
  xxl: "max-width: 1400px",

  overSm: "min-width: 577px",
  overMd: "min-width: 769px",
  overLg: "min-width: 993px",
  overXl: "min-width: 1201px",
  overXxl: "min-width: 1401px",
  overXxxl: "min-width: 1501px",
};
