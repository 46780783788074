export const countries = [
  {
    alpha2: "AU",
    name: "Австралія",
    name_ru: "Австралия",
    name_en: "Australia",
  },
  {
    alpha2: "AT",
    name: "Австрія",
    name_ru: "Австрия",
    name_en: "Austria",
  },
  {
    alpha2: "AZ",
    name: "Азербайджан",
    name_ru: "Азербайджан",
    name_en: "Azerbaijan",
  },
  {
    alpha2: "AX",
    name: "Аландські острови",
    name_ru: "Аландские острова",
    name_en: "Aland Islands",
  },
  {
    alpha2: "AL",
    name: "Албанія",
    name_ru: "Албания",
    name_en: "Albania",
  },
  {
    alpha2: "DZ",
    name: "Алжир",
    name_ru: "Алжир",
    name_en: "Algeria",
  },
  {
    alpha2: "AS",
    name: "Американське самоа",
    name_ru: "Американское Самоа",
    name_en: "American Samoa",
  },
  {
    alpha2: "AO",
    name: "Ангола",
    name_ru: "Ангола",
    name_en: "Angola",
  },
  {
    alpha2: "AI",
    name: "Ангілья",
    name_ru: "Ангилья",
    name_en: "Anguilla",
  },
  {
    alpha2: "AD",
    name: "Андорра",
    name_ru: "Андорра",
    name_en: "Andorra",
  },
  {
    alpha2: "AQ",
    name: "Антарктика",
    name_ru: "Антарктида",
    name_en: "Antarctica",
  },
  {
    alpha2: "AG",
    name: "Антигуа і барбуда",
    name_ru: "Антигуа и Барбуда",
    name_en: "Antigua and Barbuda",
  },
  {
    alpha2: "AR",
    name: "Аргентина",
    name_ru: "Аргентина",
    name_en: "Argentina",
  },
  {
    alpha2: "AW",
    name: "Аруба",
    name_ru: "Аруба",
    name_en: "Aruba",
  },
  {
    alpha2: "AF",
    name: "Афганістан",
    name_ru: "Афганистан",
    name_en: "Afghanistan",
  },
  {
    alpha2: "BS",
    name: "Багамські острови",
    name_ru: "Багамы",
    name_en: "Bahamas",
  },
  {
    alpha2: "BD",
    name: "Бангладеш",
    name_ru: "Бангладеш",
    name_en: "Bangladesh",
  },
  {
    alpha2: "BB",
    name: "Барбадос",
    name_ru: "Барбадос",
    name_en: "Barbados",
  },
  {
    alpha2: "BH",
    name: "Бахрейн",
    name_ru: "Бахрейн",
    name_en: "Bahrain",
  },
  {
    alpha2: "BE",
    name: "Бельгія",
    name_ru: "Бельгия",
    name_en: "Belgium",
  },
  {
    alpha2: "BZ",
    name: "Беліз",
    name_ru: "Белиз",
    name_en: "Belize",
  },
  {
    alpha2: "BJ",
    name: "Бенін",
    name_ru: "Бенин",
    name_en: "Benin",
  },
  {
    alpha2: "BM",
    name: "Бермудські острови",
    name_ru: "Бермуды",
    name_en: "Bermuda",
  },
  {
    alpha2: "BG",
    name: "Болгарія",
    name_ru: "Болгария",
    name_en: "Bulgaria",
  },
  {
    alpha2: "BO",
    name: "Болівія",
    name_ru: "Боливия",
    name_en: "Bolivia",
  },
  {
    alpha2: "BQ",
    name: "Бонайре",
    name_ru: "Бонэйр, Синт-Эстатиус и Саба",
    name_en: "Bonaire",
  },
  {
    alpha2: "BA",
    name: "Боснія і герцеговина",
    name_ru: "Босния и Герцеговина",
    name_en: "Bosnia and Herzegovina",
  },
  {
    alpha2: "BW",
    name: "Ботсвана",
    name_ru: "Ботсвана",
    name_en: "Botswana",
  },
  {
    alpha2: "BR",
    name: "Бразилія",
    name_ru: "Бразилия",
    name_en: "Brazil",
  },
  {
    alpha2: "BN",
    name: "Бруней-даруссалам",
    name_ru: "Бруней",
    name_en: "Brunei Darussalam",
  },
  {
    alpha2: "BF",
    name: "Буркіна-фасо",
    name_ru: "Буркина-Фасо",
    name_en: "Burkina Faso",
  },
  {
    alpha2: "BI",
    name: "Бурунді",
    name_ru: "Бурунди",
    name_en: "Burundi",
  },
  {
    alpha2: "BT",
    name: "Бутан",
    name_ru: "Бутан",
    name_en: "Bhutan",
  },
  {
    alpha2: "BY",
    name: "Білорусь",
    name_ru: "Белоруссия",
    name_en: "Belarus",
  },
  {
    alpha2: "VN",
    name: "В'єтнам",
    name_ru: "Вьетнам",
    name_en: "Viet Nam",
  },
  {
    alpha2: "VU",
    name: "Вануату",
    name_ru: "Вануату",
    name_en: "Vanuatu",
  },
  {
    alpha2: "GB",
    name: "Велика британія",
    name_ru: "Великобритания",
    name_en: "United Kingdom",
  },
  {
    alpha2: "VE",
    name: "Венесуела",
    name_ru: "Венесуэла",
    name_en: "Venezuela",
  },
  {
    alpha2: "VG",
    name: "Віргінські острови (брит.)",
    name_ru: "Британские Виргинские острова",
    name_en: "Virgin Islands, British",
  },
  {
    alpha2: "VI",
    name: "Віргінські острови (сша)",
    name_ru: "Виргинские Острова (США)",
    name_en: "Virgin Islands, U.S.",
  },
  {
    alpha2: "AM",
    name: "Вірменія",
    name_ru: "Армения",
    name_en: "Armenia",
  },
  {
    alpha2: "GA",
    name: "Габон",
    name_ru: "Габон",
    name_en: "Gabon",
  },
  {
    alpha2: "GM",
    name: "Гамбія",
    name_ru: "Гамбия",
    name_en: "Gambia",
  },
  {
    alpha2: "GH",
    name: "Гана",
    name_ru: "Гана",
    name_en: "Ghana",
  },
  {
    alpha2: "GY",
    name: "Гаяна",
    name_ru: "Гайана",
    name_en: "Guyana",
  },
  {
    alpha2: "HT",
    name: "Гаїті",
    name_ru: "Гаити",
    name_en: "Haiti",
  },
  {
    alpha2: "GP",
    name: "Гваделупа",
    name_ru: "Гваделупа",
    name_en: "Guadeloupe",
  },
  {
    alpha2: "GT",
    name: "Гватемала",
    name_ru: "Гватемала",
    name_en: "Guatemala",
  },
  {
    alpha2: "GN",
    name: "Гвінея",
    name_ru: "Гвинея",
    name_en: "Guinea",
  },
  {
    alpha2: "GW",
    name: "Гвінея-бісау",
    name_ru: "Гвинея-Бисау",
    name_en: "Guinea-Bissau",
  },
  {
    alpha2: "GG",
    name: "Гернсі",
    name_ru: "Гернси",
    name_en: "Guernsey",
  },
  {
    alpha2: "HN",
    name: "Гондурас",
    name_ru: "Гондурас",
    name_en: "Honduras",
  },
  {
    alpha2: "HK",
    name: "Гонконг",
    name_ru: "Гонконг",
    name_en: "Hong Kong",
  },
  {
    alpha2: "GD",
    name: "Гренада",
    name_ru: "Гренада",
    name_en: "Grenada",
  },
  {
    alpha2: "GL",
    name: "Гренландія",
    name_ru: "Гренландия",
    name_en: "Greenland",
  },
  {
    alpha2: "GR",
    name: "Греція",
    name_ru: "Греция",
    name_en: "Greece",
  },
  {
    alpha2: "GE",
    name: "Грузія",
    name_ru: "Грузия",
    name_en: "Georgia",
  },
  {
    alpha2: "GU",
    name: "Гуам",
    name_ru: "Гуам",
    name_en: "Guam",
  },
  {
    alpha2: "GI",
    name: "Гібралтар",
    name_ru: "Гибралтар",
    name_en: "Gibraltar",
  },
  {
    alpha2: "DK",
    name: "Данія",
    name_ru: "Дания",
    name_en: "Denmark",
  },
  {
    alpha2: "JE",
    name: "Джерсі",
    name_ru: "Джерси",
    name_en: "Jersey",
  },
  {
    alpha2: "DJ",
    name: "Джибуті",
    name_ru: "Джибути",
    name_en: "Djibouti",
  },
  {
    alpha2: "DM",
    name: "Домініка",
    name_ru: "Доминика",
    name_en: "Dominica",
  },
  {
    alpha2: "DO",
    name: "Домініканська республіка",
    name_ru: "Доминиканская Республика",
    name_en: "Dominican Republic",
  },
  {
    alpha2: "EC",
    name: "Еквадор",
    name_ru: "Эквадор",
    name_en: "Ecuador",
  },
  {
    alpha2: "GQ",
    name: "Екваторіальна гвінея",
    name_ru: "Экваториальная Гвинея",
    name_en: "Equatorial Guinea",
  },
  {
    alpha2: "ER",
    name: "Еритрея",
    name_ru: "Эритрея",
    name_en: "Eritrea",
  },
  {
    alpha2: "EE",
    name: "Естонія",
    name_ru: "Эстония",
    name_en: "Estonia",
  },
  {
    alpha2: "ET",
    name: "Ефіопія",
    name_ru: "Эфиопия",
    name_en: "Ethiopia",
  },
  {
    alpha2: "EG",
    name: "Єгипет",
    name_ru: "Египет",
    name_en: "Egypt",
  },
  {
    alpha2: "YE",
    name: "Ємен",
    name_ru: "Йемен",
    name_en: "Yemen",
  },
  {
    alpha2: "ZM",
    name: "Замбія",
    name_ru: "Замбия",
    name_en: "Zambia",
  },
  {
    alpha2: "EH",
    name: "Західна сахара",
    name_ru: "САДР",
    name_en: "Western Sahara",
  },
  {
    alpha2: "ZW",
    name: "Зімбабве",
    name_ru: "Зимбабве",
    name_en: "Zimbabwe",
  },
  {
    alpha2: "IL",
    name: "Ізраїль",
    name_ru: "Израиль",
    name_en: "Israel",
  },
  {
    alpha2: "ID",
    name: "Індонезія",
    name_ru: "Индонезия",
    name_en: "Indonesia",
  },
  {
    alpha2: "IN",
    name: "Індія",
    name_ru: "Индия",
    name_en: "India",
  },
  {
    alpha2: "IQ",
    name: "Ірак",
    name_ru: "Ирак",
    name_en: "Iraq",
  },
  {
    alpha2: "IR",
    name: "Іран, ісламська республіка",
    name_ru: "Иран",
    name_en: "Iran, Islamic Republic of",
  },
  {
    alpha2: "IE",
    name: "Ірландія",
    name_ru: "Ирландия",
    name_en: "Ireland",
  },
  {
    alpha2: "IS",
    name: "Ісландія",
    name_ru: "Исландия",
    name_en: "Iceland",
  },
  {
    alpha2: "ES",
    name: "Іспанія",
    name_ru: "Испания",
    name_en: "Spain",
  },
  {
    alpha2: "IT",
    name: "Італія",
    name_ru: "Италия",
    name_en: "Italy",
  },
  {
    alpha2: "JO",
    name: "Йорданія",
    name_ru: "Иордания",
    name_en: "Jordan",
  },
  {
    alpha2: "CV",
    name: "Кабо-верде",
    name_ru: "Кабо-Верде",
    name_en: "Cabo Verde",
  },
  {
    alpha2: "KZ",
    name: "Казахстан",
    name_ru: "Казахстан",
    name_en: "Kazakhstan",
  },
  {
    alpha2: "KY",
    name: "Кайманові острови",
    name_ru: "Острова Кайман",
    name_en: "Cayman Islands",
  },
  {
    alpha2: "KH",
    name: "Камбоджа",
    name_ru: "Камбоджа",
    name_en: "Cambodia",
  },
  {
    alpha2: "CM",
    name: "Камерун",
    name_ru: "Камерун",
    name_en: "Cameroon",
  },
  {
    alpha2: "CA",
    name: "Канада",
    name_ru: "Канада",
    name_en: "Canada",
  },
  {
    alpha2: "QA",
    name: "Катар",
    name_ru: "Катар",
    name_en: "Qatar",
  },
  {
    alpha2: "KE",
    name: "Кенія",
    name_ru: "Кения",
    name_en: "Kenya",
  },
  {
    alpha2: "KG",
    name: "Киргизстан",
    name_ru: "Киргизия",
    name_en: "Kyrgyzstan",
  },
  {
    alpha2: "CN",
    name: "Китай",
    name_ru: "КНР",
    name_en: "China",
  },
  {
    alpha2: "CO",
    name: "Колумбія",
    name_ru: "Колумбия",
    name_en: "Colombia",
  },
  {
    alpha2: "KM",
    name: "Комори",
    name_ru: "Коморы",
    name_en: "Comoros",
  },
  {
    alpha2: "CG",
    name: "Конго",
    name_ru: "Республика Конго",
    name_en: "Congo",
  },
  {
    alpha2: "CD",
    name: "Конго, демократична республіка",
    name_ru: "Демократическая Республика Конго",
    name_en: "Congo, Democratic Republic of the",
  },
  {
    alpha2: "KP",
    name: "Корейська народно-демократична республіка",
    name_ru: "КНДР",
    name_en: "Korea, Democratic People?s Republic",
  },
  {
    alpha2: "KR",
    name: "Корея, республіка",
    name_ru: "Республика Корея",
    name_en: "Korea, Republic of",
  },
  {
    alpha2: "CR",
    name: "Коста-рика",
    name_ru: "Коста-Рика",
    name_en: "Costa Rica",
  },
  {
    alpha2: "CI",
    name: "Кот-д'івуар",
    name_ru: "Кот-д’Ивуар",
    name_en: "Cote  d'Ivoire",
  },
  {
    alpha2: "CU",
    name: "Куба",
    name_ru: "Куба",
    name_en: "Cuba",
  },
  {
    alpha2: "KW",
    name: "Кувейт",
    name_ru: "Кувейт",
    name_en: "Kuwait",
  },
  {
    alpha2: "CW",
    name: "Кюрасао",
    name_ru: "Кюрасао",
    name_en: "Cura?ao",
  },
  {
    alpha2: "CY",
    name: "Кіпр",
    name_ru: "Кипр",
    name_en: "Cyprus",
  },
  {
    alpha2: "KI",
    name: "Кірибаті",
    name_ru: "Кирибати",
    name_en: "Kiribati",
  },
  {
    alpha2: "LV",
    name: "Латвія",
    name_ru: "Латвия",
    name_en: "Latvia",
  },
  {
    alpha2: "LS",
    name: "Лесото",
    name_ru: "Лесото",
    name_en: "Lesotho",
  },
  {
    alpha2: "LT",
    name: "Литва",
    name_ru: "Литва",
    name_en: "Lithuania",
  },
  {
    alpha2: "LU",
    name: "Люксембург",
    name_ru: "Люксембург",
    name_en: "Luxembourg",
  },
  {
    alpha2: "LR",
    name: "Ліберія",
    name_ru: "Либерия",
    name_en: "Liberia",
  },
  {
    alpha2: "LB",
    name: "Ліван",
    name_ru: "Ливан",
    name_en: "Lebanon",
  },
  {
    alpha2: "LY",
    name: "Лівія",
    name_ru: "Ливия",
    name_en: "Libya",
  },
  {
    alpha2: "LI",
    name: "Ліхтенштейн",
    name_ru: "Лихтенштейн",
    name_en: "Liechtenstein",
  },
  {
    alpha2: "MM",
    name: "М'янма",
    name_ru: "Мьянма",
    name_en: "Myanmar",
  },
  {
    alpha2: "MU",
    name: "Маврикій",
    name_ru: "Маврикий",
    name_en: "Mauritius",
  },
  {
    alpha2: "MR",
    name: "Мавританія",
    name_ru: "Мавритания",
    name_en: "Mauritania",
  },
  {
    alpha2: "MG",
    name: "Мадагаскар",
    name_ru: "Мадагаскар",
    name_en: "Madagascar",
  },
  {
    alpha2: "YT",
    name: "Майотта",
    name_ru: "Майотта",
    name_en: "Mayotte",
  },
  {
    alpha2: "MO",
    name: "Макао",
    name_ru: "Макао",
    name_en: "Macaо",
  },
  {
    alpha2: "MW",
    name: "Малаві",
    name_ru: "Малави",
    name_en: "Malawi",
  },
  {
    alpha2: "MY",
    name: "Малайзія",
    name_ru: "Малайзия",
    name_en: "Malaysia",
  },
  {
    alpha2: "MV",
    name: "Мальдіви",
    name_ru: "Мальдивы",
    name_en: "Maldives",
  },
  {
    alpha2: "MT",
    name: "Мальта",
    name_ru: "Мальта",
    name_en: "Malta",
  },
  {
    alpha2: "ML",
    name: "Малі",
    name_ru: "Мали",
    name_en: "Mali",
  },
  {
    alpha2: "UM",
    name: "Малі віддалені острови сша",
    name_ru: "Внешние малые острова (США)",
    name_en: "United States Minor Outlying Islands",
  },
  {
    alpha2: "MA",
    name: "Марокко",
    name_ru: "Марокко",
    name_en: "Morocco",
  },
  {
    alpha2: "MQ",
    name: "Мартиніка",
    name_ru: "Мартиника",
    name_en: "Martinique",
  },
  {
    alpha2: "MH",
    name: "Маршаллові острови",
    name_ru: "Маршалловы Острова",
    name_en: "Marshall Islands",
  },
  {
    alpha2: "MX",
    name: "Мексика",
    name_ru: "Мексика",
    name_en: "Mexico",
  },
  {
    alpha2: "MZ",
    name: "Мозамбік",
    name_ru: "Мозамбик",
    name_en: "Mozambique",
  },
  {
    alpha2: "MD",
    name: "Молдова, республіка",
    name_ru: "Молдавия",
    name_en: "Moldova, Republic of",
  },
  {
    alpha2: "MC",
    name: "Монако",
    name_ru: "Монако",
    name_en: "Monaco",
  },
  {
    alpha2: "MN",
    name: "Монголія",
    name_ru: "Монголия",
    name_en: "Mongolia",
  },
  {
    alpha2: "MS",
    name: "Монтсеррат",
    name_ru: "Монтсеррат",
    name_en: "Montserrat",
  },
  {
    alpha2: "FM",
    name: "Мікронезія, федеративні штати",
    name_ru: "Микронезия",
    name_en: "Micronesia, Federated States of",
  },
  {
    alpha2: "NA",
    name: "Намібія",
    name_ru: "Намибия",
    name_en: "Namibia",
  },
  {
    alpha2: "NR",
    name: "Науру",
    name_ru: "Науру",
    name_en: "Nauru",
  },
  {
    alpha2: "NP",
    name: "Непал",
    name_ru: "Непал",
    name_en: "Nepаl",
  },
  {
    alpha2: "NZ",
    name: "Нова зеландія",
    name_ru: "Новая Зеландия",
    name_en: "New Zealand",
  },
  {
    alpha2: "NC",
    name: "Нова каледонія",
    name_ru: "Новая Каледония",
    name_en: "New Caledonia",
  },
  {
    alpha2: "NO",
    name: "Норвегія",
    name_ru: "Норвегия",
    name_en: "Norway",
  },
  {
    alpha2: "NE",
    name: "Нігер",
    name_ru: "Нигер",
    name_en: "Niger",
  },
  {
    alpha2: "NG",
    name: "Нігерія",
    name_ru: "Нигерия",
    name_en: "Nigeria",
  },
  {
    alpha2: "NL",
    name: "Нідерланди",
    name_ru: "Нидерланды",
    name_en: "Netherlands",
  },
  {
    alpha2: "NI",
    name: "Нікарагуа",
    name_ru: "Никарагуа",
    name_en: "Nicaragua",
  },
  {
    alpha2: "DE",
    name: "Німеччина",
    name_ru: "Германия",
    name_en: "Germany",
  },
  {
    alpha2: "NU",
    name: "Ніуе",
    name_ru: "Ниуэ",
    name_en: "Niue",
  },
  {
    alpha2: "AE",
    name: "Об'єднані арабські емірати",
    name_ru: "ОАЭ",
    name_en: "United Arab Emirates",
  },
  {
    alpha2: "OM",
    name: "Оман",
    name_ru: "Оман",
    name_en: "Oman",
  },
  {
    alpha2: "CK",
    name: "Острови кука",
    name_ru: "Острова Кука",
    name_en: "Cook Islands",
  },
  {
    alpha2: "TC",
    name: "Острови теркс і кайкос",
    name_ru: "Тёркс и Кайкос",
    name_en: "Turks and Caicos Islands",
  },
  {
    alpha2: "BV",
    name: "Острів буве",
    name_ru: "Остров Буве",
    name_en: "Bouvet Island",
  },
  {
    alpha2: "IM",
    name: "Острів мен",
    name_ru: "Остров Мэн",
    name_en: "Isle of Man",
  },
  {
    alpha2: "NF",
    name: "Острів норфолк",
    name_ru: "Остров Норфолк",
    name_en: "Norfolk Island",
  },
  {
    alpha2: "CX",
    name: "Острів різдва",
    name_ru: "Остров Рождества",
    name_en: "Christmas Island",
  },
  {
    alpha2: "SH",
    name: "Острів святої єлени",
    name_ru: "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
    name_en: "Saint Helena",
  },
  {
    alpha2: "PK",
    name: "Пакистан",
    name_ru: "Пакистан",
    name_en: "Pakistan",
  },
  {
    alpha2: "PW",
    name: "Палау",
    name_ru: "Палау",
    name_en: "Palau",
  },
  {
    alpha2: "PS",
    name: "Палестина",
    name_ru: "Государство Палестина",
    name_en: "State of Palestine",
  },
  {
    alpha2: "PA",
    name: "Панама",
    name_ru: "Панама",
    name_en: "Panama",
  },
  {
    alpha2: "PG",
    name: "Папуа-нова гвінея",
    name_ru: "Папуа — Новая Гвинея",
    name_en: "Papua New Guinea",
  },
  {
    alpha2: "PY",
    name: "Парагвай",
    name_ru: "Парагвай",
    name_en: "Paraguay",
  },
  {
    alpha2: "PE",
    name: "Перу",
    name_ru: "Перу",
    name_en: "Peru",
  },
  {
    alpha2: "PL",
    name: "Польща",
    name_ru: "Польша",
    name_en: "Poland",
  },
  {
    alpha2: "PT",
    name: "Португалія",
    name_ru: "Португалия",
    name_en: "Portugal",
  },
  {
    alpha2: "PR",
    name: "Пуерто-рико",
    name_ru: "Пуэрто-Рико",
    name_en: "Puerto Rico",
  },
  {
    alpha2: "ZA",
    name: "Південна африка",
    name_ru: "ЮАР",
    name_en: "South Africa",
  },
  {
    alpha2: "SS",
    name: "Південний судан",
    name_ru: "Южный Судан",
    name_en: "South Sudan",
  },
  {
    alpha2: "MP",
    name: "Північні маріанські острови",
    name_ru: "Северные Марианские острова",
    name_en: "Northern Mariana Islands",
  },
  {
    alpha2: "PN",
    name: "Піткерн",
    name_ru: "Острова Питкэрн",
    name_en: "Pitcairn",
  },
  {
    alpha2: "MK",
    name: "Республіка македонія",
    name_ru: "Македония",
    name_en: "Macedonia",
  },
  {
    alpha2: "RE",
    name: "Реюньйон",
    name_ru: "Реюньон",
    name_en: "R?union",
  },
  {
    alpha2: "RU",
    name: "Російська федерація",
    name_ru: "Россия",
    name_en: "Russian Federation",
  },
  {
    alpha2: "RW",
    name: "Руанда",
    name_ru: "Руанда",
    name_en: "Rwanda",
  },
  {
    alpha2: "RO",
    name: "Румунія",
    name_ru: "Румыния",
    name_en: "Romania",
  },
  {
    alpha2: "SV",
    name: "Сальвадор",
    name_ru: "Сальвадор",
    name_en: "El Salvador",
  },
  {
    alpha2: "WS",
    name: "Самоа",
    name_ru: "Самоа",
    name_en: "Samoa",
  },
  {
    alpha2: "SM",
    name: "Сан-марино",
    name_ru: "Сан-Марино",
    name_en: "San Marino",
  },
  {
    alpha2: "ST",
    name: "Сан-томе і принсіпі",
    name_ru: "Сан-Томе и Принсипи",
    name_en: "Sao Tome and Principe",
  },
  {
    alpha2: "-",
    name: "Сарк",
    name_ru: "Сарк",
    name_en: "Sark",
  },
  {
    alpha2: "SA",
    name: "Саудівська аравія",
    name_ru: "Саудовская Аравия",
    name_en: "Saudi Arabia",
  },
  {
    alpha2: "SZ",
    name: "Свазіленд",
    name_ru: "Свазиленд",
    name_en: "Swaziland",
  },
  {
    alpha2: "SC",
    name: "Сейшельські острови",
    name_ru: "Сейшельские Острова",
    name_en: "Seychelles",
  },
  {
    alpha2: "BL",
    name: "Сен-бартелемі",
    name_ru: "Сен-Бартелеми",
    name_en: "Saint Barth?lemy",
  },
  {
    alpha2: "MF",
    name: "Сен-мартен",
    name_ru: "Сен-Мартен",
    name_en: "Saint Martin",
  },
  {
    alpha2: "PM",
    name: "Сен-п'єр і мікелон",
    name_ru: "Сен-Пьер и Микелон",
    name_en: "Saint Pierre and Miquelon",
  },
  {
    alpha2: "SN",
    name: "Сенегал",
    name_ru: "Сенегал",
    name_en: "Senegal",
  },
  {
    alpha2: "VC",
    name: "Сент-вінсент і гренадіни",
    name_ru: "Сент-Винсент и Гренадины",
    name_en: "Saint Vincent and the Grenadines",
  },
  {
    alpha2: "KN",
    name: "Сент-кітс і невіс",
    name_ru: "Сент-Китс и Невис",
    name_en: "Saint Kitts аnd Nevis",
  },
  {
    alpha2: "LC",
    name: "Сент-люсія",
    name_ru: "Сент-Люсия",
    name_en: "Saint Lucia",
  },
  {
    alpha2: "RS",
    name: "Сербія",
    name_ru: "Сербия",
    name_en: "Serbia",
  },
  {
    alpha2: "SG",
    name: "Сингапур",
    name_ru: "Сингапур",
    name_en: "Singapore",
  },
  {
    alpha2: "SY",
    name: "Сирійська арабська республіка",
    name_ru: "Сирия",
    name_en: "Syrian Arab Republic",
  },
  {
    alpha2: "SK",
    name: "Словаччина",
    name_ru: "Словакия",
    name_en: "Slovakia",
  },
  {
    alpha2: "SI",
    name: "Словенія",
    name_ru: "Словения",
    name_en: "Slovenia",
  },
  {
    alpha2: "SB",
    name: "Соломонові острови",
    name_ru: "Соломоновы Острова",
    name_en: "Solomon Islands",
  },
  {
    alpha2: "SO",
    name: "Сомалі",
    name_ru: "Сомали",
    name_en: "Somalia",
  },
  {
    alpha2: "SD",
    name: "Судан",
    name_ru: "Судан",
    name_en: "Sudan",
  },
  {
    alpha2: "SR",
    name: "Суринам",
    name_ru: "Суринам",
    name_en: "Suriname",
  },
  {
    alpha2: "US",
    name: "Сша",
    name_ru: "США",
    name_en: "United States",
  },
  {
    alpha2: "SL",
    name: "Сьєрра-леоне",
    name_ru: "Сьерра-Леоне",
    name_en: "Sierra Leone",
  },
  {
    alpha2: "TJ",
    name: "Таджикистан",
    name_ru: "Таджикистан",
    name_en: "Tajikistan",
  },
  {
    alpha2: "TW",
    name: "Тайвань, провінція китаю",
    name_ru: "Китайская Республика",
    name_en: "Taiwan, Province of China",
  },
  {
    alpha2: "TZ",
    name: "Танзанія, об'єднана республіка",
    name_ru: "Танзания",
    name_en: "Tanzania, United Republic of",
  },
  {
    alpha2: "TH",
    name: "Таїланд",
    name_ru: "Таиланд",
    name_en: "Thailand",
  },
  {
    alpha2: "TL",
    name: "Тимор-лешті",
    name_ru: "Восточный Тимор",
    name_en: "Timor-Leste",
  },
  {
    alpha2: "TG",
    name: "Того",
    name_ru: "Того",
    name_en: "Togo",
  },
  {
    alpha2: "TK",
    name: "Токелау",
    name_ru: "Токелау",
    name_en: "Tokelau",
  },
  {
    alpha2: "TO",
    name: "Тонга",
    name_ru: "Тонга",
    name_en: "Tonga",
  },
  {
    alpha2: "TT",
    name: "Тринідад і тобаго",
    name_ru: "Тринидад и Тобаго",
    name_en: "Trinidad and Tobago",
  },
  {
    alpha2: "TV",
    name: "Тувалу",
    name_ru: "Тувалу",
    name_en: "Tuvalu",
  },
  {
    alpha2: "TN",
    name: "Туніс",
    name_ru: "Тунис",
    name_en: "Tunisia",
  },
  {
    alpha2: "TR",
    name: "Туреччина",
    name_ru: "Турция",
    name_en: "Turkey",
  },
  {
    alpha2: "TM",
    name: "Туркменистан",
    name_ru: "Туркмения",
    name_en: "Turkmenistan",
  },
  {
    alpha2: "UG",
    name: "Уганда",
    name_ru: "Уганда",
    name_en: "Uganda",
  },
  {
    alpha2: "HU",
    name: "Угорщина",
    name_ru: "Венгрия",
    name_en: "Hungary",
  },
  {
    alpha2: "UZ",
    name: "Узбекистан",
    name_ru: "Узбекистан",
    name_en: "Uzbekistan",
  },
  {
    alpha2: "UA",
    name: "Україна",
    name_ru: "Украина",
    name_en: "Ukraine",
  },
  {
    alpha2: "WF",
    name: "Уолліс і футуна",
    name_ru: "Уоллис и Футуна",
    name_en: "Wallis and Futuna Islands",
  },
  {
    alpha2: "UY",
    name: "Уругвай",
    name_ru: "Уругвай",
    name_en: "Uruguay",
  },
  {
    alpha2: "FO",
    name: "Фарерські острови",
    name_ru: "Фареры",
    name_en: "Faroe Islands",
  },
  {
    alpha2: "FK",
    name: "Фолклендські острови",
    name_ru: "Фолклендские острова",
    name_en: "Falkland Islands",
  },
  {
    alpha2: "GF",
    name: "Французька гвіана",
    name_ru: "Гвиана",
    name_en: "French Guiana",
  },
  {
    alpha2: "PF",
    name: "Французька полінезія",
    name_ru: "Французская Полинезия",
    name_en: "French Polynesia",
  },
  {
    alpha2: "FR",
    name: "Франція",
    name_ru: "Франция",
    name_en: "France",
  },
  {
    alpha2: "FJ",
    name: "Фіджі",
    name_ru: "Фиджи",
    name_en: "Fiji",
  },
  {
    alpha2: "PH",
    name: "Філіппіни",
    name_ru: "Филиппины",
    name_en: "Philippines",
  },
  {
    alpha2: "FI",
    name: "Фінляндія",
    name_ru: "Финляндия",
    name_en: "Finland",
  },
  {
    alpha2: "HR",
    name: "Хорватія",
    name_ru: "Хорватия",
    name_en: "Croatia",
  },
  {
    alpha2: "CF",
    name: "Центральноафриканська республіка",
    name_ru: "ЦАР",
    name_en: "Central African Republic",
  },
  {
    alpha2: "TD",
    name: "Чад",
    name_ru: "Чад",
    name_en: "Chad",
  },
  {
    alpha2: "CZ",
    name: "Чехія",
    name_ru: "Чехия",
    name_en: "Czech Republic",
  },
  {
    alpha2: "CL",
    name: "Чилі",
    name_ru: "Чили",
    name_en: "Chile",
  },
  {
    alpha2: "ME",
    name: "Чорногорія",
    name_ru: "Черногория",
    name_en: "Montenegro",
  },
  {
    alpha2: "CH",
    name: "Швейцарія",
    name_ru: "Швейцария",
    name_en: "Switzerland",
  },
  {
    alpha2: "SE",
    name: "Швеція",
    name_ru: "Швеция",
    name_en: "Sweden",
  },
  {
    alpha2: "SJ",
    name: "Шпіцберген та ян-маєн",
    name_ru: "Шпицберген и Ян-Майен",
    name_en: "Svalbard and Jan Mayen",
  },
  {
    alpha2: "LK",
    name: "Шри-ланка",
    name_ru: "Шри-Ланка",
    name_en: "Sri Lanka",
  },
  {
    alpha2: "JM",
    name: "Ямайка",
    name_ru: "Ямайка",
    name_en: "Jamaica",
  },
  {
    alpha2: "JP",
    name: "Японія",
    name_ru: "Япония",
    name_en: "Japan",
  },
];
