import { RESPONSIVE_SIZES } from "utils/tools";

export type SpacingWidth = number;

export type ColumnWidth =
  | "content"
  | "fill"
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | number;

export type FlexSpace = {
  smHSpace?: SpacingWidth;
  mdHSpace?: SpacingWidth;
  lgHSpace?: SpacingWidth;
  xlHSpace?: SpacingWidth;
  xxlHSpace?: SpacingWidth;
  hSpace?: SpacingWidth;
  smVSpace?: SpacingWidth;
  mdVSpace?: SpacingWidth;
  lgVSpace?: SpacingWidth;
  xlVSpace?: SpacingWidth;
  xxlVSpace?: SpacingWidth;
  vSpace?: SpacingWidth;
};

export type FlexSize = {
  sm?: ColumnWidth;
  md?: ColumnWidth;
  lg?: ColumnWidth;
  xl?: ColumnWidth;
  xxl?: ColumnWidth;
} & FlexSpace;

export function getFlexSizeProps(props: FlexSize) {
  return (({ sm, md, lg, xl, xxl }) => ({
    sm,
    md,
    lg,
    xl,
    xxl,
  }))(props);
}

export function getFlexSpaceProps(props: FlexSpace) {
  return (({
    smHSpace,
    mdHSpace,
    lgHSpace,
    xlHSpace,
    xxlHSpace,
    hSpace,
    smVSpace,
    mdVSpace,
    lgVSpace,
    xlVSpace,
    xxlVSpace,
    vSpace,
  }) => ({
    smHSpace,
    mdHSpace,
    lgHSpace,
    xlHSpace,
    xxlHSpace,
    hSpace,
    smVSpace,
    mdVSpace,
    lgVSpace,
    xlVSpace,
    xxlVSpace,
    vSpace,
  }))(props);
}

function calcGridItemSize(size: ColumnWidth, hSpace: SpacingWidth) {
  switch (size) {
    case "fill":
      return "flex-shrink: 1; flex-grow: 1;";
    case "content":
      return "flex-shrink: 1;";
    default:
      return size  ===  12
        ? `flex-basis: 100%`
        : `flex-basis: calc((100% - ${
            hSpace * (12 / size - 1)
          }px) / (12 / ${size}))`;
  }
}

export function flexContainerSizeStyle(props: FlexSpace) {
  const hSpace = props.hSpace || 0;
  const vSpace = props.vSpace || 0;

  return `
    display: flex;
    gap: ${vSpace}px ${hSpace}px;
    box-sizing: border-box;
    position: relative;
    @media (${RESPONSIVE_SIZES.sm}) {
      gap: ${props.smVSpace || vSpace}px ${props.smHSpace || hSpace}px;
    }
    @media (${RESPONSIVE_SIZES.overSm}) and (${RESPONSIVE_SIZES.md}) {
      gap: ${props.mdVSpace || vSpace}px ${props.mdHSpace || hSpace}px;
    }
    @media (${RESPONSIVE_SIZES.overMd}) and (${RESPONSIVE_SIZES.lg}) {
      gap: ${props.lgVSpace || vSpace}px ${props.lgHSpace || hSpace}px;
    }
    @media (${RESPONSIVE_SIZES.overLg}) and (${RESPONSIVE_SIZES.xl}) {
      gap: ${props.xlVSpace || vSpace}px ${props.xlHSpace || hSpace}px;
    }
    @media (${RESPONSIVE_SIZES.overXl}) {
      gap: ${props.xxlVSpace || vSpace}px ${props.xxlHSpace || hSpace}px;
    }
  `;
}

export function flexItemSizeStyle(props: FlexSize & FlexSpace) {
  const sm = props.sm || "content";
  const md = props.md || props.sm || "content";
  const lg = props.lg || props.md || props.sm || "content";
  const xl = props.xl || props.lg || props.md || props.sm || "content";
  const xxl =
    props.xxl || props.xl || props.lg || props.md || props.sm || "content";

  const hSpace = props.hSpace || 0;

  return `@media (${RESPONSIVE_SIZES.sm}) {
    ${calcGridItemSize(sm, props.smHSpace || hSpace)}
  }
  @media (${RESPONSIVE_SIZES.overSm}) and (${RESPONSIVE_SIZES.md}) {
    ${calcGridItemSize(md, props.mdHSpace || hSpace)}
  }
  @media (${RESPONSIVE_SIZES.overMd}) and (${RESPONSIVE_SIZES.lg}) {
    ${calcGridItemSize(lg, props.lgHSpace || hSpace)}
  }
  @media (${RESPONSIVE_SIZES.overLg}) and (${RESPONSIVE_SIZES.xl}) {
    ${calcGridItemSize(xl, props.xlHSpace || hSpace)}
  }
  @media (${RESPONSIVE_SIZES.overXl}) {
    ${calcGridItemSize(xxl, props.xxlHSpace || hSpace)}
  }
  position: relative;
  `;
}
