import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";
import { toast } from "react-toastify";
import { ReactComponent as LinkIcon } from "pages/topup/assets/link.svg";
import { RESPONSIVE_SIZES } from "utils/tools";
import { PayUrls } from "pages/topup/BinancePayTopUp";
import { Typography } from "UI/Typography";

export const BinancePayModalContent = ({ payUrls }: { payUrls: PayUrls }) => {
  const { t } = useTranslation();

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(t("Скопировано в буфер обмена"));
  };

  return (
    <StyledWrapper>
      <StyledTypography variant="body">
        {t("Перейдите по ссылке для пополнения кошелька")}
      </StyledTypography>
      <StyledFieldWrapper>
        <LinkIcon
          onClick={() => handleCopy(payUrls?.link_universal_url || "")}
        />
        <StyledLink target="_blank" href={payUrls?.link_universal_url}>
          {payUrls?.link_universal_url}
        </StyledLink>
      </StyledFieldWrapper>
      <StyledTypography variant="body">
        {t("Или отсканируйте QR-code")}
      </StyledTypography>
      <StyledFieldWrapper style={{ height: 230 }}>
        <StyledQrCodeWrapper>
          <StyledQrBox>
            <QRCodeSVG value={payUrls?.qr_code_content || ""} size={170} />
          </StyledQrBox>
        </StyledQrCodeWrapper>
      </StyledFieldWrapper>
    </StyledWrapper>
  );
};

const StyledTypography = styled(Typography)`
  margin-bottom: 20px;
  @media (${RESPONSIVE_SIZES.md}) {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 12px;
  }
`;

const StyledWrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

const StyledLink = styled.a`
  display: block;
  width: 100%;
  padding: 5px;
  border: none;
  background: transparent;
  max-width: 100%;
  overflow: hidden;
  text-decoration: none;
  font-size: 14px;
  color: #19202e;
  word-wrap: break-word;
  &:focus {
    outline: none;
  }
`;

const StyledFieldWrapper = styled.div`
  background: #eaeff7;
  padding: 15px;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledQrBox = styled.div`
  background: #ffffff;
  padding: 25px;
`;

const StyledQrCodeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
