import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useGetTransactionRefundsQuery } from "api/baseAPI/transactions";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { Typography } from "UI/Typography";
import { dateFormatter } from "utils/date";
import { getColors } from "utils/getColors";
import { ReactComponent as CircleCheckIcon } from "utils/img/circle-check.svg";
import { ReactComponent as CircleCloseIcon } from "utils/img/circle-close.svg";
import { ReactComponent as CircleWarningIcon } from "utils/img/circle-warning.svg";
import { visibleNumber } from "utils/numbers";

type Props = {
  id: number;
};

const STATUS_NAME: Record<string, string> = {
  SUCCESSFUL: "Успешний",
  FAILED: "Ошибка",
  IN_PROCESS: "В обработке",
};

const STATUS_COLORS: Record<string, string> = {
  SUCCESSFUL: "green",
  FAILED: "red",
  IN_PROCESS: "orange",
};

const STATUS_ICONS: Record<string, React.ReactElement> = {
  SUCCESSFUL: <CircleCheckIcon />,
  FAILED: <CircleCloseIcon />,
  IN_PROCESS: <CircleWarningIcon />,
};

export const TransactionRefunds = ({ id }: Props): React.ReactElement => {
  const { t } = useTranslation();

  const tableCellTitles = [
    t("ID"),
    t("Дата создания"),
    t("Сумма"),
    t("Статус"),
  ];

  const [page, setPage] = useState("1");
  const [pageSize, setPageSize] = useState("10");

  const { pageBackground } = getColors();

  const { data, isFetching } = useGetTransactionRefundsQuery({
    page,
    page_size: pageSize,
    transaction: id,
  });

  const handleSetPage = (pageVal: number) => {
    setPage(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    setPage("1");
    setPageSize(String(rows));
  };

  return (
    <Table
      tableCellTitles={tableCellTitles}
      isLoading={isFetching}
      count={data ? data.count : 0}
      page={Number(page)}
      rowsPerPage={3}
      setPage={handleSetPage}
      setRowsPerPage={handleSetRowsPerPage}
      disabledPagination
      maxHeight={200}
    >
      {data?.results.map((row) => (
        <StyledRow key={row.id} background={pageBackground}>
          <StyledCell>{row.id}</StyledCell>
          <StyledCell>{dateFormatter.format(row.created_at)}</StyledCell>
          <StyledCell>{visibleNumber(row.amount_to_refund, 4)}</StyledCell>
          <StyledCell>
            {row.status ? (
              <StyledWrapper>
                <IconColor color={STATUS_COLORS[row.status]}>
                  {STATUS_ICONS[row.status]}
                </IconColor>
                <Typography variant="subtitle2">
                  {STATUS_NAME[row.status]}
                </Typography>
              </StyledWrapper>
            ) : (
              ""
            )}
          </StyledCell>
        </StyledRow>
      ))}
    </Table>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconColor = styled.span`
  svg {
    width: 12px !important;
    height: 12px !important;
    margin-right: 10px;
    path {
      fill: ${(props: { color: string }) => props.color} !important;
    }
  }
`;
