import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

export const KIND_CHECKOUT = 1;
// export const KIND_SOC_INVOICE = 2;
// export const KIND_TIP = 3;
export type KIND = typeof KIND_CHECKOUT;

export type InvoicesQueryParams = {
  offset: string;
  limit: string;
  order_by: string;
  page: string;
  page_size: string;
  search: string;
  uuid: string;
  title__ilike: string;
  wallet_id: string;
  point_id: string;
};

export type CreateInvoice = {
  title: string;
  kind: KIND;
  active_till: string | null;
  locale: string;

  account_id: string | null;
  point_id: string | null;
  wallet_id: string | null;
  service_mapping: Record<string, number>;
  external_id: string;

  is_reusable: boolean;
  fixed_amount: string | null;
  amount_currency: string | null;
  min_amount: string | null;
  default_amount: string | null;
  preferred_amounts: string[];
  preferred_amounts_logic: 1 | 2;

  additional_fields: string[];
  prefilled_fields: Record<string, string> | null;
  description_template: string;

  success_amount?: number;
  fail_amount?: number;
  fail_count?: number;
  success_count?: number;
};

export type Invoice = {
  account_id: number;
  active_till: string;
  additional_fields: string[];
  amount_currency: string;
  created_at: string;
  default_amount: string;
  description_template: string;
  external_id: string;
  external_customer_id: string;
  fail_amount: string;
  fail_count: string;
  fixed_amount: string;
  is_reusable: boolean;
  kind: KIND;
  locale: string;
  min_amount: string;
  pay_url: string;
  point: {
    callback_url: string;
    cancel_url: string;
    fail_url: string;
    return_url: string;
    success_url: string;
  };
  point_id: number;
  preferred_amounts: string[];
  preferred_amounts_logic: 1 | 2;
  prefilled_fields: Record<string, string>;
  service_mapping: Record<string, number>;
  success_amount: string;
  success_count: string;
  title: string;
  updated_at: string;
  uuid: string;
  wallet_id: number;
};

type InvoicesRequest = {
  pagination: { total: number };
  results: Invoice[];
};

const checkoutInvoiceInvoicesApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getInvoices: builder.query<InvoicesRequest, InvoicesQueryParams>({
      query: (params) => {
        if (params.uuid) return `/checkout/invoices/invoice/${params.uuid}/`;
        const query = getQuery(params);
        return `/checkout/invoices/invoice/list/?${query.toString()}`;
      },
      transformResponse: (response: InvoicesRequest | Invoice) => {
        if (response && "pagination" in response) return response;
        return {
          pagination: { total: response?.uuid ? 1 : 0 },
          results: response?.uuid ? [response] : [],
        };
      },
      providesTags: ["CheckoutInvoices"],
    }),
    getInvoice: builder.query<Invoice, { uuid: string }>({
      query: ({ uuid }) => `/checkout/invoices/invoice/${uuid}/`,
      providesTags: ["CheckoutInvoice"],
    }),
    postInvoice: builder.mutation<Invoice, CreateInvoice>({
      query: (data) => ({
        url: "/checkout/invoices/invoice/create/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CheckoutInvoices", "CheckoutInvoice"],
    }),
    patchInvoice: builder.mutation<
      Invoice,
      Partial<CreateInvoice> & { uuid: string }
    >({
      query: ({ uuid, ...data }) => ({
        url: `/checkout/invoices/invoice/${uuid}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["CheckoutInvoices", "CheckoutInvoice"],
    }),
    deleteInvoice: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/checkout/invoices/invoice/${uuid}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["CheckoutInvoices", "CheckoutInvoice"],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  usePostInvoiceMutation,
  usePatchInvoiceMutation,
  useDeleteInvoiceMutation,
} = checkoutInvoiceInvoicesApi;
