export type Margin = {
  m?: number;
  mx?: number;
  my?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
};

export function marginProps(props: Margin) {
  return (({ m, my, mx, mt, mr, mb, ml }) => ({
    m,
    my,
    mx,
    mt,
    mr,
    mb,
    ml,
  }))(props);
}

export function marginStyle(props: Margin) {
  return `
    ${props.m ? `margin:${  props.m  }px;` : ""}
    ${
      props.mx
        ? `margin-left:${  props.mx  }px; margin-right:${  props.mx  }px;`
        : ""
    }
    ${
      props.my
        ? `margin-top:${  props.my  }px; margin-bottom:${  props.my  }px;`
        : ""
    }
    ${props.mt ? `margin-top:${  props.mt  }px;` : ""}
    ${props.mr ? `margin-right:${  props.mr  }px;` : ""}
    ${props.mb ? `margin-bottom:${  props.mb  }px;` : ""}
    ${props.ml ? `margin-left:${  props.ml  }px;` : ""}
  `;
}
