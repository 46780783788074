import { find, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { TextInput } from "UI/Form/TextInput";
import { Typography } from "UI/Typography";
import {
  PointManagerItem,
  useLazyGetPointManagerByUserQuery,
} from "api/baseAPI/pointManagers";
import {
  useSelectedPointManager,
  useSelectedWalletManager,
} from "hooks/useSelectedEntity";
import {
  setSelectedPointManager,
  setSelectedWallet,
} from "reducers/basicSlice";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { ReactComponent as CloseIcon } from "utils/img/close.svg";
import { ReactComponent as SearchIcon } from "utils/img/search.svg";
import { RESPONSIVE_SIZES } from "utils/tools";

export const PointManager = ({
  isMenuCollapsed,
  isMenuFooter,
  userId,
}: {
  isMenuCollapsed: boolean;
  isMenuFooter?: boolean;
  userId: number;
}): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenPointMenu, setIsOpenPointMenu] = useState(false);
  const [search, setSearch] = useState("");

  const { pageBackground } = getColors();

  const [getPointManagers, { data, isSuccess }] =
    useLazyGetPointManagerByUserQuery();

  const selectedPointManager = useSelectedPointManager();
  const selectedWallet = useSelectedWalletManager();

  useEffect(() => {
    if (data?.results?.length && isSuccess) {
      if (selectedPointManager?.id) {
        const pointManager = find(data?.results, {
          id: selectedPointManager?.id,
        });
        if (!isEmpty(pointManager)) {
          dispatch(setSelectedPointManager({ ...pointManager }));
          if (pointManager?.available_wallets?.[0]?.id && !selectedWallet?.id)
            dispatch(
              setSelectedWallet({
                ...(pointManager?.main_wallet ||
                  pointManager?.available_wallets?.[0]),
              })
            );
        }
      } else {
        dispatch(setSelectedPointManager({ ...data?.results?.[0] }));
        if (data?.results?.[0]?.available_wallets?.[0]?.id)
          dispatch(
            setSelectedWallet({
              ...(data?.results?.[0]?.main_wallet ||
                data?.results?.[0]?.available_wallets?.[0]),
            })
          );
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (userId) getPointManagers({ user: userId });
  }, [userId]);

  const handleSelectPoint = (item: PointManagerItem) => {
    dispatch(setSelectedPointManager({ ...item }));
    if (item.available_wallets?.[0]?.id)
      dispatch(
        setSelectedWallet({
          ...(item?.main_wallet || item.available_wallets?.[0]),
        })
      );
    setIsOpenPointMenu(!isOpenPointMenu);
  };

  const getElements = useMemo(() => {
    if (data?.results && data?.results?.length > 0 && search) {
      return data?.results.filter((item) =>
        `${item.point?.id || ""} | ${
          item.point?.name?.toLowerCase() || ""
        }`.includes(search.toLowerCase())
      );
    }
    if (data?.results && data?.results?.length > 0) {
      return data?.results;
    }
    return [];
  }, [data?.results, search]);

  return (
    <>
      <StyledWrapperPoint
        open={isOpenPointMenu}
        isMenuFooter={!!isMenuFooter}
        onClick={() => setIsOpenPointMenu(!isOpenPointMenu)}
      >
        <div>
          {t("Точка")}
          {!isMenuCollapsed && (
            <StyledTitlePoint>
              {selectedPointManager?.point?.id
                ? `${selectedPointManager?.point?.id} | ${selectedPointManager?.point?.name}`
                : ""}
            </StyledTitlePoint>
          )}
        </div>
        {!isMenuCollapsed && <StyledArrow open={isOpenPointMenu} />}
        {isMenuFooter && <StyledArrowFooter open={isOpenPointMenu} />}
      </StyledWrapperPoint>
      {isOpenPointMenu && (
        <StyledModalRoot>
          <StyledModalOverlay
            onClick={() => setIsOpenPointMenu(!isOpenPointMenu)}
          />
          <StyledWrapperModal>
            <StyledWrapperTitle>
              <Typography variant="h6">{t("Выберите точку")}</Typography>
              <StyledClose
                onClick={() => setIsOpenPointMenu(!isOpenPointMenu)}
              />
            </StyledWrapperTitle>
            <TextInput
              placeholder={t("Поиск")}
              size="small"
              iconStart={<StyledSearchIcon />}
              iconEnd={<StyledCloseIcon />}
              value={search}
              onChange={(val) => setSearch(val)}
            />
            {getElements && (
              <StyledList>
                {getElements.map((item, i) => (
                  <StyledItem
                    key={i}
                    background={pageBackground}
                    onClick={() => handleSelectPoint(item)}
                  >
                    <Typography variant="subtitle3">{`${item.point?.id} | ${item.point?.name}`}</Typography>
                  </StyledItem>
                ))}
              </StyledList>
            )}
          </StyledWrapperModal>
        </StyledModalRoot>
      )}
    </>
  );
}

// Style
const StyledClose = styled(CloseIcon)`
  display: none;
  @media (${RESPONSIVE_SIZES.lg}) {
    display: block;
  }
`;
const StyledWrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;
const StyledSearchIcon = styled(SearchIcon)`
  cursor: pointer;
`;
const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
const StyledList = styled.div`
  margin-top: 16px;
`;
const StyledItem = styled.div`
  padding: 8px 12px;
  margin-bottom: 4px;
  cursor: pointer;
  background: ${(props: { background: string }) => props.background};
`;
const StyledWrapperModal = styled.div`
  position: absolute;
  padding: 24px;
  width: 300px;
  height: calc(100vh - 48px);
  color: #000000;
  left: 256px;
  background-color: white;
  z-index: 100;
  @media (${RESPONSIVE_SIZES.sm}) {
    position: fixed;
    overflow: auto;
    width: calc(100% - 48px);
    height: calc(100vh - 108px);
    z-index: 100;
    left: 0;
  }
`;
const StyledModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 90;
  @media (${RESPONSIVE_SIZES.sm}) {
    background-color: transparent;
  }
`;
const StyledModalRoot = styled.div`
  overflow-x: hidden;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  overflow-y: scroll;
  display: flex;
`;
const StyledTitlePoint = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 4px;
`;
const StyledWrapperPoint = styled.div`
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 16px 36px;
  color: ${(props: { open: boolean; isMenuFooter: boolean }) =>
    props.open ? "#000000" : "#ffffff"};
  background: ${(props) => (props.open ? "#ffffff" : "#000000")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 18px;
  height: 70px;
  @media (${RESPONSIVE_SIZES.sm}) {
    ${(props) => (props.isMenuFooter ? "height: 64px;" : "display: none;")};
  }
`;
const StyledArrow = styled(ArrowUpDown)`
  padding-top: 8px;
  transform: rotate(270deg);
  path {
    fill: #ffffff !important;
  }
  ${(props: { open: boolean }) =>
    props.open
      ? `
          padding-bottom: 8px;
          padding-top: 0;
          transform: rotate(90deg);
          path {
            fill: #000000 !important;
          }
        `
      : ""};
`;
const StyledArrowFooter = styled(ArrowUpDown)`
  padding-left: 8px;
  path {
    fill: #ffffff !important;
  }
  ${(props: { open: boolean }) =>
    props.open
      ? `
          padding-right: 8px;
          padding-left: 0;
          transform: rotate(180deg);
          path {
            fill: #000000 !important;
          }
        `
      : ""};
`;
