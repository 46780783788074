import styled from "styled-components";
import { getColors } from "utils/getColors";
import checkedIcon from "utils/img/checked.svg";
import { marginProps, Margin, marginStyle } from "UI/utility/maring";
import { Padding, paddingProps, paddingStyle } from "UI/utility/padding";

type CheckboxProps = {
  className?: string;
  label?: string;
  name?: string;
  value?: boolean;
  onChange?: (val: boolean) => void;
  disabled?: boolean;
} & Margin &
  Padding;

export const Checkbox = ({
  className,
  label,
  name,
  value,
  onChange,
  disabled,
  ...otherProps
}: CheckboxProps) => {
  const { accentColor } = getColors();

  return (
    <StyledCheckboxLabel
      className={className}
      {...paddingProps(otherProps)}
      {...marginProps(otherProps)}
    >
      <StyledCheckboxInput
        checked={Boolean(value)}
        name={name}
        onChange={(e) => onChange && onChange(e.target.checked)}
        type="checkbox"
        disabled={!!disabled}
      />
      <StyledCustomCheckbox
        background={accentColor}
        checked={Boolean(value) ?? false}
      />
      {label}
    </StyledCheckboxLabel>
  );
};

const StyledCheckboxLabel = styled.label<Margin & Padding>`
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #393939;
  cursor: pointer;

  ${(props) => [marginStyle(props), paddingStyle(props)].join("")}
`;

const StyledCheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;
const StyledCustomCheckbox = styled.span`
  width: 15px;
  height: 15px;
  min-width: 15px;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  border: 1px solid ${(props) => props.background};
  background-color: ${(props) =>
    props.checked ? props.background : "transparent"};

  &::before {
    display: ${(props: { checked: boolean; background: string }) =>
      props.checked ? "block" : "none"};
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    background: url(${checkedIcon});
    width: 10px;
    height: 8px;
  }
`;
