import { ReactElement } from "react";
import * as yup from "yup";
import { ReactComponent as ExmoIcon } from "utils/img/exmo.svg";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { EmailPayInput } from "pages/payout/logic/forms/components/inputs/EmailPayInput";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class ExmoPayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "exmo";

  kind: 5 = 5;

  name: string = "Exmo";

  mobileName: string = this.name;

  icon: ReactElement = (<ExmoIcon />);

  csvExample: string | null = "/file-samples/sample-exmo.csv";

  xlsxExample: string | null = "/file-samples/sample-exmo.xlsx";

  get serviceId() {
    return (
      (this.pointManager?.service_config?.exmo_create_code &&
        this.getService(this.pointManager?.service_config?.exmo_create_code)) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <EmailPayInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .email("Неправильный формат email")
    .required("Введите email");
}
