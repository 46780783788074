import React from "react";
import styled from "styled-components";
import { Margin, marginProps, marginStyle } from "./utility/maring";
import { Padding, paddingProps, paddingStyle } from "./utility/padding";

type Color = "green";

type AlertProps = { color: Color; children: React.ReactNode } & Margin &
  Padding;

export const Alert = ({ color, children, ...otherProps }: AlertProps) => (
  <StyledAlertWrapper
    color={color}
    {...marginProps(otherProps)}
    {...paddingProps(otherProps)}
  >
    {children}
  </StyledAlertWrapper>
);

const StyledAlertWrapper = styled.div<{ color: Color } & Margin & Padding>`
  ${(props) => [marginStyle(props), paddingStyle(props)].join("")}
  background-color: #ddeee1;
  padding: 10px;
`;
