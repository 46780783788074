import { useFormik } from "formik";
import { isEmpty, map } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { MultiSelect } from "UI/Form/MultiSelect";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import {
  useCreatePointManagerMutation,
  useGetMerchantGroupsQuery,
  useLazyGetPointManagerQuery,
  useUpdatePointManagerMutation,
} from "api/baseAPI/pointManagers";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";

type Props = {
  editId: number;
  onClose: () => void;
};

export const PointManagerForm = (props: Props): React.ReactElement => {
  const { onClose, editId } = props;
  const { t } = useTranslation();

  const selectedPointManager = useSelectedPointManager();
  const getMerchantGroups = useGetMerchantGroupsQuery();
  const [getPointManager, getPointManagerMeta] = useLazyGetPointManagerQuery();
  const [createPointManager, { isSuccess, isError, isLoading }] =
    useCreatePointManagerMutation();
  const [
    updatePointManager,
    {
      isSuccess: isSuccessUpload,
      isError: isErrorUpload,
      isLoading: isLoadingUpload,
    },
  ] = useUpdatePointManagerMutation();

  useEffect(() => {
    if (editId) {
      getPointManager({ id: editId });
    }
  }, [editId]);

  useEffect(() => {
    if (isSuccess || isSuccessUpload) {
      onClose();
    }
  }, [isSuccess, isSuccessUpload]);

  const form = useFormik({
    initialValues: {
      email: "",
      access_level: "",
      user: "",
      available_components: [],
      available_wallets: [],
    },
    onSubmit: (values) => {
      if (editId) {
        updatePointManager({
          id: editId,
          available_components: map(values.available_components, (item) =>
            Number(item)
          ),
          available_wallets: map(values.available_wallets, (item) =>
            Number(item)
          ),
          user: values.user,
          access_level: Number(values.access_level),
          point_id: selectedPointManager.point.id,
        });
        return;
      }
      createPointManager({
        available_components: map(values.available_components, (item) =>
          Number(item)
        ),
        available_wallets: map(values.available_wallets, (item) =>
          Number(item)
        ),
        email: values.email,
        access_level: Number(values.access_level),
        point_id: selectedPointManager.point.id,
      });
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t("Неправильный формат"))
        .required(t("Обязательное поле")),
      access_level: Yup.string().required(t("Обязательное поле")),
      available_wallets: Yup.array().min(1, t("Обязательное поле")),
    }),
  });

  useTranslateFormErrors(form);

  useEffect(() => {
    if (getPointManagerMeta.isSuccess && !isEmpty(getPointManagerMeta.data)) {
      form.setFieldValue(
        "access_level",
        getPointManagerMeta.data?.access_level?.id
          ? String(getPointManagerMeta.data?.access_level?.id)
          : ""
      );
      form.setFieldValue(
        "user",
        getPointManagerMeta.data?.user?.id
          ? String(getPointManagerMeta.data?.user?.id)
          : ""
      );
      form.setFieldValue(
        "email",
        getPointManagerMeta.data?.user?.username
          ? String(getPointManagerMeta.data?.user?.username)
          : ""
      );
      form.setFieldValue(
        "available_components",
        !isEmpty(getPointManagerMeta.data?.available_components)
          ? map(getPointManagerMeta.data?.available_components, (item) =>
              String(item.id)
            )
          : []
      );
      form.setFieldValue(
        "available_wallets",
        !isEmpty(getPointManagerMeta.data?.available_wallets)
          ? map(getPointManagerMeta.data?.available_wallets, (item) =>
              String(item.id)
            )
          : []
      );
    }
  }, [getPointManagerMeta]);

  useErrorNotification([{ isError }]);
  useErrorNotification([{ isError: isErrorUpload }]);
  useSuccessNotification(
    [{ isSuccess }, { isSuccess: isSuccessUpload }],

    t("Доступ успешно выдан!")
  );

  return (
    <Modal
      open
      hideHeader
      width={410}
      onClose={() => !isLoading && onClose()}
      disabledOverlay
    >
      <Box p={24}>
        <Typography variant="h5" mb={24}>
          {t("Выдача доступа")}
        </Typography>
        <TextInput
          size="small"
          label={t("Почта")}
          placeholder={t("Введите почту")}
          onChange={(value) => form.setFieldValue("email", value)}
          disabled={!!editId}
          value={form.values.email}
          error={form.touched.email && Boolean(form.errors.email)}
          helperText={form.touched.email && form.errors.email}
        />
        <Box mt={24}>
          <Select
            value={form.values.access_level}
            onChange={(value) => form.setFieldValue("access_level", value)}
            label={t("Уровень доступа")}
            placeholder={t("Выберите уровень доступа")}
            size="small"
            error={
              form.touched.access_level && Boolean(form.errors.access_level)
            }
            helperText={form.touched.access_level && form.errors.access_level}
            options={
              !isEmpty(getMerchantGroups?.data?.results)
                ? getMerchantGroups?.data?.results?.map((item) => ({
                    value: `${item.id}`,
                    label: item.name,
                  })) || []
                : []
            }
          />
        </Box>
        <Box mt={24}>
          <MultiSelect
            value={form.values.available_components}
            onChange={(value) =>
              form.setFieldValue("available_components", value)
            }
            label={t("Доступные пункты меню")}
            placeholder={t("Выберите пункты меню")}
            size="small"
            error={
              form.touched.available_components &&
              Boolean(form.errors.available_components)
            }
            helperText={
              form.touched.available_components &&
              form.errors.available_components
                ? String(form.errors.available_components)
                : ""
            }
            options={
              selectedPointManager?.available_components?.length > 0
                ? selectedPointManager?.available_components?.map(
                    (item: { id: number; display_name: string }) => ({
                      value: `${item.id}`,
                      label: `${item.id} | ${item.display_name}`,
                    })
                  )
                : []
            }
          />
        </Box>
        <Box mt={24}>
          <MultiSelect
            value={form.values.available_wallets}
            onChange={(value) => form.setFieldValue("available_wallets", value)}
            label={t("Доступные кошельки")}
            placeholder={t("Выберите доступные кошельки")}
            size="small"
            error={
              form.touched.available_wallets &&
              Boolean(form.errors.available_wallets)
            }
            helperText={
              form.touched.available_wallets && form.errors.available_wallets
                ? String(form.errors.available_wallets)
                : ""
            }
            options={
              selectedPointManager?.available_wallets?.length > 0
                ? selectedPointManager?.available_wallets?.map(
                    (item: { id: number; name: string }) => ({
                      value: `${item.id}`,
                      label: `${item.id} | ${item.name}`,
                    })
                  )
                : []
            }
          />
        </Box>
        <Box flex alignItems="center" justifyContent="space-between" mt={24}>
          <Button
            mr={16}
            size="small"
            disabled={isLoading || isLoadingUpload}
            variant="outlined"
            onClick={() => onClose()}
          >
            {t("Отмена")}
          </Button>
          <Box style={{ maxWidth: "55%", width: "100%" }}>
            <Button
              size="small"
              fullwidth
              disabled={isLoading || isLoadingUpload}
              onClick={() => form.handleSubmit()}
            >
              {t("Выдать доступ")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
