import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import tEN from "./en.json";
import tUK from "./uk.json";

export const defaultNS = "translation";

export const resources = {
  en: { translation: tEN },
  uk: { translation: tUK },
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  defaultNS,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
