import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

export type PayoutTemplate = {
  created_at: string;
  title: string;
  element_count: number;
  element_amount: number;
  kind: 1 | 2 | 3;
  id: number;
};
export type PayoutTemplatesQueryParams = {
  page: string;
  page_size: string;
  search: string;
  kind?: string;
};

type CreatePayoutTemplate = {
  point_manager_id: number;
  title: string;
  kind: number;
  elements: PayoutTemplateElement[];
};

export type PayoutTemplateElement = {
  customer_account: string;
  amount_currency: string;
  amount: string;
  description: string;
  template: number;
  id?: number;
  fields?: any;
};
export type CreatePayoutTemplateElement = {
  customer_account: string;
  amount_currency: string;
  amount: string;
  description: string;
  template: number;
  fields?: Record<string, string>;
};

export const payoutTemplatesApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPayoutTemplates: builder.query<
      { count: number; results: PayoutTemplate[] },
      PayoutTemplatesQueryParams
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/payouts/payout-templates/?${query.toString()}`;
      },
      providesTags: ["PayoutTemplates"],
    }),
    getPayoutTemplate: builder.query<PayoutTemplate, { id: number }>({
      query: ({ id }) => `/payouts/payout-templates/${id}/`,
      providesTags: ["PayoutTemplate"],
    }),
    createPayoutTemplate: builder.mutation<
      PayoutTemplate,
      CreatePayoutTemplate
    >({
      query: (data) => ({
        url: "/payouts/payout-templates/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PayoutTemplates", "PayoutTemplate"],
    }),
    updatePayoutTemplate: builder.mutation<
      PayoutTemplate,
      Partial<CreatePayoutTemplate> & { id: number }
    >({
      query: ({ id, ...data }) => ({
        url: `/payouts/payout-templates/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["PayoutTemplates", "PayoutTemplate"],
    }),
    deletePayoutTemplate: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/payouts/payout-templates/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayoutTemplates", "PayoutTemplate"],
    }),

    getPayoutTemplateElements: builder.query<
      { count: number; results: PayoutTemplateElement[] },
      { page_size: number; template: number }
    >({
      query: (params) => {
        const query = getQuery(params);
        return `/payouts/payout-template-elements/?${query.toString()}`;
      },
      providesTags: ["PayoutTemplateElements"],
    }),
    createPayoutTemplateElement: builder.mutation<
      PayoutTemplateElement,
      CreatePayoutTemplateElement
    >({
      query: (data) => ({
        url: "/payouts/payout-template-elements/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PayoutTemplateElements"],
    }),
    updatePayoutTemplateElement: builder.mutation<
      PayoutTemplateElement,
      Partial<CreatePayoutTemplateElement> & { id: number }
    >({
      query: ({ id, ...data }) => ({
        url: `/payouts/payout-template-elements/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["PayoutTemplateElements"],
    }),
    deletePayoutTemplateElement: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/payouts/payout-template-elements/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayoutTemplateElements"],
    }),
  }),
});

export const {
  useGetPayoutTemplatesQuery,
  useLazyGetPayoutTemplatesQuery,
  useLazyGetPayoutTemplateQuery,
  useCreatePayoutTemplateMutation,
  useUpdatePayoutTemplateMutation,
  useDeletePayoutTemplateMutation,

  useLazyGetPayoutTemplateElementsQuery,
  useCreatePayoutTemplateElementMutation,
  useUpdatePayoutTemplateElementMutation,
  useDeletePayoutTemplateElementMutation,
} = payoutTemplatesApi;
