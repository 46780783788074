import { useTranslation } from "react-i18next";
import { ProductItem } from "api/baseAPI/socInvoice";
import {
  StyledCell,
  StyledRow,
  Table,
} from "components/table/Table";
import { getColors } from "utils/getColors";

export const ProductsTable = ({
  products,
  isForm,
}: {
  products: ProductItem[];
  isForm?: boolean;
}) => {
  const { pageBackground } = getColors();
  const { t } = useTranslation();

  return (
    <Table
      isLoading={false}
      tableCellTitles={[
        t("Название товара"),
        t("Количество"),
        t("Сумма"),
        t("Валюта"),
        t("Описание"),
      ]}
      count={products?.length || 0}
      page={1}
      rowsPerPage={10}
      disabledPagination
      hideActionColumn
      setPage={() => {}}
      setRowsPerPage={() => {}}
    >
      {products.map((row) => (
        <StyledRow
          key={row.id}
          style={{ background: !isForm ? pageBackground : "" }}
        >
          <StyledCell>
            {row.name?.length > 20
              ? `${row.name.substring(0, 20)}...`
              : row.name}
          </StyledCell>
          <StyledCell>{row.quantity}</StyledCell>
          <StyledCell>{row.cost}</StyledCell>
          <StyledCell>{row.currency}</StyledCell>
          <StyledCell>
            {row.description?.length > 20
              ? `${row.description.substring(0, 20)}...`
              : row.description}
          </StyledCell>
        </StyledRow>
      ))}
    </Table>
  );
}
