import styled from "styled-components";

import React from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Typography } from "UI/Typography";
import { TRANSACTION_STATUSES } from "api/baseAPI/transactions";
import { getColors } from "utils/getColors";
import { ReactComponent as CircleCheckIcon } from "utils/img/circle-check.svg";
import { ReactComponent as CircleCloseIcon } from "utils/img/circle-close.svg";
import { ReactComponent as CircleWarningIcon } from "utils/img/circle-warning.svg";
import { ReactComponent as CircleInfoIcon } from "utils/img/info-circle.svg";

const STATUS_BADGES: Record<string, string> = {
  "-1": "info",
  0: "primary",
  1: "success",
  2: "danger",
  3: "danger",
  4: "warning",
  5: "warning",
  6: "info",
  10: "warning",
  30: "warning",
  40: "warning",
  50: "warning",
  100: "danger",
  200: "danger",
};

const STATUS_COLORS: Record<string, string> = {
  primary: "blue",
  success: "green",
  danger: "red",
  warning: "orange",
  info: "cyan",
};

const STATUS_ICONS: Record<string, React.ReactElement> = {
  primary: <CircleInfoIcon />,
  success: <CircleCheckIcon />,
  danger: <CircleCloseIcon />,
  warning: <CircleWarningIcon />,
  info: <CircleInfoIcon />,
};

interface Props {
  status: keyof typeof TRANSACTION_STATUSES;
  failure_reason_description: string;
  id: number;
}

export const useAllTransactionStatuses = (): Record<
  keyof typeof TRANSACTION_STATUSES,
  string
> => {
  const { t } = useTranslation();
  return {
    "-1": t("Зарезервированный ID"),
    0: t("Новая"),
    1: t("Успешная"),
    2: t("Неуcпешная"),
    3: t("Отменена"),
    4: t("Реверс"),
    5: t("Истёк срок"),
    6: t("Авторизация успешна"),
    10: t("В обработке"),
    30: t("В процессе отмены"),
    40: t("В процессе реверса"),
    50: t("Перепроведён"),
    100: t("Внутренняя ошибка"),
    200: t("Создание неуспешно"),
  };
};

export const TransactionStatus = ({
  status,
  failure_reason_description,
  id,
}: Props) => {
  const { accentColor } = getColors();
  const ALL_TRANSACTION_STATUSES = useAllTransactionStatuses();
  return (
    <>
      <StyledWrapper data-tip data-for={String(id)}>
        <IconColor color={STATUS_COLORS[STATUS_BADGES[status]]}>
          {STATUS_ICONS[STATUS_BADGES[status]]}
        </IconColor>
        <Typography variant="subtitle2">
          {ALL_TRANSACTION_STATUSES[status]}
        </Typography>
      </StyledWrapper>
      {status  ===  2 && failure_reason_description ? (
        <ReactTooltip
          id={String(id)}
          border
          place="top"
          multiline
          type="light"
          effect="solid"
          borderColor={accentColor}
        >
          <span>{failure_reason_description}</span>
        </ReactTooltip>
      ) : (
        <></>
      )}
    </>
  );
}

const StyledWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconColor = styled.span`
  svg {
    width: 12px !important;
    height: 12px !important;
    margin-right: 10px;
    path {
      fill: ${(props: { color: string }) => props.color} !important;
    }
  }
`;
