import { includes, reduce } from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  AccountWalletTransactionsQueryParams,
  useGetAccountWalletTransactionsQuery,
} from "api/baseAPI/accountWalletTransactions";
import { useCreateReportMutation } from "api/baseAPI/reports";
import { useGetUsersMeRetrieverPermissionsQuery } from "api/baseAPI/user";
import { useLazyGetWalletQuery } from "api/baseAPI/wallets";
import { LinearProgress } from "components/LinearProgress";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { useSelectedWalletManager } from "hooks/useSelectedEntity";
import { dateFormatter } from "utils/date";
import { getTimezone } from "utils/getTimezone";
import { ReactComponent as Download } from "utils/img/download.svg";
import { visibleNumber } from "utils/numbers";
import { useUrlQuery } from "utils/url";
import { getCurrencyIcon } from "utils/useStyle";
import { AccountWalletTransactionsFiltersRow } from "./parts/AccountWalletTransactionsFiltersRow";
import { ModalInfo } from "./parts/ModalInfo";

export const AccountWalletTransactions = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Отчетность"), ""],
    [t("История баланса"), ""],
  ];

  const tableCellTitles = [
    t("ID"),
    t("Дата"),
    t("Зачислено"),
    t("Списано"),
    t("Баланс"),
    t("Комментарий"),
  ];

  const [openModal, setOpenModal] = useState(false);

  const selectedWallet = useSelectedWalletManager();
  const timezone = getTimezone();

  const [getWallet, getWalletMeta] = useLazyGetWalletQuery();
  const [createReport, createReportMeta] = useCreateReportMutation();
  const getRetrieverPermissionsQuery = useGetUsersMeRetrieverPermissionsQuery();

  useEffect(() => {
    if (createReportMeta.data?.uuid) {
      setOpenModal(true);
    }
  }, [createReportMeta.data]);

  useEffect(() => {
    if (selectedWallet?.id) {
      getWallet({ id: selectedWallet?.id });
    }
  }, [selectedWallet]);

  const initParameters = {
    page: "1",
    page_size: "10",
    search: "",
    account_wallet: selectedWallet?.id ? String(selectedWallet?.id) : "",
    amount__gt: "",
    amount__lt: "",
    created_at__gte: DateTime.local()
      .setZone(timezone)
      .minus({ day: 7 })
      .startOf("day")
      .toISO(),
    created_at__lte: DateTime.local().setZone(timezone).toISO(),
  };

  const { queryParams, querySetters } =
    useUrlQuery<AccountWalletTransactionsQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const { data, isFetching, refetch } = useGetAccountWalletTransactionsQuery({
    ...queryParams,
    ...(queryParams.amount__gt === "1"
      ? {
          amount__gt: "",
          amount__lt: "0",
        }
      : {}),
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const handleCreateReport = (type: number) => {
    const filters = {
      ...queryParams,
      ...(queryParams.amount__gt === "1"
        ? {
            amount__gt: "",
            amount__lt: "0",
          }
        : {}),
      model: "AccountWalletTransaction",
    };
    const reduceFilters = reduce(
      filters,
      (acc, value, key) => ({
        ...acc,
        ...(value && key !== "page" && key !== "page_size"
          ? {
              [key]: value,
            }
          : {}),
      }),
      {}
    );
    createReport({
      account_id: getWalletMeta?.data?.account?.id || 0,
      export_type: type,
      filters: reduceFilters,
    });
  };

  return (
    <Layout title={t("История баланса")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        {createReportMeta.isLoading ? <LinearProgress /> : <></>}
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
              {...(includes(getRetrieverPermissionsQuery.data, "add_report")
                ? {
                    customButton: (
                      <Grid
                        item
                        flex
                        sm={12}
                        xl={4}
                        responsive={{
                          xl: { ml: 16, mt: 0 },
                          sm: { ml: 0, mt: 16 },
                        }}
                      >
                        <Box style={{ width: "calc(50% - 8px)" }}>
                          <Button
                            variant="outlined"
                            fullwidth
                            onClick={() => handleCreateReport(2)}
                            disabled={createReportMeta.isLoading}
                            iconStart={<Download />}
                          >
                            CSV
                          </Button>
                        </Box>
                        <Box style={{ width: "calc(50% - 8px)" }} ml={16}>
                          <Button
                            disabled={createReportMeta.isLoading}
                            onClick={() => handleCreateReport(1)}
                            fullwidth
                            variant="outlined"
                            iconStart={<Download />}
                          >
                            XLS
                          </Button>
                        </Box>
                      </Grid>
                    ),
                  }
                : {})}
            />
          }
          filtersRow={
            <AccountWalletTransactionsFiltersRow
              queryParams={queryParams}
              querySetters={querySetters}
            />
          }
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data &&
            data.results.map((row, i) => (
              <StyledRow key={i}>
                <StyledCell>{row.transaction.id}</StyledCell>
                <StyledCell>{dateFormatter.format(row.created_at)}</StyledCell>
                <StyledCell>
                  <Typography
                    variant="subtitle2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {row.amount > 0 ? (
                      <>
                        {getCurrencyIcon(row.transaction.amount_currency || "")}
                        {visibleNumber(row.amount, 4)}
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                </StyledCell>
                <StyledCell>
                  <Typography
                    variant="subtitle2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {row.amount < 0 ? (
                      <>
                        -
                        {getCurrencyIcon(
                          row.transaction.amount_currency || "",
                          "0"
                        )}
                        {visibleNumber(row.amount, 4).slice(1)}
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                </StyledCell>
                <StyledCell>
                  <Typography
                    variant="subtitle2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {row.balance_after ? (
                      row.balance_after < 0 ? (
                        <>
                          -
                          {getCurrencyIcon(
                            row.transaction.amount_currency || "",
                            "0"
                          )}
                          {visibleNumber(row.balance_after, 4).slice(1)}
                        </>
                      ) : (
                        <>
                          {getCurrencyIcon(
                            row.transaction.amount_currency || ""
                          )}
                          {visibleNumber(row.balance_after, 4)}
                        </>
                      )
                    ) : (
                      ""
                    )}
                  </Typography>
                </StyledCell>
                <StyledCell>{row.comment}</StyledCell>
              </StyledRow>
            ))}
        </Table>
        {openModal ? <ModalInfo onClose={() => setOpenModal(false)} /> : <></>}
      </Box>
    </Layout>
  );
};
