export type Hiden = {
  hidden?: boolean;
};

export function hiddenProps(props: Hiden) {
  return (({ hidden }) => ({
    hidden,
  }))(props);
}

export function hiddenStyle(props: Hiden) {
  return props.hidden ? "display: none;" : "";
}
