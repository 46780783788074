import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { FormikProps } from "formik";
import { find, includes, isEmpty, last, without } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Checkbox } from "UI/Form/Checkbox";
import { Select } from "UI/Form/Select";
import { Switch } from "UI/Form/Switch";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useLazyGetAvailablePaymentMethodsQuery } from "api/baseAPI/invoicing";
import { useGetPointServicesQuery } from "api/baseAPI/pointServices";
import { LinearProgress } from "components/LinearProgress";
import { DateTimePicker } from "components/RangeSelector/DateTimePicker";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useStyle } from "hooks/useStyle";
import i18n from "locale";
import { getColors } from "utils/getColors";
import { ReactComponent as CaretCircleUpIcon } from "utils/img/caret-circle-up.svg";
import { ReactComponent as CloseIcon } from "utils/img/close.svg";
import { hexToRGB } from "utils/mixColors";
import { handleChangeNumber, numberForInput } from "utils/numbers";
import { PagesFormValues } from "pages/invoicing/pages/PageCrete";
import { MultiSelect } from "UI/Form/MultiSelect";
import { InputTags } from "./InputTags";
import { countries } from "./countries";

const CARD = 1;
const APPLE = 2;
const GOOGLE = 3;
const BINANCE = 4;

export const FirstStep = ({
  form,
  isTemplate,
}: {
  form: FormikProps<PagesFormValues>;
  isEdit?: boolean;
  isTemplate?: boolean;
}) => {
  const { t } = useTranslation();
  const [editService, setEditService] = useState(false);
  const [openAdditional, setOpenAdditional] = useState(false);
  const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
  const [openMain, setOpenMain] = useState(true);
  const [openPurpose, setOpenPurpose] = useState(false);
  const [openAddressParams, setOpenAddressParams] = useState(false);
  const [addFullName, setAddFullName] = useState(false);
  const [addBirthDate, setAddBirthDate] = useState(false);
  const [addName, setAddName] = useState(false);
  const [addPhone, setAddPhone] = useState(false);
  const [addEmail, setAddEmail] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [addCountry, setAddCountry] = useState(false);
  const [addCity, setAddCity] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [addZipCode, setAddZipCode] = useState(false);
  const [reload, setReload] = useState(false);
  const style = useStyle();
  const { accentColor } = getColors();
  const ref = useRef();

  const selectedPointManager = useSelectedPointManager();

  const pointServices = useGetPointServicesQuery({
    page: "1",
    page_size: "100",
    search: "",
    point: selectedPointManager.point?.id,
  });

  const getServices = useMemo(() => {
    const { data } = pointServices;
    if (data && data?.results?.length) {
      const services = Array.from(
        new Set(
          data?.results?.map((item) => ({
            value: String(item.service?.id),
            label: `${item.service?.id} | ${item.service?.name}`,
          }))
        )
      );
      return services;
    }
    return [];
  }, [pointServices]);

  useEffect(() => {
    if (i18n.language) {
      setReload(true);
      setTimeout(() => setReload(false), 0);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (
      form.values.wallet &&
      !isEmpty(selectedPointManager?.available_wallets)
    ) {
      const findWallet = find(selectedPointManager?.available_wallets, {
        id: Number(form.values.wallet),
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // @ts-ignore
      form.setFieldValue("amount_currency", findWallet?.currency || "UAH");
    }
  }, [form.values.wallet, selectedPointManager?.available_wallets]);

  const [getAvailablePaymentMethods, getAvailablePaymentMethodsMeta] =
    useLazyGetAvailablePaymentMethodsQuery();

  useEffect(() => {
    if (form.values.service) {
      getAvailablePaymentMethods({ id: Number(form.values.service) });
    }
  }, [form.values.service]);

  useEffect(() => {
    if (getAvailablePaymentMethodsMeta.isSuccess && editService) {
      form.setFieldValue(
        "available_payment_methods",
        includes(form.values.available_payment_methods, BINANCE)
          ? [...getAvailablePaymentMethodsMeta.data, BINANCE]
          : [...getAvailablePaymentMethodsMeta.data]
      );
    }
  }, [getAvailablePaymentMethodsMeta, editService]);

  useEffect(() => {
    if (!isEmpty(form.touched) && !isEmpty(form.errors)) {
      setOpenMain(true);
    }
  }, [form.touched, form.errors]);

  useEffect(() => {
    if (addFullName) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "first_name",
        "last_name",
        "patronymic",
      ]);
      setAddFullName(false);
    }
  }, [form.values.additional_fields, addFullName]);

  useEffect(() => {
    if (addBirthDate) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "birth_date",
      ]);
      setAddBirthDate(false);
    }
  }, [form.values.additional_fields, addBirthDate]);

  useEffect(() => {
    if (addName) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "name",
      ]);
      setAddName(false);
    }
  }, [form.values.additional_fields, addName]);

  useEffect(() => {
    if (addPhone) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "phone",
      ]);
      setAddPhone(false);
    }
  }, [form.values.additional_fields, addPhone]);

  useEffect(() => {
    if (addEmail) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "email",
      ]);
      setAddEmail(false);
    }
  }, [form.values.additional_fields, addEmail]);

  useEffect(() => {
    if (addComment) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "comment",
      ]);
      setAddComment(false);
    }
  }, [form.values.additional_fields, addComment]);

  useEffect(() => {
    if (addCountry) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "country",
      ]);
      setAddCountry(false);
    }
  }, [form.values.additional_fields, addCountry]);

  useEffect(() => {
    if (addCity) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "city",
      ]);
      setAddCity(false);
    }
  }, [form.values.additional_fields, addCity]);

  useEffect(() => {
    if (addAddress) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "address",
      ]);
      setAddAddress(false);
    }
  }, [form.values.additional_fields, addAddress]);

  useEffect(() => {
    if (addZipCode) {
      form.setFieldValue("additional_fields", [
        ...form.values.additional_fields,
        "zip_code",
      ]);
      setAddZipCode(false);
    }
  }, [form.values.additional_fields, addZipCode]);

  const onAdd = useCallback(
    (e: any) => {
      if (!!e.detail.data?.value && e.detail.data?.value === t("ФИО")) {
        setAddFullName(true);
      }
      if (
        !!e.detail.data?.value &&
        e.detail.data?.value === t("Дата рождения")
      ) {
        setAddBirthDate(true);
      }
      if (
        !!e.detail.data?.value &&
        e.detail.data?.value === t("Имя плательщика")
      ) {
        setAddName(true);
      }
      if (
        !!e.detail.data?.value &&
        e.detail.data?.value === t("Номер телефона плательщика")
      ) {
        setAddPhone(true);
      }
      if (
        !!e.detail.data?.value &&
        e.detail.data?.value === t("Email плательщика")
      ) {
        setAddEmail(true);
      }
      if (!!e.detail.data?.value && e.detail.data?.value === t("Комментарий")) {
        setAddComment(true);
      }
      if (!!e.detail.data?.value && e.detail.data?.value === t("Страна")) {
        setAddCountry(true);
      }
      if (!!e.detail.data?.value && e.detail.data?.value === t("Город")) {
        setAddCity(true);
      }
      if (!!e.detail.data?.value && e.detail.data?.value === t("Адрес")) {
        setAddAddress(true);
      }
      if (
        !!e.detail.data?.value &&
        e.detail.data?.value === t("Почтовый индекс")
      ) {
        setAddZipCode(true);
      }
    },
    [form.values.additional_fields]
  );

  const onChange = useCallback((e: any) => {
    if (last(e.detail.value || "") !== "@")
      form.setFieldValue("description_template", e.detail.value);
  }, []);

  const getCountryOptions = useMemo(() => {
    if (i18n.language.toLocaleLowerCase() === "uk")
      return countries.map((el) => ({
        value: el.alpha2,
        label: el.name,
      }));
    if (i18n.language.toLocaleLowerCase() === "en")
      return countries.map((el) => ({
        value: el.alpha2,
        label: el.name_en,
      }));
    return countries.map((el) => ({
      value: el.alpha2,
      label: el.name_ru,
    }));
  }, [i18n.language]);

  return (
    <>
      {pointServices.isLoading && (
        <Box mb={10}>
          <LinearProgress />
        </Box>
      )}
      <Box
        flex
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={() => setOpenMain(!openMain)}
      >
        <Typography variant="h5" mr={16}>
          {t("Основные параметры")}
        </Typography>
        <StyledCaretCircleUpIcon open={openMain} />
      </Box>
      {openMain ? (
        <Grid container pt={24} hSpace={24} vSpace={24} smHSpace={1}>
          <Grid item sm={12} md={4}>
            <TextInput
              size="small"
              label={t("Название страницы")}
              placeholder={t("Введите название страницы")}
              onChange={(value) => form.setFieldValue("title", value)}
              value={form.values.title}
              error={form.touched.title && Boolean(form.errors.title)}
              helperText={form.touched.title && form.errors.title}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Select
              value={form.values.service}
              onChange={(value) => {
                form.setFieldValue("service", value);
                form.setFieldValue(
                  "available_payment_methods",
                  includes(form.values.available_payment_methods, BINANCE)
                    ? [BINANCE]
                    : []
                );
                setEditService(true);
              }}
              label={t("Услуга")}
              placeholder={t("Выберите услугу")}
              size="small"
              options={getServices}
              error={form.touched.service && Boolean(form.errors.service)}
              helperText={form.touched.service && form.errors.service}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Select
              value={form.values.bp_service}
              onChange={(value) => {
                form.setFieldValue("bp_service", value);
                if (value) {
                  form.setFieldValue(
                    "available_payment_methods",
                    Array.from(
                      new Set([
                        ...form.values.available_payment_methods,
                        BINANCE,
                      ])
                    )
                  );
                } else {
                  form.setFieldValue(
                    "available_payment_methods",
                    without(form.values.available_payment_methods, BINANCE)
                  );
                }
              }}
              label={`${t("Услуга")} Binance Pay`}
              placeholder={t("Выберите услугу")}
              size="small"
              options={getServices}
              error={form.touched.bp_service && Boolean(form.errors.bp_service)}
              helperText={form.touched.bp_service && form.errors.bp_service}
              withClear
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Select
              value={form.values.wallet}
              onChange={(value) => form.setFieldValue("wallet", value)}
              label={t("Кошелек")}
              placeholder={t("Выберите кошелек")}
              size="small"
              options={
                selectedPointManager?.available_wallets?.length > 0
                  ? selectedPointManager?.available_wallets?.map((item) => ({
                      value: `${item.id}`,
                      label: `${item.id} | ${item.name}`,
                    }))
                  : []
              }
              error={form.touched.wallet && Boolean(form.errors.wallet)}
              helperText={form.touched.wallet && form.errors.wallet}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <TextInput
              size="small"
              label={t("Валюта")}
              placeholder={t("Выберите валюту")}
              onChange={(value) => form.setFieldValue("amount_currency", value)}
              readOnly
              value={form.values.amount_currency}
              error={
                form.touched.amount_currency &&
                Boolean(form.errors.amount_currency)
              }
              helperText={
                form.touched.amount_currency && form.errors.amount_currency
              }
            />
          </Grid>
          <Grid item sm={6} md={2}>
            <TextInput
              size="small"
              label={t("Сумма")}
              placeholder={t("Введите сумму")}
              onChange={(value) => {
                form.setFieldValue(
                  "fixed_amount",
                  handleChangeNumber(value.replace(/[^0-9.]/g, ""))
                );
                form.setFieldValue("preferred_amounts", []);
              }}
              value={numberForInput(form.values.fixed_amount)}
              error={
                form.touched.fixed_amount && Boolean(form.errors.fixed_amount)
              }
              helperText={form.touched.fixed_amount && form.errors.fixed_amount}
            />
          </Grid>
          <Grid item sm={6} md={2}>
            <TextInput
              size="small"
              label={t("Минимальная сумма")}
              placeholder={t("Введите сумму")}
              onChange={(value) => {
                form.setFieldValue(
                  "min_amount",
                  handleChangeNumber(value.replace(/[^0-9.]/g, ""))
                );
                form.setFieldValue("preferred_amounts", []);
              }}
              value={numberForInput(form.values.min_amount)}
              error={form.touched.min_amount && Boolean(form.errors.min_amount)}
              helperText={form.touched.min_amount && form.errors.min_amount}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <InputTags
              name="preferred_amounts"
              size="small"
              disabled={!!form.values.fixed_amount}
              value={[...form.values.preferred_amounts]?.sort(
                (a: string, b: string) => Number(a) - Number(b)
              )}
              maxIntLength={12}
              onChange={(newValue: string[]) => {
                form.setFieldValue("preferred_amounts", newValue);
              }}
              label={t("Предлагаемые суммы")}
              placeholder={t("Введите сумму")}
              error={
                form.touched.preferred_amounts &&
                Boolean(form.errors.preferred_amounts)
              }
              {...(form.errors.preferred_amounts &&
              form.touched.preferred_amounts
                ? {
                    helperText: String(form.errors.preferred_amounts),
                  }
                : {})}
              {...(((form.errors.preferred_amounts &&
                !form.touched.preferred_amounts) ||
                !form.errors.preferred_amounts) &&
              form.values.preferred_amounts?.length > 0
                ? {
                    helperText: t("Поле принимает от 2 до 3 вариантов"),
                  }
                : {})}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <Box flex alignItems="center" nowrap>
              <ButtonSwitch
                firstTitle={t("Добавлять")}
                secondTitle={t("Заменять")}
                value={form.values.preferred_amounts_logic === 1}
                onChange={() =>
                  form.setFieldValue(
                    "preferred_amounts_logic",
                    form.values.preferred_amounts_logic === 2 ? 1 : 2
                  )
                }
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={4}>
            <Select
              value={form.values.locale}
              onChange={(value) => {
                form.setFieldValue("locale", value);
                if (!form.values.available_locales?.includes(value))
                  form.setFieldValue("available_locales", [
                    ...form.values.available_locales,
                    value,
                  ]);
              }}
              placeholder={t("Язык")}
              label={t("Язык")}
              size="small"
              options={[
                { value: "ua", label: "UA" },
                { value: "ru", label: "RU" },
                { value: "en", label: "EN" },
              ]}
              error={form.touched.locale && Boolean(form.errors.locale)}
              helperText={form.touched.locale && form.errors.locale}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <MultiSelect
              value={form.values.available_locales}
              onChange={(value) => {
                form.setFieldValue(
                  "available_locales",
                  !value?.includes(form.values.locale)
                    ? [...value, form.values.locale]
                    : value
                );
              }}
              label={t("Доступные языки")}
              placeholder={t("Выберите")}
              size="small"
              options={[
                { label: "UA", value: "ua" },
                { label: "EN", value: "en" },
                { label: "RU", value: "ru" },
              ]}
              error={
                form.touched.available_locales &&
                Boolean(form.errors.available_locales)
              }
              helperText={
                form.touched.available_locales &&
                String(form.errors.available_locales || "")
              }
            />
          </Grid>
          {!isTemplate ? (
            <Grid item sm={12} md={4}>
              <DateTimePicker
                value={
                  form.values.active_till
                    ? DateTime.fromISO(form.values.active_till)
                    : null
                }
                onChange={(val) => form.setFieldValue("active_till", val)}
                placeholder={t("Срок действия страницы")}
                label={t("Срок действия страницы")}
                error={
                  form.touched.active_till && Boolean(form.errors.active_till)
                }
                helperText={form.touched.active_till && form.errors.active_till}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item sm={12} md={4}>
            <Box
              flex
              alignItems="center"
              justifyContent="space-between"
              p={8}
              style={{
                background: "#FFFFFF",
              }}
            >
              <Typography variant="subtitle3">
                {`${t("Статус страницы")} ${
                  form?.values.status ? t("Актив") : t("Деактив")
                }`}
              </Typography>
              <Switch
                value={form?.values.status}
                onChange={(checked) => form?.setFieldValue("status", checked)}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={4} flex alignItems="center">
            <Checkbox
              value={!form.values.is_reusable}
              onChange={() =>
                form.setFieldValue("is_reusable", !form.values.is_reusable)
              }
              label={t("Одноразовая оплата")}
            />
          </Grid>
        </Grid>
      ) : null}

      <Box
        flex
        alignItems="center"
        mt={32}
        style={{ cursor: "pointer" }}
        onClick={() => setOpenPaymentMethods(!openPaymentMethods)}
      >
        <Typography variant="h5" mr={16}>
          {t("Платежные методы")}
        </Typography>
        <StyledCaretCircleUpIcon open={openPaymentMethods} />
      </Box>
      {openPaymentMethods ? (
        <Grid container pt={24} hSpace={24} vSpace={24}>
          <Grid item sm={12}>
            <Checkbox
              value={includes(form.values.available_payment_methods, CARD)}
              onChange={() =>
                form.setFieldValue(
                  "available_payment_methods",
                  includes(form.values.available_payment_methods, CARD)
                    ? without(form.values.available_payment_methods, CARD)
                    : [...form.values.available_payment_methods, CARD]
                )
              }
              disabled={
                !form.values.service ||
                !includes(getAvailablePaymentMethodsMeta.data, CARD)
              }
              label={t("Оплата картой")}
            />
          </Grid>
          <Grid item sm={12}>
            <Checkbox
              value={includes(form.values.available_payment_methods, GOOGLE)}
              onChange={() =>
                form.setFieldValue(
                  "available_payment_methods",
                  includes(form.values.available_payment_methods, GOOGLE)
                    ? without(form.values.available_payment_methods, GOOGLE)
                    : [...form.values.available_payment_methods, GOOGLE]
                )
              }
              disabled={
                !form.values.service ||
                !includes(getAvailablePaymentMethodsMeta.data, GOOGLE)
              }
              label="Google Pay"
            />
          </Grid>
          <Grid item sm={12}>
            <Checkbox
              value={includes(form.values.available_payment_methods, APPLE)}
              onChange={() =>
                form.setFieldValue(
                  "available_payment_methods",
                  includes(form.values.available_payment_methods, APPLE)
                    ? without(form.values.available_payment_methods, APPLE)
                    : [...form.values.available_payment_methods, APPLE]
                )
              }
              disabled={
                !form.values.service ||
                !includes(getAvailablePaymentMethodsMeta.data, APPLE)
              }
              label="Apple Pay"
            />
          </Grid>
          <Grid item sm={12}>
            <Checkbox
              value={includes(form.values.available_payment_methods, BINANCE)}
              onChange={() =>
                form.setFieldValue(
                  "available_payment_methods",
                  includes(form.values.available_payment_methods, BINANCE)
                    ? without(form.values.available_payment_methods, BINANCE)
                    : [...form.values.available_payment_methods, BINANCE]
                )
              }
              disabled={!form.values.bp_service}
              label="Binance Pay"
            />
          </Grid>
        </Grid>
      ) : null}

      <Box
        flex
        alignItems="center"
        mt={32}
        style={{ cursor: "pointer" }}
        onClick={() => setOpenAdditional(!openAdditional)}
      >
        <Typography variant="h5" mr={16}>
          {t("Опциональные параметры")}
        </Typography>
        <StyledCaretCircleUpIcon open={openAdditional} />
      </Box>
      {openAdditional ? (
        <Grid container pt={24} hSpace={24} vSpace={24}>
          {!style.is_fc ? (
            <>
              <Grid item sm={12} md={4}>
                <Box>
                  <Grid container alignItems="center" nowrap>
                    <Grid item>
                      <Checkbox
                        value={includes(form.values.additional_fields, "name")}
                        onChange={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(form.values.additional_fields, "name")
                              ? without(form.values.additional_fields, "name")
                              : [...form.values.additional_fields, "name"]
                          );
                          if (includes(form.values.additional_fields, "name")) {
                            form.setFieldValue(
                              "description_template",
                              form.values.description_template.replace(
                                `[[{"value":"${t(
                                  "Имя плательщика"
                                )}","prefix":"@"}]]`,
                                ""
                              )
                            );
                            form.setFieldValue("prefilled_fields.name", "");
                          }
                        }}
                      />
                    </Grid>
                    <Grid item md="fill">
                      <TextInput
                        size="small"
                        label={t("Имя плательщика")}
                        placeholder={t("Предварительно заполнить")}
                        onFocus={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(form.values.additional_fields, "name")
                              ? [...form.values.additional_fields]
                              : [...form.values.additional_fields, "name"]
                          );
                        }}
                        onChange={(v) =>
                          form.setFieldValue("prefilled_fields.name", v)
                        }
                        value={form.values.prefilled_fields?.name || ""}
                        iconEnd={
                          form.values.prefilled_fields?.name ? (
                            <CloseIcon
                              style={{ cursor: "pointer", marginTop: 5 }}
                              onClick={() => {
                                form.setFieldValue("prefilled_fields.name", "");
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item sm={12} md={4}>
            <Box>
              <Grid container alignItems="center" nowrap>
                <Grid item>
                  <Checkbox
                    value={includes(form.values.additional_fields, "phone")}
                    onChange={() => {
                      form.setFieldValue(
                        "additional_fields",
                        includes(form.values.additional_fields, "phone")
                          ? without(form.values.additional_fields, "phone")
                          : [...form.values.additional_fields, "phone"]
                      );
                      if (includes(form.values.additional_fields, "phone")) {
                        form.setFieldValue(
                          "description_template",
                          form.values.description_template.replace(
                            `[[{"value":"${t(
                              "Номер телефона плательщика"
                            )}","prefix":"@"}]]`,
                            ""
                          )
                        );
                        form.setFieldValue("prefilled_fields.phone", "");
                      }
                    }}
                  />
                </Grid>
                <Grid item sm="fill">
                  <TextInput
                    size="small"
                    label={t("Номер телефона плательщика")}
                    placeholder="+380123456789"
                    onFocus={() => {
                      form.setFieldValue(
                        "additional_fields",
                        includes(form.values.additional_fields, "phone")
                          ? [...form.values.additional_fields]
                          : [...form.values.additional_fields, "phone"]
                      );
                    }}
                    onChange={(v) =>
                      form.setFieldValue(
                        "prefilled_fields.phone",
                        v.replace(/[^0-9+]/g, "").slice(0, 13)
                      )
                    }
                    value={form.values.prefilled_fields?.phone || ""}
                    iconEnd={
                      form.values.prefilled_fields?.phone ? (
                        <CloseIcon
                          style={{ cursor: "pointer", marginTop: 5 }}
                          onClick={() => {
                            form.setFieldValue("prefilled_fields.phone", "");
                          }}
                        />
                      ) : (
                        <></>
                      )
                    }
                    error={
                      form.touched.prefilled_fields &&
                      Boolean(form.errors.prefilled_fields?.phone)
                    }
                    helperText={
                      form.touched.prefilled_fields &&
                      form.errors.prefilled_fields?.phone
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12} md={4}>
            <Box>
              <Grid container alignItems="center" nowrap>
                <Grid item>
                  <Checkbox
                    value={includes(form.values.additional_fields, "email")}
                    onChange={() => {
                      form.setFieldValue(
                        "additional_fields",
                        includes(form.values.additional_fields, "email")
                          ? without(form.values.additional_fields, "email")
                          : [...form.values.additional_fields, "email"]
                      );
                      if (includes(form.values.additional_fields, "email")) {
                        form.setFieldValue(
                          "description_template",
                          form.values.description_template.replace(
                            `[[{"value":"${t(
                              "Email плательщика"
                            )}","prefix":"@"}]]`,
                            ""
                          )
                        );
                        form.setFieldValue("prefilled_fields.email", "");
                      }
                    }}
                  />
                </Grid>
                <Grid item sm="fill">
                  <TextInput
                    size="small"
                    label={t("Email плательщика")}
                    placeholder={t("Предварительно заполнить")}
                    onFocus={() => {
                      form.setFieldValue(
                        "additional_fields",
                        includes(form.values.additional_fields, "email")
                          ? [...form.values.additional_fields]
                          : [...form.values.additional_fields, "email"]
                      );
                    }}
                    onChange={(v) =>
                      form.setFieldValue("prefilled_fields.email", v)
                    }
                    value={form.values.prefilled_fields?.email || ""}
                    iconEnd={
                      form.values.prefilled_fields?.email ? (
                        <CloseIcon
                          style={{ cursor: "pointer", marginTop: 5 }}
                          onClick={() => {
                            form.setFieldValue("prefilled_fields.email", "");
                          }}
                        />
                      ) : (
                        <></>
                      )
                    }
                    error={
                      form.touched.prefilled_fields &&
                      Boolean(form.errors.prefilled_fields?.email)
                    }
                    helperText={
                      form.touched.prefilled_fields &&
                      form.errors.prefilled_fields?.email
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12} md={4}>
            <Box>
              <Grid container alignItems="center" nowrap>
                <Grid item>
                  <Checkbox
                    value={includes(form.values.additional_fields, "comment")}
                    onChange={() => {
                      form.setFieldValue(
                        "additional_fields",
                        includes(form.values.additional_fields, "comment")
                          ? without(form.values.additional_fields, "comment")
                          : [...form.values.additional_fields, "comment"]
                      );
                      if (includes(form.values.additional_fields, "comment")) {
                        form.setFieldValue(
                          "description_template",
                          form.values.description_template.replace(
                            `[[{"value":"${t("Комментарий")}","prefix":"@"}]]`,
                            ""
                          )
                        );
                        form.setFieldValue("prefilled_fields.comment", "");
                      }
                    }}
                  />
                </Grid>
                <Grid item sm="fill">
                  <TextInput
                    fullwidth
                    size="small"
                    label={t("Комментарий")}
                    placeholder={t("Предварительно заполнить")}
                    onFocus={() => {
                      form.setFieldValue(
                        "additional_fields",
                        includes(form.values.additional_fields, "comment")
                          ? [...form.values.additional_fields]
                          : [...form.values.additional_fields, "comment"]
                      );
                    }}
                    onChange={(v) =>
                      form.setFieldValue("prefilled_fields.comment", v)
                    }
                    value={form.values.prefilled_fields?.comment || ""}
                    iconEnd={
                      form.values.prefilled_fields?.comment ? (
                        <CloseIcon
                          style={{ cursor: "pointer", marginTop: 5 }}
                          onClick={() => {
                            form.setFieldValue("prefilled_fields.comment", "");
                          }}
                        />
                      ) : (
                        <></>
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Box>
              <Grid container hSpace={24} vSpace={24}>
                <Grid item sm={12} md={4}>
                  <Grid container alignItems="center" nowrap>
                    <Grid item>
                      <Checkbox
                        value={includes(
                          form.values.additional_fields,
                          "last_name"
                        )}
                        onChange={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(form.values.additional_fields, "last_name")
                              ? without(
                                  form.values.additional_fields,
                                  "last_name"
                                )
                              : [...form.values.additional_fields, "last_name"]
                          );
                          if (
                            includes(form.values.additional_fields, "last_name")
                          ) {
                            form.setFieldValue(
                              "description_template",
                              form.values.description_template.replace(
                                `[[{"value":"${t("ФИО")}","prefix":"@"}]]`,
                                ""
                              )
                            );
                            form.setFieldValue(
                              "prefilled_fields.last_name",
                              ""
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item sm="fill">
                      <TextInput
                        size="small"
                        label={t("Фамилия")}
                        placeholder={t("Предварительно заполнить")}
                        onFocus={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(form.values.additional_fields, "last_name")
                              ? [...form.values.additional_fields]
                              : [...form.values.additional_fields, "last_name"]
                          );
                        }}
                        onChange={(v) =>
                          form.setFieldValue("prefilled_fields.last_name", v)
                        }
                        value={form.values.prefilled_fields?.last_name || ""}
                        iconEnd={
                          form.values.prefilled_fields?.last_name ? (
                            <CloseIcon
                              style={{ cursor: "pointer", marginTop: 5 }}
                              onClick={() => {
                                form.setFieldValue(
                                  "prefilled_fields.last_name",
                                  ""
                                );
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Grid container alignItems="center" nowrap>
                    <Grid item>
                      <Checkbox
                        value={includes(
                          form.values.additional_fields,
                          "first_name"
                        )}
                        onChange={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(
                              form.values.additional_fields,
                              "first_name"
                            )
                              ? without(
                                  form.values.additional_fields,
                                  "first_name"
                                )
                              : [...form.values.additional_fields, "first_name"]
                          );
                          if (
                            includes(
                              form.values.additional_fields,
                              "first_name"
                            )
                          ) {
                            form.setFieldValue(
                              "description_template",
                              form.values.description_template.replace(
                                `[[{"value":"${t("ФИО")}","prefix":"@"}]]`,
                                ""
                              )
                            );
                            form.setFieldValue(
                              "prefilled_fields.first_name",
                              ""
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item sm="fill">
                      <TextInput
                        size="small"
                        label={t("Имя")}
                        placeholder={t("Предварительно заполнить")}
                        onFocus={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(
                              form.values.additional_fields,
                              "first_name"
                            )
                              ? [...form.values.additional_fields]
                              : [...form.values.additional_fields, "first_name"]
                          );
                        }}
                        onChange={(v) =>
                          form.setFieldValue("prefilled_fields.first_name", v)
                        }
                        value={form.values.prefilled_fields?.first_name || ""}
                        iconEnd={
                          form.values.prefilled_fields?.first_name ? (
                            <CloseIcon
                              style={{ cursor: "pointer", marginTop: 5 }}
                              onClick={() => {
                                form.setFieldValue(
                                  "prefilled_fields.first_name",
                                  ""
                                );
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Grid container alignItems="center" nowrap>
                    <Grid item>
                      <Checkbox
                        value={includes(
                          form.values.additional_fields,
                          "patronymic"
                        )}
                        onChange={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(
                              form.values.additional_fields,
                              "patronymic"
                            )
                              ? without(
                                  form.values.additional_fields,
                                  "patronymic"
                                )
                              : [...form.values.additional_fields, "patronymic"]
                          );
                          if (
                            includes(
                              form.values.additional_fields,
                              "patronymic"
                            )
                          ) {
                            form.setFieldValue(
                              "description_template",
                              form.values.description_template.replace(
                                `[[{"value":"${t("ФИО")}","prefix":"@"}]]`,
                                ""
                              )
                            );
                            form.setFieldValue(
                              "prefilled_fields.patronymic",
                              ""
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item sm="fill">
                      <TextInput
                        size="small"
                        label={t("Отчество")}
                        placeholder={t("Предварительно заполнить")}
                        onFocus={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(
                              form.values.additional_fields,
                              "patronymic"
                            )
                              ? [...form.values.additional_fields]
                              : [...form.values.additional_fields, "patronymic"]
                          );
                        }}
                        onChange={(v) =>
                          form.setFieldValue("prefilled_fields.patronymic", v)
                        }
                        value={form.values.prefilled_fields?.patronymic || ""}
                        iconEnd={
                          form.values.prefilled_fields?.patronymic ? (
                            <CloseIcon
                              style={{ cursor: "pointer", marginTop: 5 }}
                              onClick={() => {
                                form.setFieldValue(
                                  "prefilled_fields.patronymic",
                                  ""
                                );
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Grid container alignItems="center" nowrap>
                    <Grid item>
                      <Checkbox
                        value={includes(
                          form.values.additional_fields,
                          "birth_date"
                        )}
                        onChange={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(
                              form.values.additional_fields,
                              "birth_date"
                            )
                              ? without(
                                  form.values.additional_fields,
                                  "birth_date"
                                )
                              : [...form.values.additional_fields, "birth_date"]
                          );
                          if (
                            includes(
                              form.values.additional_fields,
                              "birth_date"
                            )
                          ) {
                            form.setFieldValue(
                              "description_template",
                              form.values.description_template.replace(
                                `[[{"value":"${t(
                                  "Дата рождения"
                                )}","prefix":"@"}]]`,
                                ""
                              )
                            );
                            form.setFieldValue(
                              "prefilled_fields.birth_date",
                              ""
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid item sm="fill">
                      <DateTimePicker
                        oldDate
                        value={
                          form.values.prefilled_fields?.birth_date &&
                          DateTime.fromFormat(
                            form.values.prefilled_fields?.birth_date,
                            "dd.MM.yyyy"
                          )
                            ? DateTime.fromFormat(
                                form.values.prefilled_fields?.birth_date,
                                "dd.MM.yyyy"
                              )
                            : null
                        }
                        onChange={(v) =>
                          form.setFieldValue(
                            "prefilled_fields.birth_date",
                            v ? DateTime.fromISO(v).toFormat("dd.MM.yyyy") : ""
                          )
                        }
                        onFocus={() => {
                          form.setFieldValue(
                            "additional_fields",
                            includes(
                              form.values.additional_fields,
                              "birth_date"
                            )
                              ? [...form.values.additional_fields]
                              : [...form.values.additional_fields, "birth_date"]
                          );
                        }}
                        label={t("Дата рождения")}
                        placeholder={t("Предварительно заполнить")}
                        error={
                          form.touched.prefilled_fields &&
                          Boolean(form.errors.prefilled_fields?.birth_date)
                        }
                        helperText={
                          form.touched.prefilled_fields &&
                          form.errors.prefilled_fields?.birth_date
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12}>
                  <Box
                    flex
                    alignItems="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpenAddressParams(!openAddressParams)}
                  >
                    <Typography variant="h5" mr={16}>
                      {t("Параметры адреса")}
                    </Typography>
                    <StyledCaretCircleUpIcon open={openAddressParams} />
                  </Box>
                  {openAddressParams ? (
                    <Grid container pt={24} hSpace={24} vSpace={24}>
                      <Grid item sm={12} md={4}>
                        <Grid container alignItems="center" nowrap>
                          <Grid item>
                            <Checkbox
                              value={includes(
                                form.values.additional_fields,
                                "country"
                              )}
                              onChange={() => {
                                form.setFieldValue(
                                  "additional_fields",
                                  includes(
                                    form.values.additional_fields,
                                    "country"
                                  )
                                    ? without(
                                        form.values.additional_fields,
                                        "country"
                                      )
                                    : [
                                        ...form.values.additional_fields,
                                        "country",
                                      ]
                                );
                                if (
                                  includes(
                                    form.values.additional_fields,
                                    "country"
                                  )
                                ) {
                                  form.setFieldValue(
                                    "description_template",
                                    form.values.description_template.replace(
                                      `[[{"value":"${t(
                                        "Страна"
                                      )}","prefix":"@"}]]`,
                                      ""
                                    )
                                  );
                                  form.setFieldValue(
                                    "prefilled_fields.country",
                                    ""
                                  );
                                }
                              }}
                            />
                          </Grid>
                          <Grid item sm="fill">
                            <Select
                              onFocus={() => {
                                form.setFieldValue(
                                  "additional_fields",
                                  includes(
                                    form.values.additional_fields,
                                    "country"
                                  )
                                    ? [...form.values.additional_fields]
                                    : [
                                        ...form.values.additional_fields,
                                        "country",
                                      ]
                                );
                              }}
                              onChange={(v) =>
                                form.setFieldValue(
                                  "prefilled_fields.country",
                                  v || ""
                                )
                              }
                              value={
                                form.values.prefilled_fields?.country || ""
                              }
                              label={t("Страна")}
                              placeholder={t("Предварительно заполнить")}
                              size="small"
                              options={getCountryOptions}
                              withClear
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={4}>
                        <Grid container alignItems="center" nowrap>
                          <Grid item>
                            <Checkbox
                              value={includes(
                                form.values.additional_fields,
                                "city"
                              )}
                              onChange={() => {
                                form.setFieldValue(
                                  "additional_fields",
                                  includes(
                                    form.values.additional_fields,
                                    "city"
                                  )
                                    ? without(
                                        form.values.additional_fields,
                                        "city"
                                      )
                                    : [...form.values.additional_fields, "city"]
                                );
                                if (
                                  includes(
                                    form.values.additional_fields,
                                    "city"
                                  )
                                ) {
                                  form.setFieldValue(
                                    "description_template",
                                    form.values.description_template.replace(
                                      `[[{"value":"${t(
                                        "Город"
                                      )}","prefix":"@"}]]`,
                                      ""
                                    )
                                  );
                                  form.setFieldValue(
                                    "prefilled_fields.city",
                                    ""
                                  );
                                }
                              }}
                            />
                          </Grid>
                          <Grid item sm="fill">
                            <TextInput
                              fullwidth
                              size="small"
                              label={t("Город")}
                              placeholder={t("Предварительно заполнить")}
                              onFocus={() => {
                                form.setFieldValue(
                                  "additional_fields",
                                  includes(
                                    form.values.additional_fields,
                                    "city"
                                  )
                                    ? [...form.values.additional_fields]
                                    : [...form.values.additional_fields, "city"]
                                );
                              }}
                              onChange={(v) =>
                                form.setFieldValue(
                                  "prefilled_fields.city",
                                  v.slice(0, 36)
                                )
                              }
                              value={form.values.prefilled_fields?.city || ""}
                              iconEnd={
                                form.values.prefilled_fields?.city ? (
                                  <CloseIcon
                                    style={{
                                      cursor: "pointer",
                                      marginTop: 5,
                                    }}
                                    onClick={() => {
                                      form.setFieldValue(
                                        "prefilled_fields.city",
                                        ""
                                      );
                                    }}
                                  />
                                ) : (
                                  <></>
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={4}>
                        <Grid container alignItems="center" nowrap>
                          <Grid item>
                            <Checkbox
                              value={includes(
                                form.values.additional_fields,
                                "address"
                              )}
                              onChange={() => {
                                form.setFieldValue(
                                  "additional_fields",
                                  includes(
                                    form.values.additional_fields,
                                    "address"
                                  )
                                    ? without(
                                        form.values.additional_fields,
                                        "address"
                                      )
                                    : [
                                        ...form.values.additional_fields,
                                        "address",
                                      ]
                                );
                                if (
                                  includes(
                                    form.values.additional_fields,
                                    "address"
                                  )
                                ) {
                                  form.setFieldValue(
                                    "description_template",
                                    form.values.description_template.replace(
                                      `[[{"value":"${t(
                                        "Адрес"
                                      )}","prefix":"@"}]]`,
                                      ""
                                    )
                                  );
                                  form.setFieldValue(
                                    "prefilled_fields.address",
                                    ""
                                  );
                                }
                              }}
                            />
                          </Grid>
                          <Grid item sm="fill">
                            <TextInput
                              fullwidth
                              size="small"
                              label={t("Адрес")}
                              placeholder={t("Предварительно заполнить")}
                              onFocus={() => {
                                form.setFieldValue(
                                  "additional_fields",
                                  includes(
                                    form.values.additional_fields,
                                    "address"
                                  )
                                    ? [...form.values.additional_fields]
                                    : [
                                        ...form.values.additional_fields,
                                        "address",
                                      ]
                                );
                              }}
                              onChange={(v) =>
                                form.setFieldValue(
                                  "prefilled_fields.address",
                                  v.slice(0, 128)
                                )
                              }
                              value={
                                form.values.prefilled_fields?.address || ""
                              }
                              iconEnd={
                                form.values.prefilled_fields?.address ? (
                                  <CloseIcon
                                    style={{
                                      cursor: "pointer",
                                      marginTop: 5,
                                    }}
                                    onClick={() => {
                                      form.setFieldValue(
                                        "prefilled_fields.address",
                                        ""
                                      );
                                    }}
                                  />
                                ) : (
                                  <></>
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={4}>
                        <Grid container alignItems="center" nowrap>
                          <Grid item>
                            <Checkbox
                              value={includes(
                                form.values.additional_fields,
                                "zip_code"
                              )}
                              onChange={() => {
                                form.setFieldValue(
                                  "additional_fields",
                                  includes(
                                    form.values.additional_fields,
                                    "zip_code"
                                  )
                                    ? without(
                                        form.values.additional_fields,
                                        "zip_code"
                                      )
                                    : [
                                        ...form.values.additional_fields,
                                        "zip_code",
                                      ]
                                );
                                if (
                                  includes(
                                    form.values.additional_fields,
                                    "zip_code"
                                  )
                                ) {
                                  form.setFieldValue(
                                    "description_template",
                                    form.values.description_template.replace(
                                      `[[{"value":"${t(
                                        "Почтовый индекс"
                                      )}","prefix":"@"}]]`,
                                      ""
                                    )
                                  );
                                  form.setFieldValue(
                                    "prefilled_fields.zip_code",
                                    ""
                                  );
                                }
                              }}
                            />
                          </Grid>
                          <Grid item sm="fill">
                            <TextInput
                              fullwidth
                              size="small"
                              label={t("Почтовый индекс")}
                              placeholder={t("Предварительно заполнить")}
                              onFocus={() => {
                                form.setFieldValue(
                                  "additional_fields",
                                  includes(
                                    form.values.additional_fields,
                                    "zip_code"
                                  )
                                    ? [...form.values.additional_fields]
                                    : [
                                        ...form.values.additional_fields,
                                        "zip_code",
                                      ]
                                );
                              }}
                              onChange={(v) =>
                                form.setFieldValue(
                                  "prefilled_fields.zip_code",
                                  v.slice(0, 10)
                                )
                              }
                              value={
                                form.values.prefilled_fields?.zip_code || ""
                              }
                              iconEnd={
                                form.values.prefilled_fields?.zip_code ? (
                                  <CloseIcon
                                    style={{
                                      cursor: "pointer",
                                      marginTop: 5,
                                    }}
                                    onClick={() => {
                                      form.setFieldValue(
                                        "prefilled_fields.zip_code",
                                        ""
                                      );
                                    }}
                                  />
                                ) : (
                                  <></>
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : null}
      <Box
        flex
        alignItems="center"
        mt={32}
        style={{ cursor: "pointer" }}
        onClick={() => setOpenPurpose(!openPurpose)}
      >
        <Typography variant="h5" mr={16}>
          {t("Назначение платежа")}
        </Typography>
        <StyledCaretCircleUpIcon open={openPurpose} />
      </Box>
      {openPurpose && !reload ? (
        <Grid container pt={24}>
          <Grid item sm={12} lg={7.8} style={{ width: "100%" }}>
            <StyledDescriptionWrapper accentcolor={accentColor}>
              <Tags
                value={form.values.description_template || ""}
                settings={{
                  mode: "mix",
                  pattern: /@/,
                  enforceWhitelist: true,
                  dropdown: {
                    enabled: 0,
                  },
                }}
                tagifyRef={ref}
                placeholder={t("Шаблон назначения платежа")}
                onAdd={onAdd}
                onChange={onChange}
                whitelist={[
                  t("ФИО"),
                  t("Дата рождения"),
                  ...(style.is_fc ? [] : [t("Имя плательщика")]),
                  t("Номер телефона плательщика"),
                  t("Email плательщика"),
                  t("Комментарий"),
                ]}
              />
            </StyledDescriptionWrapper>
            {form.errors.description_template &&
              form.touched.description_template && (
                <StyledHelperText>
                  {form.errors.description_template}
                </StyledHelperText>
              )}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
const StyledDescriptionWrapper = styled.div`
  .tagify {
    border: 1px solid #b9bfc8;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 5px 20px;
    min-height: 48px;
    width: 100%;
    color: #19202e;
    box-sizing: border-box;
    background: transparent;
    &:placeholder {
      color: #727272;
    }
    &:hover {
      border: 1px solid
        ${({ accentcolor }: { accentcolor: string }) =>
          hexToRGB(accentcolor, 0.4)};
    }
    &:focus {
      border: 1px solid ${({ accentcolor }) => accentcolor} !important;
    }
    .tagify__input {
      margin-bottom: -22px;
    }
  }
  tag {
    background: #eaeff7;
    height: 20px;
    padding: 4px;
    white-space: nowrap;
    color: #19202e;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    div {
      &::before {
        display: none;
      }
    }
  }
`;

const StyledCaretCircleUpIcon = styled(CaretCircleUpIcon)`
  ${({ open }: { open: boolean }) =>
    !open ? "transform: rotate(180deg)" : ""};
`;

const StyledHelperText = styled.div`
  font-weight: 400;
  margin-top: 4px;
  font-size: 12px;
  line-height: 15px;
  color: #D00025;
`;
