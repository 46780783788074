import { baseSplitApi } from "./index";

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
}

export interface LoginRequest {
  username: string;
  password: string;
  otp?: string;
}

export interface StyleResponse {
  name: string;
  description: string;
  is_fc: boolean;
  guest_dashboard_available: boolean;
  receipt_endpoint: string;
  helpcrunch_organization: string;
  helpcrunch_app_id: string;
  style: {
    logo: string;
    accent_color: string;
    background_color: string;
    background_image: string;
    font: string;
    soc_registration_available: boolean;
  };
}

export interface UsersSelfResponse {
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  avatar: string;
  timezone: string;
  id: number;
  is_active: boolean;
  is_approved: boolean;
  is_staff: boolean;
  otp_enabled: boolean;
  account: { id: number; name: string };
}

interface UserInfosItem {
  email: string;
  created_at: string;
  business_name: string;
  site_url: string;
  id: number;
  user: number;
  phone_number: string;
  tax_number: string;
  registration_form: { id: number; name: string };
  business_area: { id: number; name: string };
}

interface UserDocument {
  file: string;
  is_approved: boolean;
  id: number;
  user: { id: number; name: string };
  document: { id: number; name: string };
}

export interface Banner {
  id: number;
  status: number;
  brand: number;
  image_uk: string;
  image_en: string;
}

export const userApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: ({ username, password, otp }) => ({
        url: "/o/token/",
        method: "POST",
        body: {
          username,
          password,
          otp,
          client_id: String(process.env.REACT_APP_API_AUTH_CLIENT_ID),
          grant_type: "password",
        },
      }),
    }),
    googleLogin: builder.mutation<LoginResponse, { googleToken: string }>({
      query: ({ googleToken }) => ({
        url: "/o/convert-token/",
        method: "POST",
        body: {
          grant_type: "convert_token",
          client_id: process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_OAUTH2_KEY, // <django-oauth-generated-client_id>,
          client_secret: process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_OAUTH2_SECRET, // <django-oauth-generated-client_secret>,
          backend: "google-oauth2",
          token: googleToken,
        },
      }),
    }),
    logout: builder.mutation<void, { token: string }>({
      query: ({ token }) => ({
        url: "/o/revoke-token/",
        method: "POST",
        body: {
          token,
          client_id: String(process.env.REACT_APP_API_AUTH_CLIENT_ID),
        },
      }),
    }),
    register: builder.mutation<
      LoginResponse,
      {
        username: string;
        email: string;
        password: string;
      }
    >({
      query: (data) => ({
        url: "/users/register/",
        method: "POST",
        body: data,
      }),
    }),
    checkOtpStatus: builder.mutation<
      { status: boolean },
      { username: string; password: string }
    >({
      query: (data) => ({
        url: "/users/check-otp-status/",
        method: "POST",
        body: data,
      }),
    }),
    getStyle: builder.mutation<StyleResponse, { domain: string }>({
      query: (data) => ({
        url: "/brands/get-full-info/",
        method: "POST",
        body: data,
      }),
    }),
    brandsAuthorize: builder.mutation<{ token: string }, void>({
      query: () => ({
        url: "/brands/authorize/",
        method: "POST",
        body: { domain: window.location.host },
      }),
      invalidatesTags: ["UserInfos"],
    }),
    getActiveBanner: builder.mutation<Banner, void>({
      query: () => ({
        url: "/banners/get-active-banner/",
        method: "POST",
        body: { domain: window.location.host },
      }),
    }),
    getUsersSelf: builder.query<UsersSelfResponse, void>({
      query: () => "/users/self/",
      providesTags: ["User"],
    }),
    updateUser: builder.mutation<
      UsersSelfResponse,
      { formData: FormData; id: number }
    >({
      query: ({ id, formData }) => ({
        url: `/users/${id}/`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["User"],
    }),
    getUserInfos: builder.query<{ results: UserInfosItem[] }, { user: number }>(
      {
        query: ({ user }) => {
          const query = new URLSearchParams();
          query.append("user", String(user));
          return `/user-infos/?${query.toString()}`;
        },
        providesTags: ["UserInfos"],
      }
    ),
    getUsersNewPassword: builder.query<{ password: string }, number>({
      query: (id) => `/users/${id}/new-password/`,
    }),
    getUserInfo: builder.query<UserInfosItem, { id: number }>({
      query: ({ id }) => `/user-infos/${id}/`,
    }),
    createUserInfo: builder.mutation<
      UserInfosItem,
      {
        business_name: string;
        user: number;
        tax_number: string;
        registration_form: number | string;
        business_area: number | string;
      }
    >({
      query: (data) => ({
        url: "/user-infos/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["UserInfos"],
    }),
    updateUserInfo: builder.mutation<
      UserInfosItem,
      {
        business_name: string;
        user: number;
        id: number;
        tax_number: string;
        registration_form: number | string;
        business_area: number | string;
      }
    >({
      query: ({ id, ...data }) => ({
        url: `/user-infos/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["UserInfos"],
    }),
    getUserDocuments: builder.query<
      { results: UserDocument[] },
      { user: number }
    >({
      query: ({ user }) => {
        const query = new URLSearchParams();
        query.append("user", String(user));
        return `/user-documents/?${query.toString()}`;
      },
      providesTags: ["UserDocuments"],
    }),
    createUserDocument: builder.mutation<void, { formData: FormData }>({
      query: ({ formData }) => ({
        url: "/user-documents/",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["UserDocuments"],
    }),
    getOtp: builder.mutation<{ code: string; string: string }, void>({
      query: (data) => ({
        url: "/users/self/get-otp/",
        method: "POST",
        body: data,
      }),
    }),
    updateOtp: builder.mutation<void, { otp: string }>({
      query: (data) => ({
        url: "/users/self/update-otp/",
        method: "POST",
        body: data,
        invalidatesTags: ["User"],
      }),
    }),
    changePassword: builder.mutation<
      void,
      { old_password: string; new_password: string; otp: string }
    >({
      query: (data) => ({
        url: "/users/self/change-password/",
        method: "POST",
        body: data,
      }),
    }),

    getUsersMeInvoicingPermissions: builder.query<string[], void>({
      query: () => "/users/me/invoicing/permissions/",
    }),
    getUsersMePointManagerPermissions: builder.query<string[], void>({
      query: () => "/users/me/pointmanager/permissions/",
    }),
    getUsersMeRetrieverPermissions: builder.query<string[], void>({
      query: () => "/users/me/retriever/permissions/",
    }),
    getUsersMePayoutsPermissions: builder.query<string[], void>({
      query: () => "/users/me/payouts/permissions/",
    }),
    getUsersMeTransactionPermissions: builder.query<string[], void>({
      query: () => "/users/me/transaction/permissions/",
    }),
    getUsersMeReportRequestPermissions: builder.query<string[], void>({
      query: () => "/users/me/reportrequest/permissions/",
    }),
    getUsersMeTransactionFraudInfoPermissions: builder.query<string[], void>({
      query: () => "/users/me/transactionfraudinfo/permissions/",
    }),
    getUsersMeAgoraPermissions: builder.query<string[], void>({
      query: () => "/users/me/agora/permissions/",
    }),
    getUsersMeСustomPermissions: builder.query<string[], void>({
      query: () => "/users/me/custom_permission/permissions/",
    }),
    getUsersMeUserPermissions: builder.query<string[], void>({
      query: () => "/users/me/user/permissions/",
    }),
    getUsersMeCustomizationThemePermissions: builder.query<string[], void>({
      query: () => "/users/me/customizationtheme/permissions/",
    }),
    getUsersMeCustomizationRulePermissions: builder.query<string[], void>({
      query: () => "/users/me/customizationrule/permissions/",
    }),
    getUsersMeInvoicePermissions: builder.query<string[], void>({
      query: () => "/users/me/invoices/permissions/",
    }),
  }),
});

export const {
  useLoginMutation,
  useGoogleLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useGetStyleMutation,
  useBrandsAuthorizeMutation,
  useCheckOtpStatusMutation,
  useGetUsersSelfQuery,
  useUpdateUserMutation,
  useLazyGetUsersNewPasswordQuery,
  useLazyGetUsersSelfQuery,
  useLazyGetUserInfosQuery,
  useLazyGetUserInfoQuery,
  useCreateUserInfoMutation,
  useUpdateUserInfoMutation,
  useGetUserDocumentsQuery,
  useCreateUserDocumentMutation,
  useGetOtpMutation,
  useChangePasswordMutation,
  useUpdateOtpMutation,
  useGetActiveBannerMutation,

  useGetUsersMeAgoraPermissionsQuery,
  useGetUsersMePayoutsPermissionsQuery,
  useGetUsersMeRetrieverPermissionsQuery,
  useGetUsersMeInvoicingPermissionsQuery,
  useGetUsersMeTransactionPermissionsQuery,
  useGetUsersMePointManagerPermissionsQuery,
  useGetUsersMeReportRequestPermissionsQuery,
  useGetUsersMeTransactionFraudInfoPermissionsQuery,
  useGetUsersMeСustomPermissionsQuery,
  useGetUsersMeUserPermissionsQuery,
  useGetUsersMeCustomizationRulePermissionsQuery,
  useGetUsersMeCustomizationThemePermissionsQuery,
  useGetUsersMeInvoicePermissionsQuery,
} = userApi;
