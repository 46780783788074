import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { InvoicesQueryParams } from "api/baseAPI/checkoutInvoiceInvoices";
import { TableFilter } from "components/table/TableFilter";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { useFilterMakerResult } from "utils/filterMaker";
import { useSelectedPointManager } from "hooks/useSelectedEntity";

export const InvoicesFilters = ({
  error,
  filters,
}: {
  filters: useFilterMakerResult<InvoicesQueryParams>;
  error: FetchBaseQueryError | SerializedError | undefined;
}) => {
  const { t } = useTranslation();
  const { values, setValue, errors } = filters;
  const [errorState, setError] = useState<Record<string, string>>({});

  useEffect(() => {
    if (error && "data" in error) {
      const data = error.data as Record<string, string>;
      setError(data);
    } else {
      setError({});
    }
  }, [error]);

  const selectedPointManager = useSelectedPointManager();

  return (
    <TableFilter
      onSubmit={filters.handleSubmit}
      onReset={filters.handleReset}
      disabledReset={filters.disabledReset}
      disableSubmit={!!errors}
      widthBox="60%"
    >
      <Grid container mt={12} hSpace={24} vSpace={24} smHSpace={8}>
        <Grid item sm={12} md={6}>
          <TextInput
            size="small"
            label={t("UIID ссылки")}
            placeholder={t("Предварительно заполнить")}
            value={values.uuid}
            onChange={(value) => setValue("uuid", value)}
            error={Boolean(errorState?.uuid)}
            helperText={errorState?.uuid}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextInput
            size="small"
            label={t("Название")}
            placeholder={t("Предварительно заполнить")}
            value={values.title__ilike}
            onChange={(value) => setValue("title__ilike", value)}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Select
            value={values.wallet_id}
            onChange={(value) => setValue("wallet_id", value)}
            label={t("Кошелек")}
            placeholder={t("Выберите кошелек")}
            size="small"
            options={
              selectedPointManager?.available_wallets?.length > 0
                ? selectedPointManager?.available_wallets?.map((item) => ({
                    value: `${item.id}`,
                    label: `${item.id} | ${item.name}`,
                  }))
                : []
            }
          />
        </Grid>
      </Grid>
    </TableFilter>
  );
};
