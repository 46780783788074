import { baseSplitApi } from "./index";

type AccountWalletTransactions = {
  id: number;
  created_at: string;
  balance_after: number;
  balance_before: number;
  comment: string;
  amount: number;
  transaction: { id: number; amount_currency: string };
};

export type AccountWalletTransactionsQueryParams = {
  page: string;
  page_size: string;
  search: string;
  account_wallet: string;
  amount__gt: string;
  amount__lt: string;
  created_at__gte: string;
  created_at__lte: string;
};

export const accountWalletTransactionsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAccountWalletTransactions: builder.query<
      { count: number; results: AccountWalletTransactions[] },
      AccountWalletTransactionsQueryParams
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/retriever/v0/account-wallet-transactions/?${query.toString()}`;
      },
    }),
  }),
});

export const { useGetAccountWalletTransactionsQuery } =
  accountWalletTransactionsApi;
