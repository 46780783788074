import React from "react";
import styled from "styled-components";
import { getColors } from "utils/getColors";
import { ReactComponent as SpinerIcon } from "utils/img/spiner.svg";
import { Margin, marginProps, marginStyle } from "./utility/maring";
import { Padding, paddingProps, paddingStyle } from "./utility/padding";
import {
  getResponsiveProps,
  getResponsiveStyle,
  Responsive,
} from "./utility/responsive";
import { hiddenStyle, Hiden } from "./utility/hiden";

type ResponsiveGridProps = Padding & Margin & Hiden;

type ButtonProps = {
  className?: string;
  children?: string;
  fullwidth?: boolean;
  disabledUpperCase?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  iconStart?: React.ReactElement;
  iconEnd?: React.ReactElement;
  size?: "medium" | "small" | "large";
  variant?: "contained" | "outlined" | "text";
  type?: "button" | "submit";
  isLoading?: boolean;
} & Padding &
  Margin &
  Responsive<ResponsiveGridProps>;

export const Button = ({
  className,
  children,
  fullwidth,
  onClick,
  disabled,
  iconEnd,
  iconStart,
  size,
  variant,
  type,
  isLoading,
  ...otherProps
}: ButtonProps) => {
  const { accentColor, buttonActive, pageBackground } = getColors();

  return (
    <StyledButton
      type={type}
      className={className}
      onClick={() => onClick && onClick()}
      color={accentColor}
      activeColor={buttonActive}
      textBackground={pageBackground}
      fullwidth={fullwidth}
      disabled={disabled}
      size={size}
      variant={variant}
      {...paddingProps(otherProps)}
      {...marginProps(otherProps)}
      {...getResponsiveProps(otherProps)}
    >
      {iconStart || iconEnd ? (
        <StyledFlex>
          {iconStart && !isLoading ? (
            <StyledIconStart>{iconStart}</StyledIconStart>
          ) : (
            <></>
          )}
          {isLoading ? (
            <StyledSpinerIcon
              {...(variant && variant !== "contained"
                ? { color: accentColor }
                : {})}
            />
          ) : (
            children?.toLocaleUpperCase()
          )}
          {iconEnd && !isLoading ? (
            <StyledIconEnd>{iconEnd}</StyledIconEnd>
          ) : (
            <></>
          )}
        </StyledFlex>
      ) : isLoading ? (
        <StyledSpinerIcon
          {...(variant && variant !== "contained"
            ? { color: accentColor }
            : {})}
        />
      ) : (
        children?.toLocaleUpperCase()
      )}
    </StyledButton>
  );
};

type StyledButtonProps = {
  color?: string;
  activeColor?: string;
  textBackground?: string;
  fullwidth?: boolean;
  disabled?: boolean;
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
} & Padding &
  Margin &
  Hiden &
  Responsive<ResponsiveGridProps>;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledIconStart = styled.div`
  margin-right: 12px;
  display: flex;
`;
const StyledIconEnd = styled.div`
  margin-left: 12px;
  display: flex;
`;
const StyledSpinerIcon = styled(SpinerIcon)<{ color?: string }>`
  rect {
    fill: ${(props) => props.color};
  }
`;

const StyledButton = styled.button<StyledButtonProps>`
  background-color: ${(props) => props.color};
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: white;
  svg path {
    fill: white !important;
  }
  padding: 12px 32px;
  cursor: pointer;
  width: ${(props) => (props.fullwidth ? "100%" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  &:hover {
    background-color: ${(props) => props.activeColor};
  }
  &:active {
    background-color: ${(props) => props.activeColor};
  }
  ${(props) =>
    props.size === "large"
      ? `
          font-size: 14px;
          padding: 16px 32px;
        `
      : ""};
  ${(props) =>
    props.size === "small"
      ? `
          font-size: 10px;
          padding: 8px 32px;
        `
      : ""};
  ${(props) =>
    props.variant === "outlined"
      ? `
          background-color: transparent;
          padding: 11px 31px;
          color: ${props.color};
          svg path {
            fill: ${props.color} !important;
          }
          border: 1px solid ${props.color};
          &:hover {
            background-color: transparent;
            color: ${props.activeColor};
            svg path {
              fill: ${props.activeColor} !important;
            }
            border: 1px solid ${props.activeColor};
          }
          &:active {
            background-color: transparent;
            color: ${props.activeColor};
            svg path {
              fill: ${props.activeColor} !important;
            }
            border: 1px solid ${props.activeColor};
          }
          ${props.size === "large" ? "padding: 15px 31px;" : ""};
          ${props.size === "small" ? "padding: 7px 31px;" : ""};
        `
      : ""};
  ${(props) =>
    props.variant === "text"
      ? `
          background-color: transparent;
          color: ${props.color};
          svg path {
            fill: ${props.color} !important;
          }
          &:hover {
            color: ${props.activeColor};
            svg path {
              fill: ${props.activeColor} !important;
            }
            background-color: ${props.textBackground};
          }
          &:active {
            color: ${props.activeColor};
            svg path {
              fill: ${props.activeColor} !important;
            }
            background-color: ${props.textBackground};
          }
        `
      : ""};

  ${(props) =>
    [
      paddingStyle,
      marginStyle,
      getResponsiveStyle<ResponsiveGridProps>([
        paddingStyle,
        marginStyle,
        hiddenStyle,
      ]),
    ]
      .map((fn) => fn(props))
      .join("")}
`;
