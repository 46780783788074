import React from "react";

import { ReactComponent as SquareCheckIcon } from "utils/img/square-check.svg";

import { Button } from "UI/Button";
import { Box } from "UI/Box";
import { Typography } from "UI/Typography";
import { Modal } from "UI/Modal";

type Props = {
  title: string;
  buttonText: string;
  handleClose?: () => void;
  handleClick: () => void;
};

export const ModalSuccess = (props: Props): React.ReactElement => {
  const { title, buttonText, handleClick, handleClose } = props;

  return (
    <Modal
      open
      hideHeader
      maxHeight="auto"
      width={370}
      onClose={() => handleClose && handleClose()}
    >
      <Box p={27} style={{ textAlign: "center" }}>
        <Box
          flex
          alignItems="center"
          justifyContent="center"
          style={{
            height: 84,
            width: 84,
            background: "rgba(62, 169, 89, 0.1)",
            margin: "auto",
          }}
        >
          <SquareCheckIcon />
        </Box>
        <Typography variant="h4" mt={24}>
          {title}
        </Typography>
        <Button mt={24} size="small" fullwidth onClick={() => handleClick()}>
          {buttonText}
        </Button>
      </Box>
    </Modal>
  );
}
