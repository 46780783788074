type Direction = "row" | "column" | "column-reverse";

export type FlexDirection = {
  direction?: Direction;
};

export function flexDirectionProps(props: FlexDirection) {
  return (({ direction }) => ({
    direction,
  }))(props);
}

export function flexDirectionStyle(props: FlexDirection) {
  return props.direction ? `flex-direction: ${props.direction};` : "";
}
