import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { TransferFormValues } from "pages/w2w/W2w";
import { Alert } from "UI/Alert";
import { Box } from "UI/Box";
import { Checkbox } from "UI/Form/Checkbox";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { ReactComponent as Warning } from "utils/img/warning.svg";
import { useCurrencyIcon } from "utils/useStyle";

export const ReceiverById = ({
  currency,
  amountRate,
  rate,
  form,
}: {
  currency: { from: string | null; to: string | null };
  amountRate: number | string;
  rate: null | number;
  form: FormikProps<TransferFormValues>;
}) => {
  const { t } = useTranslation();
  const fromCurrencyIcon = useCurrencyIcon(currency.from || "");
  const toCurrencyIcon = useCurrencyIcon(currency.to || "");

  return (
    <Grid
      container
      style={{ background: "white" }}
      p={16}
      responsive={{ sm: { mx: 16 }, md: { mx: 24 } }}
    >
      <Grid item sm={12} pb={16}>
        <Typography variant="body" fontWeight="bold">
          {t("Получатель")}
        </Typography>
      </Grid>
      <Grid item sm="fill">
        <Grid container hSpace={24}>
          <Grid item sm={12} xl={5}>
            <TextInput
              mask="0000000000000000000000000000000000000"
              fullwidth
              placeholder={t("ID получателя")}
              mb={24}
              value={form.values.receiverWalletId}
              onChange={(v) => form.setFieldValue("receiverWalletId", v)}
              error={
                form.touched.receiverWalletId &&
                Boolean(form.errors.receiverWalletId)
              }
              helperText={
                form.touched.receiverWalletId && form.errors.receiverWalletId
              }
            />
            {amountRate && rate ? (
              <Alert color="green" mb={24}>
                <Grid container hSpace={10}>
                  <Grid item>
                    <Warning />
                  </Grid>
                  <Grid item>
                    {t("Сумма зачисления")} <br />
                    {fromCurrencyIcon}&nbsp;{amountRate}
                    {rate !== 1 ? (
                      <>
                        &nbsp;{t("по курсу")} {toCurrencyIcon}&nbsp;{rate}
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Alert>
            ) : null}
            <Box>
              <Typography fontSize={16} fontWeight="bold" variant="body" pb={8}>
                <Checkbox
                  label={t("Сохранить кошелек получателя в часто используемые")}
                  value={form.values.saveWallet}
                  onChange={(v) => form.setFieldValue("saveWallet", v)}
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item sm="fill">
            <TextInput
              fullwidth
              placeholder={t("Название кошелька получателя")}
              value={form.values.walletName}
              onChange={(v) => form.setFieldValue("walletName", v)}
              error={form.errors.walletName}
              helperText={form.errors.walletName}
              mb={24}
            />
            <TextInput
              fullwidth
              placeholder={t("Комментарий")}
              value={form.values.comment}
              onChange={(v) => form.setFieldValue("comment", v)}
              error={form.errors.comment}
              helperText={form.errors.comment}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
