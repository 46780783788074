import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AdditionalPayoutsField } from "api/baseAPI/payouts";
import { Alert } from "UI/Alert";
import { Box } from "UI/Box";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { ReactComponent as InfoIcon } from "./icons/Info.svg";

interface FioRowProps {
  values: { first_name: string; last_name: string; middle_name: string };
  errors: {
    first_name: string | undefined;
    last_name: string | undefined;
    middle_name: string | undefined;
  };
  setFieldValue: (field: string, value: string) => void;
  fields?: AdditionalPayoutsField[] | null;
  setFieldTouched?: (field: string) => void;
}

export const FioRow = ({
  values,
  setFieldValue,
  errors,
  fields,
  setFieldTouched,
}: FioRowProps) => {
  const { t } = useTranslation();

  const isLimitText = useMemo(
    () =>
      !!fields?.find((item) => item.gate_key === "first_name") &&
      !!fields?.find((item) => item.gate_key === "last_name") &&
      !!fields?.find((item) => item.gate_key === "middle_name"),
    [fields]
  );

  return (
    <Grid container>
      {!isLimitText ? (
        <Grid item sm={12} mb={15}>
          <Alert color="green">
            <Box flex alignItems="center">
              <InfoIcon style={{ marginRight: "5px" }} />
              {t(
                "При отправке суммы более чем {{fioLimit}} необходимо ввести ФИО получателя",
                { fioLimit: "30000" }
              )}
            </Box>
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item sm={12}>
        <Grid container hSpace={10}>
          {!fields?.find((item) => item.gate_key === "last_name") ? (
            <Grid item sm={12} md={4}>
              <TextInput
                placeholder={t("Фамилия *")}
                value={values.last_name}
                onBlur={() => {
                  if (setFieldTouched) setFieldTouched(`last_name`);
                }}
                onChange={(value) =>
                  setFieldValue(
                    `last_name`,
                    value
                      .replace(/[^a-zA-Zа-яА-ЯёЁєЄіІїЇґҐ]+/g, "")
                      .slice(0, 20)
                  )
                }
                error={!!errors.last_name}
                helperText={errors.last_name || ""}
                size="small"
              />
            </Grid>
          ) : (
            <></>
          )}

          {!fields?.find((item) => item.gate_key === "first_name") ? (
            <Grid item sm={12} md={4}>
              <TextInput
                placeholder={t("Имя *")}
                value={values.first_name}
                onBlur={() => {
                  if (setFieldTouched) setFieldTouched(`first_name`);
                }}
                onChange={(value) =>
                  setFieldValue(
                    `first_name`,
                    value
                      .replace(/[^a-zA-Zа-яА-ЯёЁєЄіІїЇґҐ]+/g, "")
                      .slice(0, 20)
                  )
                }
                error={!!errors.first_name}
                helperText={errors.first_name || ""}
                size="small"
              />
            </Grid>
          ) : (
            <></>
          )}

          {!fields?.find((item) => item.gate_key === "middle_name") ? (
            <Grid item sm={12} md={4}>
              <TextInput
                placeholder={t("Отчество")}
                value={values.middle_name}
                onChange={(value) =>
                  setFieldValue(
                    `middle_name`,
                    value
                      .replace(/[^a-zA-Zа-яА-ЯёЁєЄіІїЇґҐ]+/g, "")
                      .slice(0, 20)
                  )
                }
                error={!!errors.middle_name}
                helperText={errors.middle_name || ""}
                size="small"
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
