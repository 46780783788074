import { Page } from "./invoicing";
import { baseSplitApi } from "./index";

export interface ProductItem {
  id: number;
  name: string;
  quantity: string;
  currency: string;
  cost: number;
  description: string;
}

export interface SocInvoiceDataItem {
  sender_full_name: string;
  sender_phone_number: string;
  sender_email: string;
  receiver_full_name: string;
  receiver_phone_number: string;
  receiver_email: string;
  page: Page;
  id: number;
  wallet: { id: number; name: string };
  products: ProductItem[];
}
interface SocInvoiceDataCreate {
  page: number;
  sender_full_name: string;
  sender_phone_number: string;
  sender_email: string;
  receiver_full_name: string;
  receiver_phone_number: string;
  receiver_email: string;
}
interface ProductCreate {
  page: number;
  name: string;
  quantity: string;
  currency: string;
  cost: number;
  description: string;
}

export type SocInvoiceDataQueryParams = {
  page: string;
  page_size: string;
  search: string;
};

export const socInvoiceApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getSocInvoiceDatas: builder.query<
      { count: number; results: SocInvoiceDataItem[] },
      SocInvoiceDataQueryParams & { page__point: string }
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/invoicing/soc-invoice-data/?${query.toString()}`;
      },
      providesTags: ["SocInvoiceDatas"],
    }),
    getSocInvoiceData: builder.query<SocInvoiceDataItem, { id: number }>({
      query: ({ id }) => `/invoicing/soc-invoice-data/${id}/`,
      providesTags: ["SocInvoiceData"],
    }),
    createSocInvoiceData: builder.mutation<
      SocInvoiceDataItem,
      SocInvoiceDataCreate
    >({
      query: (data) => ({
        url: "/invoicing/soc-invoice-data/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SocInvoiceDatas", "SocInvoiceData"],
    }),
    updateSocInvoiceData: builder.mutation<
      SocInvoiceDataItem,
      SocInvoiceDataCreate & { id: number }
    >({
      query: ({ id, ...data }) => ({
        url: `/invoicing/soc-invoice-data/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["SocInvoiceDatas", "SocInvoiceData"],
    }),
    deleteSocInvoiceData: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/invoicing/soc-invoice-data/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["SocInvoiceDatas", "SocInvoiceData"],
    }),
    createInvoicingProduct: builder.mutation<ProductItem, ProductCreate>({
      query: (data) => ({
        url: "/invoicing/page-products/",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetSocInvoiceDatasQuery,
  useLazyGetSocInvoiceDataQuery,
  useCreateSocInvoiceDataMutation,
  useUpdateSocInvoiceDataMutation,
  useDeleteSocInvoiceDataMutation,

  useCreateInvoicingProductMutation,
} = socInvoiceApi;
