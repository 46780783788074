import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { PageTransactionsQueryParams } from "api/baseAPI/invoicing";
import { DateSelector } from "components/RangeSelector/DateSelector";
import { Select } from "UI/Form/Select";
import { Grid } from "UI/Grid";
import { IUseUrlQueryResult } from "utils/url";

export const PageTransactionsFiltersRow = ({
  queryParams,
  querySetters,
}: IUseUrlQueryResult<PageTransactionsQueryParams>) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      my={24}
      style={{
        justifyContent: "space-between",
      }}
      responsive={{
        sm: { direction: "column-reverse" },
        md: { direction: "row" },
      }}
      vSpace={12}
    >
      <Grid item sm={12} md={5} xxl={3}>
        <Select
          value={queryParams.status}
          onChange={(val) => querySetters.set_status(val)}
          label={t("Статус")}
          placeholder={t("Выберите")}
          size="small"
          options={[
            { value: "", label: t("Все статусы") },
            { value: "1", label: t("Успешная") },
            { value: "0", label: t("Неуспешная") },
            { value: "-1", label: t("В обработке") },
          ]}
        />
      </Grid>
      <Grid item sm={12} md={6} xxl={5}>
        <DateSelector
          label={t("Период")}
          start={
            queryParams.created_at__gte
              ? DateTime.fromISO(queryParams.created_at__gte)
              : null
          }
          end={
            queryParams.created_at__lte
              ? DateTime.fromISO(queryParams.created_at__lte)
              : null
          }
          set_start={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__gte(val);
          }}
          set_end={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__lte(val);
          }}
          right
        />
      </Grid>
    </Grid>
  );
};
