import React, { ChangeEvent } from "react";
import { Button } from "UI/Button";

interface Props {
  handleFile: (files: File) => void;
  title: string;
}

const ButtonFileUploader = ({ handleFile, title }: Props) => {
  const hiddenFileInput = React.createRef<HTMLInputElement>();

  const handleClick = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = e.target.files?.[0];
    if (fileUploaded) handleFile(fileUploaded);
  };
  return (
    <>
      <Button size="small" onClick={handleClick}>
        {title}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};
export default ButtonFileUploader;
