import React from "react";
import styled from "styled-components";
import { getColors } from "utils/getColors";
import { marginProps, Margin, marginStyle } from "./utility/maring";
import { Padding, paddingProps, paddingStyle } from "./utility/padding";

type IconButtonProps = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactElement;
  size?: "medium" | "small" | "large";
  variant?: "contained" | "outlined";
} & Padding &
  Margin;

export const IconButton = ({
  className,
  onClick,
  disabled,
  size,
  variant,
  children,
  ...otherProps
}: IconButtonProps) => {
  const { accentColor, buttonActive, pageBackground } = getColors();

  return (
    <StyledButton
      className={className}
      onClick={() => onClick && onClick()}
      color={accentColor}
      activeColor={buttonActive}
      textBackground={pageBackground}
      disabled={disabled}
      size={size}
      variant={variant}
      {...paddingProps(otherProps)}
      {...marginProps(otherProps)}
    >
      {children}
    </StyledButton>
  );
}

type StyledButtonProps = {
  color?: string;
  activeColor?: string;
  textBackground?: string;
  fullwidth?: boolean;
  disabled?: boolean;
  size?: IconButtonProps["size"];
  variant?: IconButtonProps["variant"];
} & Padding &
  Margin;

const StyledButton = styled.button<StyledButtonProps>`
  background-color: ${(props) => props.color};
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  svg path {
    fill: white !important;
  }
  display: flex;
  align-items: center;
  padding: 13px 15px;
  cursor: pointer;
  width: auto;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  &:hover {
    background-color: ${(props) => props.activeColor};
  }
  &:active {
    background-color: ${(props) => props.activeColor};
  }
  ${(props) =>
    props.variant  ===  "outlined"
      ? `
          background-color: transparent;
          svg path {
            fill: #838383 !important;
          }
          border: 1px solid #838383;
          &:hover {
            background-color: ${props.activeColor};
            svg path {
              fill: white !important;
            }
            border: 1px solid ${props.activeColor};
          }
          &:active {
            background-color: ${props.activeColor};
            svg path {
              fill: white !important;
            }
            border: 1px solid ${props.activeColor};
          }
        `
      : ""};

  ${(props) => [marginStyle(props), paddingStyle(props)].join("")}
`;
