import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Box } from "UI/Box";
import { Divider } from "UI/Divider";
import { Typography } from "UI/Typography";
import { useStyle } from "hooks/useStyle";
import { getColors } from "utils/getColors";

const host = window.location.host.split(".");
host[0] = "docs";

export const Footer = (): React.ReactElement => {
  const { t } = useTranslation();
  const style = useStyle();
  const { backgroundColor } = getColors();

  return (
    <StyleWrapper>
      <Box
        flex
        responsive={{ md: { direction: "row" }, sm: { direction: "column" } }}
      >
        {style.logo ? <StyledLogo src={style.logo} /> : <></>}
        <Box responsive={{ md: { ml: 120, mt: 0 }, sm: { ml: 0, mt: 24 } }}>
          <Typography
            variant="h6"
            mb={12}
            style={{ color: "white", opacity: 0.7 }}
          >
            {t("Контакты")}
          </Typography>
          <Typography variant="h6" mb={12} style={{ color: "white" }}>
            admin@panel.com
          </Typography>
          <Typography variant="h6" mb={12} style={{ color: "white" }}>
            +380 98 098 77 77
          </Typography>
          <Typography variant="h6" mb={40} style={{ color: "white" }}>
            +380 93 883 56 65
          </Typography>
        </Box>
      </Box>
      <Divider direction="vertical" color="rgba(200, 207, 220, 0.5)" />
      <Box flex justifyContent="space-between" mt={12}>
        <a
          href={`https://${host.join(".")}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Typography variant="subtitle3" style={{ color: "white" }}>
            Cookie Policy
          </Typography>
        </a>
        <a
          href={`https://${host.join(".")}`}
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Typography variant="subtitle3" style={{ color: "white" }}>
            Privacy Policy
          </Typography>
        </a>
      </Box>
      <StyledEllipseTop />
      <StyledEllipseBottom background={backgroundColor} />
    </StyleWrapper>
  );
};
const StyledLogo = styled.img`
  max-height: 26px;
  width: fit-content;
`;
const StyleWrapper = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: #1b1b22;
  padding: 32px 24px 12px 24px;
`;
const StyledEllipseTop = styled.div`
  position: absolute;
  width: 152px;
  height: 156px;
  border-radius: 50%;
  right: 50px;
  top: -100px;
  z-index: 12;
  background: rgba(255, 255, 255, 0.34);
  filter: blur(130.785px);
`;
const StyledEllipseBottom = styled.div`
  position: fixed;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  left: 22px;
  bottom: -60px;
  z-index: 12;

  background: ${(props: { background: string }) => props.background};
  filter: blur(130.785px);
`;
