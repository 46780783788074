import styled from "styled-components";

import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";

interface Props {
  open: boolean;
  onClick: () => void;
}

export const IconCellOpen = ({ open, onClick }: Props) => (
    <StyledIcon onClick={onClick}>
      <StyledArrowUpDown open={open} />
    </StyledIcon>
  )

const StyledIcon = styled.div`
  padding: 4px;
  cursor: pointer;
  margin-right: 12px;
  display: flex;
  align-items: center;
  svg path {
    fill: #838383 !important;
  }
`;

const StyledArrowUpDown = styled(ArrowUpDown)`
  ${(props: { open: boolean }) =>
    props.open ? `transform: rotate(180deg);` : ""};
`;
