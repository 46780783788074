import { FormikErrors, FormikTouched } from "formik";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { v4 as uuid4 } from "uuid";
import { Box } from "UI/Box";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { LinearProgress } from "components/LinearProgress";
import { useStyle } from "hooks/useStyle";
import { getColors } from "utils/getColors";
import getObjectsFromFile from "utils/getObjectsFromFile";
import { ReactComponent as EmailIcon } from "utils/img/email.svg";
import { ReactComponent as InfoIcon } from "utils/img/info.svg";
import { ReactComponent as UPIFileIcon } from "utils/img/upi-file.svg";
import { useSuccessNotification } from "utils/notificationWrappers";
import {
  handleChangeNumber,
  numberForInput,
  visibleNumber,
} from "utils/numbers";
import { useCurrencyIcon } from "utils/useStyle";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { FioRow } from "pages/payout/parts/FioRow";
import { ReactComponent as DeletePayoutIcon } from "pages/payout/parts/icons/delete-payout.svg";
import { IPayoutMethod } from "pages/payout/logic/payoutMethods";
import {
  PAYOUT_FIO_LIMIT,
  PayoutData,
  PayoutFormData,
  randomInt,
} from "pages/payout/logic/payoutsCreateLogic";
import {
  PayoutData as UpiPayoutData,
  usePayoutsFromTemplate,
  useUPIPayoutForm,
} from "pages/payout/logic/upiform";
import { PayoutFormWrapper } from "./components/PayoutFormWrapper";

export const UPIPayoutForm = ({
  payoutMethod,
}: {
  payoutMethod: IPayoutMethod;
}) => {
  const { t } = useTranslation();
  const { accentColor } = getColors();
  const style = useStyle();

  const {
    form,
    isSuccess,
    needSplitPayouts,
    setNeedSplitPayouts,
    showAddToTemplateModal,
    setShowAddToTemplateModal,
    addRow,
    deleteRow,
    handleSplitPayouts,
    isSplitting,
  } = useUPIPayoutForm(payoutMethod);

  useSuccessNotification([{ isSuccess }]);

  useTranslateFormErrors(form);

  const subscriber = {
    setPayouts: () => {},
    setSaveToTemplate: (isSave: boolean) => {
      form.validateForm().then((err: FormikErrors<PayoutFormData>) => {
        if (isEmpty(err)) {
          setShowAddToTemplateModal(isSave);
        } else {
          form.setTouched(err as FormikTouched<PayoutFormData>);
          form.setErrors(err);
        }
      });
    },
    setIsSubmitting: () => {},
    setDelay: (delay: number) => form.setFieldValue("delay", delay, true),
    setFormErrors: () => {},
  };

  payoutMethod?.addSubscriber("payoutForm", subscriber);

  useEffect(() => {
    payoutMethod.notifyIsSubmiting(form.isSubmitting);
  }, [form.isSubmitting]);

  useEffect(() => {
    payoutMethod.notifyFormUpdate(form.values.payouts);
  }, [form.values.payouts]);

  payoutMethod.resetForm = form.resetForm;
  payoutMethod.handleSubmit = form.handleSubmit;
  payoutMethod.handleValidate = () =>
    new Promise((resolve) => {
      form.validateForm().then((errors: FormikErrors<PayoutFormData>) => {
        if (isEmpty(errors)) {
          resolve(true);
        } else {
          form.setTouched(errors as FormikTouched<PayoutFormData>);
          form.setErrors(errors);
          resolve(false);
        }
      });
    });
  payoutMethod.handleSaveToTemplate = (save: boolean) => {
    form.setFieldValue("saveCardsToTemplate", save);
  };

  payoutMethod.errors = form.errors;
  payoutMethod.values = form.values;

  const { isLoading } = usePayoutsFromTemplate({
    payoutMethod,
    cb: (payouts) => {
      form.setFieldValue("payouts", payouts);
    },
  });

  const handleUpload = (
    files: FileList | null,
    onSuccess: (lines: Record<string, PayoutData>) => void,
    onError: (error: string) => void
  ) => {
    if (files) {
      const file = files[0];

      getObjectsFromFile(file)
        .then((object) => {
          const { commissionsData } = payoutMethod;

          if (commissionsData === null) {
            return null;
          }

          const { max_amount } = commissionsData;
          const lines: Record<string, PayoutData> = {};

          object.forEach((line) => {
            const customer_account = line[0] || "";
            let amount = line[1] || "";
            const description = line[2] || "";
            const IFSC = line[3] || "";
            const AccHolderName = line[4] || "";
            const BeneficiaryMobile = line[5] || "";
            const BeneficiaryEmail = line[6] || "";
            const BeneficiaryAddress = line[7] || "";
            const first_name = line[8] || "";
            const middle_name = line[9] || "";
            const last_name = line[10] || "";

            if (max_amount && Number(amount) <= max_amount) {
              lines[uuid4()] = {
                customer_account: String(customer_account),
                amount: handleChangeNumber(
                  String(amount)
                    .replace(/[^0-9.]/g, "")
                    .slice(0, 13)
                ),
                IFSC: String(IFSC),
                AccHolderName: String(AccHolderName),
                BeneficiaryMobile: String(BeneficiaryMobile),
                BeneficiaryEmail: String(BeneficiaryEmail),
                BeneficiaryAddress: String(BeneficiaryAddress),
                description: String(description),
                first_name: String(first_name),
                middle_name: String(middle_name),
                last_name: String(last_name),
              };
            } else {
              let part_index = 1;

              while (max_amount && Number(amount) > max_amount) {
                const part_amount = randomInt(max_amount * 0.75, max_amount);

                lines[uuid4()] = {
                  customer_account: String(customer_account),
                  amount: String(part_amount),
                  IFSC: String(IFSC),
                  AccHolderName: String(AccHolderName),
                  BeneficiaryMobile: String(BeneficiaryMobile),
                  BeneficiaryEmail: String(BeneficiaryEmail),
                  BeneficiaryAddress: String(BeneficiaryAddress),
                  description: description
                    ? `${description}; part ${part_index}`
                    : `part ${part_index}`,
                  first_name: String(first_name),
                  middle_name: String(middle_name),
                  last_name: String(last_name),
                };

                amount = `${Number((Number(amount) - part_amount).toFixed(2))}`;
                part_index += 1;
              }

              lines[uuid4()] = {
                customer_account: String(customer_account),
                amount: String(amount),
                IFSC: String(IFSC),
                AccHolderName: String(AccHolderName),
                BeneficiaryMobile: String(BeneficiaryMobile),
                BeneficiaryEmail: String(BeneficiaryEmail),
                BeneficiaryAddress: String(BeneficiaryAddress),
                description: description
                  ? `${description}; part ${part_index}`
                  : `part ${part_index}`,
                first_name: String(first_name),
                middle_name: String(middle_name),
                last_name: String(last_name),
              };
            }
            return line;
          });

          onSuccess(lines);
        })
        .catch(() => {
          onError(t("Некорректный формат файла!"));
        });
    }
  };

  const currencyIcon = useCurrencyIcon(payoutMethod.currency || "UAH");
  const commissions = payoutMethod.getCommissions(form.values.payouts);

  if (payoutMethod.isLoaded  ===  false || isLoading) {
    return (
      <Box py={20}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <PayoutFormWrapper
      payout={payoutMethod}
      payoutsData={form.values.payouts}
      resetForm={form.resetForm}
      handleSplitPayouts={handleSplitPayouts}
      isSplitting={isSplitting}
      setNeedSplitPayouts={setNeedSplitPayouts}
      needSplitPayouts={needSplitPayouts}
      setShowAddToTemplateModal={setShowAddToTemplateModal}
      showAddToTemplateModal={showAddToTemplateModal}
      addRow={addRow}
      handleUpload={(files) =>
        handleUpload(
          files,
          (lines) =>
            form.setValues({
              ...form.values,
              payouts: lines as unknown as Record<string, UpiPayoutData>,
            }),
          (error) => {
            console.error(error);
          }
        )
      }
    >
      <>
        <Box mb={24}>
          <Grid container>
            <>
              {(
                Object.entries(form.values.payouts) as [
                  id: string,
                  payout: PayoutData
                ][]
              ).map(([id, payout], index) => (
                <Grid
                  item
                  sm={12}
                  key={id}
                  mb={15}
                  px={24}
                  pt={24}
                  style={{ background: "white" }}
                >
                  <Grid container mb={24}>
                    <Grid item sm={12}>
                      <Box
                        flex
                        justifyContent="space-between"
                        alignItems="center"
                        mb={10}
                      >
                        <Typography variant="body">
                          {`${t("Платеж")} № ${index + 1}`}
                        </Typography>
                        <Box flex alignItems="center">
                          <Typography
                            px={5}
                            py={3}
                            mr={15}
                            fontSize={14}
                            style={{
                              color: "#2C60B0",
                              backgroundColor: "#EAEFF7",
                            }}
                          >
                            {t("Комиссия")}&nbsp;
                            {currencyIcon}
                            {visibleNumber(commissions[id].point)}
                          </Typography>

                          {Object.keys(form.values.payouts).length > 1 ? (
                            <DeletePayoutIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteRow(id)}
                            />
                          ) : null}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item sm={12} mb={12}>
                      <Grid
                        container
                        hSpace={10}
                        vSpace={10}
                        justifyContent="space-between"
                      >
                        <Grid item sm={12} md={4}>
                          <TextInput
                            placeholder="0000000000000"
                            value={form.values.payouts[id].customer_account}
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].customer_account`,
                                true
                              );
                            }}
                            name="customer_account"
                            error={
                              !!(form.touched.payouts?.[id]?.customer_account &&
                              form.errors.payouts?.[id]?.customer_account)
                            }
                            helperText={
                              t<any>(
                                form.touched.payouts?.[id]?.customer_account &&
                                  form.errors.payouts?.[id]?.customer_account &&
                                  form.errors.payouts?.[id]?.customer_account
                              ) || ""
                            }
                            onChange={(value) => {
                              form.setFieldValue(
                                `payouts[${id}].customer_account`,
                                value.substring(0, 20)
                              );
                            }}
                            iconStart={<UPIFileIcon />}
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12} md={3}>
                          <TextInput
                            placeholder={t("Введите сумму")}
                            // mask="0000000000"
                            value={numberForInput(payout.amount)}
                            name="amount"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].amount`,
                                handleChangeNumber(
                                  value.replace(/[^0-9.]/g, "").slice(0, 13)
                                )
                              )
                            }
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].amount`,
                                true
                              );
                            }}
                            error={
                              !!(form.touched.payouts?.[id]?.amount &&
                              form.errors.payouts?.[id]?.amount)
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.amount &&
                                form.errors.payouts?.[id]?.amount &&
                                form.errors.payouts?.[id]?.amount) ||
                              ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12} md={5}>
                          <TextInput
                            placeholder={t("Примечание")}
                            value={payout.description}
                            name="description"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].description`,
                                value
                              )
                            }
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(
                                `payouts[${id}].description`,
                                true
                              );
                            }}
                            error={
                              !!(form.touched.payouts?.[id]?.description &&
                              form.errors.payouts?.[id]?.description)
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.description &&
                                form.errors.payouts?.[id]?.description &&
                                form.errors.payouts?.[id]?.description) ||
                              ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12} md={7}>
                          <TextInput
                            placeholder={t("Код бенефициара")}
                            value={payout.IFSC}
                            name="tax_id"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].IFSC`,
                                value.substring(0, 11)
                              )
                            }
                            onBlur={(e) => {
                              form.handleBlur(e);
                              form.setFieldTouched(`payouts[${id}].IFSC`, true);
                            }}
                            error={
                              !!(form.touched.payouts?.[id]?.IFSC &&
                              form.errors.payouts?.[id]?.IFSC)
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.IFSC &&
                                form.errors.payouts?.[id]?.IFSC &&
                                form.errors.payouts?.[id]?.IFSC) ||
                              ""
                            }
                            size="small"
                            iconEnd={
                              <div>
                                <InfoIcon data-tip data-for="ifsc" />
                                <ReactTooltip
                                  id="ifsc"
                                  border
                                  place="top"
                                  type="dark"
                                  effect="solid"
                                  borderColor={accentColor}
                                >
                                  <span>
                                    {t(
                                      "Код бенефициара индийской финансовой системы"
                                    )}
                                  </span>
                                </ReactTooltip>
                              </div>
                            }
                          />
                        </Grid>
                        <Grid item sm={12} md={5}>
                          <TextInput
                            placeholder={t("Имя счета получателя")}
                            value={payout.AccHolderName}
                            name="AccHolderName"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].AccHolderName`,
                                value
                              )
                            }
                            error={
                              !!(form.touched.payouts?.[id]?.AccHolderName &&
                              form.errors.payouts?.[id]?.AccHolderName)
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.AccHolderName &&
                                form.errors.payouts?.[id]?.AccHolderName &&
                                form.errors.payouts?.[id]?.AccHolderName) ||
                              ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <TextInput
                            placeholder="example@example.com"
                            value={payout.BeneficiaryEmail}
                            name="BeneficiaryEmail"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].BeneficiaryEmail`,
                                value
                              )
                            }
                            error={
                              !!(form.touched.payouts?.[id]?.BeneficiaryEmail &&
                              form.errors.payouts?.[id]?.BeneficiaryEmail)
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.BeneficiaryEmail &&
                                form.errors.payouts?.[id]?.BeneficiaryEmail &&
                                form.errors.payouts?.[id]?.BeneficiaryEmail) ||
                              ""
                            }
                            iconStart={<EmailIcon />}
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <TextInput
                            placeholder={t("Номер телефона")}
                            value={payout.BeneficiaryMobile}
                            name="BeneficiaryMobile"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].BeneficiaryMobile`,
                                value.replace(/[^0-9+]/g, "").slice(0, 13)
                              )
                            }
                            error={
                              !!(form.touched.payouts?.[id]?.BeneficiaryMobile &&
                              form.errors.payouts?.[id]?.BeneficiaryMobile)
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.BeneficiaryMobile &&
                                form.errors.payouts?.[id]?.BeneficiaryMobile &&
                                form.errors.payouts?.[id]?.BeneficiaryMobile) ||
                              ""
                            }
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <TextInput
                            placeholder={t("Адрес получателя")}
                            value={payout.BeneficiaryAddress}
                            name="BeneficiaryAddress"
                            onChange={(value) =>
                              form.setFieldValue(
                                `payouts[${id}].BeneficiaryAddress`,
                                value
                              )
                            }
                            error={
                              !!(form.touched.payouts?.[id]?.BeneficiaryAddress &&
                              form.errors.payouts?.[id]?.BeneficiaryAddress)
                            }
                            helperText={
                              (form.touched.payouts?.[id]?.BeneficiaryAddress &&
                                form.errors.payouts?.[id]?.BeneficiaryAddress &&
                                form.errors.payouts?.[id]
                                  ?.BeneficiaryAddress) ||
                              ""
                            }
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      {payoutMethod.currency &&
                      Number(payout.amount) >
                        Number(
                          PAYOUT_FIO_LIMIT[payoutMethod.currency.toLowerCase()]
                        ) &&
                      style.is_fc ? (
                        <FioRow
                          values={{
                            first_name: form.values.payouts[id].first_name,
                            last_name: form.values.payouts[id].last_name,
                            middle_name: form.values.payouts[id].middle_name,
                          }}
                          errors={{
                            first_name: form?.errors?.payouts?.[id]?.first_name,
                            last_name: form?.errors?.payouts?.[id]?.last_name,
                            middle_name:
                              form?.errors?.payouts?.[id]?.middle_name,
                          }}
                          setFieldValue={(field, value) =>
                            form.setFieldValue(`payouts[${id}].${field}`, value)
                          }
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          </Grid>
        </Box>
      </>
    </PayoutFormWrapper>
  );
}
