import { ReactElement } from "react";
import * as yup from "yup";
import { ReactComponent as SettlePayIcon } from "utils/img/settlepay.svg";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { SettlePayInput } from "pages/payout/logic/forms/components/inputs/SettlePayInput";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class SettlepayPayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "settlepay";

  kind: 3 = 3;

  name: string = "SettlePay";

  mobileName: string = "SettlePay";

  icon: ReactElement = (<SettlePayIcon />);

  csvExample: string | null = "/file-samples/sample-settlepay.csv";

  xlsxExample: string | null = "/file-samples/sample-settlepay.xlsx";

  get serviceId() {
    return (
      (this.pointManager?.service_config?.settlepay_payouts &&
        this.getService(
          this.pointManager?.service_config?.settlepay_payouts
        )) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <SettlePayInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .test(
      "isValid",
      "Необходимо ввести номер телефона или ID кошелька",
      (value: string | undefined): boolean => {
        if (Number(value)) {
          return value?.length  ===  13;
        } 
          return (
            value?.slice(0, 2)  ===  "SP" &&
            value.length >= 5 &&
            value.length <= 32
          );
        
      }
    )
    .required("Необходимо ввести номер телефона или ID кошелька");
}
