import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetStyleMutation } from "api/baseAPI/user";
import { setStyle } from "reducers/authSlice";

export default function getSettingStyles() {
  const dispatch = useDispatch();
  const [getStule, getStuleMeta] = useGetStyleMutation();

  useEffect(() => {
    getStule({ domain: window.location.host });
  }, []);

  useEffect(() => {
    if (getStuleMeta.isSuccess) {
      dispatch(
        setStyle({
          name: getStuleMeta.data?.name || "",
          description: getStuleMeta.data?.description || "",
          is_fc: getStuleMeta.data?.is_fc || false,
          guest_dashboard_available:
            getStuleMeta.data?.guest_dashboard_available || false,
          receipt_endpoint: getStuleMeta.data?.receipt_endpoint || "",
          helpcrunch_organization:
            getStuleMeta?.data?.helpcrunch_organization || "",
          helpcrunch_app_id: getStuleMeta?.data?.helpcrunch_app_id || "",
          logo: getStuleMeta.data?.style?.logo || "",
          font: getStuleMeta.data?.style?.font || "",
          accent_color: getStuleMeta.data?.style?.accent_color || "",
          background_color: getStuleMeta.data?.style?.background_color || "",
          background_image: getStuleMeta.data?.style?.background_image || "",
          soc_registration_available:
            getStuleMeta.data?.style?.soc_registration_available || false,
        })
      );
    }
  }, [getStuleMeta.isSuccess]);
}
