import { includes } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Switch } from "UI/Form/Switch";
import { Typography } from "UI/Typography";
import {
  useDeleteCustomizationRuleMutation,
  useGetCustomizationRulesQuery,
  useUpdateCustomizationRuleMutation,
} from "api/baseAPI/customizer";
import { useGetUsersMeCustomizationRulePermissionsQuery } from "api/baseAPI/user";
import { FilterBar } from "components/table/FilterBar";
import { RowStatusOnOff } from "components/table/RowStatusOnOff";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { ReactComponent as EyeIcon } from "utils/img/eye-full.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { ModalCustomizationRulePreview } from "./ModalCustomizationRulePreview";

export const CustomizationRules = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableCellTitles = [
    "ID",
    t("Название"),
    t("Светлая"),
    t("Темная"),
    t("Статус"),
  ];

  const [page, setPage] = useState<string>("1");
  const [pageSize, setPageSize] = useState<string>("10");
  const [search, setSearch] = useState<string>("");
  const [previewId, setPreviewId] = useState<number | null>(null);
  const selectedPointManager = useSelectedPointManager();
  const getCustomizationRulePermissions =
    useGetUsersMeCustomizationRulePermissionsQuery();

  const { data, isFetching, refetch } = useGetCustomizationRulesQuery({
    page,
    page_size: pageSize,
    search,
    point_id: selectedPointManager.point?.id,
  });

  const handleSetPage = (pageVal: number) => {
    setPage(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    setPage("1");
    setPageSize(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    setPage("1");
    setSearch(searchVal);
  };

  const [deleteCustomizationRule, deleteCustomizationRuleMeta] =
    useDeleteCustomizationRuleMutation();

  const handleDeleteCustomizationRule = (id: number) => {
    deleteCustomizationRule({ id });
  };

  const [updateCustomizationRule, updateCustomizationRuleMeta] =
    useUpdateCustomizationRuleMutation();

  useErrorNotification([
    deleteCustomizationRuleMeta,
    updateCustomizationRuleMeta,
  ]);
  useSuccessNotification([
    deleteCustomizationRuleMeta,
    updateCustomizationRuleMeta,
  ]);

  return (
    <Box pb={32} px={24}>
      {previewId && (
        <ModalCustomizationRulePreview
          onClose={() => setPreviewId(null)}
          ruleID={previewId}
        />
      )}
      <Typography variant="h5" mb={16}>
        {t("Правила отображения")}
      </Typography>
      <Table
        tableCellTitles={tableCellTitles}
        isLoading={isFetching || updateCustomizationRuleMeta.isLoading}
        filterBar={
          <FilterBar
            search={search}
            setSearch={handleSetSearch}
            createTitle={t("Добавить правило")}
            {...(includes(getCustomizationRulePermissions.data, "add")
              ? {
                  createPath: "/admin/customizer/customization-rule/create",
                }
              : {})}
            refetch={refetch}
          />
        }
        count={data ? data.count : 0}
        page={Number(page)}
        rowsPerPage={Number(pageSize)}
        setPage={handleSetPage}
        setRowsPerPage={handleSetRowsPerPage}
      >
        {data?.results.map((row) => (
          <StyledRow key={row.id}>
            <StyledCell>{row.id}</StyledCell>
            <StyledCell>{row.name}</StyledCell>
            <StyledCell>
              {row.light && (
                <>
                  {row.light.id} | {row.light.name}
                </>
              )}
            </StyledCell>
            <StyledCell>
              {row.dark && (
                <>
                  {row.dark.id} | {row.dark.name}
                </>
              )}
            </StyledCell>
            <StyledCell>
              <RowStatusOnOff status={row.is_active} />
            </StyledCell>
            <StyledCell key="actions" align="right">
              <Box
                flex
                alignItems="center"
                justifyContent="flex-end"
                nowrap
                mr={20}
              >
                <Switch
                  small
                  value={row.is_active}
                  onChange={(value) =>
                    updateCustomizationRule({
                      id: row.id,
                      is_active: value,
                    })
                  }
                />
                <StyledEyeIcon onClick={() => setPreviewId(row.id)} />
                <TableRowActions
                  actions={[
                    ...(includes(getCustomizationRulePermissions.data, "change")
                      ? [
                          <MenuItemAction
                            key={2}
                            onClick={() =>
                              navigate(
                                `/admin/customizer/customization-rule/${row.id}/edit`
                              )
                            }
                          >
                            <EditIcon style={{ width: "22px" }} /> &nbsp;
                            {t("Редактировать")}
                          </MenuItemAction>,
                        ]
                      : []),
                    ...(includes(getCustomizationRulePermissions.data, "delete")
                      ? [
                          <MenuItemAction
                            key={3}
                            onClick={() =>
                              handleDeleteCustomizationRule(row.id)
                            }
                          >
                            <DeleteIcon style={{ width: "22px" }} /> &nbsp;
                            {t("Удалить")}
                          </MenuItemAction>,
                        ]
                      : []),
                    ...(includes(getCustomizationRulePermissions.data, "view")
                      ? [
                          <MenuItemAction
                            key={3}
                            onClick={() => setPreviewId(row.id)}
                          >
                            <EyeIcon style={{ width: "22px" }} /> &nbsp;
                            {t("Просмотр правила")}
                          </MenuItemAction>,
                        ]
                      : []),
                  ]}
                />
              </Box>
            </StyledCell>
          </StyledRow>
        ))}
      </Table>
    </Box>
  );
};

const StyledEyeIcon = styled(EyeIcon)`
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
`;
