import { useMemo } from "react";
import styled from "styled-components";

export const getCurrencyIcon = (currency: string, ml?: string) => {
  const currencyIcons = {
    USD: <StyledP ml={ml}>&#36;</StyledP>,
    USDT: <StyledP ml={ml}>&#8366;</StyledP>,
    EUR: <StyledP ml={ml}>&#8364;</StyledP>,
    RUB: <StyledP ml={ml}>&#8381;</StyledP>,
    UAH: <StyledP ml={ml}>&#8372;</StyledP>,
    INR: <StyledP ml={ml}>&#8377;</StyledP>,
    KZT: <StyledP ml={ml}>&#8376;</StyledP>,
    AZN: <StyledP ml={ml}>&#8380;</StyledP>,
    KGS: <StyledP ml={ml}>&#13199;</StyledP>,
    TJS: <StyledP ml={ml}>&#84;&#74;&#83;</StyledP>,
    TMT: <StyledP ml={ml}>&#109;</StyledP>,
    PLN: <StyledP ml={ml}>&#122;&#322;</StyledP>,
    TRY: <StyledP ml={ml}>&#8356;</StyledP>,
    CLP: <StyledP ml={ml}>&#36;</StyledP>,
    MXN: <StyledP ml={ml}>&#36;</StyledP>,
    CZK: <StyledP ml={ml}>&#75;&#269;</StyledP>,
    HUF: <StyledP ml={ml}>&#70;&#116;</StyledP>,
    RON: <StyledP ml={ml}>&#108;&#101;&#105;</StyledP>,
    BRL: <StyledP ml={ml}>&#82;&#36;</StyledP>,
    BTC: <StyledP ml={ml}>&#x20BF;</StyledP>,
  };

  return (
    currencyIcons?.[currency as keyof typeof currencyIcons] || (
      <StyledP ml={ml}>{currency}</StyledP>
    )
  );
};

export const useCurrencyIcon = (currency: string) => useMemo(() => getCurrencyIcon(currency), [currency]);

const StyledP = styled.div<{ ml?: string }>`
  margin: 0;
  margin-left: ${(props) => (props.ml ? props.ml : "5px")};
  display: inline-block;
`;
