import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { getTimezone } from "utils/getTimezone";
import { RESPONSIVE_SIZES } from "utils/tools";
import { CalendarDay } from "./CalendarDay";
import { ReactComponent as ChevronLeft } from "./assets/chevron-left.svg";
import { ReactComponent as ChevronRight } from "./assets/chevron-right.svg";

export const Calendar = ({
  rangeStart,
  rangeEnd,
  minDate,
  type,
  onDateSet,
  decreeseMontHandler,
  increeseMonthHandler,
  displayedMonth,
  displayedYear,
}: {
  rangeStart: DateTime | null;
  rangeEnd: DateTime | null;
  minDate: DateTime | null;
  type: "start" | "end";
  onDateSet: (date: DateTime) => void;
  decreeseMontHandler?: () => void;
  increeseMonthHandler?: () => void;
  displayedMonth: number;
  displayedYear: number;
}): React.ReactElement => {
  const { t } = useTranslation();

  const MONTHS: { [key: number]: string } = {
    1: t("Январь"),
    2: t("Февраль"),
    3: t("Март"),
    4: t("Апрель"),
    5: t("Май"),
    6: t("Июнь"),
    7: t("Июль"),
    8: t("Август"),
    9: t("Сентябрь"),
    10: t("Октябрь"),
    11: t("Ноябрь"),
    12: t("Декабрь"),
  };

  const WEEK_DAYS: { [key: string]: string } = {
    1: t("пн"),
    2: t("вт"),
    3: t("ср"),
    4: t("чт"),
    5: t("пт"),
    6: t("сб"),
    7: t("вс"),
  };
  const { accentColor, pageBackground } = getColors();
  const timezone = getTimezone();
  const currentDate = DateTime.local().setZone(timezone);
  const currentYear = currentDate.year;
  const currentMonth = currentDate.month;
  const currentDay = currentDate.day;

  const {daysInMonth} = DateTime.local(displayedYear, displayedMonth);
  const firstDayNumber = DateTime.local(displayedYear, displayedMonth).weekday;

  // Handlers
  const selectDayHandler = (day: number) => {
    onDateSet(
      DateTime.local(displayedYear, displayedMonth, day, {
        zone: timezone,
      })
    );
  };

  return (
    <StyledCalendarWrapper>
      <StyledSelectWrapper>
        {type  ===  "start" ? (
          <StyledMonthNovigator
            disabled={
              (minDate || DateTime.local().minus({ year: 5 })) >
              DateTime.local(displayedYear, displayedMonth)
            }
            onClick={() => decreeseMontHandler && decreeseMontHandler()}
          >
            <ChevronLeft />
          </StyledMonthNovigator>
        ) : (
          <Typography> </Typography>
        )}
        <Typography>
          {MONTHS[displayedMonth]}&nbsp;{displayedYear}
        </Typography>
        {type  ===  "end" ? (
          <StyledMonthNovigator
            onClick={() => increeseMonthHandler && increeseMonthHandler()}
          >
            <ChevronRight />
          </StyledMonthNovigator>
        ) : (
          <Typography> </Typography>
        )}
      </StyledSelectWrapper>
      <StyledWeekNamesWrapper>
        {Array(7)
          .fill(null)
          .map((_, i) => (
            <div key={i}>{WEEK_DAYS[i + 1]}</div>
          ))}
      </StyledWeekNamesWrapper>

      <StyledDaysWrapper>
        {Array(firstDayNumber - 1)
          .fill(null)
          .map((_, i) => (
            <div key={i + 100} />
          ))}
        {Array(daysInMonth)
          .fill(null)
          .map((_, i) => {
            const selected =
              !!((rangeEnd &&
                displayedYear === rangeEnd.year &&
                displayedMonth === rangeEnd.month &&
                rangeEnd.day  ===  i + 1) ||
              (rangeStart &&
                displayedYear === rangeStart.year &&
                displayedMonth === rangeStart.month &&
                rangeStart.day  ===  i + 1));

            const current =
              !!(displayedYear === currentYear &&
              displayedMonth === currentMonth &&
              currentDay  ===  i + 1);

            let inRange = false;

            const displayedDay = DateTime.local(
              displayedYear,
              displayedMonth,
              i + 1,
              { zone: timezone }
            );

            if (rangeStart && rangeEnd) {
              if (rangeStart <= displayedDay && displayedDay <= rangeEnd) {
                inRange = true;
              }
            }

            let disabled = false;
            if (displayedDay > currentDate) {
              disabled = true;
            }
            if (minDate && displayedDay < minDate) {
              disabled = true;
            }

            return (
              <StyledCalendarDay
                key={i}
                selected={selected}
                current={current}
                inRange={inRange}
                onClick={() => selectDayHandler(i + 1)}
                disabled={disabled}
                background={pageBackground}
                rangeBackground={accentColor}
              >
                {i + 1}
              </StyledCalendarDay>
            );
          })}
      </StyledDaysWrapper>
    </StyledCalendarWrapper>
  );
};

const StyledCalendarWrapper = styled.div`
  padding: 2px;
  margin: 0;
  @media (${RESPONSIVE_SIZES.sm}) {
    margin: 20px 0;
  }
`;

const StyledMonthNovigator = styled.button`
  padding: 2px 5px;
  border: none;
  background-color: white;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledWeekNamesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 30px);
  color: rgba(0, 0, 0, 0.5);
  font-weight: 300;
  border-top: 1px solid (0, 0, 0, 0.5);
  margin: 5px 0 0;
  border-bottom: 1px solid (0, 0, 0, 0.5);
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
`;

const StyledDaysWrapper = styled.div`
  display: grid;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  row-gap: 0;
  grid-template-columns: repeat(7, 30px);
  padding: 5px 0;
`;

export const StyledCalendarDay = styled(CalendarDay)<{
  selected: boolean;
  current: boolean;
  inRange: boolean;
  disabled: boolean;
  background: string;
  rangeBackground: string;
}>`
  position: relative;
  width: 25px;
  height: 25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => (props.selected ? "white" : "#000000")};
  background-color: ${(props) =>
    props.selected ? props.rangeBackground : "transparent"};
  border: 1px solid #ffffff;
  text-decoration: ${(props) =>
    props.disabled
      ? "line-through"
      : (propsValue) => (propsValue.current ? "underline" : "none")};
  &:hover {
    background-color: transparent;
    color: #000000;
    border: 1px solid ${(props) => props.rangeBackground};
  }
  ${(props) =>
    props.inRange && !props.selected
      ? `background-color: ${props.background}`
      : ""};
`;
