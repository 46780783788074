export const luhnCreditCardValidation = (value: string): boolean => {
  // accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false;

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0;
  let bEven = false;

  const replacedValue = value.replace(/\D/g, "");

  for (let n = replacedValue.length - 1; n >= 0; n--) {
    const cDigit = replacedValue.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven) {
      // eslint-disable-next-line no-cond-assign
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }
  return nCheck % 10 === 0;
};
