import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useGetPointsPointLogQuery } from "api/baseAPI/points";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { dateFormatter } from "utils/date";
import { useFilterMaker } from "utils/filterMaker";
import { useUrlQuery } from "utils/url";
import { PointLogDetails } from "./parts/PointLogDetails";
import { PointLogsFilters } from "./parts/PointLogsFilters";

const initParameters = {
  page: "1",
  search: "",
  log_to: "",
  log_from: "",
};

export const PointLogs = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Управление"), ""],
    [t("Лог API запросов"), ""],
  ];

  const tableCellTitles = [
    t("Ключ"),
    "",
    t("Кол-во записей"),
    t("ID Транзакции"),
    t("Время записи"),
  ];

  const [openId, setOpenId] = useState<number>(0);

  const selectedPointManager = useSelectedPointManager();
  const { queryParams, querySetters } = useUrlQuery<{
    page: string;
    search: string;
    log_to: string;
    log_from: string;
  }>(initParameters);

  const { page, search } = queryParams;

  const { set_page, set_search } = querySetters;

  const { data, isSuccess, isFetching, refetch } = useGetPointsPointLogQuery({
    ...queryParams,
    point: selectedPointManager.point?.id,
  });
  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_log_to(values.log_to);
      querySetters.set_log_from(values.log_from);
    },
    () => {
      set_search(initParameters.search);
      querySetters.set_log_to(initParameters.log_to);
      querySetters.set_log_from(initParameters.log_from);
    }
  );

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const handleOpen = (id: number) => {
    setOpenId(openId === id ? 0 : id);
  };

  return (
    <Layout title={t("Лог API запросов")} breadCrumbs={breadCrumbs}>
      <StyledWrapper>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
              filters={<PointLogsFilters filters={filters} />}
              hideSearch
            />
          }
          count={0}
          dataLength={isSuccess && data?.length ? data?.length : 0}
          page={Number(page)}
          rowsPerPage={10}
          setPage={handleSetPage}
          setRowsPerPage={() => {}}
          disabledSelectedRow
        >
          {data?.map((row) => (
            <React.Fragment key={row.key}>
              <StyledRow>
                <StyledCell>{row.key}</StyledCell>
                <StyledCell>
                  <IconCellOpen
                    open={openId === row.key}
                    onClick={() => handleOpen(row.key)}
                  />
                </StyledCell>
                <StyledCell>{row.log_count}</StyledCell>
                <StyledCell>
                  {row.transaction_id === -1 ? "-" : row.transaction_id}
                </StyledCell>
                <StyledCell>
                  {dateFormatter.format(row.datetime?.replace("GMT", ""))}
                </StyledCell>
              </StyledRow>
              {openId === row.key && (
                <StyledRow>
                  <td colSpan={5}>
                    <PointLogDetails rowKey={row.key} />
                  </td>
                </StyledRow>
              )}
            </React.Fragment>
          ))}
        </Table>
      </StyledWrapper>
    </Layout>
  );
};

const StyledWrapper = styled.div`
  padding: 32px 24px;
`;
