export type Flex = {
  flex?: boolean;
};

export function flexProps(props: Flex) {
  return (({ flex }) => ({
    flex,
  }))(props);
}

export function flexStyle(props: Flex) {
  return props.flex ? "display: flex;" : "";
}
