import { baseSplitApi } from "./index";

export const apiCredentialsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getGatewayCredentials: builder.mutation<
      {
        api_token: string;
      },
      { point: number }
    >({
      query: (data) => ({
        url: "/api-credentials/get-gateway-credentials/",
        method: "POST",
        body: data,
      }),
    }),
    getInvoicingCredentials: builder.mutation<
      {
        client_id: string;
        username: string;
        password: string;
      },
      void
    >({
      query: () => ({
        url: "/api-credentials/get-invoicing-credentials/",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetGatewayCredentialsMutation,
  useGetInvoicingCredentialsMutation,
} = apiCredentialsApi;
