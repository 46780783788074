import { includes } from "lodash";
import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

export type TransactionFraudQueryParams = {
  page: string;
  page_size: string;
  search: string;
  transaction: string;
  id: string;
  ps: string;
  type: string;
  status: string;
  transaction__amount__gte: string;
  transaction__amount__lte: string;
  transaction__description__icontains: string;
  transaction__amount_currency: string;
};
export interface TransactionFraud {
  id: number;
  account: { id: number; name: string };
  transaction: {
    id: number;
    name: string;
    amount: number;
    description: string;
    amount_currency: string;
  };
  dispute: {
    created_at: string;
    transaction_fraud_info_id: number;
    uid: string;
  };
  reported_at: string;
  comment: string;
  can_reverse: boolean;
  type: keyof typeof TRANSACTION_FRAUD_TYPES;
  status: keyof typeof TRANSACTION_FRAUD_STATUSES;
}

export const TRANSACTION_FRAUD_TYPES = {
  "1": "Входящий фрод",
  "2": "Чарджбек",
  "3": "Чарджбек без 3DS",
  "4": "Пре-арбитрация",
};

export const TRANSACTION_FRAUD_STATUSES = {
  "1": "Новый диспут",
  "2": "Ожидает документы",
  "3": "Закрытый диспут",
  "4": "Подтвержденная транзакция",
};

export type MessageItem = {
  id: string;
  content: string;
  sender_id: string;
  sender_type: 0 | 1;
  created_at: string;
  attachments: {
    id: number;
    file: string;
    created_at: string;
    uploaded_by: 0 | 1;
    chat: string;
  }[];
};

export type AttachmentItem = {
  id: number;
  file: string;
  created_at: string;
  uploaded_by: 0 | 1;
  chat: string;
};

export type FetchChatMessages = {
  count: number;
  next: string;
  results: MessageItem[];
};

export type FetchChatAttachments = {
  count: number;
  next: string;
  results: AttachmentItem[];
};

export const transactionFraudApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getTransactionsFraudInfo: builder.query<
      { count: number; results: TransactionFraud[] },
      TransactionFraudQueryParams & { transaction__point: string }
    >({
      query: (params) => {
        const query = getQuery(params, [], true);
        return `/transaction-fraud-info/?${query.toString()}`;
      },
      providesTags: ["TransactionFrauds"],
    }),

    uploadFile: builder.mutation<void, { file: File }>({
      query: ({ file }) => {
        const body = new FormData();
        body.append("file", file);

        return {
          url: includes(file.name, ".csv")
            ? "/transaction-fraud-info/upload-csv/"
            : "/transaction-fraud-info/upload-xls/",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["TransactionFrauds"],
    }),
    startDispute: builder.query<
      {
        created_at: string;
        transaction_fraud_info_id: number;
        uid: string;
        chat: { uid: string };
      },
      { id: number }
    >({
      query: ({ id }) => `/transaction-fraud-info/${id}/start-dispute/`,
    }),
    getChatMessages: builder.query<
      FetchChatMessages,
      { uid: string; page: number }
    >({
      query: ({ uid, page }) => {
        const query = getQuery({ page, ordering: "-created_at" });
        return `/ag/chats/${uid}/messages/?${query.toString()}`;
      },
    }),
    getChatAttachments: builder.query<
      FetchChatAttachments,
      { chat: string; page: number }
    >({
      query: ({ chat, page }) => {
        const query = getQuery({
          chat__uid: chat,
          page,
          ordering: "-created_at",
          page_size: 20,
        });
        return `/ag/message-attachments/?${query.toString()}`;
      },
    }),
    uploadChatAttachment: builder.mutation<
      AttachmentItem,
      { formData: FormData }
    >({
      query: ({ formData }) => ({
        url: "/ag/message-attachments/",
        method: "POST",
        body: formData,
      }),
    }),
    getChatAttachmentsByIds: builder.query<
      FetchChatAttachments,
      { chat: string; id__in: string }
    >({
      query: ({ chat, id__in }) => {
        const query = getQuery({ chat__uid: chat, id__in });
        return `/ag/message-attachments/?${query.toString()}`;
      },
    }),
  }),
});

export const {
  useGetTransactionsFraudInfoQuery,
  useLazyGetChatMessagesQuery,
  useStartDisputeQuery,
  useLazyGetChatAttachmentsQuery,
  useUploadChatAttachmentMutation,
  useLazyGetChatAttachmentsByIdsQuery,
} = transactionFraudApi;
