import { Route, Routes } from "react-router-dom";
import { Invoice } from "./invoices/Invoice";
import { InvoiceCrete } from "./invoices/InvoiceCreate";
import { InvoiceEdit } from "./invoices/InvoiceEdit";
import { Invoices } from "./invoices/Invoices";
import { TemplateCrete } from "./templates/TemplateCreate";
import { TemplateEdit } from "./templates/TemplateEdit";
import { Templates } from "./templates/Templates";
import { Transactions } from "./transactions/Transactions";

const Router = () => (
  <Routes>
    <Route path="invoices" element={<Invoices />} />
    <Route path="invoices/create" element={<InvoiceCrete />} />
    <Route path="invoices/:uuid/edit" element={<InvoiceEdit />} />
    <Route path="invoices/:uuid" element={<Invoice />} />

    <Route path="transactions" element={<Transactions />} />

    <Route path="templates" element={<Templates />} />
    <Route path="templates/create" element={<TemplateCrete />} />
    <Route path="templates/:uuid/edit" element={<TemplateEdit />} />
  </Routes>
);

export default Router;
