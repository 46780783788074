import { useEffect } from "react";
import { Modal } from "UI/Modal";
import { useLazyGetCustomizationRuleQuery } from "api/baseAPI/customizer";
import { useGetPointsAvailablePaymentMethodsQuery } from "api/baseAPI/points";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { PayFormRulePreview } from "./PayFormRulePreview";
import { useCustomizationRuleFormLogic } from "./CustomizationRuleFormLogic";

export const ModalCustomizationRulePreview = ({
  ruleID,
  onClose,
}: {
  ruleID: number;
  onClose: () => void;
}) => {
  const selectedPointManager = useSelectedPointManager();
  const paymentMethodTypesQuery = useGetPointsAvailablePaymentMethodsQuery({
    point: selectedPointManager.point?.id,
  });
  const [getCustomizationRule] = useLazyGetCustomizationRuleQuery();

  const { form } = useCustomizationRuleFormLogic({
    onSubmit: () => {},
  });

  useEffect(() => {
    getCustomizationRule({ id: ruleID })
      .unwrap()
      .then((data) => {
        form.setValues({
          name: data.name,
          light: data.light?.id ? String(data.light?.id) : "",
          dark: data.dark?.id ? String(data.dark?.id) : "",
          theme_switch: data.theme_switch,
          is_gp_available: data.is_gp_available,
          is_ap_available: data.is_ap_available,
          ap_gp_position: data.ap_gp_position,
          company_name: data.company_name,
          messenger: data.messenger,
          phone_number: data.phone_number,
          email: data.email,
          partners_logos: data.partners_logos,
          is_active: data.is_active,
          oferta_style: data.oferta_style,
          available_payment_methods: [],
          payment_slug: null,
          locales: data.locales,
        });
      });
  }, [ruleID]);

  useEffect(() => {
    if (
      paymentMethodTypesQuery.isSuccess &&
      paymentMethodTypesQuery.data?.length > 0
    ) {
      const findCardMethod = paymentMethodTypesQuery.data.find(
        (el) => el.slug === "card_debit_hpp"
      );
      form.setFieldValue(
        "payment_slug",
        findCardMethod?.id
          ? { id: findCardMethod.id, slug: findCardMethod.slug }
          : null
      );
    }
  }, [paymentMethodTypesQuery]);

  return (
    <Modal hideHeader open height={620} width={1060} onClose={onClose}>
      <PayFormRulePreview form={form} handleClose={onClose} autoRefresh />
    </Modal>
  );
};
