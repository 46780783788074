import FileSaver from "file-saver";
import { useFormik } from "formik";
import {
  filter,
  find,
  includes,
  isArray,
  isEmpty,
  last,
  map,
  reduce,
  replace,
} from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Checkbox } from "UI/Form/Checkbox";
import { MultiSelect } from "UI/Form/MultiSelect";
import { Select } from "UI/Form/Select";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { privatApi } from "api/axios";
import {
  useCreateReportMutation,
  useDeleteReportMutation,
  useFormReportMutation,
  useGetReportsQuery,
} from "api/baseAPI/reports";
import { useGetUsersMeRetrieverPermissionsQuery } from "api/baseAPI/user";
import {
  useLazyGetWalletQuery,
  useTransactionStatuses,
} from "api/baseAPI/wallets";
import { LinearProgress } from "components/LinearProgress";
import { DateSelector } from "components/RangeSelector/DateSelector";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  // MenuItemActionA,
  TableRowActions,
} from "components/table/TableRowActions";
import {
  useSelectedPointManager,
  useSelectedWalletManager,
} from "hooks/useSelectedEntity";
import { dateFormatter } from "utils/date";
import { getColors } from "utils/getColors";
import { getTimezone } from "utils/getTimezone";
import { ReactComponent as ArrowsReloadIcon } from "utils/img/arrows-reload.svg";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as DownloadIcon } from "utils/img/download.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { DataItem } from "pages/invoicing/pages/parts/AdditionalComponents";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
};

export const Reports = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Отчетность"), ""],
    [t("Отчеты"), ""],
  ];

  const tableCellTitles = [
    t("Название"),
    t("Дата формирования отчета"),
    t("Статус"),
  ];

  const [openUuid, setOpenUuid] = useState<string>("");
  const selectedPointManager = useSelectedPointManager();
  const { accentColor } = getColors();

  const selectedWallet = useSelectedWalletManager();
  const getRetrieverPermissionsQuery = useGetUsersMeRetrieverPermissionsQuery();
  const timezone = getTimezone();

  const [getWallet, getWalletMeta] = useLazyGetWalletQuery();
  const [createReport, createReportMeta] = useCreateReportMutation();
  const [deleteReport, deleteReportMeta] = useDeleteReportMutation();
  const [formReport, formReportMeta] = useFormReportMutation();

  useEffect(() => {
    if (selectedWallet?.id) {
      getWallet({ id: selectedWallet?.id });
    }
  }, [selectedWallet]);

  const TRANSACTION_REPORT_STATUS: { [key: number]: string } = {
    "-1": t("В очереди"),
    0: t("Формируется"),
    1: t("Сформирован"),
    2: t("Ошибка формирования"),
    3: t("Отменен"),
  };

  const SERVICE_TYPES: { [key: number | string]: string } = {
    "": t("Все типы"),
    0: t("Внутренние"),
    1: t("Выплаты"),
    2: t("Эквайринг"),
    3: "p2p",
  };

  const walletoptions = useMemo(
    () =>
      selectedPointManager?.available_wallets?.length > 0
        ? selectedPointManager?.available_wallets?.map(
            (item: { id: number; name: string }) => ({
              value: `${item.id}`,
              label: `${item.id} | ${item.name}`,
            })
          )
        : [],
    [selectedPointManager]
  );

  const fieldsOptions = [
    { value: "id", label: "ID" },
    { value: "service", label: t("Сервис") },
    { value: "point_price", label: t("Сумма мерчанта") },
    { value: "point_currency", label: t("Валюта мерчанта") },
    { value: "provider_amount", label: t("Сумма клиента") },
    { value: "provider_currency", label: t("Валюта клиента") },
    { value: "amount", label: t("Сумма") },
    { value: "amount_currency", label: t("Валюта суммы") },
    { value: "status", label: t("Статус") },
    { value: "failure_reason_code", label: t("Ошибка") },
    { value: "external_transaction_id", label: t("Внешний ID") },
    { value: "external_customer_id", label: t("Внешний клиент") },
    { value: "description", label: t("Примечания") },
    { value: "uuid", label: t("Уникальный ID") },
    { value: "account", label: t("ID аккаунта") },
    { value: "created_at", label: t("Дата") },
    { value: "card_number", label: t("Аккаунт") },
    { value: "card_ps", label: t("ПС") },
    {
      value: "failure_reason",
      label: t("Описание ошибки"),
    },
    { value: "refunded_amount", label: t("Cумма возврата") },
    { value: "total_commission", label: t("Комиссия") },
  ];

  const { queryParams, querySetters } = useUrlQuery<{
    page: string;
    page_size: string;
    search: string;
  }>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const { data, isFetching, refetch } = useGetReportsQuery({
    ...queryParams,
    account_id: getWalletMeta?.data?.account?.id
      ? String(getWalletMeta?.data?.account?.id)
      : "",
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  useSuccessNotification([createReportMeta, deleteReportMeta, formReportMeta]);
  useErrorNotification([createReportMeta, deleteReportMeta, formReportMeta]);

  const form = useFormik({
    initialValues: {
      created_at__gte: DateTime.local()
        .setZone(timezone)
        .minus({ day: 3 })
        .startOf("day")
        .toISO(),
      created_at__lte: DateTime.local().setZone(timezone).toISO(),
      account_wallet_id__in: !isEmpty(selectedPointManager?.available_wallets)
        ? String(selectedPointManager?.available_wallets?.[0]?.id)
        : "",
      fields: [],
      status__in: "",
      service_type: "",
      export_type: "1",
      extra_format: false,
      is_archive: false,
    },
    onSubmit: (values) => {
      const filters = reduce(
        {
          ...(values.extra_format
            ? {
                changed_at__gte: values.created_at__gte,
                changed_at__lte: values.created_at__lte,
              }
            : {
                created_at__gte: values.created_at__gte,
                created_at__lte: values.created_at__lte,
              }),
          ...(values.extra_format
            ? {
                history_status__in: includes(values.status__in, ",")
                  ? values.status__in.split(",")
                  : values.status__in || ["1", "4"],
              }
            : {
                status__in: includes(values.status__in, ",")
                  ? values.status__in.split(",")
                  : values.status__in,
              }),
          fields: !isEmpty(values.fields)
            ? [
                ...map(
                  filter(fieldsOptions, (item) =>
                    includes(values.fields, item.value)
                  ),
                  "value"
                ),
                ...(values.extra_format ? ["refunded_amount"] : []),
              ]
            : map(fieldsOptions, "value"),
          account_wallet_id__in: includes(values.account_wallet_id__in, ",")
            ? values.account_wallet_id__in.split(",")
            : values.account_wallet_id__in,
          service_type: values.service_type,
          export_type: "",
          extra_format: values.extra_format ? "with_refunds" : "",
          model: "Transaction",
          parent__isnull: "true",
          // is_archive: String(values.is_archive),
        },
        (acc, value, key) => ({
          ...acc,
          ...(!isEmpty(value)
            ? {
                ...(includes(key, "__in") &&
                value &&
                !includes(value, ",") &&
                !isArray(value)
                  ? {
                      [replace(key, "__in", "")]: value,
                    }
                  : {
                      [key]: value,
                    }),
              }
            : {}),
        }),
        {}
      );

      createReport({
        account_id: getWalletMeta?.data?.account?.id || 0,
        export_type: Number(values.export_type),
        filters,
      });
    },
  });

  const TRANSACTION_STATUSES = useTransactionStatuses();

  const allStatusOptions = [
    ...Object.entries(TRANSACTION_STATUSES).map(([k, v]) => ({
      label: v,
      value: k,
    })),
  ];

  useEffect(() => {
    if (
      form.values.created_at__gte &&
      form.values.created_at__lte &&
      DateTime.fromISO(form.values.created_at__gte).plus({ day: 7 }) <=
        DateTime.fromISO(form.values.created_at__lte)
    ) {
      form.setFieldValue("export_type", "2");
    }
  }, [form.values.created_at__gte, form.values.created_at__lte]);

  const fetchExport = async (url: string) =>
    privatApi<void>(
      url,
      "GET",
      {},
      {
        responseType: "blob",
      }
    );

  const handleDownload = async (url: string) => {
    try {
      const response = await fetchExport(url);

      if (response.status === 204) {
        return;
      }
      let filename = last(url.split("/"));
      try {
        const reg = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          response?.headers?.["content-disposition"] || ""
        );
        if (reg) {
          filename = reg[1].replace(/['"]/g, "");
        }
      } catch (e) {
        console.error("Error", e);
      }

      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        FileSaver.saveAs(response.data, filename);
      } catch (e) {
        console.error("Error", e);
        toast.error(t("Ошибка"));
      }
    } catch (err) {
      toast.error(t("Ошибка"));
      throw err;
    }
  };

  const handleOpen = (uuid: string) => {
    setOpenUuid(openUuid === uuid ? "" : uuid);
  };

  const handleChangeArchive = (val: boolean) => {
    form.setFieldValue("is_archive", val);
    if (val) {
      form.setFieldValue(
        "created_at__gte",
        DateTime.local().setZone(timezone).startOf("month").toISO()
      );
      form.setFieldValue(
        "created_at__lte",
        DateTime.local().setZone(timezone).endOf("month").toISO()
      );
    } else {
      form.setFieldValue(
        "created_at__gte",
        DateTime.local()
          .setZone(timezone)
          .minus({ day: 3 })
          .startOf("day")
          .toISO()
      );
      form.setFieldValue(
        "created_at__lte",
        DateTime.local().setZone(timezone).toISO()
      );
    }
  };

  return (
    <Layout title={t("Отчеты")} breadCrumbs={breadCrumbs}>
      <Box p={24} style={{ background: "white" }}>
        <Typography variant="h6" mb={16}>
          {t("Введите данные для выгрузки отчета")}
        </Typography>
        <Grid container hSpace={24} vSpace={24} smHSpace={1} lgHSpace={24}>
          <Grid item sm={12} lg={6} xl={6} xxl={4}>
            <DateSelector
              label={t("Период")}
              start={
                form.values.created_at__gte
                  ? DateTime.fromISO(form.values.created_at__gte)
                  : null
              }
              end={
                form.values.created_at__lte
                  ? DateTime.fromISO(form.values.created_at__lte)
                  : null
              }
              minDate={DateTime.local().minus({ year: 1 })}
              set_start={(val) => form.setFieldValue("created_at__gte", val)}
              set_end={(val) => form.setFieldValue("created_at__lte", val)}
              disabledClear
              // withSwitch
              valueSwitch={form.values.is_archive}
              setSwitch={handleChangeArchive}
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <MultiSelect
              value={
                form.values.account_wallet_id__in
                  ? form.values.account_wallet_id__in.split(",")
                  : []
              }
              onChange={(val) =>
                form.setFieldValue("account_wallet_id__in", val.join(","))
              }
              label={t("Кошельки")}
              placeholder={t("Выберите кошелек")}
              size="small"
              options={walletoptions}
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <MultiSelect
              value={form.values.fields}
              onChange={(val) => form.setFieldValue("fields", val)}
              label={t("Выбор полей для загрузки отчетов")}
              placeholder={t("Все поля")}
              size="small"
              options={fieldsOptions}
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <Select
              value={form.values.service_type}
              onChange={(val) => form.setFieldValue("service_type", val)}
              label={t("Тип транзакций")}
              size="small"
              options={[
                { value: "", label: t("Все типы") },
                { value: "0", label: t("Внутренние") },
                { value: "1", label: t("Выплаты") },
                { value: "2", label: t("Эквайринг") },
                { value: "3", label: "p2p" },
              ]}
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={4}>
            <MultiSelect
              value={
                form.values.status__in ? form.values.status__in.split(",") : []
              }
              onChange={(val) =>
                form.setFieldValue("status__in", val.join(","))
              }
              label={t("Статус")}
              placeholder={t("Все статусы")}
              size="small"
              options={
                form.values.extra_format
                  ? [
                      {
                        label: t("Успешная"),
                        value: "1",
                      },
                      {
                        label: t("Реверс"),
                        value: "4",
                      },
                    ]
                  : allStatusOptions
              }
            />
          </Grid>
          <Grid item sm={12} lg={6} xl={4} flex alignItems="center">
            <Checkbox
              value={form.values.extra_format}
              onChange={(checked) => {
                form.setFieldValue("extra_format", checked);
                if (checked) form.setFieldValue("status__in", "");
              }}
              label={t("Отображать возврат отдельной транзакцией")}
            />
          </Grid>
        </Grid>

        <Grid
          container
          mt={24}
          style={{
            justifyContent: "space-between",
          }}
          hSpace={24}
          responsive={{
            sm: { direction: "column" },
            lg: { direction: "row" },
          }}
        >
          <Grid item>
            <Box
              flex
              alignItems="center"
              nowrap
              responsive={{
                sm: { direction: "column" },
                lg: { direction: "row" },
              }}
            >
              <Typography variant="h6">{t("Формат файла отчета")}</Typography>
              <div data-tip data-for="only_csv">
                <ButtonSwitch
                  responsive={{
                    lg: { mb: 0, mt: 0, ml: 16 },
                    sm: { mb: 16, mt: 16, ml: 0 },
                  }}
                  firstTitle="XLS"
                  secondTitle="CSV"
                  value={form.values.export_type === "1"}
                  onChange={() => {
                    if (
                      !(
                        form.values.created_at__gte &&
                        form.values.created_at__lte &&
                        DateTime.fromISO(form.values.created_at__gte).plus({
                          day: 7,
                        }) <= DateTime.fromISO(form.values.created_at__lte)
                      )
                    ) {
                      form.setFieldValue(
                        "export_type",
                        form.values.export_type === "2" ? "1" : "2"
                      );
                    }
                  }}
                />
              </div>
              {form.values.created_at__gte &&
              form.values.created_at__lte &&
              DateTime.fromISO(form.values.created_at__gte).plus({
                day: 7,
              }) <= DateTime.fromISO(form.values.created_at__lte) ? (
                <>
                  <ReactTooltip
                    id="only_csv"
                    border
                    place="top"
                    multiline
                    type="light"
                    effect="solid"
                    borderColor={accentColor}
                  >
                    <span>
                      {t(
                        "Для отчетов за период больше 7 дней доступен только формат csv"
                      )}
                    </span>
                  </ReactTooltip>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
          <Grid item sm={12} lg={4}>
            <Button
              fullwidth
              onClick={() => form.handleSubmit()}
              disabled={
                createReportMeta.isLoading ||
                !includes(getRetrieverPermissionsQuery.data, "add_report")
              }
            >
              {t("Сформировать отчет")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {createReportMeta.isLoading ? <LinearProgress /> : <></>}
      <Box p={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
            />
          }
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data?.results.map((row) => (
            <React.Fragment key={row.uuid}>
              <StyledRow>
                <StyledCell
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpen(row.uuid)}
                >
                  <Box flex alignItems="center" nowrap>
                    <IconCellOpen
                      open={openUuid === row.uuid}
                      onClick={() => {}}
                    />
                    {row.name}
                  </Box>
                </StyledCell>
                <StyledCell
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpen(row.uuid)}
                >
                  {dateFormatter.format(row.updated_at)}
                </StyledCell>
                <StyledCell
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpen(row.uuid)}
                >
                  {TRANSACTION_REPORT_STATUS[Number(row.status)]}
                </StyledCell>
                <StyledCell>
                  <TableRowActions
                    actions={[
                      ...(row.file && row.status === 1
                        ? [
                            <MenuItemAction
                              key={1}
                              onClick={() =>
                                handleDownload(
                                  row.file.replace("http:", "https:")
                                )
                              }
                            >
                              <DownloadIcon /> &nbsp;{t("Загрузить")}
                            </MenuItemAction>,
                          ]
                        : []),
                      <MenuItemAction
                        key={2}
                        onClick={() => deleteReport({ uuid: row.uuid })}
                      >
                        <DeleteIcon /> &nbsp;{t("Удалить")}
                      </MenuItemAction>,
                      <MenuItemAction
                        key={3}
                        onClick={() => formReport({ uuid: row.uuid })}
                      >
                        <ArrowsReloadIcon /> &nbsp;{t("Повторить")}
                      </MenuItemAction>,
                    ]}
                  />
                </StyledCell>
              </StyledRow>
              {openUuid === row.uuid && (
                <StyledRow>
                  <td colSpan={6}>
                    <Grid container p={16} pl={8} hSpace={16} vSpace={16}>
                      {row.filters?.model === "AccountWalletTransaction" ? (
                        <>
                          <Grid item sm={3}>
                            <DataItem
                              text={t("Кошелек")}
                              content={
                                find(
                                  walletoptions,
                                  (el) =>
                                    el.value === row.filters.account_wallet
                                )?.label || ""
                              }
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <DataItem
                              text={t("Период")}
                              content={`${dateFormatter.format(
                                row.filters?.created_at__gte || ""
                              )} - ${dateFormatter.format(
                                row.filters?.created_at__lte || ""
                              )}`}
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <DataItem
                              text={t("Направление")}
                              content={
                                row.filters?.amount__gt === "0"
                                  ? t("Зачислено")
                                  : row.filters?.amount__gt === "1"
                                  ? t("Списано")
                                  : t("Все")
                              }
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {row.filters?.model === "Transaction" ? (
                        <>
                          <Grid item sm={3}>
                            <DataItem
                              text={t("Кошельки")}
                              content={
                                <MultiSelect
                                  value={map(
                                    map(
                                      row.filters?.account_wallet_id__in?.length
                                        ? row.filters?.account_wallet_id__in
                                        : row.filters?.account_wallet_id?.split(
                                            ","
                                          ) || [],
                                      (id) =>
                                        find(walletoptions, { value: id }) || {
                                          value: id,
                                          label: `${id} | ${id}`,
                                        }
                                    ),
                                    (item) => item.value
                                  )}
                                  disabledChange
                                  label=""
                                  placeholder={t("Все кошельки")}
                                  size="small"
                                  options={map(
                                    row.filters?.account_wallet_id__in?.length
                                      ? row.filters?.account_wallet_id__in
                                      : row.filters?.account_wallet_id?.split(
                                          ","
                                        ) || [],
                                    (id) =>
                                      find(walletoptions, { value: id }) || {
                                        value: id,
                                        label: `${id} | ${id}`,
                                      }
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <DataItem
                              text={t("Выбранные поля в отчете")}
                              content={
                                <MultiSelect
                                  value={
                                    fieldsOptions.length ===
                                    row.filters?.fields?.length
                                      ? []
                                      : map(
                                          fieldsOptions.filter((el) =>
                                            includes(
                                              row.filters?.fields || [],
                                              el.value
                                            )
                                          ),
                                          (item) => item.value
                                        )
                                  }
                                  disabledChange
                                  label=""
                                  placeholder={t("Все поля")}
                                  size="small"
                                  options={
                                    fieldsOptions.length ===
                                    row.filters?.fields?.length
                                      ? []
                                      : fieldsOptions.filter((el) =>
                                          includes(
                                            row.filters?.fields || [],
                                            el.value
                                          )
                                        )
                                  }
                                />
                              }
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <DataItem
                              text={t("Статус")}
                              content={
                                <MultiSelect
                                  value={map(
                                    allStatusOptions.filter((el) =>
                                      includes(
                                        [
                                          ...(row.filters?.status__in || []),
                                          ...(row.filters?.history_status__in ||
                                            []),
                                          ...(row.filters?.status
                                            ? [...row.filters.status.split(",")]
                                            : []),
                                        ],
                                        el.value
                                      )
                                    ),
                                    (item) => item.value
                                  )}
                                  disabledChange
                                  label=""
                                  placeholder={t("Все статусы")}
                                  size="small"
                                  options={allStatusOptions.filter((el) =>
                                    includes(
                                      [
                                        ...(row.filters?.status__in || []),
                                        ...(row.filters?.history_status__in ||
                                          []),
                                        ...(row.filters?.status
                                          ? [...row.filters.status.split(",")]
                                          : []),
                                      ],
                                      el.value
                                    )
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <DataItem
                              text={t("Период")}
                              content={`${dateFormatter.format(
                                row.filters?.created_at__gte ||
                                  row.filters?.changed_at__gte ||
                                  null
                              )} - ${dateFormatter.format(
                                row.filters?.created_at__lte ||
                                  row.filters?.changed_at__lte ||
                                  null
                              )}`}
                            />
                          </Grid>
                          <Grid item sm={3}>
                            <DataItem
                              text={t("Тип транзакций")}
                              content={
                                row.filters?.service_type &&
                                SERVICE_TYPES[row.filters?.service_type]
                                  ? SERVICE_TYPES[row.filters?.service_type]
                                  : t("Все типы")
                              }
                            />
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </td>
                </StyledRow>
              )}
            </React.Fragment>
          ))}
        </Table>
      </Box>
    </Layout>
  );
};
