import { Link } from "react-router-dom";
import styled from "styled-components";
import { getColors } from "utils/getColors";

export const ItemWithoutChild = ({
  to,
  title,
  icon,
  isMenuCollapsed,
  isChild,
}: {
  to: string;
  title: string;
  icon?: React.ReactElement;
  isMenuCollapsed: boolean;
  isChild?: boolean;
}) => {
  const { sidebarHoverBackground } = getColors();
  return (
    <StyledMenuItemLink
      to={to}
      backgroundhover={sidebarHoverBackground}
      small={`${!!isChild}`}
    >
      {icon ? (
        <StyledMenuItemIconWrapper>{icon}</StyledMenuItemIconWrapper>
      ) : (
        <StyledFakeIcon />
      )}
      {!isMenuCollapsed && title}
    </StyledMenuItemLink>
  );
}

const StyledMenuItemLink = styled(Link)<{
  active?: boolean;
  backgroundhover: string;
  small?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 18px;
  height: ${(props) => (props.small  ===  "true" ? "29px" : "52px")};
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  ${(props: { active?: boolean }) => (props.active ? `opacity: 0.4;` : "")}
  &:hover {
    background: ${(props) => props.backgroundhover};
  }
`;

const StyledMenuItemIconWrapper = styled.div`
  margin-right: 10px;
`;

const StyledFakeIcon = styled.div`
  margin-right: 30px;
`;
