import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "UI/Button";
import { Divider } from "UI/Divider";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
import {
  useGetWalletInvoiceTopUpQuery,
  useLazyGetWalletQuery,
} from "api/baseAPI/wallets";
import { Layout } from "components/layouts/Layout";
import { useSelectedWalletManager } from "hooks/useSelectedEntity";
import { setSelectedWallet } from "reducers/basicSlice";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { WalletInfo } from "./parts/WalletInfo";

export const InvoiceTopUp = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Пополнение"), ""],
    [t("Платежная страница"), ""],
  ];

  const dispatch = useDispatch();
  const selectedWallet = useSelectedWalletManager();

  const [getWalletData] = useLazyGetWalletQuery();

  useEffect(() => {
    if (selectedWallet?.id) {
      getWalletData({ id: selectedWallet.id });
    }
  }, [selectedWallet?.id]);

  const [isShowPayModal, setIsShowPayModal] = useState<boolean>(false);
  const [payUrl, setPayUrl] = useState<string | null>(null);

  const { data, isError } = useGetWalletInvoiceTopUpQuery({
    id: selectedWallet.id,
  });

  useSuccessNotification([]);
  useErrorNotification([]);

  const form = useFormik({
    initialValues: {
      amount: "",
    },
    onSubmit: (_, { setSubmitting }) => {
      if (data?.page_url) {
        setPayUrl(data?.page_url);
        setIsShowPayModal(true);
        setSubmitting(false);
      }
    },
  });

  const handleSelectWallet = (item: { id: number; name: string }) => {
    dispatch(setSelectedWallet(item));
  };

  return (
    <Layout title={t("Платежная страница")} breadCrumbs={breadCrumbs}>
      <Grid
        container
        style={{
          background: "#ffffff",
          padding: "24px",
        }}
        hSpace={24}
        vSpace={12}
      >
        <Grid item sm={12} lg={8}>
          <WalletInfo
            selectedWallet={selectedWallet}
            onChangeSelectedWallet={handleSelectWallet}
          />
        </Grid>
        <Grid item responsive={{ sm: { hidden: true }, lg: { hidden: false } }}>
          <Divider />
        </Grid>
        <Grid item sm={12} lg="fill">
          <Grid
            container
            vSpace={16}
            responsive={{ sm: { direction: "column" } }}
          >
            <Grid item sm="fill">
              <Button
                mt={10}
                onClick={() => form.submitForm()}
                disabled={form.isSubmitting || isError}
                fullwidth
              >
                {t("Пополнить")}
              </Button>
              <Modal
                title={t("Оплата")}
                open={isShowPayModal && payUrl !== null}
                height={650}
                width={500}
                onClose={() => setIsShowPayModal(false)}
                isSmallTitle
              >
                {payUrl ? (
                  <iframe
                    src={payUrl}
                    title="iframe"
                    width="100%"
                    height="630px"
                    style={{ border: "none" }}
                  />
                ) : (
                  <></>
                )}
              </Modal>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};
