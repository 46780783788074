import React from "react";
import { DateTime } from "luxon";
import styled from "styled-components";
import { getTimezone } from "utils/getTimezone";
import { RESPONSIVE_SIZES } from "utils/tools";
// import chevronDown from "./assets/chevron-down.svg";

function formatTimeValue(value: string): string {
  let result = value;

  if (String(value).length < 2) {
    result = String(0).concat(String(value));
  }

  return result;
}

export const TimeSelector = ({
  type,
  rangeStart,
  rangeEnd,
  onChange,
}: {
  type: "start" | "end";
  rangeStart: DateTime | null;
  rangeEnd: DateTime | null;
  onChange: (date: DateTime) => void;
}): React.ReactElement => {
  const hoursRange = [];
  const minutesRange = [];
  const timezone = getTimezone();

  for (let hour = 0; hour < 24; hour++) {
    if (
      rangeStart &&
      rangeEnd &&
      rangeStart.year  ===  rangeEnd.year &&
      rangeStart.month  ===  rangeEnd.month &&
      rangeStart.day  ===  rangeEnd.day
    ) {
      if (type  ===  "end" && rangeStart.hour <= hour) {
        hoursRange.push(hour);
      }
      if (type  ===  "start" && rangeEnd.hour >= hour) {
        hoursRange.push(hour);
      }
    } else {
      hoursRange.push(hour);
    }
  }

  for (let minute = 0; minute < 60; minute++) {
    if (
      rangeStart &&
      rangeEnd &&
      rangeStart.year  ===  rangeEnd.year &&
      rangeStart.month  ===  rangeEnd.month &&
      rangeStart.day  ===  rangeEnd.day
    ) {
      if (type  ===  "start" && rangeEnd.minute >= minute) {
        minutesRange.push(minute);
      }
      if (type  ===  "end" && rangeStart.minute < minute) {
        minutesRange.push(minute);
      }
    } else {
      minutesRange.push(minute);
    }
  }

  // Handlers
  const selectHourHandler = (hour: number) => {
    if (type === "start") {
      onChange(
        DateTime.local(
          rangeStart?.year || 0,
          rangeStart?.month || 0,
          rangeStart?.day || 0,
          hour,
          rangeStart?.minute || 0,
          { zone: timezone }
        )
      );
    }
    if (type === "end") {
      onChange(
        DateTime.local(
          rangeEnd?.year || 0,
          rangeEnd?.month || 0,
          rangeEnd?.day || 0,
          hour,
          rangeEnd?.minute || 0,
          { zone: timezone }
        )
      );
    }
  };

  const selectMinuteHandler = (minute: number) => {
    if (type === "start") {
      onChange(
        DateTime.local(
          rangeStart?.year || 0,
          rangeStart?.month || 0,
          rangeStart?.day || 0,
          rangeStart?.hour || 0,
          minute,
          { zone: timezone }
        )
      );
    }
    if (type === "end") {
      onChange(
        DateTime.local(
          rangeEnd?.year || 0,
          rangeEnd?.month || 0,
          rangeEnd?.day || 0,
          rangeEnd?.hour || 0,
          minute,
          { zone: timezone }
        )
      );
    }
  };

  return (
    <>
      <StyledSelector
        value={type  ===  "start" ? rangeStart?.hour : rangeEnd?.hour}
        onChange={(e) => {
          selectHourHandler(Number(e.target.value));
        }}
      >
        {hoursRange.map((hour) => (
          <option key={hour} value={hour}>
            {formatTimeValue(String(hour))}
          </option>
        ))}
      </StyledSelector>
      &nbsp;:&nbsp;
      <StyledSelector
        value={type  ===  "start" ? rangeStart?.minute : rangeEnd?.minute}
        onChange={(e) => {
          selectMinuteHandler(Number(e.target.value));
        }}
      >
        {minutesRange.map((minute) => (
          <option key={minute} value={minute}>
            {formatTimeValue(String(minute))}
          </option>
        ))}
      </StyledSelector>
    </>
  );
}

const StyledSelector = styled.select`
  background: #ffffff;
  border: 1px solid #e8ebf1;
  box-sizing: border-box;
  padding: 4px;
  appearance: none;
  &:focus {
    outline: none;
  }

  @media (${RESPONSIVE_SIZES.sm}) {
    padding: 4px 24px;
    margin: 4px 0;
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background: #19202e;
    }
  }
`;
