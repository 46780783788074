import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { ValidationError } from "yup";

export interface useFilterMakerResult<T> {
  values: T;
  handleReset: () => void;
  setValue: (key: keyof T, value: string) => void;
  setValueAndSubmit: (
    key: keyof T | Array<keyof T>,
    value: string | string[]
  ) => void;
  handleSubmit: () => void;
  disabledReset: boolean;
  errors: Record<keyof T, string> | null;
}

export function useFilterMaker<T>(
  initParams: T,
  params: T,
  onSubmit: (values: T) => void,
  onReset?: () => void,
  /**
   * A Yup Schema or a function that returns a Yup schema
   */
  validationSchema?: any | (() => any)
): useFilterMakerResult<T> {
  const [values, setValues] = useState<T>(params);
  const [errors, setErrors] = useState<Record<keyof T, string> | null>(null);

  const handleReset = () => {
    setValues(initParams);
    localStorage.removeItem("saved_values");
    if (onReset) onReset();
    onSubmit(initParams);
  };

  const setValue = (key: keyof T, value: string) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const setValueAndSubmit = (
    key: keyof T | Array<keyof T>,
    value: string | string[]
  ) => {
    let newValues = { ...values };

    if (Array.isArray(key)) {
      if (Array.isArray(value)) {
        const valPairs = key.reduce((acc, v, i) => {
          acc[v] = value[i];
          return acc;
        }, {} as Record<keyof T, string>);
        newValues = {
          ...values,
          ...valPairs,
        };
      } else {
        console.error("You pass key as Array, but value is not Array!");
      }
    } else {
      newValues = {
        ...values,
        [key]: value,
      };
    }
    setValues(newValues);
    onSubmit(newValues);
  };

  const handleSubmit = () => {
    onSubmit(values);
  };

  const disabledReset = useMemo(() => isEqual(
      { ...initParams, page: "", page_size: "" },
      { ...params, page: "", page_size: "" }
    ), [initParams, params]);

  useEffect(() => {
    if (validationSchema && values) {
      validationSchema
        .validate(values, { abortEarly: false })
        .then(() => setErrors(null))
        .catch((err: ValidationError) => {
          if (err.inner) {
            if (err.inner.length > 0) {
              setErrors(
                err.inner.reduce((acc, item) => {
                  if (item.path) {
                    acc[item.path as keyof T] = item.message;
                  }
                  return acc;
                }, {} as Record<keyof T, string>)
              );
            }
          }
        });
    } else {
      setErrors(null);
    }
  }, [values]);

  return {
    values,
    handleReset,
    setValue,
    setValueAndSubmit,
    handleSubmit,
    disabledReset,
    errors,
  };
}
