import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useGetUsersSelfQuery } from "api/baseAPI/user";
import { ModalSuccess } from "components/ModalSuccess";
import { Layout } from "components/layouts/Layout";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowLeftIcon } from "utils/img/arrow-left.svg";
import { ReactComponent as ArrowUpDownIcon } from "utils/img/arrow-up-down.svg";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { ReactComponent as LockIcon } from "utils/img/lock.svg";
import { ReactComponent as ShieldCheckIcon } from "utils/img/shield-check.svg";
import { ModalChangePassword } from "./parts/ModalChangePassword";
import { ModalChangeProfile } from "./parts/ModalChangeProfile";
import { ModalOtp } from "./parts/ModalOtp";

const ElementMenu = ({
  disableArrow,
  disabled,
  icon,
  title,
  onClick,
  open,
}: {
  disableArrow?: boolean;
  disabled?: boolean;
  open?: boolean;
  icon: React.ReactElement;
  title: string;
  onClick: () => void;
}) => {
  const { pageBackground } = getColors();
  return (
    <StyledItem
      open={open}
      background={pageBackground}
      onClick={() => !disabled && onClick()}
    >
      <Box flex alignItems="center">
        <Box
          flex
          alignItems="center"
          justifyContent="center"
          style={{ height: 40, width: 40, background: pageBackground }}
        >
          {icon}
        </Box>
        <Typography ml={12}>{title}</Typography>
      </Box>
      {!disableArrow ? <StyledArrowUpDown /> : <></>}
    </StyledItem>
  );
};

export const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openItem, setOpenItem] = useState("");
  const [openSuccess, setOpenSuccess] = useState("");
  const [openModalOtp, setOpenModalOtp] = useState(false);
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);

  const getUsersSelfQuery = useGetUsersSelfQuery();

  const currentQString = useLocation().search;
  const query = new URLSearchParams(currentQString);

  useEffect(() => {
    const refrech = query.get("refrech");
    if (refrech === "start") {
      navigate({ search: "" });
      setOpenItem("");
      setOpenModalOtp(false);
      setOpenModalChangePassword(false);
    }
  }, [query]);

  const breadCrumbs = [
    [t("Мой профиль"), ""],
    [
      openItem === "security" ? t("Безопасность") : t("Редактирование профиля"),
      "",
    ],
  ];

  return (
    <Layout title={t("Мой профиль")} breadCrumbs={openItem ? breadCrumbs : []}>
      <Grid
        container
        hSpace={1}
        style={{
          minHeight: "calc(100vh - 62px)",
        }}
      >
        <Grid
          item
          sm={12}
          lg={4}
          xl={3.2}
          style={{
            background: "#ffffff",
          }}
          responsive={{
            sm: { hidden: openModalOtp || openModalChangePassword },
            md: { hidden: false },
          }}
        >
          <Box
            p={24}
            style={{
              height: "calc(100% - 48px)",
            }}
          >
            {!openItem ? (
              <>
                <Box flex alignItems="center">
                  <StyledUserLogo src={getUsersSelfQuery.data?.avatar} />
                  <Typography variant="h6" ml={12}>{`${
                    getUsersSelfQuery.data?.first_name || ""
                  } ${getUsersSelfQuery.data?.last_name || ""}`}</Typography>
                </Box>
                <StyledDivider />
                <ElementMenu
                  icon={<ShieldCheckIcon />}
                  title={t("Безопасность")}
                  onClick={() => setOpenItem("security")}
                />
                <ElementMenu
                  disableArrow
                  icon={<EditIcon />}
                  title={t("Редактировать профиль")}
                  onClick={() => setOpenItem("profile")}
                />
              </>
            ) : (
              <></>
            )}
            {openItem === "security" ? (
              <Box
                flex
                direction="column"
                justifyContent="space-between"
                style={{
                  height: "100%",
                }}
              >
                <Box>
                  <Button
                    ml={-24}
                    mb={22}
                    variant="text"
                    size="small"
                    onClick={() => {
                      setOpenItem("");
                      setOpenModalOtp(false);
                      setOpenModalChangePassword(false);
                    }}
                    iconStart={<ArrowLeftIcon />}
                  >
                    {t("Назад")}
                  </Button>
                  <ElementMenu
                    open={openModalChangePassword}
                    icon={<LockIcon />}
                    title={t("Сменить пароль")}
                    onClick={() => {
                      setOpenModalOtp(false);
                      setOpenModalChangePassword(true);
                    }}
                  />
                  <ElementMenu
                    open={openModalOtp}
                    icon={<ShieldCheckIcon />}
                    title={t("Управление 2FA")}
                    onClick={() => {
                      setOpenModalOtp(true);
                      setOpenModalChangePassword(false);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <></>
            )}
            {openItem === "profile" ? (
              <ModalChangeProfile
                onClose={() => setOpenItem("")}
                onSuccess={() => {
                  setOpenItem("");
                  getUsersSelfQuery.refetch();
                }}
              />
            ) : (
              <></>
            )}
            {openSuccess ? (
              <ModalSuccess
                handleClick={() => {
                  setOpenSuccess("");
                  setOpenItem("");
                }}
                title={
                  openSuccess === "otp"
                    ? t("Двухфакторная аутентификация обновлена")
                    : t("Пароль изменен успешно!")
                }
                buttonText={t("В профиль")}
              />
            ) : (
              <></>
            )}
          </Box>
        </Grid>
        {openModalChangePassword ? (
          <ModalChangePassword
            onClose={() => setOpenModalChangePassword(false)}
            onSuccess={() => {
              setOpenModalChangePassword(false);
              setOpenSuccess("password");
              getUsersSelfQuery.refetch();
            }}
          />
        ) : (
          <></>
        )}
        {openModalOtp ? (
          <ModalOtp
            onClose={() => setOpenModalOtp(false)}
            onSuccess={() => {
              setOpenModalOtp(false);
              setOpenSuccess("otp");
              getUsersSelfQuery.refetch();
            }}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Layout>
  );
};
const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 24px;
  margin-left: -24px;
  margin-right: -24px;
  padding-right: 24px;
  padding-left: 24px;
  ${(props: { open?: boolean; background: string }) =>
    props.open
      ? `
          background: ${props.background};
        `
      : ""};
  &:hover {
    background: ${(props) => props.background};
  }
`;
const StyledUserLogo = styled.img`
  width: 56px;
  height: 56px;
`;
const StyledDivider = styled.hr`
  margin: 24px 0;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: #ecf0f6;
`;
const StyledArrowUpDown = styled(ArrowUpDownIcon)`
  path {
    fill: rgba(57, 57, 57, 1) !important;
  }
  transform: rotate(270deg);
  margin-right: 12px;
`;
