import { FormikProps } from "formik";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "UI/Box";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { AdditionalPayoutsField } from "api/baseAPI/payouts";
import { useStyle } from "hooks/useStyle";
import {
  handleChangeNumber,
  numberForInput,
  visibleNumber,
} from "utils/numbers";
import { useCurrencyIcon } from "utils/useStyle";
import { FioRow } from "pages/payout/parts/FioRow";
import { ReactComponent as DeletePayoutIcon } from "pages/payout/parts/icons/delete-payout.svg";
import { IPayoutMethod } from "pages/payout/logic/payoutMethods";
import {
  PAYOUT_FIO_LIMIT,
  PayoutData,
  PayoutFormData,
} from "pages/payout/logic/payoutsCreateLogic";
import { AdditionalFields } from "./AdditionalFields";

export type CustomerAccountInputProps = {
  form: FormikProps<PayoutFormData>;
  id: string;
};
export type CustomerAccountInputType = (
  props: CustomerAccountInputProps
) => ReactElement;

export const FormLines = (props: {
  payoutMethod: IPayoutMethod;
  form: FormikProps<PayoutFormData>;
  deleteRow: (id: string) => void;
  customerAccountInput: CustomerAccountInputType;
  additionalFields: AdditionalPayoutsField[] | null;
}) => {
  const {
    form,
    payoutMethod,
    deleteRow,
    customerAccountInput,
    additionalFields,
  } = props;
  const { t } = useTranslation();
  const style = useStyle();
  const currencyIcon = useCurrencyIcon(payoutMethod.currency || "UAH");
  const commissions = payoutMethod.getCommissions(form.values.payouts);

  return (
    <>
      {(
        Object.entries(form.values.payouts) as [
          id: string,
          payout: PayoutData
        ][]
      ).map(([id, payout], index) => (
        <Grid
          item
          sm={12}
          key={id}
          mb={15}
          px={24}
          pt={24}
          style={{ background: "white" }}
        >
          <Grid container mb={24}>
            <Grid item sm={12}>
              <Box
                flex
                justifyContent="space-between"
                alignItems="center"
                mb={10}
              >
                <Typography variant="body">
                  {`${t("Платеж")} № ${index + 1}`}
                </Typography>
                <Box flex alignItems="center">
                  <Typography
                    px={5}
                    py={3}
                    mr={15}
                    fontSize={14}
                    style={{
                      color: "#2C60B0",
                      backgroundColor: "#EAEFF7",
                    }}
                  >
                    {t("Комиссия")}&nbsp;
                    {currencyIcon}
                    {visibleNumber(commissions[id].point)}
                  </Typography>

                  {Object.keys(form.values.payouts).length > 1 ? (
                    <DeletePayoutIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteRow(id)}
                    />
                  ) : null}
                </Box>
              </Box>
            </Grid>
            <Grid item sm={12} mb={12}>
              <Grid
                container
                hSpace={10}
                vSpace={10}
                justifyContent="space-between"
              >
                <Grid item sm={12} md={4}>
                  {customerAccountInput({ id, form })}
                </Grid>
                <Grid item sm={12} md={3}>
                  <TextInput
                    placeholder={t("Введите сумму")}
                    // mask="0000000000"
                    value={numberForInput(payout.amount)}
                    name="amount"
                    onChange={(value) =>
                      form.setFieldValue(
                        `payouts[${id}].amount`,
                        handleChangeNumber(
                          value.replace(/[^0-9.]/g, "").slice(0, 13)
                        )
                      )
                    }
                    onBlur={(e) => {
                      form.handleBlur(e);
                      form.setFieldTouched(`payouts[${id}].amount`, true);
                    }}
                    error={
                      !!(form.touched.payouts?.[id]?.amount &&
                      form.errors.payouts?.[id]?.amount)
                    }
                    helperText={
                      (form.touched.payouts?.[id]?.amount &&
                        form.errors.payouts?.[id]?.amount &&
                        form.errors.payouts?.[id]?.amount) ||
                      ""
                    }
                    size="small"
                  />
                </Grid>
                <Grid item sm={12} md={5}>
                  <TextInput
                    placeholder={t("Примечание")}
                    value={payout.description}
                    name="description"
                    onChange={(value) =>
                      form.setFieldValue(`payouts[${id}].description`, value)
                    }
                    onBlur={(e) => {
                      form.handleBlur(e);
                      form.setFieldTouched(`payouts[${id}].description`, true);
                    }}
                    error={
                      !!(form.touched.payouts?.[id]?.description &&
                      form.errors.payouts?.[id]?.description)
                    }
                    helperText={
                      (form.touched.payouts?.[id]?.description &&
                        form.errors.payouts?.[id]?.description &&
                        form.errors.payouts?.[id]?.description) ||
                      ""
                    }
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>

            {additionalFields ? (
              <Grid item sm={12} mb={12}>
                <AdditionalFields
                  fields={additionalFields}
                  form={form}
                  payoutId={id}
                />
              </Grid>
            ) : (
              <></>
            )}

            <Grid item sm={12}>
              {payoutMethod.currency &&
              Number(payout.amount) >
                Number(PAYOUT_FIO_LIMIT[payoutMethod.currency.toLowerCase()]) &&
              style.is_fc ? (
                <FioRow
                  values={{
                    first_name: form.values.payouts[id].first_name,
                    last_name: form.values.payouts[id].last_name,
                    middle_name: form.values.payouts[id].middle_name,
                  }}
                  errors={{
                    first_name: form?.errors?.payouts?.[id]?.first_name,
                    last_name: form?.errors?.payouts?.[id]?.last_name,
                    middle_name: form?.errors?.payouts?.[id]?.middle_name,
                  }}
                  setFieldValue={(field, value) =>
                    form.setFieldValue(`payouts[${id}].${field}`, value)
                  }
                  setFieldTouched={(field) =>
                    form.setFieldTouched(`payouts[${id}].${field}`, true)
                  }
                  fields={additionalFields}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
