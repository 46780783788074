export type NoWrap = {
  nowrap?: boolean;
};

export function noWrapProps(props: NoWrap) {
  return (({ nowrap }) => ({
    nowrap,
  }))(props);
}

export function noWrapStyle(props: NoWrap) {
  return props.nowrap ? "flex-wrap: nowrap !important;" : "flex-wrap: wrap;";
}
