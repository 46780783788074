import i18next from "i18next";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModalProvider } from "./components/Modal";
import { PrivateOutlet } from "./components/PrivateOutlet";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { AccountWalletTransactions } from "./pages/accountWalletTransactions/AccountWalletTransactions";
import Admin from "./pages/admin";
import Invoicing from "./pages/invoicing";
import { Onboarding } from "./pages/onboarding/Onboarding";
import { PayoutCreate } from "./pages/payout/PayoutCreate";
import { PayoutHistory } from "./pages/payout/PayoutHistory";
import { PayoutTemplates } from "./pages/payout/PayoutTemplates";
import { Profile } from "./pages/profile/Profile";
import { Report } from "./pages/report/Report";
import { ReportRequests } from "./pages/reportRequests/ReportRequests";
import { Reports } from "./pages/reports/Reports";
import ServicesRouter from "./pages/services";
import { BinancePayTopUp } from "./pages/topup/BinancePayTopUp";
import { CardTopUp } from "./pages/topup/CardTopUp";
import { CryptoTopUp } from "./pages/topup/CryptoTopUp";
import { InvoiceTopUp } from "./pages/topup/PaymentPage";
import { TransactionFrauds } from "./pages/transactionFrauds/TransactionFrauds";
import { Transactions } from "./pages/transactions/Transactions";
import { W2w } from "./pages/w2w/W2w";
import CheckoutInvoice from "./pages/checkoutInvoice";
import { store } from "./store";

const App = () => {
  const lang = localStorage.getItem("lang");
  useEffect(() => {
    const defLang =
      process.env.REACT_APP_INTERFACE_LANGS?.split(",")?.[0] === "ua"
        ? "uk"
        : process.env.REACT_APP_INTERFACE_LANGS?.split(",")?.[0];
    i18next.changeLanguage(lang ?? (defLang || "uk"));
  }, []);

  useEffect(() => {
    if (
      !hotjar.initialized() &&
      process.env.REACT_APP_HOTJAR_SITE_ID &&
      Number(process.env.REACT_APP_HOTJAR_SITE_ID)
    ) {
      hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_SITE_ID), 6);
    }
  }, []);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.stateChange(window.location.pathname);
    }
  }, [window.location.pathname]);
  return (
    <Provider store={store}>
      <ModalProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route element={<PrivateOutlet />}>
              <Route path="/" element={<Report />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/payout-create" element={<PayoutCreate />} />
              <Route path="/payout-history" element={<PayoutHistory />} />
              <Route path="/payout-templates" element={<PayoutTemplates />} />

              <Route path="/admin/*" element={<Admin />} />

              <Route path="/card-top-up" element={<CardTopUp />} />
              <Route path="/crypto-top-up" element={<CryptoTopUp />} />
              <Route path="/invoice-top-up" element={<InvoiceTopUp />} />
              <Route path="/binance-pay-top-up" element={<BinancePayTopUp />} />
              <Route path="/w2w" element={<W2w />} />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/account-wallet-transactions"
                element={<AccountWalletTransactions />}
              />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/report-requests" element={<ReportRequests />} />

              <Route path="invoicing/*" element={<Invoicing />} />
              <Route path="invoice/*" element={<CheckoutInvoice />} />

              <Route path="/services/*" element={<ServicesRouter />} />

              <Route
                path="/transaction-fraud"
                element={<TransactionFrauds />}
              />
            </Route>
          </Routes>
        </Router>
      </ModalProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Provider>
  );
};

export default App;
