import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useCreateThemeMutation } from "api/baseAPI/customizer";
import { Layout } from "components/layouts/Layout";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useGetPointsAvailablePaymentMethodsQuery } from "api/baseAPI/points";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { ThemeForm } from "./ThemeForm";
import { useThemeFormLogic } from "./ThemeFormLogic";
import { PayFormThemePreview } from "./PayFormThemePreview";

export const ThemeCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadCrumbs = [
    [t("Управление"), ""],
    [t("Настройки формы"), "/admin/customizer"],
    [t("Создание темы"), ""],
  ];

  const selectedPointManager = useSelectedPointManager();
  const paymentMethodTypesQuery = useGetPointsAvailablePaymentMethodsQuery({
    point: selectedPointManager.point?.id,
  });

  const [createTheme, createThemeMeta] = useCreateThemeMutation();

  const { form, setSelectedFile, setImageUrl, imageUrl, selectedFile } =
    useThemeFormLogic({
      onSubmit: (values, setSubmitting) => {
        createTheme({
          name: values.name,
          point_id: selectedPointManager.point?.id,
          logo: selectedFile || "",
          background_color: values.background_color,
          panel_background_color_first: values.panel_background_color_first,
          panel_background_color_second: values.panel_background_color_second,
          accent_color: values.accent_color,
          primary_text_font: values.primary_text_font,
          primary_text_color: values.primary_text_color,
          secondary_text_color: values.secondary_text_color,
          button_text_color: values.button_text_color,
          ap_gp_style: values.ap_gp_style,
          inputs_style: values.inputs_style,
          inputs_background: values.inputs_background,
          border_radius: values.border_radius,
          delete_logo: false,
        })
          .unwrap()
          .then(() => {
            navigate("/admin/customizer");
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });

  useEffect(() => {
    if (
      paymentMethodTypesQuery.isSuccess &&
      paymentMethodTypesQuery.data?.length > 0
    ) {
      const findCardMethod = paymentMethodTypesQuery.data.find(
        (el) => el.slug  ===  "card_debit_hpp"
      );
      form.setFieldValue(
        "payment_slug",
        findCardMethod?.id
          ? { id: findCardMethod.id, slug: findCardMethod.slug }
          : null
      );
    }
  }, [paymentMethodTypesQuery]);

  useErrorNotification([createThemeMeta]);
  useSuccessNotification([createThemeMeta]);

  useServerErrorsInForm(form.setFieldError, createThemeMeta.error);

  return (
    <Layout title={t("Создание темы")} breadCrumbs={breadCrumbs}>
      <Box
        px={24}
        py={32}
        flex
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "calc(100vh - 124px)" }}
      >
        <Grid
          container
          hSpace={24}
          vSpace={24}
          style={{ position: "relative" }}
        >
          <Grid item sm={12} lg={3}>
            <ThemeForm
              submitButtonText={t("Сохранить")}
              form={form}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              onCancel={() => {
                navigate("/admin/customizer");
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={9}
            responsive={{ md: { mb: 0 }, sm: { mb: 80 } }}
          >
            <PayFormThemePreview form={form} logo={imageUrl} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
