import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { isBoolean } from "lodash";
import styled from "styled-components";
import * as Yup from "yup";
import {
  useBrandsAuthorizeMutation,
  useCheckOtpStatusMutation,
  useGetActiveBannerMutation,
  useGoogleLoginMutation,
  useLazyGetUsersSelfQuery,
  useLoginMutation,
} from "api/baseAPI/user";
import { LeftBlockLoginPage } from "components/LeftBlockLoginPage";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/LoginInput";
import { Modal } from "UI/Modal";
import getSettingStyles from "utils/getSettingStyles";
import { ReactComponent as EyeIcon } from "utils/img/eye.svg";
import { ReactComponent as HideEyeIcon } from "utils/img/hide-eye.svg";
import { ReactComponent as InputErrorIcon } from "utils/img/input-error-icon.svg";
import { ReactComponent as InputSuccessIcon } from "utils/img/input-success-icon.svg";
import { useErrorNotification } from "utils/notificationWrappers";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useAuth } from "hooks/useAuth";
import { useBanner } from "hooks/useSelectedEntity";
import { useStyle } from "hooks/useStyle";
import { setAuth } from "reducers/authSlice";
import { setBanner } from "reducers/basicSlice";

export const Login = () => {
  const [error, setError] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [disableRequestOTP, setDisableRequestOTP] = useState(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const auth = useAuth();
  const style = useStyle();
  const banner = useBanner();

  const [brandsAuthorize, brandsAuthorizeMeta] = useBrandsAuthorizeMutation();
  const [login, { isLoading }] = useLoginMutation();
  const [googleLogin] = useGoogleLoginMutation();
  const [getActiveBanner] = useGetActiveBannerMutation();
  const [getUsersSelf, getUsersSelfMeta] = useLazyGetUsersSelfQuery();
  const [checkOtpStatus, checkOtpStatusMeta] = useCheckOtpStatusMutation();

  getSettingStyles();

  useEffect(() => {
    document.title = "ЛК | Log in";
  });

  const {
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    validateField,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
      otp: "",
      show_password: false,
    },
    onSubmit: async (v) => {
      setError("");
      login({
        username: v.username,
        password: v.password,
        otp: v.otp,
      })
        .unwrap()
        .then((data) => {
          dispatch(
            setAuth({
              access_token: data.access_token,
              refresh_token: data.refresh_token,
            })
          );
          getUsersSelf();
        })
        .catch((err: any) => {
          if (err?.data?.error_description === "Invalid OTP code") {
            setFieldError("otp", "Invalid OTP code");
            setError("");
            return;
          }
          if (String(err?.status).startsWith("4")) {
            setError("Incorrect username or password");
          }
          if (String(err?.status).startsWith("5")) {
            setError("Oops! Something went wrong");
          }
        });
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Username/email is required"),
      password: Yup.string()
        .min(8, "Password should be minimun of 8 characters")
        .required("Password is required"),
      otp: Yup.string().when("password", {
        is: () => !!checkOtpStatusMeta?.data?.status,

        then: Yup.string()
          .min(6, "OTP should be minimun of 6 characters")
          .required("OTP is required"),
      }),
    }),
  });

  useEffect(() => {
    if (isValid && values.username && values.password && !disableRequestOTP) {
      checkOtpStatus({ username: values.username, password: values.password });
    }
  }, [isValid, values.username, values.password, disableRequestOTP]);

  useEffect(() => {
    getActiveBanner()
      .unwrap()
      .then((bannerData) => {
        const closedBanner = localStorage.getItem("closedBanner");
        if (bannerData?.id && closedBanner !== String(bannerData?.id))
          dispatch(setBanner(bannerData));
      });
  }, []);

  const handleCloseModalBanner = () => {
    localStorage.setItem("closedBanner", String(banner?.id));
    dispatch(setBanner(null));
  };

  useEffect(() => {
    if (disableRequestOTP) {
      setTimeout(() => {
        setDisableRequestOTP(false);
      }, 500);
    }
  }, [disableRequestOTP]);

  useEffect(() => {
    if (checkOtpStatusMeta.isSuccess)
      setShowOTP(checkOtpStatusMeta?.data?.status);
  }, [checkOtpStatusMeta]);

  useEffect(() => {
    if (errors.password && values.password) validateField("password");
  }, [errors.password, values.password]);

  useEffect(() => {
    if (auth.access_token && getUsersSelfMeta.isSuccess) {
      if (
        isBoolean(getUsersSelfMeta.data.is_approved) &&
        !getUsersSelfMeta.data.is_approved
      ) {
        navigate("/onboarding");
      } else {
        navigate("/");
      }
    }
  }, [getUsersSelfMeta, auth.access_token]);

  useEffect(() => {
    if (brandsAuthorizeMeta.isSuccess && brandsAuthorizeMeta.data?.token) {
      dispatch(
        setAuth({
          access_token: brandsAuthorizeMeta.data?.token,
          refresh_token: "",
        })
      );
      getUsersSelf();
    }
  }, [brandsAuthorizeMeta]);

  useErrorNotification([brandsAuthorizeMeta]);

  useEffect(() => {
    if (style.soc_registration_available) {
      window.onload = () => {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_ACCOUNT_CLIENT_ID,
          callback: (data: { credential: string }) => {
            googleLogin({ googleToken: data.credential })
              .unwrap()
              .then((resp) => {
                setAuth({
                  access_token: resp.access_token,
                  refresh_token: resp.refresh_token,
                });
                getUsersSelf();
              })
              .catch((err) => {
                console.error(err);
              });
          },
        });
        window.google.accounts.id.renderButton(
          document.getElementById("google-button"),
          {
            theme: "filled_blue",
            size: "large",
          }
        );
      };
    }
  }, []);

  return (
    <StyledLayout>
      <Modal
        isSmallTitle
        width="auto"
        open={!!banner?.id}
        onClose={handleCloseModalBanner}
      >
        <img
          style={{ maxWidth: "100vw" }}
          src={
            i18n.language === "en" && banner.image_en
              ? banner.image_en
              : banner.image_uk
          }
          alt=""
        />
      </Modal>
      <LeftBlockLoginPage />
      <StyledRightColumn>
        <StyledWraperForm>
          <StyledFakeHeader />
          <form onSubmit={handleSubmit}>
            <StyledTitleForm>Log in</StyledTitleForm>
            <StyledSubtitleForm>
              To login to your account, please fill in all fields.
            </StyledSubtitleForm>
            <Box mt={48}>
              <TextInput
                placeholder="Enter username or email address"
                name="username"
                value={values.username}
                onChange={(value) => {
                  setFieldValue("username", value);
                  if (!touched.username) setFieldTouched("username", true);
                  setTimeout(() => validateField("username"));
                  setDisableRequestOTP(true);
                }}
                error={(touched.username && !!errors.username) || !!error}
                helperText={
                  touched.username && errors.username ? errors.username : ""
                }
                {...(!errors.username && !!values.username && !error
                  ? {
                      icon: <InputSuccessIcon />,
                    }
                  : {})}
                {...(!!errors.username || !!error
                  ? {
                      icon: <InputErrorIcon />,
                    }
                  : {})}
              />
            </Box>
            <Box mt={16}>
              <TextInput
                placeholder="Enter password"
                type={values.show_password ? "text" : "password"}
                name="password"
                value={values.password}
                onChange={(value) => {
                  setFieldValue("password", value.slice(0, 30));
                  if (!touched.password) setFieldTouched("password", true);
                  setTimeout(() => validateField("password"));
                  setDisableRequestOTP(true);
                }}
                error={(touched.password && !!errors.password) || !!error}
                helperText={
                  touched.password && errors.password
                    ? errors.password
                    : error || ""
                }
                icon={
                  <StyledWrapperIcons>
                    <StyledWrapperFirstIcon
                      onClick={() =>
                        setFieldValue("show_password", !values.show_password)
                      }
                    >
                      {values.show_password ? <EyeIcon /> : <HideEyeIcon />}
                    </StyledWrapperFirstIcon>

                    {!errors.password && !!values.password && !error ? (
                      <StyledWrapperLastIcon>
                        <InputSuccessIcon />
                      </StyledWrapperLastIcon>
                    ) : null}
                    {!!errors.password || !!error ? (
                      <StyledWrapperLastIcon>
                        <InputErrorIcon />
                      </StyledWrapperLastIcon>
                    ) : null}
                  </StyledWrapperIcons>
                }
              />
            </Box>
            {showOTP && (
              <Box mt={16}>
                <TextInput
                  placeholder="Enter OTP"
                  name="otp"
                  value={values.otp}
                  onChange={(value) => {
                    setFieldValue(
                      "otp",
                      value.replace(/[^0-9]/g, "").slice(0, 6)
                    );
                    if (!touched.otp) setFieldTouched("otp", true);
                    setTimeout(() => validateField("otp"));
                  }}
                  error={touched.otp && !!errors.otp}
                  helperText={touched.otp && errors.otp ? errors.otp : ""}
                />
              </Box>
            )}
            <Box mt={40}>
              <StyledButton
                disabled={isLoading}
                fullwidth
                disabledUpperCase
                type="submit"
              >
                Log in
              </StyledButton>
              <Box py={15}>
                <div id="google-button" />
              </Box>
            </Box>
          </form>
          <Box mb={40}>
            <StyledWrapperLink>
              Don`t have an account? &nbsp;
              <StyledLink to="/signup">Sign up</StyledLink>
            </StyledWrapperLink>
            {style.guest_dashboard_available ? (
              <StyledWrapperLink>
                or sign in to the &nbsp;
                <StyledLinkButton onClick={() => brandsAuthorize()}>
                  Guest account
                </StyledLinkButton>
              </StyledWrapperLink>
            ) : (
              <></>
            )}
          </Box>
        </StyledWraperForm>
      </StyledRightColumn>
    </StyledLayout>
  );
};

// Styles
const StyledButton = styled(Button)`
  font-size: 20px;
  padding: 20px;
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 16px;
    line-height: 19px;
    padding: 15px;
  }
`;
const StyledLinkButton = styled.div`
  color: black;
  cursor: pointer;
  text-decoration: underline;
`;
const StyledLink = styled(Link)`
  color: black;
`;
const StyledLayout = styled.div`
  display: flex;
  min-height: 100vh;
`;
const StyledRightColumn = styled.div`
  width: calc(50% + 55px);
  padding-left: 120px;
  padding-right: 120px;
  display: flex;
  align-items: center;
  position: relative;
  @media (${RESPONSIVE_SIZES.lg}) {
    width: 100%;
    padding: 24px;
  }
`;
const StyledWraperForm = styled.div`
  width: 100%;
  margin: 0 128px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (${RESPONSIVE_SIZES.xxl}) {
    margin: 0;
  }
`;
const StyledTitleForm = styled.div`
  font-weight: 600;
  font-size: 48px;
  line-height: 32px;
  color: #000000;
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 32px;
  }
`;
const StyledSubtitleForm = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-top: 24px;
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 14px;
    line-height: 20px;
  }
`;
const StyledWrapperFirstIcon = styled.div`
  cursor: pointer;
`;
const StyledWrapperLastIcon = styled.div`
  margin-left: 20px;
`;
const StyledWrapperIcons = styled.div`
  display: flex;
`;
const StyledWrapperLink = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  color: #727272;
  margin-top: 12px;
  @media (${RESPONSIVE_SIZES.lg}) {
    margin-bottom: 24px;
  }
`;
const StyledFakeHeader = styled.div`
  font-size: 16px;
`;
