import React, { useState } from "react";
import { DateTime } from "luxon";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as Calendar } from "utils/img/calendar.svg";
import { getColors } from "utils/getColors";
import { getTimezone } from "utils/getTimezone";
import { ReactComponent as Close } from "utils/img/close.svg";
import { RESPONSIVE_SIZES } from "utils/tools";
import { hexToRGB } from "utils/mixColors";
import { RangeSelector } from "./RangeSelector";

export const DateSelector = ({
  className,
  right,
  disabledClear,
  label,
  start,
  end,
  withSwitch,
  valueSwitch,
  setSwitch,
  minDate,
  set_end,
  set_start,
}: {
  className?: string;
  right?: boolean;
  withSwitch?: boolean;
  valueSwitch?: boolean;
  setSwitch?: (value: boolean) => void;
  disabledClear?: boolean;
  label?: string;
  start: DateTime | null;
  end: DateTime | null;
  minDate?: DateTime | null;
  set_start: (value: string) => void;
  set_end: (value: string) => void;
}): React.ReactElement => {
  const { t } = useTranslation();
  const { accentColor } = getColors();
  const [focus, setFocus] = useState<boolean>(false);
  const timezone = getTimezone();

  return (
    <StyledDataSelector className={className}>
      <RangeSelector
        right={right}
        withSwitch={withSwitch}
        valueSwitch={valueSwitch}
        setSwitch={setSwitch}
        minDate={minDate || null}
        rangeStart={start ? start.setZone(timezone) : null}
        rangeEnd={end ? end.setZone(timezone) : null}
        dateFormat="yyyy.LL.dd"
        onChange={(startDate: DateTime | null, endDate: DateTime | null) => {
          if (startDate && endDate) {
            set_start(startDate.toISO());
            set_end(endDate.toISO());
          }
        }}
      >
        <StyledRelative>
          <StyledFieldset bordercolor={accentColor} focus={focus}>
            <StyledLabel empty={!label}>{label || ""}</StyledLabel>
            <StyledCalendar />
            <StyledTextInput
              readOnly
              bordercolor={accentColor}
              placeholder={t("Выбор периода")}
              value={
                start && end
                  ? `${start.setZone(timezone).toFormat("yyyy.LL.dd")} ${start
                      .setZone(timezone)
                      .toFormat("HH:mm")} - ${end
                      .setZone(timezone)
                      .toFormat("yyyy.LL.dd")} ${end
                      .setZone(timezone)
                      .toFormat("HH:mm")}`
                  : ""
              }
              onMouseDown={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          </StyledFieldset>
        </StyledRelative>
        {start && end && !disabledClear ? (
          <StyledClose
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              set_start("");
              set_end("");
            }}
          />
        ) : null}
      </RangeSelector>
    </StyledDataSelector>
  );
};

const StyledClose = styled(Close)`
  position: absolute;
  cursor: pointer;
  top: 14px;
  right: 10px;
  padding: 6px;
  z-index: 100;
`;
const StyledFieldset = styled.fieldset<{
  bordercolor: string;
  focus?: boolean;
}>`
  border: none;
  border: 1px solid #b9bfc8;
  padding: 0;
  margin-top: -8px;
  padding-left: 5px;
  &:hover {
    border: 1px solid ${(props) => hexToRGB(props.bordercolor, 0.4)};
    legend {
      color: ${(props) => hexToRGB(props.bordercolor, 0.4)};
    }
  }
  ${(props) =>
    props.focus
      ? `
          border: 1px solid ${props.bordercolor} !important;
          legend {
            color: ${props.bordercolor} !important;
          }
        `
      : ""};
`;

const StyledLabel = styled.legend<{ empty: boolean }>`
  font-style: normal;
  font-weight: 400;
  color: #19202e;
  max-width: calc(133% - 30px);
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 17px;
  height: 17px;
  ${(props) =>
    props.empty
      ? `
          padding-left: 0;
          padding-right: 0;
        `
      : ""};
`;
const StyledRelative = styled.div`
  position: relative;
  width: 100%;
`;
const StyledCalendar = styled(Calendar)`
  position: absolute;
  top: 14px;
  left: 14px;
`;

const StyledTextInput = styled.input<{ bordercolor: string }>`
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 6.5px;
  width: 100%;
  color: #19202e;
  box-sizing: border-box;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const StyledDataSelector = styled.div`
  @media (${RESPONSIVE_SIZES.md}) {
    width: 100%;
  }
`;
