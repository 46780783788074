import { find } from "lodash";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { MultiSelect } from "UI/Form/MultiSelect";
import { Select } from "UI/Form/Select";
import { Grid } from "UI/Grid";
import { TransactionsQueryParams } from "api/baseAPI/transactions";
import { useTransactionStatuses } from "api/baseAPI/wallets";
import { DateSelector } from "components/RangeSelector/DateSelector";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { setSelectedWallet } from "reducers/basicSlice";
import { IUseUrlQueryResult } from "utils/url";

export const TransactionsFiltersRow = ({
  queryParams,
  querySetters,
}: IUseUrlQueryResult<TransactionsQueryParams>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedPointManager = useSelectedPointManager();

  const TRANSACTION_STATUSES = useTransactionStatuses();

  return (
    <Grid
      container
      my={24}
      justifyContent="space-between"
      vSpace={24}
      hSpace={24}
      smHSpace={1}
      mdHSpace={1}
    >
      <Grid item sm={12} lg={6} xl={3}>
        <Select
          value={queryParams.account_wallet}
          onChange={(value) => {
            querySetters.set_page("1");
            querySetters.set_account_wallet(value);
            const findWallet = find(selectedPointManager?.available_wallets, {
              id: Number(value),
            });
            if (value && findWallet)
              dispatch(
                setSelectedWallet({
                  ...findWallet,
                })
              );
          }}
          label={t("Кошелек")}
          size="small"
          options={
            selectedPointManager?.available_wallets?.length > 0
              ? selectedPointManager?.available_wallets?.map(
                  (item: { id: number; name: string }) => ({
                    value: `${item.id}`,
                    label: `${item.id} | ${item.name}`,
                  })
                )
              : []
          }
        />
      </Grid>
      <Grid item sm={12} lg={6} xl={3}>
        <MultiSelect
          value={
            queryParams.status__in ? queryParams.status__in.split(",") : []
          }
          onChange={(val) => {
            querySetters.set_page("1");
            querySetters.set_status__in(val.join(","));
          }}
          label={t("Статус")}
          placeholder={t("Все статусы")}
          size="small"
          options={[
            ...Object.entries(TRANSACTION_STATUSES).map(([k, v]) => ({
              label: v,
              value: k,
            })),
          ]}
        />
      </Grid>
      <Grid item sm={12} lg={6} xl={3}>
        <Select
          value={queryParams.service__type}
          onChange={(val) => {
            querySetters.set_page("1");
            querySetters.set_service__type(val);
          }}
          label={t("Тип")}
          size="small"
          options={[
            { value: "", label: t("Все типы") },
            { value: "0", label: t("Внутренние") },
            { value: "1", label: t("Выплаты") },
            { value: "2", label: t("Эквайринг") },
            { value: "3", label: "p2p" },
          ]}
        />
      </Grid>
      <Grid item sm={12} lg={6} xl={3}>
        <DateSelector
          label={t("Период")}
          start={
            queryParams.created_at__gte
              ? DateTime.fromISO(queryParams.created_at__gte)
              : null
          }
          end={
            queryParams.created_at__lte
              ? DateTime.fromISO(queryParams.created_at__lte)
              : null
          }
          minDate={DateTime.local().minus({ year: 1 })}
          set_start={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__gte(val);
          }}
          set_end={(val) => {
            querySetters.set_page("1");
            querySetters.set_created_at__lte(val);
          }}
          right
        />
      </Grid>
    </Grid>
  );
}
