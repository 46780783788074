import { FormikProps, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { TextInput } from "UI/Form/TextInput";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { SocInvoiceDataFormValues } from "pages/services/soc-invoce-data/SocInvoiceDataCreate";

type Props = {
  formSocInvoiceData: FormikProps<SocInvoiceDataFormValues>;
  onClose: () => void;
};

export const ProductForm = (props: Props): React.ReactElement => {
  const { formSocInvoiceData, onClose } = props;
  const { t } = useTranslation();

  const form = useFormik({
    initialValues: {
      name: "",
      quantity: "",
      currency: formSocInvoiceData.values.amount_currency,
      cost: "",
      description: "",
    },
    onSubmit: (values) => {
      const products = [
        ...formSocInvoiceData.values.products,
        {
          id: Date.now(),
          ...values,
        },
      ];
      formSocInvoiceData.setFieldValue("products", products);
      onClose();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("Обязательное поле")),
      quantity: Yup.number()
        .transform((value, origin) => origin  ===  "" ? null : value)
        .nullable()
        .positive(t("Введите положительное число"))
        .required(t("Обязательное поле")),
      cost: Yup.number()
        .transform((value, origin) => origin  ===  "" ? null : value)
        .nullable()
        .positive(t("Введите положительное число"))
        .required(t("Обязательное поле")),
      currency: Yup.string().required(t("Обязательное поле")),
      description: Yup.string().required(t("Обязательное поле")),
    }),
  });

  useTranslateFormErrors(form);

  return (
    <Modal
      open
      hideHeader
      width={410}
      onClose={() => onClose()}
      disabledOverlay
    >
      <Box p={24}>
        <Typography variant="h4">{t("Добавление товара")}</Typography>
        <Box flex alignItems="center" justifyContent="space-between" mt={24}>
          <Box style={{ maxWidth: "calc(50% - 8px)", width: "100%" }}>
            <TextInput
              size="small"
              label={t("Сумма")}
              placeholder={t("Введите сумму")}
              onChange={(value) =>
                form.setFieldValue(
                  "cost",
                  value.replace(/[^0-9.]/g, "").slice(0, 14)
                )
              }
              value={form.values.cost}
              error={form.touched.cost && Boolean(form.errors.cost)}
              helperText={form.touched.cost && form.errors.cost}
            />
          </Box>
          <Box style={{ maxWidth: "calc(50% - 8px)", width: "100%" }}>
            <TextInput
              size="small"
              label={t("Количество")}
              placeholder={t("Введите количество")}
              onChange={(value) =>
                form.setFieldValue(
                  "quantity",
                  value.replace(/[^0-9.]/g, "").slice(0, 10)
                )
              }
              value={form.values.quantity}
              error={form.touched.quantity && Boolean(form.errors.quantity)}
              helperText={form.touched.quantity && form.errors.quantity}
            />
          </Box>
        </Box>
        <Box mt={24}>
          <TextInput
            size="small"
            label={t("Валюта")}
            placeholder={t("Выберите валюту")}
            onChange={(value) => form.setFieldValue("currency", value)}
            readOnly
            value={form.values.currency}
            error={form.touched.currency && Boolean(form.errors.currency)}
            helperText={form.touched.currency && form.errors.currency}
          />
        </Box>
        <Box mt={24}>
          <TextInput
            size="small"
            label={t("Название товара")}
            placeholder={t("Введите название")}
            onChange={(value) =>
              form.setFieldValue("name", value.slice(0, 100))
            }
            value={form.values.name}
            error={form.touched.name && Boolean(form.errors.name)}
            helperText={form.touched.name && form.errors.name}
          />
        </Box>
        <Box mt={24}>
          <TextInput
            size="small"
            label={t("Описание")}
            placeholder={t("Введите описание")}
            onChange={(value) =>
              form.setFieldValue("description", value.slice(0, 254))
            }
            multiline
            rows={3}
            value={form.values.description}
            error={form.touched.description && Boolean(form.errors.description)}
            helperText={form.touched.description && form.errors.description}
          />
        </Box>
        <Box flex alignItems="center" justifyContent="space-between" mt={24}>
          <Button
            mr={16}
            size="small"
            variant="outlined"
            onClick={() => onClose()}
          >
            {t("Отмена")}
          </Button>
          <Box style={{ maxWidth: "55%", width: "100%" }}>
            <Button size="small" fullwidth onClick={() => form.handleSubmit()}>
              {t("Добавить")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
