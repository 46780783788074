import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Typography } from "UI/Typography";
import { ReactComponent as SquareCheckIcon } from "utils/img/square-check.svg";

type Props = {
  create?: boolean;
  isTemplate?: boolean;
};

export const ModalSuccess = (props: Props): React.ReactElement => {
  const { create, isTemplate } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box p={27} style={{ textAlign: "center" }}>
      <Box
        flex
        alignItems="center"
        justifyContent="center"
        style={{
          height: 84,
          width: 84,
          background: "rgba(62, 169, 89, 0.1)",
          margin: "auto",
        }}
      >
        <SquareCheckIcon />
      </Box>
      {!isTemplate ? (
        <>
          <Typography variant="h4" mt={24}>
            {create
              ? t("Инвойс успешно создан!")
              : t("Инвойс успешно обновлен!")}
          </Typography>
        </>
      ) : (
        <Typography variant="h4" mt={24}>
          {create ? t("Шаблон успешно создан!") : t("Шаблон успешно обновлен!")}
        </Typography>
      )}
      <Button
        mt={24}
        size="small"
        fullwidth
        onClick={() =>
          navigate(!isTemplate ? "/invoice/invoices" : "/invoice/templates")
        }
      >
        {!isTemplate ? t("К странице инвойсов") : t("К шаблонам")}
      </Button>
    </Box>
  );
};
