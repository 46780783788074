import React, { ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getColors } from "utils/getColors";
import { ReactComponent as MoreVertical } from "utils/img/more-vertical.svg";

interface TableRowActionsProps {
  actions: ReactElement[];
  long?: boolean;
}

export const TableRowActions = ({ actions, long }: TableRowActionsProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [display, setDisplay] = useState<boolean>(false);
  const [top, setTop] = useState<number>(0);
  const [left, setLeft] = useState<number>(0);

  const { accentColor, pageBackground } = getColors();

  const handleClick = (item: ReactElement) => {
    if (item.props?.onClick) item.props?.onClick();
    setDisplay(false);
  };
  if (actions.length === 0) return null;

  return (
    <StyledWrapper
      tabIndex={0}
      onClick={(e) => {
        if (!display) {
          const menuHeight = 50 * (actions.length + 1);
          setTop(
            e.clientY -
              (window.innerHeight - e.clientY < menuHeight ? menuHeight : 0)
          );
          setLeft(e.clientX - (long ? 280 : 200));
        } else {
          setTop(0);
          setLeft(0);
        }
        setDisplay(!display);
      }}
    >
      <StyledMoreVertical
        id="tableRowActionsMenu"
        accentcolor={accentColor}
        pagebackground={pageBackground}
        active={`${display}`}
        title={t("Действия")}
      />
      {display && (
        <>
          <StyledModalOverlay onClick={() => setDisplay(false)} />
          <StyledMenu
            id="tableRowActionsMenu"
            ref={ref}
            top={top}
            left={left}
            pagebackground={pageBackground}
          >
            {actions.map((item: ReactElement) =>
              React.cloneElement(item, {
                onClick: () => handleClick(item),
                style: { minWidth: "150px" },
              })
            )}
          </StyledMenu>
        </>
      )}
    </StyledWrapper>
  );
};

const StyledModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledMoreVertical = styled(MoreVertical)<{
  accentcolor: string;
  pagebackground: string;
  active: string;
}>`
  cursor: pointer;
  padding: 5px 8px;
  margin-top: 1px;
  &:hover {
    background: ${(props) => props.pagebackground};
    path {
      fill: ${(props) => props.accentcolor} !important;
    }
  }
  ${(props) =>
    props.active === "true"
      ? `
          background: ${props.pagebackground};
          path {
            fill: ${props.accentcolor} !important;
          }
        `
      : ""};
`;

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  // right: 20px;
  transform: translate(8px, 30px);
  background: #ffffff;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.18);
  padding: 16px 24px;
  z-index: 110;
  div {
    border-bottom: 1px solid #c8cfdc;
    &:hover {
      background: ${(props: {
        pagebackground: string;
        top: number;
        left: number;
      }) => props.pagebackground};
    }
  }
  a {
    border-bottom: 1px solid #c8cfdc;
    &:hover {
      background: ${(props: {
        pagebackground: string;
        top: number;
        left: number;
      }) => props.pagebackground};
    }
  }
  & > div:last-child {
    border-bottom: none;
  }
`;

export const MenuItemAction = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 4px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #19202e;
  svg {
    margin-right: 10px;
    path {
      fill: #838383 !important;
    }
  }
`;

export const MenuItemActionA = styled.a`
  text-decoration: none;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 4px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #19202e;
  svg {
    margin-right: 10px;
    path {
      fill: #838383 !important;
    }
  }
`;

export const IconColor = styled.span`
  svg {path {
    fill: ${(props: { color: string }) => props.color} !important;
  }
`;
