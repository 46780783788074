import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStyle } from "hooks/useStyle";
import { RESPONSIVE_SIZES } from "utils/tools";

type TextInputProps = {
  placeholder?: string;
  name?: string;
  type?: "text" | "password";
  value?: string;
  disabled?: boolean;
  onChange?: (val: string) => void;
  icon?: React.ReactElement;
  error?: boolean;
  helperText?: string;
};

export const TextInput = ({
  placeholder,
  name,
  type,
  value,
  disabled,
  onChange,
  icon,
  error,
  helperText,
}: TextInputProps) => {
  const style = useStyle();
  const [readOnly, setReadOnly] = useState(name !== "password");

  useEffect(() => {
    setTimeout(() => setReadOnly(false), 500);
  }, []);

  return (
    <StyledTextInputWrapper disabled={disabled}>
      <StyledTextInput
        type={type || "text"}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        icon={icon}
        disabled={disabled}
        error={!!error}
        bordercolor={style.accent_color}
        // autoComplete="off"
        readOnly={readOnly}
      />
      {icon ? <StyledInputIcon>{icon}</StyledInputIcon> : null}
      {helperText ? (
        <StyledHelperText error={!!error}>{helperText}</StyledHelperText>
      ) : null}
    </StyledTextInputWrapper>
  );
};

const StyledTextInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: ${(props: { disabled?: boolean }) => (props.disabled ? "0.5" : "1")};
`;

type StyledInputProps = {
  icon?: React.ReactElement;
  error?: boolean;
  bordercolor?: string;
};

const StyledTextInput = styled.input`
  border: none;
  border: 1px solid
    ${(props: StyledInputProps) => (props.error ? "#e41919" : "#d5d5d5")};
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding: 21px 24px;
  width: 100%;
  color: #000000;
  box-sizing: border-box;
  padding-right: ${(props: StyledInputProps) => (props.icon ? "85px" : "40px")};
  &:hover {
    border: 1px solid
      ${(props: StyledInputProps) => (props.error ? "#e41919" : "#434343")};
  }
  &:focus {
    outline: none;
    border: 1px solid
      ${(props: StyledInputProps) =>
        props.error ? "#e41919" : props.bordercolor || "#00c06e"};
  }
  &:placeholder {
    color: #727272;
  }
  &:disabled {
    opacity: 0.5;
    border: 1px solid #ececec;
  }
  &:-webkit-autofill ~ label {
    opacity: 1;
  }
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 14px;
    line-height: 17px;
    padding: 15px;
    padding-right: ${(props: StyledInputProps) =>
      props.icon ? "85px" : "40px"};
  }
`;

const StyledHelperText = styled.div`
  position: absolute;
  bottom: 1px;
  left: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #e41919;
  color: ${(props: { error: boolean }) =>
    props.error ? "#e41919" : "#727272"};
  @media (${RESPONSIVE_SIZES.lg}) {
    font-size: 12px;
  }
`;

const StyledInputIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  @media (${RESPONSIVE_SIZES.lg}) {
    top: 15px;
    right: 15px;
  }
`;
