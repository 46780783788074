/* eslint-disable no-useless-escape */
export default `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Оплата заказа онлайн</title>

</head>
<body>
    <a href="#" target="_top" class="back-to-shop-icon cancel-btn-hidden"></a>
<div class="main">

    <div class="main__wrapper">

        <div class="head-payment">
            <div class="head-payment__logo-cont">
                <div class="head-payment__logo"></div>
            </div>
            <div class="head-payment__back-link-cont">
                <!--<a href="/" target="_top" class="head-payment__back-link">-->
                    <!--Отменить оплату-->
                <!--</a>-->
            </div>
            <div class="head-payment__info">
                <div>
                    <div class="head-payment__lock-cont" id="lockSecure_upd" hidden>
                        <svg class="head-payment__lock" viewBox="0 0 16 21" style="height: 21px;">
                            <g id="Business_web" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="logo_lock" class="head-payment__lock-inner"
                                   transform="translate(-338.000000, -514.000000)" fill="#6aa52a">
                                    <g id="ic_lock" transform="translate(338.000000, 514.000000)">
                                        <g id="lock">
                                            <path d="M14,7 L13,7 L13,5 C13,2.2 10.8,0 8,0 C5.2,0 3,2.2 3,5 L3,7 L2,7 C0.9,7 0,7.9 0,9 L0,19 C0,20.1 0.9,21 2,21 L14,21 C15.1,21 16,20.1 16,19 L16,9 C16,7.9 15.1,7 14,7 L14,7 Z M8,16 C6.9,16 6,15.1 6,14 C6,12.9 6.9,12 8,12 C9.1,12 10,12.9 10,14 C10,15.1 9.1,16 8,16 L8,16 Z M11.1,7 L4.9,7 L4.9,5 C4.9,3.3 6.3,1.9 8,1.9 C9.7,1.9 11.1,3.3 11.1,5 L11.1,7 L11.1,7 Z"
                                                  id="Shape">
                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <spam class="head-payment__lock-text">Безопасная передача данных</spam>
                    </div>
                <p class="head-payment__info-head">
                    Сумма
                </p>
                <p class="head-payment__info-amount">
                    150000.56<span>UAH</span>
                </p>
                <p class="head-payment__info-text">
                    Transaction description #123456
                </p>
            </div>
            </div>
        </div>

        <div class="form-payment">
            <div class="form-payment__form-cont">
                <form id="form1" method="post" name="form" class="form-payment__form">
                    <!--<div class="form-payment__form-inner-cont">-->
                        <div class="form-payment__card-number-cont">
                            <div class="form-payment__card-info" id="card-type_upd" hidden>
                                <div class="form-payment__card-type"></div>
                                <div class="form-payment__card-bank">
                                </div>
                            </div>
                            <p class="form-payment__card-number-title">
                                Номер карты
                            </p>
                            <input
                                    type="tel"
                                    name="card_number"
                                    class="form-payment__card-number"
                                    readonly
                                    placeholder="●●●● ●●●● ●●●● ●●●●"
                            >
                            <div class="form-payment__card-number-line" id="card_number_line"></div>
                            <div class="error-input-cont form-payment__constraint-error-card-number">
                                <p class="error-input" id="form-payment__constraint-error-card-number"></p>
                            </div>
                            <div class="form-payment__card-number-line_before" id="card_number_line_before"></div>
                        </div>
                        <div class="form-payment__info-cont">
                            <div class="form-payment__date-cont">
                                <p class="form-payment__date-title">
                                    Срок действия
                                </p>
                                <input
                                        type="tel"
                                        name="expire_date"
                                        class="form-payment__date"
                                        placeholder="MM/YY"
                                        maxlength="7"
                                        readonly
                                >
                                <div class="form-payment__date-line" id="card_date_line"></div>
                                <div class="form-payment__date-line_before" id="card_date_line_before"></div>
                            </div>
                            <div class="form-payment__cvv-cont">
                                <div class="form-payment__cvv-title">
                                    CVV2
                                </div>
                                <input
                                    type="tel"
                                    pattern="\d*"
                                    name="cvv"
                                    class="form-payment__cvv"
                                    placeholder="●●●"
                                    maxlength="3"
                                    readonly
                                >
                                <div class="form-payment__cvv-line"></div>
                            </div>
                            <div id="cvv-hint_upd" hidden>
                                <div class="cvv-card_upd">
                                    <div class="cvv-card__line"></div>
                                    <p class="cvv-card__text">
                                        1234 <span class="cvv-card__text-cvv">123</span>
                                    </p>
                                </div>
                            </div>
                            <div class="form-payment__lock-cont" id="lockSecure">
                                <svg class="form-payment__lock" viewBox="0 0 16 21">
                                    <g id="Business_web" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="logo_lock" class="form-payment__lock-inner" transform="translate(-338.000000, -514.000000)" fill="#dadee4">
                                            <g id="ic_lock" transform="translate(338.000000, 514.000000)">
                                                <g id="lock">
                                                    <path d="M14,7 L13,7 L13,5 C13,2.2 10.8,0 8,0 C5.2,0 3,2.2 3,5 L3,7 L2,7 C0.9,7 0,7.9 0,9 L0,19 C0,20.1 0.9,21 2,21 L14,21 C15.1,21 16,20.1 16,19 L16,9 C16,7.9 15.1,7 14,7 L14,7 Z M8,16 C6.9,16 6,15.1 6,14 C6,12.9 6.9,12 8,12 C9.1,12 10,12.9 10,14 C10,15.1 9.1,16 8,16 L8,16 Z M11.1,7 L4.9,7 L4.9,5 C4.9,3.3 6.3,1.9 8,1.9 C9.7,1.9 11.1,3.3 11.1,5 L11.1,7 L11.1,7 Z" id="Shape">
                                                    </path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <div class="form-payment__lock-info-cont">
                                    <p class="form-payment__lock-info-title">
                                        Безопасная передача данных
                                    </p>
                                    <p class="form-payment__lock-info-text">
                                        Используется безопасный протокол с
                                        <span>256-битным</span> шифрованием
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="form-payment__btn-cont">
                            <input type="button" value="Оплатить" class="form-payment__btn">
                        </div>

                        <div class="donate">
                            <a href="#" target="_blank" class="donate__btn">
                              <span></span>
                              Збір коштів
                            </a>
                            <p class="donate__descr">
                              На потреби українців, що потерпіли від російської агресії
                            </p>
                        </div>
                </form>
            </div>
        </div>

    </div>

    <div class="footer">
        <div class="footer__img-cont">
            <img
                src="/img/mastercard-securecode.svg"
                alt="mastercard-securecode"
                class="footer__img"
            >
            <img
                src="/img/verified-by-visa.svg"
                alt="verified-by-visa"
                class="footer__img"
            >
            <img
                src="/img/pci-dss.png"
                alt="pci-dss.png"
                class="footer__img"
            >
        </div>
    </div>

</div>

</body>
</html>
`;
