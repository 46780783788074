import styled from "styled-components";
import { map } from "lodash";
import { getColors } from "utils/getColors";

type TabListProps = {
  value: string;
  onChange: (val: string) => void;
  items: { label: string; value: string }[];
};

export const TabList = (props: TabListProps) => {
  const { value, onChange, items } = props;
  const { accentColor } = getColors();

  return (
    <StyledTabWrapper>
      {map(items, (item) => (
        <StyledTab
          key={item.value}
          active={value === item.value}
          accentcolor={accentColor}
          onClick={() => onChange(item.value)}
        >
          {item.label?.toLocaleUpperCase()}
        </StyledTab>
      ))}
    </StyledTabWrapper>
  );
};

// Styles
const StyledTabWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  position: relative;
  text-align: center;
  display: flex;
`;

const StyledTab = styled.div`
  padding: 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  min-width: 150px;
  cursor: pointer;
  transition: left 0.25s;
  color: ${(props: { active: boolean; accentcolor: string }) =>
    props.active ? props.accentcolor : "#838383"};
  border-bottom: 2px solid
    ${(props) => (props.active ? props.accentcolor : "#838383")};
`;
