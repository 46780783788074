import styled from "styled-components";
import { useStyle } from "hooks/useStyle";
import noise from "utils/img/noise.png";
import { RESPONSIVE_SIZES } from "utils/tools";

export const LeftBlockLoginPage = () => {
  const style = useStyle();

  return (
    <StyledColumn>
      <StyledWraperInfo>
        {style.logo ? <StyledLogo src={style.logo} /> : <></>}
        <StyledSubtitleInfo>We are</StyledSubtitleInfo>
        <StyledTitleInfo>Online payment service</StyledTitleInfo>
        <StyledTextInfo>{style.description}</StyledTextInfo>
      </StyledWraperInfo>
      <StyledEllipseTop />
      <StyledEllipseBottom background={style.background_color} />
      <StyledGradient />
    </StyledColumn>
  );
};

// Styles
const StyledColumn = styled.div`
  width: calc(50% - 55px);
  position: relative;
  background: #1b1b22;
  padding-bottom: 82px;
  display: flex;
  align-items: flex-end;
  @media (${RESPONSIVE_SIZES.lg}) {
    display: none;
  }
`;
const StyledLogo = styled.img`
  max-width: 250px;
  max-height: 80px;
`;
const StyledWraperInfo = styled.div`
  width: 100%;
  padding: 48px;
  margin: 0 64px;
  background: rgba(0, 0, 0, 0.48);
  backdrop-filter: blur(12px);
  color: #ffffff;
  z-index: 15;
`;
const StyledSubtitleInfo = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin-top: 32px;
`;
const StyledTitleInfo = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  margin-top: 8px;
`;
const StyledTextInfo = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
`;
const StyledEllipseTop = styled.div`
  position: absolute;
  width: 292px;
  height: 296px;
  border-radius: 50%;
  right: -170px;
  top: 34px;
  z-index: 12;

  background: rgba(255, 255, 255, 0.34);
  filter: blur(300px);
`;
const StyledEllipseBottom = styled.div`
  position: absolute;
  width: 292px;
  height: 296px;
  border-radius: 50%;
  left: 3px;
  bottom: 80px;
  z-index: 12;

  background: ${(props: { background: string }) =>
    props.background || "#00C06E"};
  filter: blur(300px);
`;
const StyledGradient = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 13;
  background: url(${noise});
  opacity: 0.8;
`;
