import { FormikProps } from "formik";
import { isNumber, map } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Checkbox } from "UI/Form/Checkbox";
import { MultiSelect } from "UI/Form/MultiSelect";
import { Select } from "UI/Form/Select";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useGetThemesQuery } from "api/baseAPI/customizer";
import { useGetPointsAvailablePaymentMethodsQuery } from "api/baseAPI/points";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { getColors } from "utils/getColors";
import { ReactComponent as CaretCircleUpIcon } from "utils/img/caret-circle-up.svg";
import { RESPONSIVE_SIZES } from "utils/tools";
import { FormValues } from "./CustomizationRuleFormLogic";

interface CustomizationRuleFormProps {
  submitButtonText: string;
  form: FormikProps<FormValues>;
  ruleID?: string;
  onCancel?: () => void;
}

export const CustomizationRuleForm = ({
  submitButtonText,
  form,
  ruleID,
  onCancel,
}: CustomizationRuleFormProps) => {
  const { t } = useTranslation();

  const [openBasicSettings, setOpenBasicSettings] = useState(true);
  const [openOptionSettings, setOpenOptionSettings] = useState(true);
  const [openContactSettings, setOpenContactSettings] = useState(true);

  const selectedPointManager = useSelectedPointManager();

  const { pageBackground } = getColors();

  const paymentMethodTypesQuery = useGetPointsAvailablePaymentMethodsQuery({
    point: selectedPointManager.point?.id,
  });

  const themesQuery = useGetThemesQuery({
    page: "1",
    page_size: "100",
    point_id: selectedPointManager.point?.id,
  });

  useEffect(() => {
    if (!form.values.is_ap_available && !form.values.is_gp_available) {
      form.setFieldValue("ap_gp_position", "1");
    }
  }, [form.values.is_ap_available, form.values.is_gp_available]);

  useEffect(() => {
    if (form.values.ap_gp_position === 3) {
      form.setFieldValue("is_ap_available", false);
      form.setFieldValue("is_gp_available", false);
    }
  }, [form.values.ap_gp_position]);

  return (
    <>
      <Box>
        <Typography variant="h5">{t("Параметры правила")}</Typography>
        {ruleID && (
          <TextInput value={ruleID} label="ID" size="small" disabled mt={24} />
        )}
        <Box
          flex
          alignItems="center"
          mt={24}
          style={{ cursor: "pointer" }}
          onClick={() => setOpenBasicSettings(!openBasicSettings)}
        >
          <Typography variant="h5" mr={16}>
            {t("Общие настройки")}
          </Typography>
          <StyledCaretCircleUpIcon open={openBasicSettings} />
        </Box>
        {openBasicSettings ? (
          <Grid container pt={24} hSpace={24} vSpace={24}>
            <Grid item sm={12}>
              <TextInput
                size="small"
                label={t("Название")}
                placeholder={t("Введите название")}
                onChange={(value) => form.setFieldValue("name", value)}
                value={form.values.name}
                error={form.touched.name && Boolean(form.errors.name)}
                helperText={form.touched.name && form.errors.name}
              />
            </Grid>
            <Grid item sm={12}>
              <Select
                value={form.values.light}
                onChange={(value) => {
                  form.setFieldValue("light", value);
                }}
                label={`${t("Вариант отображения")} 1`}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  ...map(themesQuery.data?.results, (item) => ({
                    label: item.name,
                    value: String(item.id),
                  })),
                ]}
                error={form.touched.light && Boolean(form.errors.light)}
                helperText={form.touched.light && form.errors.light}
              />
            </Grid>
            <Grid item sm={12}>
              <Select
                value={form.values.dark}
                onChange={(value) => {
                  form.setFieldValue("dark", value);
                }}
                label={`${t("Вариант отображения")} 2`}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  ...map(themesQuery.data?.results, (item) => ({
                    label: item.name,
                    value: String(item.id),
                  })),
                ]}
                error={form.touched.dark && Boolean(form.errors.dark)}
                helperText={form.touched.dark && form.errors.dark}
              />
            </Grid>
            <Grid item sm={12}>
              <Checkbox
                value={!form.values.theme_switch}
                onChange={() =>
                  form.setFieldValue("theme_switch", !form.values.theme_switch)
                }
                label={t("Добавить переключатель тем")}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Box
          flex
          alignItems="center"
          mt={32}
          style={{ cursor: "pointer" }}
          onClick={() => setOpenOptionSettings(!openOptionSettings)}
        >
          <Typography variant="h5" mr={16}>
            {t("Настройки опций")}
          </Typography>
          <StyledCaretCircleUpIcon open={openOptionSettings} />
        </Box>
        {openOptionSettings ? (
          <Grid container pt={24} hSpace={24} vSpace={24}>
            <Grid item sm={6}>
              <Checkbox
                value={!form.values.is_ap_available}
                onChange={() =>
                  form.setFieldValue(
                    "is_ap_available",
                    !form.values.is_ap_available
                  )
                }
                label="Apple Pay"
              />
            </Grid>
            <Grid item sm={6}>
              <Checkbox
                value={!form.values.is_gp_available}
                onChange={() =>
                  form.setFieldValue(
                    "is_gp_available",
                    !form.values.is_gp_available
                  )
                }
                label="Google Pay"
              />
            </Grid>

            <Grid item sm={12}>
              <Select
                value={
                  form.values.ap_gp_position
                    ? String(form.values.ap_gp_position)
                    : ""
                }
                onChange={(value) => {
                  form.setFieldValue("ap_gp_position", value);
                }}
                label={t("Позиция AP/GP")}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  {
                    label: t("Верх"),
                    value: "1",
                  },
                  {
                    label: t("Низ"),
                    value: "2",
                  },
                  {
                    label: t("Нет"),
                    value: "3",
                  },
                ]}
                error={
                  form.touched.ap_gp_position &&
                  Boolean(form.errors.ap_gp_position)
                }
                helperText={
                  form.touched.ap_gp_position && form.errors.ap_gp_position
                }
              />
            </Grid>
            <Grid item sm={12}>
              <MultiSelect
                value={
                  form.values.available_payment_methods?.map((el) =>
                    String(el.id)
                  ) || []
                }
                onChange={(val) =>
                  form.setFieldValue(
                    "available_payment_methods",
                    paymentMethodTypesQuery?.data?.filter((el) =>
                      val?.includes(String(el.id))
                    )
                  )
                }
                label={t("Доступные платежные методы")}
                placeholder={t("Все")}
                size="small"
                options={
                  paymentMethodTypesQuery?.data &&
                  paymentMethodTypesQuery?.data?.length > 0
                    ? paymentMethodTypesQuery?.data?.map((item) => ({
                        value: `${item.id}`,
                        label: item.slug,
                      }))
                    : []
                }
                error={
                  form.touched.available_payment_methods &&
                  Boolean(form.errors.available_payment_methods)
                }
                helperText={
                  form.touched.available_payment_methods &&
                  String(form.errors.available_payment_methods || "")
                }
              />
            </Grid>
            <Grid item sm={12}>
              <MultiSelect
                value={form.values.locales}
                onChange={(val) => form.setFieldValue("locales", val)}
                label={t("Доступные локали")}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  { label: "en", value: "en" },
                  { label: "uk", value: "uk" },
                  { label: "ru", value: "ru" },
                  { label: "pt", value: "pt" },
                  { label: "es", value: "es" },
                  { label: "az", value: "az" },
                ]}
                error={form.touched.locales && Boolean(form.errors.locales)}
                helperText={
                  form.touched.locales && String(form.errors.locales || "")
                }
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Box
          flex
          alignItems="center"
          mt={32}
          style={{ cursor: "pointer" }}
          onClick={() => setOpenContactSettings(!openContactSettings)}
        >
          <Typography variant="h5" mr={16}>
            {t("Настройки контактов")}
          </Typography>
          <StyledCaretCircleUpIcon open={openContactSettings} />
        </Box>
        {openContactSettings ? (
          <Grid container pt={24} hSpace={24} vSpace={24}>
            <Grid item sm={12}>
              <Select
                value={
                  isNumber(form.values.oferta_style)
                    ? String(form.values.oferta_style)
                    : ""
                }
                onChange={(value) => {
                  form.setFieldValue("oferta_style", Number(value) || "");
                }}
                label={t("Отображение оферты")}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  { label: t("Нет"), value: "0" },
                  { label: t("Публичная оферта"), value: "1" },
                  { label: t("Полный текст с офертой"), value: "2" },
                ]}
                error={
                  form.touched.oferta_style && Boolean(form.errors.oferta_style)
                }
                helperText={
                  form.touched.oferta_style && form.errors.oferta_style
                }
              />
            </Grid>
            <Grid item sm={12}>
              <MultiSelect
                value={form.values.partners_logos}
                onChange={(val) => form.setFieldValue("partners_logos", val)}
                label={t("Футер")}
                placeholder={t("Выберите")}
                size="small"
                options={[
                  {
                    label: "Mastercard",
                    value: "/static-public/partners/mc-secure.svg",
                  },
                  {
                    label: "Visa",
                    value: "/static-public/partners/visa-verify.svg",
                  },
                  {
                    label: "PCI DSS",
                    value: "/static-public/partners/pci-dss.svg",
                  },
                ]}
                error={
                  form.touched.partners_logos &&
                  Boolean(form.errors.partners_logos)
                }
                helperText={
                  form.touched.partners_logos &&
                  String(form.errors.partners_logos || "")
                }
              />
            </Grid>
            <Grid item sm={12}>
              <TextInput
                size="small"
                label={t("Компания / Вебсайт")}
                placeholder={t("Введите значение")}
                onChange={(value) => form.setFieldValue("company_name", value)}
                value={form.values.company_name}
                error={
                  form.touched.company_name && Boolean(form.errors.company_name)
                }
                helperText={
                  form.touched.company_name && form.errors.company_name
                }
              />
            </Grid>
            <Grid item sm={12}>
              <TextInput
                size="small"
                label={t("Ссылка на мессенджер")}
                placeholder={t("Введите значение")}
                onChange={(value) => form.setFieldValue("messenger", value)}
                value={form.values.messenger}
                error={form.touched.messenger && Boolean(form.errors.messenger)}
                helperText={form.touched.messenger && form.errors.messenger}
              />
            </Grid>
            <Grid item sm={12}>
              <TextInput
                size="small"
                label={t("Телефон")}
                placeholder={t("Введите значение")}
                onChange={(value) =>
                  form.setFieldValue(
                    "phone_number",
                    value.replaceAll(/\D/g, "")
                  )
                }
                value={form.values.phone_number}
                error={
                  form.touched.phone_number && Boolean(form.errors.phone_number)
                }
                helperText={
                  form.touched.phone_number && form.errors.phone_number
                }
              />
            </Grid>
            <Grid item sm={12}>
              <TextInput
                size="small"
                label="Email"
                placeholder={t("Введите значение")}
                onChange={(value) => form.setFieldValue("email", value)}
                value={form.values.email}
                error={form.touched.email && Boolean(form.errors.email)}
                helperText={form.touched.email && form.errors.email}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
      <StyledGridButtons
        background={pageBackground}
        container
        hSpace={24}
        mt={24}
      >
        <Grid item sm={6}>
          <Button variant="outlined" fullwidth onClick={onCancel}>
            {t("Отмена")}
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            variant="contained"
            onClick={form.submitForm}
            fullwidth
            disabled={form.isSubmitting}
          >
            {submitButtonText}
          </Button>
        </Grid>
      </StyledGridButtons>
    </>
  );
};

const StyledCaretCircleUpIcon = styled(CaretCircleUpIcon)`
  ${(props: { open: boolean }) =>
    !props.open ? "transform: rotate(180deg)" : ""};
`;

const StyledGridButtons = styled(Grid)<{
  background: string;
}>`
  @media (${RESPONSIVE_SIZES.sm}) {
    position: fixed;
    bottom: 88px;
    width: 100vw;
    background: ${(props) => props.background};
    padding: 24px;
    margin: -24px;
    z-index: 105;
  }
`;
