export type Text = {
  textAlign?: "left" | "center" | "right";
  textTransform?: "lowercase" | "uppercase" | "capitalize";
  fontSize?: number;
  fontWeight?: "bold" | "normal" | "thin";
};

export function textProps(props: Text) {
  return (({ textAlign, textTransform, fontSize, fontWeight }) => ({
    textAlign,
    textTransform,
    fontSize,
    fontWeight,
  }))(props);
}

export function textStyle(props: Text) {
  return `
    ${props.textAlign ? `text-align: ${  props.textAlign  };` : ""}
    ${props.textTransform ? `text-transform: ${  props.textTransform  };` : ""}
    ${props.fontSize ? `font-size: ${  props.fontSize  }px;` : ""}
    ${props.fontWeight ? `font-weight: ${  props.fontWeight  };` : ""}
  `;
}
