import { useTranslation } from "react-i18next";
import { baseSplitApi } from "./index";

export interface Wallet {
  id: number;
  name: string;
  type: number;
  status: number;
  balance: string;
  currency: string;
  balance_min: string;
  balance_hold: string;
  rolling_reserve_amount: string;
  account: { id: number; name: string };
}

interface DataRequest {
  id: number;
  created_at__lte: string;
  created_at__gte: string;
}

export const useTransactionStatuses = (): Record<number, string> => {
  const { t } = useTranslation();
  return {
    0: t("Новая"),
    1: t("Успех"),
    2: t("Ошибка"),
    3: t("Отмена"),
    4: t("Реверс"),
    5: t("Таймаут"),
    10: t("В ожидании"),
  };
};

export const walletsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getWallets: builder.query<{ count: number; results: Wallet[] }, void>({
      query: () => `/wallets/`,
    }),
    getWallet: builder.query<Wallet, { id: number }>({
      query: ({ id }) => `/wallets/${id}/`,
      providesTags: ["Wallet"],
    }),
    getWalletTurnover: builder.query<
      {
        incoming_sum: number;
        incoming_total: number;
        outgoing_sum: number;
        outgoing_total: number;
      },
      DataRequest
    >({
      query: ({ id, ...params }) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/retriever/v0/wallets/${id}/turnover/?${query.toString()}`;
      },
    }),
    getWalletFailedTransactions: builder.query<
      {
        amount: number;
        count: number;
        failure_reason: string;
        failure_reason_code: number;
      }[],
      DataRequest
    >({
      query: ({ id, ...params }) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/retriever/v0/wallets/${id}/failed-transactions/?${query.toString()}`;
      },
    }),
    getWalletTransactionStatuses: builder.query<
      {
        failure_reason: string;
        status: keyof ReturnType<typeof useTransactionStatuses>;
        amount: number;
        count: number;
      }[],
      DataRequest & {
        status: string;
        service__type: string;
      }
    >({
      query: ({ id, status, ...params }) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/retriever/v0/wallets/${id}/${
          status ? "failed-transactions" : "transaction-statuses"
        }/?${query.toString()}`;
      },
    }),
    getWalletPeriodStats: builder.query<
      Record<
        string,
        {
          incoming: { amount: number; count: number };
          outgoing: { amount: number; count: number };
        }
      >,
      DataRequest
    >({
      query: ({ id, ...params }) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/retriever/v0/wallets/${id}/period-stats/?${query.toString()}`;
      },
    }),
    cardTopUp: builder.mutation<
      {
        response: {
          error: { code: number; title: string };
          response: { result: { pay_url: string } };
        };
      },
      {
        walletId: number;
        currency: string;
        amount: number;
        pointId: number;
        serviceId: number;
      }
    >({
      query: ({ walletId, currency, amount, pointId, serviceId }) => ({
        url: `/wallets/${walletId}/card-top-up/`,
        method: "post",
        body: { currency, amount, point: pointId, service: serviceId },
      }),
    }),
    cryptoTopUp: builder.mutation<
      {
        response: {
          error: { code: number; title: string };
          response: { result: { pay_url: string } };
        };
      },
      {
        walletId: number;
        currency: string;
        amount: number;
        point: number;
        service: number;
      }
    >({
      query: ({ walletId, ...data }) => ({
        url: `/wallets/${walletId}/crypto-top-up/`,
        method: "post",
        body: data,
      }),
    }),
    binancePayTopUp: builder.mutation<
      {
        response: {
          error: { code: number; title: string };
          response: {
            result: {
              qr_code_content: string;
              qr_code_deeplink: string;
              qr_code_img_link: string;
              link_universal_url: string;
            };
          };
        };
      },
      {
        walletId: number;
        currency: string;
        amount: number;
        pointId: number;
        serviceId: number;
      }
    >({
      query: ({ walletId, currency, amount, pointId, serviceId }) => ({
        url: `/wallets/${walletId}/binance-pay-top-up/`,
        method: "post",
        body: { currency, amount, point: pointId, service: serviceId },
      }),
    }),
    walletCheck: builder.query<
      { id: number; currency: string },
      { id: number }
    >({
      query: ({ id }) => `/wallets/${id}/check/`,
    }),
    getWalletInvoiceTopUp: builder.query<{ page_url: string }, { id: number }>({
      query: ({ id }) => `/wallets/${id}/invoice-topup/`,
    }),
  }),
});

export const {
  useGetWalletQuery,
  useLazyGetWalletQuery,
  useCardTopUpMutation,
  useCryptoTopUpMutation,
  useLazyGetWalletTurnoverQuery,
  useLazyGetWalletPeriodStatsQuery,
  useLazyGetWalletTransactionStatusesQuery,
  useBinancePayTopUpMutation,
  useLazyWalletCheckQuery,
  useGetWalletInvoiceTopUpQuery,
} = walletsApi;
