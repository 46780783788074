import React, { CSSProperties } from "react";
import styled from "styled-components";
import {
  AlignItems,
  alignItemsProps,
  alignItemsStyle,
} from "./utility/alignItems";
import { Flex, flexProps, flexStyle } from "./utility/flex";
import {
  FlexDirection,
  flexDirectionProps,
  flexDirectionStyle,
} from "./utility/flexDirection";
import {
  JustifyContent,
  justifyContentProps,
  justifyContentStyle,
} from "./utility/justifyContent";
import { hiddenProps, hiddenStyle, Hiden } from "./utility/hiden";
import { Margin, marginProps, marginStyle } from "./utility/maring";
import { noWrapProps, NoWrap, noWrapStyle } from "./utility/nowrap";
import { Padding, paddingProps, paddingStyle } from "./utility/padding";
import {
  getResponsiveProps,
  getResponsiveStyle,
  Responsive,
} from "./utility/responsive";

type BoxProps = {
  children: React.ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
} & Padding &
  Margin &
  NoWrap &
  Flex &
  Hiden &
  FlexDirection &
  JustifyContent &
  AlignItems &
  Responsive<Hiden & Padding & FlexDirection & NoWrap & Margin>;

export const Box = ({ children, style, onClick, ...otherProps }: BoxProps) => (
  <StyledBox
    {...paddingProps(otherProps)}
    {...marginProps(otherProps)}
    {...getResponsiveProps(otherProps)}
    {...justifyContentProps(otherProps)}
    {...alignItemsProps(otherProps)}
    {...hiddenProps(otherProps)}
    {...flexProps(otherProps)}
    {...flexDirectionProps(otherProps)}
    {...noWrapProps(otherProps)}
    style={style}
    onClick={() => onClick && onClick()}
  >
    {children}
  </StyledBox>
);

const StyledBox = styled.div<
  Hiden & Margin & Padding & Flex & JustifyContent & NoWrap & AlignItems
>`
  ${(props) =>
    [
      noWrapStyle,
      paddingStyle,
      marginStyle,
      flexStyle,
      hiddenStyle,
      justifyContentStyle,
      alignItemsStyle,
      flexDirectionStyle,
      getResponsiveStyle<Hiden & Padding & FlexDirection & NoWrap & Margin>([
        paddingStyle,
        marginStyle,
        noWrapStyle,
        flexDirectionStyle,
        hiddenStyle,
      ]),
    ]
      .map((fn) => fn(props))
      .join("")}
`;
