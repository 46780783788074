import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { Box } from "UI/Box";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Divider } from "UI/Divider";
import { Select } from "UI/Form/Select";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  useLazyGetWalletPeriodStatsQuery,
  useLazyGetWalletQuery,
  useLazyGetWalletTransactionStatusesQuery,
  useLazyGetWalletTurnoverQuery,
  useTransactionStatuses,
} from "api/baseAPI/wallets";
import { DateSelector } from "components/RangeSelector/DateSelector";
import { Layout } from "components/layouts/Layout";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  useSelectedWalletManager,
  useWalletInfoManager,
} from "hooks/useSelectedEntity";
import { setSelectedWallet, setWalletInfo } from "reducers/basicSlice";
import { getColors } from "utils/getColors";
import { getTimezone } from "utils/getTimezone";
import { visibleNumber } from "utils/numbers";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useUrlQuery } from "utils/url";
import { useCurrencyIcon } from "utils/useStyle";
import { WalletInfo } from "pages/topup/parts/WalletInfo";

const CustomTooltip = ({
  active,
  payload,
  currencyIcon,
}: {
  active: boolean;
  payload: { value: number }[];
  currencyIcon: JSX.Element;
}) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <Box
        flex
        p={16}
        style={{
          background: "white",
          textDecoration: "none",
          border: "none",
          outline: "none !important",
        }}
      >
        <div>
          <Typography variant="subtitle2" mb={8} style={{ color: "#374258" }}>
            {t("Пополнение")}
          </Typography>
          <Typography style={{ color: "#181F2D" }}>
            {currencyIcon}
            {visibleNumber(payload[0]?.value || 0)}
          </Typography>
        </div>
        <Box px={16}>
          <Divider />
        </Box>
        <div>
          <Typography variant="subtitle2" mb={8} style={{ color: "#374258" }}>
            {t("Выплаты")}
          </Typography>
          <Typography style={{ color: "#181F2D" }}>
            {currencyIcon}
            {visibleNumber(payload[1]?.value || 0)}
          </Typography>
        </div>
      </Box>
    );
  }
  return null;
};

export const Report = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenWalletMenu, setIsOpenWalletMenu] = useState(false);

  const selectedWallet = useSelectedWalletManager();
  const walletInfo = useWalletInfoManager();
  const currencyIcon = useCurrencyIcon(walletInfo.currency);

  const [getWallet, getWalletMeta] = useLazyGetWalletQuery();
  const [getWalletTurnover, getWalletTurnoverMeta] =
    useLazyGetWalletTurnoverQuery();
  const [getWalletPeriodStats, getWalletPeriodStatsMeta] =
    useLazyGetWalletPeriodStatsQuery();
  const [getWalletTransactionStatuses, getWalletTransactionStatusesMeta] =
    useLazyGetWalletTransactionStatusesQuery();

  const { accentColor } = getColors();
  const timezone = getTimezone();

  const initParameters = {
    created_at__gte: DateTime.local()
      .setZone(timezone)
      .minus({ day: 1 })
      .startOf("day")
      .toISO(),
    created_at__lte: DateTime.local({ zone: timezone }).toISO(),
    status: "",
    service__type: "2",
  };

  const { queryParams, querySetters } = useUrlQuery<{
    status: string;
    service__type: string;
    created_at__lte: string;
    created_at__gte: string;
  }>(initParameters);

  const { status, service__type, created_at__gte, created_at__lte } =
    queryParams;

  const {
    set_status,
    set_service__type,
    set_created_at__gte,
    set_created_at__lte,
  } = querySetters;

  useEffect(() => {
    if (selectedWallet?.id) {
      getWallet({ id: selectedWallet?.id });
    }
  }, [selectedWallet]);

  useEffect(() => {
    if (selectedWallet?.id) {
      const requestParams = {
        created_at__gte: new Date(created_at__gte).toISOString(),
        created_at__lte: new Date(created_at__lte).toISOString(),
        id: selectedWallet?.id,
      };
      getWalletTurnover(requestParams);
      getWalletPeriodStats(requestParams);
    }
  }, [selectedWallet, created_at__gte, created_at__lte, status]);

  useEffect(() => {
    if (selectedWallet?.id) {
      const requestParams = {
        ...queryParams,
        created_at__gte: new Date(created_at__gte).toISOString(),
        created_at__lte: new Date(created_at__lte).toISOString(),
        id: selectedWallet?.id,
      };
      getWalletTransactionStatuses(requestParams);
    }
  }, [selectedWallet, queryParams]);

  useEffect(() => {
    if (getWalletMeta?.data) dispatch(setWalletInfo(getWalletMeta?.data));
  }, [getWalletMeta]);

  const handleSelectWallet = (item: { id: number; name: string }) => {
    dispatch(setSelectedWallet(item));
    setIsOpenWalletMenu(!isOpenWalletMenu);
  };
  const totalCountTurnover = useMemo(
    () =>
      (getWalletTurnoverMeta?.data?.incoming_total || 0) +
      (getWalletTurnoverMeta?.data?.outgoing_total || 0),
    [getWalletTurnoverMeta?.data]
  );

  const getDataStats = useMemo(() => {
    const { data } = getWalletPeriodStatsMeta;
    if (data && Object.keys(data).length) {
      const arrayData = Object.keys(data)
        .sort()
        .reduce((acc: any, item) => {
          const date = item.split(" ")[0].split("-");
          const time = item.split(" ")[1].split(":");
          return [
            ...acc,
            {
              date: `${date?.[1]}.${date?.[2]}${
                time?.[0] && time?.[1] && time?.[0] !== "00"
                  ? `-${time?.[0]}.${time?.[1]}`
                  : ""
              }`,
              incoming_sum: data?.[item]?.incoming?.amount || 0,
              incoming_total: data?.[item]?.incoming?.count || 0,
              outgoing_sum: data?.[item]?.outgoing?.amount || 0,
              outgoing_total: data?.[item]?.outgoing?.count || 0,
            },
          ];
        }, []);
      return arrayData;
    }
    return [];
  }, [getWalletPeriodStatsMeta]);

  const TRANSACTION_STATUSES = useTransactionStatuses();

  return (
    <Layout title={t("Итоговый отчет")}>
      <>
        <Grid
          container
          style={{
            background: "#ffffff",
            padding: "24px",
          }}
          hSpace={24}
          vSpace={12}
        >
          <Grid
            item
            sm={12}
            md={9}
            lg="fill"
            xxl={9}
            responsive={{ md: { pt: 10 }, lg: { pt: 0 } }}
          >
            <Typography mb={15} variant="h6">
              {t("Выберите кошелек")}
            </Typography>
            <WalletInfo
              selectedWallet={selectedWallet}
              onChangeSelectedWallet={handleSelectWallet}
            />
          </Grid>
          <Grid
            item
            responsive={{ sm: { hidden: true }, xxl: { hidden: false } }}
          >
            <Divider />
          </Grid>
          <Grid item md={12} lg="fill">
            <Typography mb={15} variant="h6">
              {t("Оборот")}
            </Typography>
            <Grid
              container
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
              pb={18}
            >
              <Grid item>
                <TypographySubtitle3 variant="subtitle3">
                  {t("Пополнение")}
                </TypographySubtitle3>
                <StyledElseBalance>
                  {currencyIcon}
                  {visibleNumber(
                    getWalletTurnoverMeta?.data?.incoming_sum || 0
                  )}
                </StyledElseBalance>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                }}
              >
                <StyledChip data-tip data-for="incoming_total">
                  {`${
                    totalCountTurnover &&
                    getWalletTurnoverMeta?.data?.incoming_total
                      ? (
                          (getWalletTurnoverMeta.data.incoming_total * 100) /
                          totalCountTurnover
                        ).toFixed(1)
                      : "0.0"
                  }%`}
                </StyledChip>
                <ReactTooltip
                  id="incoming_total"
                  border
                  place="top"
                  multiline
                  type="light"
                  effect="solid"
                  borderColor={accentColor}
                >
                  <span>{`${getWalletTurnoverMeta?.data?.incoming_total} ${t(
                    "транзакций из общего числа пополнений и выплат"
                  )}`}</span>
                </ReactTooltip>
                <StyledChip ml={8}>
                  {getWalletTurnoverMeta?.data?.incoming_total || 0}
                </StyledChip>
              </Grid>
            </Grid>
            <StyledDivider />
            <Grid
              container
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
              pt={18}
            >
              <Grid item>
                <TypographySubtitle3 variant="subtitle3">
                  {t("Выплаты")}
                </TypographySubtitle3>
                <StyledElseBalance>
                  {currencyIcon}
                  {visibleNumber(
                    getWalletTurnoverMeta?.data?.outgoing_sum || 0
                  )}
                </StyledElseBalance>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                }}
              >
                <StyledChip data-tip data-for="outgoing_total">
                  {`${
                    totalCountTurnover &&
                    getWalletTurnoverMeta?.data?.outgoing_total
                      ? (
                          (getWalletTurnoverMeta.data.outgoing_total * 100) /
                          totalCountTurnover
                        ).toFixed(1)
                      : "0.0"
                  }%`}
                </StyledChip>
                <ReactTooltip
                  id="outgoing_total"
                  border
                  place="top"
                  multiline
                  type="light"
                  effect="solid"
                  borderColor={accentColor}
                >
                  <span>{`${getWalletTurnoverMeta?.data?.outgoing_total} ${t(
                    "транзакций из общего числа пополнений и выплат"
                  )}`}</span>
                </ReactTooltip>
                <StyledChip ml={8}>
                  {getWalletTurnoverMeta?.data?.outgoing_total || 0}
                </StyledChip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            padding: "24px",
          }}
        >
          <Grid item sm={12} style={{ maxWidth: "100%" }}>
            <Typography variant="h6">{t("Статистика платежей")}</Typography>
            <Grid
              container
              mt={16}
              vSpace={16}
              style={{
                justifyContent: "space-between",
              }}
              responsive={{
                md: { direction: "column-reverse" },
                lg: { direction: "row" },
              }}
            >
              <Grid
                item
                flex
                alignItems="flex-end"
                responsive={{ lg: { nowrap: true } }}
              >
                <StyledDateSelector
                  start={
                    created_at__gte ? DateTime.fromISO(created_at__gte) : null
                  }
                  end={
                    created_at__lte ? DateTime.fromISO(created_at__lte) : null
                  }
                  set_start={set_created_at__gte}
                  set_end={set_created_at__lte}
                  disabledClear
                />
                <StyledSelect
                  value={status}
                  onChange={(val) => set_status(val)}
                  placeholder={t("Статус")}
                  size="small"
                  options={[
                    { value: "", label: t("Все статусы") },
                    { value: "1", label: t("Неуспешные") },
                  ]}
                />
              </Grid>
              <Grid item sm={12} md="content" flex>
                <ButtonSwitch
                  firstTitle={t("Пополнение")}
                  secondTitle={t("Выплаты")}
                  value={service__type === "2"}
                  onChange={(val) => set_service__type(val ? "2" : "1")}
                />
              </Grid>
            </Grid>
            <Table
              tableCellTitles={[
                t("Статус"),
                t("Количество"),
                t("Сумма"),
                t("Процент %"),
              ]}
              isLoading={getWalletTransactionStatusesMeta?.isFetching}
              count={0}
              dataLength={6}
              disabledPagination
              page={1}
              rowsPerPage={6}
              setPage={() => {}}
              setRowsPerPage={() => {}}
            >
              {getWalletTransactionStatusesMeta?.data &&
              getWalletTransactionStatusesMeta.data?.length > 0
                ? getWalletTransactionStatusesMeta.data.map((row, index) => (
                    <StyledRow key={index}>
                      <StyledCell>
                        {TRANSACTION_STATUSES[row.status] || row.failure_reason}
                      </StyledCell>
                      <StyledCell>{row.count}</StyledCell>
                      <StyledCell>{visibleNumber(row.amount)}</StyledCell>
                      <StyledCell>
                        {`${
                          getWalletTransactionStatusesMeta.data?.reduce(
                            (acc, item) => acc + item.count,
                            0
                          ) && row.count
                            ? (
                                (row.count * 100) /
                                (getWalletTransactionStatusesMeta.data?.reduce(
                                  (acc, item) => acc + item.count,
                                  0
                                ) || 1)
                              ).toFixed(1)
                            : "0.0"
                        }%`}
                      </StyledCell>
                    </StyledRow>
                  ))
                : null}
            </Table>
          </Grid>
          <Grid
            item
            sm={12}
            my={16}
            flex
            style={{
              justifyContent: "space-between",
            }}
            responsive={{
              sm: { direction: "column", hidden: true },
              md: { direction: "row", hidden: false },
            }}
          >
            <Typography variant="h6">{t("История транзакций")}</Typography>
            <Grid
              container
              responsive={{
                sm: { mt: 16 },
                md: { mt: 0 },
              }}
            >
              <Grid
                item
                flex
                style={{
                  alignItems: "center",
                }}
              >
                <StyledPoint color="#70C886" />
                <Typography variant="subtitle2">{t("Выплаты")}</Typography>
              </Grid>
              <Grid
                item
                flex
                ml={36}
                style={{
                  alignItems: "center",
                }}
              >
                <StyledPoint color="#4089F7" />
                <Typography variant="subtitle2">{t("Пополнение")}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            p={5}
            style={{ overflow: "auto" }}
            responsive={{
              sm: { hidden: true },
              md: { hidden: false },
            }}
          >
            <LineChart
              width={1600}
              height={330}
              data={getDataStats ?? undefined}
            >
              <XAxis dataKey="date" />
              <YAxis />
              <CartesianGrid strokeDasharray="5 5" />
              {
                <Tooltip
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  content={<CustomTooltip currencyIcon={currencyIcon} />}
                />
              }
              <Line
                type="monotone"
                name={t("Пополнение")}
                dataKey="incoming_sum"
                strokeWidth="2"
                stroke="#4089F7"
                dot={{ stroke: "#4089F7", strokeWidth: 2 }}
                isAnimationActive
              />
              <Line
                type="monotone"
                name={t("Выплаты")}
                dataKey="outgoing_sum"
                strokeWidth="2"
                stroke="#70C886"
                dot={{ stroke: "#70C886", strokeWidth: 2 }}
                isAnimationActive
              />
            </LineChart>
          </Grid>
        </Grid>
      </>
    </Layout>
  );
};

// Style
const StyledSelect = styled(Select)`
  max-width: 250px;
  max-height: 50.5px;
  @media (${RESPONSIVE_SIZES.md}) {
    max-width: initial;
  }
`;
const StyledDateSelector = styled(DateSelector)`
  margin-right: 12px;
  max-width: 310px;
  min-width: 310px;
  @media (${RESPONSIVE_SIZES.md}) {
    max-width: initial;
    margin-right: 0;
    margin-bottom: 16px;
  }
`;
const StyledPoint = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${(props) => props.color};
`;
const StyledChip = styled.div<{
  ml?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  height: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background: rgba(64, 137, 247, 0.2);
  margin-left: ${(props) => props.ml || 0}px;
`;
const TypographySubtitle3 = styled(Typography)`
  color: #374258 !important;
  margin-bottom: 8px !important;
`;
const StyledElseBalance = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #19202e;
  display: flex;
`;
const StyledDivider = styled.hr`
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: #ecf0f6;
`;
