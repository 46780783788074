import { includes } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Box } from "UI/Box";
import {
  PayoutsQueryParams,
  useGetPayoutsQuery,
  useSyncStatusMutation,
} from "api/baseAPI/payouts";
import { useLazyGetTransactionQuery } from "api/baseAPI/transactions";
import { useGetUsersMePayoutsPermissionsQuery } from "api/baseAPI/user";
import { FilterBar } from "components/table/FilterBar";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useStyle } from "hooks/useStyle";
import { useFilterMaker } from "utils/filterMaker";
import { ReactComponent as StatusError } from "utils/img/circle-close.svg";
import { ReactComponent as StatusPending } from "utils/img/circle-warning.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { visibleNumber } from "utils/numbers";
import { useUrlQuery } from "utils/url";
import { getCurrencyIcon } from "utils/useStyle";
import { ReactComponent as Download } from "./icons/download.svg";
import { ReactComponent as SaveAsTemplate } from "./icons/save-as-template.svg";
import { ReactComponent as StatusSuccess } from "./icons/status-success.svg";
import { ReactComponent as SyncStatus } from "./icons/sync-status.svg";
import { PayoutsFilters } from "./PayoutsFilters";

export const PayoutsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const style = useStyle();
  const selectedPointManager = useSelectedPointManager();
  const getPayoutsPermissionsQuery = useGetUsersMePayoutsPermissionsQuery();

  const initParameters = {
    page: "1",
    page_size: "10",
    search: "",
    registry__account_wallet_id: "",
    registry: "",
    created_at__gte: "",
    created_at__lte: "",
    amount__gte: "",
    amount__lte: "",
    customer_account__icontains: "",
    registry__point_id: "",
  };

  const { queryParams, querySetters } =
    useUrlQuery<PayoutsQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const { data, isFetching, isSuccess, refetch, error } = useGetPayoutsQuery({
    ...queryParams,
    registry__point_id: String(selectedPointManager.point?.id),
  });

  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_registry__account_wallet_id(
        values.registry__account_wallet_id
      );
      querySetters.set_registry(values.registry);
      querySetters.set_created_at__gte(values.created_at__gte);
      querySetters.set_created_at__lte(values.created_at__lte);
      querySetters.set_amount__gte(values.amount__gte);
      querySetters.set_amount__lte(values.amount__lte);
      querySetters.set_customer_account__icontains(
        values.customer_account__icontains
      );
    },
    () => {
      set_search(initParameters.search);
      querySetters.set_registry(initParameters.registry);
      querySetters.set_registry__account_wallet_id(
        initParameters.registry__account_wallet_id
      );
      querySetters.set_created_at__gte(initParameters.created_at__gte);
      querySetters.set_created_at__lte(initParameters.created_at__lte);
      querySetters.set_amount__gte(initParameters.amount__gte);
      querySetters.set_amount__lte(initParameters.amount__lte);
      querySetters.set_customer_account__icontains(
        initParameters.customer_account__icontains
      );
    }
  );
  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const [syncStatus, syncStatusMeta] = useSyncStatusMutation();
  const [getTransaction, getTransactionMeta] = useLazyGetTransactionQuery();

  useSuccessNotification([syncStatusMeta]);
  useErrorNotification([syncStatusMeta]);
  useErrorNotification([getTransactionMeta]);

  useEffect(() => {
    if (getTransactionMeta.isSuccess && getTransactionMeta.data.uuid) {
      window.open(
        `https://${window.location.host}${
          style.receipt_endpoint || "/ce/download_receipt/"
        }${getTransactionMeta.data.uuid}`,
        "_blank"
      );
    }
  }, [getTransactionMeta]);

  return (
    <Table
      tableCellTitles={[
        t("ID"),
        t("Номер транзакции"),
        t("Получатель"),
        t("Сумма"),
        t("Примечания"),
        t("Статус"),
      ]}
      filterBar={
        <FilterBar
          search={search}
          setSearch={handleSetSearch}
          createTitle="выдать доступ"
          refetch={refetch}
          filters={<PayoutsFilters filters={filters} error={error} />}
        />
      }
      count={data ? data.count : 0}
      rowsPerPage={Number(page_size)}
      page={Number(page)}
      setPage={handleSetPage}
      setRowsPerPage={handleSetRowsPerPage}
      isLoading={isFetching}
    >
      {isSuccess
        ? data?.results.map((item) => (
            <React.Fragment key={item.id}>
              <StyledRow>
                <StyledCell>{item.id}</StyledCell>
                <StyledCell>{item.transaction_id}</StyledCell>
                <StyledCell>{item.customer_account}</StyledCell>
                <StyledCell>
                  {getCurrencyIcon(item.amount_currency)}
                  {visibleNumber(item.amount)}
                </StyledCell>
                <StyledCell>{item.description}</StyledCell>
                <StyledCell>
                  {!item.is_failed && !item.is_processed && (
                    <Box flex alignItems="center" justifyContent="center">
                      <StyledStatusPending /> &nbsp; {t("В обработке")}
                    </Box>
                  )}
                  {!item.is_failed && item.is_processed && (
                    <Box flex alignItems="center" justifyContent="center">
                      <StatusSuccess /> &nbsp; {t("Обработан")}
                    </Box>
                  )}
                  {item.is_failed && (
                    <Box flex alignItems="center" justifyContent="center">
                      <StyledStatusError /> &nbsp; {t("Ошибка")}
                    </Box>
                  )}
                </StyledCell>
                <StyledCell key="actions" align="right">
                  <TableRowActions
                    long
                    actions={[
                      ...(includes(
                        getPayoutsPermissionsQuery.data,
                        "add_payouttemplate"
                      )
                        ? [
                            <MenuItemAction
                              key={0}
                              onClick={() =>
                                navigate({
                                  pathname: "/payout-templates",
                                  search: `?history_id=${item.id}`,
                                })
                              }
                            >
                              <SaveAsTemplate />
                              {t("Сохранить в шаблоны")}
                            </MenuItemAction>,
                          ]
                        : []),
                      ...(item.transaction_id
                        ? [
                            <MenuItemAction
                              key={1}
                              onClick={() => syncStatus({ id: item.id })}
                            >
                              <SyncStatus />
                              {t("Синхронизировать статус")}
                            </MenuItemAction>,
                          ]
                        : []),
                      ...(item.transaction_id && style.is_fc
                        ? [
                            <MenuItemAction
                              key={3}
                              onClick={() =>
                                getTransaction({ id: item.transaction_id })
                              }
                            >
                              <Download style={{ verticalAlign: "middle" }} />{" "}
                              {t("Скачать чек")}
                            </MenuItemAction>,
                          ]
                        : []),
                    ]}
                  />
                </StyledCell>
              </StyledRow>
            </React.Fragment>
          ))
        : null}
    </Table>
  );
}

const StyledStatusError = styled(StatusError)`
  height: 13.5px;
  width: 13.5px;
  path {
    fill: #d00025 !important;
  }
`;

const StyledStatusPending = styled(StatusPending)`
  height: 13.5px;
  width: 13.5px;
  path {
    fill: #fbb100 !important;
  }
`;
