import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Grid } from "UI/Grid";
import { IconButton } from "UI/IconButton";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowsReloadIcon } from "utils/img/arrows-reload.svg";
import { ReactComponent as Close } from "utils/img/close.svg";
import { ReactComponent as Filter } from "utils/img/filter.svg";
import { RESPONSIVE_SIZES } from "utils/tools";

export const TableFilter = ({
  children,
  onSubmit,
  onReset,
  isLoading,
  widthBox,
  disableSubmit,
  disabledReset,
  error,
}: {
  children: React.ReactElement;
  onSubmit: () => void;
  onReset: () => void;
  isLoading?: boolean;
  widthBox?: string;
  disabledReset?: boolean;
  disableSubmit?: boolean;
  error?: FetchBaseQueryError | SerializedError | undefined;
}) => {
  const { t } = useTranslation();
  const { pageBackground } = getColors();

  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(error)) setOpen(true);
  }, [error]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    if (open) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const handleReset = () => {
    onReset();
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit();
    setOpen(false);
  };

  return (
    <span ref={ref}>
      <IconButton variant="outlined" onClick={handleToggle} mr={16}>
        <Filter />
      </IconButton>
      {open && (
        <>
          <StyledModalOverlay onClick={handleToggle} />
          <StyledWrapperModal widthBox={widthBox}>
            <Grid
              container
              justifyContent="space-between"
              style={{ height: "-webkit-fill-available" }}
            >
              <Grid item sm={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">
                      {t("Настройки фильтра")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <StyledClose onClick={handleToggle} />
                  </Grid>
                </Grid>
                {children}
              </Grid>
              <Grid item sm={12} mt={24} flex alignItems="flex-end">
                <Box
                  p={16}
                  style={{ background: pageBackground, width: "100%" }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Button
                        px={5}
                        variant="text"
                        size="small"
                        iconStart={<ArrowsReloadIcon />}
                        disabled={!!disabledReset}
                        onClick={handleReset}
                      >
                        {t("Сбросить")}
                      </Button>
                    </Grid>
                    <Grid item flex>
                      <Button
                        responsive={{ md: { px: 32 }, sm: { px: 14 } }}
                        variant="outlined"
                        size="small"
                        onClick={handleToggle}
                      >
                        {t("Отмена")}
                      </Button>
                      <Button
                        responsive={{
                          md: { px: 32, ml: 16 },
                          sm: { px: 14, ml: 8 },
                        }}
                        variant="contained"
                        size="small"
                        disabled={isLoading || disableSubmit}
                        onClick={handleSubmit}
                      >
                        {t("Подтвердить")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </StyledWrapperModal>
        </>
      )}
    </span>
  );
}
const StyledClose = styled(Close)`
  cursor: pointer;
`;
const StyledWrapperModal = styled.div`
  position: absolute;
  padding: 32px;
  width: ${({ widthBox }: { widthBox?: string }) =>
    widthBox || "50%"};
  min-width: 300px;
  color: #000000;
  left: 0;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 121;
  @media (${RESPONSIVE_SIZES.sm}) {
    position: fixed;
    overflow: auto;
    padding: 24px;
    width: calc(100% - 48px);
    height: calc(100vh - 110px);
    z-index: 103;
    top: 0;
  }
`;
const StyledModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
`;
